import {
  PharmacyLocationDto,
  NormalTimeRangeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';

export enum MapSize {
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  lg = 'lg',
}

export interface StoreInformationMapProps {
  size: MapSize;
  zoom?: number;
}

export interface StoreInformationProps {
  item: PharmacyLocationDto;
  onChangePress: (item: PharmacyLocationDto) => void;
  disabled?: boolean;
  mapProps?: StoreInformationMapProps;
  showChange?: boolean;
  showPhone?: boolean;
  openingHours?: NormalTimeRangeDto;
  showFullDetails?: boolean;
}

export interface PreferredHomeLocationInformationProps {
  item: PharmacyLocationDto;
  onChangePress: (item?: PharmacyLocationDto) => void;
  openingHours?: NormalTimeRangeDto;
  showChangeButton?: boolean;
  onSendMessagePress?: () => void;
}
