import { isPostalCode } from '@digitalpharmacist/validation-dp';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';

type ZipCodeFieldType = {
  name: string;
  required: boolean;
};

export const ZipCodeField = ({ name, required }: ZipCodeFieldType) => {
  return (
    <TextField
      name={name}
      label={getText('zip-code')}
      testID={'postal_code'}
      type="numeric"
      rules={{
        validate: (value) => {
          return !value || isPostalCode(value, 'US')
            ? true
            : getText('zip-code-is-not-valid');
        },
        required: required && getText('zip-code-is-required'),
      }}
    />
  );
};
