import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useNavigation } from '@react-navigation/native';
import {
  LoginStackNavigationProp,
  LoginStackParamList,
} from '../../navigation/LoginNavigation';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import * as Linking from 'expo-linking';
import { useCurrentUrl } from 'assets/hooks';

export const RegisterConfirmation: FunctionComponent<
  PropsWithChildren<RegisterConfirmationProps>
> = () => {
  const { url } = useCurrentUrl();

  const theme = useTheme();
  const navigation = useNavigation<LoginStackNavigationProp>();

  const onPress = async () => {
    if (url && url !== '') {
      const { queryParams } = Linking.parse(url);
      if (queryParams) {
        navigation.navigate('intro', queryParams as never);
      } else {
        navigation.navigate('intro');
      }
    } else {
      navigation.navigate('intro');
    }
  };

  return (
    <ScreenContainer>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 25,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: 'center',
        }}
      >
        {getText('register-confirmation-heading')}
      </Text>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 14,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: 'center',
        }}
      >
        {getText('register-confirmation-body')}
      </Text>
      <View style={{ margin: theme.getSpacing(1) }}>
        <View style={{ marginBottom: theme.getSpacing(2) }}>
          <Button
            onPress={onPress}
            hierarchy="tertiary"
            logger={{ id: 'register-confirmation-back-to-login-button' }}
          >
            {getText('return-to-login')}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

interface RegisterConfirmationProps
  extends NativeStackScreenProps<
    LoginStackParamList,
    'register-confirmation'
  > {}
