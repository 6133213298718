import { ResourceItem } from '../../screens/account/patient/patient-actions';
import { findConditionsById } from './medical-info-actions';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import medicationService from '../../api/medication-service';
import { getText } from 'assets/localization/localization';
import { TypeaheadBaseItem } from 'assets/components/typeahead/types';
import {
  TypeaheadWithTags,
  TypeaheadWithTagsProps,
} from 'assets/components/typeahead-with-tags';
import vucaService from '../../api/vuca-service';

export type MedicalConditionTypeaheadProps = {
  medicalConditionIds?: string[];
  onChange?: (medicalConditionIds: string[]) => void;
};

export const MedicalConditionTypeahead: FunctionComponent<
  PropsWithChildren<MedicalConditionTypeaheadProps>
> = ({ medicalConditionIds, onChange: parentOnChange }) => {
  const [medicalConditions, setMedicalConditions] = useState<ResourceItem[]>();
  const [medicalConditionTags, setMedicalConditionTags] =
    useState<TypeaheadBaseItem[]>();

  // Load suggested medicalCondition tags
  useEffect(() => {
    void (async () => {
      setMedicalConditionTags(await vucaService.getTopConditionHints());
    })();
  }, []);

  useEffect(() => {
    if (!medicalConditionIds) return;
    void (async () => {
      if (medicalConditionIds.length > 0) {
        const loadedMedicalConditions =
          await findConditionsById(medicalConditionIds);
        setMedicalConditions(loadedMedicalConditions);
      }
    })();
  }, [medicalConditionIds]);

  const handleOnChange = (value: (string | TypeaheadBaseItem)[]) => {
    parentOnChange?.(
      value.map((val) => {
        if (typeof val === 'string') {
          return val;
        }
        return val.value;
      }),
    );
  };

  const medicalConditionsTypeaheadProps: TypeaheadWithTagsProps = {
    multiple: true,
    asyncOptions: async (searchTerm: string) => {
      if (!searchTerm) return Promise.resolve([]);
      return vucaService.conditionSearch(searchTerm);
    },
    hintMessage: getText('medical-conditions-non-capitalized'),
    defaultValue: medicalConditions,
    tags: medicalConditionTags,
    onChange: handleOnChange,
    useCustomNoOptionsValuePrefix: true,
  };

  return <TypeaheadWithTags {...medicalConditionsTypeaheadProps} />;
};
