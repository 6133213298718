import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useEffect,
} from 'react';
import { GradientView } from 'assets/components/gradient-view';
import { Platform, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { LocationIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import { uuid } from 'assets/utils/uuid';
import { IconButton } from 'assets/components/icon-button';
import { StoreSelector } from '../store-selector';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { StoreSelectorHandler } from '../store-selector/types';
import { useIsFocused } from '@react-navigation/native';
import { NotificationsListNative } from '../notifications-list';
import { loadStores } from '../../actions/app-actions';
import { getDefaultGreeting } from '../../utils';
import { NotificationBadge } from '../notifications';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
export const LandingHeader: FunctionComponent<
  PropsWithChildren<LandingHeaderProps>
> = ({
  pharmacyName,
  greetings,
  patientName,
  gradientId = uuid(),
  height = 109,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);
  const storeSelectorRef = useRef<StoreSelectorHandler>(null);

  const { user, updateUser } = useUserState();
  const { stores, notifications } = useAppStateStore();
  const { notificationsBadgeCount } = useAppStateStore();

  const isMultiStore = stores.length > 1;

  const isFocused = useIsFocused();
  let defaultGreeting = '';

  // Set the default greeting when the screen is focused
  if (isFocused) {
    !greetings && (defaultGreeting = getDefaultGreeting());
  }

  // first rendering only
  useEffect(() => {
    if (stores.length < 1) void loadStores();
  }, []);

  const handleOnLocationChanged = (value: PharmacyLocationDto) => {
    updateUser({ preferredPharmacyLocationId: value.id });
  };

  const handleOnStoreIconPress = () => {
    storeSelectorRef.current?.show();
  };

  const notificationsBottomSheetRef = useRef<BaseModalHandler>(null);

  const handleNotificationsBadgePress = () => {
    notificationsBottomSheetRef.current?.show();
  };

  return (
    <>
      <GradientView
        contentHeight={height}
        fillGradientStops={[
          { stopColor: theme.colors.gradientStart, offset: 0 },
          { stopColor: theme.colors.gradientEnd, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View style={styles.headerContainer}>
          <View style={styles.leftContent}>
            <Text style={styles.pharmacyNameText}>{pharmacyName}</Text>
            <Text style={styles.greetingsText}>
              {greetings || defaultGreeting}
            </Text>
            {patientName && (
              <Text style={styles.patientNameText}>{patientName}</Text>
            )}
          </View>

          <View style={styles.rightContent}>
            <View style={{ position: 'relative' }}>
              {notificationsBadgeCount > 0 && (
                <NotificationBadge
                  count={notificationsBadgeCount}
                  onPress={handleNotificationsBadgePress}
                />
              )}
              <NotificationsListNative
                bottomSheetRef={notificationsBottomSheetRef}
                notifications={notifications}
              />
            </View>

            {isMultiStore && (
              <IconButton
                icon={LocationIcon}
                logger={{ id: '' }}
                onPress={handleOnStoreIconPress}
                color={theme.palette.white}
              />
            )}
          </View>
        </View>
      </GradientView>
      {isMultiStore && (
        <StoreSelector
          ref={storeSelectorRef}
          options={stores}
          selectedOption={stores.find(
            (x) => x.id === user?.preferredPharmacyLocationId,
          )}
          onChange={handleOnLocationChanged}
          storeInformationShown={false}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'flex-end',
    position: 'relative',
    paddingBottom: theme.getSpacing(1),
  },
  leftContent: {
    alignItems: 'flex-start',
    paddingLeft: theme.getSpacing(2),
    marginRight: theme.getSpacing(4),
  },
  rightContent: {
    position: 'absolute',
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(0.5),
  },
  pharmacyNameText: {
    ...theme.lumistryFonts.label.xSmall.semiBold,
    color: theme.palette.gray[200],
    marginBottom: theme.getSpacing(1),
  },
  greetingsText: {
    ...theme.lumistryFonts.label.xLarge.regular,
    color: theme.palette.white,
  },
  patientNameText: {
    ...theme.lumistryFonts.label.xxLarge.bold,
    color: theme.palette.white,
  },
}));

export interface LandingHeaderProps {
  pharmacyName?: string;
  greetings?: string;
  patientName?: string;
  gradientId?: string;
  height?: number;
}

export const NativeHeaderTestIDs = {
  title: 'native-header-title',
  actionButton: 'native-header-action-button',
  backButton: 'native-header-back-button',
};
