import {
  PatientRecordDto,
  CardType,
} from '@digitalpharmacist/patient-service-client-axios';
import { TrashWithStripesIcon, ImageIcon } from 'assets/icons';
import { FileUploadIcon } from 'assets/icons/FileUploadIcon';
import { Text } from 'assets/components/text';

import { getText } from 'assets/localization/localization';
import { useTheme, makeStyles } from 'assets/theme';
import { useState, useCallback } from 'react';
import {
  View,
  TouchableOpacity,
  Platform,
  useWindowDimensions,
} from 'react-native';
import FileStorageService from '../../../api/file-storage-service';
import { cardTypeConfig } from './EditPatientCard';
import {
  PhotoInfo,
  PhotoButton,
} from './components/insurance-card/PhotoButton';
import { useAppStateStore } from '../../../store/app-store';
import { useFocusEffect } from '@react-navigation/native';
import { usePatientIntakeState } from './patient-store';

export const PatientCard = ({
  patientRecord,
  cardType,
  navigateToAddCard,
  makePrimaryInsuranceCard,
  showModalForSelectedCard,
}: {
  patientRecord: PatientRecordDto;
  cardType: CardType;
  navigateToAddCard: () => void;
  makePrimaryInsuranceCard: () => void;
  showModalForSelectedCard: () => void;
}) => {
  const theme = useTheme();
  const {
    viewTitleCopy,
    category,
    frontFilenameProperty,
    backFilenameProperty,
  } = cardTypeConfig[cardType];
  const { pharmacyId } = useAppStateStore();
  const styles = useStyles();
  const { width, height } = useWindowDimensions();
  const buttonWidth =
    Platform.OS === 'web'
      ? theme.webMaxWidth - theme.getSpacing(2)
      : width - theme.getSpacing(2);
  const buttonHeight = height * 0.25;

  const [frontPhoto, setFrontPhoto] = useState<PhotoInfo>();
  const [backPhoto, setBackPhoto] = useState<PhotoInfo>();

  const loadImages = async () => {
    try {
      const frontFilename = patientRecord[frontFilenameProperty];

      if (frontFilename) {
        const responseReadUrl = await FileStorageService.readUrlPharmacy(
          category,
          frontFilename,
          pharmacyId,
        );
        setFrontPhoto({ uri: responseReadUrl.url });
      }

      if (backFilenameProperty) {
        const backFilename = patientRecord[backFilenameProperty];

        if (backFilename) {
          const responseReadUrl = await FileStorageService.readUrlPharmacy(
            category,
            backFilename,
            pharmacyId,
          );
          setBackPhoto({ uri: responseReadUrl.url });
        }
      }
      usePatientIntakeState.setState({
        error: undefined,
        status: 'idle',
      });
    } catch (error) {
      usePatientIntakeState.setState({
        error: { message: getText('image-reading-error') },
        status: 'error',
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      void loadImages();
    }, [patientRecord]),
  );

  return (
    <View style={{ marginTop: theme.getSpacing(3) }}>
      <View style={styles.imageOptions}>
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 14,
          }}
        >
          {getText(viewTitleCopy)}
        </Text>
        {frontPhoto && (
          <View style={styles.inline}>
            {cardType === CardType.InsuranceSecondary && (
              <TouchableOpacity onPress={makePrimaryInsuranceCard}>
                <Text style={styles.replaceText}>
                  {getText('make-primary')}
                </Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity onPress={navigateToAddCard}>
              <Text style={styles.replaceText}>{getText('replace')}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={showModalForSelectedCard}>
              <TrashWithStripesIcon size={20} color={theme.palette.gray[500]} />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <PhotoButton
        uri={frontPhoto?.uri}
        width={buttonWidth}
        height={buttonHeight}
        onPress={() => {
          if (!frontPhoto) {
            navigateToAddCard();
          }
        }}
        disable={!!frontPhoto?.uri}
      >
        <View style={styles.uploadButton}>
          <View>
            <ImageIcon size={32} color={theme.palette.gray[500]} />
          </View>
          <View style={styles.upload}>
            <FileUploadIcon color={theme.palette.gray[500]} size={24} />
            <Text style={styles.uploadText}>{getText('upload')}</Text>
          </View>
        </View>
      </PhotoButton>
      {backPhoto && (
        <View style={{ marginTop: theme.getSpacing(2) }}>
          <PhotoButton
            uri={backPhoto.uri}
            width={buttonWidth}
            height={buttonHeight}
            disable={!!backPhoto.uri}
          ></PhotoButton>
        </View>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageOptions: {
    marginBottom: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    textAlign: 'left',
  },
  replaceText: {
    color: theme.palette.primary[600],
    fontSize: 14,
    fontWeight: '500',
    marginRight: 12,
  },
  buttonContainer: {
    marginTop: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
  uploadButton: {
    padding: theme.getSpacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
