import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { makeStyles } from 'assets/theme';
import { View, Image, ViewStyle } from 'react-native';
import { PUBLIC_FILE_STORAGE_SERVICE_BASE_URL } from '../../common/constants';
import { useAppStateStore } from '../../store/app-store';
export const Logo: FunctionComponent<PropsWithChildren<LogoProps>> = ({
  width = 241,
  height = 105,
  isOnDark = true,
  containerStyle,
}) => {
  const pharmacyId = useAppStateStore((x) => x.pharmacyId);
  const uri = `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/${
    isOnDark ? 'logo_dark' : 'logo_light'
  }`;
  const styles = useStyles();
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const renderImageSource = () => {
    if (imageError) {
      return isOnDark
        ? require('../../../assets/logo_on_dark.png')
        : require('../../../assets/logo_on_light.png');
    } else {
      return { uri };
    }
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <Image
        resizeMode="contain"
        source={renderImageSource()}
        style={[styles.logo, { width: width, height: height }]}
        onError={handleImageError}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    resizeMode: 'contain',
  },
}));

interface LogoProps {
  width?: number;
  height?: number;
  isOnDark?: boolean;
  containerStyle?: ViewStyle;
}
