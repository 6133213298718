import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import {
  LoginStackNavigationProp,
  LoginStackParamList,
} from '../../navigation/LoginNavigation';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { NotificationType } from '@digitalpharmacist/users-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import { useLoginState } from './login-store';
import { sendNotificationWhenAccountLocked } from './login-actions';
import { useNavigation } from '@react-navigation/native';
import { Alert } from 'assets/components/alert/Alert';

export const LoginAttemptsExceeded: FunctionComponent<
  PropsWithChildren<LoginAttemptsExceededProps>
> = () => {
  const navigation = useNavigation<LoginStackNavigationProp>();
  const { pharmacyId } = useAppStateStore();
  const { error, email } = useLoginState();
  const theme = useTheme();
  const styles = useStyles();

  const onPress = async (notificationType: NotificationType) => {
    if (!email) return;
    await sendNotificationWhenAccountLocked(
      email,
      pharmacyId,
      notificationType,
      navigation,
    );
  };

  return (
    <ScreenContainer>
      {!!error && (
        <Alert
          title={error.message}
          intent="error"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      )}
      <Text style={styles.textHeader}>{getText('max-attempts-exceeded')}</Text>
      <Text style={[styles.text, styles.textMargin]}>
        {getText('account-locked-reason')}
      </Text>
      <Text style={styles.text}>{getText('security-reason')}</Text>
      <View style={{ margin: theme.getSpacing(1) }}>
        <View style={styles.textMargin}>
          <Button
            onPress={() => onPress(NotificationType.Email)}
            hierarchy="secondary"
            logger={{ id: 'send-email-button' }}
          >
            {getText('send-me-email')}
          </Button>
          <Button
            onPress={() => onPress(NotificationType.Sms)}
            hierarchy="secondary"
            logger={{ id: 'send-sms-button' }}
            style={{ marginTop: theme.getSpacing(2) }}
          >
            {getText('send-me-sms')}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

type LoginAttemptsExceededProps = NativeStackScreenProps<
  LoginStackParamList,
  'login-attempts-exceeded'
>;

const useStyles = makeStyles((theme) => ({
  textHeader: {
    color: theme.palette.gray[900],
    fontSize: 25,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    textAlign: 'center',
  },
  text: {
    color: theme.palette.gray[900],
    fontSize: 14,
    textAlign: 'center',
  },
  textMargin: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
}));
