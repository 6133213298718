import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useState,
  useRef,
} from 'react';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import {
  View,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  Platform,
  PlatformOSType,
  useWindowDimensions,
} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Text } from 'assets/components/text';
import { SearchIcon, CloseIcon } from 'assets/icons';
import { TextInput } from 'react-native-paper';
import { ListMenu, ListItemLink } from 'assets/components/list-menu';
import { BottomSheetInput } from 'assets/components/bottom-sheet';
import { useDebounceEffect } from './hooks/useDebounceEffect';
import moment from 'moment';
import { usePatientSubmissionsState } from './patient-store';
import {
  getSubmissionPDF,
  getUserSubmissionsByPatientRecordId,
} from './patient-actions';
import { SubmissionCoreMetadataDto } from '@digitalpharmacist/forms-service-client-axios';
import { PatientSubmission } from './PatientSubmission';
import { FORMS_API_BASE_URL } from '../../../common/constants';
import { StorageKeys } from '../../../../enums/storage-keys';

import { LoadingIndicator } from 'assets/components/loading-indicator';
import { downloadFile, getDateTimeFilename } from './patient-forms.utils';
import { PatientUnderCareStackParamList } from './components/account-information/linked-accounts/PatientUnderCareNavigation';
import AsyncStorageService from '../../../api/async-storage-service';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { useAppStateStore } from '../../../store/app-store';

export const PatientForms: FunctionComponent<
  PropsWithChildren<PatientUnderCareProps>
> = ({ route }) => {
  const theme = useTheme();
  const styles = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [submissionDetails, setSubmissionDetails] = useState<
    Record<string, string>
  >({
    formId: '',
    locationId: '',
    submissionId: '',
    name: '',
  });

  const patient_id = route.params?.patient_id;

  const { width } = useWindowDimensions();
  let inputMaxWidth = width;
  if (Platform.OS === 'web' && width > theme.webMaxWidth) {
    inputMaxWidth = theme.webMaxWidth;
  }

  const submissions = usePatientSubmissionsState((state) => state.submissions);
  const submissionPdfStatus = usePatientSubmissionsState(
    (state) => state.submissionPdfStatus,
  );
  const isLoading = usePatientSubmissionsState(
    (state) => state.status === 'loading',
  );
  const [data, setData] = useState(submissions);
  const sheetRef = useRef<BaseModalHandler>(null);
  const { pharmacyId } = useAppStateStore();

  const handleInputChange = (
    e: NativeSyntheticEvent<TextInputChangeEventData>,
  ) => {
    setInputValue(e.nativeEvent.text);
  };

  const handleClearInput = () => setInputValue('');

  const handleSubmissionDetailsClick = (item: SubmissionCoreMetadataDto) => {
    setSubmissionDetails({
      formId: item.form_id,
      locationId: item.location_id,
      submissionId: item.submission_id,
      name: item.form_name,
    });

    sheetRef.current?.show();
  };

  useFocusEffect(
    useCallback(() => {
      if (!patient_id) {
        getUserSubmissionsByPatientRecordId();
      }
    }, []),
  );

  const handleSubmissionPDFDownload = async () => {
    usePatientSubmissionsState.setState({
      submissionPdfStatus: 'loading',
    });
    const token = await AsyncStorageService.getItem(StorageKeys.AccessToken);
    const name = getDateTimeFilename(submissionDetails.name, 'pdf');

    const { uri } = await FileSystem.downloadAsync(
      `${FORMS_API_BASE_URL}/locations/${submissionDetails.locationId}/forms/${submissionDetails.formId}/submissions/${submissionDetails.submissionId}/submissionPDF`,
      FileSystem.documentDirectory + name,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-pharmacy-id': pharmacyId,
        },
      },
    );

    usePatientSubmissionsState.setState({
      submissionPdfStatus: 'success',
    });

    await Sharing.shareAsync(uri, { mimeType: 'application/pdf' });
  };

  const downloadSubmissionWeb = async () => {
    const data = await getSubmissionPDF(
      submissionDetails.formId,
      submissionDetails.submissionId,
      submissionDetails.locationId,
    );

    data &&
      downloadFile(
        data,
        getDateTimeFilename(
          `${submissionDetails.name.split(' ').join('_')}`,
          'pdf',
        ),
      );
  };
  useDebounceEffect(() => {
    if (!isLoading) {
      setData(
        submissions.filter((item) =>
          item.form_name.toLowerCase().includes(inputValue),
        ),
      );
    }
  }, [inputValue, isLoading]);

  return (
    <InternalScreenContainer title={getText('patient-forms')}>
      <View style={styles.container}>
        <View
          style={[
            styles.inputContainer,
            { width: inputMaxWidth - theme.getSpacing(2) },
          ]}
        >
          <InputWrapper
            placeholder={getText('search-forms')}
            autoComplete="off"
            autoCapitalize="none"
            style={styles.inputWrapper}
            mode="outlined"
            outlineColor={theme.palette.gray[200]}
            placeholderTextColor={theme.palette.gray[500]}
            activeOutlineColor={theme.palette.primary[600]}
            left={
              <TextInput.Icon
                name={SearchIcon}
                color={theme.palette.gray[500]}
                size={22}
                forceTextInputFocus={false}
                style={{ top: theme.getSpacing(0.5) }}
              />
            }
            right={
              inputValue ? (
                <TextInput.Icon
                  name={CloseIcon}
                  color={theme.palette.gray[500]}
                  size={22}
                  forceTextInputFocus={false}
                  style={{ top: theme.getSpacing(0.5) }}
                  onPress={handleClearInput}
                />
              ) : null
            }
            value={inputValue}
            onChange={handleInputChange}
            returnKeyType="done"
          />
        </View>
      </View>
      <View style={styles.borderBottom}></View>
      <View>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <>
            {data.length > 0 ? (
              <ListMenu>
                {data.map((item) => (
                  <ListItemLink
                    key={item.form_name + item.form_id + item.submission_id}
                    showIcon={false}
                    onPress={() => handleSubmissionDetailsClick(item)}
                  >
                    <View style={styles.textContainer}>
                      <Text
                        ellipsizeMode="tail"
                        numberOfLines={1}
                        style={[styles.textUnderTitle, styles.formName]}
                      >
                        {item.form_name}
                      </Text>
                      <Text
                        ellipsizeMode="tail"
                        numberOfLines={1}
                        style={[styles.textUnderTitle, styles.formDate]}
                      >
                        {moment(item.created_at, 'YYYY-MM-DD').format(
                          'MM/DD/YYYY',
                        )}
                      </Text>
                    </View>
                  </ListItemLink>
                ))}
              </ListMenu>
            ) : (
              <Text style={styles.emptySearchText}>
                {submissions.length > 0
                  ? `${getText('no-submissions-found')} "${inputValue}"`
                  : getText('no-submissions')}
              </Text>
            )}
          </>
        )}
      </View>
      <GenericModal
        isScrollable
        webModalProps={{
          contentContainerStyle: styles.flexOne,
          scrollViewStyle: styles.flexOne,
        }}
        showDismissButton={Platform.OS !== 'web'}
        nativeModalBodyStyle={styles.flexOne}
        title={submissionDetails.name}
        ref={sheetRef}
        buttons={[
          {
            hierarchy: 'primary',
            onPress: async () =>
              Platform.OS === 'web'
                ? await downloadSubmissionWeb()
                : handleSubmissionPDFDownload(),
            logger: { id: 'appointment-submissions-download-pdf-button-modal' },
            disabled: submissionPdfStatus === 'loading',
            loading: submissionPdfStatus === 'loading',
            text: getText('download-pdf'),
          },
          {
            onPress: () => sheetRef.current?.hide(),
            logger: { id: 'appointment-submissions-cancel-button-modal' },
            disabled: submissionPdfStatus === 'loading',
            hierarchy: 'tertiary-gray',
            text: getText('close'),
          },
        ]}
      >
        <PatientSubmission
          formId={submissionDetails.formId}
          locationId={submissionDetails.locationId}
          submissionId={submissionDetails.submissionId}
        />
      </GenericModal>
    </InternalScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
  },
  emptySearchText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 28,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
    color: theme.palette.gray[900],
    marginVertical: theme.getSpacing(2),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[800],
    fontWeight: '500',
    fontSize: 20,
    marginTop: theme.getSpacing(2),
    lineHeight: 28,
  },
  text: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
    textAlign: 'center',
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textUnderTitle: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
  },
  formName: {
    flex: 5,
  },
  formDate: {
    flex: 2,
    minWidth: 98,
    textAlign: 'right',
  },
  borderBottom: {
    height: 1,
    backgroundColor: theme.palette.gray[300],
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  inputWrapper: {
    height: 44,
    backgroundColor: theme.palette.gray[200],
  },
  inputContainer: {
    justifyContent: 'center',
    marginBottom: theme.getSpacing(1),
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  flexOne: {
    flex: 1,
  },
  bottomSheetFooter: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    flex: 1,
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
}));

type PatientUnderCareProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  'patient-under-care-forms'
>;

const InputWrapper: FunctionComponent<
  React.ComponentProps<typeof TextInput> & {
    platform?: PlatformOSType;
    useBottomSheetInput?: boolean;
  }
> = ({ platform = Platform.OS, useBottomSheetInput, ...props }) => {
  // TODO: find a better way to write this with generics
  return platform === 'web' || !useBottomSheetInput ? (
    <TextInput {...props} />
  ) : (
    <BottomSheetInput {...props} />
  );
};
