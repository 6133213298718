import {
  AppointmentTypeDto,
  BookingDto,
  SubmissionDto,
  UpdateBookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { getText } from 'assets/localization/localization';
import AppointmentService from '../../api/appointment-service';
import { AsyncStatus } from '../../store/types';
import { useAppointmentDetailsState } from './appointment-details-store';
import appointmentService from '../../api/appointment-service';

export const getBooking = async (
  pharmacyId: string,
  locationId: string,
  bookingId: string,
) => {
  useAppointmentDetailsState.setState({ status: 'loading' });

  try {
    const response = await AppointmentService.findBookingById(
      pharmacyId,
      locationId,
      bookingId,
    );

    useAppointmentDetailsState.setState({
      status: 'success',
      booking: response,
      type: new Date(response.endTime) > new Date() ? 'upcoming' : 'past',
    });
  } catch (error) {
    useAppointmentDetailsState.setState({
      error: {
        message: getText('error-loading-appointment'),
      },
      status: 'error',
    });
  }
};

export const getAppointmentType = async (
  pharmacyId: string,
  locationId: string,
  appointmentTypeId: string,
) => {
  useAppointmentDetailsState.setState({ appointmentTypeStatus: 'loading' });

  try {
    const response = await AppointmentService.findAppointmentTypeById(
      pharmacyId,
      locationId,
      appointmentTypeId,
    );

    useAppointmentDetailsState.setState({
      appointmentTypeStatus: 'success',
      appointmentType: response,
    });
  } catch (error) {
    useAppointmentDetailsState.setState({
      error: {
        message: getText('error-loading-appointment-type'),
      },
      appointmentTypeStatus: 'error',
    });
  }
};

export const cancelBooking = async (
  pharmacyId: string,
  locationId: string,
  bookingId: string,
) => {
  useAppointmentDetailsState.setState({ cancelAppointmentStatus: 'loading' });

  try {
    await AppointmentService.cancelBooking(pharmacyId, locationId, bookingId);

    useAppointmentDetailsState.setState({
      cancelAppointmentStatus: 'success',
    });
  } catch (error) {
    useAppointmentDetailsState.setState({
      error: {
        message: getText('error-cancel-appointment'),
      },
      cancelAppointmentStatus: 'error',
    });
  }
};

export const setBooking = (booking: BookingDto | undefined) => {
  useAppointmentDetailsState.setState({ booking });
};

export const setAppointmentType = (
  appointmentType: AppointmentTypeDto | undefined,
) => {
  useAppointmentDetailsState.setState({ appointmentType });
};

export const setRefresh = (refresh: boolean) => {
  useAppointmentDetailsState.setState({ refresh });
};

export const refreshAppointmentDetails = () => {
  setRefresh(true);
};

export const setShowCancelAppointment = (showCancelAppointment: boolean) => {
  useAppointmentDetailsState.setState({ showCancelAppointment });
};

export const setCancelAppointmentStatus = (
  cancelAppointmentStatus: AsyncStatus,
) => {
  useAppointmentDetailsState.setState({ cancelAppointmentStatus });
};

export const updateBooking = async (
  pharmacyId: string,
  locationId: string,
  bookingId: string,
  booking: UpdateBookingDto,
) => {
  useAppointmentDetailsState.setState({ checkListStatus: 'loading' });

  try {
    const response = await appointmentService.updateBooking(
      pharmacyId,
      locationId,
      bookingId,
      booking,
    );

    useAppointmentDetailsState.setState({
      booking: response,
      checkListStatus: 'success',
      status: 'success',
    });
  } catch (error) {
    useAppointmentDetailsState.setState({
      error: {
        message: getText('error-updating-appointment'),
      },
      status: 'error',
    });
  }
};

export const updateBookingOptimistically = async (
  pharmacyId: string,
  locationId: string,
  bookingId: string,
  booking: UpdateBookingDto,
) => {
  const currentBooking = useAppointmentDetailsState.getState().booking;

  useAppointmentDetailsState.setState({
    booking: {
      ...(currentBooking as BookingDto),
      ...booking,
    },
    checkListStatus: 'loading',
  });

  try {
    await appointmentService.updateBooking(
      pharmacyId,
      locationId,
      bookingId,
      booking,
    );

    useAppointmentDetailsState.setState({
      checkListStatus: 'success',
      status: 'success',
    });
  } catch (error) {
    useAppointmentDetailsState.setState({
      booking: currentBooking as BookingDto,
      error: {
        message: getText('error-updating-appointment'),
      },
      status: 'error',
    });
  }
};

export const setCurrentForm = (currentForm: number) => {
  useAppointmentDetailsState.setState({ currentForm });
};

export const setFormSubmissions = (formSubmissions: SubmissionDto[]) => {
  useAppointmentDetailsState.setState({ formSubmissions });
};

export const setSaveSubmissionsStatus = (
  saveSubmissionsStatus: AsyncStatus,
) => {
  useAppointmentDetailsState.setState({ saveSubmissionsStatus });
};

export const setOpenFormFillModal = (openFormFillModal: boolean) => {
  useAppointmentDetailsState.setState({ openFormFillModal });
};
