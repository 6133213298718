import { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  ReactElement,
} from 'react';
import { Menu, MenuItem } from 'react-native-material-menu';

export const ContextMenu: FunctionComponent<ContextMenuProps> = ({
  children,
  items,
  leftClick,
}) => {
  const styles = useStyles();
  const [visible, setVisible] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const showMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (!visible) {
      setMenuPosition({
        x: e.nativeEvent.pageX,
        y: e.nativeEvent.pageY,
      });
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const hideMenu = () => {
    setVisible(false);
  };

  const handleItemClick = (item: ContextMenuItem) => {
    item.onClick();
    hideMenu();
  };

  return (
    <div onContextMenu={showMenu} onClick={leftClick ? showMenu : undefined}>
      <Menu
        visible={visible}
        animationDuration={0}
        onRequestClose={hideMenu}
        style={{
          ...styles.container,
          left: menuPosition.x,
          top: menuPosition.y,
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            testID={ContextMenuTestIDs.contextMenuGroupItem}
            key={index}
            onPress={() => handleItemClick(item)}
          >
            {item.label}
            {item.itemComponent}
          </MenuItem>
        ))}
      </Menu>
      {children}
    </div>
  );
};

export interface ContextMenuProps extends PropsWithChildren {
  items: ContextMenuItem[];
  leftClick?: boolean;
}

export type ContextMenuItem = {
  label?: string;
  itemComponent?: ReactElement;
  onClick: () => void;
};

export const ContextMenuTestIDs = {
  contextMenuGroupItem: 'context-menu-group-item',
};

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.lumistryFonts.text.small.regular,
    position: 'absolute',
    borderRadius: theme.roundness,
    width: 240,
    shadowColor: theme.palette.gray[900],
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.08,
    shadowRadius: theme.roundness,
    elevation: 5,
  },
}));
