import {
  PatientUserDto,
  UpdateUserRequest,
} from '@digitalpharmacist/users-service-client-axios';
import create from 'zustand';
import UsersServiceInstance from '../api/users-service';

export interface User extends PatientUserDto {
  preferredPharmacyLprId?: string;
  pendingPhoneNumber?: string;
}

export interface UserState {
  user?: User;
  updateUser: (data: Partial<User>) => Promise<void>;
  logout: () => void;
}

export const useUserState = create<UserState>((set, get) => ({
  user: undefined,
  updateUser: async (partialUser: Partial<User>) => {
    const currentUserInfo = get().user;

    if (currentUserInfo?.id) {
      const user: PatientUserDto = await UsersServiceInstance.updatePatient(
        currentUserInfo.id,
        // This disable is necessary because PatientUserDto's preferredPharmacyLocationId can be null, and UpdateUserRequest's cannot
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        { ...currentUserInfo, ...partialUser } as UpdateUserRequest,
      );

      const updatedUser = { ...user, ...partialUser };

      set({
        user: updatedUser,
      });
    }
  },
  logout: () => {
    set({ user: undefined });
  },
}));

export const isUserAuthorized = (user: PatientUserDto | undefined) => {
  return user?.id &&
    user.firstName &&
    user.lastName &&
    user.dateOfBirth &&
    user.phoneNumber &&
    user.phoneNumberVerified &&
    user.email &&
    user.emailVerified &&
    user.hasSeenFindMedsScreen &&
    user.preferredPharmacyLocationId &&
    user.patientRecordId
    ? true
    : false;
};

export const userNeedsEmailVerification = (
  user: PatientUserDto | undefined,
) => {
  return user?.id && user.email && !user.emailVerified;
};

export const isEmailVerified = (user: PatientUserDto | undefined) => {
  return user?.emailVerified;
};

export const userHasPreferredPharmacy = (user: PatientUserDto | undefined) => {
  return user?.id && !user.preferredPharmacyLocationId;
};

export const userNeedsDetails = (user: PatientUserDto | undefined) => {
  return (
    user?.id &&
    (!user.dateOfBirth ||
      !user.firstName ||
      !user.lastName ||
      !user.phoneNumber)
  );
};

export const hasSeenFindMedsScreen = (user: PatientUserDto | undefined) => {
  return user?.hasSeenFindMedsScreen;
};
