import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { List } from 'react-native-paper';
import { makeStyles, useTheme } from '../../theme';
import { Text } from '../text';

export const ListAccordion: FunctionComponent<
  PropsWithChildren<ListAccordionProps>
> = ({
  title,
  expanded,
  onPress,
  children,
  backgroundColor,
  listStyle,
  titleStyle,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const renderTitle = () => (
    <View>
      <Text style={[styles.title, titleStyle]}>{title}</Text>
    </View>
  );
  return (
    <>
      <List.Accordion
        title={renderTitle()}
        expanded={expanded}
        onPress={onPress}
        testID={ListAccordionTestIDs.accordion}
        style={[
          {
            margin: 0,
            padding: 0,
            height: 45,
            backgroundColor: backgroundColor ?? theme.palette.gray[50],
          },
          listStyle,
        ]}
      >
        {children}
      </List.Accordion>
    </>
  );
};

export interface ListAccordionProps {
  title: string;
  expanded?: boolean;
  onPress?: () => void;
  backgroundColor?: string;
  listStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<ViewStyle>;
}

export const ListAccordionTestIDs = {
  accordion: 'accordion',
};

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    left: 0,
    alignContent: 'flex-start',
    textAlignVertical: 'center',
    paddingLeft: theme.getSpacing(2),
    lineHeight: 20,
    fontSize: 14,
    color: theme.palette.gray[900],
  },
}));
