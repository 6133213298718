import React, { FunctionComponent } from 'react';
import { Svg, Path, Rect, Circle } from 'react-native-svg';
import { IconProps } from './types';

export const AccountNotificationIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Circle cx={24} cy={24} r={24} fill="#EAF1F4" stroke="transparent" />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M30 31.75v-1.5a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v1.5m9-10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
      <Rect
        width={20.5}
        height={20.5}
        x={13.75}
        y={13.75}
        stroke={color}
        strokeWidth={1.5}
        rx={10.25}
      />
    </Svg>
  );
};
