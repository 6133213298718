import React, { PropsWithChildren } from 'react';
import { DefaultDeepLinkingConfig } from '../config';
import { DeepLinkingContext } from '../context/deep-linking-context';
import { DeepLinkingConfig, DeepLinkingProviderProp } from '../types';
import {
  canHandleIntent,
  isAndroidBrowser,
  isDocumentHidden,
  isIOSBrowser,
} from '../utils';
import { useCurrentUrl } from '../../../hooks';

export const DeepLinkingProvider: React.FunctionComponent<
  PropsWithChildren<DeepLinkingProviderProp>
> = ({ children, deepLinkingConfig }) => {
  const [currentDeepLinking, setCurrentDeepLinking] =
    React.useState<DeepLinkingConfig>(
      deepLinkingConfig ?? DefaultDeepLinkingConfig,
    );

  const setDeepLinkingConfig = (override: Partial<DeepLinkingConfig>) => {
    setCurrentDeepLinking((curr) => ({
      ...curr,
      ...override,
    }));
  };

  const launchAppOrAltUrl = (redirectUrl: string, timeout: number = 2500) => {
    let timer: NodeJS.Timeout;
    let heartbeat: NodeJS.Timer;

    const launchAppUrl = `${currentDeepLinking.scheme}://${redirectUrl}`;
    const altUrl = isAndroidBrowser()
      ? currentDeepLinking.android.fallbackUrl
      : isIOSBrowser()
      ? currentDeepLinking.ios.fallbackUrl
      : '';

    function clearTimers() {
      clearTimeout(timer);
      clearTimeout(heartbeat);
    }

    function intervalHeartbeat() {
      if (isDocumentHidden()) {
        clearTimers();
      }
    }

    function tryUniversalLinkApproach() {
      document.location.href = launchAppUrl;
      // send alert to user if want ro proceed to alternative url
      timer = setTimeout(() => {
        const response = confirm(
          'It seems that you have not installed our app. Do you want to go to the store and install it?',
        );
        if (response) document.location.href = altUrl;
      }, timeout);
    }

    function tryIntentApproach() {
      const googleChromeIntent = `intent://${redirectUrl}#Intent;scheme=${currentDeepLinking.scheme};package=${currentDeepLinking.android.package};S.browser_fallback_url=${currentDeepLinking.android.fallbackUrl};end`;
      document.location.href = googleChromeIntent;
    }

    heartbeat = setInterval(intervalHeartbeat, timeout / 10);

    if (canHandleIntent() && !isIOSBrowser()) {
      tryIntentApproach();
    } else {
      tryUniversalLinkApproach();
    }
  };

  return (
    <DeepLinkingContext.Provider
      value={{
        deepLinkingConfig: currentDeepLinking,
        setDeepLinkingConfig: setDeepLinkingConfig,
        launchApp: launchAppOrAltUrl,
      }}
    >
      {children}
    </DeepLinkingContext.Provider>
  );
};
