import { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { Form, InternalScreenContainer } from 'assets/layout';
import { Text } from 'assets/components/text';
import { Pressable, View } from 'react-native';
import { useTransferMedicationsStore } from './transfer-meds-store';
import { useForm } from 'react-hook-form';
import { Divider } from 'react-native-paper';
import { useUserState } from '../../store/user-store';
import { usePatientRecordState } from '../account/patient/patient-store';
import { getText } from 'assets/localization/localization';
import { TransferMedsTestIDs } from './TransferMedsTestIDs';
import { useIsFocused } from '@react-navigation/native';
import {
  DropdownSelectField,
  DropdownSelectHandler,
} from 'assets/components/dropdown-select';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import patientService from '../../api/patient-service';
import { useRefillMedicationsStore } from '../refill/refill-store';
import { useAppStateStore } from '../../store/app-store';
import { StackHeaderProps } from '@react-navigation/stack';

export const MultipleLocationsPharmacy: FunctionComponent<
  PropsWithChildren<TransferMedicationsNavigationProp>
> = ({ navigation }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { user } = useUserState();
  const { patientRecordItems } = usePatientRecordState();
  const { updateTransferMedsData } = useTransferMedicationsStore();
  const { selectedPatientRecordId } = useRefillMedicationsStore();
  const dropdownSelectRef = useRef<DropdownSelectHandler>(null);
  const isFocused = useIsFocused();
  const { stores, pharmacyName } = useAppStateStore();

  const methods = useForm({
    defaultValues: {
      selectedPatientRecordId: selectedPatientRecordId,
      locationPharmacy: '',
    },
  });

  const getLocationPatientRecordFromPUC = (): string | undefined => {
    const locationId = user?.preferredPharmacyLocationId;
    const pucRecord = patientRecordItems.find(
      (puc) =>
        puc.patientRecordId === methods.getValues().selectedPatientRecordId,
    );
    const lprRecord = pucRecord?.location_patient_record.find(
      (lpc) => lpc.location_id === locationId,
    );
    return lprRecord?.id;
  };

  let selectedLPRId = getLocationPatientRecordFromPUC();

  useEffect(() => {
    if (!isFocused) {
      dropdownSelectRef.current?.hide();
    }
  }, [isFocused]);

  let selectedPatientRecordItem = patientRecordItems.find(
    (pri) =>
      pri.patientRecordId === methods.getValues().selectedPatientRecordId,
  );
  // Load Prescriptions based on selected Location/Patient
  useEffect(() => {
    if (patientRecordItems.length) {
      //if the selected value has been deleted then select the default one which is Myself

      if (!selectedPatientRecordItem) {
        selectedPatientRecordItem = patientRecordItems[0];
        methods.setValue(
          'selectedPatientRecordId',
          selectedPatientRecordItem.patientRecordId,
        );
      } else {
        methods.setValue(
          'selectedPatientRecordId',
          selectedPatientRecordItem.patientRecordId,
        );
      }
    }
  }, [selectedPatientRecordId]);

  const handleOnPress = () => {
    void (async () => {
      if (
        !updateTransferMedsData ||
        !user ||
        !user.preferredPharmacyLocationId ||
        !user.patientRecordId
      )
        return;

      if (!selectedLPRId) {
        const patientRecord =
          await patientService.patientRecordInitLocationPatientRecord(
            methods.getValues().selectedPatientRecordId!,
            { location_id: user.preferredPharmacyLocationId },
          );
        selectedLPRId = patientRecord.location_patient_records.find(
          (lpr) => lpr.location_id === user.preferredPharmacyLocationId,
        )?.id;
        if (!user.patientRecordId)
          throw new Error(getText('error-loading-patient-record'));
      }

      let lprRecord: LocationPatientRecordDto | undefined;

      if (selectedLPRId) {
        lprRecord = await patientService.findLocationPatientRecord(
          user.preferredPharmacyLocationId,
          selectedLPRId,
        );
      }

      updateTransferMedsData({
        pucFullName: selectedPatientRecordItem?.fullName,
        locationPatientRecord: lprRecord,
        locationPatientRecordId: selectedLPRId,
      });

      if (methods.getValues().locationPharmacy === 'cameron') {
        navigation.navigate('transfer-medications', {
          screen: 'location-select',
        });
      } else {
        updateTransferMedsData({
          originated: 'another-pharmacy',
        });
        navigation.navigate('transfer-medications', {
          screen: 'single-location',
        });
      }
      if (stores.length < 1) {
        navigation.navigate('transfer-medications', {
          screen: 'single-location',
        });
      }
    })();
  };

  const handleAddNewPatient = () => {
    navigation.navigate('people-under-care');
  };

  return (
    <InternalScreenContainer
      title={getText('transfer-meds-header-title')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: methods.handleSubmit(handleOnPress),
          testID: TransferMedsTestIDs.nextButton,
          logger: { id: TransferMedsTestIDs.nextButton },
          text: getText('next'),
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Alert
          visible={
            !!methods.getFieldState('locationPharmacy').error ||
            !!methods.getFieldState('selectedPatientRecordId').error
          }
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
        />
        <Form.Row>
          <Form.Column>
            <View>
              <Text style={styles.title}>{getText('about-your-transfer')}</Text>
            </View>
            <View>
              <Divider />
            </View>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(2) }}>
            <View>
              <Text style={styles.helperText}>
                {getText('whose-prescription-are-you-transferring')}
              </Text>
            </View>
            <DropdownSelectField
              name="selectedPatientRecordId"
              testID="selectedPatientRecordId"
              options={patientRecordItems.map((item) => ({
                label: item.fullName,
                value: item.patientRecordId,
              }))}
              rules={{
                required: getText('field-required'),
              }}
              ref={dropdownSelectRef}
              labelInlined={true}
              placeholder={getText('person')}
            />
            <Pressable onPress={handleAddNewPatient}>
              <View>
                <Text style={styles.addNewPatient}>
                  {getText('add-new-patient')}
                </Text>
              </View>
            </Pressable>
          </Form.Column>
        </Form.Row>
        {stores && stores.length > 1 && (
          <Form.Row>
            <Form.Column>
              <Text style={styles.helperText}>
                {getText('where-are-you-transferring-from')}
              </Text>
              <RadioButtonGroupField
                rules={{
                  required: getText('field-required'),
                }}
                name="locationPharmacy"
                values={[
                  {
                    text: getText('another-pharmacy-location', {
                      pharmacyName: pharmacyName,
                    }),
                    value: 'cameron',
                  },
                  {
                    text: getText('different-pharmacy'),
                    value: 'different',
                  },
                ]}
              />
            </Form.Column>
          </Form.Row>
        )}
      </Form>
    </InternalScreenContainer>
  );
};

export type TransferMedicationsNavigationProp = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  helperText: {
    marginVertical: theme.getSpacing(0.5),
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  addNewPatient: {
    marginVertical: theme.getSpacing(1),
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
}));
