import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useEffect,
} from 'react';
import { Modal as RNPModal, Portal, IconButton } from 'react-native-paper';
import { View, Dimensions, ViewStyle } from 'react-native';
import { Button, ButtonHierarchy, ButtonProps } from '../button';
import { Text } from '../text';
import { makeStyles, useTheme } from '../../theme';
import { CloseIcon } from '../../icons';
import { TrashIcon } from '../../icons';
import { ScrollView } from 'react-native-gesture-handler';
import { getText } from '../../localization/localization';
import { GenericModalButtonProps } from 'assets/components/generic-modal/GenericModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    padding: theme.getSpacing(2),
    margin: theme.getSpacing(2),
    borderRadius: theme.roundness,
    justifyContent: 'center',
    alignSelf: 'center',
    maxHeight: Dimensions.get('window').height * 0.9,
  },
  fillContainer: { flex: 1 },
  modalSM: { width: 600 },
  modalLG: { width: 800 },
  title: {
    ...theme.fonts.regular,
    fontWeight: '800',
    color: theme.palette.gray[900],
  },
  titleSM: { fontSize: 16, lineHeight: 18 },
  titleLG: { fontSize: 18, lineHeight: 20 },
  closeIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  dismissButton: {
    margin: 0,
    padding: 0,
    height: 20,
    width: 20,
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
  },
  content: {
    marginTop: theme.getSpacing(1),
    textAlign: 'center',
  },
  header: {
    flexDirection: 'row',
    paddingBottom: theme.getSpacing(2),
  },
  footer: { flexDirection: 'row-reverse' },
  footerButton: {
    marginRight: theme.getSpacing(2),
    marginTop: theme.getSpacing(2),
    minWidth: 120,
    height: 44,
  },
  buttons: {
    marginLeft: theme.getSpacing(2),
    marginTop: theme.getSpacing(2),
    minWidth: 120,
    height: 44,
  },
  okButton: {
    marginRight: 0,
  },
  pinLeft: {
    flex: 1,
    alignItems: 'flex-start',
  },
}));

export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  show,
  size = 'sm',
  okButtonProps,
  cancelButtonProps,
  resetButtonProps,
  deleteButtonProps,
  dismissButtonProps,
  title,
  titleSize = 'sm',
  children,
  isScrollable = false,
  height,
  headerComponent,
  scrollViewStyle,
  contentContainerStyle,
  fillContainer,
  hideFooter,
  handleDismissModal,
  showDismissButton = true,
  buttons,
  leftAlignedButton,
  scrollToTop = false,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const scrollViewRef = useRef<any>();

  useEffect(() => {
    if (scrollToTop) {
      scrollViewRef.current?.scrollTo({ y: 0, animated: true });
    }
  }, [scrollToTop]);

  return (
    <Portal
      theme={{
        colors: {
          backdrop: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <RNPModal
        visible={show}
        onDismiss={
          handleDismissModal ??
          dismissButtonProps?.onPress ??
          cancelButtonProps?.onPress
        }
        contentContainerStyle={[
          styles.root,
          size === 'sm' ? styles.modalSM : styles.modalLG,
          fillContainer && styles.fillContainer,
        ]}
      >
        <View testID={ModalTestIDs.header} style={styles.header}>
          {title && (
            <View>
              <Text
                testID={ModalTestIDs.title}
                style={[
                  styles.title,
                  titleSize === 'sm' ? styles.titleSM : styles.titleLG,
                  size === 'sm' ? { width: 500 } : { width: 700 },
                ]}
              >
                {title}
              </Text>
            </View>
          )}
          {showDismissButton && (
            <View testID={ModalTestIDs.closeIcon} style={styles.closeIcon}>
              <IconButton
                accessibilityLabel={
                  dismissButtonProps?.text ?? getText('dismiss')
                }
                icon={CloseIcon}
                style={styles.dismissButton}
                onPress={
                  handleDismissModal ??
                  cancelButtonProps?.onPress ??
                  dismissButtonProps?.onPress
                }
                size={20}
                color={theme.palette.gray[500]}
                {...dismissButtonProps}
              />
            </View>
          )}
        </View>
        {headerComponent}
        <ScrollView
          scrollEnabled={isScrollable}
          style={{ height: height ?? undefined }}
          contentContainerStyle={scrollViewStyle}
          ref={scrollViewRef}
        >
          <View
            testID={ModalTestIDs.content}
            style={[styles.content, contentContainerStyle]}
          >
            {children}
          </View>
        </ScrollView>
        {buttons ? (
          <View testID={ModalTestIDs.footer} style={styles.footer}>
            {buttons.map((button, index) => (
              <View
                style={styles.buttons}
                testID={ModalTestIDs.getButtonIds(index)} //Think of something better here?!
                key={index}
              >
                <Button accessibilityLabel={button.text} {...button}>
                  {button.text}
                </Button>
              </View>
            ))}
            {leftAlignedButton && (
              <View
                style={[styles.footerButton, styles.pinLeft]}
                testID={ModalTestIDs.leftAlignedButton}
              >
                <Button
                  icon={leftAlignedButton.icon}
                  accessibilityLabel={leftAlignedButton.text}
                  {...leftAlignedButton}
                >
                  {leftAlignedButton.text}
                </Button>
              </View>
            )}
          </View>
        ) : (
          // This piece of code below is deprecated and only intended to support the current implementation while we migrate to the new approach
          !hideFooter && (
            <View testID={ModalTestIDs.footer} style={styles.footer}>
              {okButtonProps && (
                <View
                  style={[styles.footerButton]}
                  testID={ModalTestIDs.okButton}
                >
                  <Button
                    hierarchy="primary"
                    size="large"
                    accessibilityLabel={okButtonProps.text ?? getText('ok')}
                    {...okButtonProps}
                  >
                    {okButtonProps.text ?? getText('ok')}
                  </Button>
                </View>
              )}
              {cancelButtonProps && (
                <View
                  style={styles.footerButton}
                  testID={ModalTestIDs.cancelButton}
                >
                  <Button
                    hierarchy="secondary-gray"
                    size="large"
                    accessibilityLabel={
                      cancelButtonProps.text ?? getText('cancel')
                    }
                    {...cancelButtonProps}
                  >
                    {cancelButtonProps.text ?? getText('cancel')}
                  </Button>
                </View>
              )}
              {resetButtonProps && (
                <View
                  style={styles.footerButton}
                  testID={ModalTestIDs.resetButton}
                >
                  <Button
                    hierarchy="tertiary-gray"
                    accessibilityLabel={
                      resetButtonProps.text ?? getText('reset')
                    }
                    {...resetButtonProps}
                  >
                    {resetButtonProps.text ?? getText('reset')}
                  </Button>
                </View>
              )}

              {deleteButtonProps && (
                <View
                  style={[
                    styles.footerButton,
                    deleteButtonProps.pinLeft && styles.pinLeft,
                  ]}
                  testID={ModalTestIDs.deleteButton}
                >
                  <Button
                    hierarchy="destructive"
                    icon={TrashIcon}
                    accessibilityLabel={
                      deleteButtonProps.text ?? getText('delete')
                    }
                    {...deleteButtonProps}
                  >
                    {deleteButtonProps.text ?? getText('delete')}
                  </Button>
                </View>
              )}
            </View>
          )
        )}
      </RNPModal>
    </Portal>
  );
};

export interface ModalProps {
  show: boolean;
  size?: 'sm' | 'lg';
  title?: string;
  titleSize?: 'sm' | 'lg';
  buttons?: GenericModalButtonProps[];
  leftAlignedButton?: GenericModalButtonProps;
  scrollToTop?: boolean;
  // TODO should we clean these deprecated values and clean the Modal a bit?
  /**
   * @deprecated Use {@link buttons} instead.
   */
  okButtonProps?: ModalButtonProps;
  /**
   * @deprecated Use {@link buttons} instead.
   */
  cancelButtonProps?: ModalButtonProps;
  /**
   * @deprecated Use {@link buttons} instead.
   */
  resetButtonProps?: ModalButtonProps;
  /**
   * @deprecated Use {@link buttons} instead.
   */
  deleteButtonProps?: ModalButtonProps & {
    pinLeft?: boolean;
  };
  /**
   * @deprecated Use {@link showDismissButton} and {@link handleDismissModal} props instead.
   */
  dismissButtonProps?: Pick<ButtonProps, 'onPress' | 'logger'> & {
    text?: string;
  };
  isScrollable?: boolean;
  height?: number;
  headerComponent?: React.ReactNode;
  scrollViewStyle?: ViewStyle;
  contentContainerStyle?: ViewStyle;
  fillContainer?: boolean;
  /**
   * @deprecated This should not be used
   */
  hideFooter?: boolean;
  handleDismissModal?: () => void;
  showDismissButton?: boolean;
}

export interface ModalButtonProps extends Omit<ButtonProps, 'hierarchy'> {
  text?: string;
  hierarchy?: ButtonHierarchy;
}

export const ModalTestIDs = {
  header: 'confirmation-modal-header',
  footer: 'confirmation-modal-header',
  title: 'confirmation-modal-title',
  content: 'confirmation-modal-content',
  okButton: 'confirmation-modal-okButton',
  cancelButton: 'confirmation-modal-cancelButton',
  closeIcon: 'confirmation-modal-closeIcon',
  resetButton: 'confirmation-modal-resetButton',
  deleteButton: 'confirmation-modal-deleteButton',
  leftAlignedButton: 'left-aligned-modal-button',
  getButtonIds: (index: number) => `modal-button-${index}`,
};
