import React, {
  useRef,
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { View, Modal, Linking } from 'react-native';
import {
  Camera as ExpoCamera,
  CameraCapturedPicture,
  CameraType,
  FlashMode,
  PermissionStatus,
} from 'expo-camera';
import { logInfo } from 'assets/logging/logger';
import { CameraPreview } from './CameraPreview';
import { CameraScreen } from './CameraScreen';
import { CameraAccessDenied } from './CameraAccessDenied';
import { Portal } from 'react-native-paper';
import { makeStyles } from 'assets/theme';

/*
 * Camera Component allows taking of a photo on a user's device.
 */
export const Camera: FunctionComponent<PropsWithChildren<CameraProps>> = ({
  title,
  isVisible,
  onClose,
  onSave,
  mask,
}) => {
  const styles = useStyles();
  const [cameraAccess, setCameraAccess] = useState<
    PermissionStatus | undefined
  >(undefined);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [photo, setPhoto] = useState<CameraCapturedPicture | undefined>(
    undefined,
  );

  const [cameraType] = useState(CameraType.back);
  const [flashMode, setFlashMode] = useState<FlashMode>(FlashMode.off);
  const cameraRef = useRef<ExpoCamera>(null);

  const handleStartCameraPress = async () => {
    const { status } = await ExpoCamera.requestCameraPermissionsAsync();
    if (status === PermissionStatus.GRANTED) {
      setCameraAccess(PermissionStatus.GRANTED);
    } else {
      setCameraAccess(PermissionStatus.DENIED);
      logInfo('User denied access to camera');
    }
  };

  const handleTakePicture = async () => {
    const photo = await cameraRef.current!.takePictureAsync();
    setIsPreview(true);
    setPhoto(photo);
  };

  const handleSavePhoto = () => {
    onSave(photo!);
  };

  const handleRetakePhoto = () => {
    setPhoto(undefined);
    setIsPreview(false);
    handleStartCameraPress();
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (isVisible) {
      handleStartCameraPress();
      setIsPreview(false);
      setPhoto(undefined);
    }
  }, [isVisible]);

  const handleToggleFlash = () => {
    if (flashMode === FlashMode.torch) {
      setFlashMode(FlashMode.off);
    } else {
      setFlashMode(FlashMode.torch);
    }
  };

  return (
    <>
      <Portal>
        <Modal
          animationType="slide"
          visible={isVisible && cameraAccess !== undefined}
          presentationStyle={'fullScreen'}
          statusBarTranslucent={true}
        >
          <View style={styles.container}>
            {cameraAccess === PermissionStatus.GRANTED &&
              isPreview &&
              photo && (
                <CameraPreview
                  photo={photo}
                  onSavePhotoPress={handleSavePhoto}
                  onRetakePhotoPress={handleRetakePhoto}
                />
              )}
            {cameraAccess === PermissionStatus.GRANTED &&
              (!isPreview || !photo) && (
                <CameraScreen
                  cameraType={cameraType}
                  cameraRef={cameraRef}
                  flashMode={flashMode}
                  onTakePicturePress={handleTakePicture}
                  onToggleFlashPress={handleToggleFlash}
                  title={title}
                  onPressCancel={handleCancel}
                  mask={mask}
                />
              )}
            {cameraAccess === PermissionStatus.DENIED && (
              <CameraAccessDenied
                onSettingsPress={() => Linking.openSettings()}
                onBackPress={() => {
                  setCameraAccess(PermissionStatus.UNDETERMINED);
                  onClose();
                }}
              />
            )}
          </View>
        </Modal>
      </Portal>
    </>
  );
};

interface CameraProps {
  title: string;
  isVisible: boolean;
  onClose: () => void;
  onSave: (image: CameraCapturedPicture) => void;
  mask?: 'card';
}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
}));
