import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from 'react';
import { Platform, View } from 'react-native';
import {
  OrderMedicationsHandler,
  OrderMedicationsProps,
} from './OrderMedicationsAlertProps';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { GenericBottomSheet } from 'assets/components/generic-bottom-sheet/GenericBottomSheet';
import { Alert } from 'assets/components/alert/Alert';
import { getText } from 'assets/localization/localization';
import { useTheme } from 'react-native-paper';

const OrderMedicationsAlertComponent: ForwardRefRenderFunction<
  OrderMedicationsHandler,
  OrderMedicationsProps
> = (
  { platform = Platform.OS, ...rest }: OrderMedicationsProps,
  ref: ForwardedRef<OrderMedicationsHandler>,
) => {
  const theme = useTheme();

  const modalRef = React.useRef<BaseModalHandler>(null);

  const handleShow = () => {
    modalRef.current?.show();
  };

  const handleHide = () => {
    modalRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <GenericBottomSheet
      ref={modalRef}
      title={getText('select-one-item')}
      height={'25%'}
    >
      <View>
        <Alert
          title={getText('choose-from-the-list')}
          intent="warning"
          style={{ marginBottom: theme.getSpacing(2) }}
          testID="selectOneItemAlert"
        />
      </View>
    </GenericBottomSheet>
  );
};

export const OrderMedicationsAlert = forwardRef<
  OrderMedicationsHandler,
  OrderMedicationsProps
>(OrderMedicationsAlertComponent);
