import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { View } from 'react-native';
import { UseFormReturn } from 'react-hook-form';
import { useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { useResetPasswordState } from './reset-password-store';
import { ChangePasswordForm } from '../register/register-actions';
import { CreatePasswordTestIDs } from '../register/RegisterTestIDs';
import { isPassword } from '@digitalpharmacist/validation-dp';
import { PasswordRules } from 'assets/common/PasswordRules';

export const ChangePassword: FunctionComponent<
  PropsWithChildren<{
    form: UseFormReturn<ChangePasswordForm>;
  }>
> = ({ form }) => {
  const theme = useTheme();
  const { error } = useResetPasswordState();
  const passwordValue = form.watch('newPassword');

  useEffect(() => {
    useResetPasswordState.setState({ error: undefined, status: 'idle' });
  }, []);

  return (
    <View>
      <Text
        style={{
          marginBottom: theme.getSpacing(2),
        }}
      >
        {getText('enter-existing-password')}
      </Text>
      <Form methods={form}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              testID={CreatePasswordTestIDs.currentPasswordInput}
              label={getText('current-password')}
              name="currentPassword"
              rules={{
                required: getText('password-is-required'),
              }}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              testID={CreatePasswordTestIDs.passwordInput}
              label={getText('new-password')}
              name="newPassword"
              rules={{
                required: getText('password-is-required'),
                validate: {
                  value: () => {
                    return isPassword(form.getValues().newPassword)
                      ? true
                      : getText('password-entry-does-not-meet-criteria');
                  },
                },
              }}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              testID={CreatePasswordTestIDs.passwordConfirmInput}
              label={getText('confirm-new-password')}
              name="confirmNewPassword"
              rules={{
                required: getText('password-is-required'),
                validate: {
                  value: () => {
                    return form.getValues().newPassword ===
                      form.getValues().confirmNewPassword
                      ? true
                      : getText('passwords-must-match');
                  },
                },
              }}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Content>
          <PasswordRules passwordValue={passwordValue}></PasswordRules>
        </Form.Content>
      </Form>
    </View>
  );
};
