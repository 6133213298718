import React from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import { CheckIcon } from '../../icons';
import { Text } from '../text';
import Select, { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import { TypeaheadBaseItem, TypeaheadProps, customValuePrefix } from './types';
import { LoadingIndicator } from '../loading-indicator';
import { getText } from '../../localization/localization';

const CustomItem: React.FunctionComponent<{
  onClick: () => void;
  label: string;
  isLast?: boolean;
}> = ({ onClick = () => {}, label = 'Label', isLast = false }) => {
  const theme = useTheme();
  const styles = useStyles();
  const [hoverState, setHoverState] = React.useState(false);

  return (
    <div
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      style={{
        ...styles.options,
        backgroundColor: hoverState ? theme.palette.primary[100] : undefined,
        textAlign: 'left',
        marginTop: -8,
        marginLeft: -12,
        marginRight: -12,
        marginBottom: isLast ? -8 : 2,
        paddingLeft: theme.getSpacing(3),
        paddingRight: theme.getSpacing(3),
        paddingTop: theme.getSpacing(1),
        paddingBottom: theme.getSpacing(1),
      }}
      onClick={onClick}
    >
      <Text
        style={{
          ...styles.optionsLabel,
          ...theme.fonts.medium,
        }}
      >
        {label}
      </Text>
    </div>
  );
};

export const TypeaheadWeb = <T extends string | TypeaheadBaseItem>({
  label,
  options,
  defaultValue,
  emptyValue,
  hintMessage,
  disabled = false,
  multiple = false,
  onChange = (value: T[]) => {},
  onInputChange = (value: any) => {},
  asyncOptions,
  getOptionText = (option) =>
    (option as TypeaheadBaseItem).text ?? (option as string),
  getOptionValue = (option) =>
    (option as TypeaheadBaseItem).value ?? (option as string),
  placeholder,
  disableNoOptionHandler = false,
  useCustomNoOptionsValuePrefix = false,
}: TypeaheadProps<T>) => {
  const theme = useTheme();
  const styles = useStyles();
  // checks
  if (options && asyncOptions)
    throw Error(
      'Typeahead can not implement both [options] and [asyncOptions]!',
    );
  const asyncSelectRef = React.useRef(null);
  const staticSelectRef = React.useRef(null);
  const classNamePrefix = 'typeahead-web-select';

  const selectStyles: StylesConfig<T, true> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: theme.palette.white,
      borderRadius: theme.roundness,
      minHeight: 56,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen
        ? ('rotate(180deg)' as any)
        : null,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      ...theme.fonts.medium,
      padding: '6px 12px',
      paddingLeft: theme.getSpacing(3),
      paddingRight: theme.getSpacing(3),
      paddingTop: theme.getSpacing(1),
      paddingBottom: theme.getSpacing(1),
    }),
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        ...theme.fonts.medium,
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        ...theme.fonts.medium,
        backgroundColor: theme.palette.primary[50],
        borderRadius: theme.roundness,
        padding: '3px 9px',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      ...theme.fonts.medium,
      color: theme.palette.primary[700],
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: theme.palette.primary[700],
      borderRadius: theme.roundness,
    }),
    input: (styles) => ({
      ...styles,
      ...theme.fonts.medium,
    }),
    placeholder: (styles) => ({
      ...styles,
      ...theme.fonts.medium,
      textAlign: 'left',
    }),
  };

  const handleOnChange = (newValue: any) => {
    // TODO: review logic to simplify it
    if (multiple) {
      if (newValue.length !== 0) {
        const isLastItemEmptyItem =
          getOptionValue(newValue[newValue.length - 1]) ===
          getOptionValue(emptyValue ?? ({} as T));

        if (isLastItemEmptyItem) {
          onChange([emptyValue] as T[]);
        } else
          onChange(
            newValue.filter(
              (x: T) =>
                getOptionValue(x) !== getOptionValue(emptyValue ?? ({} as T)),
            ) as T[],
          );
      } else onChange(newValue);
    } else {
      onChange(newValue ? ([newValue] as T[]) : []);
    }
  };

  const noOptionsMessageHandler =
    (selectRef: any) => (noOptionsParams: { inputValue: string }) => (
      <>
        {emptyValue ? (
          <CustomItem
            label={getOptionText(emptyValue as T)}
            onClick={() => {
              handleOnChange([emptyValue] as T[]);
              (selectRef.current as any)?.blur();
            }}
            isLast={!noOptionsParams.inputValue}
          />
        ) : null}

        {noOptionsParams.inputValue ? (
          <CustomItem
            label={`${getText('use')} "${noOptionsParams.inputValue}"`}
            onClick={() => {
              multiple
                ? handleOnChange([
                    ...(
                      (selectRef.current as any)?.state.selectValue as T[]
                    ).filter(
                      (x) =>
                        getOptionValue(x) !==
                        getOptionValue(emptyValue ?? ({} as T)),
                    ),
                    {
                      text: noOptionsParams.inputValue,
                      value: useCustomNoOptionsValuePrefix
                        ? customValuePrefix + noOptionsParams.inputValue
                        : noOptionsParams.inputValue,
                    } as T,
                  ] as T[])
                : handleOnChange({
                    text: noOptionsParams.inputValue,
                    value: useCustomNoOptionsValuePrefix
                      ? customValuePrefix + noOptionsParams.inputValue
                      : noOptionsParams.inputValue,
                  } as T);
              (selectRef.current as any)?.blur();
            }}
            isLast
          />
        ) : null}
        {!emptyValue && !noOptionsParams.inputValue
          ? getText('no-options')
          : null}
      </>
    );

  return (
    <View
      style={{ width: '100%', zIndex: 100 }}
      testID={TypeaheadWebTestIDs.typeaheadId}
    >
      <View>
        {label && (
          <Text style={styles.label} testID={TypeaheadWebTestIDs.label}>
            {label}
          </Text>
        )}
      </View>
      {asyncOptions ? (
        <AsyncSelect
          ref={asyncSelectRef}
          menuPortalTarget={document.body}
          classNamePrefix={classNamePrefix}
          menuPlacement="auto"
          controlShouldRenderValue
          isClearable
          aria-label={getText('select')}
          placeholder={placeholder || hintMessage}
          isDisabled={disabled}
          isMulti={multiple ? true : undefined}
          value={
            multiple
              ? defaultValue
              : defaultValue && defaultValue.length > 0
              ? defaultValue[0]
              : null
          }
          noOptionsMessage={
            disableNoOptionHandler
              ? () => null
              : noOptionsMessageHandler(asyncSelectRef)
          }
          onInputChange={onInputChange}
          getOptionLabel={(option) => getOptionText(option as T)}
          getOptionValue={(option) => getOptionValue(option as T)}
          loadOptions={async (newValue) => {
            const promisedOptions = await asyncOptions(newValue);
            return [emptyValue]
              .concat(promisedOptions)
              .filter((x) => x) as any[];
          }}
          components={{
            IndicatorSeparator: () => null,
            LoadingIndicator: () => <LoadingIndicator size={20} />,
          }}
          onChange={handleOnChange}
          styles={selectStyles}
        />
      ) : (
        <Select
          ref={staticSelectRef}
          menuPortalTarget={document.body}
          classNamePrefix={classNamePrefix}
          menuPlacement="auto"
          isSearchable
          isClearable
          placeholder={placeholder || hintMessage}
          aria-label={getText('select')}
          isMulti={multiple ? true : undefined}
          isDisabled={disabled}
          value={
            multiple
              ? defaultValue
              : defaultValue && defaultValue.length > 0
              ? defaultValue[0]
              : null
          }
          onInputChange={onInputChange}
          components={{
            IndicatorSeparator: () => null,
            LoadingIndicator: () => <LoadingIndicator size={20} />,
          }}
          getOptionLabel={(option) => getOptionText(option as T)}
          getOptionValue={(option) => getOptionValue(option as T)}
          options={
            [emptyValue]
              .concat(options)
              .filter((x) => x)
              .map((option) => {
                const isSelected = defaultValue
                  ?.map((sValue) => getOptionValue(sValue))
                  .includes(getOptionValue(option as T));
                return {
                  ...(option as any),
                  label: (
                    <View style={styles.options}>
                      <Text
                        style={{
                          ...styles.optionsLabel,
                        }}
                      >
                        {getOptionText(option as T)}
                      </Text>
                      {isSelected && (
                        <View style={styles.checkIcon}>
                          <CheckIcon size={20} color={theme.colors.primary} />
                        </View>
                      )}
                    </View>
                  ),
                };
              }) as any[]
          }
          noOptionsMessage={noOptionsMessageHandler(staticSelectRef)}
          onChange={handleOnChange}
          styles={selectStyles}
        />
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(1),
  },
  options: {
    flexDirection: 'row',
  },
  optionsLabel: {
    flex: 9,
  },
  checkIcon: {
    flex: 1,
  },
}));

export const TypeaheadWebTestIDs = {
  label: 'typeahead-web-label',
  typeaheadId: 'typeahead-web-id',
};
