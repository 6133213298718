import * as React from 'react';
import { Linking, Platform, StyleProp, ViewStyle } from 'react-native';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import { GoogleIcon } from 'assets/icons';
import { makeStyles } from 'assets/theme';
import { useLoginState } from '../../screens/login/login-store';
import {
  DOMAIN,
  GOOGLE_SSO_CLIENT_ID,
  COGNITO_GOOGLE_SSO_URL,
} from '../../common/constants';
import { useAppStateStore } from '../../store/app-store';

export const GoogleSignInButton: React.FunctionComponent<
  GoogleSignInButtonProps
> = ({ text = getText('google-signup'), style = {} }) => {
  const styles = useStyles();
  const { status } = useLoginState();
  const slug = useAppStateStore((x) => x.slug);

  const handleGoogleLogin = async () => {
    let redirectUri = 'https://' + DOMAIN + '/' + slug + '/intro';
    const responseType = '&response_type=TOKEN&client_id=';
    const scope =
      '&scope=email openid phone profile aws.cognito.signin.user.admin';
    if (Platform.OS === 'web') {
      redirectUri = window.location.origin + '/' + slug + '/intro';
      // If on web, we need to be able to open in the same tab instead of a new tab
      // Linking.openURL does not allow this, so we need to use window.open
      window.open(
        COGNITO_GOOGLE_SSO_URL +
          redirectUri +
          responseType +
          GOOGLE_SSO_CLIENT_ID +
          scope,
        '_self',
      );
    } else {
      Linking.openURL(
        COGNITO_GOOGLE_SSO_URL +
          redirectUri +
          responseType +
          GOOGLE_SSO_CLIENT_ID +
          scope,
      );
    }
  };

  return (
    <Button
      icon={GoogleIcon}
      disabled={status === 'loading'}
      onPress={handleGoogleLogin}
      hierarchy={'secondary-gray'}
      logger={{ id: 'google-login-button' }}
      style={[styles.button, style]}
    >
      {text}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(0.5),
    paddingBottom: theme.getSpacing(0.5),
  },
}));

interface GoogleSignInButtonProps {
  text?: string;
  style?: StyleProp<ViewStyle>;
}
