import { LoadingIndicator } from 'assets/components/loading-indicator';
import { FlatList, View } from 'react-native';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import {
  RecordUnderCareRequestDto,
  RecordUnderCareRequestStatus,
  UpdateRecordUnderCareRequestDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { respondToRequestUnderCare } from '../../patient-actions';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  ForwardRefRenderFunction,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';

export const CaregiverApproval: ForwardRefRenderFunction<
  BaseModalHandler,
  CaregiverApprovalModalProps
> = (
  props: CaregiverApprovalModalProps,
  ref: ForwardedRef<BaseModalHandler>,
) => {
  const theme = useTheme();
  const styles = useStyles();
  const caregiverRequestModalRef = React.useRef<BaseModalHandler>(null);

  const handleShow = () => {
    caregiverRequestModalRef.current?.show();
  };

  const handleHide = () => {
    caregiverRequestModalRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const respondToCaregiverAgreement = async (
    isApproved: boolean,
    request?: RecordUnderCareRequestDto,
  ) => {
    if (!request) return;
    const updateRecordUnderCareRequestDto: UpdateRecordUnderCareRequestDto = {
      status: isApproved
        ? RecordUnderCareRequestStatus.Approved
        : RecordUnderCareRequestStatus.Denied,
    };
    await respondToRequestUnderCare(
      request.id,
      updateRecordUnderCareRequestDto,
    );
    handleHide();
  };

  return (
    <>
      <GenericModal
        buttons={[
          {
            onPress: () => respondToCaregiverAgreement(true, props.request),
            logger: { id: 'approve-caregiver-request-button' },
            text: getText('authorize'),
            hierarchy: 'primary',
          },
          {
            text: getText('decline'),
            hierarchy: 'destructive',
            onPress: () => respondToCaregiverAgreement(false, props.request),
            logger: { id: 'decline-caregiver-request-button' },
          },
        ]}
        title={getText('caregiver-approval-agreement')}
        ref={caregiverRequestModalRef}
        isScrollable={true}
      >
        {props.request ? (
          <View>
            <View style={styles.contentAlignmentCenter}>
              <Text style={styles.modalText}>
                {getText('puc-permission-access-to-records', {
                  firstName: props.request.caregiver_first_name,
                  lastName: props.request.caregiver_last_name,
                })}
              </Text>
              <View style={styles.lineStyle}>
                <Text style={styles.listItemTitleMobile}>
                  {getText('if-you-authorize-person')}
                </Text>
              </View>
              <View style={styles.leftSpacing}>
                <FlatList
                  data={[
                    { key: getText('manage-prescriptions') },
                    { key: getText('order-refills-prescriptions') },
                    { key: getText('pick-up-prescriptions') },
                    {
                      key: getText('discuss-care', {
                        pharmacyName: props.pharmacyName,
                      }),
                    },
                    {
                      key: getText('manage-health'),
                    },
                  ]}
                  renderItem={({ item }) => {
                    return (
                      <View style={{ marginBottom: 6 }}>
                        <Text
                          style={styles.modalText}
                        >{`\u2022 ${item.key}`}</Text>
                      </View>
                    );
                  }}
                />
              </View>
              <Text
                style={[
                  styles.modalHeader,
                  {
                    marginTop: theme.getSpacing(2),
                    fontFamily: theme.fonts.medium.fontFamily,
                  },
                ]}
              >
                {getText('access-validity')}
              </Text>
              <Text style={styles.modalText}>
                {getText('revoke-access-in-settings')}
              </Text>
            </View>
          </View>
        ) : (
          <LoadingIndicator />
        )}
      </GenericModal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  leftSpacing: {
    marginLeft: theme.getSpacing(1),
    fontFamily: theme.fonts.medium.fontFamily,
  },
  lineStyle: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: '400',
  },
  modalHeader: {
    lineHeight: 28,
    fontWeight: '700',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  listItemTitleMobile: {
    lineHeight: 20,
    fontFamily: 'Lato_700Bold',
    fontSize: 18,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  contentAlignmentCenter: { alignContent: 'center' },
}));

interface CaregiverApprovalModalProps {
  request?: RecordUnderCareRequestDto;
  pharmacyName: string;
}

export const CaregiverApprovalModal = React.forwardRef<
  BaseModalHandler,
  CaregiverApprovalModalProps
>(CaregiverApproval);
