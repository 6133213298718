import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { PRESCRIPTION_API_BASE_URL } from '../../common/constants';
import {
  PatientApi as PrescriptionPatientClient,
  CreateRefillDto,
  RefillDto,
  LocationConfigDto,
  IntegrationType,
  PrescriptionDto,
  CreatePrescriptionDto,
  RxStatus,
  TransferPrescriptionDto,
  CreateTransferPrescriptionDto,
  UpdateAutoFillRequest,
} from '@digitalpharmacist/prescription-service-client-axios';
import { getText } from 'assets/localization/localization';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../../common/axios-default-request-config';
import {
  LocationPatientRecordDto,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import {
  findAllergiesById,
  findConditionsById,
} from '../../components/medical-info/medical-info-actions';
import { noKnownAllergiesValue } from '../../api/medication-service';

export interface IRefillService {
  getPatientPrescriptions(
    location_id: string,
    patient_id: string,
  ): Promise<PrescriptionDto[]>;

  getPatientPrescription(
    id_or_rx: string,
    location_id: string,
    patient_id: string,
  ): Promise<PrescriptionDto>;

  searchPrescriptionsAutomatically(
    location_id: string,
    patient_location_id: string,
    last_name: string,
    dob: string,
    rx_number: string,
  ): Promise<PrescriptionDto>;

  createPatientPrescriptions(
    location_id: string,
    patient_id: string,
    prescription: CreatePrescriptionDto,
  ): Promise<PrescriptionDto>;

  submitPatientRefill(
    location_id: string,
    patient_user_id: string,
    refill: CreateRefillDto,
  ): Promise<RefillDto>;

  getLocationConfig(location_id: string): Promise<LocationConfigDto>;

  isLocationIntegrated(location_id: string): Promise<boolean>;

  getLastRefill(
    location_id: string,
    patient_id: string,
  ): Promise<RefillDto | undefined>;

  getOrderRefill(
    location_id: string,
    patient_id: string,
    order_id: string,
  ): Promise<RefillDto | undefined>;

  hasPatientMissingInfos(
    patient: PatientRecordDto | LocationPatientRecordDto | undefined,
  ): boolean;

  getPatientMedicalInfoText(patientRecord: PatientRecordDto): Promise<string>;

  checkPatientPrescriptions(
    selectedMedications: PrescriptionDto[],
    location_id: string,
    patient_id: string,
  ): Promise<PrescriptionDto[]>;

  submitTransferPrescriptions(
    location_id: string,
    transferPrescriptions: CreateTransferPrescriptionDto,
  ): Promise<TransferPrescriptionDto>;
}

export class RefillService extends BaseApiService implements IRefillService {
  private readonly prescriptionClient: PrescriptionPatientClient;
  public readonly pickupQuestions = [
    {
      text: getText('will-pickup-prescription-today'),
      name: 'pickupPrescriptionToday',
      options: [
        {
          text: getText('yes'),
          value: 'yes',
        },
        {
          text: getText('no'),
          value: 'no',
        },
      ],
    },
    {
      text: getText('will-pickup-prescription-urgently'),
      name: 'pickupPrescriptionUrgently',
      options: [
        {
          text: getText('yes'),
          value: 'yes',
        },
        {
          text: getText('no'),
          value: 'no',
        },
      ],
    },
  ];

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);

    this.prescriptionClient = new PrescriptionPatientClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async createPatientPrescriptions(
    location_id: string,
    patient_id: string,
    prescription: CreatePrescriptionDto,
  ): Promise<PrescriptionDto> {
    const response = await this.prescriptionClient.prescriptionCreateOrUpdate(
      patient_id,
      location_id,
      prescription,
    );

    return response.data;
  }

  async getPatientPrescriptions(
    location_id: string,
    patient_id: string,
  ): Promise<PrescriptionDto[]> {
    const response = await this.prescriptionClient.prescriptionFindAll(
      patient_id,
      location_id,
    );

    return response.data.sort((a, b) =>
      (a.next_refill_date &&
        b.next_refill_date &&
        a.next_refill_date < b.next_refill_date) ||
      a.created_at > b.created_at
        ? 1
        : -1,
    );
  }

  async getPatientPrescription(
    id_or_rx: string,
    location_id: string,
    patient_id: string,
  ): Promise<PrescriptionDto> {
    const response = await this.prescriptionClient.prescriptionFindOne(
      id_or_rx,
      patient_id,
      location_id,
    );

    return response.data;
  }

  async searchPrescriptionsAutomatically(
    location_id: string,
    last_name: string,
    date_of_birth: string,
    rx_number: string,
  ): Promise<PrescriptionDto> {
    const response = await this.prescriptionClient.prescriptionGetByRx(
      rx_number,
      last_name,
      date_of_birth,
      location_id,
    );

    return response.data;
  }

  async checkPatientPrescriptions(
    selectedMedications: PrescriptionDto[],
    location_id: string,
    patient_id: string,
  ): Promise<PrescriptionDto[]> {
    if (!selectedMedications.length) return [];

    const { data } = await this.prescriptionClient.prescriptionCheckStatus(
      patient_id,
      location_id,
      selectedMedications.map((rx) => rx.rx_number),
    );

    return data.filter(
      (prescription) => prescription.rx_status !== RxStatus.Fillable,
    );
  }

  async getLastRefill(
    location_id: string,
    patient_id: string,
  ): Promise<RefillDto | undefined> {
    const response = await this.prescriptionClient.refillsFind(
      location_id,
      [patient_id],
      undefined, // submittedBy
      undefined, // orderId
      1, // limit
      0, // offset
      'created_at:desc',
    );

    if (response.data.length) {
      return response.data[0];
    }
  }

  async getOrderRefill(
    location_id: string,
    patient_id: string,
    order_id: string,
  ): Promise<RefillDto | undefined> {
    const response = await this.prescriptionClient.refillsFind(
      location_id,
      [patient_id],
      undefined, // submittedBy
      order_id,
      undefined, // limit
      0, // offset
      'created_at:desc',
      true, // includeRefillItems
      true, // includePrescriptions
    );

    if (response.data.length) {
      return response.data[0];
    }
  }

  async submitPatientRefill(
    location_id: string,
    patient_id: string,
    refill: CreateRefillDto,
  ) {
    const response = await this.prescriptionClient.refillsCreate(
      patient_id,
      location_id,
      {
        ...refill,
        patient_id: patient_id,
      },
    );
    return response.data;
  }

  async submitTransferPrescriptions(
    location_id: string,
    transferPrescriptions: CreateTransferPrescriptionDto,
  ) {
    const response = await this.prescriptionClient.transferPrescriptionCreate(
      transferPrescriptions.location_patient_record_id,
      location_id,
      transferPrescriptions,
    );

    return response.data;
  }

  async getLocationConfig(location_id: string) {
    const response =
      await this.prescriptionClient.locationConfigFind(location_id);

    return response.data;
  }

  async isLocationIntegrated(location_id: string): Promise<boolean> {
    const response =
      await this.prescriptionClient.locationConfigFind(location_id);

    return (
      response.data.integration_type === IntegrationType.Integrated ||
      response.data.integration_type === IntegrationType.DataFeed
    );
  }

  hasPatientMissingInfos(
    patient: PatientRecordDto | LocationPatientRecordDto | undefined,
  ): boolean {
    if (!patient) return true;

    return !patient.gender || !patient.allergies?.length;
  }

  async requestAutoFill(
    prescription: PrescriptionDto,
    user_id: string,
  ): Promise<UpdateAutoFillRequest> {
    const response =
      await this.prescriptionClient.prescriptionUpdateAutoFillRequest(
        prescription.prescription_id,
        prescription.patient_id,
        prescription.location_id,
        {
          auto_fill_request_status: 'requested',
          user_id: user_id,
        },
      );

    return response.data;
  }

  async getPatientMedicalInfoText(
    patientRecord: PatientRecordDto,
  ): Promise<string> {
    return this.getMedicalInfoText(
      patientRecord.allergies,
      patientRecord.medical_conditions,
      patientRecord.prefers_easy_open_bottle_caps,
    );
  }

  async getRefillPatientMedicalInfoText(refill: RefillDto): Promise<string> {
    return this.getMedicalInfoText(
      refill.patient_allergies,
      refill.patient_medical_conditions,
      refill.patient_prefers_easy_open_bottle_caps,
    );
  }

  async getMedicalInfoText(
    allergies: string[] | undefined,
    medical_conditions: string[] | undefined,
    prefers_easy_open_bottle_caps: boolean | null | undefined,
  ): Promise<string> {
    const allergiesItems = await findAllergiesById(allergies || []);
    const medicationConditionsItems = await findConditionsById(
      medical_conditions || [],
    );

    const text = [];

    if (prefers_easy_open_bottle_caps) {
      text.push(getText('prefers-easy-open-caps'));
    }

    if (
      allergiesItems.filter(
        (allergy) => allergy.value !== noKnownAllergiesValue,
      ).length > 0
    ) {
      text.push(
        getText('allergies-colon') +
          allergiesItems.map((a) => a.text).join(','),
      );
    }

    if (medicationConditionsItems.length > 0) {
      text.push(
        getText('medical-conditions') +
          medicationConditionsItems.map((m) => m.text).join(','),
      );
    }

    return text.join(', ');
  }
}

export default new RefillService(
  PRESCRIPTION_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
