import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'assets/form';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { PatientUnderCareForm } from './patient-actions';
import { AccountStackParamList } from '../AccountNavigation';
import { PatientUnderCareEditDetailsEnum } from './patient-store';
import { getText } from 'assets/localization/localization';
import { Form, InternalScreenContainer } from 'assets/layout';
import { useUserState } from '../../../store/user-store';
import {
  PatientRecordDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import patientService from '../../../api/patient-service';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { useMedicalInfoStore } from './medical-info-store';
import { MedicalInformation } from './components/account-information/MedicalInformation';
import { UseFormReturn } from 'react-hook-form';
import { PersonalInfoMdl } from './components/record-under-care-info/PersonalInfoModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { ampli } from '../../../ampli';

export const MedicalInfo: FunctionComponent<
  PropsWithChildren<MedicalInfoProps>
> = () => {
  const user = useUserState.getState().user;
  const medicalForm = useForm<PatientUnderCareForm>({});
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const [viewMode, switchViewMode] = useState(false);
  const [editDetails, setWhichEditDetails] = useState(
    PatientUnderCareEditDetailsEnum.MedicalInfo,
  );
  const { status, error } = useMedicalInfoStore();
  const editMedicalInfoRef = React.useRef<BaseModalHandler>(null);

  if (!user) {
    throw new Error('No user found');
  }

  const patientRecordId = user.patientRecordId;
  if (!patientRecordId)
    throw new Error('Logged in user has no patient record ID');

  const refreshPatientRecord = async () => {
    const pr = await patientService.findPatientRecord(patientRecordId);
    setPatientRecord(pr);
  };

  useEffect(() => {
    void refreshPatientRecord();
  }, []);

  useEffect(() => {
    if (!patientRecord) return;

    medicalForm.setValue('allergies', patientRecord.allergies);
    medicalForm.setValue(
      'medical_conditions',
      patientRecord.medical_conditions,
    );

    if (patientRecord.prefers_easy_open_bottle_caps != null) {
      medicalForm.setValue(
        'prefers_easy_open_bottle_caps',
        patientRecord.prefers_easy_open_bottle_caps,
      );
    }
  }, [patientRecord]);

  if (!patientRecord) {
    return <LoadingIndicator />;
  }

  const onEditPress = (
    editDetail: PatientUnderCareEditDetailsEnum,
    methods: UseFormReturn<PatientUnderCareForm>,
  ) => {
    methods.clearErrors();
    whichDetailsToEdit(editDetail);
    showModal();
    methods.setValue('medical_conditions', patientRecord.medical_conditions);
    methods.setValue('allergies', patientRecord.allergies);
    if (patientRecord.prefers_easy_open_bottle_caps) {
      methods.setValue(
        'prefers_easy_open_bottle_caps',
        patientRecord.prefers_easy_open_bottle_caps,
      );
    }
    whichDetailsToEdit(editDetail);

    return methods;
  };

  const whichDetailsToEdit = (editType: PatientUnderCareEditDetailsEnum) => {
    setWhichEditDetails(editType);
  };

  // TODO: ajshe clean up these helpers and flow
  const showModal = () => {
    switchViewMode(true);
    editMedicalInfoRef.current?.show();
  };

  const hideModal = () => {
    editMedicalInfoRef.current?.hide();
  };

  const handleSubmit = async () => {
    useMedicalInfoStore.setState({
      status: 'loading',
      error: undefined,
    });
    const { allergies, prefers_easy_open_bottle_caps, medical_conditions } =
      medicalForm.getValues();
    const isFormValid = await medicalForm.trigger(['allergies']);
    if (isFormValid) {
      updatePatientRecord({
        allergies,
        prefers_easy_open_bottle_caps,
        medical_conditions,
      })
        .then(() => {
          useMedicalInfoStore.setState({
            status: 'success',
            error: undefined,
          });
          ampli.medicalInfoEdited({
            allergyInfoSelected: allergies.join(','),
            medicalConditionSelected: medical_conditions.join(','),
            medicalInfoEditedTime: new Date().toISOString(),
            safetyCapSelected: prefers_easy_open_bottle_caps?.toString() ?? '',
          });
        })
        .catch(() => {
          useMedicalInfoStore.setState({
            status: 'error',
            error: { message: getText('error-updating-patient-record') },
          });
        });
      showModal();
      editMedicalInfoRef.current?.hide();
    }
  };

  const updatePatientRecord = async (updatePR: UpdatePatientRecordDto) => {
    setPatientRecord(
      await patientService.updatePatientRecord(patientRecord.id, updatePR),
    );
  };

  return (
    <InternalScreenContainer title={getText('medical-info')} showFooter>
      <Form methods={medicalForm}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <MedicalInformation
          patientRecord={patientRecord}
          onEditPress={() => {
            onEditPress(
              PatientUnderCareEditDetailsEnum.MedicalInfo,
              medicalForm,
            );
          }}
        />
        <PersonalInfoMdl
          ref={editMedicalInfoRef}
          title={getText('medical-info')}
          handleRemovePatientUnderCare={() => {}}
          patientRecord={patientRecord}
          changeViewMode={hideModal}
          viewMode={viewMode}
          handleSave={() => {}}
          onEditPress={onEditPress}
          handleSubmit={() => handleSubmit()}
          whichDetailsToEdit={whichDetailsToEdit}
          editDetails={editDetails}
          form={medicalForm}
          subtitle={getText('personal-info')}
        />
      </Form>
    </InternalScreenContainer>
  );
};

type MedicalInfoProps = NativeStackScreenProps<
  AccountStackParamList,
  'medical-info'
>;
