import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { getText } from 'assets/localization/localization';
import React, { ForwardRefRenderFunction, useEffect } from 'react';
import { MedicationInfo } from './MedicationInfo';
import { useAppStateStore } from '../../store/app-store';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export const MedicationInfoModal: ForwardRefRenderFunction<
  BaseModalHandler,
  MedicationInfoModalProps
> = () => {
  const isFindingDigitalContentOpen = useAppStateStore(
    (s) => s.isFindingDigitalContentOpen,
  );
  const modalRef = React.useRef<BaseModalHandler>(null);

  useEffect(() => {
    if (isFindingDigitalContentOpen) {
      modalRef.current?.show();
    } else {
      modalRef.current?.hide();
    }
  }, [isFindingDigitalContentOpen]);

  const handleModalClose = () => {
    useAppStateStore.setState({
      isFindingDigitalContentOpen: false,
    });
  };

  return (
    <GenericModal
      title={getText('med-info-location')}
      ref={modalRef}
      onClose={handleModalClose}
      buttons={[
        {
          text: getText('close'),
          hierarchy: 'secondary',
          onPress: handleModalClose,
          logger: { id: 'med-info-details-dismiss-button-modal' },
        },
      ]}
    >
      <MedicationInfo />
    </GenericModal>
  );
};
export type MedicationInfoModalProps = {};
