import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTheme } from 'assets/theme';
import { Platform } from 'react-native';

const Tab = createMaterialTopTabNavigator();
export const NavigationTabBar: FunctionComponent<
  PropsWithChildren<NavigationTabsProps>
> = ({ screens, hideHeader = false }) => {
  const theme = useTheme();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarAllowFontScaling: false,
        tabBarIndicatorStyle: {
          backgroundColor: theme.colors.brandedPrimary,
          height: 4,
        },
        tabBarLabelStyle: {
          ...theme.lumistryFonts.text.medium,
          fontWeight: '500',
          textTransform: Platform.OS === 'android' ? 'uppercase' : 'none',
        },
        tabBarItemStyle: {
          //changed the width but this needs to be fixed within a Design system ticket and be a dynamic value
          width: Platform.OS === 'web' ? 135 : undefined,
          display: 'flex',
          flexDirection: 'row',
        },
        tabBarContentContainerStyle: {
          justifyContent: 'center',
        },
        tabBarIndicatorContainerStyle: Platform.OS === 'web' && {
          left: '50%',
          transform: [{ translateX: -135 }],
        },
        tabBarStyle: {
          backgroundColor: theme.palette.white,
          borderBottomColor: theme.palette.gray[200],
          ...(hideHeader ? { display: 'none' } : {}),
        },
        tabBarActiveTintColor: theme.colors.brandedPrimary,
        tabBarInactiveTintColor: theme.palette.gray[500],
      }}
    >
      {screens &&
        screens.map((item, index) => (
          <Tab.Screen
            name={item.name}
            component={item.component}
            key={index}
            options={{
              title: item.title ?? item.name,
              tabBarIcon: item.icon,
              tabBarTestID: item.testID,
            }}
          />
        ))}
    </Tab.Navigator>
  );
};

export interface NavigationTabsProps {
  screens: Screen[];
  hideHeader?: boolean;
}

type Screen = {
  name: string;
  title?: string;
  component?: any;
  testID?: string;
  icon?: FunctionComponent;
};
