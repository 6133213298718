import React, { FunctionComponent, useEffect } from 'react';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { View } from 'react-native';
import { useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useResetPasswordState } from './reset-password-store';
import { ConfirmPasswordForm } from './reset-password-actions';
import { useNavigation } from '@react-navigation/native';
import {
  LoginStackNavigationProp,
  ResetPasswordProps,
} from '../../navigation/LoginNavigation';
import { resetPassword } from './reset-password-actions';
import { ResetPasswordTestIDs } from './ResetPasswordTestIDs';
import { PasswordRules } from 'assets/common/PasswordRules';
import { getText } from 'assets/localization/localization';
import { ScreenContainer, Form } from 'assets/layout';
import { isPassword } from '@digitalpharmacist/validation-dp';

export const ResetPassword: FunctionComponent<ResetPasswordProps> = ({
  route,
}) => {
  const { email, confirmation_code, reset_password_link_id } = route.params;

  const theme = useTheme();
  const navigation = useNavigation<LoginStackNavigationProp>();
  const { status, error, updateData } = useResetPasswordState();
  const methods = useForm<ConfirmPasswordForm>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });
  const passwordValue = methods.watch('password');

  useEffect(() => {
    updateData({ email });
  }, []);

  const handleSubmit = () => {
    void resetPassword(
      {
        ...methods.getValues(),
        email,
        confirmationCode: confirmation_code,
        resetPasswordLinkId: reset_password_link_id,
      },
      navigation,
    );
  };

  const handleGoToLogin = () => {
    navigation.navigate('intro');
  };

  return (
    <ScreenContainer useBottomInset keyboardVerticalOffset={200}>
      <View>
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 30,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(1),
            textAlign: 'center',
          }}
        >
          {getText('create-new-password')}
        </Text>
        <Text
          style={{
            color: theme.palette.gray[600],
            fontSize: 16,
            paddingBottom: theme.getSpacing(2),
            textAlign: 'center',
          }}
        >
          {getText('password-reset-subheading')}
        </Text>
      </View>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              testID={ResetPasswordTestIDs.passwordInput}
              label={getText('new-password')}
              name="password"
              rules={{
                required: getText('password-is-required'),
                validate: {
                  isPassword: (value) => {
                    return isPassword(value)
                      ? true
                      : getText('password-entry-does-not-meet-criteria');
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              testID={ResetPasswordTestIDs.passwordConfirmInput}
              label={getText('confirm-new-password')}
              name="passwordConfirm"
              rules={{
                required: getText('password-is-required'),
                validate: {
                  confirmPasswordMatchesPassword: (value) => {
                    return value === passwordValue
                      ? true
                      : getText('passwords-must-match');
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Content>
          <PasswordRules passwordValue={passwordValue}></PasswordRules>
        </Form.Content>
        <Form.Actions>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === 'loading'}
              logger={{ id: 'reset-password-submit-button' }}
            >
              {getText('save-password')}
            </Button>
          </View>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Button
              onPress={handleGoToLogin}
              hierarchy="tertiary-gray"
              logger={{ id: 'create-password-back-to-login-button' }}
            >
              {getText('go-to-login')}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};
