import React, { FunctionComponent, useMemo } from 'react';
import { View } from 'react-native';
import { Divider } from 'react-native-paper';
import {
  OrderDto,
  OrderItemDto,
  OrderProgressStatus,
} from '@digitalpharmacist/order-service-client-axios';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';

import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text/Text';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon/Icon';
import { ArrowRightIcon, CalendarIcon, InfoIcon } from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';

import refillService from '../../screens/refill/refill-service';
import { formatDate, formatDateFromApi } from '../../common/datetime-utils';
import { OrderProgressStatusBadge } from './OrderProgressStatusBadge';

const MAX_ITEMS_TO_SHOW = 3;

export const OrderCard: FunctionComponent<OrderCardProps> = ({
  order,
  orderProgressStatus,
  onOpenOrderDetails,
  onOpenPrescriptionDetails,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const handleOpenPrescriptionDetails = async (refillItem: OrderItemDto) => {
    const prescription = await refillService.getPatientPrescription(
      refillItem.item_name || 'INVALID',
      order.location_id,
      order.patient_id,
    );

    onOpenPrescriptionDetails(prescription);
  };

  const orderCreationDate = useMemo(
    () => formatDateFromApi(order.created_at),
    [order.updated_at],
  );

  const patientDateOfBirth = useMemo(
    () =>
      order.patient_date_of_birth
        ? formatDate(order.patient_date_of_birth)
        : '',
    [order.patient_date_of_birth],
  );

  const patientFullName = useMemo(
    () =>
      `${order.patient_first_name || ''} ${
        order.patient_last_name || ''
      }`.trim(),
    [order.patient_first_name, order.patient_last_name],
  );

  return (
    <View style={styles.container}>
      <View style={styles.rowSpaceBetween}>
        <View style={styles.rowView}>
          <Icon icon={CalendarIcon} color={theme.palette.gray[500]} size={14} />
          <Text style={styles.dateText}>
            {getText('ordered')} {orderCreationDate}
          </Text>
        </View>
        <View style={styles.rowView}>
          <IconButton
            icon={ArrowRightIcon}
            onPress={onOpenOrderDetails}
            color={theme.palette.gray[500]}
            logger={{ id: 'icon-button-id' }}
            testID="order-details-icon-button"
          />
        </View>
      </View>
      <View>
        <Divider />
      </View>
      <View style={styles.rowView}>
        <Text style={styles.textTitle}>{patientFullName}</Text>
        <Text style={styles.textDetails}> ({patientDateOfBirth})</Text>
      </View>
      {order.items &&
        order.items.slice(0, MAX_ITEMS_TO_SHOW).map((item) => (
          <View key={item.order_item_id}>
            <View style={styles.rowView}>
              <Text style={styles.medsText}>
                {item.item_description}
                <IconButton
                  icon={InfoIcon}
                  color={theme.palette.gray[500]}
                  onPress={() => handleOpenPrescriptionDetails(item)}
                  logger={{ id: 'med-details-icon-button' }}
                  size={19}
                  style={{ height: 18, margin: 0, padding: 0 }}
                />
              </Text>
            </View>
          </View>
        ))}
      {order.items && order.items.length > MAX_ITEMS_TO_SHOW && (
        <Text style={styles.moreMedsText}>
          +{order.items.slice(MAX_ITEMS_TO_SHOW).length} {getText('more')}...
        </Text>
      )}
      {orderProgressStatus && (
        <View style={styles.badgeContainer}>
          <OrderProgressStatusBadge orderProgressStatus={orderProgressStatus} />
        </View>
      )}
    </View>
  );
};

export interface OrderCardProps {
  order: OrderDto;
  orderProgressStatus: OrderProgressStatus | undefined;
  onOpenOrderDetails: () => void;
  onOpenPrescriptionDetails: (prescription: PrescriptionDto) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.white,
    shadowColor: 'rgba(16, 24, 40, 0.1)',
    shadowOffset: {
      width: 1,
      height: 4,
    },
    shadowOpacity: 0.58,
    shadowRadius: 1.0,
    marginBottom: theme.getSpacing(1),
  },
  textTitle: {
    ...theme.fonts.medium,
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
    fontWeight: '700',
    fontSize: 17,
    height: 22,
    color: theme.palette.gray[900],
  },
  textDetails: {
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    fontSize: 17,
    height: 22,
    color: theme.palette.gray[700],
  },
  medsText: {
    fontWeight: '400',
    fontSize: 17,
    color: theme.palette.gray[900],
    flexShrink: 1,
    marginBottom: theme.getSpacing(1),
  },
  dateText: {
    ...theme.fonts.regular,
    marginLeft: theme.getSpacing(0.5),
    fontWeight: '400',
    fontSize: 14,
    height: 17,
    color: theme.palette.gray[500],
  },
  badgeContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
  },
  button: {
    borderRadius: 50,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  moreMedsText: {
    fontWeight: '500',
    fontSize: 14,
    height: 20,
    color: theme.palette.gray[700],
    textTransform: 'lowercase',
  },
}));
