import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Alert } from 'assets/components/alert';
import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text/Text';
import { Form, InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, View } from 'react-native';
import { Divider } from 'react-native-paper';
import { Barcode } from '../../camera/barcode/Barcode';
import { formatDateFromUI } from '../../common/datetime-utils';
import { DateTimeFormat, formatISODateAsString } from '../../common/form-utils';
import { StoreSelector } from '../../components/store-selector';
import { MapSize } from '../../components/store-selector/types';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import { AddMedsStackParamList } from './AddMedicationsNavigation';
import { FindPrescriptionFlowTestIDs } from './AddMedicationsTestIDs';
import {
  ensureLocationPatientRecordExist,
  openAddMedicationManually,
  patientRecordAddPrescription,
  resetAddPrescriptionState,
  setFindMedsScreenAsSeen,
} from './add-medications-actions';
import { useAddMedicationsStore } from './add-medications-store';
import { logError } from 'assets/logging/logger';
import { StackNavigationProp } from '@react-navigation/stack';
import { loadStores } from '../../actions/app-actions';
import isMatch from 'date-fns/isMatch';
import * as validate from '@digitalpharmacist/validation-dp';

export const AddMedicationsIntegrated: FunctionComponent<
  PropsWithChildren<AddMedicationsIntegratedProps>
> = () => {
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const theme = useTheme();
  const styles = useStyles();
  const locationId = useUserState((s) => s.user?.preferredPharmacyLocationId);
  const { stores } = useAppStateStore();
  const { status, patientRecord, error, originated } = useAddMedicationsStore();

  const [isScannerVisible, setIsScannerVisible] = useState(false);
  const [scannedValue, setScannedValue] = useState('');
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();

  const hasError = status === 'error';

  const methods = useForm<FindPrescriptionForm>({
    defaultValues: {
      rxNumber: '',
      lastName: '',
      dateOfBirth: '',
    },
  });

  useEffect(() => {
    if (!patientRecord) return;

    methods.setValue('lastName', patientRecord.last_name);
    methods.setValue(
      'dateOfBirth',
      formatISODateAsString(
        patientRecord.date_of_birth,
        DateTimeFormat.USDateFormat,
      ),
    );
  }, [patientRecord]);

  useEffect(() => {
    if (!stores.length) void loadStores(); // Load stores if not loaded
    if (!locationId) return;

    const selectedStore = stores.find((store) => store.id === locationId);

    setSelectedStore(selectedStore);
  }, [stores, locationId]);

  const handleScan = (value: string) => {
    setScannedValue(value);
    setIsScannerVisible(false);
  };

  const handleOpenScanner = () => {
    setIsScannerVisible(true);
  };

  const handleScannerClose = () => {
    setIsScannerVisible(false);
  };

  const handleSubmit = async () => {
    if (!locationId) {
      return logError(new Error('Missing User preferredPharmacyLocationId'));
    }

    await patientRecordAddPrescription(
      {
        location_id: locationId,
        rx_number: methods.getValues().rxNumber,
        last_name: methods.getValues().lastName,
        date_of_birth: formatDateFromUI(methods.getValues().dateOfBirth || ''),
      },
      navigation,
    );
    methods.reset();
  };

  const handleSkip = async () => {
    const userPatientRecordId = useUserState.getState().user?.patientRecordId;
    const patientRecordId = useAddMedicationsStore.getState().patientRecord?.id;

    if (originated === 'medication') {
      navigation.navigate('app', { screen: 'medications' });
    }

    if (originated === 'onboarding') {
      void setFindMedsScreenAsSeen();
    }

    if (userPatientRecordId === patientRecordId) {
      await ensureLocationPatientRecordExist(userPatientRecordId!!);
    }

    resetAddPrescriptionState();
  };

  const handleAddManually = () => {
    if (!patientRecord) {
      return logError(new Error('PatientRecord missing'));
    }

    void openAddMedicationManually(patientRecord.id, navigation);
  };

  const isNumber = (value: string) => {
    if (!value) return true;

    const isNumericRegex = new RegExp(/^[0-9]+$/);
    if (isNumericRegex.test(value)) return true;

    return getText('rx-number-must-be-numeric');
  };

  return (
    <InternalScreenContainer
      style={styles.root}
      title={getText('find-meds')}
      buttons={[
        {
          hierarchy: 'tertiary',
          testID: FindPrescriptionFlowTestIDs.doThisLaterButton,
          onPress: originated === 'onboarding' ? handleSkip : handleAddManually,
          logger: { id: FindPrescriptionFlowTestIDs.doThisLaterButton },
          text: getText(
            originated === 'onboarding' ? 'do-this-later' : 'add-manually',
          ),
        },
        {
          hierarchy: 'primary',
          onPress: methods.handleSubmit(handleSubmit),
          testID: FindPrescriptionFlowTestIDs.nextButton,
          logger: { id: FindPrescriptionFlowTestIDs.nextButton },
          text: hasError ? getText('try-again') : getText('next'),
        },
      ]}
      scrollToTop={
        !!methods.getFieldState('dateOfBirth').error ||
        !!methods.getFieldState('lastName').error ||
        !!methods.getFieldState('rxNumber').error ||
        hasError
      }
    >
      <ScrollView style={{ flex: 1 }}>
        {hasError && (
          <Alert
            intent={'error'}
            title={getText('unable-to-locate-your-prescription-alert-title')}
            description={error?.message}
          />
        )}
        <Form.Alert
          visible={
            !!methods.getFieldState('dateOfBirth').error ||
            !!methods.getFieldState('lastName').error ||
            !!methods.getFieldState('rxNumber').error
          }
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
        />
        <Text style={styles.textTitle}>
          {getText('retrieve-your-prescriptions')}
        </Text>
        <Divider />
        <Text style={styles.descriptionText}>
          {getText('integrated-prescription-flow-description')}
        </Text>
        <Text style={styles.title}>
          {getText('prescription-information')} *
        </Text>
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <View style={{ marginBottom: theme.getSpacing(1) }}>
                <TextField
                  type="numeric"
                  label={getText('rx-number')}
                  name="rxNumber"
                  handleOpenScanner={handleOpenScanner}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  rules={{
                    required: getText('rx-number-is-required'),
                    validate: {
                      number: isNumber,
                    },
                  }}
                  value={scannedValue}
                  testID={FindPrescriptionFlowTestIDs.rxNumberTextField}
                />
              </View>
              {hasError && (
                <>
                  <View style={{ marginBottom: theme.getSpacing(1) }}>
                    <TextField
                      type="text"
                      label={getText('patient-last-name')}
                      name="lastName"
                      onSubmit={methods.handleSubmit(handleSubmit)}
                      rules={{
                        required: getText('patient-last-name-is-required'),
                      }}
                      testID={FindPrescriptionFlowTestIDs.lastNameTextField}
                    />
                  </View>
                  <View style={{ marginBottom: theme.getSpacing(1) }}>
                    <TextField
                      label={getText('patient-dob')}
                      name="dateOfBirth"
                      testID={FindPrescriptionFlowTestIDs.dobNameTextField}
                      rules={{
                        required: getText('birth-date-is-required'),
                        validate: {
                          validateDOB: (value: string) => {
                            return isMatch(
                              value,
                              DateTimeFormat.DOBDateFormat,
                            ) && validate.isDateOfBirth(value)
                              ? true
                              : getText('date-is-not-valid');
                          },
                        },
                      }}
                      onSubmit={methods.handleSubmit(handleSubmit)}
                      type="date"
                    />
                  </View>
                </>
              )}
            </Form.Column>
          </Form.Row>
          <View>
            <Text style={styles.textTitle}>{getText('location')}</Text>
          </View>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Divider />
          </View>
          <Form.Row>
            <Form.Column>
              <StoreSelector
                options={stores}
                selectedOption={selectedStore}
                onChange={setSelectedStore}
                changeButtonShown={false}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </ScrollView>

      <View style={{ margin: theme.getSpacing(1) }}>
        <Barcode
          onScan={handleScan}
          isVisible={isScannerVisible}
          onClose={handleScannerClose}
        />
      </View>
    </InternalScreenContainer>
  );
};

interface FindPrescriptionForm {
  rxNumber: string;
  lastName: string;
  dateOfBirth: string;
}

export type AddMedicationsIntegratedProps = NativeStackScreenProps<
  AddMedsStackParamList,
  'find-medications'
>;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginTop: theme.getSpacing(3),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(2),
  },
  title: {
    color: theme.palette.gray[900],
    fontWeight: '400',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(1),
  },
  nameText: {
    color: theme.palette.gray[900],
    fontWeight: '700',
    fontSize: 16,
    marginTop: theme.getSpacing(2),
  },
  addressText: {
    color: theme.palette.gray[900],
    fontSize: 16,
  },
  descriptionText: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    marginVertical: theme.getSpacing(1),
  },
  blueText: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.primary[400],
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  container: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(4),
  },
  textTitleCard: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 20,
  },
  textSubtitleCard: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
  },
}));
