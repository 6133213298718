import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text/Text';
import { makeStyles } from 'assets/theme';
import { getText } from 'assets/localization/localization';

export const ErrorOccurredPage: FunctionComponent<
  PropsWithChildren<ErrorOccurredProps>
> = ({ onPress, title, description }) => {
  const styles = useStyles();

  return (
    <View style={styles.screenContainer}>
      <Text style={styles.title}>
        {title ?? getText('error-occurred-title')}
      </Text>
      <Text style={styles.description}>
        {description ?? getText('error-occurred-description')}
      </Text>
      <Button
        hierarchy="primary"
        onPress={onPress}
        testID={'error-occurred-button'}
        logger={{ id: 'error-occurred-button' }}
      >
        {getText('home')}
      </Button>
    </View>
  );
};

export type ErrorOccurredProps = {
  onPress: () => void;
  title?: string;
  description?: string;
};

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '30%',
    marginVertical: theme.getSpacing(2),
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 28,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.gray[900],
    marginVertical: theme.getSpacing(2),
  },
  description: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
  },
}));
