import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useEffect,
} from 'react';
import { GradientView } from 'assets/components/gradient-view';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { LocationIcon } from 'assets/icons';
import { uuid } from 'assets/utils/uuid';
import { IconButton } from 'assets/components/icon-button';
import { StoreSelector } from '../store-selector';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { StoreSelectorHandler } from '../store-selector/types';
import { NavigationItems, NavigationItemsProps } from './NavigationItems';
import { Logo } from '../logo/Logo';
import { NotificationsListWeb } from '../notifications-list';

import { loadStores } from '../../actions/app-actions';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { NotificationBadge } from '../notifications';

export const WebHeader: FunctionComponent<
  PropsWithChildren<WebHeaderProps>
> = ({ gradientId = uuid(), navigation, screens, prefix }) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);
  const storeSelectorRef = useRef<StoreSelectorHandler>(null);
  const { user, updateUser } = useUserState();
  const { stores, notifications } = useAppStateStore();
  const { notificationsBadgeCount } = useAppStateStore();

  const isMultiStore = stores.length > 1;

  // first rendering only
  useEffect(() => {
    if (stores.length < 1) void loadStores();
  }, []);

  const handleOnLocationChanged = (value: PharmacyLocationDto) => {
    void updateUser({ preferredPharmacyLocationId: value.id });
  };

  const handleOnStoreIconPress = () => {
    storeSelectorRef.current?.show();
  };

  return (
    <>
      <GradientView
        contentHeight={88}
        fillGradientStops={[
          { stopColor: theme.colors.gradientStart, offset: 0 },
          { stopColor: theme.colors.gradientEnd, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View style={styles.headerContainer}>
          <TouchableOpacity
            onPress={() => navigation?.navigate('home')}
            style={styles.leftContent}
          >
            <Logo width={222} height={100} />
          </TouchableOpacity>

          {navigation && screens && (
            <NavigationItems
              navigation={navigation}
              screens={screens}
              prefix={prefix}
            />
          )}
          <View style={styles.rightContent}>
            {notificationsBadgeCount > 0 && (
              <NotificationBadge count={notificationsBadgeCount} />
            )}
            <NotificationsListWeb notifications={notifications} />
            {isMultiStore && (
              <IconButton
                icon={LocationIcon}
                logger={{ id: 'locationIconId' }}
                onPress={handleOnStoreIconPress}
                color={theme.palette.white}
              />
            )}
          </View>
        </View>
      </GradientView>
      {isMultiStore && (
        <StoreSelector
          ref={storeSelectorRef}
          options={stores}
          selectedOption={stores.find(
            (x) => x.id === user?.preferredPharmacyLocationId,
          )}
          onChange={handleOnLocationChanged}
          storeInformationShown={false}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
    height: '100%',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  leftContent: {
    alignItems: 'flex-start',
    paddingLeft: theme.getSpacing(2),
    marginRight: theme.getSpacing(4),
  },
  middleContent: {
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(2),
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: 1,
    height: '100%',
  },
  rightContent: {
    position: 'absolute',
    flexDirection: 'row',
    right: theme.getSpacing(2),
    bottom: theme.getSpacing(1.5),
    justifyContent: 'flex-end',
  },
  tabContent: {
    flexDirection: 'row',
    marginLeft: theme.getSpacing(4),
  },
  textStyle: {
    color: theme.palette.white,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '500',
    paddingTop: theme.getSpacing(1),
    marginTop: theme.getSpacing(0.5),
  },
}));

export interface WebHeaderProps extends NavigationItemsProps {
  gradientId?: string;
}

export const NativeHeaderTestIDs = {
  title: 'native-header-title',
  actionButton: 'native-header-action-button',
  backButton: 'native-header-back-button',
};
