import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { Icon } from 'assets/components/icon/Icon';
import { makeStyles } from 'assets/theme';

interface InfoCardProps {
  title: string;
  description: string;
  icon: FunctionComponent;
  size: number;
}

export const InfoCard: FunctionComponent<PropsWithChildren<InfoCardProps>> = ({
  title,
  description,
  icon,
  size,
}) => {
  const styles = useStyles();

  return (
    <>
      <View style={styles.container}>
        <View style={styles.icon}>
          <Icon icon={icon} size={size} />
        </View>
        <View style={styles.textContainer}>
          <View>
            <Text style={styles.title}>{title}</Text>
          </View>
          <View>
            <Text style={styles.description}>{description}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: theme.getSpacing(1),
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 8,
    borderColor: theme.palette.gray[200],
    backgroundColor: theme.palette.yellow[50],
    boxShadow:
      '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
  },
  icon: {
    marginVertical: theme.getSpacing(2),
  },
  textContainer: {
    flexDirection: 'column',
    marginLeft: theme.getSpacing(1),
    flexWrap: 'wrap',
    flex: 1,
    flexGrow: 1,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[900],
    margin: theme.getSpacing(1),
  },
  description: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    margin: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
}));
