import { View } from 'react-native';
import { useMessagesState } from './messages-store';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';

export const MessageCount = (): JSX.Element | null => {
  const count = useMessagesState((state) => state.count);
  const styles = useStyles();

  return count.unread ? (
    <View style={styles.container}>
      <Text style={styles.text}>{count.unread}</Text>
    </View>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.getSpacing(1),
    borderRadius: 50,
    backgroundColor: theme.palette.primary[600],
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },

  text: {
    color: theme.palette.white,
  },
}));
