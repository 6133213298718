import React, {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
} from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import { CheckIcon } from '../../icons';
import { Text } from '../text';
import Select, { SingleValueProps, GroupBase, components } from 'react-select';
import {
  DropDownOption,
  DropdownSelectHandler,
  DropdownSelectProps,
} from './types';

const TypeSingleValueTemplate = ({
  hasValue,
  selectProps,
  data,
  labelInlined,
}: SingleValueProps<DropDownOption, false, GroupBase<DropDownOption>> & {
  labelInlined: boolean;
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.singleValueTemplateContainer}>
      {hasValue && labelInlined && (
        <Text
          ellipsizeMode="tail"
          numberOfLines={1}
          style={styles.secondaryText}
        >
          {selectProps.placeholder}
        </Text>
      )}
      <Text
        style={[
          styles.primaryText,
          !hasValue && { color: theme.palette.gray[500] },
          selectProps.isDisabled && { color: theme.palette.gray[500] },
        ]}
        ellipsizeMode="tail"
        numberOfLines={1}
      >
        {data.label || selectProps.placeholder}
      </Text>
    </View>
  );
};

const DropdownWeb: ForwardRefRenderFunction<
  DropdownSelectHandler,
  PropsWithChildren<DropdownSelectProps>
> = (
  { label, options, value, disabled, placeholder, labelInlined, onChange },
  ref,
) => {
  const styles = useStyles();
  const theme = useTheme();
  const handleOnchange = (option: { label?: string; value: any }) => {
    onChange?.(option.value);
  };

  const inputText = options.find((x) => x.value === value)?.label;

  const listOptions = options.map((option) => ({
    ...option,
    itemLabel: option.label || option.value,
  }));

  function optionsFilter(option: any, searchText: string): boolean {
    if (
      option.data.itemLabel.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {!labelInlined && (
        <View>
          {label && (
            <Text style={styles.label} testID={DropdownSelectWebTestIDs.label}>
              {label}
            </Text>
          )}
        </View>
      )}
      <View testID="dropdown-test-id">
        <Select
          menuPlacement="auto"
          value={options.find((x) => x.value === value) || null}
          isSearchable={false}
          isDisabled={disabled}
          menuPortalTarget={document.body}
          placeholder={placeholder || label}
          aria-label="Select"
          filterOption={optionsFilter}
          styles={{
            menu: (provided) => ({ ...provided }),
            dropdownIndicator: (base, state) => ({
              ...base,
              transition: 'all .2s ease',
              transform: state.selectProps.menuIsOpen
                ? ('rotate(180deg)' as any)
                : null,
            }),
            control: (base) => ({
              ...base,
              borderRadius: theme.roundness,
              fontFamily: theme.fonts.regular.fontFamily,
              color: disabled ? theme.palette.gray[500] : undefined,
              textAlign: 'left',
              boxShadow: 'none',
              height: labelInlined ? 58 : 44,
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused
                ? theme.palette.primary[50]
                : undefined,
            }),
            singleValue: (provided) => ({
              ...provided,
              color: theme.palette.gray[900],
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
            SingleValue: (props) => {
              return (
                <components.SingleValue {...props}>
                  <TypeSingleValueTemplate
                    {...props}
                    labelInlined={labelInlined}
                  />
                </components.SingleValue>
              );
            },
          }}
          options={
            listOptions.map((option) => ({
              ...option,
              label: (
                <View style={styles.options}>
                  <Text style={styles.optionsLabel}>{option.label}</Text>
                  {value === option.value && (
                    <View style={styles.checkIcon}>
                      <CheckIcon size={20} color={theme.colors.primary} />
                    </View>
                  )}
                </View>
              ),
            })) as any
          }
          onChange={(option) => {
            if (!!option) {
              handleOnchange(option);
            }
          }}
        />
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
  options: {
    flexDirection: 'row',
  },
  optionsLabel: {
    flex: 9,
  },
  checkIcon: {
    flex: 1,
  },
  singleValueTemplateContainer: {
    flex: 1,
    justifyContent: 'center',
    gap: theme.getSpacing(0.5),
  },
  primaryText: {
    ...theme.lumistryFonts.label.large,
    color: theme.palette.gray[900],
  },
  secondaryText: {
    ...theme.lumistryFonts.label.xSmall,
    color: theme.palette.gray[500],
  },
}));

export const DropdownSelectWebTestIDs = {
  label: 'dropdown-label',
};

export const DropdownSelectWeb = forwardRef<
  DropdownSelectHandler,
  DropdownSelectProps
>(DropdownWeb);
