import React from 'react';
import { View } from 'react-native';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useTheme, makeStyles } from '../../theme';
import { Text } from '../text';
import {
  RadioButtonGroupInput,
  RadioButtonGroupInputProps,
} from './RadioButtonGroupInput';
import { RadioButtonValue } from './RadioButton';
import { getNestedObjectValue } from '../../utils/common';
import { Icon } from '../icon';
import { AlertTriangleIcon } from '../../icons';

export const RadioButtonGroupField = <
  TValue extends RadioButtonValue = string,
>({
  name,
  rules,
  label,
  hideRequiredAsterisk,
  hintMessage,
  onValueChange,
  ...rest
}: RadioButtonGroupFieldProps<TValue>) => {
  const theme = useTheme();
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('RadioGroup form control must have a parent form context');
  }

  const { control, formState } = formContext;
  const error = getNestedObjectValue(formState.errors, name);

  return (
    <View>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioButtonGroupInput<TValue>
            {...rest}
            value={value}
            onValueChange={(newValue) => {
              onValueChange?.(newValue);
              onChange(newValue);
            }}
            hintMessage={hintMessage}
            label={
              label && rules?.required && !hideRequiredAsterisk
                ? `${label} *`
                : label
            }
          />
        )}
        name={name}
        rules={rules}
      />

      {!!error && (
        <View style={styles.errorContainer}>
          <View style={styles.icon}>
            <Icon
              icon={AlertTriangleIcon}
              color={theme.palette.error[600]}
              size={16}
            />
          </View>
          <Text
            testID={RadioButtonGroupFieldTestIDs.error}
            style={styles.errorMessage}
          >
            {error.message}
          </Text>
        </View>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: theme.getSpacing(1),
  },
  errorMessage: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.error[600],
  },
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
}));

export interface RadioButtonGroupFieldProps<TValue>
  extends Omit<RadioButtonGroupInputProps<TValue>, 'onValueChange'> {
  name: string;
  rules?: RegisterOptions;
  label?: string;
  hideRequiredAsterisk?: boolean;
  hintMessage?: string;
  onValueChange?: (value: TValue) => void;
}

export const RadioButtonGroupFieldTestIDs = {
  error: 'rdb-error',
};
