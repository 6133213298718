import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  PropsWithChildren,
} from 'react';
import { ForwardRefRenderFunction, ForwardedRef } from 'react';
import { Modal, ModalProps } from 'assets/components/modal';
import { GenericModalButtonProps } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from './BaseModal';

export const WebModalView: ForwardRefRenderFunction<
  BaseModalHandler,
  WebModalProps
> = (props: WebModalProps, ref: ForwardedRef<BaseModalHandler>) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleDismissModal = () => {
    setShowModal(false);
    if (props.onClose) props.onClose();
  };

  useImperativeHandle(ref, () => ({
    show: handleShowModal,
    hide: handleDismissModal,
  }));

  return (
    <Modal
      show={showModal}
      title={props.title}
      isScrollable={props.isScrollable}
      handleDismissModal={handleDismissModal}
      showDismissButton={props.showDismissButton}
      leftAlignedButton={props.leftAlignedButton}
      buttons={props.buttons}
      size={props.size}
      {...props.modalProps}
      scrollToTop={props.scrollToTop}
    >
      {props.children}
    </Modal>
  );
};

export type WebModalProps = PropsWithChildren<{
  title: string;
  ref?: ForwardedRef<BaseModalHandler>;
  buttons?: GenericModalButtonProps[];
  isScrollable?: boolean;
  showDismissButton?: boolean;
  scrollToTop?: boolean;
  leftAlignedButton?: GenericModalButtonProps;
  size: 'sm' | 'lg';
  modalProps?: Pick<
    ModalProps,
    'height' | 'contentContainerStyle' | 'scrollViewStyle'
  >;

  onClose?: () => void;
}>;

export const WebModal = forwardRef<BaseModalHandler, WebModalProps>(
  WebModalView,
);
