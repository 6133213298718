import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import WebView from 'react-native-webview';
import { useEffect, useState } from 'react';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { StorageKeys } from '../../../enums/storage-keys';
import { BrightcoveVideoPlayerProp } from './types';
import { generateUrl } from './utils';
import AsyncStorageService from '../../api/async-storage-service';
import { useAppStateStore } from '../../store/app-store';
import { useTheme } from 'assets/theme';

export const BrightcoveVideoPlayer: FunctionComponent<
  BrightcoveVideoPlayerProp
> = (props) => {
  return Platform.OS === 'web' ? (
    <BrightcoveVideoPlayerWeb {...props} />
  ) : (
    <BrightcoveVideoPlayerNative {...props} />
  );
};

export const BrightcoveVideoPlayerNative: FunctionComponent<
  BrightcoveVideoPlayerProp
> = ({ videoInfo, style, auth }) => {
  const [tokenValue, setTokenValue] = useState<string | null>();
  const slug = useAppStateStore((state) => state.slug);
  const theme = useTheme();

  useEffect(() => {
    if (auth)
      void (async () => {
        const value = await AsyncStorageService.getItem(
          StorageKeys.AccessToken,
        );
        setTokenValue(value);
      })();
  }, [auth]);

  const render = () => {
    const loadingComponent = <LoadingIndicator />;
    if (!videoInfo.videoId) return loadingComponent;

    if (videoInfo.videoId && auth && !tokenValue) return loadingComponent;

    if (!slug) return;
    const src = generateUrl(videoInfo, slug, auth);

    return (
      <View
        style={{
          width: '100%',
          borderRadius: 2,
          borderColor: theme.palette.gray[500],
          borderWidth: 1,
          ...style,
        }}
      >
        <WebView
          style={{
            outline: 'none',
            boxSizing: 'border-box',
            boxShadow: 'none',
          }}
          height="100%"
          width="100%"
          source={{
            uri: src,
          }}
          injectedJavaScriptBeforeContentLoaded={`
              localStorage.setItem("TokenKey", "${StorageKeys.AccessToken}");
              localStorage.setItem("${StorageKeys.AccessToken}", "${tokenValue}");
          `}
          javaScriptEnabled
          originWhitelist={['*']}
          useWebKit={true}
          mediaPlaybackRequiresUserAction={true}
          scrollEnabled
          nestedScrollEnabled
        />
      </View>
    );
  };

  return render();
};

export const BrightcoveVideoPlayerWeb: FunctionComponent<
  BrightcoveVideoPlayerProp
> = ({ videoInfo, style, auth }) => {
  const slug = useAppStateStore((state) => state.slug);
  const theme = useTheme();

  const renderIframe = async () => {
    if (!slug) return;
    const src = generateUrl(videoInfo, slug, auth);
    const iframeContainer = document.getElementById(videoInfo.videoId);
    if (iframeContainer) iframeContainer?.replaceChildren();

    const iframe = document.createElement('iframe');
    iframe.id = `${videoInfo.videoId}-video-frame`; // frame id
    iframe.src = src;
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.frameBorder = '0';
    iframe.allow = 'fullscreen';
    iframeContainer?.appendChild(iframe);

    iframe.contentWindow?.localStorage.setItem(
      'TokenKey',
      StorageKeys.AccessToken,
    );
  };

  useEffect(() => {
    renderIframe();
  }, [videoInfo.videoId]);

  return (
    <View
      style={{
        width: '100%',
        borderRadius: 2,
        borderColor: theme.palette.gray[500],
        borderWidth: 1,
        ...style,
      }}
      nativeID={videoInfo.videoId}
      testID={videoInfo.videoId}
    >
      {!videoInfo.videoId ?? <LoadingIndicator />}
    </View>
  );
};
