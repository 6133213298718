import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import Constants from 'expo-constants';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  Platform,
  RefreshControlProps,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
  useWindowDimensions,
  KeyboardAvoidingView,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { WebFooter } from '../../../../apps/mobile/modules/components/web-footer/WebFooter';
import { getText } from '../../localization/localization';
import { calculateScreenWidth } from '../../utils/screenContainerUtils';

export interface ScreenContainerHandler {
  scrollTo?(
    y?:
      | number
      | {
          x?: number | undefined;
          y?: number | undefined;
          animated?: boolean | undefined;
        },
    x?: number,
    animated?: boolean,
  ): void;
}

export const ScreenContainer = forwardRef<
  ScreenContainerHandler,
  ScreenContainerProps
>(
  (
    {
      children,
      title,
      backgroundColor,
      fullWidth = false,
      style,
      showVersion = true,
      disableScrollView = false,
      useTopInset = false,
      useBottomInset = false,
      refreshControl,
      showFooter = false,
      keyboardVerticalOffset = Constants.statusBarHeight + 25,
    },
    ref,
  ) => {
    const theme = useTheme();
    const scrollViewRef = useRef<ScrollView>(null);
    const styles = useStyles();
    const insets = useSafeAreaInsets();

    const { width } = useWindowDimensions();

    useImperativeHandle(ref, () => ({
      scrollTo: scrollViewRef.current?.scrollTo,
    }));

    const ViewRenderer = disableScrollView ? View : ScrollView;

    const getScreenWidth = () => {
      return Platform.OS !== 'web'
        ? calculateScreenWidth(width, theme.webMaxWidth, fullWidth)
        : calculateScreenWidth(600, width, fullWidth, 40);
    };

    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 }}
        enabled={Platform.OS !== 'web'}
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        <View
          style={[
            {
              backgroundColor: backgroundColor ?? theme.palette.white,
              paddingTop: useTopInset ? insets.top : 0,
              paddingBottom: useBottomInset ? insets.bottom : 0,
            },
            styles.container,
            style,
          ]}
        >
          <ViewRenderer
            style={styles.viewRenderer}
            ref={scrollViewRef}
            contentContainerStyle={styles.contentContainer}
            refreshControl={refreshControl}
          >
            <View
              style={[
                {
                  width: getScreenWidth(),
                },
                styles.content,
              ]}
            >
              {Platform.OS === 'web' && title && (
                <Text style={styles.title}>{title}</Text>
              )}
              {children}
            </View>
            {/* BLUES-5385  hide the footer from all web pages until there is a need for it */}
            {/* {Platform.OS === 'web' && showFooter && (
              <WebFooter
                phone="(888) 699-9803"
                email="help@digitalpharmacist.com"
                fax="(877) 456-2345"
              />
            )} */}
          </ViewRenderer>
        </View>
      </KeyboardAvoidingView>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    height: '100%',
  },
  viewRenderer: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
  },
  content: {
    alignSelf: 'center',
    flex: 1,
    paddingVertical: theme.getSpacing(3),
  },
  versionText: {
    alignSelf: 'center',
    color: '#D3D3D3',
    padding: theme.getSpacing(1),
  },
  title: {
    ...theme.lumistryFonts.label.xxLarge.bold,
    paddingBottom: theme.getSpacing(2),
  },
}));

export interface ScreenContainerProps {
  title?: string;
  backgroundColor?: string;
  fullWidth?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  children?: React.ReactNode;
  showVersion?: boolean;
  disableScrollView?: boolean;
  useTopInset?: boolean;
  useBottomInset?: boolean;
  refreshControl?: React.ReactElement<RefreshControlProps>;
  showFooter?: boolean;
  keyboardVerticalOffset?: number;
}
