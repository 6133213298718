import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const BowlIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, colorSecondary, size, testID }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={color || theme.palette.gray[700]}
        d="m46.993 62.45 2.81 6.622a1.386 1.386 0 0 1-1.275 1.923V71H20.259a1.39 1.39 0 0 1-1.226-2.046l2.76-6.504a17.465 17.465 0 0 1-6.483-3.979c-3.068-2.975-5.015-7.066-5.015-11.566v-9.276c0-.767.622-1.39 1.39-1.39h45.416c.767 0 1.39.623 1.39 1.39v9.276c0 4.5-1.947 8.591-5.015 11.566a17.458 17.458 0 0 1-6.483 3.979Zm-1.837 2.76H23.63l-1.277 3.01h24.08l-1.278-3.01ZM27.21 60.573h2.51c-.563-.66-.875-1.645-.889-3.012h16.666c4.589 0 6.793-2.03 8.41-4.085a13.007 13.007 0 0 0 1.8-6.347H28.833v-3.707H55.71V39.02H13.075v4.402h8.343v3.707h-8.34c.054 3.08 1.217 5.923 3.094 8.194l-.03-.08c1.423 1.342 3.523 2.19 5.276 2.371.349 1.152 1.545 2.264 3.367 2.746a13.8 13.8 0 0 0 2.425.213Z"
      />
      <Path
        fill={colorSecondary || theme.colors.brandedText}
        fillRule="evenodd"
        d="m53.197 25.02-3.776 8.902H38.423l8.528-12.38a5.56 5.56 0 1 1 6.246 3.478Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
