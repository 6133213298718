import React, { FunctionComponent, PropsWithChildren } from 'react';
import { resendPhoneConfirmationCode, verify } from './verification-actions';
import { useNavigation } from '@react-navigation/native';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { useUserState } from '../../store/user-store';
import { formatPhone } from '../../utils';
import { ConfirmationWithVerificationCode } from '../account/patient/components/account-information/ConfirmWithCode';
import { View } from 'react-native';
import { ScreenContainer } from 'assets/layout/screen/ScreenContainer';
import { Text } from 'assets/components/text';
import { useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';

export const PhoneVerification: FunctionComponent<
  PropsWithChildren<PhoneVerificationProps>
> = (props) => {
  const navigation = useNavigation<AuthStackNavigationProp>();
  const theme = useTheme();

  const phone = useUserState((x) => {
    return x.user?.pendingPhoneNumber;
  });
  const formattedPhone = phone ? formatPhone(phone) : '';

  const handleSubmit = async (confirmCode: string) => {
    await verify({ confirmCode }, 'phone', navigation);
  };

  const handleResendCode = async () => {
    await resendPhoneConfirmationCode();
  };

  return (
    <ScreenContainer
      style={{ alignItems: 'center' }}
      keyboardVerticalOffset={200}
    >
      <View
        style={{
          alignItems: 'center',
          marginBottom: theme.getSpacing(2),
          width: '50%',
          alignSelf: 'center',
        }}
      >
        <Text
          style={{
            fontSize: 30,
            marginBottom: theme.getSpacing(2),
            marginTop: theme.getSpacing(3),
            textAlign: 'center',
          }}
        >
          {getText('verify-your-phone')}
        </Text>
      </View>
      <ConfirmationWithVerificationCode
        handleSubmit={handleSubmit}
        handleResendCode={handleResendCode}
        confirmValue={formattedPhone}
      ></ConfirmationWithVerificationCode>
    </ScreenContainer>
  );
};

interface PhoneVerificationProps {}
