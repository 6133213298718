import { OrderProgressStatus } from '@digitalpharmacist/order-service-client-axios';
import { Tag } from 'assets/components/tag';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

export const OrderProgressStatusBadge: FunctionComponent<
  PropsWithChildren<OrderProgressStatusBadgeProps>
> = ({ orderProgressStatus }) => {
  const styles = useStyles();

  const badgeOptionMap: Record<
    OrderProgressStatus,
    { style: StyleProp<ViewStyle>; label: string }
  > = {
    not_started: {
      style: styles.badgeReceived,
      label: getText('order-status-received'),
    },
    started: {
      style: styles.badgeInProgress,
      label: getText('order-tracker-in-progress'),
    },
    filled: {
      style: styles.badgeFilled,
      label: getText('order-tracker-filled'),
    },
    completed: {
      style: styles.badgeCompleted,
      label: getText('order-progress-completed'),
    },
  };

  const badgeOption = badgeOptionMap[orderProgressStatus];

  return <Tag style={badgeOption.style} label={badgeOption.label} />;
};

export interface OrderProgressStatusBadgeProps {
  orderProgressStatus: OrderProgressStatus;
}

const useStyles = makeStyles((theme) => ({
  badgeReceived: {
    color: theme.palette.gray[700],
    backgroundColor: theme.palette.primary[100],
  },
  badgeInProgress: {
    color: theme.palette.gray[700],
    backgroundColor: theme.palette.yellow[100],
  },
  badgeFilled: {
    color: theme.palette.gray[700],
    backgroundColor: theme.palette.success[50],
  },
  badgeCompleted: {
    color: theme.palette.gray[700],
    backgroundColor: theme.palette.gray[200],
  },
}));
