import { FunctionComponent, useRef } from 'react';
import { View } from 'react-native';
import WebView from 'react-native-webview';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { BottomSheet } from 'assets/components/bottom-sheet';
import theme, { getSpacing, makeStyles } from 'assets/theme';
import { IconButton } from 'assets/components/icon-button';
import { CloseIcon } from 'assets/icons';

export const LinkWindowNative: FunctionComponent<LinkWindowProps> = ({
  src,
  show,
  onClose,
}) => {
  const ref = useRef<BottomSheetModal>(null);
  const styles = useStyles();

  if (show) {
    ref.current?.present();
  } else {
    ref.current?.dismiss();
  }

  return (
    <BottomSheet
      bottomSheetRef={ref}
      height="100%"
      contentContainerStyle={{ flex: 1 }}
      hideHandle={false}
      onDismiss={onClose}
      headerContent={
        <View style={styles.headerContent}>
          <View style={styles.headerIconStyle}>
            <IconButton
              icon={CloseIcon}
              onPress={() => onClose()}
              size={20}
              color={theme.palette.gray[500]}
              logger={{
                id: 'bottom-sheet-close-button',
              }}
            />
          </View>
        </View>
      }
    >
      <View
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <WebView
          style={{
            borderTopLeftRadius: getSpacing(1),
            borderTopRightRadius: getSpacing(1),
          }}
          height="100%"
          width="100%"
          source={{
            uri: src,
          }}
        />
      </View>
    </BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContent: {
    justifyContent: 'center',
    marginBottom: theme.getSpacing(4),
  },
  headerIconStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: theme.getSpacing(1),
  },
}));

export interface LinkWindowProps {
  src: string;
  show: boolean;
  onClose: () => void;
}
