import React, { PropsWithChildren } from 'react';
import { DropdownSelectNative } from './DropdownSelectNative';
import { DropdownSelectWeb } from './DropdownSelectWeb';
import { Platform } from 'react-native';
import {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { DropdownSelectProps, DropdownSelectHandler } from './types';

const Dropdown: ForwardRefRenderFunction<
  DropdownSelectHandler,
  PropsWithChildren<DropdownSelectProps>
> = (
  { platform = Platform.OS, ...props },
  ref: ForwardedRef<DropdownSelectHandler>,
) => {
  const dropdownSelectRef = React.useRef<DropdownSelectHandler>(null);

  const handleShow = () => {
    dropdownSelectRef?.current?.show();
  };

  const handleHide = () => {
    dropdownSelectRef?.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const ActualComponent =
    platform === 'web' ? DropdownSelectWeb : DropdownSelectNative;

  return <ActualComponent {...props} />;
};

export const DropdownSelect = forwardRef<
  DropdownSelectHandler,
  DropdownSelectProps
>(Dropdown);
