import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { InternalScreenContainer } from 'assets/layout';
import { Icon } from 'assets/components/icon';
import { AddPersonIcon, CalendarIcon } from 'assets/icons';
import {
  PatientRecordDto,
  RecordUnderCareDto,
  RecordUnderCareRequestDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { CaregiverApprovalModal } from '../../caregiver-approval/CaregiverApprovalModal';
import { ConfirmDeleteModal } from '../../confirm-delete-action/ConfirmDeleteActionModal';
import { formatDateToMMMD } from '../../../../../../common/datetime-utils';
import {
  refreshRecordUnderCareRequestsState,
  revokeByPatientUnderCare,
} from '../../../patient-actions';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { useUserState } from '../../../../../../store/user-store';
import { useAppStateStore } from '../../../../../../store/app-store';
import {
  LinkedAccountsTab,
  usePatientRecordState,
} from '../../../patient-store';
import patientService from '../../../../../../api/patient-service';
import { EmptyStatePage } from '../../../../../../components/empty-state-page/EmptyStatePage';
import { useFocusEffect } from '@react-navigation/native';

export const Caregivers: FunctionComponent = () => {
  const theme = useTheme();
  const styles = useStyles();
  const { recordUnderCareRequests, caregivers } = usePatientRecordState();
  const isWeb = Platform.OS === 'web';
  const { pharmacyName } = useAppStateStore();
  const [isApproved, setIsApproved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useUserState.getState().user;
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const [selectedRequest, setSelectedRequest] =
    useState<RecordUnderCareRequestDto>();
  const [selectedRecordUnderCare, setSelectedRecordUnderCare] =
    useState<RecordUnderCareDto>();
  const caregiverRequestModalRef = React.useRef<BaseModalHandler>(null);
  const caregiverRevokeRequestModalRef = React.useRef<BaseModalHandler>(null);

  if (!user) {
    throw new Error('No user found');
  }

  const patientRecordId = user.patientRecordId;
  if (!patientRecordId)
    throw new Error('Logged in user has no patient record ID');

  const refreshPatientRecord = async () => {
    const pr = await patientService.findPatientRecord(patientRecordId);
    setPatientRecord(pr);
  };

  useEffect(() => {
    void refreshRecordUnderCareRequestsState();
    void refreshPatientRecord();
  }, [isApproved, showModal]);

  useFocusEffect(
    useCallback(() => {
      usePatientRecordState.setState({
        linkedAccountsSelectedTab: LinkedAccountsTab.Caregivers,
      });
    }, []),
  );

  if (!patientRecord) {
    return <LoadingIndicator />;
  }

  const handleShowModal = (record: RecordUnderCareDto) => {
    setSelectedRecordUnderCare(record);
    caregiverRevokeRequestModalRef.current?.show();
  };

  const handleOnPressRevoke = async (recordId: string, id: string) => {
    await revokeByPatientUnderCare(id, recordId);
    handleHide();
  };

  const handleHide = () => {
    caregiverRevokeRequestModalRef.current?.hide();
  };

  const openCaregiverApprovalAgreement = (
    show: boolean,
    request: RecordUnderCareRequestDto,
  ) => {
    setSelectedRequest(request);
    setIsApproved(show);
    caregiverRequestModalRef.current?.show();
  };

  return (
    <InternalScreenContainer showFooter>
      {recordUnderCareRequests.length > 0 || caregivers.length > 0 ? (
        <>
          {caregivers.map((record) => (
            <View style={styles.requestContainer} key={record.id}>
              <View style={styles.requestTextContainer}>
                <Text style={styles.nameText}>
                  {`${record.caregiver_first_name} ${record.caregiver_last_name}`.trim()}{' '}
                </Text>
                <TouchableOpacity
                  style={styles.textContainer}
                  onPress={() => handleShowModal(record)}
                >
                  <Text
                    style={[
                      styles.pressableTextRevoke,
                      styles.leftSpacing,
                      isWeb && styles.pointer,
                    ]}
                  >
                    {getText('revoke-access')}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.rowDirection}>
                <Text style={styles.emailTextStyle}>
                  {record.caregiver_email}
                </Text>
              </View>

              <View style={styles.authorizationContainer}>
                <View style={styles.rightSpacing}>
                  <Icon
                    icon={CalendarIcon}
                    color={theme.palette.gray[500]}
                    size={14}
                  ></Icon>
                </View>
                <Text style={styles.requestAuthorizedStyle}>
                  {getText('authorized-on', {
                    authorizedDate: formatDateToMMMD(record.created_at),
                  })}
                </Text>
              </View>
            </View>
          ))}
          {recordUnderCareRequests.map((request) => (
            <View key={request.id}>
              <View style={styles.requestContainer}>
                <View style={styles.requestTextContainer}>
                  <Text style={styles.nameText}>
                    {`${request.caregiver_first_name} ${request.caregiver_last_name}`.trim()}
                  </Text>
                  <TouchableOpacity
                    onPress={() =>
                      openCaregiverApprovalAgreement(true, request)
                    }
                    style={styles.textContainer}
                  >
                    <Text
                      style={[
                        styles.pressableText,
                        styles.leftSpacing,
                        isWeb && styles.pointer,
                      ]}
                    >
                      {getText('review-request')}
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.rowDirection}>
                  <Text style={styles.emailTextStyle}>
                    {request.caregiver_email}
                  </Text>
                </View>

                <View style={styles.authorizationContainer}>
                  <View style={styles.rightSpacing}>
                    <Icon
                      icon={CalendarIcon}
                      color={theme.palette.warning[500]}
                      size={14}
                    ></Icon>
                  </View>
                  <Text style={styles.requestPendingStyle}>
                    {getText('request-status', {
                      submittedDate: formatDateToMMMD(request.created_at),
                    })}
                  </Text>
                </View>
              </View>
            </View>
          ))}
        </>
      ) : (
        <EmptyStatePage
          icon={AddPersonIcon}
          iconColor={theme.palette.white}
          title={getText('how-to-add-caregiver')}
          description={getText('add-caregiver-details')}
          onPress={() => {}}
        />
      )}

      <CaregiverApprovalModal
        ref={caregiverRequestModalRef}
        pharmacyName={pharmacyName}
        request={selectedRequest}
      />
      <ConfirmDeleteModal
        ref={caregiverRevokeRequestModalRef}
        height={'40%'}
        title={getText('revoke-access') + '?'}
        subtitle={
          selectedRecordUnderCare
            ? getText('revoke-access-for-authorized-caregiver', {
                firstName: selectedRecordUnderCare.caregiver_first_name,
                lastName: selectedRecordUnderCare.caregiver_last_name,
              })
            : ''
        }
        buttons={[
          {
            text: getText('revoke'),
            hierarchy: 'destructive',
            onPress: () => {
              if (selectedRecordUnderCare)
                void handleOnPressRevoke(
                  selectedRecordUnderCare.id,
                  selectedRecordUnderCare.caregiver_id,
                );
            },
            logger: { id: 'revoke-access-modal-send-button' },
          },
          {
            onPress: () => handleHide(),
            logger: { id: 'revoke-access-modal-cancel-button' },
            text: getText('cancel'),
            hierarchy: 'secondary-gray',
          },
        ]}
      />
    </InternalScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  nameText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '700',
    fontFamily: 'Lato_700Bold',
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.error[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: {
    marginLeft: theme.getSpacing(1),
    fontFamily: theme.fonts.medium.fontFamily,
  },

  pointer: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- TODO: this was copied from StoreInformation
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  requestHeaderStyle: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'Lato_700Bold',
    color: theme.palette.gray[900],
  },
  emailTextStyle: {
    color: theme.palette.gray[900],
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
  },
  requestContainer: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  authorizationContainer: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(3),
  },
  rightSpacing: {
    marginRight: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(0.5),
  },
  requestPendingStyle: {
    color: theme.palette.warning[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
  requestAuthorizedStyle: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
  rowDirection: { flexDirection: 'row' },
}));
