import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { ScreenContainer } from '../../../../../../../../packages/assets/layout/screen/ScreenContainer';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { View } from 'react-native';
import * as validate from '@digitalpharmacist/validation-dp';
import {
  DateTimeFormat,
  formatDateStringAsISO,
} from '../../../../../common/form-utils';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import {
  PatientUnderCareForm,
  updatePatientUnderCareForm,
} from '../../patient-actions';
import { usePatientRecordState } from '../../patient-store';
import {
  Gender,
  PatientRecordDto,
  RecordUnderCareDto,
  UpdateAddressDto,
  UpdatePatientRecordDto,
  UpdateRecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { Text } from 'assets/components/text';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import patientService from '../../../../../api/patient-service';
import { UseFormReturn } from 'react-hook-form';
import { Address } from '../FormAddress';
import isMatch from 'date-fns/isMatch';

export enum EditPersonalInformationMode {
  Self = 'self',
  PersonUnderCare = 'person_under_care',
}

export const EditPersonalInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord?: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    relationshipProps?: Array<{ label: string; value: string }>;
    record?: RecordUnderCareDto;
    handleSave: (updatePatientRecord: UpdatePatientRecordDto) => void;
    form: UseFormReturn<PatientUnderCareForm>;
    mode: EditPersonalInformationMode;
    formError?: boolean;
  }>
> = ({
  record,
  patientRecord,
  changeViewMode,
  relationshipProps,
  handleSave,
  form,
  mode,
  formError,
}) => {
  const theme = useTheme();
  const {
    status,
    error,
    patientRecord: currentPatientRecord,
  } = usePatientRecordState();

  const handleSubmit = async (values: PatientUnderCareForm) => {
    const { relationship } = values;
    updatePatientUnderCareForm(form.getValues());
    const updatedFormValues = form.getValues();
    changeViewMode(false);
    if (record?.relationship !== relationship) {
      const updateRecordUnderCare: UpdateRecordUnderCareDto = {
        relationship: relationship,
      };
      if (patientRecord && record)
        await patientService.recordUnderCareUpdate(
          patientRecord.id,
          record.id,
          updateRecordUnderCare,
        );
    }
    const addressDto: UpdateAddressDto = {};
    if (updatedFormValues.address1 !== '')
      addressDto.address1 = updatedFormValues.address1;
    if (updatedFormValues.address2 !== '')
      addressDto.address2 = updatedFormValues.address2;
    if (updatedFormValues.city !== '') addressDto.city = updatedFormValues.city;
    if (updatedFormValues.state !== '')
      addressDto.state = updatedFormValues.state;
    if (updatedFormValues.postal_code !== '')
      addressDto.postal_code = updatedFormValues.postal_code;
    if (
      updatedFormValues.address1 !== '' &&
      updatedFormValues.city !== '' &&
      updatedFormValues.state !== '' &&
      updatedFormValues.postal_code !== '' &&
      updatedFormValues.country !== ''
    ) {
      addressDto.country = updatedFormValues.country;
    }
    const isAddressComplete =
      updatedFormValues.address1 &&
      updatedFormValues.city &&
      updatedFormValues.country &&
      updatedFormValues.state &&
      updatedFormValues.postal_code
        ? true
        : false;
    const updatePatientRecord: UpdatePatientRecordDto = {
      first_name: updatedFormValues.first_name,
      last_name: updatedFormValues.last_name,
      date_of_birth: formatDateStringAsISO(
        updatedFormValues.date_of_birth,
        DateTimeFormat.USDateFormat,
      ),
      gender: updatedFormValues.gender,
      address: isAddressComplete ? addressDto : null,
    };

    handleSave(updatePatientRecord);
  };

  return (
    <View>
      <Form methods={form}>
        <Form.Alert
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
          visible={!!formError}
        />
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('first-name')}
              name="first_name"
              rules={{
                required: getText('first-name-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('first-name-is-not-valid');
                  },
                },
              }}
              testID={'first-name-edit-user'}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('last-name')}
              name="last_name"
              rules={{
                required: getText('last-name-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('last-name-is-not-valid');
                  },
                },
              }}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              testID={'last-name-edit-user'}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('birth-date')}
              name="date_of_birth"
              rules={{
                required: getText('birth-date-is-required'),
                validate: {
                  validateDOB: (value: string) => {
                    return isMatch(value, DateTimeFormat.DOBDateFormat) &&
                      validate.isDateOfBirth(value)
                      ? true
                      : getText('date-is-not-valid');
                  },
                },
              }}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              type="date"
              testID={'birth-date-edit-user'}
            />
          </Form.Column>
        </Form.Row>
        {record && relationshipProps && relationshipProps.length && (
          <Form.Row>
            <Form.Column>
              <DropdownSelectField
                options={relationshipProps}
                name="relationship"
                testID="relationship"
                label={getText('relation-to-primary-user') + '*'}
                labelInlined={true}
              ></DropdownSelectField>
            </Form.Column>
          </Form.Row>
        )}
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
            <Text> {getText('gender-on-insurance') + ' *'}</Text>
            <RadioButtonGroupField
              name="gender"
              testID={'gender-test-id'}
              rules={{
                required: getText('gender-is-required'),
              }}
              values={[
                {
                  text: getText('male'),
                  value: Gender.Male,
                },
                {
                  text: getText('female'),
                  value: Gender.Female,
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Address
          form={form}
          autoFillConfig={
            currentPatientRecord?.address &&
            mode === EditPersonalInformationMode.PersonUnderCare
              ? {
                  label: getText('auto-fill-my-address'),
                  address: currentPatientRecord.address,
                }
              : undefined
          }
        ></Address>
      </Form>
    </View>
  );
};
