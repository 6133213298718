import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import type { StackNavigationProp } from '@react-navigation/stack';
import {
  StackHeaderProps,
  createStackNavigator,
} from '@react-navigation/stack';
import { GradientView } from 'assets/components/gradient-view';
import { NativeHeader } from 'assets/components/native-header';
import { ChevronLeftIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { useTheme } from 'assets/theme';
import { GestureResponderEvent } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { IconButton, Text } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Logo } from '../components/logo/Logo';
import {
  AddMedicationsHeader,
  AddMedicationsNavigation,
} from '../screens/add-medications/AddMedicationsNavigation';
import { PaperlessEnrollment } from '../screens/paperless-enrollment/PaperlessEnrollment';
import { RefillGuestNavigation } from '../screens/refill-guest/RefillGuestNavigation';
import { RegisterDetails } from '../screens/register/RegisterDetails';
import { RegisterStore } from '../screens/register/RegisterStore';
import { RegisterStoreDetails } from '../screens/register/RegisterStoreDetails';
import { PhoneVerification } from '../screens/verify/PhoneVerification';
import {
  hasSeenFindMedsScreen,
  useUserState,
  userHasPreferredPharmacy,
  userNeedsDetails,
} from '../store/user-store';
import { useAppRedirectionContext } from '../providers/app-redirection/hooks';
import { LoginNavigation } from './LoginNavigation';
import { usePatientRecordState } from '../screens/account/patient/patient-store';

const AuthStack = createStackNavigator<AuthStackParamList>();

export const AuthNavigation: FunctionComponent = () => {
  const user = useUserState((x) => x.user);

  const userHasToSetPreferredPharmacyLocation = useUserState((x) => {
    return userHasPreferredPharmacy(x.user);
  });
  const userNeedsDetailsSet = useUserState((x) => {
    return userNeedsDetails(x.user);
  });

  const userHasSeenFindMedsScreen = useUserState((x) => {
    return hasSeenFindMedsScreen(x.user);
  });

  const { setAppRedirectionConfig } = useAppRedirectionContext();

  useEffect(() => {
    setAppRedirectionConfig({
      arePublicRoutesLoaded: true,
      arePrivateRoutesLoaded: false,
    });

    return () => setAppRedirectionConfig({ arePublicRoutesLoaded: false });
  }, []);

  if (!user) {
    return <LoginNavigation />;
  } else {
    return (
      <AuthStack.Navigator
        screenOptions={{
          headerShown: true,
          header: AuthNavigationHeader,
          title: getText('app'), // fallback title
        }}
      >
        {userNeedsDetailsSet && (
          <>
            <AuthStack.Screen
              name="register-details"
              component={RegisterDetails}
              options={{ title: getText('register-details') }}
            />
            <AuthStack.Screen
              name="phone-verification"
              component={PhoneVerification}
              options={{
                title: getText('phone-verification'),
                header: (props) => (
                  <AuthNavigationHeader
                    showBack={true}
                    {...props}
                    routeName="register-details"
                  />
                ),
              }}
            />
          </>
        )}
        {userHasToSetPreferredPharmacyLocation && (
          <>
            <AuthStack.Screen
              name="patient-stores"
              component={RegisterStore}
              options={{
                title: getText('patient-stores'),
                cardStyle: { flex: 1 },
                header: AccountSetupNavigationHeader,
                headerShown: true,
              }}
            />
            <AuthStack.Screen
              name="patient-pharmacy-stores"
              component={RegisterStoreDetails}
              options={{
                title: getText('patient-pharmacy-stores'),
                presentation: 'modal',
                headerShown: false,
              }}
            />
          </>
        )}
        {!userHasToSetPreferredPharmacyLocation && !userNeedsDetailsSet && (
          <AuthStack.Screen
            name="paperless-enrollment"
            component={PaperlessEnrollment}
            options={{
              title: getText('paperless-screen-title'),
              header: AccountSetupNavigationHeader,
            }}
          />
        )}
        {!userHasSeenFindMedsScreen && (
          <AuthStack.Screen
            name="add-medications-onboarding"
            component={AddMedicationsNavigation}
            options={{
              title: getText('add-prescription'),
              header: AddMedicationsHeader,
            }}
          />
        )}
      </AuthStack.Navigator>
    );
  }
};

export type AuthStackParamList = {
  'refill-guest': undefined;
  'register-details': undefined;
  'phone-verification': undefined;
  'privacy-policy': undefined;
  'terms-of-service': undefined;
  'patient-stores': undefined;
  'patient-pharmacy-stores': undefined;
  'paperless-enrollment': undefined;
  'add-prescription-onboarding': undefined;
  'add-medications-onboarding': undefined;
};

export type AuthStackNavigationProp = StackNavigationProp<AuthStackParamList>;

export interface AuthNavigationProps extends StackHeaderProps {
  showBack?: boolean;
  routeName?: string;
}

export const AuthNavigationHeader: FunctionComponent<
  PropsWithChildren<AuthNavigationProps>
> = (props) => {
  const theme = useTheme();
  const routeName = props.route.name as keyof AuthStackParamList;

  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  if (routeName === 'refill-guest') {
    return <></>;
  }

  return (
    <>
      <SafeAreaView
        style={{
          position: 'absolute',
          left: 0,
          zIndex: 1,
        }}
      >
        {props.showBack && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onPress={() =>
              props.navigation.navigate(
                props.routeName as keyof AuthStackParamList,
              )
            }
          >
            <IconButton
              icon={ChevronLeftIcon}
              color={theme.colors.brandedPrimary}
              size={24}
            />

            <Text
              style={{
                color: theme.colors.brandedPrimary,
                fontSize: 16,
                fontWeight: '500',
                lineHeight: 24,
              }}
            >
              {getText('back')}
            </Text>
          </TouchableOpacity>
        )}
      </SafeAreaView>

      <GradientView
        contentStyle={{ justifyContent: 'center', alignItems: 'center' }}
        fillGradientStops={[
          { stopColor: '#F5F9FD', offset: 0 },
          { stopColor: '#F5F9FD', offset: 100 },
        ]}
        contentHeight={200}
      >
        <SafeAreaView>
          <Logo width={222} height={100} isOnDark={false} />
        </SafeAreaView>
      </GradientView>
    </>
  );
};

export interface AccountSetupNavigationProps extends StackHeaderProps {
  showBack?: boolean;
}

export const AccountSetupNavigationHeader: FunctionComponent<
  AccountSetupNavigationProps
> = (props) => {
  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  return (
    <NativeHeader
      title={getText('account-setup')}
      onBack={handleBack}
      showBack={props.showBack}
    />
  );
};
