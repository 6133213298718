import {TranslationMapping} from "../localization";

const mapping: TranslationMapping = {
  "complete-checklist-items-in-order": "Debes completar los elementos de la lista de verificación en orden",
  "answer-following-questions": "Responda las siguientes preguntas sobre su sitio web orientado al paciente.",
  "build-replace-website": "Nos gustaría que Lumistry creara un sitio web para reemplazar el mío actual.",
  "build-new-website": "Nos gustaría que Lumistry construyera un sitio web porque no tenemos uno.",
  "no-build-existing-website": "No queremos que Lumistry construya un sitio web porque ya tenemos uno.",
  "no-build-no-existing-website": "No queremos que Lumistry construya un sitio web y no tenemos uno.",
  "brand-color-required": "Se requiere color de marca.",
  "website-access": "Acceso al sitio web",
  "website-featured-services": "Servicios destacados del sitio web",
  "select-6-services": "Seleccione hasta 6 para resaltar en su sitio web.",
  "selected-services-tooltip-text": "Los servicios seleccionados se mostrarán en la navegación del sitio web y tendrán secciones dedicadas en el sitio web.",
  "pharmacy-service-details": "Detalles del servicio de farmacia",
  "indicate-type-of-compounding":"Indique el tipo de composición que ofrece:",

  "website-you-like-to-use": "Tiene un dominio de sitio web que le gustaría utilizar?",
  "website-how-to-access-domain": "Sabes cómo acceder a tu dominio?",
  "website-domain-first-tooltip": "Un dominio de sitio web es un nombre especial que se escribe en la computadora para acceder a un sitio web específico en Internet. Un ejemplo de dominio de sitio web (o la dirección del sitio web) sería www.yourpharmacy.com",
  "website-domain-second-tooltip": "Tiene acceso al nombre de usuario y la contraseña del registrador de dominio que posee el dominio.",
  "website-completion-gratitude": "Gracias por completar la configuración del sitio web. Un miembro del equipo de Lumistry se comunicará con usted para ayudarlo a completar el resto del proceso del sitio web.",
  "publish-website-need-permission": "Para publicar su nuevo sitio web en un dominio existente, Lumistry necesita permiso para iniciar sesión en el host de su dominio en su nombre.",
  "give-lumistry-permission": "Doy permiso a Lumistry para acceder al siguiente dominio.",
  "please-provide-domain-details": "Proporcione los siguientes detalles sobre su dominio",
  "domain-name": "Nombre de dominio",
  "domain-host": "Host del dominio",
  "login-user-name": "Nombre de usuario para iniciar sesión",
  "login-password": "Contraseña de inicio de sesión",
  "checklist-app-config-submission-title": "Patient App",
  "checklist-app-config-submission-message": "Are you sure you are ready to submit your patient app to be published? ",
  "checklist-app-config-submission-description": "Once submitted, your patient app will have to be approved by the App Store and Google play before it can be listed. Once approved, any changes to branding, such as logos or color, must be re-approved by the App Store. ",
  "checklist-app-config-app-dev-account":"Apple Dev Account",
  "checklist-app-config-app-dev-account-desc": "An account will be created on your behalf using the following info:",
  "checklist-app-config-name":"App name",
  "checklist-app-config-name-required":"Required",
  "checklist-app-config-name-hint": "Appears in the mobile app (36 character limit)",
  "checklist-app-config-name-max-character": "Max limit 36 character",
  "checklist-app-config-dev-account": "Dev Account",
  "checklist-app-config-dev-account-description":"An account will be created on your behalf using the following info:",
  "checklist-app-config-duns-placeholder":"Select D-U-N-S",
  "checklist-app-config-duns-required":"D-U-N-S is required",
  "upload-a-pdf": "Subir un pdf...",
  "checklist-app-config-duns-tooltip": "Select the D-U-N-S you’d like associated with your patient app. This is often your primary location.",
  "checklist-app-config-market-name": "App marketplace name",
  "checklist-app-config-market-name-description": "Appears in Apple App Store and Google Play (30 character limit)",
  "checklist-app-config-market-name-max-characters": "Max limit 30 character",
  "checklist-app-config-market-name-not-valid":"Not valid",
  "checklist-app-config-market-name-required": "Required",
  "checklist-app-config-title": "App Settings",
  "checklist-app-config-id-title":"Driver’s license or government I.D.",
  "checklist-app-config-id-tooltip":"ID is used to gain approval for your App from App Store & Google Play",
  "checklist-app-config-website-url":"Website URL",
  "checklist-app-config-website-url-required": "Website URL is required",
  "checklist-app-config-website-url-not-valid": "Website URL is not valid",
  "select-brand-color": "Seleccione el color principal de su marca",
  "specs-first-item": "Límite de tamaño de 10 MB",
  "image-is-required": "Se requiere image",
  "valid-url": "Por favor introduzca un URL válido",
  "specs-second-item": "Formato PNG o SVG",
  "specs-third-item": "Fondo transparente",
  "brand-color": "Color de la marca",
 "white-text-readable": "El texto blanco debe ser legible encima de este color. Los colores brillantes están restringidos.",
 "logo-and-icon": "Logotipo e icono",
 "high-quality-graphics": "Los gráficos de alta calidad son clave para una aplicación y un sitio web de aspecto profesional. Ver especificaciones:",
 "logo-light-bg": "Logotipo sobre fondo claro",
 "logo-dark-bg": "Logo sobre fondo oscuro (Opcional)",
 "icon-apple-app": "Icono para la aplicación Apple",
 "icon-android-app": "Icono para la aplicación Android",
 "social-media": "Medios de comunicación social",
 "links-appear": "Estos enlaces aparecerán en el sitio de salud y en otras comunicaciones de los pacientes.",
 "fb-url": "Facebook URL",
 "insta-url": "URL de Instagram",
 "twitter-url": "URL X (Twitter)",
  "setup-checklist": "Liste de contrôle de configuration",
  "need-to-do-first": "Lo que tienes que hacer primero...",
  "complete-healthsite-patient": "Los elementos del sitio de salud y la aplicación para pacientes deben completarse lo antes posible para que se pueda activar la experiencia del paciente en su farmacia. Complete estos elementos primero.",
  "required": "Requerido",
  "patient-agreements": "Patient agreements",
  "patient-agreements-description": "Terms of Use and a Privacy Policy must be added to your app for your patients to agree to.",
  "patient-agreements-use-template": "Use template",
  "patient-agreements-upload-pdf":"Upload pdf...",
  "patient-agreements-terms-option": "Terms of Use options",
  "patient-agreements-terms-template": "Terms of Use template",
  "patient-agreements-privacy-option": "Privacy Policy options",
  "patient-agreements-privacy-template": "Privacy Policy template",
  "patient-agreements-legal-notice": "Legal notice TBD",
  "patient-website": "Sitio web para pacientes",
  "checklist-item-completed": "Este elemento de la lista de verificación ya está completo",
  "other-settings": "Otros ajustes",
  "checklist-items-in-settings": "Estos elementos de la lista de verificación también se pueden encontrar en el área de Configuración.",
  "basic-setup": "Configuración básica",
  "media-settings": "Configuración de medios",
  "patient-app": "Aplicación para pacientes",
  "scanner-config": "Configuración del escáner",
  "clinical-services": "Servicios clínicos",
  "refill-settings": "Configuración de recarga",
  "message-settings": "Configuración de mensajes",
  "patient-agreements-desc": "Configure sus Términos de uso y Política de privacidad para pacientes",
  "patient-website-desc": "Configure y conecte el sitio web de su farmacia.",
  "basic-setup-desc": "Gracias por completar la configuración paso a paso.",
  "media-settings-desc": "Branding, redes sociales y acuerdos con los pacientes.",
  "patient-app-desc": "Ingrese la marca de su farmacia para la aplicación para pacientes de su farmacia.",
  "scanner-config-desc": "Alta prioridad. Paso requerido para usar lectores de códigos de barras",
  "clinical-services-desc": "Seleccione los servicios reservables que ofrece su farmacia",
  "refill-settings-desc": "Copia de error personalizada, períodos de recordatorio, opciones de recarga automática, más....",
  "message-settings-desc": "Mensajes iniciados por el paciente, configuración de respuesta automática a mensajes, más...",
  "checklist-card-title":"Setup checklist",
  "checklist-card-subtitle":"Customize and get to know Lumistry.",
  "checklist-card-button":"Open checklist",
  "save-logout": "Salvar & Cerrar sesion",
  "on-premise-equipment": "Equipo en las instalaciones",
  "ob-something-went-wrong":"Something went wrong, please contact help and support.",
  "ob-address-title": 'Addresses',
  "ob-address-description": 'Please provide the following address information.',
  "ob-multi-location-title": "Location details",
  "ob-multi-location-subtitle":"Please complete each section.",
  "ob-multi-review-brand-name":"Brand name",
  "ob-multi-review-number-locations": "Number of locations",
  "pharmacy-store-address":'Dirección de la tienda de farmacia',
  "photo-error": "Foto (s) no disponible.Inténtalo de nuevo más tarde.",
  "pharmacy-store-address-tooltip":'The physical location of the pharmacy',
  "account-billing-address":'Dirección de facturación de cuenta',
  "account-billing-address-tooltip": 'Address associated with your payment method',
  "denied-password-change-text": "Ha creado su cuenta de Portal de pacientes usando SSO, como Google o Apple Sign-On.Para actualizar su contraseña, deberá entrar en su configuración SSO",
  "account-shipping-address": 'Account Shipping Address',
  "account-shipping-address-tooltip": 'Optional alternate shipping address',
  "server-error": "Error Interno del Servidor",
  "same-as-pharmacy-store-address": "Same as Pharmacy Store Address",
  "add-employee": "Agregar empleado",
  "ob-employees-title": "Empleadas",
  "ob-employees-generic-error": "Certaines entrées ne sont pas valides. Veuillez vérifier toutes les données.",
  "ob-hours-generic-error": "Algunas entradas no son válidas. Por favor revisa todos los datos.",
  "ob-employees-description": "Introduzca los empleados y asígneles un rol. Los roles determinan qué acciones tiene permiso para realizar un empleado. Utilice las siguientes descripciones para asignar el rol correcto.",
  "pms-does-not-require-opie-desc": "El software de gestión de su farmacia no requiere un dispositivo en el sitio para conectarse con Lumistry. Puede continuar con el siguiente paso.",
  "pms-requires-opie-desc": "Lumisty se integrará con el sistema de gestión de su farmacia a través de un dispositivo físico. Este dispositivo se enviará una vez que complete esta configuración y se programará una llamada de instalación.",
  "have-it-person": "¿Tiene una persona de TI que le ayude cuando su red o firewall necesiten actualizarse?*",
  "do-not-know": "No sé",
  "preferred-time-is-required": "Se requiere horario preferido",
  "firewall-provider-is-not-valid": "El proveedor de firewall no es válido",
  "technician-name-is-not-valid": "El nombre del técnico no es válido",
  "technician-email-is-not-valid": "El correo electrónico del técnico no es válido",
  "technician-phone-is-not-valid": "El número de teléfono del técnico no es válido",
  "recipient-name-is-not-valid": "El nombre del destinatario no es válido",
  "recipient-name-is-required": "El nombre del destinatario es obligatorio",
  "city-is-not-valid": "La ciudad no es válida.",
  "recipient_name":"Nombre del Recipiente",
  "firewall-provider": "Proveedor de cortafuegos",
  "name-is-not-valid": "El nombre no es válido",
  "phone-is-required": "Se requiere número de teléfono",
  "faq": "Preguntas frecuentes",
  "visit-help-center": "Visita el centro de ayuda",
  "brand-name-is-not-valid": "El nombre de la marca no es válido.",
  "parent-company-is-not-valid": "La empresa matriz no es válida",
  "pharmacy-name-is-not-valid": "El nombre de la farmacia no es válido",
  "legal-entity-name-is-not-valid": "El nombre de la entidad legal no es válido",
  "dba-is-not-valid":"DBA no es válido",
  "pharmacy-email-address-is-not-valid": "La dirección de correo electrónico de la farmacia no es válida",
  "pharmacy-ncpdp-is-not-valid": "NCPDP no es válido",
  "pharmacy-npi-is-not-valid": "NPI no es válido",
  "fax-is-not-valid":"El número de fax no es válido",
  "firewall-provider-is-required": "Se requiere proveedor de firewall",
  "technician-name": "Nombre del técnico",
  "technician-name-is-required": "Se requiere el nombre del técnico",
  "technician-email": "Correo electrónico del técnico",
  "technician-email-is-required": "Se requiere el correo electrónico del técnico",
  "technician-phone": "Número de teléfono del técnico",
  "technician-phone-is-required": "Se requiere el número de teléfono del técnico",
  "address-device-shipped": "¿A qué dirección desea que le envíen el dispositivo?*",
  "po-boxes-not-accepted":"CORREOS. no se aceptarán cajas",
  "ship-to-pharmacy": "Enviar a la dirección de la tienda de farmacia",
  "select-preferred-weekdays": "Seleccione su(s) día(s) de la semana preferido(s) para la sesión de instalación remota:*",
  "use-defaults": "Use defaults",
  "select-all": "Seleccionar todo",
  "store-general-hours": "Estos son los horarios generales de tu tienda.",
  "holidays": "Vacaciones",
  "additional-closures-may-be-added": "Es posible que se agreguen cierres adicionales más adelante en la configuración.",
  "am-or-pm-installations": "¿Se prefiere AM o PM para una llamada telefónica de instalación remota?*",
  "status-is-required": "Se requiere estado",
  "signup": "Inscribirse",
  "how-to-add-caregiver": "¿Cómo agrego un cuidador?",
  "add-caregiver-details": "Si desea agregar un cuidador para que lo ayude a administrar su salud digitalmente, primero deberá agregarlo como paciente bajo su cuidado. Después de eso, puede aprobar o rechazar su solicitud de acceso del cuidador a sus registros",
  "patients-under-care": "Pacientes bajo cuidado",
  "caregivers": "Cuidadores",
  "login": "Iniciar sesión",
  "username": "Nombre de usuario",
  "sender-subject-date": "Remitente, asunto, fecha",
  "template-name": "Nombre de la plantilla",
  "assignee-summary": "Cesionario, resumen",
  "name-rxnumber-dob": "Nombre, número de receta, fecha de nacimiento",
  "first-name-last-name-dob": "Nombre, apellido, fecha de nacimiento",
  "patient-name-dob-form-name": "Nombre del paciente, fecha de nacimiento, nombre del formulario",
  "name-ncpdp-street-city": "Nombre, NCPDP, calle, ciudad",
  "user-email-address": "Usuario, dirección de correo electrónico",
  "password": "Clave",
  "dashboard": "Tablero",
  "medications": "Medicamentos",
  "messages": "Mensajes",
  "profile": "Perfil",
  "image-reading-error": "No se puede cargar la imagen. Inténtalo de nuevo más tarde.",
  "get-care": "Obtenga atención",
  "open-settings": "Configuración abierta",
  "lets-get-to-know-you": "vamos a conocerte",
  "app-settings": "Aplicación y sitio web",
  "branding": "La imagen de marca",
  "primary-color": "Color primario",
  "text-color": "Color de texto",
  "accent": "Acento",
  "invalid-hex-value": "Valor hexadecimal no válido",
  "edit-brand-colors": "Editar colores de marca",
  "logo-light": "Logotipo en la luz",
  "logo-dark": "Logotipo en la oscuridad",
  "icon-android": "Icono Android",
  "icon-ios": "Ícono iOS",
  "png-only": "Solo se admiten las imágenes PNG",
  "valid-image-type": "Solo se admiten las imágenes JPG, PNG o SVG",
  "valid-icon-dimensions": "Las dimensiones del icono deben ser 1024x1024",
  "patient-select": "Seleccione al paciente antes de comenzar.",
  "form-submission-not-deleting": "Los formularios con envíos no pueden eliminarse",
  "access-private-public": "Acceso: privado/público",
  "assignee-required": "Se requiere asignado",
  "search-locations": "Ubicación de búsqueda",
  "reminder-forms": "Este servicio tiene formularios que debe completar antes de su cita.",
  "cannot-delete-task-type": "No se puede eliminar los tipos de tareas que están actualmente en uso",
  "cannot-edit-task-type": "No se puede editar el tipo de tarea predeterminado",
  "personal-task-assign": "Las tareas personales solo se pueden asignar al propietario/creador de la tarea",
  "drag-change-order": "Arrastre para cambiar el orden",
  "new-service": "Nuevo Servicio",
  "remove-item": "Remover el artículo",
  "download-all-submissions": "Descargar todas las presentaciones",
  "role-permission-description": "Consulte roles y permisos para obtener una descripción completa sobre el acceso al sistema.",
  "no-submissions-download": "No hay envíos para descargar",
  "copy-link": "Copiar link",
  "form-was-imported": "Se importó el formulario",
  "expired-link": "This link has been expired",
  "used-password": "Su nueva contraseña no puede ser una de sus 5 contraseñas anteriores.Elija una contraseña que no haya utilizado anteriormente.",
  "go-to-form": "Ir a la forma",
  "after-adding-patient-message": "Después de agregar un nuevo paciente, regrese al mensaje original y haga clic en el enlace nuevamente.",
  "welcome": "Bienvenidos",
  "logout": "Cerrar sesión",
  "full-access": "Full access",
  "it-admin-description": 'Administrador del sistema (agregar y eliminar usuarios)',
  "brand-description": 'Gerente de nivel de marca',
  "corporate-description": 'Gerente de nivel superior',
  "manager-description": 'Configurar personal, gerente y no acceso por ubicación (siguiente pantalla)',
  "remove-role": "Desactivar usuario",
  "location-employee": "Empleado de ubicación",
  "user-has-role-error": "El usuario existe. Edite el usuario para administrarlo.",
  "location-employee-description": "Configurar personal, gerente y sin acceso por ubicación (siguiente pantalla)",
  "remove-role-description":"Ya no se permite en el sistema.",
  "front": "Delantero",
  "back": "atrás",
  "import-forms": "Formularios de importación",
  "number-of-forms": "Número de formularios para importar",
  "placeholder-step": "Paso de marcador de posición",
  "form": "Forma",
  "import-plan": "Plan de importación",
  "new-form": 'Nueva forma',
  "instructions": "Instrucciones",
  "name-not-available": 'Nombre no disponible',
  "add-override": "Agregar anulación",
  "appointments-calendar": "Calendario de citas",
  "new-appointment": "Nueva Cita",
  "not-implemented": "Aun no implementado",
  "edit-appointment": "Editar Cita",
  "download": "Descargar",
  "configure-label-secure": "Configurar {{label}} (Seguro)",
  "chat": "Charlar",
  "instructions-api": "Acceder a una cuenta de Jotform existente en Lumistry requiere una clave API que se encuentra en la configuración de Jotform.",
  "pdf-instructions": "El .pdf a continuación contiene una guía corta y paso a paso para obtener esta clave.",
  "open-pdf": "Abra PDF con instrucciones",
  "sent": "Enviada",
  "credentials": "Cartas credenciales",
  "lumistry-api-key": 'La clave API que proporcione le dará acceso a Lumistry a sus datos de Jotform, incluidos formularios y envíos.Sus datos se mantendrán seguros y solo se utilizarán con el fin de importar los formularios que especifique en la plataforma Lumistry.',
  "create-first-form": "Crea tu primera forma",
  "pharmacy-additional-closures-inactive": "Inactiva",
  "manage-subscription": "Administre sus recetas y su familia, comuníquese con su farmacia, acceda a materiales educativos, programen citas y más!",
  "get-mobile-app": "Obtenga nuestra aplicación móvil!",
  "back-to-login": "Atrás para iniciar sesión",
  "otc-medications": "Medicamentos de venta libre",
  "see-order-details": "Ver detalles del pedido",
  "prescriptions-filled": "Se llenan {{filled}} de {{total}} recetas",
  "rx-order-progress": "Orden #{{orderId}} en curso",
  "next": "Siguiente",
  "auto-fill-my-address": "Llenar automáticamente mi dirección",
  "not-yet-entered": "Todavía no ingresado",
  "all-tasks": "Todas las tareas",
  "every-n-months": "Cada {{number}} meses",
  "every-n-weeks": "Cada {{number}} semanas",
  "section-on-the-roadmap": "{{section}} en la hoja de ruta, aún no disponible",
  "bottle-cap-preference": "Preferencias de tapas de botellas",
  "coming-soon": "Planeado para MVP, aún no disponible",
  "allergies-conditions-caregivers": "Alergias, afecciones, etc.",
  "insurance-id-card": "Seguro y I.D. Tarjetas",
  "patient-forms": "Formularios de pacientes",
  "submission-view": "Ver Envío del Formulario",
  "edit-form": "Formulario de Edición",
  "communication-preferences": "Preferencia de comunicaciones",
  "sign-out": "Desconectar",
  "scan-barcode": "Escanear código de barras",
  "scanned": "Escaneado",
  "insurance-and-id-cards": "Seguro y Tarjetas de Identificación",
  "primary": "Principal",
  "secondary": "Secundario",
  "id-card": "Tarjeta de identificación",
  "save": "Guardar",
  "forgot-password": "¿Olvidaste tu contraseña?",
  "make-primary": "Convertir en primario",
  "updated": "Actualizado {{date}}",
  "camera-access-denied": "No se puede acceder a la cámara, vaya a la configuración de su aplicación y conceda acceso a la cámara.",
  "username-is-required": "Se requiere el nombre de usuario",
  "password-is-required": "Se requiere la contraseña",
  "view-full-profile": "Ver perfil completo",
  "insurance-card": "Tarjeta de seguro",
  "barcode-scanner": "Escaner de código de barras",
  "add-card": "Añadir tarjeta...",
  "primary-secondary-photo-id": "Identificación primaria, secundaria, con fotografía",
  "anonymous-refill": "Recarga anónima",
  "english": "Inglés",
  "spanish": "Español",
  "services": "Servicios",
  "new-availability-tab": "Nueva Disponibilidad",
  "french": "Francés",
  "same-mailing-address": "La misma que mi direccion de correo",
  "edit-service-tab": "Editar Servicio",
  "days": "Días",
  "show-grid": "Mostrar en la cuadrícula",
  "task-appear-grid": "La tarea aparecerá en la cuadrícula y las vistas de la lista.",
  "yes": "Sí",
  "no": "No",
  "about-this-app": "Acerca de esta aplicación",
  "about-this-app-title": "Acerca de Esta Aplicación",
  "new-task": "Nueva tarea",
  "summary": "Resumen",
  "description": "Descripción",
  "summary-is-required": "Se requiere resumen",
  "recurrence": "Reaparición",
  "one-time": "Una vez",
  "weekly": "Semanalmente",
  "monthly": "Mensual",
  "start-date": "Fecha de inicio",
  "high": "Alta",
  "low": "Bajo",
  "medium": "Medio",
  "create-first-task": "Crea tu primera tarea",
  "assigned-to": "Asignado a",
  "nobody": "Nadie",
  "created-by": "Creado por",
  "system-user": "Usuario del sistema",
  "task-details": "Detalles de la tarea",
  "created": "Creada",
  "happy-birthday": "Feliz cumpleaños",
  "insurance-card-update": "Actualización de la tarjeta de seguro",
  "flu-shot-reminder": "Recordatorio de la vacuna contra la gripe",
  "spring-promotions": "Promociones de primavera",
  "summer-promotions": "Promociones de verano",
  "fall-promotions": "Promociones de otoño",
  "thanksgiving": "Acción de Gracias",
  "seasons-greeting": "Saludos de temporada",
  "happy-new-year": "Saludos de temporada",
  "happy-birthday-description": "Desarrollar una relación personal y aumentar la fidelidad de los clientes",
  "insurance-card-update-description": "Solicitar a los pacientes información actualizada sobre el seguro",
  "flu-shot-reminder-description": "Recuérdeles a los pacientes acerca de las vacunas contra la gripe y pídales que programen una cita y ayuden a generar ingresos",
  "spring-promotions-description": "Promocione los productos y servicios de temporada que su farmacia tiene para ofrecer durante la temporada de primavera, por ejemplo, alivio de alergias",
  "summer-promotions-description": "Promocione los productos y servicios de temporada que su farmacia tiene para ofrecer durante la temporada de verano, por ejemplo, productos UV, repelentes de insectos, alivio para picaduras de insectos",
  "fall-promotions-description": "Promocione los productos y servicios de temporada que su farmacia tiene para ofrecer durante la temporada de otoño, por ejemplo, alivio del resfriado y la tos",
  "thanksgiving-description": "Desarrollar una relación personal y aumentar la fidelidad del cliente",
  "seasons-greeting-description": "Desarrollar una relación personal y aumentar la fidelidad de los clientes",
  "happy-new-year-description": "Desarrollar una relación personal y aumentar la fidelidad de los clientes",
  "happy-birthday-message-subject": "¡Saludos desde tu farmacia local!",
  "insurance-card-update-message-subject": "Tarjeta Seguro",
  "flu-shot-reminder-message-subject": "Vacunas",
  "spring-promotions-message-subject": "¡Saludos desde tu farmacia local!",
  "summer-promotions-message-subject": "¡Saludos desde tu farmacia local!",
  "fall-promotions-message-subject": "¡Saludos desde tu farmacia local!",
  "thanksgiving-message-subject": "¡Saludos desde tu farmacia local!",
  "seasons-greeting-message-subject": "¡Saludos desde tu farmacia local!",
  "happy-new-year-message-subject": "¡Saludos desde tu farmacia local!",
  "happy-birthday-message-body": "Hola, {{firstName}}, de parte de todos nosotros aquí en {{pharmacyName}}, ¡te deseamos un muy feliz cumpleaños! Déjanos saber si hay algo que podamos hacer para que esto suceda. ¡mejor!",
  "insurance-card-update-message-body": "Nos gustaría asegurarnos de tener su información más actualizada. Si tiene una nueva tarjeta de seguro, vaya a Configuración de cuenta para cargar su seguro más reciente información. ¡Gracias!",
  "flu-shot-reminder-message-body": "La temporada de gripe ya está aquí y estamos empezando a programar las vacunas contra la gripe. ¡Utiliza nuestra aplicación para reservar tu cita hoy!",
  "spring-promotions-message-body": "¡No permita que sus alergias estacionales le impidan disfrutar de la primavera! Visite la farmacia hoy para explorar nuestras opciones de alivio de alergias de venta libre o envíenos un mensaje a través de la aplicación si tiene alguna pregunta. .",
  "summer-promotions-message-body": "Un clima más cálido puede significar más diversión bajo el sol, pero también significa más insectos molestos o quemaduras solares. Dirígete a la farmacia hoy para abastecerte de repelentes de insectos y protector solar para asegurarte de que tú y tu ¡Los seres queridos permanecen seguros mientras se divierten bajo el sol!",
  "fall-promotions-message-body": "¿El cambio de clima te hace toser y sentirte congestionado? Visita la farmacia hoy para buscar nuestros medicamentos de venta libre para aliviar la tos y el resfriado, o envíanos un mensaje a través de la aplicación si ¡tienes alguna pregunta!",
  "thanksgiving-message-body": "Es esa época del año en la que nos reunimos para contar y reflexionar sobre nuestras bendiciones. Eres una de las muchas cosas por las que estamos profundamente agradecidos. Gracias por elegirnos para cuidar de ti y de tus seres queridos. ¡Te deseo a ti y a los tuyos un feliz Día de Acción de Gracias lleno de alegría y bondad!",
  "seasons-greeting-message-body": "¡Les envío alegría navideña y les deseo unas excelentes fiestas! ¡Disfruten del clima acogedor, la buena compañía y mucha comida deliciosa!",
  "happy-new-year-message-body": "¡Nuestra farmacia les desea un Feliz Año Nuevo! ¡Brindemos por la felicidad y la salud para usted y sus seres queridos!",
  "when-to-send-every-year": "Cuándo enviar cada año",
  "updated-without-date": "Actualizada",
  "no-task": "No hay tarea que mostrar",
  "no-task-disable-filters": "No hay tareas para mostrar. Desactive algunos filtros o relaje su consulta de búsqueda.",
  "tasks-grid": "Tarea cuadrícula",
  "create-refill": "Crear recarga",
  "grid": "Cuadrícula",
  "browse": "Navegar",
  "unknown": "Desconocida",
  "automated-messages": "Mensajes automatizados",
  "person": "Persona",
  "apply-to-all": "Aplicar a todo",
  "edit-form-title": "Editar título de formulario",
  "subtitle": "Subtitular",
  "edit-title": "Editar título",
  "edit-subtitle": "Editar subtítulo",
  "service-required": "Se requiere servicio",
  "set-to-default": "Establecer en predeterminado",
  "availability-name": "Nombre de disponibilidad",
  "name-is-required": "Se requiere el nombre",
  "display-name": "Nombre para mostrar",
  "length": "Longitud",
  "restriction": "Restricciones",
  "monday-abbreviation": "Lun",
  "tuesday-abbreviation": "Mar",
  "wednesday-abbreviation": "Mié",
  "thursday-abbreviation": "Jue",
  "friday-abbreviation": "Vie",
  "saturday-abbreviation": "Sáb",
  "sunday-abbreviation": "Dom",
  "frequency": "Frecuencia",
  "end-date": "Fecha final",
  "custom-domain": "Dominio personalizado (opcional)",
  "sort-by-due-date": "Ordenar por fecha de vencimiento",
  "sort-by-assignee": "Ordenar por cesionario",
  "sort-by-task-summary": "Ordenar por resumen de la tarea",
  "ascending": "Ascendente",
  "descending": "Descendente",
  "arabic": "Arábica",
  "chinese": "China",
  "vietnamese": "Vietnamita",
  "korean": "Coreana",
  "german": "Alemana",
  "russian": "Rusa",
  "fullname": "Nombre completo",
  "enter-valid-phone-number": "Por favor ingrese un número de teléfono válido.",
  "account-holder": "Titular de la cuenta",
  "please-upload-image": "Sube una imagen",
  "signature": "Firma",
  "type-your-question": "Escriba una pregunta",
  "select-single-option": "Seleccione una sola opción",
  "select-multiple-options": "Seleccione varias opciones",
  "number": "Número",
  "every-week": "Cada semana",
  "every": "Cada",
  "weeks": "semanas",
  "months": "meses",
  "medical-conditions-without-colon": "Condiciones médicas",
  "no-known-medical-conditions": "Sin Condiciones Médicas Conocidas",
  "medical-conditions-non-capitalized": "Condiciones médicas",
  "please-select": "Seleccione",
  "edit-checkbox-label": "Editar etiqueta de casilla de verificación",
  "maximum-input-value": "Valor de entrada máximo",
  "required-field": "Campos requeridos",
  "all-statuses": "Todos los estados",
  "all-priorities": "Todas las prioridades",
  "all-visibilities": "Todas las visibilidades",
  "all-types": "Todos los tipos",
  "year": "año",
  "month": "mes",
  "day": "día",
  "week": "semana",
  "date-overrides": "Anulaciones de fecha",
  "import-form": "Formulario de importación",
  "no-form-templates": "No hay plantillas de formulario disponibles.",
  "no-submission-found": "No se encontraron presentaciones",
  "search-submissions": "Envíos de búsqueda",
  "submission": "Envío",
  "no-availability": "No disponible",
  "new-availability": "Nueva disponibilidad",
  "make-default": "Hacer por defecto",
  "default-availability": "Disponibilidad predeterminada",
  "edit-date-override": "Editar anulación de fecha",
  "no-services": "Sin servicios",
  "form-imported-text": "Este formulario fue importado y presenta una funcionalidad que actualmente no está disponible.Todavía puede obtener una vista previa del formulario, pero no puede hacer ediciones.",
  "form-not-imported-text": "Ce formulaire contient une fonctionnalité qui n'est pas actuellement prise en charge.Vous pouvez toujours prévisualiser le formulaire, mais vous ne pouvez pas y apporter de modifications.",
  "appointment-filters": "Filtros de cita",
  "task-types": "Tipos de tareas",
  "task-type-required": "Se requiere el título del tipo de tarea",
  "task-type-title-exceeded": "El título no debe exceder los 30 caracteres",
  "characters": "caracteres",
  "task-type-description-required": "Tipo de tarea Descripción es necesaria",
  "task-type-description-exceeded": "La descripción no debe exceder los 130 caracteres",
  "delete-task-type-confirm": "Al confirmar que eliminará el tipo de tarea seleccionada.",
  "user-roles": "Roles del usuario",
  "options": "Opción",
  "add-new-option": "Agregar nueva opción",
  "edit-columns": "Editar columnas",
  "add-new-column": "Agregar una nueva columna",
  "import": "Import",
  "task-filters": "Filtros de tareas",
  "error": "Error",
  "lose-data-creating-form": "Al confirmar que perderá el progreso actual en la creación de un nuevo formulario a partir de una plantilla.",
  "disable-filters": "Deshabilite algunos filtros o relaje su consulta de búsqueda.",
  "due-date": "Fecha de vencimiento",
  "completed": "Terminada",
  "are-you-sure-to-delete-task": "¿Estás seguro de que quieres eliminar la tarea?",
  "are-you-sure-to-resolve-task": "¿Estás seguro de que quieres resolver la tarea?",
  "data": "Datos",
  "changed-value": "Valor cambiado",
  "easy-open-bottle-caps-field": "Tapas de botellas de fácil apertura",
  "med-sync-field": "Sincronización de medicamentos",
  "visibility-personal-policy": "Será visto por usted y el cesionario (si se asigna)",
  "visibility-team-policy": "Será visto por todos",
  "start-date-required": "Se requiere fecha de inicio",
  "copyright-lumistry": "Lumistry de derechos de autor",
  "all-rights-reserved": "Reservados todos los derechos",
  "version": "Versión",
  "continue": "Continuar",
  "coming-up": "Próximamente:",
  "view": "Vista",
  "update-personal-info": "Actualizar información personal",
  "name-phone-email": "Nombre, teléfono, correo electrónico, etc.",
  "first-name": "Nombre",
  "first-name-is-required": "Se requiere el nombre",
  "last-name": "Apellido",
  "brand-name-is-required": "Se requiere nombre de marca",
  "parent-company-is-required": "Se requiere empresa matriz",
  "last-name-is-required": "Se requiere el apellido",
  "email-short": "Correo electrónico",
  "email": "Correo electrónico",
  "email-is-required": "Se requiere el correo electrónico",
  "email-continue": "Continuar con el correo electrónico",
  "mobile-number": "Número de móvil",
  "mobile-number-is-required": "Se requiere el número de móvil",
  "birth-date": "Fecha de nacimiento",
  "birth-date-is-required": "Se requiere fecha de nacimiento",
  "lets-get-started": "Comencemos por crear una cuenta.",
  "front-of-card": "Frente de la tarjeta",
  "back-of-card": "Fondo de la tarjeta",
  "add-front-of-card": "Frente de la tarjeta",
  "add-back-of-card": "Fondo de la tarjeta",
  "cancel": "Cancelar",
  "cancelations": "Cancelaciones",
  "retake": "Volver a tomar",
  "use-photo": "Usar foto",
  "password-must-include": "La contraseña debe incluir",
  "at-least-8-characters": "al menos 8 caracteres",
  "at-least-1-number": "al menos 1 número",
  "at-least-1-capital-letter": "al menos 1 letra mayúscula",
  "at-least-1-lowercase-letter": "al menos 1 letra minúscula",
  "at-least-1-special-character": "al menos 1 carácter especial",
  "privacy-policy-error": "Por favor lea la política de privacidad antes de aceptar",
  "privacy-policy-agree": "Estoy de acuerdo con los términos y condiciones anteriores",
  "privacy-policy-email-signup": "Regístrese para recibir correos electrónicos de mi farmacia",
  "pharmacy-privacy-policy": "Política de privacidad de la Farmacia",
  "terms-and-conditions": "Proveedor de Software Términos & Condiciones",
  "terms-of-service": "Términos y condiciones",
  "terms-of-service-error": "Lea los términos del servicio antes de aceptar",
  "terms-of-service-agree": "Estoy de acuerdo con los términos y condiciones anteriores",
  "terms-of-service-email-signup": "Regístrese para recibir correos electrónicos",
  "and": "y",
  "submit": "Enviar",
  "2-of-2-agreements": "2 de 2 acuerdos",
  "medical-info-step-3": "Paso 3 de 8 / Agregue su información médica",
  "password-entry-does-not-meet-criteria": "La entrada de la contraseña no cumple los criterios",
  "password-entries-do-not-match": "Las entradas de contraseña no coinciden",
  "create-your-account": "Crea tu cuenta",
  "please-enter-your-new-password": "Por favor, introduzca su nueva contraseña",
  "email-is-not-valid": "El correo electrónico no es válido",
  "phone-is-not-valid": "El teléfono no es válido",
  "create-account": "Crear cuenta",
  "confirm-password": "Confirmar contraseña",
  "patient-info-step": "Paso x de y / Confirma tu información",
  "communications-language": "Lenguaje de comunicaciones",
  "prescriptions-communications": "Comunicaciones de recetas: ¿Cómo le gustaría que le notifiquen cuando sus recetas estén listas?",
  "automated-voice-call": "Llamada de voz automatizada",
  "text-message": "Mensaje de texto",
  "app-notifications": "Notificaciones de aplicación",
  "all-other-pharmacy-communications": "Todas las demás comunicaciones de la farmacia",
  "same-time-preferences": "¿Le gustaría que estas recetas estén listas al mismo tiempo cada mes?",
  "additional-information": "Información Adicional",
  "visually-impaired": "Discapacidad visual",
  "hearing-impaired": "Personas con discapacidad auditiva",
  "easy-open-bottle-caps": "Tapas de botellas fáciles de abrir",
  "personal-info-step-2": "Paso 2 de 7 / Agrega tu información personal",
  "country": "País",
  "country-is-required": "Se requiere el país",
  "state": "Estado",
  "state-is-required": "Se requiere el estado",
  "city": "Ciudad",
  "city-is-required": "Se requiere la ciudad",
  "edit-notes": "Editar notas",
  "street-address": "Dirección",
  "street-address-is-required": "Se requiere la dirección",
  "street-address-line-2": "Dirección 2",
  "zip-code": "Código postal",
  "zip-code-is-required": "Se requiere el código postal",
  "gender-is-required": "Se requiere género",
  "male": "Masculino",
  "female": "Femenina",
  "verification-code-resent": "Código de verificación reenviado",
  "please-enter-code": "Ingrese el código que le enviamos por correo electrónico",
  "didnt-receive-email": "¿No recibiste el correo electrónico?",
  "please-check-spam-folder": "Por favor revise su carpeta de correo no deseado o reenvíe el código",
  "didnt-receive-text-message": "¿No recibiste el mensaje de texto?",
  "phone-number-is-sms-capable": "Asegúrese de que el número de teléfono que ingresó sea compatible con SMS o reenvíe el código.",
  "email-address-is-correct": "Asegúrese de que la dirección de correo electrónico que ingresó sea correcta o reenvíe el código.",
  "standard-text-messages-apply": "Es posible que se apliquen tarifas estándar de mensajes de texto según su proveedor de servicios.",
  "resend-code": "Reenviar codigo",
  "reset-password": "Restablecer contraseña",
  "reset-link-sent": "Si el correo electrónico está asociado con una cuenta activa, debería recibir un enlace de reinicio.",
  "please-enter-email": "Por favor, introduzca la dirección de correo electrónico asociada a su cuenta",
  "how-would-you-like-to-receive-reset-link": "¿Cómo le gustaría recibir el enlace de reinicio?",
  "link-will-be-sent": "El enlace se enviará al número de teléfono registrado",
  "using-the-phone-number-on-file": "Usando el número de teléfono en el archivo",
  "send-reset-link": "Enviar enlace de reinicio",
  "create-new-password": "Crear nueva contraseña",
  "confirm-new-password": "Crear contraseña",
  "new-password": "Nueva contraseña",
  "for-security-reasons": "Por razones de seguridad, su contraseña caduca cada 90 días, por favor actualice su contraseña",
  "update-password": "Actualizar contraseña",
  "password-expiring-soon": "La contraseña caduca pronto",
  "password-expire-in-7-days": "Su contraseña expirará en 7 días",
  "update-password-now": "Desea actualizar su contraseña ahora?",
  "confirm-update-password": "Confirmar nueva contraseña",
  "go-to-login": "Ir al inicio de sesión",
  "return-to-login": "Volver al inicio de sesión",
  "apple-signup": "Regístrese en Apple",
  "apple-continue": "Continuar con Apple",
  "apple-login": "Inicio de sesión de Apple",
  "password-incorrect": "Contraseña incorrecta",
  "change-password": "Cambiar contraseña",
  "current-password": "Contraseña actual",
  "enter-existing-password": "Ingrese su contraseña existente y la nueva contraseña a continuación.",
  "login-with-email": "Iniciar sesión con el correo electrónico",
  "login-enter-email": "Insertar email",
  "passwords-must-match": "Las contraseñas no coinciden",
  "no-photo": "Sin foto",
  "dismiss": "Descartar",
  "load-more": "Cargar más",
  "loading": "Cargando",
  "open-map": "Abrir mapa",
  "input-field": "Campo de entrada",
  "text-field": "Campo de texto",
  "show-hide-password": "Mostrar/ocultar contraseña",
  "search": "Buscar",
  "select": "Seleccionar",
  "use": "Usar",
  "no-options": "No hay opciones",
  "email-or-password-incorrect": "El correo electrónico o la contraseña son incorrectos. Vuelva a intentarlo.",
  "unable-to-complete-registration": "No se puede completar el registro. Inténtalo de nuevo.",
  "unable-to-update-account": "No se puede actualizar la cuenta. Inténtalo de nuevo.",
  "unable-to-complete-verification": "No se puede completar la verificación. Inténtalo de nuevo.",
  "unable-to-resend-verification-code": "No se puede reenviar el código de verificación. Inténtalo de nuevo.",
  "error-submitting": "Error al enviar. Inténtalo de nuevo.",
  "unable-to-send-reset-link": "No se puede enviar el enlace de reinicio. Inténtalo de nuevo.",
  "unable-to-change-password": "No se puede cambiar la contraseña.Inténtalo de nuevo.",
  "store-selector-change": "Cambio",
  "store-selector-search-address": "Código postal",
  "store-selector-change-store": "Cambio tienda",
  "store-selector-your-store": "Tu tienda",
  "store-selector-nearby-store": "Tiendas cercanas",
  "store-selector-close": "Cerrar",
  "store-selector-select": "Seleccione",
  "store-selector-no-stores-error": "No se puede encontrar la ubicación de la farmacia",
  "feedback": "Retroalimentación",
  "feedback-give-feedback": "Dar retroalimentación",
  "feedback-give-your-feedback": "Dé su retroalimentación",
  "feedback-please-enter-feedback": "Ingrese su retroalimentación",
  "feedback-how-to-improve-app": "How can we improve our app?",
  "feedback-give-feedback-about-pharmacy": "Give us feedback about our pharmacy",
  "feedback-sent": "Retroalimentación enviada!",
  "feedback-thank-you": "Gracias por su retroalimentación!",
  "feedback-what-type-of-feedback": "Qué tipo de comentarios estás dejando hoy?",
  "feedback-target-is-required": "El objetivo de comentarios es obligatorio.",
  "feedback-feedback": "Ingrese información adicional",
  "feedback-feedback-is-required": "Se requiere información de comentarios.",
  "feedback-allowContact": "Acepto que se ponga en contacto conmigo para obtener más aclaraciones.",
  "feedback-thanks-message": "Gracias por tus comentarios!",
  "feedback-reach-you-message": "Nos pondremos en contacto con usted directamente si tenemos preguntas.",
  "feedback-back-to-profile": "Volver al perfil",
  "feedback-error-occurred": "Se ha producido un error.",
  "feedback-contact-info": "Datos de contacto",
  "feedback-edit": "Editar",
  "feedback-email-address": "Dirección de correo electrónico",
  "feedback-phone-number": "Número de teléfono",
  "feedback-app": "App",
  "phone-number": "teléfono: {{phone}}",
  "fax": "fax: {{fax}}",
  "digital-pharmacist": "Digital Pharmacist",
  "pharmacy-website": "Sitio web de farmacia y aplicación móvil proporcionada por",
  "feedback-location": "Tienda",
  "refill-card-medication": "Medicamento",
  "refill-card-due-date": "Recarga vencida",
  "prescription-card-day-supply": "suministro diario",
  "prescription-card-filled-on": "lleno de",
  "prescription-card-refills": "Recargas restantes",
  "prescription-card-dob": "DOB",
  "medications-fill-your-prescription": "Receta de relleno",
  "medications-ready-to-be-filled": "Listo para ser llenado",
  "medications-other-upcoming-refills": "Otras próximas recargas",
  "insurance-card-upload-error": "No se puede cargar de seguro. Inténtalo de nuevo.",
  "camera-mask-label": "Centra la tarjeta dentro del marco",
  "primary-insurance-add-your-information": "Agregar tarjeta de seguro primaria",
  "secondary-insurance-add-your-information": "Agregar tarjeta de seguro secundaria",
  "photoId": "Agregue la foto I.D.",
  "photo-take-card-picture": "Tome una foto o suba una imagen del lado frontal de la foto emitida por el gobierno I.D. tarjeta",
  "insurance-take-card-picture": "Tome una foto o suba una imagen de ambos lados de la tarjeta de seguro",
  "take-photo": "Toma una foto",
  "choose-photo": "Elija una foto",
  "insurance-bring-to-store": "lo llevaré a la tienda",
  "google-signup": "Regístrese con Google",
  "google-continue": "Continuar con Google",
  "google-login": "Inicio de sesión de Google",
  "google-login-failed": "Error de inicio de sesión de Google",
  "patient-stores": "Encuentra tu tienda",
  "date-is-not-valid": "La fecha no es válida",
  "verify-your-email": "Verifique su dirección de correo electrónico",
  "verify-your-phone": "verifica tu numero de telefono",
  "please-enter-your-information": "Por favor ingrese su información",
  "email-address": "Dirección de correo electrónico",
  "email-is-invalid": "Correo electronico es invalido",
  "passwords-do-not-match": "Las entradas de contraseña no coinciden",
  "password-does-not-meet-criteria": "La entrada de contraseña no cumple con los criterios",
  "follow-reset-password-instructions": "Siga las instrucciones para restablecer su contraseña.",
  "pharmacy-reset-link-sent": "Se ha enviado un correo electrónico a ",
  "need-help": "Necesitas ayuda?",
  "forms": "Formularios",
  "tasks": "Tareas",
  "welcome-to-tasks": "Bienvenida a tareas",
  "create-new-task": "Crea una tarea para comenzar.",
  "new-task-button": "Nueva Tarea",
  "delegate-work-among-employees": "Delegar el trabajo entre los empleados",
  "communicate-a-sync-with-coworkers": "Comunicar a-sync con compañeras de trabajo",
  "track-week-month-quarter-year-tasks": "Seguimiento de tareas semanales, mensuales, trimestrales y anuales",
  "example-task-check-shelves": "Tarea de ejemplo: \"Revisar los estantes en busca de medicamentos vencidos\"",
  "welcome-to-services": "Bienvenido a la configuración del servicio",
  "setup-bookable-service": "Configure servicios reservables para comenzar.",
  "new-service-button": "Nuevo servicio",
  "patients-book-via-mobile": "Los pacientes reservan a través de una aplicación móvil o un portal web",
  "pharmacy-staff-can-also-book": "El personal de farmacia también puede reservar para los pacientes.",
  "use-pharmacy-hours-custom-availability": "Utilice el horario de la farmacia o establezca una disponibilidad personalizada",
  "see-upcoming-appointments": "Ver las próximas citas en el panel",
  "change-availability": "Cambiar disponibilidad",
  "book-first-appointment": "Reserva tu primera cita",
  "new-appointment-button": "Nueva cita",
  "no-upcoming-appointment": "No hay citas próximas",
  "schedule-an-appointment": "Programe una cita para un paciente.",
  "patients": "Pacientes",
  "refill-submissions": "Recarga",
  "refill-submissions-header": "Envíos de recarga",
  "upcoming-refill": "Próxima recarga",
  "user": "Usuario",
  "users": "Usuarios",
  "new-user": "Nuevo usuario",
  "user-details": "Detalles de usuario",
  "title": "Título",
  "role": "Role",
  "closed": "Cerrada",
  "send-message": "Enviar mensaje",
  "revoke": "Revocar",
  "last-active": "Último activo",
  "first-name-is-not-valid": "El nombre no es válido",
  "last-name-is-not-valid": "El apellido no es válido",
  "registered": "Registrado",
  "meds": "Medicamentos",
  "orders": "Pedidos",
  "reminders": "Recordatorios",
  "activity": "Actividad",
  "show": "Mostrar",
  "login-attempts-exceeded": "Se superaron los intentos de inicio de sesión",
  "max-attempts-exceeded": "Máximo de intentos excedidos",
  "account-locked-reason": "Su cuenta ha sido bloqueada porque alcanzó la cantidad máxima de intentos de inicio de sesión no válidos permitidos.",
  "security-reason": "Por motivos de seguridad, elija una de las siguientes opciones para cambiar su contraseña y recuperar su cuenta.",
  "send-me-email": "Envíame un correo electrónico",
  "send-me-sms": "Envíame un mensaje de texto",
  "view-activity-logs": "Ver registros de actividad",
  "resend-invite": "Reenviar invitación",
  "error-sending-invite": "Se produjo un error al enviar la invitación",
  "delete": "Borrar",
  "delete-confirmation": "¿Estás seguro de que quieres eliminar esto?",
  "cannot-undo-action": "No puede deshacer esta acción.",
  "revoke-access-for-authorized-caregiver": "Revocar el acceso significa que {{firstName}} {{lastName}} ya no es uno de sus cuidadores autorizados.",
  "confirm-remove-patient": "Eliminar a {{firstName}} {{lastName}} de tu lista significa que ya no tendrás acceso a los registros de {{firstName}} ni administrarás la salud de {{firstName}}. digitalmente.",  "set-your-preferences": "Paso {{currentStep}} de {{totalSteps}} / Establece tus preferencias",
  "find-your-store": "Encuentra tu tienda",
  "delete-insurance": "Eliminar esta información de seguro",
  "confirm-delete-insurance":"¿Está seguro de que desea eliminar esta información del seguro?",
  "delete-photo-id": "Eliminar esta foto de identificación.",
  "confirm-delete-id": "¿Estás seguro de que deseas eliminar esta foto de identificación?",
  "confirm-your-store": "Paso {{currentStep}} de {{totalSteps}} / Confirma tu tienda",
  "order-submitted": "¡Su pedido ha sido enviado!",
  "photo-id-text": "Foto de identificación",
  "no-id-card": "Sin documento de identidad",
  "relation-to-primary-user": "Relación con el usuario principal",
  "email-capital-case": "Dirección de correo electrónico",
  "standard-text-apply": "Es posible que se apliquen tarifas de mensajería estándar dependiendo\de su proveedor de servicios.",
  "order": "Orden",
  "picking-up-at": "Recogida en",
  "ready-today": "Listo hoy entre",
  "prescribed-for": "Prescrito para",
  "prescriber": "Prescrito",
  "prescriber-info-not-available": "Información no disponible",
  "prescriber-name-not-available": "(Nombre no disponible)",
  "prescriber-phone-not-available": "(Teléfono no disponible)",
  "rx-number": "Número de prescripción",
  "last-filled-on": "Llenado por última vez el",
  "last-ordered-on": "Pedido por última vez el",
  "auto-refills": "Auto-recargas",
  "auto-refill": "Auto-recargas",
  "auto-refill-not-eligible" : "No elegible",
  "auto-refill-requested" : "Solicitado",
  "auto-refill-patient-requested" : "Paciente solicitado",
  "request-auto-refill" : "Solicitar recarga automática",
  "refills-left": "Recargas restantes",
  "quantity-dispensed": "Cantidad dispensada",
  "days-supply": "Suministro de días",
  "directions": "Direcciones",
  "questions-about-medication": "Preguntas sobre este medicamento?",
  "ask-your-pharmacist": "Pregunta a tu farmacéutico",
  "message": "Mensaje",
  "refill": "Rellenar",
  "child": "Niño",
  "spouse": "Esposo",
  "parent": "Padre",
  "grandparent": "Abuelo",
  "grandchild": "Nieto",
  "pet": "Mascota",
  "partner": "Pareja",
  "other": "Otro persona bajo mi cuidado",
  "resources": "RECURSOS",
  "medication-guides": "Guías de Medicamentos",
  "how-to-take": "Cómo tomar este medicamento",
  "patient-education-sheets": "Hojas de Educación para el Paciente",
  "side-effects-interactions": "Efectos secundarios, interacciones medicamentosas",
  "prescription-written": "Fecha de Rx",
  "prescription-by": "Prescripción por",
  "call-prescriber": "Prescriptor: ",
  "done": "Hecho",
  "no-patients-yet": "No hay pacientes todavía...",
  "integrate-pms-to-see-patients": "Integre con su PMS para ver pacientes aquí.",
  "do-this-later": "Haz esto más tarde",
  "entered-prescription-manually": "Ingresado manualmente",
  "your-prescriptions": "Prescripciones",
  "add": "Agregar",
  "drug-name": "Nombre de la medicamento",
  "drug-name-is-required": "Se requiere el nombre del medicamento",
  "step": "Paso",
  "rx-number-is-required": "Se requiere el número de Rx",
  "rx-number-is-duplicated": "El número de receta ya está presente",
  "change": "Cambia",
  "location": "Ubicación",
  "locations": "Ubicaciones",
  "patient-dob": "Fecha de nacimiento del paciente (mm/dd/aaaa)",
  "patient-dob-is-required": "Se requiere la fecha de nacimiento",
  "patient-last-name-is-required": "Se requiere el apellido del paciente",
  "patient-last-name": "Apellido del paciente",
  "we-found-your-medications-alert-title": "Lista de medicamentos encontrados.",
  "unable-to-locate-your-prescription-alert-title": "No se puede localizar la lista de recetas.",
  "unable-to-locate-your-prescription-alert-description": "Confirme que la información a continuación coincida con lo que hay en la etiqueta de prescripción.",
  "prescription-information": "Información de prescripción",
  "integrated-prescription-flow-description": "Ingrese cualquier número de prescripción reciente para conectar la lista de prescripción con la ubicación de farmacia seleccionada.",
  "hi": "Hola, {{firstName}}.",
  "create-your-password": "Por favor crea tu contraseña",
  "i-have-read-and-agree": "He leído y estoy de acuerdo con",
  "pharmacy-terms-and-conditions": "Términos y Condiciones",
  "privacy-policy": "Declaracion de privacidad",
  "terms-and-conditions-required": "Se requiere un acuerdo de términos y condiciones",
  "privacy-policy-required": "Se requiere acuerdo de política de privacidad",
  "at-least-one-selected": "Se debe seleccionar al menos una opción.",
  'click-sections-above': "Haga clic en el botón Atrás para revisar su información. Aún puedes realizar cambios más adelante en la configuración. Haga clic en “Enviar“ para iniciar su farmacia.",
  "number-of-locations": "Número de ubicaciones",
  "pharmacy-taking-off": "Tu farmacia está despegando!",
  "next-steps": "Próximos Pasos",
  "complete-new-account": "Complete la lista de verificación de la nueva cuenta.",
  "employess-to-be-notified": "Se notificará a los empleados para que se registren.",
  "monitor-progress": "Supervise el progreso de su equipo diariamente.",
  "check-progress": "Comprueba el progreso...",
  "go-to-dashboard": "Ir al panel de Control",
  "agreements": "Acuerdos",
  "equipment": "Equipo",
  "employees": "Empleados",
  "addresses": "Direcciones",
  "ein": "EIN",
  "store-address": "Dirección de la tienda",
  "lumistry-setup": "Configuración luminosa",
  "welcome-lumistry-setup": "¡Bienvenido a la configuración de Lumistry! En esta breve guía se le pedirá información de alto nivel sobre su farmacia, que incluye:",
  "account-info": "Informacion de cuenta",
  "location-info": "Información de ubicación",
  "ob-all-hours-desc": "Estos horarios se aplicarán a todas las ubicaciones de la lista.",
  "confirm-information-collected": "Confirmar la información recopilada en el proceso de venta.",
  "location-billing-account": "Ubicación, facturación y cuenta",
  "require-additional-hardware": "Algunos sistemas de gestión de farmacias requieren hardware adicional.",
  "add-your-employees": "Añade a tus empleados",
  "set-hours-timezone-holidays": "Establecer horarios de tienda, zona horaria y días festivos",
  "learn-about-employee-roles": "Más información sobre los roles de los empleados",
  "lumistry-roles": "Roles luminosos",
  "employee-role-determines-action": "El rol de un empleado determina qué acciones tiene permiso para realizar.",
  "handles-organization-permissions": "Maneja todos los permisos administrativos de la organización (por ejemplo, propietario de la farmacia).",
  "location-level-leader": "Líder a nivel de ubicación y tomador de decisiones. Es posible que el personal necesite la aprobación del Gerente para acceder a ciertas funcionalidades dentro de la plataforma.",
  "common-level-of-access": "Nivel de acceso más común. Puede formar parte de Farmacéutico, Técnico, Pasante de Farmacéutico, Cajero, etc.",

  "new-patient": "Nuevo paciente",
  "i-am-a-new-patient": "Soy un nuevo paciente",
  "patient-details": "Detalles del paciente",
  "edit": "Editar",
  "date-of-birth": "Fecha de nacimiento",
  "date-of-birth-short": "FDN",
  "phone-number-footer": "Número de teléfono",
  "edit-phone-number": "Número de Teléfono",
  "edit-mobile-number": "Número de móvil",
  "gender-text": "Género",
  "language": "Idioma",
  "conditions": "Condiciones",
  "easy-open-bottle": "Botella abrefácil",
  "med-sync": "Sincronización Médica",
  "photo-id": "Tarjeta de identificación",
  "photo": "Foto",
  "image": "Imagen",
  "notification": "Notificación",
  "app-notification": "Notificación de la aplicación",
  "delete-image": "Eliminar esta imagen eliminará la información de esta tarjeta",
  "list": "Lista",
  "providers": "Proveedores",
  "notes": "Notas",
  "internal-use-only": "Solo uso interno",
  "continuation-disclaimer": "Al tocar Continuar con Google o Correo electrónico, acepta las condiciones de esta Farmacia",
  "to-get-started-choose": "Para comenzar,\nelija uno de los siguientes:",
  "face-id": "Identificación facial",
  "set-my-store": "Establecer como mi tienda",
  "medical-equipment-immunization": "Inmunizaciones, Equipo médico duradero, Conteo, Consulta de pacientes, Vacuna contra el COVID-19",
  "services-offered": "Servicios ofrecidos",
  "en-es-fr": "Inglés, Español, Francés",
  "languages-spoken": "Idiomas que se hablan en este lugar",
  "no-upcoming-appointments": "No hay citas próximas",
  "no-refills-due": "No hay recargas vencidas",
  "holiday-hours-vary": "El horario de vacaciones puede variar.",
  "open-hours": "Abierto hoy de: {{hours}}",
  "open-today": "Abierta hoy las 24 horas.",
  "retrieving": "Recuperando...",
  "search-by-patient-details": "Buscar por nombre o fecha de nacimiento",
  "store-hours": "Horario de la tienda",
  "upload-csv": "Cargar csv",
  "personal-info": "Información personal",
  "link-expired": "El enlace ha caducado o es inválido, necesitará uno nuevo.",
  "reset-unsuccessful": "Restablecimiento fallido",
  "will-pickup-prescription-today": "¿Recogerá su receta hoy?",
  "field-required": "Este campo es obligatorio",
  "will-pickup-prescription-urgently": "¿Necesita recoger su receta con urgencia?",
  "order-details": "Detalles del pedido",
  "method": "Método",
  "note-for-pharmacy": "Nota para la farmacia (opcional)",
  "note-for-pharmacy-not-optional": "Nota para la farmacia",
  "review": "Revisar",
  "prescription-find-server-error": "Error interno del servidor durante la búsqueda de sus medicamentos",
  "insurance-is-required": "Se requiere seguro",
  "insurance-change-last-refill": "¿Ha cambiado el seguro desde la última recarga?",
  "prescriptions-is-required": "Se requieren recetas",
  "over-the-counter-medications": "Medicamentos de venta libre",
  "enter-patient-information": "Ingrese la información del paciente",
  "please-review-messages-and-proceed": "Revise los mensajes a continuación y háganos saber cómo le gustaría proceder.",
  "how-to-proceed": "Cómo proceder",
  "enter-patient-email": "Ingrese la dirección de correo electrónico del paciente",
  "add-patient-request-submit": "Agregar solicitud de paciente enviada",
  "provide-an-email": "Veuillez fournir l'adresse e-mail que {{firstName}} {{lastName}} utilise pour le compte de {{firstName}} avec {{pharmacyName}}",
  "request-sent": "Solicitud enviada a {{firstName}} {{lastName}}",
  "approval-needed": "Se necesita la aprobación de esta persona dentro de los 14 días.",
  "person-has-an-account": "Si esta persona tiene una cuenta en {{pharmacyName}}, recibirá su solicitud",
  "add-another-patient": "Agregar otra paciente",
  "add-another": "Añadir otro",
  "go-home": "Vete a casa",
  "allow-pharmacy-contact-prescriber": "Permitir que la farmacia se comunique con el prescriptor",
  "continue-with-selected-items": "Continuar con los elementos seleccionados",
  "go-back": "Volver",
  "go-back-update-order": "Volver a actualizar mi pedido",
  "thank-you-for-choosing": "Gracias por elegir",
  "return-to-order-details": "Volver a los detalles del pedido",
  "add-people-under-care": "Agregar personas bajo su cuidado",
  "add-person": "Agregar una persona",
  "add-people-under-care-message-pt-1": "Vous pouvez trouver la personne que vous souhaitez ajouter en fournissant ses informations sur la page suivante",
  "add-people-under-care-message-pt-2": "Si la persona que está agregando tiene más de 18 años, también deberá proporcionar la dirección de correo electrónico que usa para su cuenta en",
  "we-will-process-following-items": "Continuaremos con el procesamiento de los siguientes artículos a menos que los elimine de su pedido",
  "unable-to-process-items": "No se pueden procesar uno o más elementos",
  "cannot-process-items": "Lo siguiente no se puede procesar y se eliminará de su pedido",
  "return-to-medications": "Volver a Medicamentos",
  "no-item-left-in-order-cancel": "Te queda un artículo en tu pedido. Quitarlo cancelará su pedido.",
  "no-item-left-in-order-cancelled": "No te quedan artículos en tu pedido. Su pedido será cancelado.",
  "one-or-more-items-to-review" : "Uno o más elementos para revisar",
  "we-will-contact-prescriber": "Nos comunicaremos con su prescriptor para obtener la autorización de las siguientes recetas.",
  "please-review-the-messages-below": "Revise los mensajes a continuación y háganos saber cómo le gustaría proceder.",
  "ready-to-be-filled": "Listo para ser llenado",
  "other-details": "Otros detalles",
  "store-info": "Información de la tienda",
  "account-setup": "Configuración de cuenta",
  "other-information": "Otra información",
  "notice": "Aviso",
  "insurance": "Seguro",
  "add-patient": "Agregar paciente",
  "manage-prescriptions": "Gestiona tus recetas digitalmente",
  "pick-up-prescriptions": "Recoge tus recetas",
  "discuss-care": "Hable sobre su atención con un miembro del equipo de {{pharmacyName}}",
  "manage-health": "Administre sus citas de servicios clínicos y de salud",
  "request-status": "Pendiente: enviado el {{submittedDate}}",
  "puc-permission-access-to-records": "Confirmo que {{firstName}} {{lastName}} tiene permiso para acceder a mis registros y administrar la salud digitalmente y que puedo revocar el acceso en cualquier momento",
  "if-you-authorize-person": "Si autoriza esta solicitud, entonces esta persona puede:",
  "access-validity": "Este acceso es válido hasta que lo revoques.",
  "api-required": "Se requiere la clave API",
  "legal-guardian-confirmation-for-person": "Usted acepta que es padre o tutor legal de la persona que está agregando",
  "legal-guardian-agreement": "Sí, soy padre o tutor legal de {{firstName}} {{lastName}}.",
  "confirmation": "Confirmación",
  "no-notes-attached-to-patient": "No hay notas adjuntas a este paciente",
  "add-minor-or-pet-patient": "Agregar paciente menor o mascota",
  "your-allergies": "Alergias",
  "revoke-access": "Revocar acceso",
  "authorized-on": "Autorizado el {{authorizedDate}}",
  "revoke-access-in-settings": "Puede revocar el acceso yendo a su Información médica en Configuración de la cuenta",
  "review-request": "Solicitud de revisión...",
  "authorize": "Autorizar",
  "decline": "Rechazar",
  "authorized-caregivers": "Cuidadores autorizados",
  "update-weak-password": "Su contraseña no cumple con los requisitos actuales. Para la seguridad y protección de su cuenta, cree una nueva.",
  "close": "Cerrar",
  "unable-to-submit-people-under-care-request": "No se pueden enviar personas bajo solicitud de atención",
  "your-password-has-been-successfully-changed": "Su contraseña ha sido cambiada exitosamente",
  "people-under-your-care": "Personas bajo su cuidado",
  "appointments": "Equipo",
  "add-medications": "Agregar medicamentos",
  "add-meds-text": "Puede pedir resurtidos de recetas fácilmente y administrar medicamentos agregándolos ahora, u omita este paso para hacerlo más tarde",
  "prescriptions-at-file": "Las recetas están archivadas en {{pharmacyName}}",
  "request-to-transfer": "Solicitud de transferencia de recetas de una farmacia diferente",
  "skip-this-step": "Saltar este paso",
  "do-this-medications-tab": "Puede hacerlo en la pestaña Medicamentos",
  "account": "Cuenta",
  "your-account": "Su Cuenta",
  "linked-accounts": "Cuentas vinculadas",
  "linked-accounts-description": "Caregivers, patients under care",
  "another-account": "Otra cuenta",
  "greeting": "Buen día",
  "people-under-your-care-empty-page": "Si está administrando recetas y citas para otras personas o mascotas, tenerlas todas en una sola lista es una forma conveniente de realizar un seguimiento de los medicamentos y los horarios de todos, lo que puede ayudar a evitar renovaciones y citas perdidas",
  "add-person-or-pet": "Agregar persona o mascota",
  "relationship-to-me": "Relación conmigo",
  "medical-info": "Información médica",
  "none": "Ninguno",
  "refill-details": "Detalles de recarga",
  "success": "Éxito",
  "prescription-no": "Prescripción No.",
  "name": "Nombre",
  "status": "Estado",
  "submitted": "Presentado",
  "channel": "Canal",
  "delivery-method": "Método de entrega",
  "refill-comments": "Comentarios de recarga",
  "patient-info": "Información del paciente",
  "details": "Detalles",
  "phone": "Teléfono",
  "address": "Dirección",
  "email-text": "Correo electrónico",
  "prescription": "Prescripción",
  "patient": "Paciente",
  "monday": "Lunes",
  "tuesday": "Martes",
  "wednesday": "Miércoles",
  "thursday": "Jueves",
  "friday": "Viernes",
  "saturday": "Sábado",
  "sunday": "Domingo",
  "date-submitted": "Fecha Enviado",
  "allergies-colon": "Alergias: ",
  "medical-conditions": "Condiciones medicas: ",
  "refill-past-due": "Recarga vencida:",
  "caregiver-approval-agreement": "Acuerdo de aprobación del cuidador",
  "no-known-allergies": "Sin alergias conocidas",
  "known-allergies": "Alergias conocidas",
  "allergies-required": "Ingrese las alergias o seleccione \"Sin alergias conocidas\"",
  "more-information": "Más información",
  "monthly-prescriptions": "¿Le gustaría que estas recetas estén listas al mismo tiempo cada mes?",
  "as-an-authorized-caregiver": "Como cuidador autorizado, usted puede:",
  "manage-prescriptions-digitally": "Administre las recetas de {{firstName}} digitalmente",
  "order-refills": "Pedir recargas para {{firstName}}",
  "order-refills-prescriptions": "Pedir recargas para recetas",
  "delivery": "Entrega",
  "mail": "Correo",
  "curbside": "Acera",
  "would-like-easy-open-caps": "Seleccione una preferencia de tapa de botella.",
  "prefers-easy-open-caps": "Tapas de fácil apertura (no de seguridad)",
  "prefers-safety-caps": "Tapas (de seguridad) a prueba de niños",
  "pick-up-prescriptions-for": "Recoger recetas para {{firstName}}",
  "basic-info": "Información básica",
  "discuss-care-with": "Hable sobre la atención de {{firstName}} con un miembro del equipo de {{pharmacyName}}",
  "manage-health-and-clinic": "Discutez des soins de {{firstName}} avec un membre de l'équipe de {{pharmacyName}}",
  "access-validity-puc": "Este acceso es válido hasta que {{firstName}} cumpla 18 años o hasta que elimine a {{firstName}} de su lista de personas bajo su cuidado.",
  "access-validity-puc-pet": "Este acceso es válido hasta que elimine a {{firstName}} de su lista de pacientes bajo su cuidado.",
  "update-puc-list": "Puede actualizar su lista yendo a Personas bajo mi cuidado en Configuración de la cuenta",
  "patient-record-created": "Registro de paciente creado para {{firstName}} {{lastName}}",
  "notification-preference": "Preferencias de notificación",
  "notification-preferences-details": "Las notificaciones se utilizan para avisarle cuando su pedido de receta está listo, cuando tiene una próxima cita o cuando recibe un nuevo mensaje de su equipo de farmacia.",
  "sms-text-message": "SMS (mensaje de texto)",
  "refill-due": "Refill debido:",
  "store": "Comercio",
  "app": "App",
  "allergies": "Alergias",
  "medications-empty-state-title": "Medicamentos",
  "medications-empty-state-description": "¡Agregar sus medicamentos facilitará el seguimiento y la solicitud de renovaciones de recetas importantes que lo ayudarán a mantenerse saludable!",
  "medications-reminders-empty-state-title": "Recordatorios de medicamentos",
  "medications-reminders-empty-state-description": "Los recordatorios de medicamentos son una buena manera de mantenerse al tanto de sus medicamentos que pueden ayudar a evitar riesgos innecesarios y enfermedades graves",
  "add-meds": "Agregar medicamentos",
  "transfer-meds": "Transferir medicamentos de otra farmacia",
  "transfer-meds-tab": "Transferir Meds",
  "pickup": "Levantar",
  "select-an-option": "Seleccione una opción",
  "choose-from-the-list": "Por favor, elija de la lista de artículos antes de hacer clic en el botón de pedido",
  "select-one-item": "Selecciona al menos un elemento",
  "show-more": "Mostrar más",
  "street1": "Calle 1",
  "street2": "Calle 2",
  "gender-on-insurance": "Género (como aparece en el seguro)",
  'prescription-help-copy': 'Ayuda con recetas, servicios, etc',
  "preferred-language": "Idioma preferido",
  "notification-preferences": "Preferencias de notificación",
  "auto-voice-call": "Llamada de voz automática",
  "edit-patient": "Editar paciente",
  "show-less": "Muestra meno",
  "please-confirm-the-info-matches-prescription-label": "Confirme que la información a continuación coincide con la etiqueta de su receta",
  "register-confirmation-heading": "Configuración de nueva cuenta",
  "register-confirmation-body": "Se ha enviado un nuevo enlace de configuración de cuenta a su correo electrónico. Por favor revise su correo electrónico y haga clic en el enlace proporcionado.",
  "orders-tab-empty-state-title": "Historial de pedidos",
  "orders-tab-empty-state-description": "¡Realizar un pedido de recarga es fácil desde la pestaña Meds! Después de haber realizado un pedido, los detalles y el seguimiento aparecerán aquí",
  "start-an-order": "Iniciar un pedido",
  "show-locations": "Mostrar ubicaciones",
  "first-name-edit-user": "Nombre",
  "last-name-edit-user": "Apellido",
  "dob-edit-user": "Fecha de nacimiento (mm/dd/yyyy)",
  "information": "Información",
  "intro": "Introducción",
  "enter-email": "Ingresar correo electrónico",
  "create-password": "Crear contraseña",
  "register-confirmation": "Confirmación de registro",
  "register": "Registrarse",
  "reset-link": "Restablecer enlace",
  "reset-successful": "Restablecimiento exitoso",
  "register-details": "Registrar detalles",
  "phone-verification": "Verificación del teléfono",
  "patient-pharmacy-stores": "Tiendas de farmacia para pacientes",
  "add-prescription": "Agregar receta",
  "refill-guest": "Recargar invitado",
  "home": "Hogar",
  "settings": "Configuraciones",
  "store-location": "Ubicación de la tienda",
  "expiring-soon": "Expirando pronto",
  "schedule": "Horario",
  "find-time": "Encontrar una hora",
  "learn-more": "Aprender más",
  "next-available": "Siguiente disponible",
  "not-available": "Actualmente no disponible",
  "minutes": "Minutos",
  "book-appointment": "Reservar Cita",
  "upcoming": "Próximo",
  "past": "Pasado",
  "patient-insurance": "Seguro del paciente",
  "barcode": "Código de barras",
  "patient-preferences": "Preferencias del paciente",
  "feedback-success": "Éxito de la retroalimentación",
  "people-under-care": "Personas bajo tu cuidado",
  "add-patient-under-care": "Agregar paciente bajo cuidado",
  "add-patient-under-care-info": "Agregar información de paciente bajo cuidado",
  "add-patient-under-care-email": "Agregar correo electrónico de paciente bajo cuidado",
  "add-patient-under-care-confirmation": "Agregar paciente bajo confirmación de atención",
  "patient-under-care-list": "Lista de pacientes bajo cuidado",
  "contact-info": "Información de contacto",
  "no-puc-access": "Actualmente no tiene permiso para acceder al registro de este paciente. Para obtener acceso, vaya a la configuración de su cuenta y agréguelo como Paciente bajo cuidado.",
  "automatic-prescription-flow": "Flujo de prescripción automática",
  "confirmation-message-sent": "Te enviaremos un mensaje para confirmar tu nuevo número de teléfono.",
  "confirmation-email-sent": "Le enviaremos un correo electrónico para confirmar su nueva dirección de correo electrónico.",
  "update-info": "Actualizar {{title}}",
  "verify-info": "Verificar {{title}}",
  "success-automatic-prescription-flow": "Flujo de prescripción automática de éxito",
  "on-hold": "En espera",
  "rejected-reason-unknown": "Razón del rechazo desconocida",
  "select-person": "Seleccione el paciente para esta cita. Si no los ve en la lista, deberá agregarlos primero.",
  "myself": "Mí mismo",
  "confirm": "Confirmar",
  "error-loading-appointments": "Error al cargar citas",
  "appointment-scheduled": "la cita está programada",
  "booking-error": "Algo salió mal al intentar reservar la cita. Inténtalo de nuevo.",
  "error-loading-slots": "Error al cargar los espacios para citas",
  "error-loading-patient-record": "Error al cargar el registro del paciente",
  "error-updating-patient-record": "Error de actualización del registro del paciente",
  "error-booking-appointment": "Error al reservar la cita",
  "when": "Cuando",
  "who": "Quien",
  "where": "Donde",
  "vaccine-information-statement": "Declaración de información sobre vacunas",
  "vaccine-benefits-and-risks": "Beneficios y riesgos de una vacuna",
  "care-notes": "Notas de cuidado",
  "reschedule": "Reprogramar",
  "cancel-appointment": "Cancelar cita",
  "error-loading-appointment": "Error al cargar la cita",
  "error-loading-appointment-type": "Error al cargar el tipo de cita",
  "refill-medications": "refill-medications",
  "add-order": 'Agregar Pedido',
  "refill-medical-infos": "refill-medical-infos",
  "refill-review": "refill-review",
  "refill-other-information": "refill-other-information",
  "refill-prescription-method": "refill-prescription-method",
  "refill-insurance-method": "refill-insurance-method",
  "refill-confirmation": "refill-confirmation",
  "refill-order-details": "refill-order-details",
  "good-morning": "Buen día",
  "good-afternoon": "Buenas tardes",
  "good-evening": "Buena noches",
  "you-are-chatting-with": "Estás chateando con:",
  "new-message": "Nuevo mensaje",
  "no-messages": "Ningún mensaje",
  "messages-have-a-question": "Tengo una pregunta? Necesitas cambiar un pedido? Envíanos un mensaje seguro.",
  "new-message-placeholder": "Mensaje...",
  "general": "General",
  "billing": "Facturación",
  "med-info": "Con información",
  "side-effects": "Efectos secundarios",
  "message-subject-label": "Seleccione el tema que mejor se adapte a su mensaje:",
  "send": "Enviar",
  "appointment-rescheduled": "se ha reprogramado la cita",
  "error-rescheduling-appointment": "Error al reprogramar la cita",
  "cancel-appointment-confirmation": "¿Está seguro de que desea cancelar esta cita?",
  "appointment-canceled": "cita cancelada",
  "previously-scheduled": "Programado previamente:",
  "cancel-note": "Nota: Cancelar esta cita no la eliminará de los calendarios de terceros a los que se haya agregado.",
  "cancel-error": "Algo salió mal al intentar cancelar la cita. Vuelve a intentarlo.",
  "error-cancel-appointment": "Error al cancelar la cita",
  "keep": "Mantener",
  "of": "de",
  "prescription-card-last-filled": "Ultima recarga",
  "prescription-card-last-ordered": "Último pedido:",
  "prescription-card-refill-due": "Recarga vencida",
  "remove": "Elminar",
  "remove-patient": "Elminar paciente",
  "user-not-found": "User no encontrado",
  "invalid-confirmation-code": "Código de confirmación inválido",
  "contact-support": "Soporte de contacto",
  "many-failed-attempts": "Muchos intentos fallidos",
  "not-authorized": "No autorizado",
  "retrieve-your-prescriptions": "Recuperar la lista de recetas",
  "other-meds": "Agregue medicamentos no en esta lista",
  "try-again": "Intentar otra vez",
  "notify-you": "Notificarte",
  "reschedule-appointment": "Re-agenda de citas",
  "tomorrow": "Mañana",
  "today": "Hoy",
  "canceled": "Cancelado",
  "events": "eventos",
  "error-field-number": "El campo debe ser un número",
  "no-appointments": "Actualmente no tiene citas.\nSeleccione un servicio para comenzar.",
  "no-services-booking": "No hay servicios disponibles para reservar.",
  "video-header": "Vídeos",
  "videos": "Vídeos",
  "videos-filter": "FILTRAR",
  "videos-clear": "Claro",
  "videos-all": "Todo",
  "videos-medication": "Medicamento",
  "videos-condition": "Condición",
  "videos-administration": "Administración",
  "videos-general-health": "Salud general",
  "videos-missing-medication-video": "Video de medicamento faltante",
  "videos-search": "Buscar",
  "videos-share": "Compartir",
  "videos-category": "Categoría",
  "videos-title": "Título",
  "videos-language-spanish": "Ver en Español",
  "videos-language-english": "Ver en Inglés",
  "videos-language-french": "Ver en Francés",
  "videos-duration": "Duración",
  "videos-url": "URL",
  "copied-to-clipboard": "Copiado al portapapeles",
  "videos-med-guides": "Guías médicas",
  "videos-transcript": "Transcripción",
  "delete-confirmation-modal": "¿Está seguro de que desea eliminar el {{itemType}}: {{itemName}}?",
  "service": "Servicio",
  "create-appointment": "Crear una cita",
  "limit-booking-duration": "Limitar la duración total de la reserva",
  "limit-future-bookings": "Limitar futuras reservas",
  "limit-booking-frequency": "Limitar frecuencia de reserva",
  "before-event": "Antes del evento",
  "after-event": "Después del evento",
  "into-future": "hacia el futuro",
  "within-date-range": "Dentro de un rango de fechas",
  "add-limit": "Añadir límite",
  "calendar-days": "días calendario",
  "business-days": "días laborables",
  "per-day": "Por día",
  "per-week": "Por semana",
  "per-month": "Por mes",
  "no-buffer-time": "Sin tiempo de búfer",
  "content-box-title": "Visualización de tareas que coinciden con los siguientes criterios:",
  "duration": "Duración",
  "time": "Tiempo",
  "check-later": "Por favor, vuelva más tarde",
  "birthdate": "Fecha de nacimiento",
  "actions": "Comportamiento",
  "service-record-enabled-tooltip": "Marcar el servicio como no disponible para programar citas",
  "service-record-disabled-tooltip": "Habilitar hacer citas para este servicio",
  "copy-link-button-tooltip": "Copiar al portapapeles",
  "edit-service-record-tooltip": "Editar servicio",
  "delete-service-record-tooltip": "Eliminar servicio",
  "selecting-all-pages": "Selección de todas las páginas ({{count}} elementos)",
  "select-all-pages": "Seleccionar todas las páginas",
  "selecting-current-page": "Seleccionar solo esta página ({{count}} elementos)",
  "select-current-page": "Seleccionar solo esta página ",
  "unselect-all": "Deselecciona todo",
  "others": "otros",
  "reorder": "Reordenar",
  "order-refill": "Recarga de pedido",
  "generic-bulk-confirmation-modal": "¿Está seguro de que desea {{bulkAction }} {{count}} {{type}} {{changeType}}?",
  "task": "tarea",
  "bulk-assignee-confirmation-modal": "Seleccione el asignado para {{count}} {{type}}.",
  "bulk-due-date-confirmation-modal": "Seleccione la fecha de vencimiento para {{count}} {{type}}",
  "unresolved": "Sin resolver",
  "resolved": "Resuelto",
  "in_progress": "En Progreso",
  "on_hold": "En espera",
  "task-resolve": "Tareas Resolver",
  "mark-resolved": "Marcar como resuelto",
  "mark-in-progress": "Marcar como en progreso",
  "mark-flagged": "Marcar como marcado",
  "mark-unresolved": "Marcar como no iniciado",
  "mark-on-hold": "Marcar como en espera",
  "change-assignee": "Cambiar Usuario Asignado",
  "change-due": "Cambiar fecha de vencimiento",
  "task-in-progress": "Tareas en Progreso",
  "task-flag": "Indicador de tareas",
  "assignee": "cesionario",
  "not-started": "No iniciado",
  "delete-task": "Eliminar tarea",
  "venue": "Evento",
  "over-the-counter": "De venta libre",
  "private": "Privado",
  "public": "Público",
  "enable-form": "Habilitar formulario",
  "disable-form": "Desactivar formulario",
  "your-pharmacy": "Tu farmacia",
  "edit-availability-record-tooltip": "Editar disponibilidad",
  "delete-availability-record-tooltip": "Eliminar disponibilidad",
  "availability": "Disponibilidad",
  "new-chat-message": "Nuevo Mensaje de Chat",
  "patient-is-required": "¡Se requiere paciente",
  "subject-is-required": "¡El asunto es obligatorio",
  "message-or-attachment-is-required": "¡Se requiere mensaje o archivo adjunto",
  "message-characters-limit-five-thousand": "¡El límite de caracteres del mensaje es 5000",
  "find-template": "Buscar plantilla",
  "to": "A",
  "subject": "Sujeto",
  "note-inbox-messages-should-be-clinical-in-nature": "Nota: Los mensajes de la bandeja de entrada deben ser de naturaleza clínica. El envío de mensajes de marketing a través de esta plataforma podría violar la Ley de Protección al Consumidor Telefónico y las regulaciones de la Comisión Federal de Comunicaciones. No solicite información de tarjetas de crédito.",
  "all": "Todo",
  "unread": "No leído",
  "unread-by-patient": "No leído por el paciente",
  "includes-me": "Me incluye",
  "created-by-me": "Creado por mí",
  "last-seven-days": "Los últimos 7 días",
  "filters": "Filtros",
  "clear": "Despejar",
  "send-attachment": "Enviar archivo adjunto",
  "type-your-message": "Escribe tu mensaje...",
  "mark-as-unread": "Enmascarar como no leído",
  "chat-messages": "Mensajes de chat",
  "unread-messages": "Mensajes no leídos",
  "find-patient": "Encontrar paciente",
  "new-subject": "Nuevo tema",
  "add-mobile-number-send-message": "Añadir un número de móvil para enviar un mensaje",
  "add-mobile-number": "Añadir número de móvil",
  "newest": "El mas nuevo",
  "oldest": "Más antiguo",
  "a-z": "A-Z",
  "new-chat": "Nueva conversación",
  "auto": "Auto",
  "previous-week": "Semana anterior",
  "current-week": "Semana actual",
  "next-week": "Próxima semana",
  "secure-message": "mensaje seguro",
  "unable-to-process": "No se pudo procesar su solicitud",
  "try-again-later": "Inténtelo de nuevo más tarde. Si necesita asistencia inmediata, comuníquese con su farmacia.",
  "message-pharmacy": "Mensaje farmacia",
  "call-pharmacy": "Llamar a la farmacia",
  "tasks-grid-no-results-title": "Tu criterio de búsqueda no tiene ninguna tarea en el marco de tiempo actual",
  "tasks-grid-no-results-subtitle": "No pudimos encontrar ninguna tarea en el marco de tiempo actual según su vista y filtros. Use los botones Anterior / Siguiente para navegar en el tiempo, o el botón Semana actual para restablecer la vista a los valores predeterminados.",
  "get-directions": "Obtener las direcciones",
  "explore-other-locations": "Explore otras ubicaciones que ofrecen este servicio",
  "error-getting-locations": "Error al obtener ubicaciones",
  "zip-code-is-not-valid": "El código postal no es válido",
  "street-1-is-not-valid": "La calle 1 no es válida",
  "street-2-is-not-valid": "La calle 2 no es válida",
  "allergies-are-not-valid": "Las alergias no son válidas, un ejemplo correcto es all1,all2,all3...",
  "conditions-are-not-valid": "Las condiciones no son válidas, un ejemplo correcto es cond1,cond2,cond3...",
  "make-appointment": "Hacer una cita",
  "contact-pharmacy-for-assistance": "Comuníquese con la farmacia para obtener más ayuda para reservar su cita.",
  "get-more-stores": "Obtener más tiendas",
  "error-loading-patients-under-care": "Error al cargar pacientes bajo cuidado",
  "add-new-patient": "Agregar nuevo paciente...",
  "after-adding-patient": "Después de agregar un nuevo paciente, regrese a Citas y seleccione el servicio deseado.",
  "pending-requests": "Solicitudes pendientes:",
  "add-calendar-event": "Agregar al calendario",
  "by-application": "Por aplicación",
  "by-email": "Por correo electrónic",
  "by-sms": "Por SMS",
  "by-voice": "Por voz",
  "error-loading-patient": "Ocurrió un error al tratar de obtener al paciente. Vuelva a intentarlo.",
  "in": "en",
  "ago": "hace",
  "month-short": "mes",
  "hour-short": "hora",
  "day-short": "día",
  "missing-important-patient-record-data": "Al registro del paciente le faltan datos de importación.",
  "google": "Google",
  "apple": "Apple",
  "outlook": "Outlook",
  "reset-filters": "Restablecer filtros",
  "calendar": "Calendario",
  "no-medication-guide": "Actualmente no disponible. Si tiene preguntas sobre este medicamento, comuníquese con su farmacia.",
  "appointment-panel": "Panel de citas",
  "age": "Edad",
  "confirmed": "Confirmado",
  "pending": "Pendiente",
  "rejected": "Rechazado",
  "service-description": "Descripción del servicio",
  "additional-info": "Información adicional",
  "more": "Mas",
  "less": "Menos",
  "month-capital": "Mes",
  "week-capital": "Semana",
  "day-capital": "Día",
  "dont-see-service": "¿No ves lo que necesitas?",
  "service-category": "Categoría de servicio",
  "category": "Categoría",
  "service-category-filter": "Categoría de servicio",
  "service-type": "Tipo de servicio",
  "all-services": "Todos los servicios",
  "all-service-categories": "Todas las categorías de servicios",
  "all-service-types": "Todo tipo de servicios",
  "team": 'Equipo',
  "personal": "Personal",
  "last-day": "Último día",
  "reoccurrence-must-have-context": "El campo de recurrencia debe tener un contexto de forma principal",
  "start-date-lower-end-date": "La fecha de inicio debe establecerse antes de la fecha de finalización.",
  "reschedule-note": "Nota: Reprogramar esta cita no la actualizará en ningún calendario de terceros al que se haya agregado.",
  "ordered": "Ordenada",
  "character-count": "Limitar {{max}} caracteres incluidas las variables. Contar: {{count}}",
  "maxim-count-attachments": "El recuento máximo de archivos adjuntos es {{count}}.",
  "selecting-files-wrong": "Algo salió mal al seleccionar archivos.",
  "files-sending-error-try-again": "Algo salió mal al enviar archivos. Inténtalo de nuevo.",
  "home-account-subheading": "Ajustes, seguros, ayuda, etc.",
  "home-medications-subheading": "Administre medicamentos y ordene recargas",
  "home-messages-subheading": "Chatee de forma segura con la farmacia",
  "home-appointments-subheading": "Ver o programar servicios clínicos",
  "home-puc-subheading": "Agregar una persona...",
  "create-form-first": "Por favor crea un formulario primero",
  "upload": "Subir",
  "replace": "Reemplazar",
  "front-photo-missing": "Se requiere el frente de la tarjeta",
  "back-photo-missing": "Se requiere el reverso de la tarjeta",
  "town-pharmacy": "Farmacia Municipal",
  "pharmacy-general-info": "Información general",
  "pharmacy-edit-business-info": "Editar información comercial",
  "pharmacy-general-details": "Detalles",
  "pharmacy-general-info-limited-brand-name": "Limitada a 16 caracteres",
  "pharmacy-company-name": "Farmacia Nombre corto",
  "pharmacy-brand-full-name": "Farmacia Nombre completo",
  "pharmacy-brand-admin": "Administrador de marca",
  "pharmacy-parent-company": "Empresa matriz",
  "pharmacy-ncpdp": "NCPDP",
  "pharmacy-npi": "NPI",
  "pharmacy-pms": "PMS",
  "pharmacy-facebook": "Facebook",
  "pharmacy-instagram": "Instagram",
  "pharmacy-twitter": "X",
  "pharmacy-contact-info": "Datos de contacto",
  "pharmacy-edit-contact-info": "Editar información de contacto",
  "pharmacy-email-address": "Dirección de correo electrónico",
  "pharmacy-phone-number": "Número de teléfono",
  "pharmacy-fax-number": "Número de fax",
  "pharmacy-mailing-address": "Dirección de envio",
  "pharmacy-billing-address": "Dirección de Envio",
  "pharmacy-brand-name-length": "El nombre no debe exceder los 16 caracteres.",
  "pharmacy-npi-length": "El NPI debe 10 caracteres",
  "pharmacy-departments": "Departamentos",
  "pharmacy-names-details": "Nombres",
  "pharmacy-edit-default-department-hours": "Editar horas de departamento predeterminadas",
  "pharmacy-add-default-department-hours": "Agregar horas de departamento predeterminadas",
  "pharmacy-delete-department": "¿Quieres eliminar el Departamento?",
  "pharmacy-delete-department-info": "Al confirmar eliminarás el departamento seleccionado.",
  "pharmacy-departments-name": "Departamento",
  "pharmacy-departments-hours": "Horario del departamento",
  "pharmacy-departments-hours-subtitle": "El horario predeterminado para todas las tiendas. Las tiendas individuales pueden anular esta configuración.",
  "pharmacy-departments-name-required": "El nombre del departamento es obligatorio.",
  "pharmacy-departments-add-hours": "Agregar horas",
  "pharmacy-departments-remove-hours": "Eliminar",
  "locations-all": "Todas las localizaciones",
  "locations-new": "Nueva tienda",
  "find-by-name-address-or-ncpdp": "Buscar por nombre, dirección o NCPDP",
  "locations-name": "Nombre",
  "locations-tag": "Etiqueta",
  "locations-status": "Estado",
  "locations-NCPDP": "ncpdp",
  "locations-address": "DIRECCIÓN",
  "locations-pharmacy-info": "Información de farmacia",
  "locations-contact-info": "Datos de contacto",
  "locations-teams": "Equipos",
  "locations-groups": "Grupos",
  "locations-services": "Servicios",
  "pick-up-methods": "Métodos de recogida",
  "pick-up-methods-description": "Establezca los mensajes de confirmación de recogida para esta ubicación.",
  "locations-normal-hours": "Horas normales",
  "locations-holiday-closures": "Cierres de vacaciones",
  "locations-holiday-closures-description": "Anualmente recurrente durante todo el día.",
  "locations-additional-closures": "Cierres adicionales",
  "locations-additional-closures-description": "Cierres únicas por hora o todo el día.",
  "locations-edit-pharmacy-info": "Editar información de farmacia",
  "locations-edit-contact-info": "Editar información de contacto",
  "locations-edit-department-hours": "Editar horario de departamento",
  "locations-location-name": "Nombre de farmacia (ubicación)",
  "locations-location-name-tooltip": "El nombre asociado con su NCPDP.Puede ser lo mismo que DBA.",
  "locations-internal-name": "Nombre interno",
  "locations-internal-name-tooltip": "Esto aparece en la plataforma de Lumistry y solo visto por empleados de farmacia.",
  "locations-NPI": "NPI",
  "locations-store-number": "Número de tienda",
  "locations-time-zone": "Zona horaria",
  "locations-latitude": "Latitud",
  "locations-longitude": "Longitude",
  "locations-phone-number": "Número de teléfono",
  "locations-fax-number": "Número de fax",
  "locations-email-address": "Dirección de correo electrónico",
  "locations-mailing-address": "Dirección de envio",
  "locations-edit-in-store-method": "Editar método en la tienda",
  "locations-edit-pick-up-method": "Editar método de recogida",
  "locations-active": "Activa",
  "locations-inactive": "Inactiva",
  "locations-required": "Se requiere campo",
  "locations-departments-remove-hours": "Eliminar",
  "locations-departments-add-hours": "Agregar horas",
  "pharmacy-holiday-closures": "Cierres de vacaciones",
  "pharmacy-holiday-closures-description": "Cierres de tiendas recurrentes anualmente.",
  "pharmacy-additional-closures": "Cierres adicionales",
  "pharmacy-additional-closures-description": "Estos no son recurrentes.",
  "pharmacy-additional-closures-active": "Activa",
  "pharmacy-additional-name-required": "Se requiere el nombre de cierre",
  "pharmacy-additional-date-required": "Se requiere fecha de cierre",
  "pharmacy-additional-hour-required": "Se requiere un intervalo de horas de cierre",
  "pharmacy-additional-label": "Etiqueta",
  "locations-default": "Por defecto",
  "locations-department": "Departamento",
  "locations-departments-name-required": "Se requiere el nombre del departamento.",
  "locations-use-default-settings": "utilizar la configuración predeterminada",
  "wrong-date-format": "¡Fecha equivocada!",
  "all-day": "todo el dia",
  "tags": "Etiquetas",
  "new": "Nueva",
  "url-is-not-valid": "La URL no es válida",
  "ok": "OK",
  "no-allergies": "Sin alergias",
  "easy-open-bottle-caps-confirm": "¿Quieres tapas de botellas fáciles de abrir?",
  "allergy-info": "Confirma que la información sobre alergias esté actualizada",
  "forms-fill-out": "Complete {{formsCount}} formularios requeridos...",
  "forms-filled-in": "Llene los formularios requeridos",
  "health-info": "Revisar información de salud relacionada",
  "insurance-card-reminder": "Traer tarjeta de seguro",
  "unable-to-save-changes-try-again": "No se pueden guardar los cambios, inténtalo de nuevo más tarde",
  "pre-appointment-checklist": "Lista de verificación previa a la cita",
  "related-health-info": "Información de salud relacionada",
  "add-patients-start-messaging": "Agregue a sus pacientes para comenzar a enviar mensajes",
  "upload-patients-csv": "Subir pacientes a través de CSV",
  "error-updating-appointment": "Error al reprogramar la cita",
  "message-body-is-required": "El cuerpo del mensaje es obligatorio",
  "verify-patient": "Marcar al paciente como verificado",
  "verify-patient-warning": "Este registro de paciente no se originó en su software de farmacia. Se creó cuando esta persona se registró para iniciar sesión en una cuenta del portal del paciente. Asegúrese de verificar la identidad de esta persona antes de intercambiar información confidencial.",
  "no-forms-required": "No se requirieron formularios para esta cita",
  "form-unavailable": "El formulario ya no está disponible",
  "form-not-started": "Formulario no iniciado",
  "form-submitted": "El formulario ha sido enviado",
  "preparing-submission": "Preparando el envío",
  "download-will-begin": "Su descarga comenzará en breve.",
  "delete-submission-confirmation": "¿Está seguro de que desea eliminar este envío?",
  "not-entered": "No ingresado",
  "submitted-on": "Enviado el",
  "download-pdf": "Descargar PDF",
  "submission-modal": "Modal de envío",
  "medical-information": "Informacion medica",
  "yes-easy-caps": "Sí, prefiero las tapas de fácil apertura",
  "no-safety-caps": "No, prefiero tapas de seguridad",
  "easy-open-bottle-caps-description": "Las reglamentaciones federales exigen que la mayoría de los medicamentos se empaqueten con tapas de seguridad a prueba de niños. Su elección de tapas de fácil apertura indica su deseo de dispensar los medicamentos sin tapas de seguridad. ",
  "checklist-complete": "Lista de verificación completada",
  "checklist-incomplete": "Por favor complete la lista de verificación",
  "service-status-updated": "Estado del servicio actualizado correctamente",
  "error-loading-form": "Error al cargar el formulario",
  "account-not-found-heading": "Cuenta no encontrada",
  "account-not-found-subheading": "Si creó su cuenta utilizando su inicio de sesión de Apple o Google, inicie sesión con esa cuenta",
  "account-not-found-subheading-sso": "Si creó su cuenta con su dirección de correo electrónico, inicie sesión con su correo electrónico",
  "close-confirmation-question": "¿Seguro que quieres salir?",
  "close-confirmation-text": "Este formulario es necesario para programar su cita.",
  "invalid-email-format": "Formato de correo electrónico no válido",
  "close-confirmation-exit": "Salida",
  "close-confirmation-keep-appointment": "Mantener citas",
  "password-reset-subheading": "Para la seguridad y protección de su cuenta, cree una nueva contraseña.",
  "save-password": "Guardar contraseña",
  "venue-in-person": "En Persona",
  "venue-virtual": "Video",
  "venue-over-the-phone": "Teléfono",
  "get-the-app": "Obtén la aplicación",
  "edit-reschedule-this-appointment": "Editar o reprogramar esta cita",
  "cancel-this-appointment": "Cancelar esta cita",
  "message-this-patient": "Enviar mensaje a este paciente",
  "contact-pharmacy-for-policy-info": "Comuníquese con su farmacia local para obtener más información sobre sus",
  "prescriptions-empty-state-paragraph-one": "¡Agregar sus medicamentos facilitará el seguimiento y la solicitud de renovaciones de recetas importantes que lo ayudarán a mantenerse saludable!",
  "prescriptions-empty-state-paragraph-two": "Para agregar sus recetas ahora, toque el botón a continuación. Si desea transferir sus medicamentos desde otra farmacia, toque el enlace de arriba.",
  "prescriptions-empty-state-button-text": "Agregar medicamentos",
  "otc-empty-state-paragraph-one": "Informar a su farmacia sobre todos los medicamentos, vitaminas y suplementos que toma puede ayudarlos a detectar posibles interacciones entre medicamentos, lo que lo mantendrá más seguro y saludable",
  "otc-empty-state-paragraph-two": "Esto incluye cualquier medicamento que use que no requiera receta médica",
  "otc-empty-state-button-text": "Agregar medicamentos de venta libre",
  "viewing-medications-for": "Ver medicamentos para: :",
  "mark-unread": "Marcar como no leido",
  "error-occurred-title": "Se ha producido un error inesperado",
  "error-occurred-description": "Si necesita ayuda inmediata, comuníquese con su farmacia.",
  "contact-prescription-ready": "Nos comunicaremos con usted cuando su receta esté lista",
  "task-type": "Tipo",
  "task-status": "Estado",
  "task-assigned-to": "Asignado a",
  "task-priority": "Prioridad",
  "task-created-by": "Creado por",
  "task-due-by": "Debido por",
  "task-visibility": "Visibilidad",
  "integration": "Integration",
  "api-host": "API Host",
  "server-address": "Server Address",
  "integration-location-id": "ID de ubicación de integración",
  "server-port": "Server Port",
  "api-key": "API Key",
  "setup-integration": "Setup Integration",
  "integration-missing": "Currently no integration setup",
  "edit-integration": "Edit Integration",
  "pharmacy-software": "Pharmacy Software",
  "npi": "NPI",
  "prescription-info": "Información de prescripción",
  "add-to-list": "Agregar a la lista",
  "adding-prescriptions-for": "Agregar recetas para ",
  "last-submission": "Último envío",
  "no-submissions": "No se han enviado formularios",
  "no-forms-to-display": "No hay formularios para mostrar",
  "search-forms": "Buscar formularios",
  "no-submissions-found": "No se encontraron resultados para ",
  "delete-availability-in-use-tooltip": "Esta disponibilidad está en uso para los servicios.\nElimine/cambie la disponibilidad del servicio y\nluego regrese para eliminar la disponibilidad.",
  "photo-id-2": "Identificación fotográfic",
  "find-meds": "Buscar Medicamentos",
  "rx-number-must-be-numeric": "Por favor, introduzca sólo números",
  "error-occurred-integration-save": "Se produjo un error al intentar guardar la integración.",
  "error-occurred-integration-load-save": "Se produjo un error al intentar cargar o guardar la integración. Inténtalo de nuevo.",
  "integration-saved": "Integración guardada",
  "hide": "Esconder",
  "server-address-required": "Se requiere la dirección del servidor",
  "integration-location-id-required": "Se requiere ID de ubicación de integración",
  "start-time-required": "La hora de inicio es obligatoria",
  "end-time-required": "La hora de finalización es obligatoria",
  "custom-filters": "Filtros personalizados",
  "appointments-filters-info-title": "Mostrando citas que coinciden con los siguientes criterios:",
  "service-name-info-box": "Nombre del servicio establecido en ",
  "service-category-info-box": "Categoría de servicio establecida en ",
  "appointment-notes-update-error": "Se produjo un error al intentar actualizar las notas de la cita. Inténtelo de nuevo.",
  "applied-to-all-locations": "Aplicado a todas las ubicaciones",
  "add-image": "Añadir imagen",
  "add-file": "Agregar archivo",
  "pharmacy-npi-number": "Número NPI de farmacia",
  "my-tasks": "My tasks",
  "access-denied": "Acceso denegado",
  "check-back-later": "Vuelva a consultar más tarde o comuníquese con la farmacia si tiene preguntas",
  "contact-administration": "Póngase en contacto con el administrador de TI si tiene preguntas",
  "have-second-insurance": "¿Hay un seguro secundario?",
  "new-messages": "Nuevos mensajes",
  "this-week": "Esta semana",
  "this-month": "Este mes",
  "older": "Más viejo",
  "time-sensitive": "Sensible al tiempo",
  "profile-settings": "Configuración del perfil",
  "switch-user": "Cambiar de usuario",
  "audit-trail": "Pista de auditoría",
  "automated-actions": "Acciones automatizadas",
  "help-support": "Ayuda y soporte",
  "internal-demos": "demostraciones internas",
  "vow-ivr": "Vow IVR",
  "wellness-classes": "Clases de bienestar",
  "event-logs": "Registros de eventos",
  "subscribe-to-webhooks": "Suscríbete a webhooks",
  "realtime-updates": "Actualizaciones en tiempo real",
  "enabled": "Activado",
  "disabled": "Desactivado",
  "overwrite-defaults": "Sobrescribir valores predeterminados",
  "notifications": "Notificaciones",
  "no-notifications": "No hay notificaciones",
  "no-notifications-subtitle": "Te avisaremos cuando pase algo.",
  "changed-insurance": "¿Ha cambiado el seguro desde la última recarga?",
  "add-manually": "Agregar manualmente",
  "authentication-failed-generic": "la autenticación falló",
  "sending": "Enviando...",
  "read": "Leer",
  "delivered": "Entregada",
  "failed-to-send": "Fallo al enviar",
  "retry": "Rever",
  "realtime-updates-api-key": "Actualizaciones en tiempo real Clave API",
  "internal-webhook-keys-renewed": "Clave API Webhook interna renovada",
  "error-occurred-internal-webhook-keys-renew": "Se ha producido un error al renovar la clave API interna de Webhook",
  "refresh": "Actualizar",
  "edit-rows": "Editar filas",
  "add-new-row": "Agregar nueva fila",
  "hours": "Horas",
  "date": "Fecha",
  "flagged": "Marcada",
  "recurring": "Periódica",
  "trash": "Basura",
  "high-priority": "Alta prioridad",
  "overdue": "Atrasada",
  "confirm-to-delete-name": "¿Estás seguro de que quieres eliminar el formulario con el nombre: {{name}}",
  "confirming-lose-current-form": "Al confirmar que perderá el progreso actual en la importación de un formulario.",
  "confirm-delete-availability": "¿Estás seguro de que quieres eliminar la disponibilidad con el nombre: {{name}}?",
  "confirm-to-make": "¿Estás seguro de que quieres hacer este formulario?",
  "confirm-delete-availability-implicit": "¿Estás seguro de que quieres eliminar la disponibilidad?",
  "something-went-wrong-implicit": "Algo salió mal, inténtelo de nuevo más tarde.",
  "must-valid-domain": "Debe ser un dominio válido",
  "no-access": "Sin acceso",
  "confirm-delete-service-implicit": "¿Estás seguro de que quieres eliminar el servicio?",
  "every-month": "Cada mes",
  "relationship-to-me-is-required": "Relación conmigo es obligatorio",
  "at": "a",
  // Workflow Statuses
  "workflow-status-in-queue": "In queue",
  "workflow-status-out-of-stock": "Out of stock",
  "workflow-status-special-order": "Special order",
  "workflow-status-in-process": "In process",
  "workflow-status-awaiting-insurance-authorization": "Awaiting insurance",
  "workflow-status-awaiting-prescriber-authorization": "Awaiting prescriber",
  "workflow-status-ready-for-shipping": "Ready for shipping",
  "workflow-status-ready-for-delivery": "Ready for delivery",
  "workflow-status-partial-fill": "Partial fill",
  "workflow-status-partially-filled": "Partially filled",
  "workflow-status-delivery-in-progress": "Out for delivery",
  "workflow-status-ready-for-pickup": "Ready for pickup",
  "workflow-status-dispensed": "Dispensed",
  "workflow-status-mail": "Mail",
  "workflow-status-shipped": "Shipped",
  "workflow-status-delivered": "Delivered",
  "workflow-status-returned": "Returned",
  "workflow-status-returned-to-stock": "Returned to stock",
  "workflow-status-cancelled": "Canceled",
  "tasks-list-tab": "Lista de Tareas",
  "tasks-grid-tab": "Grille de Tâches",
  "tasks-grid-automation": "Automatización de Tareas",
  "edit-location": "Ubicación de Edición",
  "workflow-status-expired": "Expired",
  "workflow-status-discontinued": "Discontinued",
  "workflow-status-transferred": "Transferred",
  "workflow-status-insurance-refused": "Refused by insurance",
  "workflow-status-prescriber-refused": "Refused by prescriber",
  "workflow-status-filled": "Rx filled",
  "workflow-status-undefined": "-",
  // Workflow Statuses Long
  "workflow-status-in-queue-message": "This Rx is in the refill queue",
  "workflow-status-out-of-stock-message": "This Rx is out of stock",
  "workflow-status-special-order-message": "This Rx is on special order",
  "workflow-status-in-process-message": "This Rx is in the process of refilling",
  "workflow-status-awaiting-insurance-authorization-message": "This Rx is awaiting insurance authorization",
  "workflow-status-awaiting-prescriber-authorization-message": "This Rx is awaiting prescriber authorization",
  "workflow-status-ready-for-shipping-message": "This Rx is filled and ready for shipping",
  "workflow-status-ready-for-delivery-message": "This Rx is filled and ready for delivery",
  "workflow-status-partial-fill-message": "This Rx was partially filled",
  "workflow-status-partially-filled-message": "This Rx was partially filled",
  "workflow-status-delivery-in-progress-message": "This Rx has been sent out for delivery",
  "workflow-status-ready-for-pickup-message": "This Rx is filled and ready for pickup",
  "workflow-status-dispensed-message": "This prescription has been dispensed",
  "workflow-status-mail-message": "This prescription has been mailed",
  "workflow-status-shipped-message": "This prescription has been shipped",
  "workflow-status-delivered-message": "This prescription has been delivered",
  "workflow-status-returned-message": "This prescription was returned",
  "workflow-status-returned-to-stock-message": "This prescription was returned to stock",
  "workflow-status-cancelled-message": "This prescription was canceled",
  "workflow-status-expired-message": "This prescription has expired",
  "workflow-status-discontinued-message": "This prescription has been discontinued",
  "workflow-status-transferred-message": "This prescription was transferred",
  "workflow-status-insurance-refused-message": "This prescription was refused by the insurance provider",
  "workflow-status-prescriber-refused-message": "This prescription was refused by the prescriber",
  "workflow-status-filled-message": "This prescription has been filled",
  "workflow-status-undefined-message": "Info unavailable",
  // Rx Statuses
  "rx-status-no-refills": "No more refills",
  "rx-status-expired": "Rx expired",
  "rx-status-too-soon": "Rx too soon",
  "rx-status-not-found": "Rx not found",
  "rx-status-controlled": "Controlled",
  "rx-status-narcotic": "Narcotic",
  "rx-status-transferred": "Transferred",
  "rx-status-discontinued": "Discontinued",
  "rx-status-on-hold": "On hold",
  "rx-status-deleted": "Rx deleted",
  "rx-status-fillable": "Refill OK",
  "rx-status-partially-filled": "Partial fill",
  "rx-status-out-of-stock": "Out of stock",
  "rx-status-special-order": "Special order",
  "rx-status-deceased": "Patient deceased",
  "rx-status-undefined": "-",
  // Rx Statuses Long
  "rx-status-no-refills-message": "This Rx has no more refills available",
  "rx-status-expired-message": "This prescription has expired",
  "rx-status-too-soon-message": "It is too soon to refill this prescription",
  "rx-status-not-found-message": "There is no record of this prescription number",
  "rx-status-controlled-message": "This prescription is a controlled substance",
  "rx-status-narcotic-message": "This prescription is a narcotic",
  "rx-status-transferred-message": "This prescription was transferred",
  "rx-status-discontinued-message": "This prescription has been discontinued",
  "rx-status-on-hold-message": "This prescription is on hold",
  "rx-status-deleted-message": "This prescription was deleted",
  "rx-status-fillable-message": "This prescription is good for refilling",
  "rx-status-partially-filled-message": "This prescription was partially filled",
  "rx-status-out-of-stock-message": "This prescription is out of stock",
  "rx-status-special-order-message": "This prescription is on special order",
  "rx-status-deceased-message": "The prescription belongs to a deceased patient",
  "rx-status-undefined-message": "Info unavailable",
  // Order Statuses
  "order-status-received": "Received",
  "order-status-in-progress" : "In Progress",
  "order-status-filled": "Filled",
  "order-status-out-for-delivery": "Out for delivery",
  "order-status-ready-for-pickup": "Ready for pickup",
  "order-status-picked-up": "Picked up",
  "order-status-mailed": "Mailed",
  "order-status-shipped": "Shipped",
  "order-status-delivered": "Delivered",
  "order-status-returned-to-stock": "Returned to stock",
  "order-status-info-unavailable": "Info unavailable, contact your pharmacy",
  "order-status-undefined": "Info not available, try again later",
  // Order Progress Statuses
  "order-progress-not-started": "Not started",
  "order-progress-started": "Started",
  "order-progress-completed": "Completed",
  "order-progress-filled": "Filled",
  "order-progress-undefined": "(n/a)",
  // Refill Status
  "refill-status-passed": "Aprobado",
  "refill-status-not-passed": "No pasó",
  "refill-status-filter-all": "Todo",
  //
  "preview": "Vista previa",
  "form-not-editable": "Este formulario no es editable",
  "form-preview": "Vista previa del formulario",
  "dob-age": "Fecha de nacimiento (edad)",
  "form-name": "Nombre del formulario",
  "mark-all-as-read": "Marcar todo como leído",
  "mark-as-read": "Marcar como leído",
  "continuation-disclaimer-register": "Al tocar Siguiente, acepta las condiciones de esta farmacia.",
  "inactive": "Inactivo",
  "submissions": "Presentaciones",
  "last-modified": "Última modificación",
  "this-patient-has-not-sent-or-received-any-messages": "Este paciente no ha enviado ni recibido ningún mensaje",
  "have-moment-break-ice-and-ask-them-how-they-are-doing": "¿Tienes un momento? ¡Rompe el hielo y pregúntales cómo les va!",
  "copy-url": "Copiar URL",
  "error-add-self-as-people-under-care-request":"No puede agregarse como paciente bajo su cuidado",
  "error-exist-record-in-people-under-care-request-list":"{{name}} ya está en su lista de pacientes bajo su cuidado",
  "enable-datafeed": "Habilitar fuente de datos",
  "server-port-required": "Se requiere puerto de servidor",
  "invalid-ip-url": "Por favor ingrese una dirección IP o URL válida",
  "numeric-only": "Por favor introduzca sólo valores numéricos",
  "allergies-info-not-available": "Información sobre alergias no disponible",
  "info-not-available": "Información no disponible",
  "active": "Activo",
  "no-edit": "Sin edición",
  "imported": "Importado",
  "please-confirm-your-choice": "Por favor confirma tu elección",
  "form-edit-warning-title":"Este formulario tiene envíos asociados.",
  "form-edit-warning":"Realizar cambios en este formulario puede resultar en que los datos enviados por el paciente se eliminen de los formularios que completaron. Para evitar perder información importante, puede descargar los envíos del formulario antes de realizar cambios o archivar este formulario y crear uno nuevo.",
  "form-save-confirm": "Confirmar guardar",
  "form-save-modal-message": "Guardar estos cambios puede provocar la pérdida de datos dentro de los envíos de formularios. ¿Estas seguro que deseas continuar?",
  "form-save-save-button": "Ahorrar",
  "file-too-large": "El archivo es demasiado grande para adjuntarlo; pruebe con un archivo más pequeño. Tamaño máximo de carga de archivos: 5 MB",
  "order-tracker-title": "Order: {{patient}} on {{date}}",
  "order-tracker-description": "{{completed}} of {{total}} prescription(s) filled",
  "order-tracker-see-orders": "+{{total}} more",
  "order-tracker-in-progress": "In progress",
  "order-tracker-filled": "Filled",
  "find-pharmacy": "Buscar Farmacia",
  "reset": "Resetar",
  "datafeed" : "Fuente de datos",
  "download-submissions":"Descargar presentaciones",
  "inactive-form-error-title":"¡Ups! No existe.",
  "inactive-form-error-description":
  "Este formulario ya no acepta envíos. Si necesita ayuda, comuníquese con su farmacia.",
  "form-open-error": "Une erreur s'est produite lors de la tentative d'ouverture du formulaire, veuillez réessayer plus tard.",
  "bulk-message-your-patients": "Envía mensajes masivos a tus pacientes",
  "new-bulk-message": "Nuevo mensaje masivo",
  "target-your-patients-with-clinical-opportunities-and-more": "Diríjase a sus pacientes con oportunidades clínicas y más",
  "paperless-enrollment-title": "Estás inscrito en Paperless",
  "paperless-enrollment-subtitle": "No utilizar papel significa que recibirá todos los documentos y registros importantes de forma digital.",
  "paperless-enrollment-text": "No es necesario realizar ninguna acción para continuar.",
  "paperless-enrollment-benefits-title": "Beneficios de no usar papel",
  "paperless-enrollment-first-benefit": "Impacto ambiental positivo",
  "paperless-enrollment-second-benefit": "Mayor protección contra el robo de identidad",
  "paperless-enrollment-third-benefit": "Mejora de la alfabetización sanitaria a través de vídeos sobre medicamentos fáciles de entender",
  "paperless-enrollment-fourth-benefit": "Acceso conveniente a información médica y videos de salud desde la aplicación",
  "paperless-enrollment-med-info": "¿Dónde puedo encontrar información médica y vídeos de salud?",
  "paperless-screen-title": "Sin papel",
  "validation-start-hour-smaller": "¡La hora de inicio debe ser antes de la hora de finalización!",
  "validation-end-hour-greater": "¡El tiempo de finalización debe ser después de la hora de inicio!",
  "npi-required": "NPI is required",
  "api-key-required": "API Key is required",
  "username-required": "Username is required",
  "password-required": "Password is required",
  "patient-message-invalid-file-type": "Tipo de archivo invalido. Los tipos de archivos admitidos son: JPEG, PNG, AVIF, WebP, HEIC, GIF, PDF",
  "patient-message-file-limit": "Límite excedido. Número máximo de archivos adjuntos permitidos: 1",
  "bulk": 'Granel',
  "appointment-created":"Cita creada",
  "opted-sms-email": "Asegúrese de haber aceptado recibir notificaciones por SMS o correo electrónico para no perderse ninguna información médica importante de su farmacia.",
  "sms-data-rates": "Pueden aplicarse tarifas de SMS y datos.",
  "communication-preferences-benefit": "Informarnos sobre sus preferencias de comunicación nos permitirá comunicarnos con usted de la forma en que le gustaría que lo contactáramos.",
  "communications-notifications-etc": "Comunicaciones, notificaciones, etc.",
  "med-info-description-start": "Puede encontrar fácilmente información sobre medicamentos y videos de salud tocando el ",
  "med-info-description-end": " ícono en su receta dentro de la pestaña de medicamentos.",
  "medicine-name": "Tableta de metformina clorhidrato de 500 mg",
  "refill-due-date": "Fecha límite de recarga: 3 Nov",
  "last-filled-date": "Última llenado",
  "med-info-location": "Dónde encontrar información sobre medicamentos",
  "pharmacy-department-name-exist": "El nombre del departamento ya existe",
  "appointment-at":"Cita en",
  "pharmacy-appointment":"Cita en farmacia",
  "fill-out-forms": "Llenar formularios",
  "do-it-later":"Hazlo después",
  'welcome-to-forms': 'Bienvenida a los formularios',
  'create-new-form-or-upload': 'Comience creando un nuevo formulario o importándolo desde Jotform.',
  'create-new-form-or-upload-forms': 'Cree un nuevo formulario o importe desde Jotform',
  'collect-ephi-or-hipaa': 'Recopile ePHI de manera compatible con HIPAA',
  'save-time-by-getting-info': 'Ahorre tiempo obteniendo información antes de las citas',
  'import-your-existing-templates':
  'Ahorre tiempo obteniendo información antes de las citas',
  'save-paper': '¡Ahorra papel!',
  'all-forms-are-inactive': 'Todos los formularios están inactivos.',
  'patient-notifications': 'Notificaciones al paciente',
  'edit-after-hours': 'Editar fuera de horario',
  'error-occurred-after-hours-load-save': 'Se produjo un error al intentar cargar o guardar el mensaje fuera de horario. Inténtalo de nuevo.',
  'message-settings-saved': 'Configuración de mensajes guardada',
  'auto-response-after-hours': 'Respuesta automática fuera de horario',
  "error-occurred-integration-load": "Se produjo un error al intentar obtener la integración.",
  "forms-are-optional": "Los formularios son opcionales",
  "forms-are-optional-checkbox-tooltip":"Marcar esta opción permitirá a los pacientes reservar citas sin enviar formularios",
  "pharmacy-info" :"Información de farmacia",
  "pharmacy-name":"Nombre de la farmacia",
  "pharmacy-name-is-required" :"El nombre de la farmacia es requerido",
  "about-your-transfer":"Acerca de tu transferencia",
  "what-meds-are-you-transferring":"¿Qué medicamentos estás transfiriendo?" ,
  "all-my-medications" :"Todos mis medicamentos",
  "some-of-my-medications":"Algunos de mis medicamentos",
  "why-transfer-all-the-meds" :"Por qué transferir todos los medicamentos...",
  "why-transfer-all-the-meds-description":"Tener sus medicamentos en un solo lugar no solo es más conveniente para usted, sino que también reduce el riesgo de interacciones medicamentosas y errores de medicación.",
  "start-over-to-change-person":"Empezar de nuevo a cambiar de persona",
  "fill-prescriptions-after-transferring":"Surtir recetas después de la transferencia",
  "transfer-another":"Transferir otro",
  "start-over":"Empezar de nuevo",
  "enter-your-medications" :"Ingrese sus medicamentos",
  "enter-more":"Ingresar más",
  "at-least-one-drug-name-is-required":"Se requiere al menos un nombre de medicamento",
  "your-transfer-request-has-been-submitted":"Tu solicitud de transferencia ha sido enviada",
  "your-transfer-request-has-been-submitted-and-filled":"Su solicitud de transferencia ha sido enviada y será completada",
  "pharmacy":"Farmacia",
  "from-different-pharmacy": "De una farmacia diferente",
  "whose-prescription-are-you-transferring":"¿De quién son las recetas que estás transfiriendo?",
  "where-are-you-transferring-from":"¿Desde dónde los estás transfiriendo?",
  "another-pharmacy-location":"Otra ubicación de {{pharmacyName}}",
  "different-pharmacy":"Una farmacia diferente",
  "transfer-meds-header-title": "Transferir Medicamentos",
  "all-medications":"Todos medicamentos",
  "no-results-found": "No se han encontrado resultados",
  "med-info-preferences": "Preferencias de información sobre medicamentos",
  "select-med-info": "Seleccione cómo desea recibir información sobre medicamentos:",
  "receive-digitally": "Reciba digitalmente en la aplicación",
  "receive-print-out": "Recibir impresión en papel",
  "patient-message-invalid-image-file-type": "Tipo de archivo invalido. Los tipos de archivos admitidos son: JPEG, PNG, AVIF, WebP, HEIC",
  'welcome-to-submissions': 'Bienvenido a las presentaciones',
  'all-forms-submitted-by-your-patients-will-appear-here':'Todos los formularios enviados por sus pacientes aparecerán aquí.',
  'anytime-access-to-your-submissions':'Anytime access to all your form submissions in one place',
  'easy-managment-without-need-to-print':'Acceso en cualquier momento a todos los envíos de formularios en un solo lugar',
  'ability-to-download-single-or-multiple-forms':'Posibilidad de descargar formularios únicos o múltiples',
  'savings-on-paper-and-toner-materials':'¡Ahorro en materiales de papel y tóner!',
  "appointments-on-service": "There are appointments on this service. It cannot be deleted. Disable the service so new appointments cannot be booked.",
  'fill-optional-forms-reminder':'Este servicio cuenta con formularios que debes completar antes de tu cita',
  'template': 'Plantilla',
  'forms-services': 'Servicios',
  'offerings': 'Ofrendas',
  'locations-languages-spoken': 'Lenguas',
  'refills': 'Recambios',
  'offered-to-patient': 'Ofrecido al paciente',
  'on-of': 'En/Apagada',
  'appt': 'Appt',
  'pharmacy-hours': 'Horas de farmacia',
  "refill-not-passed": "Recarga no pasada",
  "new-form-submissions": "Nuevos envíos de formularios",
  "tasks-completed": "Tareas completadas",
  "messages-read": "Mensajes Leídos",
  'select-chat-template': 'Seleccionar plantilla de chat',
  'use-template': 'Usar plantilla',
  'insert-emoji': 'Insertar emojis',
  'attach-files': 'Adjuntar archivos',
  "roles": "Roles",
  "global": "Global",
  "role-corporate": "Corporativa",
  "role-it-admin": "Es Administrador",
  "role-brand": "Marca",
  "role-manager": "Gerente",
  "role-staff": "Personal",
  "role-no-access": "Sin Acceso",
  "role-lumistry-user": "Usuario de Lumistry",
  "role-lumistry-admin": "Usuario de Lumistry Admin",
  "profile-first-name": "Nombre de pila",
  "profile-last-name": "Apellido",
  "mark-as-passed": "Marcar como aprobado",
  "mark-as-not-passed": "Marcar como no aprobada",
  "send-a-secure-message":"Enviar un mensaje seguro",
  "dashboard-no-appointments": "Sin citas",
  "dashboard-task": "Tarea",
  'contact-help-support': "Soporte de Contacto",
  "form-view": "Vista de Formulario",
  "upcoming-appointments": 'Próximas Citas',
  "canceled-appointments": "Citas Canceladas",
  "past-appointments": "Citas Pasadas",
  'help-with-prescription': 'Ayuda con una receta',
  'tech-support': 'Soporte técnico para la aplicación.',
  'need-help-prescription': 'Necesito ayuda con una receta',
  'send-secure-message': 'Enviar un mensaje seguro',
  'call-the-pharmacy': 'Llama a la farmacia',
  "help-and-support": "Ayuda y Soporte",
  "sender": "Remitente",
  "recipients": "Destinatarias",
  "timestamp": "Marca de tiempo",
  "bulk-messages": "Marca de tiempo",
  "scheduled": "Programada",
  "non-secure-messages": "Mensajes no seguros",
  "secure-chats": "Chats seguras",
  "templates": "Plantillas",
  "send-date": "Fecha de envio",
  "secure-chat": "Sstablecer chat",
  "edit-user": "Editar Usuario",
  "share-video": "Compartir video",
  "copy-link-to-share-video-message": "Copie el siguiente enlace para compartirlo en un mensaje",
  "video-send": "Enviar...",
  "copy": "Copiar",
  'secure': 'Seguro',
  'in-app-chat': 'Chat en la aplicación',
  'non-secure': 'No seguro',
  'sms-email-1-way-communication': 'SMS, correo electrónico (unidireccional)',
  'sms-email': 'SMS/correo electrónico',
  'send-to-all-patients': 'Enviar a todas las pacientes',
  'send-to-all': 'Enviar a todos',
  'send-to-specific-people': 'Enviar a personas específicas',
  'add-recipient': 'Agregar destinatario',
  'when-to-send': 'Cuando enviar',
  'immediate': 'Inmediato',
  'scheduled-not-available': 'Programado (dentro del alcance de MVP aún no disponible)',
  'google-review': 'Revisión de Google',
  'recipient-criteria': 'Criterios de destinatario',
  'bulk-message-added': 'Mensaje masivo agregado',
  'error-creating-bulk-message': 'Error al crear un mensaje masivo',
  'video-share-send-bulk-messages': 'Hola nombre, tu recarga está lista para ser recogida.',
  "automations": "Automatizaciones",
  "automated-tasks": "Tareas Automatizadas",
  "insurance-changes":"Cambios de seguro",
  "update-automation-error":"No se pudo actualizar la automatización. Inténtalo de nuevo.",
  "get-automated-tasks-error":"Se produjo un error al intentar cargar las tareas automatizadas. Inténtalo de nuevo.",
  "configure":"Configurar",
  "info-cards": "Tarjetas de información",
  "edit-info-card": "Editar tarjeta de información",
  "new-info-card": "Nueva tarjeta de información",
  "link": "Enlace",
  "link-url": "URL del enlace",
  "link-label": "Etiqueta de enlace",
  "one-or-two-words-are-suggested": "Se sugieren 1 o 2 palabras",
  "max-char-allowed-message": "{{maxChar}} máximo del personaje",
  "title-is-required": "Se requiere título",
  "enable": "Permitir",
  "disable": "Desactivar",
  "find-assignee-or-summary": "Buscar cesionario o resumen",
  "task-types-description": "Los tipos de tareas ayudan a categorizar las tareas. Se muestran en la vista de lista y cuadrícula.",
  "created-date": "Fecha de creación",
  "task-category": "Categoría de tarea",
  "confirming-deactivate-user": "Al confirmar que desactivarás a la usuaria",
  "bottle-caps-confirmation-message": "¿Le gustaría que las tapas de botella abiertas fáciles?",
  "character-count-of-total": "Número de letras: {{count}}/{{total}}",
  "unexpected-error": 'Error inesperado',
  "patient-does-not-have-medications":"Este paciente no tiene medicamentos en esta farmacia",
  "remaining-refills":"Restantes recargas",
  "rx":"Rx",
  "send-video":"Enviar vídeo",
  "enrolled":"Inscrito",
  "not-enrolled":"No inscrito",
  "patient-record-updates": "Actualizaciones de registros de pacientes",
  "failed-refills": "Recargas fallidas",
  "paperless-patient": "Paciente sin papel",
  "allergy-updates": "Actualizaciones de alergias",
  "patient-record-updates-description": "Crea una tarea con actualizaciones realizadas por el paciente en su perfil, como tapas de botellas de fácil apertura, cambios de seguro y condiciones médicas, para que pueda actualizar fácilmente el PMS",
  "failed-refills-description": "Crea una tarea con recargas que no se procesaron cuando el PMS no estaba disponible para que pueda procesarlas nuevamente más adelante cuando el PMS esté disponible",
  "paperless-patient-description": "Crea una tarea con una lista de pacientes que actualizaron sus opciones sin papel",
  "allergy-updates-description": "Crea una tarea con cualquier actualización de alergias que el paciente haya realizado en su perfil para que pueda consultar fácilmente sus medicamentos y actualizar el PMS",
  "onboarding": "Onboarding",
  "date-written":"Fecha escrita",
  "app-name": "Nombre de la aplicación",
  "app-name-required": "Nombre de la aplicación requerido!",
  "app-name-tooltip-text": "Esto aparecerá en su aplicación móvil.Póngase en contacto con el servicio de atención al cliente para actualizar el nombre de su aplicación.",
  "app-store-name": "Nombre de la tienda de aplicaciones",
  "app-store-name-required": "¡Requerido el nombre de la tienda de aplicaciones!",
  "app-store-name-tooltip-text": "Esto aparecerá en Apple App Store y Google Play.Longitud limitada.",
  "app-marketplace-name": "Nombre del mercado de aplicaciones",
  "app-marketplace-name-required": "¡Requerido el nombre del mercado de aplicaciones!",
  "app-marketplace-name-tooltip-text": "Esto aparecerá en Apple App Store y Google Play.Longitud limitada.",
  "android-identifier": "Identificador de Android",
  "android-store-link": "Enlace de la tienda de Android",
  "apple-identifier": "Identificador de manzana",
  "apple-store-link": "Enlace de Apple Store",
  "ios-app-id": "ID de aplicación de iOS",
  "ios-team-id": "ID de equipo de iOS",
  "ios-bundle-id": "IOS ID de paquete de iOS",
  "refill-order":"Pedido de recarga",
  "non-secure-message": "Ningún mensaje seguro",
  "patient-can-start-new-chat": "El paciente puede comenzar un nuevo chat",
  "can-patient-start-chat-info": "Los pacientes siempre pueden responder a un hilo existente en cualquier momento. Esta configuración controla si pueden o no iniciar un nuevo hilo.",
  "automated-workflow":"Flujo de trabajo automatizado",
  "new-insurance-card-primary-front":"Agregado - Tarjeta de seguro primario - Frente",
  "new-insurance-card-primary-back":"Agregado - Tarjeta de seguro primario - Atrás",
  "removed-insurance-card-primary-front":"Eliminado - Tarjeta de seguro primario - Anverso",
  "removed-insurance-card-primary-back":"Eliminado - Tarjeta de seguro primario - Atrás",
  "new-insurance-card-secondary-front":"Agregado - Tarjeta de seguro secundario - Frente",
  "new-insurance-card-secondary-back":"Agregado - Tarjeta de seguro secundario - Atrás",
  "removed-insurance-card-secondary-front":"Eliminado - Tarjeta de seguro secundario - Frente",
  "removed-insurance-card-secondary-back":"Eliminado - Tarjeta de seguro secundario - Atrás",

  "add-variable": "Agregar variable",
  "patient-first-name-messaging": "Nombre del paciente",
  "patient-last-name-messaging": "apellido del paciente",
  "pharmacy-phone-number-messaging":"Número de teléfono de la farmacia",
  "add-attachment": "Añadir un adjunto",
  "highlight-text-to-insert-link": "Resalte el texto para insertar el enlace",
  "appointment-details": "Detalles de la cita",
  "account-information": 'Información de la cuenta',
  "please-confirm-or-fill-in": "Por favor confirme o complete la siguiente información.",
  "primary-contact": "Contacto primario",
  "business-information": "Información de negocios",
  "brand-name": "Nombre de la marca",
  "brand-name-description": "Úselo si su farmacia está asociada con una marca general. Puede ser el mismo que el nombre de la farmacia para cuentas de ubicación única.",
  "for-iternal-use": "Para uso interno si tienes uno.",
  "pharmacy-information": "Información de farmacia",
  "legal-entity-name": "Nombre de entidad legal",
  "legal-entity-name-is-required": "Se requiere el nombre de la entidad legal",
  "dba": "DBA",
  "dba-is-required": "Se requiere DBA",
  "location-email-address": "Dirección de correo electrónico de farmacia",
  "pharmacy-email-address-is-required": "Se requiere la dirección de correo electrónico de la farmacia",
  "pharmacy-ncpdp-is-required": "Se requiere NCPDP",
  "pharmacy-npi-is-required": "Se requiere NPI",
  "duns-number": "Número DUNS",
  "duns-number-is-required": "Se requiere número DUNS",
  "duns-is-not-valid": "El número D-U-N-S no es válido.",
  "tax-id-is-not-valid": "La identificación fiscal no es válida",
  "tax-id": "TNúmero de identificación fiscal/EIN",
  "tax-id-is-required": "Se requiere Tax ID",
  "pharmacy-managment-system": "Sistema de gestión de farmacia",
  "pharmacy-managment-system-is-required": "Se requiere sistema de gestión de farmacia",
  "business-type": "Tipo de negocio",
  "business-type-is-required": "Se requiere tipo de negocio",
  "time-zone-is-required": "Se requiere zona horaria",
  "duns-tooltip-before": "El Sistema de Numeración Universal de Datos, abreviado como DUNS o D-U-N-S, es un sistema patentado desarrollado y administrado por Dun & Bradstreet que asigna un identificador numérico único, denominado número DUNS a una sola entidad comercial. Esto se obtiene directamente de Dun & Bradstreet y es ",
  "duns-tooltip-after": "obligatorio.",
  "error-occured-ob-save": "Ocurrió un error. ¡Inténtalo de nuevo!",
  "pharmacy-name-tooltip": "El nombre asociado con su NCPDP. Puede ser lo mismo que DBA.",
  "legal-name-tooltip": "El nombre comercial legal con el que opera la farmacia.",
  "dba-tooltip": "El nombre público con el que opera la farmacia.",
  "mark-as-flagged": "Marcar como marcado",
  "mark-as-unflagged": "Marcar como no marcado",
  "resolved-date": "Fecha resuelta",
  "jurisdiction": "Jurisdicción",
  "jurisdiction-is-not-valid": "La jurisdicción no es válida",
  "jurisdiction-is-required": "Se requiere jurisdicción",
  "vaccination-info": "Información de vacunación",
  "one-time-vaccination-info-warning": "Agregar esta información es una acción única y no se puede editar una vez guardada.",
  "amt-administered": "Cantidad administrado",
  "manufacturer": "Fabricante",
  "lot-nr": "Lote #",
  "amt-administered-is-required": "Cantidad Se requiere administrar",
  "manufacturer-is-required": "Se requiere fabricante",
  "lot-nr-is-required": "Se requiere el número de lote",
  "exp-date": "Exp. fecha",
  "date-of-vis": "Fecha de VIS",
  "date-administered": "Fecha de administración",
  "exp-date-required": "Exp. se requiere fecha",
  "date-of-vis-required": "Se requiere fecha de VIS",
  "date-administered-required": "Se requiere fecha de administración",
  "other-location": "Otra localización",
  "arm": "Brazo",
  "leg": "Pierna",
  "other-option": "Otro...",
  "im": "IM",
  "sq": "SQ",
  "route": "Ruta",
  "other-route": "Otra ruta",
  "side": "Lado",
  "left": "Izquierdo",
  "right": "Derecho",
  "location-required": "Se requiere ubicación",
  "route-required": "Se requiere ruta",
  "side-required": "Se requiere lado",
  "other-location-required": "Se requiere otra ubicación",
  "other-route-required": "Se requiere otra ruta",
  "user-authentication": "Autenticacion de usuario",
  "no-items-to-display-for": "No hay elementos para mostrar",
  "refill-errors":"Errores de recarga",
  "refill-errors-description":"La personalización está disponible sólo con una integración de PMS.",
  "rx-status":"Estado de Rx",
  "allow-refill": "Permitir recarga",
  "refill-due-reminders":"Recordatorios de recarga vencida",
  "edit-refill-due-reminders":"Editar recordatorios de recarga vencida",
  "refill-due-reminders-non-controlled":"Sustancia no controlada",
  "refill-due-reminders-controlled":"Sustancia controlada",
  "refill-past-due-reminders":"Recordatorio de vencimientos",
  "refill-due-reminders-non-controlled-long-text":"Sustancia no controlada (0-30 dias)",
  "refill-due-reminders-controlled-long-text":"Sustancia controlada (0-30 dias)",
  "refill-past-due-reminders-long-text":"Recargar recordatorio de vencimiento (1-14 dias)",
  "days-prior":"días antes",
  "days-after":"días antes",
  "edit-refill-errors":"Editar error de recarga - {{refillError}}",
  "refill-error-toggle-description":"Habilitar esta opción permitirá a los pacientes solicitar esta recarga en línea.",
  "refill-error-toggle-name":"Permitir recarga",
  "error-description":"Descripción del error",
  "error-name":"Nombre del error",
  "character-count-of-total-refill-error":"Número de letras:  {{charCount}}/{{count}}",
  "how-many-days-reminder-tooltip":"Esto es cuántos días antes de la fecha de vencimiento del reabastecimiento se enviará al paciente un recordatorio de vencimiento del reabastecimiento",
  "how-many-days-past-due-reminder-tooltip":"Este es el número de días después de la fecha de vencimiento de la recarga, se enviará al paciente una notificación de recarga vencida",
  "home-phone": "Teléfono de casa",
  "other-phone": "Otro teléfono",
  "cell-phone": "Teléfono móvil",
  "health-videos": "Videos de salud",
  "view-all": "Ver todo",
  "SMS": "SMS",
  "use-same-message-for-email": "usar el mismo mensaje para el correo electrónico",
  "each-word-capital-first-letter": "¡Asegúrese de que la primera letra de cada palabra esté capitalizada!",
  "refills-default-chat-message": "Este mensaje se refiere al Rx# {{rxNumber}} {{drugName}} para {{fullName}}. ",
  "rx-transfer-request": "Solicitud de transferencia de recetas",
  "rx-transfer-request-description": "",
  "pharmacy-phone": "Teléfono de farmacia",
  "patient-requesting-transfer": "La paciente está solicitando la transferencia",
  "patient-wants-refill": "La paciente quiere estas llenas",
  "due-date-required": "Se requiere fecha de vencimiento",
  "business-info": "Información comercial",
  "label-and-url-both-required": "Proporcione valores tanto para la etiqueta como para la URL, o deje ambos campos vacíos.",
  "url": "URL",
  "company": "Compañía",
  "space-craft-id": "SpaceCraftID",
  "pid": "PID",
  "default-location-id": "defaultLocationId",
  "g-analytics-key": "G.AnalyticsKey",
  "account-url": "URL de Cuenta",
  "refill-url": "URL de recarga",
  "transfer-url": "Transfer URL",
  "number-is-not-valid": "Por favor ingrese solo números",
  "website": "Sitio web",
  "add-vaccination-error": "No se pudo agregar información de vacunación. Inténtalo de nuevo.",
  "vaccination-info-pharmacy-use-only": "Información de vacunación (solo uso en farmacia)",
  "immunizer": "Inmunizador",
  "enter-age": "Introduce la edad",
  "select-filter": "Seleccionar filtro",
  "filter-is-required-field": "El filtro es un campo obligatorio",
  "must-meet-all-criteria-below": "Debe cumplir con todos los criterios a continuación",
  "send-to-filtered-list": "Enviar a lista filtrada",
  "age-is-required-field": "La edad es un campo obligatorio",
  "entered-age-is-invalid": "La edad ingresada no es válida",
  "one-or-more-invalid-zip-codes-entered": "Se ingresaron 1 o más códigos postales no válidos",
  "zip-code-is-required-field": "La edad es un campo obligatorio",
  "use-commas-for-multiple-values": "Utilice comas para múltiples valores",
  "enter-zip-codes": "Ingrese códigos postales",
  "should-be-selected-only-one-gender": "Se debe seleccionar solo 1 género",
  "age-is-older-than": "La edad es mayor que",
  "age-is-younger-than": "La edad es menor que",
  "age-is": "La edad es",
  "age-is-not": "la edad no es",
  "gender-is-male": "El género es masculino",
  "gender-is-female": "El género es femenino",
  "zip-code-is-one-of": "El código postal es uno de",
  "zip-code-is-not-one-of": "El código postal no es uno de",
  "add-vaccination-info": "Agregar información de vacunación",
  "email-subject": "Asunto del email*",
  "email-message": "Mensaje de correo electrónico*",
  "sms-message": "mensaje SMS*",
  "date-and-time": "Fecha y hora",
  "autofill-info-not-available": "Información no disponible",
  "more-videos": "Más videos",
  "marketing-or-non-marketing-bulk-text": "Elija 'Marketing' si su mensaje contiene material promocional destinado a vender o publicitar productos y servicios relacionados con la salud. De lo contrario, elija 'Sin marketing'.",
  "marketing": "Marketing",
  "non-marketing": "No marketing",
  "message-characters-limit-160": "¡El límite de caracteres del mensaje es 160!",
  "chat-message": "mensaje de chat",
  "pharmacy-mgmt-system": "Farmacia mgmt.sistema",
  "pharmacy-mgmt-system-tooltip": "Póngase en contacto con el servicio de atención al cliente para actualizar su sistema de gestión de farmacia",
  "marketing-messaging-phone": "SMS de marketing",
  "non-marketing-messaging-phone": "SMS sin marketing",
  "legal-entity-name-tooltip": "El nombre comercial legal bajo la farmacia opera.",
  "time-overlapped": "¡Tiempo superpuesto!",
  "refill-reminder-error-modal-tooltip": "Esta descripción se mostrará al paciente en caso de que se produzca este error.",
  "outbound-sms-cap":"Límite de mensajes SMS salientes",
  "outbound-sms-used":"Mensajes SMS salientes utilizados",
  "outbound-email-cap":"Límite de mensajes de correo electrónico salientes",
  "outbound-email-used":"Mensajes de correo electrónico salientes utilizados",
  "message-plans":"Planes de mensajes",
  "internal-use":"Uso interno",
  "total-overage-amount": "Monto total del excedente ($)",
  "payment-and-delivery-title":"Pago Y Entrega",
  "payment-and-delivery-description":"Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  "notify-me":"Notificarme",
  "patient-name": "Nombre del paciente",
  "edit-app-details": "Editar detalles de la aplicación",
  "edit-website-details": "Editar detalles del sitio web",
  "rx-transfer-option": "Opción de transferencia RX",
  "invalid-hex-length-use-6-digits": "Usar valor hexadecimal de 6 dígitos",
  "cannot-reuse-last-five-password": "No puede reutilizar ninguna de sus últimas 5 contraseñas, elija una nueva",
  "requires-rebuilding-app": "Requiere la aplicación de reconstrucción",
  "welcome-to-refills":"Bienvenido a Recargas",
  "refill-subtitle":"Todas las recargas enviadas a través de Lumistry aparecerán aquí.",
  "refills-bullet-point-title":"Formas de lograr que los pacientes recarguen sus medicamentos usando Lumistry:",
  "tell-patients-about-app":"Cuéntale a tus pacientes sobre tu nueva aplicación móvil",
  "send-email-patient-portal-account":"Enviar invitaciones por correo electrónico para crear una cuenta en el portal del paciente",
  "send-text-for-patients-download-app":"Envíe mensajes de texto para que los pacientes descarguen su aplicación",
  "announce-ivr-promoting-digital-solutions":"Agregue un anuncio a su saludo IVR promocionando sus soluciones digitales",
  "unable-to-process-complete-required-fields": "No se puede procesar la solicitud, complete los campos obligatorios",
  "find-first-name-last-name-email": "Encuentra el Nombre, Apellido, Correo electrónico",
  "jurisdiction-tooltip": "Estado o jurisdicción en la que se formó o incorporó la empresa",
  "switch-to-this-location": "Cambiar a esta ubicación",
  "patient-portal-url": "URL del portal del paciente",
  "form-error-occurred":"Se ha producido un error de formulario. Corrija los errores siguientes y vuelva a intentarlo.",
  "messages-reload-application":"No se puede enviar el mensaje. Por favor recarga la aplicación.",
  "no-insurance-card": "Sin tarjeta de seguro",
  "reached-sending-limit":"Has alcanzado el límite de envío de SMS o Emails ",
  'edit-message-plans': 'Editar planes de mensajes',
  "sms-email-cap-reached":"El límite de SMS y mensajes de correo electrónico se ha excedido. Consulte Configuración de facturación",
  "sms-cap-reached": "El límite de mensajes SMS ha excedido. Consulte Configuración de facturación",
  "email-cap-reached": "El límite de mensajes de correo electrónico se ha excedido. Consulte Configuración de facturación",
  "tax-ein-format-is-invalid": "El formato de identificación fiscal/EIN no es válido",
  "email-password-incorrect-try-forgot-password": "Dirección de correo electrónico y/o contraseña incorrectas. Inténtelo de nuevo o seleccione 'Ha olvidado la contraseña?' para restablecerla.",
  "signup-date": "Fecha de registro",
  "billing-settings": "Configuración de facturación",
  "service-min-length-error-message": "La duración debe ser de al menos 5 minutos",
  "one-pickup-method-must-be-enabled":"Al menos un método de recogida debe estar habilitado.",
  "review-message": "Mensaje de revisión",
  "recipients-list": "Lista de destinatarios",
  "name-dob": "Nombre, FDN",
  "action": "Acción",
  "you-have-no-bulk-message-recipients": "No tienes destinatarios. Vuelve atrás y edita la lista de destinatarios o cancela tu mensaje.",
  "one-or-more-recipients-have-been-removed": "Se han eliminado 1 o más destinatarios.",
  "at-least-one-recipient-is-required": "Se requiere al menos un destinatario",
  "billable-limits": "Límites facturables",
  "non-billable-usage": "Uso no facturable",
  "usage":"Uso",
  "qr-code": "Código QR",
  "app-is-live": "La aplicación está en vivo",
  "view-image": "Ver imagen",
  "no-name-data": "No hay datos de nombre",
  "no-data": "Sin datos",
  "website-settings-url-tooltip": "Póngase en contacto con el servicio de atención al cliente para actualizar su URL",
  "date-is-required": "Se requiere fecha",
  "edit-billable-limits":"Editar Límites facturables",
  "general-error-message": "{{message}} algo salió mal",
  "failed": "Fallida",
  "prescription-entered-already-exist-contact-pharmacy": "El número de receta que ingresó ya está asociado con una cuenta existente en el portal del paciente. Intente con otra receta o comuníquese con la farmacia para obtener más ayuda",
  "email-was-registered-successfully": "El correo electrónico se registró correctamente",
  "email-is-already-registered": "El correo electrónico ya está registrado",
  "apple-developer-account": "Cuenta de desarrollador de Apple",
  "apple-developer-account-required": "¡Se requiere una cuenta de desarrollador de Apple!",
  "apple-developer-account-tooltip": "Encuéntralo en su cuenta de desarrollador de Apple, este es el nombre de la entidad legal al que corresponde esta cuenta",
};

export default mapping;
