import { useMemo } from 'react';

export const useFormattedRelationship = (relationship: string | undefined) => {
  const formattedRelationship = useMemo(() => {
    if (relationship) {
      const firstLater = relationship.charAt(0);
      const restOfTheString = relationship.substring(1).toLocaleLowerCase();

      return firstLater + restOfTheString;
    } else {
      return '';
    }
  }, [relationship]);

  return formattedRelationship;
};
