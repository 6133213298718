import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { getText } from 'assets/localization/localization';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Platform } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { ContextMenu } from '../../../../../packages/assets/components/context-menu/ContextMenu';

export const ConversationItemWrapper: FunctionComponent<
  ConversationItemWrapperProps
> = ({
  children,
  conversation,
  rowRefs,
  viewedConversationsSet,
  renderRightActions,
  onSwipeableWillOpen,
  onSwipeableClose,
  markAsUnread,
}) => {
  const shouldDisplayMenu = viewedConversationsSet.has(
    conversation.conversation_id,
  );
  const rightActionItems = [
    {
      label: getText('mark-unread'),
      onClick: markAsUnread,
    },
  ];

  return Platform.OS !== 'web' ? (
    <Swipeable
      key={conversation.conversation_id}
      ref={(ref) => {
        if (ref && !rowRefs.get(conversation.conversation_id)) {
          rowRefs.set(conversation.conversation_id, ref);
        }
      }}
      renderRightActions={renderRightActions}
      onSwipeableWillOpen={onSwipeableWillOpen}
      onSwipeableClose={onSwipeableClose}
    >
      {children}
    </Swipeable>
  ) : shouldDisplayMenu ? (
    <ContextMenu items={rightActionItems}>{children}</ContextMenu>
  ) : (
    <>{children}</>
  );
};

interface ConversationItemWrapperProps extends PropsWithChildren {
  conversation: DirectMessagePatientDto;
  rowRefs: Map<any, any>;
  viewedConversationsSet: Set<string>;
  renderRightActions: (
    progress: unknown,
    dragX: any,
  ) => React.JSX.Element | undefined;
  onSwipeableWillOpen: () => void;
  onSwipeableClose: () => void;
  markAsUnread: () => Promise<void>;
}
