const phoneMask = '   -   -    ';
const dateMask = '  /  /    ';
const dunsMask = '  -   -    ';
const taxMask = '   -  -    ';
const secondTaxMask = '  -       ';

export function applyPhoneMask(value: string, isBackspace: boolean) {
  const lastIndex = value.length - 1;

  if (value.length === 10 && !value.includes('-')) {
    return value
      .replace(/[^0-9]/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  if (isBackspace) {
    if (phoneMask[lastIndex + 1] === '-') {
      value = value.substring(0, value.length - 1);
    }
  } else {
    if (phoneMask[lastIndex + 1] === '-') {
      return value + '-';
    }
  }

  if (value.length > 12) {
    value = value.substring(0, 12);
  }

  return value;
}

export function applySecondPhoneMask(value: string, isBackspace: boolean) {
  if (isBackspace) {
    return value.substring(0, value.length);
  } else {
    const numbers = value.replace(/[^0-9]/g, '').split('');

    const code = numbers.slice(0, 3).join('');
    const beforeDash = numbers.slice(3, 6).join('');
    const lastNumbers = numbers.slice(6, 10).join('');

    if (numbers.length < 3) {
      return `(${code})`;
    } else if (numbers.length < 6) {
      return `(${code}) ${beforeDash}`;
    } else {
      return `(${code}) ${beforeDash}-${lastNumbers}`;
    }
  }
}

export function applyDateMask(value: string, isBackspace: boolean) {
  if (isBackspace) {
    return value.substring(0, value.length);
  } else {
    const numericValue = value.replace(/[^0-9]/g, '');

    // If the user types '/', ignore the input
    if (numericValue.length > 8) {
      return numericValue
        .substring(0, 8)
        .replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    }

    let formattedValue = '';

    for (let i = 0; i < numericValue.length; i++) {
      switch (i) {
        case 2:
        case 4:
          formattedValue += '/';
          // If the user types a '/', ignore the input
          if (numericValue[i] !== '/') {
            formattedValue += numericValue[i];
          }
          break;
        default:
          formattedValue += numericValue[i];
      }
    }

    return formattedValue;
  }
}

export function applyDunsMask(value: string, isBackspace: boolean) {
  const lastIndex = value.length - 1;

  if (value.length === 9 && !value.includes('-')) {
    return value
      .replace(/[^0-9]/g, '')
      .replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  if (isBackspace) {
    if (dunsMask[lastIndex + 1] === '-') {
      value = value.substring(0, value.length - 1);
    }
  } else {
    if (dunsMask[lastIndex + 1] === '-') {
      return value + '-';
    }
  }

  if (value.length > 11) {
    value = value.substring(0, 11);
  }

  return value;
}

export function applyTaxMask(value: string, isBackspace: boolean) {
  const lastIndex = value.length - 1;

  if (value.length === 9 && !value.includes('-')) {
    return value
      .replace(/[^0-9]/g, '')
      .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  }

  if (isBackspace) {
    if (taxMask[lastIndex + 1] === '-') {
      value = value.substring(0, value.length - 1);
    }
  } else {
    if (taxMask[lastIndex + 1] === '-') {
      return value + '-';
    }
  }

  if (value.length > 11) {
    value = value.substring(0, 11);
  }

  return value;
}

export function applySecondTaxMask(value: string, isBackspace: boolean) {
  const lastIndex = value.length - 1;

  if (value.length === 9 && !value.includes('-')) {
    return value.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{7})/, '$1-$2');
  }

  if (isBackspace) {
    if (secondTaxMask[lastIndex + 1] === '-') {
      value = value.substring(0, value.length - 1);
    }
  } else {
    if (secondTaxMask[lastIndex + 1] === '-') {
      return value + '-';
    }
  }

  if (value.length > 10) {
    value = value.substring(0, 10);
  }

  return value;
}
