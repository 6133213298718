import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const PatientEduMissDoseIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      testID={testID}
      viewBox="0 0 49 49"
      fill="none"
    >
      <Path
        d="M37.1689 44.9592C36.1395 46.6517 33.9264 47.191 32.2338 46.161C30.5413 45.1316 30.0027 42.9191 31.0321 41.2265L33.6073 36.9918L39.7441 40.7252L37.1689 44.9592ZM35.6062 30.3894L33.0303 34.624L32.1349 36.0964L29.559 40.3304C28.0365 42.8347 28.8347 46.1109 31.3384 47.634C33.842 49.1572 37.1182 48.3582 38.6414 45.8553L41.2166 41.6206L42.1127 40.1475L44.6879 35.9136C46.211 33.41 45.4128 30.133 42.9092 28.61C40.4055 27.0875 37.1287 27.8857 35.6062 30.3894Z"
        fill="#118F7E"
      />
      <Path
        d="M38.3777 19.5224C38.3248 19.2435 38.2974 18.9547 38.2974 18.6607C38.2974 18.3661 38.3248 18.0773 38.3777 17.7984H47.555C47.6079 18.0773 47.6353 18.3661 47.6353 18.6607C47.6353 18.9547 47.6079 19.2435 47.555 19.5224H38.3777ZM48.9384 17.7984C48.5197 14.875 46.0062 12.627 42.9664 12.627C39.9282 12.627 37.413 14.875 36.9943 17.7984C36.953 18.0802 36.9326 18.3673 36.9326 18.6607C36.9326 18.9536 36.953 19.2406 36.9943 19.5224C37.413 22.447 39.9282 24.6938 42.9664 24.6938C46.0062 24.6938 48.5197 22.447 48.9384 19.5224C48.9797 19.2406 49.0001 18.9536 49.0001 18.6607C49.0001 18.3673 48.9797 18.0802 48.9384 17.7984Z"
        fill="#118F7E"
      />
      <Path
        d="M9.88818 22.8004V19.3099H13.9341V15.2238H17.4381V19.2965H21.537V22.7463H17.4514V26.8458H13.9743V22.8004H9.88818Z"
        fill="#118F7E"
      />
      <Path
        d="M28.0952 17.7129L28.0934 29.2272C28.0934 30.5668 27.1426 31.5421 25.832 31.545C19.4099 31.5591 12.6164 31.5595 5.63945 31.5461C4.27993 31.5446 3.3303 30.5745 3.3303 29.1881V23.6371C3.32972 19.1079 3.32972 15.3996 3.33088 10.0192H3.68488H27.7185H28.0929C28.0958 12.5839 28.0952 15.1481 28.0952 17.7129ZM4.6677 3.66407H7.90203V6.47686H4.6677V3.66407ZM10.9605 3.66407H14.1943V6.47686H10.9605V3.66407ZM17.2312 3.66407H20.465V6.47686H17.2312V3.66407ZM23.5235 3.66407H26.7572V6.47686H23.5235V3.66407ZM31.0227 9.49576H30.9959C30.993 5.61456 30.9545 6.56303 31.0174 2.53453C31.0343 1.67398 30.8451 1.00674 30.3444 0.487965C30.0399 0.172394 29.6148 4.95911e-05 29.1764 4.95911e-05H2.14777C1.80483 4.95911e-05 1.46947 0.119991 1.21503 0.348812C0.615912 0.886215 0.390005 1.59538 0.407472 2.53453C0.470936 6.56361 0.432501 5.61456 0.42959 9.49692H0.401061C0.399896 15.1627 0.399902 18.9635 0.399902 23.6371L0.400478 29.1881C0.400478 32.1984 2.65083 34.4714 5.63421 34.4759C9.05194 34.4826 12.4243 34.486 15.732 34.486C19.1805 34.486 22.5581 34.4826 25.8384 34.4748C28.7461 34.468 31.0238 32.163 31.0238 29.2272L31.025 17.7135C31.025 14.974 31.0256 12.2352 31.0227 9.49576Z"
        fill="#374D58"
      />
    </Svg>
  );
};
