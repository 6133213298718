import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { Divider } from 'react-native-paper';
import { RefillGuestTestIDs } from '../../refill-guest/RefillGuestTestIDs';
import { useAppStateStore } from '../../../store/app-store';
import { StoreSelector } from '../../../components/store-selector';
import { InternalScreenContainer } from 'assets/layout';
import { RefillStackParamList } from '../RefillNavigation';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  PharmacyLocationDto,
  PickupMethodResponseDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { MapSize } from '../../../components/store-selector/types';
import { PickupHandler } from './pickup-bottom-sheet/PickupProps';
import { Pickup } from './pickup-bottom-sheet/Pickup';
import { getText } from 'assets/localization/localization';
import {
  useRefillMedicationsStore,
  useRefillReviewMethodStore,
} from '../refill-store';
import refillService from '../refill-service';
import { RadioButtonGroupInput } from 'assets/components/radio-button-group/RadioButtonGroupInput';
import { UnableToSubmitHandler } from '../unable-to-submit/UnableToSubmitProps';
import { UnableToSubmit } from '../unable-to-submit/UnableToSubmit';
import { loadStores } from '../../../actions/app-actions';

export const ChoosePrescriptionMethod: FunctionComponent<
  PropsWithChildren<ChoosePrescriptionMethodNavigationProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { selectedLocationId, isFirstTimeRegister, unableToSubmitMedications } =
    useRefillMedicationsStore();
  const { stores, locationPickupMethods } = useAppStateStore();
  const { methodValue, methodName, methodMessage, updateReviewMethodData } =
    useRefillReviewMethodStore();

  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const [allowPickupToday, setAllowPickupToday] = useState<boolean>(false);
  const [allowPickupUrgent, setAllowPickupUrgent] = useState<boolean>(false);
  const [methodOptions, setMethodOptions] = useState<PickupMethodResponseDto[]>(
    [],
  );
  const unableToSubmitRef = React.useRef<UnableToSubmitHandler>(null);
  const pickupRef = React.useRef<PickupHandler>(null);

  useEffect(() => {
    if (!stores.length) void loadStores();

    if (!selectedLocationId || !updateReviewMethodData) return;

    void (async () => {
      const locationConfig =
        await refillService.getLocationConfig(selectedLocationId);

      setAllowPickupToday(locationConfig.allow_pickup_today);
      setAllowPickupUrgent(locationConfig.allow_pickup_urgent);
      setMethodOptions(locationPickupMethods);

      if (locationPickupMethods.length == 1) {
        updateReviewMethodData({
          methodValue: locationPickupMethods[0].id,
          methodName: locationPickupMethods[0].name,
          methodMessage: locationPickupMethods[0].confirmation,
        });
      }
    })();
  }, []);

  useEffect(() => {
    const selectedStore = stores.find(
      (store) => store.id === selectedLocationId,
    );
    setSelectedStore(selectedStore);
  }, [stores, selectedLocationId]);

  const handleShow = () => {
    pickupRef.current?.show();
  };

  const handlePickupSubmit = (values: any) => {
    if (updateReviewMethodData) {
      updateReviewMethodData({
        methodValue,
        methodName,
        methodMessage,
        isToday: values?.pickupPrescriptionToday === 'yes',
        isUrgent: values?.pickupPrescriptionUrgently === 'yes',
      });
    }
  };

  const handleChange = (newValue: string) => {
    if (newValue === 'pickup' && (allowPickupToday || allowPickupUrgent)) {
      handleShow();
    }
    if (!updateReviewMethodData) return;

    const selectedMethod = methodOptions.find((x) => x.id === newValue);

    if (selectedMethod) {
      updateReviewMethodData({
        methodValue: newValue,
        methodName: selectedMethod.name,
        methodMessage: selectedMethod.confirmation,
      });
    }
  };

  const handleShowUnableToSubmit = () => {
    unableToSubmitRef.current?.show();
  };

  const handleUnableToSubmit = async (value: string) => {
    if (value === 'continue') {
      navigation.navigate('refill-review');
    }
    if (value === 'back') {
      unableToSubmitRef.current?.hide();
    }
  };

  const handleOnPress = () => {
    if (
      isFirstTimeRegister &&
      unableToSubmitMedications &&
      unableToSubmitMedications.length > 0
    ) {
      return handleShowUnableToSubmit();
    }
    navigation.navigate('refill-review');
  };

  return (
    <InternalScreenContainer
      title={getText('medications-fill-your-prescription')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: handleOnPress,
          disabled: methodValue === '' || methodValue === 'not-selected',
          testID: RefillGuestTestIDs.refillGuestFindStoreNext,
          logger: { id: RefillGuestTestIDs.refillGuestFindStoreNext },
          text: getText('review'),
        },
      ]}
    >
      <View style={{ paddingVertical: theme.getSpacing(1) }}>
        <View style={styles.container}>
          <Text style={styles.textTitle}>{getText('order-details')}</Text>
        </View>
        <Divider />
        <View style={{ paddingTop: theme.getSpacing(1) }}>
          <View style={styles.container}>
            <View style={styles.row}>
              <StoreSelector
                options={stores}
                selectedOption={selectedStore}
                onChange={setSelectedStore}
                mapProps={{ size: MapSize.lg }}
                changeButtonShown={false}
              />
            </View>
          </View>
        </View>
        <View style={styles.container}>
          <Text style={styles.textTitle}>{getText('method')}</Text>
          <RadioButtonGroupInput
            values={methodOptions.map((method) => ({
              text: method.name,
              value: method.id,
            }))}
            value={methodValue}
            onValueChange={(newValue) => handleChange(newValue)}
          />
        </View>
      </View>
      <UnableToSubmit
        ref={unableToSubmitRef}
        handleUnableToSubmit={handleUnableToSubmit}
      />
      <Pickup
        ref={pickupRef}
        handlePickupSubmit={handlePickupSubmit}
        allowPickupToday={allowPickupToday}
        allowPickupUrgent={allowPickupUrgent}
      />
    </InternalScreenContainer>
  );
};

type ChoosePrescriptionMethodNavigationProps = NativeStackScreenProps<
  RefillStackParamList,
  'refill-insurance-method'
>;

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
  },
  addressText: {
    color: theme.palette.gray[900],
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  nameText: {
    color: theme.palette.gray[900],
    fontWeight: '700',
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  blueText: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.primary[400],
    margin: theme.getSpacing(0.5),
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  rowCheckbox: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  methodOptions: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    marginVertical: theme.getSpacing(1),
    paddingVertical: theme.getSpacing(1),
    borderRadius: theme.roundness,
  },
  textArea: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    marginVertical: theme.getSpacing(1),
    padding: theme.getSpacing(2),
    borderRadius: theme.roundness,
  },
}));
