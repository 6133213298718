import axios, { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { VUCA_API_BASE_URL } from '../common/constants';

import {
  ConditionItemSchema,
  ConditionVideoSchema,
  ConditionApi as VucaServiceClient,
} from '@digitalpharmacist/vuca-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { TypeaheadBaseItem } from 'assets/components/typeahead/types';

export interface ResourceItem {
  value: string;
  text: string;
  videos?: ConditionVideoSchema[];
  vucaId?: string;
}

export interface IVucaService {
  conditionSearch(searchString: string): Promise<TypeaheadBaseItem[]>;
  conditionFind(slugOrId: string): Promise<ResourceItem[]>;
  getTopConditionHints(): Promise<ResourceItem[]>;
}

export class VucaService extends BaseApiService implements IVucaService {
  private vucaServiceClient: VucaServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.vucaServiceClient = new VucaServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async conditionSearch(searchString: string): Promise<TypeaheadBaseItem[]> {
    const { data } =
      await this.vucaServiceClient.vucaApiApiV2ConditionsGetConditionsBySearch(
        searchString,
      );
    return data.items.map(conditionToResourceItem);
  }

  async conditionFind(slugOrId: string): Promise<ResourceItem[]> {
    try {
      const { data } =
        await this.vucaServiceClient.vucaApiApiV2ConditionsGetConditionByIdOrSlug(
          slugOrId,
        );
      return data.map(conditionToResourceItem);
    } catch (error) {
      // We want to return an empty array here because there are expected cases
      // such as custom conditions that will not have any condition info in the DB.
      // We want to return an empty array instead of throwing an error in these cases.
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      } else {
        throw error;
      }
    }
  }

  async getTopConditionHints(): Promise<ResourceItem[]> {
    const { data } =
      await this.vucaServiceClient.vucaApiApiV2ConditionsGetSuggestedConditions();
    return data.items.map(conditionToResourceItem);
  }
}

function conditionToResourceItem(condition: ConditionItemSchema): ResourceItem {
  return {
    text: condition.name,
    value: condition.slug,
    videos: condition.videos,
    vucaId: condition.vuca_id,
  };
}

export default new VucaService(
  VUCA_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
