import React, { PropsWithChildren, useEffect, useRef } from 'react';
import * as Notifications from 'expo-notifications';
import { AppState, Platform } from 'react-native';
import { AppNotificationsProviderProp, PushNotificationData } from '../types';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import { initPushNotificationToken } from '../../../common/push-notifications';
import { NotificationNavigationScreen } from '@digitalpharmacist/unified-communications-service-client-axios';
import * as Device from 'expo-device';

export const AppNotificationsProvider: React.FunctionComponent<
  PropsWithChildren<AppNotificationsProviderProp>
> = ({ children, user, refreshNotifications, handleMarkAsRead }) => {
  const notificationListener = useRef<Notifications.Subscription>();
  const responseListener = useRef<Notifications.Subscription>();
  const navigation = useNavigation<NavigationProp<ParamListBase>>();

  const [isHandlingPushNotification, setIsHandlingPushNotification] =
    React.useState(false);

  const resetBadgeCount = async () => {
    // push notifications are only applicable to actual devices, eg: no web or device emulators
    if (Platform.OS === 'web' || !Device.isDevice) {
      return;
    }
    // Check for permissions
    const { status } = await Notifications.getPermissionsAsync();
    if (status !== 'granted') {
      // Request permissions if not granted
      const { status: newStatus } =
        await Notifications.requestPermissionsAsync();
      if (newStatus !== 'granted') {
        return;
      }
    }

    // Set badge count to 0 until user logs in
    const response = await Notifications.setBadgeCountAsync(0);
  };

  const handleRefreshNotifications = async () => {
    await refreshNotifications?.();
  };

  // register push notification when user exists
  useEffect(() => {
    if (user?.id) {
      initPushNotificationToken();
      handleRefreshNotifications();
    } else {
      resetBadgeCount();
    }
  }, [user?.id]);

  useEffect(() => {
    //Handles how notifications are handled when app is in foreground
    Notifications.setNotificationHandler({
      handleNotification: async () =>
        Promise.resolve({
          shouldShowAlert: true,
          shouldPlaySound: true,
          shouldSetBadge: true,
        }),
    });

    notificationListener.current =
      // Could not find a clean way to get around the misused promise here. Disabling the rule for now.
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Notifications.addNotificationReceivedListener(async () => {
        await refreshNotifications?.();
      });

    responseListener.current =
      // Could not find a clean way to get around the misused promise here. Disabling the rule for now.
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Notifications.addNotificationResponseReceivedListener((res) => {
        setIsHandlingPushNotification(true);
        const notificationData = res.notification.request.content
          .data as PushNotificationData;
        const navigationScreen = notificationData.navigation_screen;
        const notificationId = notificationData.id;
        // We need to wait here to give the navigator time to mount
        // This is mainly needed for the case where the app was completely closed
        // when the push notification was pressed
        setTimeout(async () => {
          if (
            navigationScreen !== NotificationNavigationScreen.None &&
            notificationId
          ) {
            navigation.navigate(navigationScreen);
            await handleMarkAsRead?.(notificationId);
          }
          await refreshNotifications?.();
        }, 2000);
      });

    //Refresh notifications when app is opened from background
    // Could not find a clean way to get around the misused promise here. Disabling the rule for now.
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    AppState.addEventListener('change', async (state) => {
      if (state === 'active') {
        // We don't want to refresh notifications if we are handling a pressed push notification
        // In that case, the list will be refreshed in that handler instead
        if (isHandlingPushNotification) {
          setIsHandlingPushNotification(false);
          return;
        }
        await refreshNotifications?.();
      }
    });

    // called on unmounting phase
    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current!,
      );
      Notifications.removeNotificationSubscription(responseListener.current!);
    };
  }, []); // called only once on mounting phase

  return children;
};
