import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import {
  CancelAppointmentBottomSheet,
  CancelAppointmentBottomSheetProps,
} from './CancelAppointmentBottomSheet';
import {
  CancelAppointmentModal,
  CancelAppointmentModalProps,
} from './CancelAppointmentModal';
import {
  GenericBottomSheet,
  NativeButtonOrientation,
} from 'assets/components/generic-bottom-sheet/GenericBottomSheet';
import { getText } from 'assets/localization/localization';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { useAppointmentDetailsState } from '../appointment-details-store';

export const CancelAppointmentDialog: ForwardRefRenderFunction<
  CancelAppointmentHandler,
  CancelAppointmentProps
> = (props, ref: ForwardedRef<CancelAppointmentHandler>) => {
  const modalRef = useRef<BaseModalHandler>(null);
  const { cancelAppointmentStatus } = useAppointmentDetailsState();

  const handleShow = () => {
    modalRef.current?.show();
  };

  const handleHide = () => {
    modalRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleCancelAppointment = () => {
    props.onCancel();
  };

  return (
    <GenericBottomSheet
      title={getText('cancel-appointment')}
      height={'40%'}
      ref={modalRef}
      buttonOrientation={NativeButtonOrientation.Vertical}
      buttons={[
        {
          onPress: handleCancelAppointment,
          logger: { id: 'cancel-appointment-ok-button-modal' },
          text: getText('cancel-appointment'),
          loading: cancelAppointmentStatus === 'loading',
          hierarchy: 'destructive',
        },
        {
          onPress: handleHide,
          logger: { id: 'cancel-appointment-cancel-button-modal' },
          text: getText('keep'),
          hierarchy: 'secondary-gray',
        },
      ]}
    >
      <View>
        <Text>{getText('cancel-appointment-confirmation')}</Text>
      </View>
    </GenericBottomSheet>
  );
};

export const CancelAppointment = forwardRef<
  CancelAppointmentHandler,
  CancelAppointmentProps
>(CancelAppointmentDialog);

export const CancelAppointmentMobile = forwardRef<
  CancelAppointmentHandler,
  CancelAppointmentBottomSheetProps
>(CancelAppointmentBottomSheet);

export const CancelAppointmentWeb = forwardRef<
  CancelAppointmentHandler,
  CancelAppointmentModalProps
>(CancelAppointmentModal);

export interface CancelAppointmentProps {
  onCancel: () => void;
}

export interface CancelAppointmentHandler {
  show: () => void;
  hide: () => void;
}
