import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import { GestureResponderEvent, Platform } from 'react-native';
import { NativeHeader } from 'assets/components/native-header';
import type { StackNavigationProp } from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import { PatientUnderCareList } from './PeopleUnderCareList';
import { AddPatientUnderCareEmail } from './AddPatientUnderCareEmail';
import { AddPatientUnderCareInfo } from './AddPatientUnderCareInfo';
import { AddPatientUnderCareRequest } from './AddPatientUnderCareRequest';
import { CloseIcon, PlusIcon } from 'assets/icons';
import { AddMinorOrPetPatient } from './AddMinorOrPetPatient';
import { AddMinorOrPatientUnderCareRequestSubmitted } from './AddMinorOrPetPatientRequestSubmitted';
import { resetRecordUnderCareState } from '../../../patient-actions';
import { PatientForms } from '../../../PatientForms';
import { NavigationTabBar } from '../../../../../../components/navigation-tab-bar/NavigationTabBar';
import { Caregivers } from './Caregivers';
import {
  LinkedAccountsTab,
  usePatientRecordState,
} from '../../../patient-store';

const Stack = createStackNavigator<PatientUnderCareStackParamList>();

export const PatientUnderCareNavigation: FunctionComponent<
  PropsWithChildren<PatientUnderCareProps>
> = ({}) => {
  const headerShown = Platform.OS !== 'web';
  const { linkedAccountsSelectedTab, recordsUnderCare, caregiverRequests } =
    usePatientRecordState();

  return (
    <Stack.Navigator
      screenOptions={{
        headerMode: 'float',
        headerShown,
        header: PatientUnderCareNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen
          name="linked-accounts"
          component={LinkedAccountsNavigation}
          options={{
            title: getText('linked-accounts'),
            headerShown,
            header: (props) => (
              <PatientUnderCareNavigationHeader
                showAddIcon={
                  (recordsUnderCare.length > 0 ||
                    caregiverRequests.length > 0) &&
                  linkedAccountsSelectedTab === LinkedAccountsTab.Pucs
                }
                showBack={true}
                {...props}
              />
            ),
          }}
        />
        <Stack.Screen
          name="add-patient-under-care-info"
          component={AddPatientUnderCareInfo}
          options={{
            title: getText('add-patient'),
            headerShown,
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={true} {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="add-patient-under-care-email"
          component={AddPatientUnderCareEmail}
          options={{
            title: getText('add-patient'),
            headerShown,
            header: (props) => (
              <PatientUnderCareNavigationHeader
                showBack={true}
                showAddIcon={true}
                actionType="cancel"
                {...props}
              />
            ),
          }}
        />
        <Stack.Screen
          name="add-patient-under-care-confirmation"
          component={AddPatientUnderCareRequest}
          options={{
            title: getText('add-patient'),
            headerShown,
            header: (props) => <PatientUnderCareNavigationHeader {...props} />,
          }}
        />
        <Stack.Screen
          name="add-minor-or-pet-patient"
          component={AddMinorOrPetPatient}
          options={{
            title: getText('add-patient'),
            headerShown,
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={true} {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="add-minor-or-pet-request-submitted"
          component={AddMinorOrPatientUnderCareRequestSubmitted}
          options={{
            title: getText('add-patient'),
            headerShown,
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={false} {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="patient-under-care-forms"
          component={PatientForms}
          options={{
            title: getText('patient-forms'),
            headerShown,
            header: (props) => (
              <PatientUnderCareNavigationHeader showBack={true} {...props} />
            ),
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface PatientUnderCareProps extends StackHeaderProps {
  showAddIcon?: boolean;
  showBack?: boolean;
  actionType?: 'add' | 'cancel';
}

export type PatientUnderCareStackParamList = {
  'add-patient-under-care': undefined;
  'add-patient-under-care-info': undefined;
  'add-patient-under-care-email': undefined;
  'add-patient-under-care-confirmation': undefined;
  'linked-accounts': undefined;
  'add-minor-or-pet-patient': undefined;
  'add-minor-or-pet-request-submitted': undefined;
  'patient-under-care-forms':
    | {
        patient_id: string;
      }
    | undefined;
};

export type PatientUnderCareStackNavigationProp =
  StackNavigationProp<PatientUnderCareStackParamList>;

export const PatientUnderCareNavigationHeader: FunctionComponent<
  PropsWithChildren<PatientUnderCareProps>
> = (props) => {
  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  const actionType = props.actionType || 'add';

  const handleActionPress = () => {
    void resetRecordUnderCareState();
    if (actionType === 'add') {
      props.navigation.navigate('add-patient-under-care-info');
    } else if (actionType === 'cancel') {
      props.navigation.navigate('linked-accounts');
    }
  };

  return (
    <NativeHeader
      actionLabel={actionType === 'add' ? getText('add') : getText('cancel')}
      actionIcon={actionType === 'add' ? PlusIcon : CloseIcon}
      onActionPress={handleActionPress}
      onBack={handleBack}
      showAction={props.showAddIcon}
      showBack={props.showBack}
      title={props.options.title}
    />
  );
};

const LinkedAccountsNavigation: FunctionComponent = () => {
  return (
    <NavigationTabBar
      screens={[
        {
          name: 'puc-tab',
          component: PatientUnderCareList,
          testID: 'puc-tab-test-id',
          title: getText('patients-under-care'),
        },
        {
          name: 'caregivers-tab',
          component: Caregivers,
          title: getText('caregivers'),
          testID: 'caregivers-tab-test-id',
        },
      ]}
    />
  );
};
