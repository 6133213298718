import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { Text } from 'assets/components/text';
import {
  AlertTriangleIcon,
  CheckIcon,
  ClockIcon,
  LocationIcon,
  PlusIcon,
  UserIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import {
  convertDateTimeFromUtcToPharmacyTimezone,
  DEFAULT_PHARMACY_TIMEZONE,
} from '../../../common/datetime-utils';
import { useBookAppointmentState } from './book-appointment-store';
import { APPOINTMENT_DATE_TIME_FORMAT } from './book-appointment-utils';
import { Button } from '../../../../../../packages/assets/components/button';
import { Alert } from 'assets/components/alert';

export const BookAppointmentConfirmation: FunctionComponent<
  BookAppointmentConfirmationProps
> = ({ onAddToCalendarPress, areFormsOptional }) => {
  const styles = useStyles();
  const theme = useTheme();
  const {
    selectedSlot,
    booking,
    bookingStatus,
    appointmentType,
    isReschedule,
    selectedLocation,
  } = useBookAppointmentState();
  const error = booking?.status !== 'ACCEPTED' || bookingStatus !== 'success';

  return (
    <View style={styles.outerContainer}>
      <View style={styles.container}>
        {areFormsOptional && (
          <View style={styles.formOptionalAlertContainer}>
            <Alert
              title={getText('fill-optional-forms-reminder')}
              intent="reminder"
            />
          </View>
        )}
        <View style={[styles.circleIcon, error && styles.circleIconError]}>
          {!error ? (
            <CheckIcon size={20} color={theme.palette.success[600]} />
          ) : (
            <AlertTriangleIcon size={20} color={theme.palette.error[600]} />
          )}
        </View>
        <Text style={styles.title}>
          {!error ? (
            <>
              {appointmentType?.title}{' '}
              {isReschedule
                ? getText('appointment-rescheduled')
                : getText('appointment-scheduled')}
            </>
          ) : (
            getText('booking-error')
          )}
        </Text>
        {selectedSlot?.time && (
          <View style={styles.info}>
            <View style={styles.infoIcon}>
              <ClockIcon size={16} color={theme.palette.gray[700]} />
            </View>
            <Text style={styles.infoText}>
              {convertDateTimeFromUtcToPharmacyTimezone(
                selectedSlot.time,
                DEFAULT_PHARMACY_TIMEZONE,
                APPOINTMENT_DATE_TIME_FORMAT,
              )}
            </Text>
            <Button
              onPress={onAddToCalendarPress}
              hierarchy={'tertiary-gray'}
              logger={{ id: 'outlook-login-button' }}
              labelStyle={{
                color: theme.palette.primary[600],
              }}
            >
              <PlusIcon size={16} /> {getText('add-calendar-event')}
            </Button>
          </View>
        )}
        <View style={styles.info}>
          <View style={styles.infoIcon}>
            <UserIcon size={16} color={theme.palette.gray[700]} />
          </View>
          <Text style={styles.infoText}>
            {booking?.patient_record_first_name}{' '}
            {booking?.patient_record_last_name}
          </Text>
        </View>
        {selectedLocation && (
          <View style={styles.info}>
            <View style={styles.infoIcon}>
              <LocationIcon size={16} color={theme.palette.gray[700]} />
            </View>
            <Text style={styles.infoText}>{selectedLocation.name}</Text>
            {selectedLocation.address?.address1 && (
              <>
                <Text style={styles.infoText}>
                  {selectedLocation.address.address1}
                  {selectedLocation.address.address2 &&
                    `, ${selectedLocation.address.address2}`}
                </Text>
                <Text style={styles.infoText}>
                  {selectedLocation.address.city},{' '}
                  {selectedLocation.address.state}{' '}
                  {selectedLocation.address.postal_code}
                </Text>
              </>
            )}
          </View>
        )}
      </View>
      {isReschedule && (
        <View style={Platform.OS === 'web' ? styles.noteContainerWeb : {}}>
          <Text style={styles.note}>{getText('reschedule-note')}</Text>
        </View>
      )}
    </View>
  );
};

export interface BookAppointmentConfirmationProps {
  onAddToCalendarPress?: () => void;
  areFormsOptional?: boolean;
}

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    flex: 1,
  },
  container: {
    textAlign: 'center',
    alignItems: 'center',
    flex: 1,
    marginTop: theme.getSpacing(4),
  },
  circleIcon: {
    width: 46,
    height: 46,
    borderRadius: 46,
    backgroundColor: theme.palette.success[100],
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleIconError: {
    backgroundColor: theme.palette.error[100],
  },
  title: {
    fontSize: 24,
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(4),
    textAlign: 'center',
  },
  info: {
    alignItems: 'center',
    marginBottom: theme.getSpacing(2),
  },
  infoIcon: {
    marginBottom: theme.getSpacing(1),
  },
  infoText: {
    color: theme.palette.gray[600],
    textAlign: 'center',
  },
  noteContainerWeb: {
    marginTop: 'auto',
    marginBottom: theme.getSpacing(4),
  },
  note: {
    fontSize: 12,
    color: theme.palette.gray[700],
    textAlign: 'center',
  },
  formOptionalAlertContainer: {
    marginBottom: theme.getSpacing(4),
    alignItems: 'center',
  },
}));
