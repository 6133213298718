import { CardType } from '@digitalpharmacist/patient-service-client-axios';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import patientService from '../../api/patient-service';
import { useRefillMedicationsStore } from '../refill/refill-store';
import { useUserState } from '../../store/user-store';
import refillService from '../refill/refill-service';
import { usePatientRecordState } from '../account/patient/patient-store';

export const processInsurance = async (
  selectedPatientRecordIdValue: string,
  value: string,
  insuranceSubmittedValue: boolean,
  navigation: StackNavigationProp<ParamListBase, string>,
) => {
  const selectedPatientRecord = await patientService.findPatientRecord(
    selectedPatientRecordIdValue,
  );

  if (
    (!selectedPatientRecord.insurance_card_secondary_back_url ||
      !selectedPatientRecord.insurance_card_secondary_front_url) &&
    insuranceSubmittedValue
  ) {
    navigation.navigate('refill', {
      screen: 'edit-patient-card',
      params: {
        cardType: CardType.InsurancePrimary,
        nextNavigation: 'refill-review',
      },
    });
    return;
  }
  if (
    !selectedPatientRecord.insurance_card_primary_front_url &&
    !selectedPatientRecord.insurance_card_primary_back_url
  ) {
    return navigation.navigate('refill', { screen: 'edit-patient-card' });
  }
  if (value === 'continue' && insuranceSubmittedValue) {
    navigation.navigate('refill', { screen: 'change-insurance' });
  }
  if (value === 'continue' && !insuranceSubmittedValue) {
    navigation.navigate('refill', { screen: 'refill-review' });
  }
};

export const unselectAllMedications = () => {
  const { allMedications } = useRefillMedicationsStore.getState();

  useRefillMedicationsStore.setState({
    allMedications: allMedications?.map((medication) => ({
      ...medication,
      checked: false,
    })),
  });
};

export const requestAutoFill = async (
  prescription: PrescriptionDto,
): Promise<PrescriptionDto> => {
  const userId = useUserState.getState().user?.id || '';
  const { allMedications } = useRefillMedicationsStore.getState();

  const autoFillResponse = await refillService.requestAutoFill(
    prescription,
    userId,
  );

  // update only requested prescription for auto-fill
  useRefillMedicationsStore.setState({
    allMedications: allMedications?.map((medication) => {
      if (medication.prescription_id === prescription.prescription_id) {
        return { ...medication, ...autoFillResponse };
      }
      return medication;
    }),
  });

  return {
    ...prescription,
    ...autoFillResponse,
  };
};

export const medicationNotification = async (
  navigationParams: Record<string, string>,
): Promise<void> => {
  const { location_id, location_patient_record_id, prescription_id } =
    navigationParams;

  // identify the patient record with this lpr_id
  const patientRecordItem = usePatientRecordState
    .getState()
    .patientRecordItems.find((patientRecord) =>
      patientRecord.location_patient_record.find(
        (lpr) => lpr.id === location_patient_record_id,
      ),
    );

  if (!patientRecordItem) {
    // no action if the Pr is not found
    return;
  }

  // set the location_id of the notification
  await useUserState.getState().updateUser({
    preferredPharmacyLocationId: location_id,
  });

  // set the state
  useRefillMedicationsStore.setState({
    selectedPatientRecordId: patientRecordItem.patientRecordId,
    selectedPrescriptionId: prescription_id,
    notificationRefreshTrigger: Date.now(),
  });
};
