import { CheckboxField } from 'assets/components/checkbox';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';

export const NotificationPreferences: FunctionComponent<
  PropsWithChildren<NotificationPreferencesProps>
> = ({ setNotifyByEmail, setNotifyBySms, notifyByEmail, notifyBySms }) => {
  const styles = useStyles();

  const onNotificationSmsChange = (notify_by_sms: boolean) => {
    setNotifyBySms(notify_by_sms);
  };

  const onNotificationEmailChange = (notify_by_email: boolean) => {
    setNotifyByEmail(notify_by_email);
  };

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <Text style={styles.titleText}>
          {getText('notification-preference')}
        </Text>
      </View>
      <View>
        <Form.Row compact={true}>
          <Form.Column>
            <Text style={styles.selectText}>
              {getText('notification-preferences-details')}
            </Text>
            <CheckboxField
              label={getText('sms-text-message')}
              name="notifyBySms"
              onPress={onNotificationSmsChange}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row compact={true}>
          <Form.Column>
            <CheckboxField
              label={getText('email-short')}
              name="notifyByEmail"
              onPress={onNotificationEmailChange}
            />
          </Form.Column>
        </Form.Row>
      </View>
    </View>
  );
};

export type NotificationPreferencesProps = {
  notifyBySms: boolean;
  notifyByEmail: boolean;
  setNotifyBySms: (value: boolean) => void;
  setNotifyByEmail: (value: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
  container: { gap: theme.getSpacing(2) },
  title: {
    paddingVertical: theme.getSpacing(2),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  titleText: {
    fontSize: 18,
    lineHeight: 20,
    color: theme.palette.gray[900],
    fontFamily: theme.fonts.medium.fontFamily,
  },
  selectText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(0.5),
  },
}));
