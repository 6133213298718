import React, {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Platform, View } from 'react-native';
import { MedicationDetails } from '../medication-details/MedicationDetails';
import { NewMessage } from '../../screens/messages/NewMessage';
import {
  NewMessageHandler,
  SubjectOptionsEnum,
} from '../../screens/messages/MessageProps';
import { buildMessageList } from '../../screens/messages/messages-actions';
import {
  PrescriptionDetailsHandler,
  PrescriptionDetailsProps,
} from './PrescriptionDetailsProps';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { getText } from 'assets/localization/localization';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

const PrescriptionDetailsComponent: ForwardRefRenderFunction<
  PrescriptionDetailsHandler,
  PrescriptionDetailsProps
> = (
  { onRefillButtonPress }: PrescriptionDetailsProps,
  ref: ForwardedRef<PrescriptionDetailsHandler>,
) => {
  const isWeb = Platform.OS === 'web';
  const prescriptionRef = React.useRef<BaseModalHandler>(null);

  const newMessageRef = React.useRef<NewMessageHandler>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [prescription, setPrescription] = useState<
    PrescriptionDto | undefined
  >();

  const handleShow = (prescription: PrescriptionDto) => {
    setPrescription(prescription);
    prescriptionRef.current?.show();
  };

  const handleHide = () => {
    setPrescription(undefined);
    prescriptionRef.current?.hide();
  };

  const onMessageCreate = async () => {
    await buildMessageList();
  };

  const handlePrescriptionChange = (prescription: PrescriptionDto) => {
    setPrescription(prescription);
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <>
      <GenericModal
        title={prescription?.drug_name!}
        ref={prescriptionRef}
        isScrollable
        showDismissButton={!isWeb}
        webModalProps={{ height: 800 }}
        buttons={
          isWeb
            ? [
                {
                  onPress: handleHide,
                  logger: { id: 'prescription-details-ok-button-modal' },
                  text: getText('close'),
                  hierarchy: 'primary',
                },
              ]
            : []
        }
      >
        {prescription && (
          <MedicationDetails
            prescription={prescription}
            newMessageRef={newMessageRef}
            onChange={handlePrescriptionChange}
            onRefillButtonPress={onRefillButtonPress}
          />
        )}
      </GenericModal>
      {/* key to force rerendering of the NewMessage when prescription?.drug_name is changed */}
      <View key={`newMessage-${prescription?.drug_name ?? 'drug_name'}`}>
        <NewMessage
          preSelectedSubject={SubjectOptionsEnum.MedicationInfo}
          preSelectedMessage={
            prescription?.drug_name ? `${prescription.drug_name} ` : undefined
          } // added space at the end
          ref={newMessageRef}
          onMessageCreate={onMessageCreate}
        />
      </View>
    </>
  );
};

export const PrescriptionDetails = forwardRef<
  PrescriptionDetailsHandler,
  PrescriptionDetailsProps
>(PrescriptionDetailsComponent);
