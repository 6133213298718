import {
  PatientRecordDto,
  RecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTheme } from 'react-native-paper';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { PatientUnderCareForm } from '../../patient-actions';
import { UseFormReturn } from 'react-hook-form';
import { makeStyles } from 'assets/theme';
import { useUserState } from '../../../../../store/user-store';
import { formatPhone } from '../../../../../utils';
import { AppleIcon, GoogleIcon } from 'assets/icons';

export const ContactInformation: FunctionComponent<
  PropsWithChildren<{
    registrationMethod: string;
    patientRecord: PatientRecordDto;
    record?: RecordUnderCareDto;
    onPhoneChangePress: () => void;
    onEmailChangePress: () => void;
    form?: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ registrationMethod, onPhoneChangePress, onEmailChangePress }) => {
  const theme = useTheme();
  const styles = useStyles();
  const email = useUserState.getState().user?.email;
  const phone = useUserState.getState().user?.phoneNumber;
  const formattedPhone = phone ? formatPhone(phone) : '';

  const assignedOnboardingMethod = () => {
    if (!registrationMethod) {
      throw Error('No registration method provided');
    }
    const ssoIconMap: Record<string, React.ReactNode> = {
      google: <GoogleIcon size={20} />,
      apple: <AppleIcon size={20} />,
    };

    return ssoIconMap[registrationMethod] || null;
  };

  const ssoRegistrationMethod = assignedOnboardingMethod();

  return (
    <View>
      <View style={styles.requestTextContainer}>
        <Text style={styles.title}>
          {getText('contact-info').toUpperCase()}
        </Text>
      </View>
      <View style={styles.contactInfoContainer}>
        <View style={{ marginBottom: theme.getSpacing(3), flex: 1 }}>
          <Text style={styles.subtitle}>{getText('email-address')}</Text>
          <Text style={styles.boldSubtitle}>{email}</Text>
        </View>
        {ssoRegistrationMethod ? (
          <View style={{ alignSelf: 'center' }}>{ssoRegistrationMethod}</View>
        ) : (
          <TouchableOpacity
            style={styles.textContainer}
            onPress={() => onEmailChangePress()}
            testID="contact-email-change"
          >
            <Text style={[styles.pressableText, styles.leftSpacing]}>
              {getText('change')}
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.contactInfoContainer}>
        <View style={{ marginBottom: theme.getSpacing(3) }}>
          <Text style={styles.subtitle}>{getText('phone')}</Text>
          <Text style={styles.boldSubtitle}>{formattedPhone}</Text>
        </View>
        <TouchableOpacity
          style={styles.textContainer}
          onPress={() => onPhoneChangePress()}
          testID="contact-phone-change"
        >
          <Text style={[styles.pressableText, styles.leftSpacing]}>
            {getText('change')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    ...theme.fonts.medium,
  },
  subtitle: {
    fontWeight: '400',
    color: theme.palette.gray[600],
    fontSize: 14,
    lineHeight: 20,
  },
  boldSubtitle: {
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
    color: theme.palette.gray[900],
    flexWrap: 'wrap',
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(3),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  contactInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(1),
  },
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(1),
  },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
}));
