import {
  StackHeaderProps,
  StackScreenProps,
  createStackNavigator,
} from '@react-navigation/stack';
import { NativeHeader } from 'assets/components/native-header';
import { CloseIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { isUserAuthorized, useUserState } from '../../store/user-store';
import {
  TransferMedicationsNavigation,
  TransferMedicationsNavigationHeader,
} from '../transfer-medications/TransferMedsNavigation';
import { AddMedicationsChooseOption } from './AddMedicationsChooseOption';
import { AddMedicationsIntegrated } from './AddMedicationsIntegrated';
import { AddMedicationsManual } from './AddMedicationsManual';
import { ListMedicationsIntegrated } from './ListMedicationsIntegrated';
import { AppNavigationParamList } from '../../navigation/AppNavigation';
import { Platform } from 'react-native';

const Stack = createStackNavigator<AddMedsStackParamList>();

export const AddMedicationsNavigation: FunctionComponent<
  PropsWithChildren<AddMedicationsNavigationProps>
> = ({ route }) => {
  const showBack = route.params?.showBack;
  const isAuthorized = useUserState((x) => isUserAuthorized(x.user));

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: isAuthorized && Platform.OS !== 'web',
        header: (props) => (
          <AddMedicationsHeader showBack={showBack} {...props} />
        ),
      }}
    >
      <Stack.Group>
        <Stack.Screen
          // choose action only on onboarding
          name="choose-option"
          component={AddMedicationsChooseOption}
        />
        <Stack.Screen
          name="find-medications"
          component={AddMedicationsIntegrated}
          options={{
            cardStyle: { flex: 1 },
            title: getText('add-meds'),
          }}
        />
        <Stack.Screen
          name="list-found-medications"
          component={ListMedicationsIntegrated}
          options={{ title: getText('add-meds') }}
        />
        <Stack.Screen
          name="enter-medications"
          component={AddMedicationsManual}
          options={{ title: getText('add-meds') }}
        />
        <Stack.Screen
          name="transfer-medications"
          component={TransferMedicationsNavigation}
          options={{
            title: getText('transfer-meds'),
            header: (props) => (
              <TransferMedicationsNavigationHeader {...props} />
            ),
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

type AddMedicationsNavigationProps = StackScreenProps<
  AppNavigationParamList,
  'add-medications'
>;

export type AddMedsStackParamList = {
  'choose-option': undefined;
  'enter-medications': undefined;
  'find-medications': undefined;
  'list-found-medications': undefined;
  'transfer-medications': undefined;
};

export interface AddMedsHeaderProps extends StackHeaderProps {
  showBack?: boolean;
}

export const AddMedicationsHeader: FunctionComponent<
  PropsWithChildren<AddMedsHeaderProps>
> = (props) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const routeName = props.route.name as keyof AddMedsStackParamList;

  const handleBack = () => {
    props.navigation.goBack();
  };

  const handleActionPress = () => {
    props.navigation.navigate('app');
  };

  const showActions = routeName === 'enter-medications';

  return (
    <NativeHeader
      title={getText('add-meds')}
      actionLabel={getText('cancel')}
      actionIcon={CloseIcon}
      onActionPress={handleActionPress}
      onBack={handleBack}
      showAction={showActions}
      showBack={props.showBack}
    />
  );
};
