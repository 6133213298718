import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';

export const MedicationInfoPreferences: FunctionComponent<
  PropsWithChildren<MedicationInfoPreferencesProps>
> = ({ setPrefersPaperless }) => {
  const styles = useStyles();

  const onPaperlessChange = (prefersPaperless: boolean) => {
    setPrefersPaperless(prefersPaperless);
  };

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <Text style={styles.titleText}>{getText('med-info-preferences')}</Text>
      </View>
      <View>
        <Form.Row compact={true}>
          <Form.Column>
            <Text style={styles.selectText}>{getText('select-med-info')}</Text>
            <RadioButtonGroupField
              name="prefers_paperless"
              testID={'prefers_paperless-test-id'}
              onValueChange={onPaperlessChange}
              values={[
                {
                  text: getText('receive-digitally'),
                  value: true,
                },
                {
                  text: getText('receive-print-out'),
                  value: false,
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
      </View>
    </View>
  );
};

export type MedicationInfoPreferencesProps = {
  setPrefersPaperless: (value: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
  container: { gap: theme.getSpacing(2) },
  title: {
    paddingVertical: theme.getSpacing(2),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  titleText: {
    fontSize: 18,
    lineHeight: 20,
    color: theme.palette.gray[900],
    fontFamily: theme.fonts.medium.fontFamily,
  },
  selectText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(0.5),
  },
}));
