import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const ShotIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, colorSecondary, size, testID }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={colorSecondary || theme.colors.brandedText}
        fillRule="evenodd"
        d="M66 15.933 51.448 30.485l.448 1.751-2.746 2.746-4.395-4.396L47.5 27.84l1.754.443 13.449-13.448L66 15.933ZM43.076 43.62l-2.287-4.673-4.673-2.287-11.692 11.693 6.96 6.96 1.647-1.648-1.463-1.463 1.1-1.099 1.462 1.461 1.46-1.46-2.932-2.933 1.099-1.098 2.934 2.932 1.461-1.46-1.463-1.464 1.098-1.098 1.464 1.463 1.46-1.463-2.932-2.933 1.098-1.098 2.935 2.932 1.264-1.264Z"
        clipRule="evenodd"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        d="m15.448 55.129 3.479 3.481 2.015-2.015-2.38-2.38 4.395-4.396 6.96 6.96-4.395 4.396-2.38-2.383-2.016 2.015 3.479 3.481L46.873 42.02l-1.619-4.344-3.188-3.191-4.35-1.622L15.449 55.13ZM26.788 66.5a1.555 1.555 0 0 1-2.367 2l-5.494-5.494-6.592 6.592 2.748 2.749a1.557 1.557 0 0 1 0 2.199 1.557 1.557 0 0 1-2.199 0l-7.693-7.694a1.554 1.554 0 1 1 2.197-2.2l2.748 2.75 6.595-6.595-5.494-5.494a1.55 1.55 0 0 1 0-2.197 1.552 1.552 0 0 1 1.997-.168L36.22 29.962a1.556 1.556 0 0 1 1.766-.303l5.481 2.044c.218.08.404.204.557.357l3.66 3.663c.179.181.306.394.378.621l2.065 5.533a1.55 1.55 0 0 1-.358 1.635l.005.005L26.788 66.5Z"
      />
    </Svg>
  );
};
