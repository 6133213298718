import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from './types';

export const MedicationTransferIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg width={size} height={size} fill="none" testID={testID}>
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M2 6.263h14m-14 0V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6.263m-14 0a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3.263a1 1 0 0 1-1 1M9 10.5v3m0 0v3m0-3H6m3 0h3"
      />
    </Svg>
  );
};
