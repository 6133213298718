import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';
import theme, { makeStyles } from 'assets/theme';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AccountStackParamList } from '../AccountNavigation';
import { usePatientIntakeState } from './patient-store';
import { getText } from 'assets/localization/localization';
import { Form, InternalScreenContainer } from 'assets/layout';
import { View } from 'react-native';
import patientService from '../../../api/patient-service';
import { useUserState } from '../../../store/user-store';
import {
  CardType,
  DeleteCardDto,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Button } from 'assets/components/button';
import { StackNavigationProp } from '@react-navigation/stack';
import { RefillStackParamList } from '../../refill/RefillNavigation';
import { useRefillMedicationsStore } from '../../refill/refill-store';
import { PatientCard } from './PatientCard';
import { ConfirmDeleteModal } from './components/confirm-delete-action/ConfirmDeleteActionModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export const InsuranceAndIDCards: FunctionComponent<
  PropsWithChildren<PatientInfoProps>
> = ({ navigation }) => {
  const nav = useNavigation<StackNavigationProp<RefillStackParamList>>();
  const { data, updateData, error, status, isRefill } = usePatientIntakeState();
  const { selectedPatientRecord, updateMedicationsData } =
    useRefillMedicationsStore();
  const confirmDeleteModalRef = React.useRef<BaseModalHandler>(null);

  const styles = useStyles();
  let patientId = useUserState.getState().user?.patientRecordId;
  const [selectedCard, setSelectedCard] = useState<CardType>(
    CardType.InsurancePrimary,
  );

  if (!patientId) {
    throw Error('No patient ID');
  }
  if (selectedPatientRecord) {
    patientId = selectedPatientRecord.id;
  }

  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const [refresh, setRefresh] = useState<boolean>();

  const navigateToAddCard = (cardType: CardType) => {
    if (!isRefill && updateMedicationsData) {
      updateMedicationsData({
        selectedPatientRecord: undefined,
      });
    }
    if (!isRefill) {
      navigation.navigate('edit-patient-card', {
        cardType: cardType,
        nextNavigation: undefined,
      });
    } else {
      nav.navigate('edit-patient-card-refill', {
        cardType: cardType,
        nextNavigation: 'change-insurance',
      });
    }
  };

  const onDeleteButtonPress = async (
    cardType: CardType,
    confirmation: boolean,
  ) => {
    if (!patientId) {
      throw Error('No patient ID');
    }
    if (confirmation) {
      const deleteCard: DeleteCardDto = {
        type: cardType,
      };
      const patientRecord = await patientService.patientRecordDeleteCard(
        patientId,
        deleteCard,
      );
      setRefresh(true);
      setPatientRecord(patientRecord);
      loadPatientRecord();
    }
    handleHideConfirmDeleteModal();
  };

  const handleHideConfirmDeleteModal = () => {
    confirmDeleteModalRef.current?.hide();
  };

  const showConfirmDeleteModal = () => {
    confirmDeleteModalRef.current?.show();
  };

  const showModalForSelectedCard = (cardType: CardType) => {
    setSelectedCard(cardType);
    showConfirmDeleteModal();
  };

  const makePrimaryInsuranceCard = async () => {
    if (!patientId) {
      throw Error('No patient ID');
    }
    const patientRecord =
      await patientService.patientRecordSwitchInsurance(patientId);
    setPatientRecord(patientRecord);
  };

  const loadPatientRecord = async () => {
    usePatientIntakeState.setState({
      error: undefined,
      status: 'loading',
    });
    if (!patientId) {
      throw Error('No patient ID');
    }
    try {
      const patientRecord = await patientService.findPatientRecord(patientId);
      setPatientRecord(patientRecord);
      usePatientIntakeState.setState({
        error: undefined,
        status: 'idle',
      });
    } catch (error) {
      usePatientIntakeState.setState({
        error: { message: getText('error-loading-patient-record') },
        status: 'error',
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      void loadPatientRecord();
    }, [patientId, refresh]),
  );

  const handleInsuranceChangeNext = () => {
    if (!updateData) return;
    updateData({
      isRefill: false,
      data: data,
      status: 'success',
    });
    nav.navigate('refill-review');
  };

  return status === 'loading' || !patientRecord ? (
    <LoadingIndicator />
  ) : (
    <InternalScreenContainer
      title={getText('insurance-and-id-cards')}
      showFooter
    >
      {error && (
        <View style={{ marginVertical: theme.getSpacing(2) }}>
          <Form.Alert
            visible
            intent={'error'}
            title={error.message}
          ></Form.Alert>
        </View>
      )}
      <View>
        <PatientCard
          patientRecord={patientRecord}
          cardType={CardType.InsurancePrimary}
          navigateToAddCard={() => navigateToAddCard(CardType.InsurancePrimary)}
          makePrimaryInsuranceCard={makePrimaryInsuranceCard}
          showModalForSelectedCard={() =>
            showModalForSelectedCard(CardType.InsurancePrimary)
          }
        />
        {patientRecord.insurance_card_primary_front_url && (
          <PatientCard
            patientRecord={patientRecord}
            cardType={CardType.InsuranceSecondary}
            navigateToAddCard={() =>
              navigateToAddCard(CardType.InsuranceSecondary)
            }
            makePrimaryInsuranceCard={makePrimaryInsuranceCard}
            showModalForSelectedCard={() =>
              showModalForSelectedCard(CardType.InsuranceSecondary)
            }
          />
        )}
        {!isRefill ? (
          <PatientCard
            patientRecord={patientRecord}
            cardType={CardType.PhotoId}
            navigateToAddCard={() => navigateToAddCard(CardType.PhotoId)}
            makePrimaryInsuranceCard={makePrimaryInsuranceCard}
            showModalForSelectedCard={() =>
              showModalForSelectedCard(CardType.PhotoId)
            }
          />
        ) : (
          <View style={styles.buttonContainer}>
            <Button
              hierarchy="primary"
              testID={'next-button-id'}
              logger={{ id: 'next-button-id' }}
              onPress={handleInsuranceChangeNext}
              disabled={
                !patientRecord.insurance_card_primary_front_url &&
                !patientRecord.insurance_card_primary_back_url
              }
              style={styles.buttonContainer}
            >
              {getText('next')}
            </Button>
          </View>
        )}
      </View>
      <ConfirmDeleteModal
        ref={confirmDeleteModalRef}
        height={'40%'}
        title={
          selectedCard === CardType.PhotoId
            ? getText('delete-photo-id') + '?'
            : getText('delete-insurance') + '?'
        }
        subtitle={
          selectedCard === CardType.PhotoId
            ? getText('confirm-delete-id') + '?'
            : getText('confirm-delete-insurance') + '?'
        }
        buttons={[
          {
            text: getText('remove'),
            hierarchy: 'destructive',
            onPress: () => {
              onDeleteButtonPress(selectedCard, true);
            },
            logger: { id: 'insurance-modal-remove-button' },
          },
          {
            onPress: () => handleHideConfirmDeleteModal(),
            logger: { id: 'insurance-modal-cancel-button' },
            text: getText('cancel'),
            hierarchy: 'secondary-gray',
          },
        ]}
      />
    </InternalScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageOptions: {
    marginBottom: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    textAlign: 'left',
  },
  replaceText: {
    color: theme.palette.primary[600],
    fontSize: 14,
    fontWeight: '500',
    marginRight: 12,
  },
  buttonContainer: {
    marginTop: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

type PatientInfoProps = NativeStackScreenProps<
  AccountStackParamList,
  'insurance-and-id-card'
>;
