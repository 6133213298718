import React from 'react';
import { AppNavigationConfig } from '../types';
import { DefaultAppNavigationConfig } from '../config';
import { createNavigationContainerRef } from '@react-navigation/native';

/**
 * https://reactnavigation.org/docs/navigating-without-navigation-prop/#handling-initialization
 */
export const NavigationContainerRef = createNavigationContainerRef();

export type AppNavigationContextType = {
  appNavigationConfig: AppNavigationConfig;
  setAppNavigationConfig: (override: Partial<AppNavigationConfig>) => void;
};

export const AppNavigationContext =
  React.createContext<AppNavigationContextType>({
    appNavigationConfig: DefaultAppNavigationConfig,
    setAppNavigationConfig: () => {},
  });

export const AppNavigationConsumer = AppNavigationContext.Consumer;
