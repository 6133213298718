import axios, { AxiosRequestConfig } from 'axios';
import { FILE_STORAGE_SERVICE_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';

import {
  DefaultApi as FileStorageClient,
  PharmacyCategory,
  FileUploadResponseDto,
  LocationCategory,
} from '@digitalpharmacist/file-storage-service-client-axios';
import { Platform } from 'react-native';
import {
  FileSystemUploadType,
  cacheDirectory,
  copyAsync,
  getInfoAsync,
  makeDirectoryAsync,
  uploadAsync,
} from 'expo-file-system';
import { DocumentPickerAsset } from 'expo-document-picker';

export class FileStorageService extends BaseApiService {
  private fileStorageServiceClient: FileStorageClient;
  public imageExtensions: string[] = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'heic',
  ]; //TODO: Consider keying of the mimetype or content disposition
  static getFileExtension: any;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.fileStorageServiceClient = new FileStorageClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async writeUrlPharmacy(
    category: PharmacyCategory,
    fileName: string,
    pharmacyId: string,
  ): Promise<FileUploadResponseDto> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageWriteUrlPharmacy(
        category,
        fileName,
        pharmacyId,
      );
    return data;
  }

  async writeUrl(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<FileUploadResponseDto> {
    const { data } = await this.fileStorageServiceClient.fileStorageWriteUrl(
      category,
      locationId,
      fileName,
      pharmacyId,
    );
    return data;
  }

  async readUrlPharmacy(
    category: PharmacyCategory,
    fileName: string,
    pharmacyId: string,
  ): Promise<FileUploadResponseDto> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageReadUrlPharmacy(
        category,
        fileName,
        pharmacyId,
      );
    return data;
  }

  async readUrl(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<any> {
    return await this.fileStorageServiceClient.fileStorageReadUrl(
      category,
      locationId,
      fileName,
      pharmacyId,
    );
  }

  async uploadFile(
    category: LocationCategory,
    locationId: string,
    filename: string,
    pharmacyId: string,
    fileToUpload: DocumentPickerAsset | string,
  ): Promise<string> {
    // Get a singed link
    const responseWriteUrl = await this.writeUrl(
      category,
      locationId,
      filename,
      pharmacyId,
    );

    try {
      if (Platform.OS === 'web') {
        // uploadAsync is not available on web

        const file: Blob | File | undefined =
          typeof fileToUpload === 'string'
            ? await fetch(fileToUpload).then((r) => r.blob())
            : fileToUpload.file;

        await axios.put(responseWriteUrl.url, file, {
          headers: { 'content-type': 'application/octet-stream' },
        });
      } else {
        const uri =
          typeof fileToUpload === 'string' ? fileToUpload : fileToUpload.uri;

        const cachedFile = await this.createCacheFile(filename, uri);

        await uploadAsync(responseWriteUrl.url, cachedFile, {
          httpMethod: 'PUT',
          uploadType: FileSystemUploadType.BINARY_CONTENT,
          headers: { 'content-type': 'application/octet-stream' },
        });
      }
    } catch (error) {
      console.error('error uploading file:', error);
    }

    return filename;
  }

  createCacheFile = async (name: string, uri: string) => {
    if (!(await getInfoAsync(cacheDirectory + 'uploads/')).exists) {
      await makeDirectoryAsync(cacheDirectory + 'uploads/');
    }
    const cacheFilePath = cacheDirectory + 'uploads/' + name;
    await copyAsync({ from: uri, to: cacheFilePath });
    return cacheFilePath;
  };

  getFileExtension(name: string): string {
    const attachmentParts = name.split('.');
    return (attachmentParts[attachmentParts.length - 1] || '').toLowerCase();
  }

  isImage(fileName: string): boolean {
    const extension = this.getFileExtension(fileName);
    return this.imageExtensions.includes(extension);
  }
}

export default new FileStorageService(
  FILE_STORAGE_SERVICE_BASE_URL,
  undefined,
  true,
);
