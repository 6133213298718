import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Platform, View } from 'react-native';
import { Form, ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { LandingHeader } from '../../components/landing-header';
import { AppNavigationParamList } from '../../navigation/AppNavigation';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CommonActions, useNavigation } from '@react-navigation/native';

import {
  getForm,
  getPatientRecord,
  initLocationPatientRecord,
} from './fill-in-form-actions';
import { useFillInFormStore } from './fill-in-form-store';
import { useForm } from 'assets/form';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import { Button } from 'assets/components/button';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Text } from 'assets/components/text';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal, TouchableOpacity } from '@gorhom/bottom-sheet';
import { Modal } from 'assets/components/modal';
import { IconButton } from 'assets/components/icon-button';
import { CloseIcon } from 'assets/icons';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { FillInFormContent } from './FillInFormContent';
import { getText } from 'assets/localization/localization';
import { ErrorOccurredPage } from '../../components/error-occurred/ErrorOccurred';
import { FormStatus } from '@digitalpharmacist/forms-service-client-axios';
import { usePatientRecordState } from '../account/patient/patient-store';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

const hideLandingHeaderOnWeb = Platform.OS !== 'web';

export const FillInForm: FunctionComponent<FillInFormProps> = ({ route }) => {
  const theme = useTheme();
  const styles = useStyles();

  // Read route params
  const formId = route?.params.form_id,
    locationId = route?.params.location_id;

  const { form, patientRecord: patientRecordFromStoreAfterInititalization } =
    useFillInFormStore();
  const { user } = useUserState();
  const { pharmacyName } = useAppStateStore();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const sheetRef = React.useRef<BaseModalHandler>(null);
  const navigation = useNavigation();

  const [locationPatientRecordId, setLocationPatientRecordId] = useState<
    string | null
  >(null);

  const [isNewRecordInitialized, setIsNewRecordInitialized] = useState(false);

  const [pickedPatientData, setPickedPatientData] =
    useState<PatientData | null>(null);

  const { recordsUnderCare, patientRecord } = usePatientRecordState();
  const [pucsData, setPucsData] = useState<PatientData[] | null>(null);

  const handleFormSubmit = () => {
    setIsSubmitted(true);
  };

  const getLocationPatientRecordId = useCallback(
    (patientRecord: PatientRecordDto): string | null => {
      const findLocationPatientRecordId =
        patientRecord.location_patient_records.find(
          (record) => record.location_id === locationId,
        )?.id;

      return findLocationPatientRecordId || null;
    },
    [locationId],
  );

  const methods = useForm<SelectPatientRecord>({
    defaultValues: {
      patientRecordId: null,
    },
  });

  const startFillingIn = () => {
    // Showing Modal on web or BottomSheet on mobile
    sheetRef.current?.show();
  };

  const handleMessage = (event: MessageEvent) => {
    if (event.data.action !== 'submission-completed') return;

    handleFormSubmit();
  };

  useEffect(() => {
    user?.patientRecordId && getPatientRecord(user?.patientRecordId);

    getForm(formId, locationId);

    // Adding a "message" event listener on the web version.
    // We're using it for detecting the form submission and
    // redirecting users to the homepage. Jotform fires a "message"
    // event when user submits a form, but it only works for
    // iframes. For WebView we're detecting the Thank You page on
    // page load (see the FormWebView component)
    //
    // Jotform thread explaining this:
    // https://www.jotform.com/answers/1609027-iframe-embedded-form-does-iframe-emit-events
    Platform.OS === 'web' && window.addEventListener('message', handleMessage);

    return () => {
      Platform.OS === 'web' &&
        window.removeEventListener('message', handleMessage);
    };
  }, [formId]);

  useEffect(() => {
    if (patientRecord) {
      const foundPatientRecordAtLocation =
        getLocationPatientRecordId(patientRecord);

      if (!foundPatientRecordAtLocation) {
        initLocationPatientRecord(patientRecord.id, locationId);
      }

      methods.setValue('patientRecordId', locationPatientRecordId);
      setLocationPatientRecordId(foundPatientRecordAtLocation);
    }
  }, [patientRecord, locationPatientRecordId]);

  useEffect(() => {
    const mappedPucData = recordsUnderCare
      .map((patientUnderCare) => {
        const locationPatientRecord =
          patientUnderCare.location_patient_records.find(
            (record) => record.location_id === locationId,
          );

        if (locationPatientRecord) {
          return {
            id: locationPatientRecord.id,
            name: `${patientUnderCare.record_under_care.first_name} ${patientUnderCare.record_under_care.last_name}`,
            hasLocationPatientRecord: true,
          };
        } else {
          return {
            id: patientUnderCare.record_under_care.id,
            name: `${patientUnderCare.record_under_care.first_name} ${patientUnderCare.record_under_care.last_name}`,
            hasLocationPatientRecord: false,
          };
        }
      })
      .filter((obj) => obj !== undefined) as PatientData[];

    setPucsData(mappedPucData);
  }, [recordsUnderCare]);

  const watchPatientRecordId = methods.watch('patientRecordId');

  const navigateHome = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'home',
      }),
    );

    setIsSubmitted(false);
  };

  const handleModalDismiss = () => {
    sheetRef.current?.hide();
    isSubmitted && navigateHome();
  };

  const handleGoToForm = async () => {
    if (pickedPatientData === null) {
      return;
    }

    if (pickedPatientData.hasLocationPatientRecord === false) {
      setPickedPatientData(null);
      setIsNewRecordInitialized(true);
      await initLocationPatientRecord(pickedPatientData.id, locationId);
    }

    startFillingIn();
  };

  const getPatientData = (id: string | null) => {
    if (id === null) {
      return;
    }
    const foundPatient = allPatientsData.find((patient) => patient.id === id);
    if (!foundPatient) {
      return;
    }
    return foundPatient;
  };

  useEffect(() => {
    if (
      isNewRecordInitialized === false ||
      patientRecordFromStoreAfterInititalization === undefined
    ) {
      return;
    }
    const locationRecordId = getLocationPatientRecordId(
      patientRecordFromStoreAfterInititalization,
    );
    if (locationRecordId === null) {
      return;
    }

    const tempPatientData: PatientData = {
      id: locationRecordId,
      name: `${patientRecordFromStoreAfterInititalization.first_name} ${patientRecordFromStoreAfterInititalization.last_name}`,
      hasLocationPatientRecord: true,
    };

    setPucsData((currentPucsData) => {
      if (!currentPucsData) {
        return null;
      }
      return currentPucsData.map((pucData) => {
        if (pucData.id === patientRecordFromStoreAfterInititalization.id) {
          return {
            ...pucData,
            id: locationRecordId,
            hasLocationPatientRecord: true,
          };
        }
        return pucData;
      });
    });
    methods.setValue('patientRecordId', locationRecordId);
    setPickedPatientData(tempPatientData);
  }, [patientRecordFromStoreAfterInititalization, getLocationPatientRecordId]);

  useEffect(() => {
    const tempPatientData = getPatientData(watchPatientRecordId);
    if (!tempPatientData) {
      return;
    }
    setPickedPatientData(tempPatientData);
    setIsNewRecordInitialized(false);
  }, [watchPatientRecordId]);

  const userPatientData: PatientData = useMemo(() => {
    return {
      name: getText('myself'),
      id: locationPatientRecordId || '',
      hasLocationPatientRecord: true,
    };
  }, [locationPatientRecordId]);

  const allPatientsData: PatientData[] = useMemo(() => {
    return pucsData !== null
      ? [userPatientData, ...pucsData]
      : [userPatientData];
  }, [pucsData, userPatientData]);

  const dropdownOptions: DropdownOptions[] = useMemo(() => {
    return allPatientsData.map((patient) => ({
      value: patient.id,
      label: patient.name,
    }));
  }, [allPatientsData]);

  return (
    <View style={{ flex: 1 }}>
      {hideLandingHeaderOnWeb && (
        <LandingHeader
          pharmacyName={pharmacyName}
          patientName={user?.firstName}
          height={148}
        />
      )}
      {!user || !form ? (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator></LoadingIndicator>
        </View>
      ) : (
        <ScreenContainer>
          {form.status === FormStatus.Disabled ? (
            <ErrorOccurredPage
              onPress={navigateHome}
              title={getText('inactive-form-error-title')}
              description={getText('inactive-form-error-description')}
            />
          ) : (
            <View
              style={{
                paddingHorizontal: theme.getSpacing(2),
                flex: 1,
              }}
            >
              <Text>{getText('patient-select')}</Text>
              <Form methods={methods}>
                <Form.Alert
                  visible={!!methods.getFieldState('patientRecordId').error}
                  title={getText('unable-to-process-complete-required-fields')}
                  intent="error"
                />
                <Form.Row>
                  <Form.Column>
                    <DropdownSelectField
                      label={getText('person')}
                      name="patientRecordId"
                      testID="patientRecordId"
                      options={dropdownOptions}
                      disabled={!locationPatientRecordId}
                      labelInlined={true}
                    />
                  </Form.Column>
                </Form.Row>
              </Form>
              <TouchableOpacity>
                <Text style={{ color: theme.palette.primary[500] }}>
                  {getText('add-new-patient')}
                </Text>
              </TouchableOpacity>

              <View style={{ marginTop: 'auto' }}>
                <Button
                  hierarchy="primary"
                  disabled={!watchPatientRecordId}
                  logger={{ id: 'prescription-flow' }}
                  onPress={handleGoToForm}
                >
                  {getText('go-to-form')}
                </Button>
              </View>
            </View>
          )}
        </ScreenContainer>
      )}
      <GenericModal
        title={form?.title!}
        ref={sheetRef}
        isScrollable={true}
        webModalSize={'lg'}
        showDismissButton={Platform.OS !== 'web'}
        webModalProps={{
          contentContainerStyle: { flex: 1 },
          scrollViewStyle: { flex: 1 },
        }}
        nativeModalProps={{
          contentContainerStyle: { flex: 1 },
        }}
        nativeModalBodyStyle={{ flex: 1 }}
        buttons={
          Platform.OS === 'web'
            ? [
                {
                  onPress: handleModalDismiss,
                  logger: { id: 'fill-in-form-ok-button-modal' },
                  text: isSubmitted ? getText('close') : getText('cancel'),
                  hierarchy: 'primary',
                },
              ]
            : []
        }
      >
        {pickedPatientData && (
          <FillInFormContent
            formId={formId}
            locationId={locationId}
            locationPatientRecordId={pickedPatientData.id}
            onFormSubmit={handleFormSubmit}
          />
        )}
      </GenericModal>
    </View>
  );
};

interface SelectPatientRecord {
  patientRecordId: string | null;
}

interface DropdownOptions {
  label: string;
  value: string;
}

interface PatientData {
  id: string;
  name: string;
  hasLocationPatientRecord: boolean;
}

interface FillInFormProps
  extends BottomTabScreenProps<AppNavigationParamList, 'forms'> {}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginVertical: theme.getSpacing(2),
  },
  sheetIconContainer: {
    position: 'absolute',
    left: -12,
    top: -5,
    zIndex: 1,
  },
  sheetTitleContainer: {
    paddingHorizontal: theme.getSpacing(3),
    paddingVertical: theme.getSpacing(1),
  },
  sheetTitle: {
    ...theme.fonts.medium,
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
  loadingIndicator: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -50,
  },
}));
