import { FC, useEffect, useRef } from 'react';
import { Platform, View } from 'react-native';
import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { IconButton } from 'react-native-paper';
import { CloseIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Text } from 'assets/components/text';

interface ModalOrBottomSheetProps {
  show: boolean;
  close: () => void;
  children: React.ReactNode;
}

const ModalOrBottomSheet: FC<ModalOrBottomSheetProps> = ({
  show,
  close,
  children,
}) => {
  const sheetRef = useRef<BottomSheetModal>(null);

  const theme = useTheme();
  const styles = useStyles();

  useEffect(() => {
    if (show) {
      sheetRef.current?.present();
    } else {
      sheetRef.current?.dismiss();
    }
  }, [show]);

  return Platform.OS === 'web' ? (
    <Modal
      title={getText('store-info')}
      titleSize="sm"
      show={show}
      dismissButtonProps={{
        onPress: close,
        logger: { id: 'store-info-modal-in-chat-dismiss' },
      }}
      showDismissButton
      isScrollable
      height={800}
    >
      {children}
    </Modal>
  ) : (
    <BottomSheet
      bottomSheetRef={sheetRef}
      height={'90%'}
      onDismiss={close}
      headerContent={
        <View style={styles.container}>
          <View style={styles.headerSheetViewTitle}>
            <Text style={styles.title}>{getText('store-info')}</Text>
          </View>
          <View>
            <IconButton
              accessibilityLabel={getText('dismiss')}
              icon={CloseIcon}
              onPress={close}
              size={20}
              color={theme.palette.gray[500]}
            />
          </View>
        </View>
      }
    >
      {children}
    </BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 0,
    marginBottom: theme.getSpacing(4),
  },
  headerSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  title: {
    ...theme.fonts.medium,
    color: theme.palette.black,
    fontWeight: '600',
    fontSize: 16,
    marginLeft: theme.getSpacing(4),
  },
}));

export default ModalOrBottomSheet;
