import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, Dimensions } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import Constants from 'expo-constants';
import { Text } from 'assets/components/text';

export const CameraMask: FunctionComponent<
  PropsWithChildren<CameraMaskProps>
> = ({
  type, //this prop is here for if we add additional mask types
  label,
  width = Dimensions.get('window').width * 0.95,
  height = Dimensions.get('window').height * 0.25,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const statusBarHeight = Constants.statusBarHeight;

  return (
    <>
      <View
        style={{
          flex: 1,
        }}
      >
        <View style={[styles.top, { paddingTop: statusBarHeight }]} />
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.centerSide} />
          <View style={{ ...styles.center, width, height }} />
          <View style={styles.centerSide} />
        </View>
        <View style={styles.bottom}>
          <Text
            style={{
              color: theme.palette.white,
              fontSize: 16,
              alignSelf: 'center',
              marginTop: theme.getSpacing(2),
            }}
          >
            {label}
          </Text>
        </View>
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  top: {
    backgroundColor: theme.palette.black,
    flex: 1,
    paddingBottom: theme.getSpacing(2),
    opacity: 0.6,
  },
  centerSide: {
    flex: 1,
    backgroundColor: theme.palette.black,
    opacity: 0.6,
  },
  center: {
    borderColor: theme.palette.white,
    borderWidth: 2,
    borderRadius: theme.roundness,
    alignSelf: 'center',
    opacity: 1,
    backgroundColor: theme.palette.transparent,
  },
  bottom: {
    flex: 2,
    backgroundColor: theme.palette.black,
    opacity: 0.6,
  },
}));

interface CameraMaskProps {
  type?: 'card';
  label?: string;
  width?: number;
  height?: number;
}
