import React, { FunctionComponent } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import type { StackNavigationProp } from '@react-navigation/stack';
import { AccountNavigation } from '../screens/account/AccountNavigation';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import {
  AccountIcon,
  CalendarIcon,
  MedicationsIcon,
  MessageSquareIcon,
} from 'assets/icons';
import theme from 'assets/theme';
import { WebHeader } from '../components/landing-header/WebHeader';
import { Dashboard } from '../screens/dashboard/Dashboard';
import { AppointmentsNavigation } from '../screens/appointments/AppointmentsNavigation';
import { AppointmentDetails } from '../screens/appointment/AppointmentDetails';
import { FillInForm } from '../screens/fill-in-form/FillInForm';
import { MedicationsNavigation } from '../screens/medications/MedicationsNavigation';
import { MessageCount } from '../screens/messages/MessageCount';
import { OrderDetails } from '../screens/medications/medication-orders/OrderDetails';
import { MessagesNavigation } from '../screens/messages/MessagesNavigation';
import { RefillNavigation } from '../screens/refill/RefillNavigation';
import { AddMedicationsNavigation } from '../screens/add-medications/AddMedicationsNavigation';
import { TransferMedicationsNavigation } from '../screens/transfer-medications/TransferMedsNavigation';
import { PatientUnderCareNavigation } from '../screens/account/patient/components/account-information/linked-accounts/PatientUnderCareNavigation';
import { useAppStateStore } from '../store/app-store';

const Stack = createStackNavigator<WebTabsStackParamList>();

export const WebTabsNavigation: FunctionComponent<
  RefillNavigationProps
> = ({}) => {
  const prefix = useAppStateStore((x) => x.slug);

  return (
    <>
      <Stack.Navigator
        screenOptions={{
          headerShown: true,
          headerMode: 'float',
          header: (props) => <WebTabsHeader {...props} prefix={prefix!} />,
          title: getText('app'), // fallback title
        }}
      >
        <Stack.Group>
          <Stack.Screen
            name="home"
            component={Dashboard}
            options={{ title: getText('home') }}
          />
          <Stack.Screen
            name="account"
            component={AccountNavigation}
            options={{ title: getText('account') }}
          />
          <Stack.Screen
            name="medications"
            component={MedicationsNavigation}
            options={{ title: getText('medications') }}
          />
          <Stack.Screen
            name="messages"
            component={MessagesNavigation}
            options={{ title: getText('messages') }}
          />
          <Stack.Screen
            name="appointments"
            component={AppointmentsNavigation}
            options={{ title: getText('appointments') }}
          />
          <Stack.Screen
            name="appointment"
            component={AppointmentDetails}
            options={{ title: getText('appointment-details') }}
          />
          <Stack.Screen
            name="forms"
            component={FillInForm}
            options={{ title: getText('forms') }}
          />
          <Stack.Screen
            name="order-details-web-tab"
            component={OrderDetails}
            options={{ title: getText('order-details') }}
          />
          <Stack.Screen
            name="people-under-care"
            component={PatientUnderCareNavigation}
          />
          <Stack.Screen
            name="refill"
            component={RefillNavigation}
            options={{ title: getText('refill') }}
          />
          <Stack.Screen
            name="add-medications"
            component={AddMedicationsNavigation}
            initialParams={{
              showBack: true,
            }}
            options={{ title: getText('add-prescription') }}
          />
          <Stack.Screen
            name="transfer-medications"
            component={TransferMedicationsNavigation}
            options={{ title: getText('transfer-meds') }}
          />
        </Stack.Group>
      </Stack.Navigator>
      {/*TODO get these values from pharmacy service */}
    </>
  );
};

interface RefillNavigationProps {}

export type WebTabsStackParamList = {
  home: undefined;
  account: undefined;
  medications: undefined;
  messages: undefined;
  appointments: undefined;
  appointment: undefined;
  forms: {
    form_id: string;
    location_id: string;
  };
  'people-under-care': undefined;
  'order-details-web-tab': undefined;
  refill: undefined;
  'add-medications': { showBack: boolean } | undefined;
  'transfer-medications': undefined;
};

export type WebTabsStackNavigationProp =
  StackNavigationProp<WebTabsStackParamList>;

const WebTabsHeader: FunctionComponent<
  StackHeaderProps & { prefix: string }
> = ({ navigation, prefix }) => {
  const screens = [
    {
      name: 'medications',
      label: getText('medications'),
      icon: () => <Icon icon={MedicationsIcon} color={theme.palette.white} />,
    },
    {
      name: 'messages',
      label: getText('messages'),
      navIcon: <MessageCount />,
      icon: () => <Icon icon={MessageSquareIcon} color={theme.palette.white} />,
    },
    {
      name: 'appointments',
      label: getText('appointments'),
      icon: () => <Icon icon={CalendarIcon} color={theme.palette.white} />,
    },
    {
      name: 'account',
      label: getText('account'),
      icon: () => <Icon icon={AccountIcon} color={theme.palette.white} />,
    },
  ];

  return (
    <WebHeader navigation={navigation} screens={screens} prefix={prefix} />
  );
};
