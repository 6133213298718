import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAppStateStore } from '../store/app-store';

export interface IAsyncStorageService {
  getItem(key: string): Promise<string | null>;
  setItem(key: string, value: string): Promise<void>;
  removeItem(key: string): Promise<void>;
  clear(): Promise<void>;
}

export class AsyncStorageService implements IAsyncStorageService {
  getItem(key: string): Promise<string | null> {
    return AsyncStorage.getItem(this.composeKey(key));
  }

  setItem(key: string, value: string): Promise<void> {
    return AsyncStorage.setItem(this.composeKey(key), value);
  }

  removeItem(key: string): Promise<void> {
    return AsyncStorage.removeItem(this.composeKey(key));
  }

  clear(): Promise<void> {
    return new Promise((res) => {
      const slug = this.getSlug();
      void AsyncStorage.getAllKeys().then((keys) => {
        const filteredKeys = keys.filter((x) => x.startsWith(slug));
        void AsyncStorage.multiRemove(filteredKeys).then(res);
      });
    });
  }

  private composeKey(key: string): string {
    const slug = this.getSlug();
    const composedKey = `${slug}.${key}`;
    return composedKey;
  }

  private getSlug(): string {
    const slug = useAppStateStore.getState().slug;
    if (!slug) throw new Error('Slug not provided.');
    return slug;
  }
}

export default new AsyncStorageService();
