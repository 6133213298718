import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { VideoResponseModel } from '../../api/video-service';
import { intitialInternalLinkData } from '../../screens/messages/constants';
import { InternalLinkData } from '../../screens/messages/types';
import { VideoDisplay } from './VideoDisplay';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export const VideoOverlay: FunctionComponent<
  PropsWithChildren<VideoOverlayProps>
> = ({ data, setData }) => {
  const [videoDetails, setVideoDetails] = useState<VideoResponseModel | null>(
    null,
  );
  const sheetRef = React.useRef<BaseModalHandler>(null);
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (!data.isOpened) return;
    sheetRef.current?.show();
  }, [data.isOpened]);

  const handleModalDismiss = () => {
    sheetRef.current?.hide();
    clearData();
  };

  const clearData = () => {
    setData(intitialInternalLinkData);
    setVideoDetails(null);
    navigation.setParams({ videoId: undefined, locale: undefined });
  };

  return (
    <>
      <GenericModal
        title={videoDetails?.name ?? ''}
        ref={sheetRef}
        webModalProps={{ height: height }}
        showDismissButton={Platform.OS !== 'web'}
        nativeModalProps={{ contentContainerStyle: { flex: 1 } }}
        nativeModalBodyStyle={{ flex: 1 }}
        buttons={[
          {
            text: getText('close'),
            hierarchy: 'secondary',
            onPress: handleModalDismiss,
            logger: { id: 'video-overlay-dismiss-button-modal' },
          },
        ]}
      >
        <VideoDisplay
          data={data}
          videoDetails={videoDetails}
          setVideoDetails={setVideoDetails}
        />
      </GenericModal>
    </>
  );
};

interface VideoOverlayProps {
  data: InternalLinkData;
  setData: React.Dispatch<React.SetStateAction<InternalLinkData>>;
}

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(1),
  },
  titleText: {
    color: theme.palette.gray[900],
    fontSize: 18,
    lineHeight: 28,
    textAlign: 'center',
    flex: 1,
  },
}));
