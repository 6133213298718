import React, { FunctionComponent, PropsWithChildren, useRef } from 'react';
import { GradientView } from '../gradient-view';
import { View, GestureResponderEvent, Platform } from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import { NativeHeaderBackButton } from './NativeHeaderBackButton';
import { NativeHeaderActionButton } from './NativeHeaderActionButton';
import { SaveIcon } from '../../icons';
import { Text } from '../text';
import { PlatformType } from '../types';
import { uuid } from '../../utils/uuid';
import { getText } from '../../localization/localization';
import { TouchableOpacity } from 'react-native';

export const NativeHeader: FunctionComponent<
  PropsWithChildren<NativeHeaderProps>
> = ({
  onBack,
  title,
  showBack,
  showAction,
  actionIcon = SaveIcon,
  actionLabel = getText('save'),
  onActionPress = () => {},
  platform = Platform.OS,
  gradientId = uuid(),
}) => {
  const theme = useTheme();
  const gradientIdRef = useRef(gradientId);
  const styles = useStyles();

  return (
    <GradientView
      contentHeight={88}
      fillGradientStops={[
        { stopColor: theme.colors.gradientStart, offset: 0 },
        { stopColor: theme.colors.gradientEnd, offset: 100 },
      ]}
      gradientId={gradientIdRef.current}
    >
      <View style={styles.contentContainer}>
        {showBack && (
          <NativeHeaderBackButton
            onPress={onBack}
            platform={platform}
            testID={NativeHeaderTestIDs.backButton}
          />
        )}

        <View style={styles.textContainer}>
          <Text
            style={styles.titleStyle}
            testID={NativeHeaderTestIDs.title}
            numberOfLines={1}
          >
            {title}
          </Text>
        </View>

        {showAction && (
          <View style={styles.actionButton}>
            <NativeHeaderActionButton
              onPress={onActionPress}
              label={actionLabel}
              icon={actionIcon}
              platform={platform}
              testID={NativeHeaderTestIDs.actionButton}
            />
          </View>
        )}

        {/* create some space in case we have show back and no action */}
        {showBack && <View style={styles.space} />}
      </View>
    </GradientView>
  );
};

export interface NativeHeaderProps {
  /**
   * Title to display in center of header
   */
  title?: string;
  /**
   * Callback for user clicking back button
   */
  onBack?: (event: GestureResponderEvent) => void;
  /**
   * Whether the back button is shown or not
   */
  showBack?: boolean;
  /**
   * Label displayed in text button for iOS
   */
  actionLabel?: string;
  /**
   * Icon displayed for Android
   */
  actionIcon?: FunctionComponent;
  /**
   * Whether the action button should be shown
   */
  showAction?: boolean;
  /**
   * Whether the action button fires onPress
   */
  onActionPress?: (event: GestureResponderEvent) => void;
  /**
   * Platform, should *only* be necessary for storybook
   */
  platform?: PlatformType;
  /**
   * GradientId, should *only* be necessary for snapshot tests
   */
  gradientId?: string;
}

export const NativeHeaderTestIDs = {
  title: 'native-header-title',
  actionButton: 'native-header-action-button',
  backButton: 'native-header-back-button',
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    position: 'relative',
  },
  actionButton: {
    position: 'absolute',
    right: 0,
  },
  space: {
    minWidth: 48,
  },
  textContainer: {
    alignItems: 'center',
    flex: 1,
    paddingRight: theme.getSpacing(2.5),
  },
  titleStyle: { color: theme.palette.white, fontSize: 18, fontWeight: '500' },

  iosTextButton: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    paddingLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
  },
  iosButton: { height: 48, justifyContent: 'center' },
  androidTextButton: {
    color: theme.palette.white,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    paddingLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
    textTransform: 'capitalize',
  },
  androidButton: {
    height: 48,
    justifyContent: 'center',
    marginTop: theme.getSpacing(0.5),
  },
}));
