import { LoadingIndicator } from 'assets/components/loading-indicator';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import VideoService, { VideoResponseModel } from '../../api/video-service';
import { InternalLinkData } from '../../screens/messages/types';
import { BrightcoveVideoPlayer } from '../brightcove-video-player';

export const VideoDisplay: FunctionComponent<
  PropsWithChildren<VideoDisplayProps>
> = ({ data, videoDetails, setVideoDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const styles = useStyles();

  useEffect(() => {
    fetchVideoItem();
  }, []);

  const fetchVideoItem = () => {
    if (!data.internalId) return;

    setIsLoading(true);
    VideoService.getVideoInfo(data.internalId, data.locale)
      .then((data) => setVideoDetails(data))
      .catch((error: Error) => logError(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        videoDetails && (
          <BrightcoveVideoPlayer
            style={styles.video}
            videoInfo={{
              videoId: videoDetails.value.video_id,
              accountId: videoDetails.value.account_id,
              playerId: videoDetails.value.player_id,
            }}
          />
        )
      )}
    </>
  );
};

interface VideoDisplayProps {
  data: InternalLinkData;
  videoDetails: VideoResponseModel | null;
  setVideoDetails: React.Dispatch<
    React.SetStateAction<VideoResponseModel | null>
  >;
}

const useStyles = makeStyles(() => ({
  video: {
    aspectRatio: 16 / 9,
  },
}));
