import React, { FunctionComponent } from 'react';
import { View, Platform } from 'react-native';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { useTheme } from '../theme';
import { Logo } from '../../../apps/mobile/modules/components/logo/Logo';

export const LoadingFullScreenView: FunctionComponent = () => {
  const theme = useTheme();
  const isNotWeb = Platform.OS !== 'web';

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isNotWeb ? theme.colors.brandedPrimary : undefined,
      }}
    >
      {isNotWeb && <Logo containerStyle={{ flex: undefined }} />}
      <LoadingIndicator
        size={theme.getSpacing(4)}
        color={isNotWeb ? theme.palette.white : undefined}
      />
    </View>
  );
};
