import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Platform, View } from 'react-native';
import { Text } from 'assets/components/text';
import { useUserState } from '../../store/user-store';
import { makeStyles, useTheme } from 'assets/theme';
import { logout } from '../login/login-actions';
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
  useFocusEffect,
} from '@react-navigation/native';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import {
  usePatientSubmissionsState,
  usePatientRecordState,
  usePatientIntakeState,
} from './patient/patient-store';
import {
  getLatestUserSubmissionsByPatientRecordId,
  refreshCaregiverRequestsState,
} from './patient/patient-actions';
import moment from 'moment';
import patientService from '../../api/patient-service';
import shallow from 'zustand/shallow';
import { LandingHeader } from '../../components/landing-header';
import { useAppStateStore } from '../../store/app-store';
import {
  PharmacyPoliciesDto,
  PolicyType,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import PharmacyService from '../../api/pharmacy-service';
import { ChangePasswordForm } from '../register/register-actions';
import { useForm } from 'react-hook-form';
import { ChangePasswordRequestDto } from '@digitalpharmacist/users-service-client-axios';
import { changePassword } from '../reset-password/reset-password-actions';
import { useResetPasswordState } from '../reset-password/reset-password-store';
import { ChangePassword } from '../reset-password/ChangePasswordForm';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export const Account: FunctionComponent<
  PropsWithChildren<AccountProps>
> = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { user } = useUserState();
  const { recordsUnderCare, caregiverRequests, patientRecord } =
    usePatientRecordState();

  const { isSubmissionsLoading, latestSubmission } = usePatientSubmissionsState(
    (state) => ({
      isSubmissionsLoading: state.status === 'loading',
      latestSubmission: state.latestSubmission,
    }),
    shallow,
  );
  const { data, updateData } = usePatientIntakeState();
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const navPUC = useNavigation<NavigationProp<ParamListBase>>();
  const patientId = useUserState.getState().user?.patientRecordId;
  const [policies, setPolicies] = useState<PharmacyPoliciesDto>({});
  const [insuranceSubtitle, setInsuranceSubtitle] = useState(
    getText('add-card'),
  );
  const { pharmacyName } = useAppStateStore();
  const { pharmacyId } = useAppStateStore();
  const passChangeRef = React.useRef<BaseModalHandler>(null);
  const form = useForm<ChangePasswordForm>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const didUserRegisterWithSSO = user && user.registrationMethod !== 'none';

  const [policyModalSettings, setPolicyModalSettings] = useState({
    title: '',
    type: '',
    ref: React.useRef<BaseModalHandler>(null),
    state: false,
  });

  useEffect(() => {
    if (pharmacyId) {
      void (async () => {
        const policiesResponse: PharmacyPoliciesDto =
          await PharmacyService.findPoliciesForPharmacy(pharmacyId);
        setPolicies(policiesResponse);
      })();
    }
  }, [pharmacyId]);

  const loadPatientRecord = async () => {
    if (!patientId) {
      throw Error('No patient ID');
    }
    const patientRecord = await patientService.findPatientRecord(patientId);
    if (
      patientRecord.insurance_card_primary_front_url ||
      patientRecord.insurance_card_secondary_front_url ||
      patientRecord.photo_id_url
    ) {
      setInsuranceSubtitle(getText('primary-secondary-photo-id'));
    } else {
      setInsuranceSubtitle(getText('add-card'));
    }
    usePatientRecordState.setState({
      patientRecord,
    });
  };

  useFocusEffect(
    useCallback(() => {
      void loadPatientRecord();
    }, [patientId]),
  );

  useFocusEffect(
    useCallback(() => {
      void getLatestUserSubmissionsByPatientRecordId();
    }, []),
  );

  const onPress = () => {
    navPUC.navigate('people-under-care', {
      screen: 'add-patient-under-care-info',
    });
  };

  const handleFullProfile = () => {
    // TODO: follow on card will fill out other profile screens such as this
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigation.navigate('intro');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleMedicalInfoClick = () => {
    navigation.navigate('medical-info');
  };

  const handlePersonalInfoClick = () => {
    navigation.navigate('personal-information');
  };

  const handleAboutAppClick = () => {
    navigation.navigate('about-app');
  };

  const handleHelpSupportClick = () => {
    navigation.navigate('help-support');
  };

  const handleChangePasswordClick = () => {
    passChangeRef.current?.show();
  };

  const clearForm = () => {
    form.reset();
    form.clearErrors();
  };

  const handleSaveForChangePasswordClick = async () => {
    const isFormValid = await form.trigger();
    const changePasswordRequestDto: ChangePasswordRequestDto = {
      previous_password: form.getValues('currentPassword'),
      proposed_password: form.getValues('newPassword'),
    };
    if (isFormValid) {
      await changePassword(changePasswordRequestDto);
      const status = useResetPasswordState.getState().status;
      if (status === 'success') handleDismissForChangePassword();
    }
  };

  const handleDismissForChangePassword = () => {
    clearForm();
    useResetPasswordState.setState({ error: undefined, status: 'idle' });
    passChangeRef.current?.hide();
  };

  const handleInsuranceAndIdCardsClick = () => {
    if (!updateData) return;
    updateData({
      isRefill: false,
      data: data,
      status: 'success',
    });
    navigation.navigate('insurance-and-id-card');
  };

  const handlePatientFormsClick = () => {
    navigation.navigate('patient-forms');
  };

  const handleCommunicationPreferencesClick = () => {
    navigation.navigate('communication-preferences');
  };

  const handleItemClick = () => {
    if (caregiverRequests.length > 0 || recordsUnderCare.length > 0) {
      navPUC.navigate('people-under-care', {
        screen: 'linked-accounts',
      });
    } else {
      navPUC.navigate('people-under-care', { screen: 'patient-under-care' });
    }
  };

  const handlePolicyAppClick = (policyType: PolicyType) => {
    void openPolicyModal(policyType);
  };

  // Wrapper function to satisfy the expected type of onPress
  const handleTermsOfServiceClick = () => {
    handlePolicyAppClick(PolicyType.TermsOfService);
  };

  const handlePrivacyPolicyClick = () => {
    handlePolicyAppClick(PolicyType.PrivacyPolicy);
  };

  const openPolicyModal = (policyType: PolicyType) => {
    const policyTitle =
      policyType === PolicyType.TermsOfService
        ? getText('terms-of-service')
        : getText('privacy-policy');
    setPolicyModalSettings((prevState) => ({
      ...prevState,
      type: policyType,
      state: true,
      title: policyTitle,
    }));
    policyModalSettings.ref.current?.show();
  };

  function getModalContent() {
    if (policyModalSettings.type in policies) {
      return policies[policyModalSettings.type as PolicyType]?.content;
    } else {
      return `${getText(
        'contact-pharmacy-for-policy-info',
      )} ${policyModalSettings.title.toLowerCase()}.`;
    }
  }

  const PasswordChangeDenied = () => {
    return (
      <Text style={styles.deniedText}>
        {getText('denied-password-change-text')}
      </Text>
    );
  };

  return (
    <>
      {Platform.OS !== 'web' && (
        <LandingHeader
          pharmacyName={pharmacyName}
          greetings={getText('your-account')}
        />
      )}
      <ScreenContainer showFooter backgroundColor={theme.palette.gray[50]}>
        <View>
          <ListMenu style={styles.container}>
            <ListItemLink
              onPress={handleMedicalInfoClick}
              testID="account-medical-info"
            >
              <Text style={styles.textTitle}>{getText('medical-info')}</Text>
              <Text style={styles.textUnderTitle}>
                {getText('allergies-conditions-caregivers')}
              </Text>
            </ListItemLink>
            <ListItemLink
              onPress={handlePersonalInfoClick}
              testID="account-personal-info"
            >
              <Text style={styles.textTitle}>{getText('personal-info')}</Text>
              <Text style={styles.textUnderTitle}>
                {getText('name-phone-email')}
              </Text>
            </ListItemLink>
            <ListItemLink
              onPress={handleInsuranceAndIdCardsClick}
              testID="account-insurance-id"
            >
              <Text style={styles.textTitle}>
                {getText('insurance-id-card')}
              </Text>
              {/* TODO Last updated timestamp should be shown here if there is any card image added */}
              <Text style={styles.textUnderTitle}>{insuranceSubtitle}</Text>
            </ListItemLink>
            <ListItemLink
              onPress={handleItemClick}
              testID="account-people-under-care"
            >
              <Text style={styles.textTitle}>{getText('linked-accounts')}</Text>
              <View>
                <Text style={styles.textUnderTitle}>
                  {`${getText('caregivers')}, ${getText(
                    'patients-under-care',
                  ).toLowerCase()}`}
                </Text>
              </View>
            </ListItemLink>
            {latestSubmission ? (
              <ListItemLink
                onPress={handlePatientFormsClick}
                testID="account-patient-forms"
              >
                <Text style={styles.textTitle}>{getText('patient-forms')}</Text>
                {isSubmissionsLoading ? (
                  <Text style={styles.textUnderTitle}>{`${getText(
                    'loading',
                  )}...`}</Text>
                ) : (
                  <Text style={styles.textUnderTitle}>
                    {`${getText('last-submission')}: ${moment(
                      latestSubmission,
                      'YYYY-MM-DD',
                    ).format('MMM D, YYYY')}`}
                  </Text>
                )}
              </ListItemLink>
            ) : (
              <>
                <Text style={styles.textTitle}>{getText('patient-forms')}</Text>
                <Text style={styles.textUnderTitle}>
                  {getText('no-forms-to-display')}
                </Text>
                <View style={styles.borderBottom}></View>
              </>
            )}
            <ListItemLink
              onPress={handleCommunicationPreferencesClick}
              testID="account-communication-preferences"
            >
              <Text style={styles.textTitle}>
                {getText('communication-preferences')}
              </Text>
              <Text style={styles.textUnderTitle}>
                {getText('communications-notifications-etc')}
              </Text>
            </ListItemLink>
            <View style={{ marginTop: theme.getSpacing(1) }}>
              <ListItemLink
                onPress={handleHelpSupportClick}
                showDivider={false}
                testID="account-help-support"
              >
                <Text style={styles.touchableText}>
                  {getText('help-support')}
                </Text>
              </ListItemLink>
              <ListItemLink
                onPress={handleAboutAppClick}
                showDivider={false}
                testID="account-about-this-app"
              >
                <Text style={styles.touchableText}>
                  {getText('about-this-app')}
                </Text>
              </ListItemLink>
              <ListItemLink
                onPress={handleChangePasswordClick}
                showDivider={false}
                showIcon={false}
                testID="account-change-password"
              >
                <Text style={styles.touchableText}>
                  {getText('change-password')}
                </Text>
              </ListItemLink>
              <ListItemLink
                onPress={handleTermsOfServiceClick}
                showDivider={false}
                showIcon={false}
                testID="account-terms-of-service"
              >
                <Text style={styles.touchableText}>
                  {getText('terms-of-service')}
                </Text>
              </ListItemLink>
              <ListItemLink
                onPress={handlePrivacyPolicyClick}
                showDivider={false}
                showIcon={false}
                testID="account-privacy-policy"
              >
                <Text style={styles.touchableText}>
                  {getText('privacy-policy')}
                </Text>
              </ListItemLink>
              <ListItemLink
                onPress={handleLogout}
                showDivider={false}
                showIcon={false}
                testID="account-sign-out"
              >
                <Text style={styles.touchableText}>{getText('sign-out')}</Text>
              </ListItemLink>
            </View>
          </ListMenu>
        </View>
        <GenericModal
          buttons={[
            {
              hierarchy: 'primary',
              onPress: didUserRegisterWithSSO
                ? () => passChangeRef.current?.hide()
                : handleSaveForChangePasswordClick,
              logger: { id: 'change-password-button' },
              text: didUserRegisterWithSSO ? getText('close') : getText('save'),
            },
          ]}
          title={getText('change-password')}
          ref={passChangeRef}
        >
          {didUserRegisterWithSSO ? (
            <PasswordChangeDenied />
          ) : (
            <ChangePassword form={form}></ChangePassword>
          )}
        </GenericModal>
        <GenericModal
          title={policyModalSettings.title}
          ref={policyModalSettings.ref}
          isScrollable={true}
        >
          <View>
            <Text style={styles.modalText}>{getModalContent()}</Text>
          </View>
        </GenericModal>
        {
          //TODO: Add button for feedback widget back when BE is ready
          /*<View style={{ marginBottom: theme.getSpacing(2) }}>
        <Button
          onPress={() => {
            navigation.navigate("feedback");
          }}
          hierarchy="secondary-gray"
          logger={{ id: "profile-give-feedback-button" }}
        >
          {getText("feedback-give-feedback")}
        </Button>
      </View>*/
        }
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: 24,
    fontWeight: '500',
  },
  container: {
    paddingBottom: theme.getSpacing(2),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 20,
    marginTop: theme.getSpacing(1),
    lineHeight: 30,
  },
  textUnderTitle: {
    ...theme.fonts.regular,
    color: theme.palette.gray[600],
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
  },
  touchableText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
  },
  link: {
    ...theme.fonts.medium,
    textDecorationLine: 'underline',
    color: theme.palette.white,
  },
  borderBottom: {
    height: 1,
    backgroundColor: theme.palette.gray[300],
    marginTop: theme.getSpacing(1),
  },
  modalText: {
    lineHeight: 20,
    padding: theme.getSpacing(1),
  },
  deniedText: {
    ...theme.lumistryFonts.text.small.regular,
  },
}));

interface AccountProps {}
