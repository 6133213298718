import React, { FunctionComponent, useEffect } from 'react';
import { AuthNavigation } from './AuthNavigation';
import { isUserAuthorized, useUserState } from '../store/user-store';
import { createStackNavigator } from '@react-navigation/stack';
import { ROOT_PATH } from '../common/constants';
import { AppNavigation } from './AppNavigation';
import { ErrorBoundary } from '../screens/error-boundary/ErrorBoundary';
import { resetNavigationState } from '../screens/login/login-actions';

const RootStack = createStackNavigator<RootNavigationParamList>();

export const RootNavigation: FunctionComponent = () => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.user));

  return (
    <RootStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={ROOT_PATH}
    >
      <RootStack.Screen
        name={ROOT_PATH}
        component={() => (
          <ErrorBoundary>
            {isAuthorized ? <AppNavigation /> : <AuthNavigation />}
          </ErrorBoundary>
        )}
      />
    </RootStack.Navigator>
  );
};

export type RootNavigationParamList = {
  [ROOT_PATH]: undefined;
};
