import {
  BaseApiConfiguration,
  BaseApiService,
} from '../../../../../../packages/assets/core/api';
import { AxiosRequestConfig } from 'axios';
import {
  DefaultApi as OrderClient,
  OrderDto,
  OrderProgressStatus,
} from '@digitalpharmacist/order-service-client-axios';
import { ORDER_API_BASE_URL } from '../../../common/constants';
import patientService from '../../../api/patient-service';
import { MinPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios/api';
import {
  LocationPatientRecordDto,
  PatientRecordDto,
  RecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../../../common/axios-default-request-config';

export type PatientOrder = {
  order: OrderDto;
  orderProgressStatus: OrderProgressStatus | undefined;
  patient: MinPatientRecordDto;
};

export interface IOrderService {
  getOrdersByPatients(
    location_id: string,
    patient_ids: string[],
  ): Promise<OrderDto[]>;

  getPatientOrders(
    location_id: string,
    recordsUnderCare: RecordUnderCareDto[],
    patientRecord: PatientRecordDto,
  ): Promise<PatientOrder[]>;

  getOrderProgressStatus(order: OrderDto): OrderProgressStatus | undefined;
}

export class OrderService extends BaseApiService implements IOrderService {
  private readonly orderClient: OrderClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.orderClient = new OrderClient(undefined, baseUrl, this.axiosInstance);
  }

  async getOrdersByPatients(
    location_id: string,
    patient_ids: string[],
    in_progress?: boolean,
  ): Promise<OrderDto[]> {
    const response = await this.orderClient.orderFindByPatientIds(
      location_id,
      patient_ids,
      in_progress,
    );
    return response.data;
  }

  async getPatientOrders(
    location_id: string,
    recordsUnderCare: RecordUnderCareDto[],
    patientRecord: PatientRecordDto,
    in_progress?: boolean,
  ): Promise<PatientOrder[]> {
    const locationPatientRecordMyself =
      await patientService.findLocationPatientRecordByPR(
        location_id,
        patientRecord,
      );

    const selectedDependentIds = [];
    const locationPatientRecords: LocationPatientRecordDto[] = [];
    if (locationPatientRecordMyself) {
      selectedDependentIds.push(locationPatientRecordMyself.id);
      locationPatientRecords.push(locationPatientRecordMyself);
    }

    for (const patientUnderCare of recordsUnderCare) {
      const locationPatientRecordsForPreferredLocation =
        patientUnderCare.location_patient_records.filter(
          (re) => re.location_id === location_id,
        );

      if (locationPatientRecordsForPreferredLocation.length > 0) {
        selectedDependentIds.push(
          locationPatientRecordsForPreferredLocation[0].id,
        );

        const selectedLocationPatientRecord =
          await patientService.findLocationPatientRecord(
            location_id,
            locationPatientRecordsForPreferredLocation[0].id,
          );
        if (selectedLocationPatientRecord) {
          locationPatientRecords.push(selectedLocationPatientRecord);
        }
      }
    }

    if (!selectedDependentIds.length) return [];

    const allOrders = await this.getOrdersByPatients(
      location_id,
      selectedDependentIds,
      in_progress,
    );

    const patientOrders: PatientOrder[] = [];
    for (const order of allOrders) {
      if (order.items) {
        patientOrders.push({
          order: order,
          orderProgressStatus: this.getOrderProgressStatus(order),
          patient: locationPatientRecords.filter(
            (lpr) => lpr.id === order.patient_id,
          )[0],
        });
      }
    }

    return patientOrders.sort((po1, po2) =>
      po1.order.created_at < po2.order.created_at ? 1 : -1,
    );
  }

  public getOrderProgressStatus(
    order: OrderDto,
  ): OrderProgressStatus | undefined {
    const totalItems = order.items?.length || 0;
    let totalItemsNoValue = 0;
    let totalItemsNotStarted = 0;
    let totalItemsStarted = 0;
    let totalItemsFilled = 0;
    let totalItemsCompleted = 0;

    order.items?.forEach((orderItem) => {
      const status = orderItem.order_progress_status;

      if (status === OrderProgressStatus.NotStarted) {
        totalItemsNotStarted++;
      } else if (status === OrderProgressStatus.Started) {
        totalItemsStarted++;
      } else if (status === OrderProgressStatus.Filled) {
        totalItemsFilled++;
      } else if (status === OrderProgressStatus.Completed) {
        totalItemsCompleted++;
      } else {
        totalItemsNotStarted++;
        totalItemsNoValue++;
      }
    });

    // based on https://rxwiki.atlassian.net/browse/BLUES-5312
    let orderProgressStatus: OrderProgressStatus | undefined = undefined;

    if (totalItemsNotStarted > 0) {
      orderProgressStatus = OrderProgressStatus.NotStarted;
    }
    if (
      totalItemsStarted > 0 ||
      totalItemsFilled > 0 ||
      totalItemsCompleted > 0
    ) {
      orderProgressStatus = OrderProgressStatus.Started;
    }
    if (
      totalItemsFilled > 0 &&
      totalItemsNotStarted === 0 &&
      totalItemsStarted === 0
    ) {
      orderProgressStatus = OrderProgressStatus.Filled;
    }
    if (totalItems === totalItemsCompleted) {
      orderProgressStatus = OrderProgressStatus.Completed;
    }
    // special use case when all order_progress_status is undefined
    if (totalItemsNoValue === totalItems) {
      return undefined;
    }

    return orderProgressStatus;
  }
}

export default new OrderService(
  ORDER_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
