import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import {
  AuthorType,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Avatar } from 'assets/components/avatar';
import { Modal } from 'assets/components/modal';
import { InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import {
  IMessageExtended,
  IUploadFilesResult,
  MessageStatus,
} from 'assets/types/messageTypes';
import { getMessages } from 'assets/utils/messageUtils';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { User } from 'react-native-gifted-chat';
import { v4 } from 'uuid';
import FileStorageService from '../../api/file-storage-service';
import UnifiedCommsService from '../../api/unified-comms-service';
import { useUserState } from '../../store/user-store';
import { ChatBox } from './Chatbox';
import { ConversationStackParamList } from './ConversationNavigation';
import { IFrame } from './IFrame';
import { LinkWindowNative } from './LinkWindowNative';
import {
  intitialInternalLinkData,
  MESSAGES_TAKE,
  URL_VIDEO_PATTERN,
} from './constants';
import {
  addFailedMessage,
  removeFailedMessage,
  removeSelectedConversation,
  removeSelectedMessages,
  setCurrentPatientRecord,
  setMessagesPagination,
  setSelectedConversation,
  setSelectedMessages,
  setViewedConversationsSet,
} from './messages-actions';
import { useMessagesState } from './messages-store';
import { LinkData, InternalLinkData } from './types';
import { ImagePickerAsset } from 'expo-image-picker';
import { findHeaderThatDisallowsEmbedding, getOriginalURL } from './utils';
import { VideoOverlay } from '../../components/video-overlay/VideoOverlay';
import { SHORT_URL_DOMAIN } from '../../common/constants';
import PatientService from '../../api/patient-service';
import { DocumentPickerAsset } from 'expo-document-picker';
import { ampli } from '../../ampli';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';

export const ConversationBox: FunctionComponent<
  PropsWithChildren<ConversationBoxProps>
> = ({ route, navigation }) => {
  const conversationId = route.params.conversationId;
  const locationPatientRecordId = route.params.locationPatientRecordId;
  const styles = useStyles();
  const user = useUserState((state) => state.user);
  const selectedMessages = useMessagesState((state) => state.selectedMessages);
  const baseNavigation = useNavigation<NavigationProp<ParamListBase>>();
  const messagesPagination = useMessagesState(
    (state) => state.messagesPagination,
  );
  const viewedConversationsSet = useMessagesState(
    (state) => state.viewedConversationsSet,
  );
  const failedMessagesInConversation = useMessagesState(
    (state) => state.failedMessagesInConversation,
  );
  const newMessageAttachmentsDocuments = useMessagesState(
    (state) => state.newMessageAttachmentsDocuments,
  );
  const newMessageAttachmentsImages = useMessagesState(
    (state) => state.newMessageAttachmentsImages,
  );
  const currentPatientRecord = useMessagesState(
    (state) => state.currentPatientRecord,
  );
  const pharmacyId = user?.pharmacyId;
  const locationId = user?.preferredPharmacyLocationId;
  const [conversation, setConversation] = useState<DirectMessagePatientDto>();
  const [statusInConversation, setStatusInConversation] = useState<
    Record<string, MessageStatus>
  >({
    [conversationId]: MessageStatus.Idle,
  });
  const [linkData, setLinkData] = useState<LinkData>({
    link: '',
    isOpened: false,
  });
  const [videoLinkData, setVideoLinkData] = useState<InternalLinkData>(
    intitialInternalLinkData,
  );

  const [appointmentLinkData, setAppointmentLinkData] =
    useState<InternalLinkData>(intitialInternalLinkData);

  useEffect(() => {
    void (async () => {
      if (user?.patientRecordId) {
        const patientRecord = await PatientService.findPatientRecord(
          user.patientRecordId,
        );
        setCurrentPatientRecord(patientRecord);
      } else {
        throw new Error('Missing user.patientRecordId to get a patient info!');
      }
    })();
  }, []);

  const chatUser: User = useMemo(() => {
    return {
      _id: user?.id ?? '',
      name: `${currentPatientRecord?.first_name ?? ''} ${
        currentPatientRecord?.last_name ?? ''
      }`,
      avatar: function () {
        return (
          <Avatar
            size={32}
            firstName={currentPatientRecord?.first_name ?? ''}
            lastName={currentPatientRecord?.last_name ?? ''}
          />
        );
      },
    };
  }, [currentPatientRecord]);

  async function uploadFiles(
    locationId: string,
    pharmacyId: string,
    files: DocumentPickerAsset[] | undefined,
  ): Promise<IUploadFilesResult> {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    if (!files || !files.length) {
      return result;
    }

    for (const file of files) {
      try {
        const newName = v4();
        const extension = FileStorageService.getFileExtension(file.name);
        const fileName = `${newName}.${extension}`;

        await FileStorageService.uploadFile(
          LocationCategory.DirectMessage,
          locationId,
          fileName,
          pharmacyId,
          file,
        );

        result.filesData.push({
          name: file.name,
          stored_filename: fileName,
        });
      } catch (error) {
        console.error(`Error uploading file ${file.name}. Error: `, error);
        result.isError = true;
      }
    }

    return result;
  }

  const uploadImages = async (
    pharmacyId: string,
    locationId: string,
    images: ImagePickerAsset[],
  ) => {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    for (const image of images) {
      try {
        const newName = v4();
        const fileName = `${newName}.png`;

        await FileStorageService.uploadFile(
          LocationCategory.DirectMessage,
          locationId,
          fileName,
          pharmacyId,
          image.uri,
        );

        result.filesData.push({
          name: fileName,
          stored_filename: fileName,
        });
      } catch (error) {
        console.error(`Error uploading image. Error: `, error);
        result.isError = true;
      }
    }
    return result;
  };

  const uploadAttachments = async () => {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    if (newMessageAttachmentsDocuments.length) {
      const documentsUploadResult = await uploadFiles(
        locationId!,
        pharmacyId!,
        newMessageAttachmentsDocuments,
      );

      result.isError = documentsUploadResult.isError;
      result.filesData = documentsUploadResult.filesData;
    }

    if (newMessageAttachmentsImages.length) {
      const imagesUploadResult = await uploadImages(
        pharmacyId!,
        locationId!,
        newMessageAttachmentsImages,
      );

      if (imagesUploadResult.isError) {
        result.isError = true;
      }
      result.filesData = [...result.filesData, ...imagesUploadResult.filesData];
    }

    return result;
  };

  async function getConversation(
    locationPatientRecordId: string,
    conversationId: string,
    skip: number,
    take: number,
  ) {
    if (!pharmacyId || !locationId) return undefined;

    const data = await Promise.all([
      UnifiedCommsService.getAllMessagesByConversation(
        locationId,
        locationPatientRecordId,
        conversationId,
        skip,
        take,
      ),
      UnifiedCommsService.getConversation(
        locationId,
        locationPatientRecordId,
        conversationId,
      ),
    ]);

    setMessagesPagination({
      skip,
      take,
      count: data[0].count,
    });

    return data;
  }

  const loadMore = async () => {
    if (messagesPagination.take >= messagesPagination.count + MESSAGES_TAKE) {
      return;
    }

    if (pharmacyId && locationId) {
      const messagesResult = await getMessages({
        pharmacyId: pharmacyId,
        locationId: locationId,
        locationPatientId: locationPatientRecordId,
        conversationId: conversationId,
        UnifiedCommsService: UnifiedCommsService,
        FileStorageService: FileStorageService,
        skip: 0,
        take: messagesPagination.take,
        failedMessagesInConversation,
        isPharmacyApp: false,
      });
      ampli.messageRead({
        conversationID: conversationId,
        messageID:
          messagesResult.messages[messagesResult.count - 1]._id.toString(),
        messageReadTime: new Date().toISOString(),
        messageSubject: conversation?.subject ?? '',
      });
      setSelectedMessages(messagesResult.messages);
      setMessagesPagination({
        take: messagesPagination.take + MESSAGES_TAKE,
        skip: 0,
        count: messagesPagination.count,
      });
    }
  };

  const onSend = async (newMessages: IMessageExtended[]) => {
    if (!pharmacyId || !locationId) {
      return;
    }

    const [newMessage] = newMessages;
    let updatedSelectedMessages = [...selectedMessages];

    try {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (failedMessagesInConversation[conversationId]?.length) {
        removeFailedMessage(conversationId, newMessage);
      }

      const filesUploadResult = await uploadAttachments();

      const selectedMessagesWhenSending = selectedMessages.filter(
        (item) => item._id !== newMessage._id,
      );
      updatedSelectedMessages = [
        ...newMessages,
        ...selectedMessagesWhenSending,
      ];
      setSelectedMessages(updatedSelectedMessages);

      setStatusInConversation({
        [conversationId]: MessageStatus.Sending,
      });

      await UnifiedCommsService.createMessage(
        pharmacyId,
        locationId,
        locationPatientRecordId,
        conversationId,
        {
          author_id: String(chatUser._id),
          author_type: AuthorType.Patient,
          content: newMessage.text,
          patient_viewed_all_messages: true,
          pharmacy_viewed_all_messages: false,
          attachments: filesUploadResult.filesData.length
            ? filesUploadResult.filesData
            : [],
        },
      );

      const messagesResult = await getMessages({
        pharmacyId: pharmacyId,
        locationId: locationId,
        locationPatientId: locationPatientRecordId,
        conversationId: conversationId,
        UnifiedCommsService: UnifiedCommsService,
        FileStorageService: FileStorageService,
        skip: 0,
        take: MESSAGES_TAKE,
        failedMessagesInConversation,
        isPharmacyApp: false,
      });
      updatedSelectedMessages = messagesResult.messages;

      setStatusInConversation({
        [conversationId]: MessageStatus.Delivered,
      });
    } catch (error) {
      console.error('An error occurred while sending a message: ', error);

      const [lastMessage] = selectedMessages;
      if (chatUser) {
        const secondsOfLastMessage = new Date(
          lastMessage.createdAt,
        ).getSeconds();
        const createdAt = new Date(lastMessage.createdAt).setSeconds(
          secondsOfLastMessage + 1,
        );

        const failedMessage: IMessageExtended = {
          _id: newMessage._id,
          text: newMessage.text,
          user: chatUser,
          createdAt,
          attachments: { files: [], images: [] },
          author_type: AuthorType.Patient,
          sent: false,
        };
        const indexOfFailedMessage = updatedSelectedMessages.findIndex(
          (message) => message._id === failedMessage._id,
        );

        if (indexOfFailedMessage >= 0) {
          updatedSelectedMessages[indexOfFailedMessage] = failedMessage;
        } else {
          updatedSelectedMessages.unshift(failedMessage);
        }
        addFailedMessage(conversationId, failedMessage);
      }
      setStatusInConversation({ [conversationId]: MessageStatus.Error });
    } finally {
      setSelectedMessages(updatedSelectedMessages);
    }
  };

  useEffect(() => {
    void (async () => {
      if (selectedMessages.length) {
        // to remove messages from previous conversation, so user will not see them in a new conversation before loading new messages
        removeSelectedMessages();
      }
      setSelectedConversation(route.params.conversationId);

      const conversationData = await getConversation(
        locationPatientRecordId,
        conversationId,
        messagesPagination.skip,
        messagesPagination.take,
      );

      let patientViewedAllMessages;
      let pharmacyViewedAllMessages;

      if (conversationData) {
        patientViewedAllMessages =
          conversationData[1].patient_viewed_all_messages;
        pharmacyViewedAllMessages =
          conversationData[1].pharmacy_viewed_all_messages;

        setConversation(conversationData[1]);

        navigation.setParams({
          title:
            conversationData[1].subject +
            ' - ' +
            (conversationData[1].first_name.pharmacy ?? // The data is from ElasticSearch and could be in a bad state if repopulation were not run
              'no_first_name_pharmacy') +
            ' ' +
            (conversationData[1].last_name.pharmacy ?? // The data is from ElasticSearch and could be in a bad state if repopulation were not run
              'no_last_name_pharmacy'),
        });
      }

      if (pharmacyId && locationId) {
        const messagesResult = await getMessages({
          pharmacyId: pharmacyId,
          locationId: locationId,
          locationPatientId: locationPatientRecordId,
          conversationId: conversationId,
          UnifiedCommsService: UnifiedCommsService,
          FileStorageService: FileStorageService,
          skip: 0,
          take: MESSAGES_TAKE,
          failedMessagesInConversation,
          isPharmacyApp: false,
        });
        setSelectedMessages(messagesResult.messages);
      }

      if (
        !patientViewedAllMessages &&
        pharmacyViewedAllMessages !== undefined &&
        locationId &&
        pharmacyId
      ) {
        const updatedConversation =
          await UnifiedCommsService.updateUserViewedStatus(
            locationId,
            locationPatientRecordId,
            conversationId,
            {
              patient_viewed_all_messages: true,
              pharmacy_viewed_all_messages: pharmacyViewedAllMessages,
            },
          );

        const viewedConversationsSetCopy = new Set(viewedConversationsSet);
        viewedConversationsSetCopy.add(updatedConversation.id);
        setViewedConversationsSet(viewedConversationsSetCopy);
      }
    })();

    return () => {
      removeSelectedConversation();
    };
  }, [conversationId]);

  useEffect(() => {
    const updateUserViewedStatus = async () => {
      if (conversation?.patient_viewed_all_messages) {
        return;
      }

      if (!pharmacyId || !locationId || !conversation) {
        return;
      }

      const updatedConversation =
        await UnifiedCommsService.updateUserViewedStatus(
          locationId,
          locationPatientRecordId,
          conversationId,
          {
            patient_viewed_all_messages: true,
            pharmacy_viewed_all_messages:
              conversation.pharmacy_viewed_all_messages,
          },
        );

      const viewedConversationsSetCopy = new Set(viewedConversationsSet);
      viewedConversationsSetCopy.add(updatedConversation.id);
      setViewedConversationsSet(viewedConversationsSetCopy);
    };

    if (Platform.OS === 'web') {
      const onVisibilityChange = () => {
        if (document.visibilityState == 'visible') {
          updateUserViewedStatus();
        }
      };

      window.addEventListener('focus', updateUserViewedStatus);

      document.addEventListener('visibilitychange', onVisibilityChange);

      return () => {
        if (Platform.OS === 'web') {
          window.removeEventListener('focus', updateUserViewedStatus);
          document.removeEventListener('visibilitychange', onVisibilityChange);
        }
      };
    }
  }, [conversation]);

  const checkIfNotAllowedToEmbedWebDataFrom = async (
    url: string,
  ): Promise<boolean> => {
    try {
      // Users can send random links via messages.
      // We have a feature to embed these external pages into our platform.
      // But some websites disallow to embed their content.
      // This endpoint we need for making a request to external random website to check if they have a header
      // that reflects disallowing to be embedded.
      // There is no possibility to check it on frontend.
      const headers = (await UnifiedCommsService.getUrlResponseHeaders(
        url,
      )) as Record<string, string>;
      const disallowingHeader = findHeaderThatDisallowsEmbedding(headers);
      return Boolean(disallowingHeader);
    } catch (error) {
      console.error('Error fetching headers:', error);
      return true;
    }
  };

  const handleVideoLinkClick = (link: string) => {
    const url = new URL(link);
    const videoId = url.searchParams.get('videoId');
    const locale = url.searchParams.get('locale') || undefined;

    if (videoId)
      setVideoLinkData({ internalId: videoId, isOpened: true, locale });
  };

  const handleAppointmentLinkClick = (link: string) => {
    const url = new URL(link);
    const appointmentId = url.searchParams.get('appointment_id');
    const location_id = url.searchParams.get('location_id') || undefined;

    baseNavigation.navigate('appointment', {
      appointment_id: appointmentId,
      location_id: location_id,
    });
  };

  const handleAppointmentsLinkClick = (link: string) => {
    const url = new URL(link);
    const appointmentId = url.searchParams.get('appointment_type_id');
    const location_id = url.searchParams.get('location_id') || undefined;

    baseNavigation.navigate('appointments', {
      screen: 'upcoming',
      params: { appointment_type_id: appointmentId, location_id: location_id },
    });
  };

  async function onLinkClick(link: string) {
    const urlDomain = new URL(link).hostname;
    let newLink = link;
    if (urlDomain.includes(SHORT_URL_DOMAIN)) {
      const response = await getOriginalURL(link);
      newLink = response ?? link;
    }

    if (URL_VIDEO_PATTERN.test(newLink)) {
      handleVideoLinkClick(newLink);
      return;
    }

    if (newLink.includes('appointment') && newLink.includes('appointment_id')) {
      handleAppointmentLinkClick(newLink);
      return;
    }

    if (
      newLink.includes('appointments') &&
      newLink.includes('appointment_type_id')
    ) {
      handleAppointmentsLinkClick(newLink);
      return;
    }

    let isNotAllowedToEmbed: boolean | undefined;
    if (Platform.OS === 'web') {
      isNotAllowedToEmbed = await checkIfNotAllowedToEmbedWebDataFrom(newLink);

      if (isNotAllowedToEmbed) {
        window.open(newLink, '_blank');

        // Not updating linkData (that controls opening links in modal) for links,
        // that are not allowed to be embedded
        return;
      }
    }

    setLinkData((prevValue) => {
      const isSameLink = prevValue.link === newLink ? true : false;
      return {
        ...prevValue,
        link: isSameLink ? '' : newLink,
        isOpened: isSameLink ? false : true,
      };
    });
  }

  function closeLinkWindow() {
    setLinkData((prevValue) => {
      return {
        ...prevValue,
        link: '',
        isOpened: false,
      };
    });
  }

  return (
    <>
      <InternalScreenContainer
        style={styles.container}
        disableScrollView={true}
        useBottomInset={true}
        title={route.params.title}
        keyboardVerticalOffset={80}
      >
        <ChatBox
          messages={selectedMessages}
          onSend={onSend}
          user={chatUser}
          conversation={conversation}
          status={statusInConversation[conversationId]}
          onLinkClick={onLinkClick}
          loadMore={loadMore}
        />
        {Platform.OS === 'web' ? (
          <Modal
            show={linkData.isOpened}
            okButtonProps={{
              onPress: closeLinkWindow,
              logger: { id: 'external-webpage-close-button-modal' },
              text: getText('close'),
            }}
            dismissButtonProps={{
              onPress: closeLinkWindow,
              logger: { id: 'external-webpage-dismiss-button-modal' },
            }}
          >
            <IFrame src={linkData.link} />
          </Modal>
        ) : (
          <LinkWindowNative
            src={linkData.link}
            show={linkData.isOpened}
            onClose={closeLinkWindow}
          />
        )}
      </InternalScreenContainer>
      <VideoOverlay data={videoLinkData} setData={setVideoLinkData} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
}));

type ConversationBoxProps = NativeStackScreenProps<
  ConversationStackParamList,
  'conversation-box'
>;
