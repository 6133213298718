import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { NotificationListItem } from './NotificationListItem';
import { Text } from 'assets/components/text';
import { Divider } from 'react-native-paper';
import {
  isNew,
  isOlderThanThisMonth,
  isThisMonth,
  isThisWeek,
  sortNotifications,
} from './utils';
import { getText } from 'assets/localization/localization';
import {
  NotificationNavigationScreen,
  UserNotificationDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { LoadingIndicator } from 'assets/components/loading-indicator';

export const NotificationList: FunctionComponent<
  PropsWithChildren<NotificationListProps>
> = ({
  notifications,
  markAsRead,
  markAllAsRead,
  markAsHidden,
  markAsUnread,
  refreshNotifications,
  handleNavigationScreen,
  navigation,
  closeBottomSheet,
  closeMenu,
  isLoading = false,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const sortedNotifications = sortNotifications(notifications);

  const newNotifications = sortedNotifications.filter((notification) =>
    isNew(notification),
  );

  const thisWeekNotifications = sortedNotifications.filter((notification) =>
    isThisWeek(notification),
  );

  const thisMonthNotifications = sortedNotifications.filter((notification) =>
    isThisMonth(notification),
  );

  const oldNotifications = sortedNotifications.filter((notification) =>
    isOlderThanThisMonth(notification),
  );

  const NotificationSection: FunctionComponent<{
    notifications: UserNotificationDto[];
    heading: string;
  }> = ({ notifications, heading }) => {
    if (notifications.length === 0) {
      return null;
    }

    return (
      <>
        <View style={styles.headingContainer}>
          <Text style={styles.heading}>{heading}</Text>
          {notifications === newNotifications && (
            <TouchableOpacity
              testID={NotificationListTestIDs.markAllAsRead}
              onPress={markAllAsRead}
            >
              <Text style={styles.readActionText}>
                {getText('mark-all-as-read')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <Divider style={styles.divider} />
        {notifications.map((notification, index, array) => (
          <NotificationListItem
            refreshNotifications={refreshNotifications}
            markAsRead={markAsRead}
            markAsUnread={markAsUnread}
            markAsHidden={markAsHidden}
            key={notification.id}
            type={notification.type}
            category={notification.category}
            body={notification.body}
            timestamp={new Date(notification.created_at)}
            isRead={notification.is_read}
            hideBorder={index === array.length - 1 ? true : false}
            notificationId={notification.id}
            navigationScreen={notification.navigation_screen}
            navigationParams={notification.navigation_params}
            navigation={navigation}
            closeMenu={closeMenu}
            closeBottomSheet={closeBottomSheet}
            handleNavigationScreen={handleNavigationScreen}
          />
        ))}
      </>
    );
  };

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <View>
      <NotificationSection
        notifications={newNotifications}
        heading={getText('new-messages')}
      />
      <NotificationSection
        notifications={thisWeekNotifications}
        heading={getText('this-week')}
      />
      <NotificationSection
        notifications={thisMonthNotifications}
        heading={getText('this-month')}
      />
      <NotificationSection
        notifications={oldNotifications}
        heading={getText('older')}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  divider: {
    marginVertical: getSpacing(2),
  },
  heading: {
    color: theme.palette.gray[900],
    ...theme.fonts.medium,
    flex: 1,
    fontSize: 14,
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  readActionText: {
    color: theme.palette.primary[600],
  },
}));

export interface NotificationListProps {
  notifications: UserNotificationDto[];
  markAsRead: (notificationId: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  markAsHidden: (notificationId: string) => Promise<void>;
  markAsUnread: (notificationId: string) => Promise<void>;
  handleNavigationScreen: (
    navigationScreen: NotificationNavigationScreen,
    navigationParams?: Record<string, string>,
  ) => Promise<void>;
  refreshNotifications: () => Promise<void>;
  navigation: NavigationProp<ParamListBase>;
  closeMenu?: () => void;
  closeBottomSheet?: () => void;
  isLoading?: boolean;
}

export const NotificationListTestIDs = {
  markAllAsRead: 'mark-all-as-read',
};
