import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Form, InternalScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { Divider } from 'react-native-paper';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { CardType } from '@digitalpharmacist/patient-service-client-axios';

import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { Text } from 'assets/components/text/Text';
import { useForm } from 'assets/form';
import { useRefillMedicationsStore } from './refill-store';
import { RefillStackParamList } from './RefillNavigation';
import { RefillTestIDs } from './RefillTestIDs';
import { MedicalInfoFormFields } from '../../components/medical-info/MedicalInfoFormFields';

export const RefillMedicalInfos: FunctionComponent<
  PropsWithChildren<RefillMedicalInfosProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { selectedPatientRecord: patientRecord, updateMedicationsData } =
    useRefillMedicationsStore();

  if (!patientRecord) throw Error('No Patient Record');

  const form = useForm({
    defaultValues: {
      gender: patientRecord.gender,
      allergies: patientRecord.allergies,
      medical_conditions: patientRecord.medical_conditions,
    },
  });

  const handleOnPressDone = () => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      selectedPatientRecord: {
        ...patientRecord,
        ...form.getValues(),
      },
    });
    navigation.navigate('edit-patient-card-refill', {
      cardType: CardType.InsurancePrimary,
      nextNavigation: 'check-second-insurance',
    });
  };

  return (
    <InternalScreenContainer
      title={getText('medications-fill-your-prescription')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: form.handleSubmit(handleOnPressDone),
          testID: RefillTestIDs.refillMedicalInfosSubmit,
          logger: { id: RefillTestIDs.refillMedicalInfosSubmit },
          text: getText('next'),
        },
      ]}
      scrollToTop={
        !!form.getFieldState('gender').error ||
        !!form.getFieldState('allergies').error
      }
    >
      <Form.Alert
        visible={
          !!form.getFieldState('gender').error ||
          !!form.getFieldState('allergies').error
        }
        title={getText('unable-to-process-complete-required-fields')}
        intent="error"
      />
      <Text style={styles.textTitle}>{getText('medical-information')}</Text>
      <Divider />
      <Form methods={form}>
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(2) }}>
            <RadioButtonGroupField
              label={getText('gender-on-insurance')}
              rules={{
                required: getText('gender-is-required'),
              }}
              name="gender"
              values={[
                {
                  text: getText('male'),
                  value: 'male',
                },
                {
                  text: getText('female'),
                  value: 'female',
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <MedicalInfoFormFields showEasyOpenBootleCaps={false} />
      </Form>
    </InternalScreenContainer>
  );
};

export type RefillMedicalInfosProps = NativeStackScreenProps<
  RefillStackParamList,
  'refill-medical-infos'
>;

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
  },
}));
