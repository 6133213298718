import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import {
  RadioButton,
  RadioButtonInputMode,
  RadioButtonValue,
} from './RadioButton';
import { Text } from '../text';

export const RadioButtonGroupInput = <
  TValue extends RadioButtonValue = string,
>({
  label,
  values,
  value,
  hintMessage,
  disabled,
  onValueChange,
  testID,
  isHorizontal,
  mode,
}: PropsWithChildren<RadioButtonGroupInputProps<TValue>>) => {
  const theme = useTheme();
  const styles = useStyles();
  const [currentValue, setCurrentValue] = useState<TValue | undefined>(value);

  useEffect(() => {
    if (currentValue !== value) {
      setCurrentValue(value);
    }
  }, [value]);

  const handleOnValueChange = (value: TValue) => {
    setCurrentValue(value);
    onValueChange(value);
  };

  return (
    <View testID={`${testID}-container`}>
      {label && (
        <Text testID={RadioButtonGroupInputTestIDs.label} style={styles.label}>
          {label}
        </Text>
      )}
      <View style={[isHorizontal && styles.horizontalOptions]}>
        {values.map((item, index) => {
          return (
            <RadioButton<TValue>
              key={String(item.value)}
              text={item.text}
              testID={String(item.value)}
              value={item.value}
              onValueChange={handleOnValueChange}
              disabled={disabled}
              selected={item.value === currentValue}
              isLast={!isHorizontal && index === values.length - 1}
              description={item.description}
              mode={mode}
            />
          );
        })}
      </View>

      {!!hintMessage && (
        <Text
          testID={RadioButtonGroupInputTestIDs.hintMessage}
          style={styles.hintMessage}
        >
          {hintMessage}
        </Text>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    ...theme.lumistryFonts.text.medium,
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(1),
  },
  hintMessage: {
    color: theme.palette.gray[500],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
  horizontalOptions: {
    flexDirection: 'row',
    gap: theme.getSpacing(1),
  },
}));

export interface RadioButtonGroupInputProps<T> {
  label?: string;
  testID?: string;
  values: RadioButtonGroupItem<T>[];
  value?: T;
  hintMessage?: string;
  disabled?: boolean;
  onValueChange: (value: T) => void;
  isHorizontal?: boolean;
  mode?: RadioButtonInputMode;
}

export interface RadioButtonGroupItem<T> {
  text: string;
  value: T;
  description?: string;
}

export const RadioButtonGroupInputTestIDs = {
  label: 'rdb-text',
  error: 'rdb-error',
  hintMessage: 'rdb-hint',
};
