import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const PatientEduWatchForIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      testID={testID}
      viewBox="0 0 50 36"
      fill="none"
    >
      <Path
        d="M12.7593 11.2373C15.5898 8.4288 18.3574 5.61752 21.1977 2.88095C24.5152 -0.314325 28.9252 -0.125843 32.3264 3.2788C35.8949 6.84976 36.1824 11.2201 32.9814 14.5688C30.3102 17.3635 27.5524 20.0757 24.8274 22.8182C24.7255 22.921 24.563 22.9632 24.4708 23.0116C20.6239 19.1442 16.7874 15.2873 12.7593 11.2373Z"
        fill="#118F7E"
      />
      <Path
        d="M11.0373 12.9598C8.22886 15.7901 5.41694 18.5575 2.68102 21.3982C-0.514577 24.7154 -0.325858 29.1251 3.07854 32.527C6.64974 36.0951 11.0201 36.3826 14.3692 33.1819C17.1635 30.5104 19.8754 27.7529 22.6182 25.0275C22.7204 24.9257 22.7632 24.7632 22.8117 24.6713C18.9438 20.8242 15.0873 16.9882 11.0373 12.9598Z"
        fill="#374D58"
      />
      <Path
        d="M47.2449 25.451C47.0429 26.2101 46.8293 26.9654 46.6458 27.7288C46.548 28.1388 46.3986 28.3042 46.0333 28.2578C45.9295 28.2701 45.8289 28.2694 45.7542 28.2504C43.4264 27.6547 41.1089 27.0242 38.7898 26.4004C36.9517 25.907 35.1214 25.3875 33.2758 24.9273C32.9321 24.8416 32.8133 24.7038 32.8164 24.4835L32.8161 24.4832L32.8164 24.4822C32.8177 24.407 32.8327 24.3216 32.8583 24.226C33.0605 23.4675 33.2742 22.7116 33.458 21.9482C33.5552 21.5382 33.7052 21.3732 34.0701 21.4188C34.1739 21.407 34.2745 21.4075 34.3493 21.427C36.677 22.0219 38.9945 22.6529 41.3133 23.2773C43.1521 23.7707 44.9821 24.2898 46.8277 24.7498C47.1714 24.8354 47.2901 24.9732 47.287 25.1935L47.2873 25.1942L47.287 25.1947C47.2861 25.2701 47.2708 25.3554 47.2449 25.451ZM40.3427 14.9622C40.2783 14.9598 40.2152 14.9598 40.1505 14.9598C39.4605 14.9598 38.7593 15.031 38.0539 15.1819C37.5589 15.2882 37.0861 15.4185 36.6339 15.5732C32.8964 16.9657 30.2349 20.5654 30.2349 24.7891C30.2349 30.2188 34.6349 34.6207 40.0645 34.6207C45.4942 34.6207 49.8958 30.2188 49.8958 24.7891C49.8958 19.4526 45.6433 15.1088 40.3427 14.9622Z"
        fill="#118F7E"
      />
    </Svg>
  );
};
