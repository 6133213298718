import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import {
  useFonts,
  Lato_100Thin,
  Lato_300Light,
  Lato_400Regular,
  Lato_700Bold,
  Lato_100Thin_Italic,
  Lato_300Light_Italic,
  Lato_400Regular_Italic,
  Lato_700Bold_Italic,
  Lato_900Black,
  Lato_900Black_Italic,
} from '@expo-google-fonts/lato';
import { PlatformType } from 'assets/components/types';
import { Platform } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import { LoadingFullScreenView } from './LoadingFullScreenView';
import { logError } from '../logging/logger';

/*
 * Block rendering of app until required resources and data have been loaded
 */
export const AppLoader: FunctionComponent<
  PropsWithChildren<AppLoaderProps>
> = ({ children, onBeforeLoad, platform = Platform.OS }) => {
  const [fontsLoaded, error] = useFonts({
    Lato_100Thin,
    Lato_300Light,
    Lato_400Regular,
    Lato_700Bold,
    Lato_100Thin_Italic,
    Lato_300Light_Italic,
    Lato_400Regular_Italic,
    Lato_700Bold_Italic,
    Lato_900Black,
    Lato_900Black_Italic,
  });

  const [appLoaded, setAppLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      await SplashScreen.preventAutoHideAsync();
      // load anything that it is related static contents like fonts
      if (onBeforeLoad) {
        await onBeforeLoad();
      }
      await SplashScreen.hideAsync();

      setAppLoaded(true);
    })();
  }, []);

  // TODO: revisit this after the Expo sdk 50 update when we can update expo-fonts.
  // Putting this logError here so we at least log if we have an error
  // loading fonts on deployed apps.
  if (!fontsLoaded && error) {
    logError(error);
  }

  if (!appLoaded) {
    return <LoadingFullScreenView />;
  }

  return children;
};

export interface AppLoaderProps {
  onBeforeLoad?: () => Promise<void>;
  platform?: PlatformType;
}
