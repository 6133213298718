import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Image,
  Platform,
  useWindowDimensions,
  View,
  Linking,
} from 'react-native';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { ListAccordion } from 'assets/components/list-accordion';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
const noImage = require('../../../assets/no_image.png');
const noVideo = require('../../../assets/no_video.png');
import { Button } from 'assets/components/button';
import { AlertCircleIcon, MessageSquareIcon } from 'assets/icons';
import { Divider } from 'react-native-paper';
import { getText } from 'assets/localization/localization';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import MedicationService, { Medication } from './MedicationService';
import { MedicationGuideDetails } from './MedicationGuideDetails';
import { EducationInfoSheet } from './EducationInfoSheet';
import { BrightcoveVideoPlayer } from '../brightcove-video-player';
import { NewMessageHandler } from '../../screens/messages/MessageProps';
import { formatDate, formatDateFromApi } from '../../common/datetime-utils';
import { MedicationAutoFillInfo } from './MedicationAutoFillInfo';
import PhoneNumber from '../phone-number/PhoneNumber';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { useAppStateStore } from '../../store/app-store';
import { useShowNewMessageButton } from '../../hooks/useShowNewMessageButton';

export const MedicationDetails: FunctionComponent<MedicationDetailsProps> = ({
  prescription,
  newMessageRef,
  onChange,
  onRefillButtonPress,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [medication, setMedication] = useState<Medication | undefined>();
  const sheetRef = React.useRef<BaseModalHandler>(null);
  const educationSheetRef = React.useRef<BaseModalHandler>(null);
  const { height } = useWindowDimensions();
  const { is_patient_start_chat_available } = useAppStateStore();
  const [showDirectionsAccordion, setShowDirectionsAccordion] =
    useState<boolean>(true);

  const showNewMessageButton = useShowNewMessageButton();

  const isWeb = Platform.OS === 'web';
  const isIntegrated =
    prescription.integration_type !== 'Manual' && prescription.ndc != null;

  const dateOfBirth = formatDateFromApi(prescription.patient_date_of_birth);

  const lastRefillOrOrderDate = prescription.last_refill_date
    ? prescription.last_refill_date
    : prescription.last_order_date;

  const lastRefillOrOrderDateFormatted = lastRefillOrOrderDate
    ? formatDateFromApi(lastRefillOrOrderDate)
    : '';

  const lastRefillOrOrderText = prescription.last_refill_date
    ? getText('last-filled-on')
    : getText('last-ordered-on');

  const writtenDate =
    prescription.written_date && formatDate(prescription.written_date);

  const handleOnPhoneNumberPress = () => {
    if (!prescription.doctor_phone) return;
    if (!isWeb) {
      void Linking.openURL(`tel:${prescription.doctor_phone}`);
    }
  };

  const handleOpenMedDetails = () => {
    sheetRef.current?.show();
  };

  const handleOpenEducationDetails = () => {
    educationSheetRef.current?.show();
  };

  const getMedicationInfo = async () => {
    if (!prescription.ndc) return;

    const { medications } = await MedicationService.getMedicationInfo(
      prescription.ndc,
    );
    setMedication(medications[0]);
  };

  const handleRefillPress = () => {
    if (!onRefillButtonPress) return;
    onRefillButtonPress(prescription);
  };

  const handleMessagePress = () => {
    newMessageRef.current?.show();
  };

  // Check if a value is defined. PS: 0 is a defined value
  const isDefined = (value: number | string | undefined | null) => {
    return !(value === undefined || value === null || value === '');
  };

  useEffect(() => {
    void getMedicationInfo();
  }, [prescription.ndc]);

  const handleDirectionsAccordionOnPress = () => {
    setShowDirectionsAccordion(!showDirectionsAccordion);
  };

  return (
    <View style={{ margin: theme.getSpacing(1) }}>
      <Form.Row>
        <Form.Column>
          <Text style={styles.labelStyle}>{getText('prescribed-for')}</Text>
        </Form.Column>
        <Form.Column>
          <Text style={styles.rightGreyText}>
            {prescription.patient_first_name} {prescription.patient_last_name}
          </Text>
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column></Form.Column>
        <Form.Column>
          <Text style={styles.rightGreyText}>
            {getText('date-of-birth-short')}: {dateOfBirth}
          </Text>
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column>
          <Text style={styles.labelStyle}>{getText('rx-number')}</Text>
        </Form.Column>
        <Form.Column>
          <Text style={styles.rightGreyText}>{prescription.rx_number}</Text>
        </Form.Column>
      </Form.Row>
      {lastRefillOrOrderDate && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>{lastRefillOrOrderText}</Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>
              {lastRefillOrOrderDateFormatted}
            </Text>
          </Form.Column>
        </Form.Row>
      )}

      <MedicationAutoFillInfo prescription={prescription} onChange={onChange} />

      {isDefined(prescription.remaining_refills) && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>{getText('refills-left')}</Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>
              {prescription.remaining_refills}
            </Text>
          </Form.Column>
        </Form.Row>
      )}

      {isDefined(prescription.quantity_on_hand) && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>
              {getText('quantity-dispensed')}
            </Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>
              {prescription.quantity_on_hand}
            </Text>
          </Form.Column>
        </Form.Row>
      )}

      {isDefined(prescription.days_supply) && (
        <Form.Row style={{ marginBottom: theme.getSpacing(0.5) }}>
          <Form.Column>
            <Text style={styles.labelStyle}>{getText('days-supply')}</Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>{prescription.days_supply}</Text>
          </Form.Column>
        </Form.Row>
      )}

      {prescription.sig && (
        <Form.Row>
          <Form.Column>
            <ListAccordion
              title={getText('directions')}
              backgroundColor={theme.palette.white}
              titleStyle={{ ...styles.titleStyle, ...styles.labelStyle }}
              listStyle={styles.listStyle}
              expanded={showDirectionsAccordion}
              onPress={() => handleDirectionsAccordionOnPress()}
            >
              <Text style={{ color: theme.palette.gray[700] }}>
                {prescription.sig}
              </Text>
            </ListAccordion>
          </Form.Column>
        </Form.Row>
      )}

      {isIntegrated && (
        <Form.Row>
          <Form.Column>
            <Image
              source={
                medication && medication.images && medication.images.length > 0
                  ? { uri: medication.images[0].url }
                  : noImage
              }
              style={{
                width: 162,
                height: 119,
              }}
              resizeMode={'contain'}
            />
          </Form.Column>
        </Form.Row>
      )}
      <Form.Row style={{ marginBottom: theme.getSpacing(3) }}>
        <Form.Column>
          <Text style={styles.defaultText}>
            {getText('questions-about-medication')}
          </Text>
          <Text style={styles.defaultText}>
            {getText('ask-your-pharmacist')}
          </Text>
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column style={styles.buttonContainer}>
          {is_patient_start_chat_available ? (
            <Button
              hierarchy={'secondary-gray'}
              logger={{
                id: 'medication-details-message-button',
              }}
              icon={MessageSquareIcon}
              onPress={handleMessagePress}
            >
              {getText('message')}
            </Button>
          ) : null}
          {onRefillButtonPress && (
            <Button
              hierarchy={'primary'}
              logger={{
                id: 'medication-details-refill-button',
              }}
              onPress={handleRefillPress}
              style={{ width: 132 }}
            >
              {getText('refill')}
            </Button>
          )}
        </Form.Column>
      </Form.Row>
      {isIntegrated && (
        <Form.Row>
          <Form.Column>
            <Text
              style={{
                fontWeight: 'bold',
                marginBottom: theme.getSpacing(2),
                marginTop: theme.getSpacing(4),
              }}
            >
              {getText('resources')}
            </Text>
            <Divider style={{ borderColor: theme.palette.gray[200] }} />
            {medication && medication.medication_video ? (
              <BrightcoveVideoPlayer
                style={styles.video}
                videoInfo={{
                  videoId: medication.medication_video.data.video_id,
                  accountId: medication.medication_video.data.account_id,
                  playerId: medication.medication_video.data.player_id,
                }}
              />
            ) : (
              <Image
                source={noVideo}
                style={{
                  width: 240,
                  height: 200,
                }}
                resizeMode={'contain'}
              />
            )}
          </Form.Column>
        </Form.Row>
      )}
      {isIntegrated && (
        <Form.Row>
          <Form.Column>
            {medication && (
              <ListMenu>
                {medication.patient_educationsheet ? (
                  <ListItemLink onPress={handleOpenEducationDetails}>
                    <Text style={styles.boldText}>
                      {getText('patient-education-sheets')}
                    </Text>
                    <Text style={styles.guidesText}>
                      {getText('side-effects-interactions')}
                    </Text>
                  </ListItemLink>
                ) : (
                  <View>
                    <Text style={styles.boldText}>
                      {getText('patient-education-sheets')}
                    </Text>
                    <View style={styles.iconSpacing}>
                      <AlertCircleIcon size={20}></AlertCircleIcon>
                      <Text style={styles.guidesText}>
                        {getText('no-medication-guide')}
                      </Text>
                    </View>
                  </View>
                )}
                {medication.medication_guides ? (
                  <ListItemLink onPress={handleOpenMedDetails}>
                    <Text style={styles.boldText}>
                      {getText('medication-guides')}
                    </Text>
                    <Text style={styles.guidesText}>
                      {getText('how-to-take')}
                    </Text>
                  </ListItemLink>
                ) : (
                  <View style={{ marginTop: theme.getSpacing(2) }}>
                    <Text style={styles.boldText}>
                      {getText('medication-guides')}
                    </Text>
                    <View style={styles.iconSpacing}>
                      <AlertCircleIcon size={20}></AlertCircleIcon>
                      <Text style={styles.guidesText}>
                        {getText('no-medication-guide')}
                      </Text>
                    </View>
                  </View>
                )}
              </ListMenu>
            )}
          </Form.Column>
        </Form.Row>
      )}
      {writtenDate && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>
              {getText('prescription-written')}
            </Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>{writtenDate}</Text>
          </Form.Column>
        </Form.Row>
      )}
      {prescription.doctor_first_name && (
        <Form.Row>
          <Form.Column>
            <Text style={styles.labelStyle}>{getText('prescription-by')}</Text>
          </Form.Column>
          <Form.Column>
            <Text style={styles.rightGreyText}>
              {prescription.doctor_first_name} {prescription.doctor_last_name}
            </Text>
          </Form.Column>
        </Form.Row>
      )}
      {prescription.doctor_phone && (
        <Form.Row>
          <Form.Column>
            <PhoneNumber
              numberLabel={getText('call-prescriber')}
              phoneNumber={prescription.doctor_phone}
              mode="button"
            />
          </Form.Column>
        </Form.Row>
      )}
      <GenericModal
        title={getText('medication-guides')}
        ref={sheetRef}
        isScrollable
        webModalProps={{
          height: height,
          scrollViewStyle: { flex: 1 },
          contentContainerStyle: { flex: 1 },
        }}
        showDismissButton={!isWeb}
        nativeModalProps={{ contentContainerStyle: { flex: 1 } }}
        nativeModalBodyStyle={{ flex: 1 }}
        buttons={
          isWeb
            ? [
                {
                  onPress: () => sheetRef.current?.hide(),
                  logger: { id: 'med-details-dismiss-button-modal' },
                  text: getText('close'),
                  hierarchy: 'primary',
                },
              ]
            : []
        }
      >
        <MedicationGuideDetails medication={medication} />
      </GenericModal>
      <GenericModal
        title={
          medication?.patient_educationsheet &&
          medication?.patient_educationsheet.title
            ? medication?.patient_educationsheet.title
            : getText('patient-education-sheets')
        }
        ref={educationSheetRef}
        isScrollable
        showDismissButton={!isWeb}
        buttons={
          isWeb
            ? [
                {
                  onPress: () => educationSheetRef.current?.hide(),
                  logger: { id: 'med-details-dismiss-button-modal' },
                  text: getText('close'),
                  hierarchy: 'primary',
                },
              ]
            : []
        }
      >
        <EducationInfoSheet medication={medication} />
      </GenericModal>
    </View>
  );
};

export type MedicationDetailsProps = {
  prescription: PrescriptionDto;
  onChange: (prescription: PrescriptionDto) => void;
  newMessageRef: React.RefObject<NewMessageHandler>;
  onRefillButtonPress?: (prescription: PrescriptionDto) => void;
};

export type VideoDetailProp = {
  account_id: string;
  video_id: string;
  player_id: string;
};

const useStyles = makeStyles((theme) => ({
  listStyle: {
    margin: 0,
    marginRight: -(theme.getSpacing(1) + theme.getSpacing(0.5)),
    marginLeft: -theme.getSpacing(1),
  },
  titleStyle: {
    marginEnd: -theme.getSpacing(1),
    paddingLeft: 0,
  },
  labelStyle: {
    ...theme.lumistryFonts.text.small.bold,
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    ...theme.lumistryFonts.text.large.semiBold,
  },
  rightGreyText: {
    textAlign: 'right',
    color: theme.palette.gray[700],
  },
  defaultText: {
    lineHeight: 20,
    fontSize: 16,
  },
  guidesText: {
    color: theme.palette.gray[600],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(0.5),
  },
  boldText: {
    color: theme.palette.gray[900],
    fontSize: 16,
  },
  iconSpacing: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginTop: theme.getSpacing(0.5),
  },
  video: {
    aspectRatio: 16 / 9,
  },
  button: {
    alignSelf: 'flex-start',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(2),
  },
  educationSheetTitle: {
    fontSize: 16,
    fontWeight: '600',
    textAlign: 'center',
  },
  educationSheetTitleMobile: {
    fontFamily: 'Lato_700Bold',
    fontWeight: '700',
    fontSize: 18,
    textAlign: 'center',
  },
}));
