import React, { FunctionComponent, ReactNode } from 'react';
import {
  Calendar as RNCalendar,
  CalendarProps as RNCalendarProps,
} from 'react-native-calendars';
import {
  Direction,
  MarkedDates,
  Theme,
} from 'react-native-calendars/src/types';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons';
import { useTheme } from 'assets/theme';
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking';

export const Calendar: FunctionComponent<CalendarProps> = (props) => {
  const theme = useTheme();
  const markedDatesWithStyling: MarkedDates = {};

  const calendarTheme: ExtendedTheme = {
    textDayFontFamily: theme.fonts.regular.fontFamily,
    textMonthFontFamily: theme.fonts.regular.fontFamily,
    textDayHeaderFontFamily: theme.fonts.regular.fontFamily,
    textDayFontWeight: theme.fonts.regular.fontWeight,
    textMonthFontWeight: theme.fonts.regular.fontWeight,
    textDayHeaderFontWeight: theme.fonts.regular.fontWeight,
    textDayStyle: theme.fonts.regular,
    dayTextColor: theme.palette.gray[900],
    todayDotColor: theme.palette.gray[900],
    todayTextColor: theme.palette.gray[900],
    monthTextColor: theme.palette.gray[700],
    dotColor: theme.palette.gray[900],
    selectedDotColor: theme.palette.white,
    'stylesheet.day.basic': {
      base: {
        width: 44,
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  };

  const getMarkedDateBackgroundColor = (markedDate?: MarkingProps) => {
    if (markedDate?.marked && markedDate?.disabled) {
      return undefined;
    }

    return markedDate?.selected && !markedDate?.marked
      ? theme.colors.brandedPrimary
      : theme.colors.brandedPrimaryDisabled;
  };

  if (props.markedDates) {
    Object.keys(props.markedDates).map((date) => {
      const markedDate = props.markedDates && props.markedDates[date];

      markedDatesWithStyling[date] = {
        ...markedDate,
        customStyles: {
          container: {
            borderRadius: theme.roundness,
            backgroundColor: getMarkedDateBackgroundColor(markedDate),
          },
        },
      };
    });
  }

  const renderArrow = (direction: Direction): ReactNode => {
    return direction === 'left' ? (
      <ChevronLeftIcon size={24} color={theme.palette.gray[700]} />
    ) : (
      <ChevronRightIcon size={24} color={theme.palette.gray[700]} />
    );
  };

  return (
    <RNCalendar
      theme={calendarTheme}
      renderArrow={renderArrow}
      {...props}
      markedDates={markedDatesWithStyling}
    />
  );
};

// There is a typing issue in the calendar library, it throws type errors
// when trying to apply advanced styling (like "stylesheet.day.basic").
// This is a workaround for that. More info - https://github.com/wix/react-native-calendars/issues/1864
type ExtendedTheme = Theme & {
  [key: string]: any;
};

export type CalendarProps = RNCalendarProps;
