import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import { GestureResponderEvent, Platform } from 'react-native';
import { NativeHeader } from 'assets/components/native-header';
import type { StackNavigationProp } from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import { RefillMedications } from './RefillMedications';
import { RefillReview } from './RefillReview';
import { RefillConfirmation } from './RefillConfirmation';
import { RefillMedicalInfos } from './RefillMedicalInfos';
import { RefillOrderDetails } from './RefillOrderDetails';
import { CloseIcon } from 'assets/icons';
import { ChoosePrescriptionMethod } from './components/ChoosePrescriptionMethod';
import { ChooseInsuranceType } from './components/ChooseInsuranceType';
import { OtherInformationModal } from './components/OtherInfromationModal';
import { useRefillMedicationsStore } from './refill-store';
import { RefillDetails } from './RefillDetails';
import { EditPatientCard } from '../account/patient/EditPatientCard';
import { CardType } from '@digitalpharmacist/patient-service-client-axios';
import { CheckSecondInsurance } from './CheckSecondInsurance';
import { InsuranceAndIDCards } from '../account/patient/InsuranceAndIDCards';
import { usePatientIntakeState } from '../account/patient/patient-store';

const Stack = createStackNavigator<RefillStackParamList>();

export const RefillNavigation: FunctionComponent<
  PropsWithChildren<RefillNavigationProps>
> = ({}) => {
  const { showRefillMedications, isFirstTimeRegister } =
    useRefillMedicationsStore();
  const { data, updateData } = usePatientIntakeState();
  const { updateMedicationsData } = useRefillMedicationsStore();
  const headerShown = Platform.OS !== 'web';

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown,
        headerMode: 'float',
        header: RefillNavigationHeader,
      }}
    >
      <Stack.Group>
        {showRefillMedications && (
          <Stack.Screen
            name="refill-medications"
            component={RefillMedications}
            options={{ title: getText('refill-medications') }}
          />
        )}
        {isFirstTimeRegister && (
          <>
            <Stack.Screen
              name="refill-medical-infos"
              component={RefillMedicalInfos}
              options={{ title: getText('refill-medical-infos') }}
            />
            <Stack.Screen
              name="refill-details"
              component={RefillDetails}
              options={{ title: getText('refill-details') }}
            />
            <Stack.Screen
              name="refill-choose-prescription-method"
              component={ChoosePrescriptionMethod}
              options={{ title: getText('refill-prescription-method') }}
            />
          </>
        )}
        <Stack.Screen
          name="edit-patient-card-refill"
          component={EditPatientCard}
          options={{
            title: getText('patient-insurance'),
            headerShown,
            header: (props) => (
              <NativeHeader
                onBack={() => {
                  if (!updateData) return;
                  props.navigation.goBack();
                  updateData({
                    isRefill: true,
                    data: data,
                    status: 'success',
                  });
                }}
                showAction={false}
                onActionPress={() => {
                  if (!updateData || !updateMedicationsData) return;
                  updateData({
                    isRefill: false,
                    data: data,
                    status: 'success',
                  });
                  updateMedicationsData({
                    selectedPatientRecord: undefined,
                  });
                }}
                showBack={true}
                title={getText('insurance-and-id-cards')}
              />
            ),
          }}
          initialParams={{
            cardType: CardType.InsurancePrimary,
            nextNavigation: 'check-second-insurance',
          }}
        />
        <Stack.Screen
          name="check-second-insurance"
          component={CheckSecondInsurance}
          options={{ title: getText('patient-insurance') }}
        />
        <Stack.Screen
          name="change-insurance"
          component={InsuranceAndIDCards}
          options={{
            title: getText('insurance-and-id-cards'),
            headerShown,
            header: (props) => (
              <NativeHeader
                onBack={() => {
                  if (!updateData) return;
                  updateData({
                    isRefill: false,
                    data: data,
                    status: 'success',
                  });
                  props.navigation.goBack();
                }}
                showAction={false}
                onActionPress={() => {
                  if (!updateData || !updateMedicationsData) return;
                  updateData({
                    isRefill: false,
                    data: data,
                    status: 'success',
                  });
                  updateMedicationsData({
                    selectedPatientRecord: undefined,
                  });
                }}
                showBack={true}
                title={getText('insurance-and-id-cards')}
              />
            ),
          }}
        />
        <Stack.Screen
          name="refill-review"
          component={RefillReview}
          options={{
            title: getText('add-order'),
            headerShown,
            header: (props) => (
              <NativeHeader
                title={getText('medications-fill-your-prescription')}
                actionLabel={getText('cancel')}
                actionIcon={CloseIcon}
                onActionPress={() => props.navigation.navigate('app')}
                showAction={true}
                showBack={false}
              />
            ),
          }}
        />
        <Stack.Screen
          name="refill-other-information"
          component={OtherInformationModal}
          options={{ title: getText('add-order') }}
        />
        <Stack.Screen
          name="refill-prescription-method"
          component={ChoosePrescriptionMethod}
          options={{ title: getText('add-order') }}
        />
        <Stack.Screen
          name="refill-insurance-method"
          component={ChooseInsuranceType}
          options={{ title: getText('add-order') }}
        />
        <Stack.Screen
          name="refill-confirmation"
          component={RefillConfirmation}
          options={{
            title: getText('add-order'),
          }}
        />
        <Stack.Screen
          name="refill-order-details"
          component={RefillOrderDetails}
          options={{ title: getText('add-order') }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface RefillNavigationProps {}

export type RefillStackParamList = {
  'refill-medical-infos': undefined;
  'refill-order-details': undefined;
  'refill-details': undefined;
  'refill-review': undefined;
  'refill-confirmation': undefined;
  'refill-medications': undefined;
  'refill-prescription-method': undefined;
  'refill-choose-prescription-method': undefined;
  'refill-insurance-method': undefined;
  'refill-other-information': undefined;
  'edit-patient-card-refill': {
    cardType: CardType;
    nextNavigation?: keyof RefillStackParamList;
  };
  'check-second-insurance': undefined;
  'change-insurance': undefined;
};

export type RefillStackNavigationProp =
  StackNavigationProp<RefillStackParamList>;

const RefillNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const routeName = props.route.name as keyof RefillStackParamList;

  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  const handleActionPress = (event: GestureResponderEvent) => {
    props.navigation.navigate('app');
  };

  const showActions = routeName !== 'refill-confirmation';

  return (
    <NativeHeader
      title={getText('medications-fill-your-prescription')}
      actionLabel={getText('cancel')}
      actionIcon={CloseIcon}
      onActionPress={handleActionPress}
      onBack={handleBack}
      showAction={showActions}
      showBack={showActions}
    />
  );
};
