import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { Platform, View } from 'react-native';
import { Button } from 'assets/components/button';
import { UseFormReturn } from 'react-hook-form';
import { PatientUnderCareForm } from '../../patient-actions';
import { usePatientRecordState } from '../../patient-store';
import {
  PatientRecordDto,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { FunctionComponent, PropsWithChildren } from 'react';
import { MedicalInfoFormFields } from '../../../../../components/medical-info/MedicalInfoFormFields';

export const EditMedicalInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    handleSave: (updatePatientRecord: UpdatePatientRecordDto) => void;
    record?: RecordUnderCareDto;
    form: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ patientRecord, changeViewMode, handleSave, record, form }) => {
  const { status, error } = usePatientRecordState();

  return (
    <View>
      <Form methods={form}>
        <Form.Alert
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
          visible={!!form.getFieldState('allergies').error}
        />
        <Form.Row>
          <Form.Column>
            <MedicalInfoFormFields />
          </Form.Column>
        </Form.Row>
      </Form>
    </View>
  );
};
