import { Alert } from 'assets/components/alert';
import { getText } from 'assets/localization/localization';
import React, {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Platform } from 'react-native';
import { StoreSelectorBottomSheet } from './StoreSelectorBottomSheet';
import { StoreInformation } from './StoreInformation';
import {
  StoreSelectorHandler,
  StoreSelectorItemHandler,
  StoreSelectorProps,
} from './types';
import { StoreSelectorModal } from './StoreSelectorModal';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Distance } from '../../types';
import { StoreSelectorInformationModal } from './StoreSelectorInformationModal';
import { StoreSelectorInformationBottomSheet } from './StoreSelectorInformationBottomSheet';
import { currentDayOpenHours, getDayAsStr } from './utils';
import { handleLocationDistanceSearch } from './actions';

const StoreSelectorComponent: ForwardRefRenderFunction<
  StoreSelectorHandler,
  StoreSelectorProps
> = (
  {
    platform = Platform.OS,
    mapProps,
    options = [],
    selectedOption: defaultOption,
    storeInformationShown = true,
    changeButtonShown = true,
    onInfoButtonPress,
    ...rest
  }: StoreSelectorProps,
  ref: ForwardedRef<StoreSelectorHandler>,
) => {
  const storeSelectorRef = React.useRef<StoreSelectorHandler>(null);
  const storeSelectorInformationRef =
    React.useRef<StoreSelectorItemHandler>(null);

  const handleShow = () => {
    storeSelectorRef.current?.show();
  };

  const handleHide = () => {
    storeSelectorRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleOnChangePress = () => {
    handleShow();
  };

  if (options.length === 0) {
    return (
      <Alert intent="error" title={getText('store-selector-no-stores-error')} />
    );
  }

  const handleInfoButtonOnPress = (item: Distance<PharmacyLocationDto>) => {
    storeSelectorInformationRef.current?.show(item as PharmacyLocationDto);
    onInfoButtonPress?.(item);
  };

  return (
    <>
      {storeInformationShown && (
        <StoreInformation
          onChangePress={handleOnChangePress}
          // TODO: discuss with team, it should not be possible to not have a default option
          // (the preselected store, it is chosen from the onboarding flow)
          // fallback on first option if it is not supplied correctly because there is no ui
          // for the look and feel of "no default" option state
          item={defaultOption ?? options[0]}
          mapProps={mapProps}
          showChange={options.length > 1 && changeButtonShown}
        />
      )}
      <>
        <StoreSelectorModal
          {...rest}
          ref={storeSelectorRef}
          options={options}
          selectedOption={defaultOption}
          searchAction={handleLocationDistanceSearch}
          onInfoButtonPress={handleInfoButtonOnPress}
        />
        <StoreSelectorInformationModal
          ref={storeSelectorInformationRef}
          currentDayOpenHours={currentDayOpenHours}
          daysAsStr={getDayAsStr}
        />
      </>
    </>
  );
};

export const StoreSelector = forwardRef<
  StoreSelectorHandler,
  StoreSelectorProps
>(StoreSelectorComponent);
