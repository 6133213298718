import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { PHARMACY_API_BASE_URL } from '../common/constants';

import {
  BrandingDto,
  DefaultApi as PharmacyServiceClient,
  PharmacyDto,
  PharmacyPoliciesDto,
  DepartmentTimeRangeDto,
  PaginatedLocationResponseDto,
  PharmacyLocationDto,
  ServiceDto,
  PharmacyAppDto,
  InfoCardDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IPharmacyService {
  findAll(): Promise<PharmacyDto[]>;
  findLocationsByPharmacyId(
    search?: string,
    limit?: number,
    offset?: number,
    orderBy?: string,
    postalCode?: string,
    lng?: string,
    lat?: string,
    units?: string,
  ): Promise<PaginatedLocationResponseDto>;
  findPharmacyBranding(pharmacyId: string): Promise<BrandingDto>;
  findLocationHours(locationId: string): Promise<DepartmentTimeRangeDto[]>;
  findLocationServices(locationId: string): Promise<ServiceDto[]>;
  findLocationLanguage(locationId: string): Promise<string[]>;
  findPharmacyLocation(locationId: string): Promise<PharmacyLocationDto>;
  getAppConfig(pharmacyId: string): Promise<PharmacyAppDto>;
}

export class PharmacyService
  extends BaseApiService
  implements IPharmacyService
{
  private pharmacyServiceClient: PharmacyServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pharmacyServiceClient = new PharmacyServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async findAll(): Promise<PharmacyDto[]> {
    const { data } = await this.pharmacyServiceClient.pharmacyFindAll();
    return data;
  }

  /**
   *
   * @param pharmacyId can be `pharmacyId` or `slug`
   * @returns
   */
  async findOne(pharmacyId: string): Promise<PharmacyDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyFindOne(pharmacyId);
    return data;
  }

  async findLocationsByPharmacyId(
    search?: string,
    limit?: number,
    offset?: number,
    units?: string,
    lng?: string,
    lat?: string,
    postalCode?: string,
    orderBy?: string,
  ): Promise<PaginatedLocationResponseDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFind(
      search,
      limit,
      offset,
      units,
      lng,
      lat,
      postalCode,
      orderBy,
    );
    return data;
  }

  async findPharmacyBranding(pharmacyId: string): Promise<BrandingDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyBrandingFindOne(pharmacyId);
    return data;
  }

  async findLocationHours(
    locationId: string,
  ): Promise<DepartmentTimeRangeDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursFindAll(locationId);
    return data;
  }

  async findLocationServices(locationId: string): Promise<ServiceDto[]> {
    const { data } =
      await this.pharmacyServiceClient.serviceOptionGetServiceOptions(
        locationId,
      );
    return data;
  }

  async findLocationLanguage(locationId: string): Promise<string[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationLanguageGetLanguages(
        locationId,
      );
    return data;
  }

  async findPoliciesForPharmacy(
    pharmacyId: string,
  ): Promise<PharmacyPoliciesDto> {
    const { data } =
      await this.pharmacyServiceClient.policyFindForPharmacy(pharmacyId);
    return data;
  }

  async findPharmacyLocation(locationId: string): Promise<PharmacyLocationDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationFindOne(locationId);
    return data;
  }

  async findLocationPickupMethods(locationId: string) {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationPickupMethodGetPickupMethods(
        locationId,
      );
    return data;
  }

  async getAppConfig(pharmacyId: string): Promise<PharmacyAppDto> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyAppFind(pharmacyId);
    return data;
  }

  async getAllInfoCards(locationId: string): Promise<InfoCardDto[]> {
    const { data } =
      await this.pharmacyServiceClient.infoCardsFindAll(locationId);
    return data;
  }
}

export default new PharmacyService(
  PHARMACY_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
