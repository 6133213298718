import React, { FunctionComponent, useEffect, useState } from 'react';
import { Image, Linking, Platform, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getText } from '../../localization/localization';
import { useTheme } from '../../theme';
import { Alert } from '../alert';
import { useMapContext } from './hooks';
import { UpdateAddressDto } from '@digitalpharmacist/patient-service-client-axios';
export const Map: FunctionComponent<MapProps> = ({
  longitude,
  latitude,
  height = 150,
  width = 150,
  useWideGenericImage = false,
  address,
  accessToken,
  hasBorder = false,
  zoom = 15,
  missingCoordinatesErrorMessage,
}) => {
  const theme = useTheme();
  const mapContext = useMapContext();
  const integralWidth = Math.trunc(width);
  const integralHeight = Math.trunc(height);

  const [genericImageSource, setGenericImageSource] = useState(
    require('../../../../apps/mobile/assets/map-square.png'),
  );

  useEffect(() => {
    if (useWideGenericImage) {
      setGenericImageSource(
        require('../../../../apps/mobile/assets/map-wide.png'),
      );
    }
  }, []);

  const render = () => {
    const { accessToken: _contextAccessToken } = mapContext;

    if (!accessToken && !_contextAccessToken)
      return <Alert intent="error" title="Missing access token" />;

    if (!longitude || !latitude)
      <Alert
        intent="warning"
        title={missingCoordinatesErrorMessage ?? 'Missing coordinates'}
      />;

    return (
      <View
        style={{
          height: integralHeight,
          alignSelf: 'center',
          flex: 1,
          width: '100%',
        }}
      >
        <TouchableOpacity
          testID={MapTestIDs.map}
          onPress={() => openMap(longitude, latitude, address)}
          accessibilityRole="button"
          accessibilityLabel={getText('open-map')}
        >
          <Image
            resizeMode="cover"
            style={[
              {
                height: useWideGenericImage ? 50 : integralHeight,
                width: useWideGenericImage ? '100%' : integralWidth,
                borderRadius: theme.roundness,
              },
              hasBorder
                ? {
                    borderWidth: 5,
                    borderRadius: theme.getSpacing(1),
                    borderColor: theme.palette.white,
                  }
                : {
                    borderWidth: 1,
                    borderRadius: theme.getSpacing(1),
                    borderColor: theme.palette.gray[200],
                  },
            ]}
            source={
              !longitude && !latitude
                ? genericImageSource
                : {
                    uri:
                      'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+cc3333(' +
                      longitude +
                      ',' +
                      latitude +
                      ')/' +
                      longitude +
                      ',' +
                      latitude +
                      ',' +
                      zoom +
                      ',0/' +
                      integralHeight +
                      'x' +
                      integralWidth +
                      '@2x?access_token=' +
                      (accessToken ?? _contextAccessToken),
                  }
            }
          />
        </TouchableOpacity>
      </View>
    );
  };

  return render();
};

const openMap = (
  longitude?: number,
  latitude?: number,
  address?: UpdateAddressDto,
) => {
  // Get the values of the address object
  const addressValues = address ? Object.values(address) : [];

  // Get the keys of the address object
  const addressKeys = address ? Object.keys(address) : [];

  // Filter out the value of the 'id' field
  const filteredValues = addressValues.filter(
    (value, index) => addressKeys[index] !== 'id',
  );

  const stringifyAddress = filteredValues.join(', ');

  const scheme = Platform.select({
    ios: 'maps:0,0?q=',
    android: 'geo:0,0?q=',
  });
  const url = Platform.select({
    ios:
      longitude && latitude
        ? scheme + encodeURIComponent(latitude + ',' + longitude)
        : scheme + encodeURIComponent(stringifyAddress),
    android:
      longitude && latitude
        ? scheme + encodeURIComponent(latitude + ',' + longitude)
        : scheme + encodeURIComponent(stringifyAddress),
    default:
      longitude && latitude
        ? 'https://www.google.com/maps/search/?api=1&query=' +
          encodeURIComponent(latitude + ',' + longitude)
        : 'https://www.google.com/maps/search/?api=1&query=' +
          encodeURIComponent(stringifyAddress),
  });
  Linking.openURL(url);
};

export interface MapProps {
  longitude?: number;
  latitude?: number;
  address?: UpdateAddressDto;
  useWideGenericImage?: boolean;
  height?: number;
  width?: number;
  accessToken?: string;
  hasBorder?: boolean;
  zoom?: number;
  missingCoordinatesErrorMessage?: string;
}

export const MapTestIDs = {
  map: 'map',
};
