import React, { FunctionComponent, PropsWithChildren } from 'react';
import { DimensionValue, ImageBackground } from 'react-native';
import theme, { useTheme } from 'assets/theme';
import { StyleSheet } from 'react-native';
import { Surface } from 'react-native-paper';
import { TouchableOpacity } from 'react-native-gesture-handler';

export interface PhotoInfo {
  uri?: string;
  width?: string | number;
  height?: DimensionValue;
  assets?: Array<any>;
  disable?: boolean;
}

export const PhotoButton: FunctionComponent<
  PropsWithChildren<
    PhotoInfo & {
      onPress?: () => void;
    }
  >
> = ({
  uri,
  width = 100,
  height = 100,
  onPress,
  children,
  disable = false,
}) => {
  const theme = useTheme();
  const innerContent = uri ? (
    <ImageBackground
      source={{ uri: uri }}
      resizeMode="cover"
      style={{ height: height }}
    />
  ) : (
    children
  );

  return (
    <Surface
      style={[
        stylesButton.button,
        {
          elevation: 0,
          borderRadius: theme.roundness,
          height: height,
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundColor: theme.palette.gray[100],
          width: width,
          minHeight: height,
        },
      ]}
    >
      {!disable ? (
        <TouchableOpacity
          style={{
            justifyContent: 'center',
            height: height,
          }}
          onPress={onPress}
        >
          {innerContent}
        </TouchableOpacity>
      ) : (
        innerContent
      )}
    </Surface>
  );
};

const stylesButton = StyleSheet.create({
  button: {
    minWidth: 64,
    borderStyle: 'solid',
  },
  closeModalButton: {
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.error[600],
    borderRadius: 5,
    margin: theme.getSpacing(1),
  },
  closeButtonContainer: {
    width: 70,
  },
  label: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    textAlign: 'left',
  },
  imageOptions: {
    marginBottom: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  replaceText: {
    color: theme.colors.primary,
    fontSize: 14,
    fontWeight: '500',
    marginRight: theme.getSpacing(1.5),
  },
  errorLabel: {
    color: theme.palette.error[600],
    fontSize: 14,
    fontWeight: '400',
  },
});
