import { NotificationNavigationScreen } from '@digitalpharmacist/unified-communications-service-client-axios';
import { openFindingDigitalContent, refreshNotifications } from './app-actions';
import unifiedCommsService from '../api/unified-comms-service';
import { useAppNavigation } from '../providers/app-navigation';
import { useAppStateStore } from '../store/app-store';
import { useUserState } from '../store/user-store';
import { logError } from 'assets/logging/logger';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import { medicationNotification } from '../screens/medications/medication-actions';
import { useRefillMedicationsStore } from '../screens/refill/refill-store';

export const handleMarkAsRead = async (notificationId: string) => {
  const { user } = useUserState.getState();
  if (!user) {
    logError(new Error('Failed to mark notification as read, user not found.'));
    return;
  }
  try {
    await unifiedCommsService.updateUserNotification(user.id, notificationId, {
      is_read: true,
    });
  } catch (e) {
    logError(new Error('Failed to mark notification as read', e as Error));
  }
};

export const handleMarkAsUnread = async (notificationId: string) => {
  const { user } = useUserState.getState();
  if (!user) {
    logError(
      new Error('Failed to mark notification as unread, user not found.'),
    );
    return;
  }
  try {
    await unifiedCommsService.updateUserNotification(user.id, notificationId, {
      is_read: false,
    });
  } catch (e) {
    logError(new Error('Failed to mark notification as unread', e as Error));
  }
};

export const handleMarkAsHidden = async (notificationId: string) => {
  const { user } = useUserState.getState();
  if (!user) {
    logError(
      new Error('Failed to mark notification as hidden, user not found.'),
    );
    return;
  }
  try {
    await unifiedCommsService.updateUserNotification(user.id, notificationId, {
      is_hidden: true,
    });
  } catch (e) {
    logError(new Error('Failed to mark notification as hidden', e as Error));
  }
};

export const handleMarkAllAsRead = async () => {
  const { user } = useUserState.getState();
  useAppStateStore.setState({ status: 'loading' });

  if (!user) {
    logError(
      new Error('Failed to mark all notifications as read, user not found.'),
    );
    return;
  }
  try {
    await unifiedCommsService.markAllAsRead(user.id);
    await refreshNotifications();
    useAppStateStore.setState({ status: 'success' });
  } catch (e) {
    useAppStateStore.setState({ status: 'error' });
    logError(new Error('Failed to mark all notifications as read', e as Error));
  }
};

export const handleNavigationScreen = async (
  navigationScreen: NotificationNavigationScreen,
  navigationParams?: Record<string, string>,
) => {
  const navigation = useAppNavigation<StackNavigationProp<ParamListBase>>();

  const navScreenMap: Record<
    NotificationNavigationScreen,
    () => Promise<void>
  > = {
    'medical-info': () => {
      navigation?.navigate('app', {
        screen: 'account',
        params: { screen: 'medical-info' },
      });
      return Promise.resolve();
    },
    'people-under-care/linked-accounts/puc-tab': () => {
      navigation?.navigate('people-under-care', {
        screen: 'linked-accounts',
        params: { screen: 'puc-tab' },
      });
      // NOTE: temporary solutions since it is registered on root of the application
      // TODO: review with Curtis team
      // for the moment it does not exist any puc under account
      // it exist only on the root
      return Promise.resolve();
    },
    'people-under-care/linked-account/caregivers-tab': () => {
      navigation?.navigate('people-under-care', {
        screen: 'linked-accounts',
        params: { screen: 'caregivers-tab' },
      });
      return Promise.resolve();
    },
    account: () => {
      navigation?.navigate(navigationScreen);
      return Promise.resolve();
    },
    medications: async () => {
      if (navigationParams) {
        await medicationNotification(navigationParams);
      }
      useRefillMedicationsStore.setState({
        notificationRefreshTrigger: Date.now(),
      });
      navigation?.navigate(navigationScreen);
    },
    'finding-digital-content': async () => {
      openFindingDigitalContent();
      return Promise.resolve();
    },
    messages: () => {
      navigation?.navigate(navigationScreen);
      return Promise.resolve();
    },
    None: () => {
      return Promise.resolve();
    },
  };

  await navScreenMap[navigationScreen]();
};
