import theme from 'assets/theme';
import { getDayAsStr, getHoursAsStr } from './utils';
import { View } from 'react-native';
import { NormalTimeRangeDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';

const today = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
][new Date().getDay()];

export const renderHours = (departmentHours: NormalTimeRangeDto[]) => {
  const styles = useStyles();
  const openHoursMap: { [key: string]: NormalTimeRangeDto[] } = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };
  departmentHours.map((hours) => {
    const day = getDayAsStr(hours);
    openHoursMap[day].push(hours);
  });

  return Object.entries(openHoursMap).map(([day, timeRanges]) => {
    return (
      <View style={styles.hoursStyle} key={day}>
        <Text
          style={[
            styles.textColor,
            {
              fontFamily:
                today === day
                  ? theme.fonts.medium.fontFamily
                  : theme.fonts.regular.fontFamily,
            },
          ]}
        >
          {day}
        </Text>
        <View style={{ justifyContent: 'flex-end' }}>
          {timeRanges.length === 0 && (
            <Text
              style={[
                styles.textColor,
                { fontFamily: theme.fonts.regular.fontFamily },
              ]}
            >
              {getText('closed')}
            </Text>
          )}
          {timeRanges.map((timeRange: NormalTimeRangeDto) => {
            return (
              <View key={timeRange.id} style={styles.timeRanges}>
                <Text
                  style={[
                    styles.textColor,
                    {
                      fontFamily:
                        today === getDayAsStr(timeRange)
                          ? theme.fonts.medium.fontFamily
                          : theme.fonts.regular.fontFamily,
                    },
                  ]}
                >
                  {getHoursAsStr(timeRange)}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    );
  });
};

const useStyles = makeStyles((theme) => ({
  hoursStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.getSpacing(1),
  },
  hoursTitle: {
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
  },
  textColor: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
  },
  timeRanges: {
    alignItems: 'flex-end',
  },
}));

export default renderHours;
