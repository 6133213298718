import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { useVerificationState } from '../../../../verify/verification-store';
import { UseFormReturn } from 'react-hook-form';
import { PhoneEmailVerification } from '../../../../verify/verification-actions';
import {
  VerifiedProperty,
  VerifiedPropertyConfig,
} from '../../../../verify/EditVerifiedProperty';
import { makeStyles, useTheme } from 'assets/theme';
import { formatPhone } from '../../../../../utils';

export const EditContactInfo: FunctionComponent<
  PropsWithChildren<{
    handleSubmit: () => void;
    verifiedProperty: VerifiedProperty;
    form: UseFormReturn<PhoneEmailVerification>;
  }>
> = ({ handleSubmit, verifiedProperty, form }) => {
  const { error } = useVerificationState();
  const {
    label,
    subtitle,
    requiredMessage,
    validationMessage,
    inputType,
    validation,
    extraTitle,
  } = VerifiedPropertyConfig[verifiedProperty];

  const theme = useTheme();
  const styles = useStyles();

  const handleFormatting = (input: string) => {
    if (verifiedProperty === VerifiedProperty.Phone) {
      const formatInput = formatPhone(input);
      form.setValue('value', formatInput);
      return;
    }
    form.setValue('value', input);
  };

  return (
    <View style={{ marginTop: theme.getSpacing(1) }}>
      <Form methods={form}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <Text style={{ marginBottom: theme.getSpacing(3) }}>
              {subtitle}
            </Text>
            <TextField
              label={label}
              name="value"
              rules={{
                required: requiredMessage,
                validate: {
                  value: (value: string) => {
                    return validation(value) ? true : validationMessage;
                  },
                },
              }}
              onChange={(value: string) => {
                handleFormatting(value);
              }}
              type={inputType}
            />
          </Form.Column>
        </Form.Row>
        <View style={{ marginTop: theme.getSpacing(3) }}>
          <Text style={styles.bottomText}>{extraTitle}</Text>
        </View>
      </Form>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomText: {
    ...theme.fonts.regular,
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
    color: theme.palette.gray[600],
    marginTop: theme.getSpacing(4),
    textAlign: 'center',
  },
}));
