import UnifiedCommsService from '../../api/unified-comms-service';
import { embeddingControlHeaders } from './constants';
import { logError } from 'assets/logging/logger';

export function findHeaderThatDisallowsEmbedding(
  headers: Record<string, string>,
) {
  for (const header of embeddingControlHeaders) {
    if (
      header.name in headers &&
      new RegExp(header.directiveDisallowingEmbedding, 'i').test(
        headers[header.name],
      )
    ) {
      return headers[header.name];
    }
  }
  return null;
}

export const getOriginalURL = async (shortUrl: string) => {
  try {
    const { originalURL } =
      await UnifiedCommsService.shortioGetLinkInfo(shortUrl);
    return originalURL;
  } catch (error) {
    logError(new Error('Failed to get original URL:', error as Error));
  }
};

export async function markAsRead(
  locationId: string,
  locationPatientId: string,
  conversationId: string,
  pharmacyViewedAllMessages: boolean,
) {
  return await UnifiedCommsService.updateUserViewedStatus(
    locationId,
    locationPatientId,
    conversationId,
    {
      patient_viewed_all_messages: true,
      pharmacy_viewed_all_messages: pharmacyViewedAllMessages,
    },
  );
}
