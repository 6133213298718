import React, { FunctionComponent, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import WebView from 'react-native-webview';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { getFormContent } from './fill-in-form-actions';
import { useFillInFormStore } from './fill-in-form-store';
import { StorageKeys } from '../../../enums/storage-keys';
import { useAppStateStore } from '../../store/app-store';
import AsyncStorageService from '../../api/async-storage-service';

const FormIFrame: FunctionComponent<{ htmlSrc: string }> = ({ htmlSrc }) => {
  const theme = useTheme();

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        flex: 1,
      }}
    >
      <iframe
        style={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.gray[500],
          borderRadius: 8,
          outline: 'none',
          boxSizing: 'border-box',
          boxShadow: 'none',
        }}
        height="100%"
        width="100%"
        srcDoc={htmlSrc}
        id="jotformIframe"
      ></iframe>
    </View>
  );
};

const FormWebView: FunctionComponent<{
  htmlSrc: string;
  onFormSubmit?: (submissionId: string) => void;
}> = ({ htmlSrc, onFormSubmit }) => {
  const theme = useTheme();

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        paddingBottom: theme.getSpacing(1),
      }}
    >
      <WebView
        style={{
          outline: 'none',
          boxSizing: 'border-box',
          boxShadow: 'none',
        }}
        height="100%"
        width="100%"
        source={{ html: htmlSrc }}
        nestedScrollEnabled
        // Adding a "load" event listener to the WebView that checks
        // if the loaded page is a "Thank You" page. We don't have
        // any other way of detecting form submission in WebView, so
        // it looks for the title or scripts specific to the "Thank You" page.
        injectedJavaScriptBeforeContentLoaded={`
          window.addEventListener("load", function (event) {
            if (window.document.title === "Thank you" || document.documentElement.innerHTML.includes("thankYouPageScripts.js") || documentElement.innerHTML.includes("thankyou.css")) {
              window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'form-submitted', submissionId: window.submissionId }));
            }
          });
        `}
        onMessage={(event) => {
          const eventData = JSON.parse(event.nativeEvent.data);

          if (eventData.action === 'form-submitted') {
            onFormSubmit && onFormSubmit(eventData.submissionId);
          }
        }}
      ></WebView>
    </View>
  );
};

export const FillInFormContent: FunctionComponent<FillInFormContentProps> = ({
  formId,
  locationId,
  locationPatientRecordId,
  helperText,
  onFormSubmit,
}) => {
  const { status, formHtmlContent } = useFillInFormStore();
  const { pharmacyId } = useAppStateStore();
  const theme = useTheme();
  const styles = useStyles();
  const [extendedHtml, setExtendedHtml] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();

  // We're setting the cookies that get passed when submitting a form,
  // since we can't add the token and pharmacy ID to the request headers there.
  useEffect(() => {
    void (async () => {
      const accessToken = await AsyncStorageService.getItem(
        StorageKeys.AccessToken,
      );

      if (accessToken) {
        setAccessToken(accessToken);
      }
    })();
  }, []);

  useEffect(() => {
    getFormContent(formId, locationId, locationPatientRecordId);
  }, [formId]);

  // Inject a script for submitting a form.
  useEffect(() => {
    if (formHtmlContent && accessToken) {
      setExtendedHtml(formHtmlContent + getFormSubmitScript());
    }
  }, [formHtmlContent, accessToken]);

  // We need to override the default form submit action and submit the form with
  // AJAX instead, because we need to add authorization headers to the request.
  const getFormSubmitScript = () => {
    return `<script>
      const form = document.getElementById('${formId}');
      const url = form.getAttribute('action');

      // Add a "submit" event listener to the form.
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        const formFields = document.querySelectorAll('.form-line');
        const $form = $$('form')[0];

        // Using JotForm method for validating the form
        if (JotForm.validateAll($form)) {
          const data = new FormData(event.target);
          const xhr = new XMLHttpRequest();

          // Add authorization headers and make a request with form data.
          xhr.open("POST", url);
          xhr.setRequestHeader("Authorization", "Bearer ${accessToken}");
          xhr.setRequestHeader("X-Pharmacy-Id", "${pharmacyId}");
          xhr.send(data);

          // We get the "Thank You" page in response, so we're replacing the content with it.
          xhr.onload = (event) => {
            const response = event.target.response;
            document.open();
            document.write(response);
            document.close();
          };
        }
      });
    </script>`;
  };

  if (status !== 'success' || !extendedHtml) {
    return (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator></LoadingIndicator>
      </View>
    );
  }

  return (
    <>
      {helperText ? (
        <Text
          style={{
            color: theme.palette.gray[700],
            marginBottom: theme.getSpacing(1),
          }}
        >
          {helperText}
        </Text>
      ) : null}

      {Platform.OS === 'web' ? (
        <FormIFrame htmlSrc={extendedHtml} />
      ) : (
        <FormWebView htmlSrc={extendedHtml} onFormSubmit={onFormSubmit} />
      )}
    </>
  );
};

export interface FillInFormContentProps {
  formId: string;
  locationId: string;
  locationPatientRecordId: string;
  onFormSubmit: (submissionId: string) => void;
  helperText?: string;
}

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -50,
  },
}));
