import {
  PrescriptionDto,
  RefillRemoveBehavior,
  RxStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Button } from 'assets/components/button';
import { IconButton } from 'assets/components/icon-button';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text/Text';
import { CloseIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Platform, View } from 'react-native';
import { useAppStateStore } from '../../../store/app-store';
import { useRefillMedicationsStore } from '../refill-store';
import { UnableToSubmitDetails } from './UnableToSubmitDetails';
import {
  UnableToSubmitHandler,
  UnableToSubmitProps,
} from './UnableToSubmitProps';
import { UnableToSubmitTestID } from './UnableToSubmitTestID';
import { unselectAllMedications } from '../../medications/medication-actions';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

const UnableToSubmitComponent: ForwardRefRenderFunction<
  UnableToSubmitHandler,
  UnableToSubmitProps
> = (
  { platform = Platform.OS, handleUnableToSubmit }: UnableToSubmitProps,
  ref: ForwardedRef<UnableToSubmitHandler>,
) => {
  const theme = useTheme();
  const styles = useStyles();
  const locationPrescriptionConfig = useAppStateStore(
    (s) => s.locationPrescriptionConfig,
  );
  const {
    selectedMedications,
    unableToSubmitMedications,
    updateMedicationsData,
  } = useRefillMedicationsStore();
  const nativeRef = React.useRef<BaseModalHandler>(null);

  const handleShow = () => {
    nativeRef.current?.show();
  };

  const handleHide = () => {
    nativeRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const isRemovable = (item: PrescriptionDto) => {
    const rx_config = locationPrescriptionConfig.refill_configs.find(
      (config) => config.rx_status === item.rx_status,
    );

    if (!rx_config) return true;

    return rx_config.remove_behavior === RefillRemoveBehavior.AllowRemove;
  };

  const isFillable = (item: PrescriptionDto) => {
    return item.rx_status === RxStatus.Fillable;
  };

  const removeMedicationNotSupportedToRefill = () => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      selectedMedications: selectedMedications?.filter((med) =>
        isRemovable(med),
      ),
    });
  };

  const removeUnableToSubmitMedications = (item: PrescriptionDto) => {
    if (!updateMedicationsData) return;

    const newSelectedMedications = selectedMedications?.filter(
      (x) => x.rx_number !== item.rx_number,
    );

    const newUnableToSubmitMedications = unableToSubmitMedications?.filter(
      (med) => med.rx_number !== item.rx_number,
    );

    updateMedicationsData({
      selectedMedications: newSelectedMedications,
      unableToSubmitMedications: newUnableToSubmitMedications,
    });
  };

  const handleSave = () => {
    removeMedicationNotSupportedToRefill();
    handleUnableToSubmit('continue');
    handleHide();
  };

  const handleDismiss = () => {
    unselectAllMedications();
    handleHide();
  };

  const handleGotoMedications = () => {
    unselectAllMedications();
    handleHide();
  };

  const getAlertText = () => {
    if (processablePrescriptions.length === 1)
      return getText('no-item-left-in-order-cancel');

    if (processablePrescriptions.length === 0)
      return getText('no-item-left-in-order-cancelled');

    return '';
  };

  const fillablePrescriptions = selectedMedications?.filter(isFillable) || [];
  const processablePrescriptions =
    unableToSubmitMedications?.filter(isRemovable) || [];
  const removedPrescriptions =
    unableToSubmitMedications?.filter((med) => !isRemovable(med)) || [];
  const cannotContinueRefill =
    fillablePrescriptions.length === 0 && processablePrescriptions.length === 0;

  return (
    <>
      <GenericModal
        ref={nativeRef}
        webModalSize="sm"
        title={getText('notice')}
        showDismissButton
        buttons={[
          {
            text: cannotContinueRefill
              ? getText('return-to-medications')
              : getText('continue'),
            onPress: cannotContinueRefill ? handleGotoMedications : handleSave,
            testID: UnableToSubmitTestID.okModalButton,
            logger: { id: UnableToSubmitTestID.okModalButton },
            hierarchy: 'primary',
          },
        ]}
      >
        <UnableToSubmitDetails
          alertText={getAlertText()}
          processablePrescriptions={processablePrescriptions}
          removedPrescriptions={removedPrescriptions}
          isRemovable={isRemovable}
          onRemovePrescription={removeUnableToSubmitMedications}
        />
      </GenericModal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  title: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginLeft: theme.getSpacing(4),
  },
}));

export const UnableToSubmit = forwardRef<
  UnableToSubmitHandler,
  UnableToSubmitProps
>(UnableToSubmitComponent);
