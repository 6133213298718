import theme, { SpacingFactor } from '../theme';
// TODO: to be removed once implemented on pharmacy project
/**
 * Calculate spacing based on the theme's spacing and multiplying it by the provided factor.
 * Use for `padding` and `margin` styles.
 *
 * See MUI for inspiration: https://mui.com/system/spacing
 */
export const getSpacing = (factor: SpacingFactor) => {
  return theme.spacing * factor;
};
