import React, { FunctionComponent } from 'react';
import { Svg, Path, Rect, Circle } from 'react-native-svg';
import { IconProps } from './types';

export const MedicationsNotificationIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Circle cx={24} cy={24} r={24} fill="#EAF1F4" stroke="transparent" />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M32 14v4M34 16h-4"
      />
      <Path
        fill={color}
        d="M15 23a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM28 18a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM23 23.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      />
      <Circle cx={16.5} cy={28.5} r={2.5} stroke={color} strokeWidth={1.5} />
      <Rect
        width={4.5}
        height={9.5}
        x={14.686}
        y={20.224}
        stroke={color}
        strokeWidth={1.5}
        rx={2.25}
        transform="rotate(-101.186 14.686 20.224)"
      />
      <Circle cx={30} cy={27} r={5} stroke={color} strokeWidth={1.5} />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="m33.768 28.14-7.235-1.976"
      />
    </Svg>
  );
};
