import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';
import {
  StoreSelectorHandler,
  StoreSelectorListHandler,
  StoreSelectorProps,
} from './types';
import { Modal } from 'assets/components/modal';
import { StoreSelectorList } from './list';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { StoreSelectorInput } from './input/StoreSelectorInput';
import { useTheme } from 'assets/theme';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { getText } from 'assets/localization/localization';

export const StoreSelectorModal = forwardRef<
  StoreSelectorHandler,
  StoreSelectorProps
>(
  (
    {
      options = [],
      selectedOption,
      onChange = (value: PharmacyLocationDto) => {},
      disabled = false,
      onInfoButtonPress,
      searchAction,
    }: StoreSelectorProps,
    ref: ForwardedRef<StoreSelectorHandler>,
  ) => {
    const storeListRef = useRef<StoreSelectorListHandler>(null);
    const theme = useTheme();
    const modalRef = useRef<BaseModalHandler>(null);
    const [inputValue, setInputValue] = useState('');

    const handleShowModal = () => {
      modalRef.current?.show();
    };

    const handleDismissModal = () => {
      modalRef.current?.hide();
      setInputValue('');
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleOnSave = () => {
      const selectedValue = storeListRef.current?.getSelectedValue();
      if (selectedValue) onChange(selectedValue);
      handleDismissModal();
    };

    const handleInputChange = (value: string) => {
      setInputValue(value);
    };

    return (
      <GenericModal
        webModalSize="sm"
        title="Find your store"
        ref={modalRef}
        buttons={[
          {
            onPress: handleOnSave,
            logger: { id: 'ok-button-modal-store-selector' },
            text: getText('select'),
            hierarchy: 'primary',
          },
        ]}
        isScrollable
        showDismissButton
      >
        {/* Based on this ticket BLUES-5396 we need to hide this functionality, will be added again*/}
        {/* <StoreSelectorInput onInputChange={handleInputChange} /> */}

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              height: '100%',
              width: 380,
            }}
          >
            <StoreSelectorList
              options={options}
              selectedOption={selectedOption}
              disabled={disabled}
              searchCriteria={inputValue}
              onInfoButtonPress={onInfoButtonPress}
              searchAction={searchAction}
              ref={storeListRef}
            />
          </View>
        </View>
      </GenericModal>
    );
  },
);
