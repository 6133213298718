import {
  PrescriptionDto,
  RefillRemoveBehavior,
} from '@digitalpharmacist/prescription-service-client-axios';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { PrescriptionCard } from '../../../components/prescription-card';
import { Divider } from 'react-native-paper';
import { Alert } from 'assets/components/alert';

export type UnableToSubmitDetailsProps = {
  alertText: string;
  processablePrescriptions: PrescriptionDto[];
  removedPrescriptions: PrescriptionDto[];
  isRemovable: (prescription: PrescriptionDto) => boolean;
  onRemovePrescription: (prescription: PrescriptionDto) => void;
};

export const UnableToSubmitDetails: FunctionComponent<
  UnableToSubmitDetailsProps
> = ({
  alertText,
  processablePrescriptions,
  removedPrescriptions,
  isRemovable,
  onRemovePrescription,
}) => {
  const styles = useStyles();

  return (
    <View>
      {Boolean(alertText) && <Alert intent="warning" title={alertText} />}
      {Boolean(processablePrescriptions.length) && (
        <>
          <Text style={styles.textTitle}>
            {getText('one-or-more-items-to-review')}
          </Text>
          <Divider />
          <Text style={styles.textTitleDescription}>
            {getText('we-will-process-following-items')}
          </Text>

          {processablePrescriptions.map((prescription) => (
            <PrescriptionCard
              key={prescription.rx_number}
              showRxNumber={true}
              showStatus={true}
              prescription={prescription}
              removable={isRemovable(prescription)}
              onRemove={() => onRemovePrescription(prescription)}
            />
          ))}
        </>
      )}

      {Boolean(removedPrescriptions.length) && (
        <>
          <Text style={styles.textTitle}>
            {getText('unable-to-process-items')}
          </Text>
          <Divider />
          <Text style={styles.textTitleDescription}>
            {getText('cannot-process-items')}
          </Text>

          {removedPrescriptions.map((prescription) => (
            <PrescriptionCard
              key={prescription.rx_number}
              showRxNumber={true}
              showStatus={true}
              prescription={prescription}
              removable={isRemovable(prescription)}
              onRemove={() => onRemovePrescription(prescription)}
            />
          ))}
        </>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1.5),
  },
  textTitleDescription: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontWeight: '500',
    fontSize: 14,
    marginTop: theme.getSpacing(1.5),
    marginBottom: theme.getSpacing(2),
  },
}));
