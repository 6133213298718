import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const FlagIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={'none'}
      testID={testID}
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
    >
      <Path d="M3.33301 12.5C3.33301 12.5 4.16634 11.6667 6.66634 11.6667C9.16634 11.6667 10.833 13.3333 13.333 13.3333C15.833 13.3333 16.6663 12.5 16.6663 12.5V2.5C16.6663 2.5 15.833 3.33333 13.333 3.33333C10.833 3.33333 9.16634 1.66667 6.66634 1.66667C4.16634 1.66667 3.33301 2.5 3.33301 2.5V12.5ZM3.33301 12.5V18.3333" />
    </Svg>
  );
};
