import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { setStatusBarBackgroundColor } from 'expo-status-bar';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { AppLoader } from 'assets/common/AppLoader';
import { MapProvider } from 'assets/components/map/context';
import { ApiConfig } from 'assets/core/api';
import {
  DeepLinkingProvider,
  DefaultDeepLinkingConfig,
  initSmartBanner,
} from 'assets/core/deep-linking';
import { initLogger } from 'assets/logging/logging';
import {
  ThemeProvider,
  createBrandedColors,
  useThemeContext,
} from 'assets/theme';
import { AppNavigationProvider } from './modules/providers/app-navigation';
import { AppNotificationsProvider } from './modules/providers/app-notifications';

import { StorageKeys } from './enums/storage-keys';
import {
  PHARMACY_NOT_EXIST_ERROR,
  URL_MALFORMED_ERROR,
  checkMobileBrowser,
  loadDeepLinkingConfig,
  loadLocationPickupMethods,
  loadLocationPrescriptionConfig,
  loadLocationServicesConfig,
  loadMessagesConfig,
  loadPatientData,
  loadPharmacyBrandingInfo,
  loadSlugPrefixForWeb,
  loadUser,
  refreshNotifications,
  setAmplitudeUserProperties,
} from './modules/actions/app-actions';
import UsersServiceInstance from './modules/api/users-service';
import {
  MAPBOX_ACCESS_TOKEN,
  ROOT_PATH,
  SENTRY_ENVIRONMENT,
  USERWAY_ACCOUNT_ID,
} from './modules/common/constants';
import { handleMarkAsRead } from './modules/actions/notification-actions';
import { RootNavigation } from './modules/navigation/RootNavigation';
import { GetTheApp } from './modules/screens/get-the-app/GetTheApp';
import { logout } from './modules/screens/login/login-actions';
import { useAppStateStore } from './modules/store/app-store';
import { isUserAuthorized, useUserState } from './modules/store/user-store';
import { LoadingFullScreenView } from 'assets/common/LoadingFullScreenView';
import shallow from 'zustand/shallow';
import { AppRedirectionProvider } from './modules/providers/app-redirection';
import { titleFormatter } from './modules/utils';
import AsyncStorageService from './modules/api/async-storage-service';
import { ErrorBoundary } from './modules/screens/error-boundary/ErrorBoundary';
import { PharmacyNotFound } from './modules/screens/pharmacy-not-found/PharmacyNotFound';
import { initUserway } from 'assets/utils/common';

initLogger(SENTRY_ENVIRONMENT);

ApiConfig.setBaseApiConfig({
  getAccessToken: async () =>
    await AsyncStorageService.getItem(StorageKeys.AccessToken),
  setAccessToken: async (token: string) =>
    await AsyncStorageService.setItem(StorageKeys.AccessToken, token),
  signOut: async () => {
    await logout();
  },
  retryRefreshToken: async () => {
    try {
      const refreshToken = await AsyncStorageService.getItem(
        StorageKeys.RefreshToken,
      );
      const { access_token } = await UsersServiceInstance.refreshToken({
        refresh_token: refreshToken ?? '',
      });
      return access_token;
    } catch (error) {
      return '';
    }
  },
});

Platform.OS === 'web' &&
  DefaultDeepLinkingConfig.enableSmartBanner &&
  initSmartBanner();

export default function App() {
  return (
    <RootAppProvider>
      <SafeAreaProvider>
        <MapProvider accessToken={MAPBOX_ACCESS_TOKEN}>
          <ThemeProvider>
            <BottomSheetModalProvider>
              <ActionSheetProvider>
                <AppNavigationContainer>
                  <AppContainer>
                    <RootNavigation />
                  </AppContainer>
                </AppNavigationContainer>
              </ActionSheetProvider>
            </BottomSheetModalProvider>
          </ThemeProvider>
        </MapProvider>
      </SafeAreaProvider>
    </RootAppProvider>
  );
}

const RootAppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [hasSlugOrPharmacyError, setHasSlugOrPharmacyError] = useState(false);

  const initialize = async () => {
    try {
      await loadSlugPrefixForWeb();
      await checkMobileBrowser();
      await loadPharmacyBrandingInfo();
      await loadDeepLinkingConfig();

      if (Platform.OS === 'web' && USERWAY_ACCOUNT_ID) {
        initUserway(USERWAY_ACCOUNT_ID);
      }
    } catch (e: any) {
      if (
        e.message === URL_MALFORMED_ERROR ||
        e.message === PHARMACY_NOT_EXIST_ERROR
      )
        setHasSlugOrPharmacyError(true);
    }
  };

  return (
    <AppLoader onBeforeLoad={initialize}>
      {hasSlugOrPharmacyError ? <PharmacyNotFound /> : children}
    </AppLoader>
  );
};

const AppContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { theme, setTheme } = useThemeContext();
  const { user } = useUserState();
  const { isMobileWeb, branding, deepLinkingConfig, pharmacyName, pharmacyId } =
    useAppStateStore();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    void loadLocationPrescriptionConfig();
    void loadLocationServicesConfig();
    void loadMessagesConfig();
    void loadLocationPickupMethods();
  }, [user?.preferredPharmacyLocationId]);

  useEffect(() => {
    void (async () => {
      const newColors = createBrandedColors({
        ...theme.colors,
        brandedText: branding?.branded_text_color ?? theme.colors.brandedText,
        gradientStart:
          branding?.gradient_bkg_1_color ?? theme.colors.gradientStart,
        gradientEnd: branding?.gradient_bkg_2_color ?? theme.colors.gradientEnd,
        brandedPrimary:
          branding?.button_primary_color ?? theme.colors.brandedPrimary,
      });

      setTheme({ colors: newColors });
      setStatusBarBackgroundColor(newColors.brandedPrimary, true);

      if (!isMobileWeb) {
        await loadUser();
        await loadPatientData();
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setAmplitudeUserProperties(pharmacyName);
  }, [user?.id, pharmacyId, pharmacyName]);

  return loading ? (
    <LoadingFullScreenView />
  ) : (
    <DeepLinkingProvider deepLinkingConfig={deepLinkingConfig}>
      {isMobileWeb ? (
        <GetTheApp /> // `<GetTheApp />` needs deep linking provider in order to open te app
      ) : (
        <AppNotificationsProvider // review notification provider with Austin (do we need the user at this level? do should the notification be available only for logged in users?)
          user={user}
          refreshNotifications={refreshNotifications}
          handleMarkAsRead={handleMarkAsRead}
        >
          {children}
        </AppNotificationsProvider>
      )}
    </DeepLinkingProvider>
  );
};

const AppNavigationContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { prefix } = useAppStateStore((x) => ({ prefix: x.slug }), shallow);
  if (!prefix) throw Error('No prefix provided');

  return (
    <AppNavigationProvider
      appNavigationConfig={{
        prefix,
        root: ROOT_PATH,
        titleFormatter: titleFormatter,
      }}
    >
      <AppRedirectionContainer>{children}</AppRedirectionContainer>
    </AppNavigationProvider>
  );
};

const AppRedirectionContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.user));

  return (
    <AppRedirectionProvider
      appRedirectionConfig={{
        handleAutomaticRedirection: true,
      }}
      isAuthorized={isAuthorized}
      prefixKey="prefix"
      rootPath={ROOT_PATH}
    >
      {children}
    </AppRedirectionProvider>
  );
};
