import { StackHeaderProps } from '@react-navigation/stack';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { Text } from 'assets/components/text';
import { useForm } from 'assets/form';
import { AddMedicationsIcon } from 'assets/icons';
import { Form, InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  DimensionValue,
  ImageBackground,
  Platform,
  View,
  useWindowDimensions,
} from 'react-native';
import { Surface } from 'react-native-paper';
import { useUserState } from '../../store/user-store';
import { AddMedicationsOptions } from '../register/register-actions';
import { useTransferMedicationsStore } from '../transfer-medications/transfer-meds-store';
import {
  ensureLocationPatientRecordExist,
  openAddMedications,
  setFindMedsScreenAsSeen,
} from './add-medications-actions';
import { useAppStateStore } from '../../store/app-store';

export const AddMedicationsChooseOption: FunctionComponent<
  AddMedicationsChooseOptionProps
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { user } = useUserState();
  const { pharmacyName } = useAppStateStore();

  const methods = useForm<AddMedicationsOptions>({
    defaultValues: {
      option: 'find-medications-option',
    },
  });

  const { width } = useWindowDimensions();
  const buttonWidth =
    Platform.OS === 'web'
      ? theme.webMaxWidth - theme.getSpacing(2)
      : width - theme.getSpacing(2);

  const handleSubmit = async () => {
    const formValues = methods.getValues();

    if (!user?.patientRecordId) {
      return logError(new Error('User patientRecordId not found'));
    }

    // user choose Skip this step
    if (formValues.option === 'skip-option') {
      await setFindMedsScreenAsSeen();
      await ensureLocationPatientRecordExist(user.patientRecordId);

      navigation.navigate('app', { screen: 'home' });
    }

    // user choose Transfer Medications
    if (formValues.option === 'transfer-medications-option') {
      useTransferMedicationsStore.setState({ originated: 'onboarding' });

      navigation.navigate('transfer-medications', {
        screen: 'single-location',
      });
    }

    // user choose Add Medication
    if (formValues.option === 'find-medications-option') {
      void openAddMedications(user.patientRecordId, navigation, 'onboarding');
    }
  };

  return (
    <InternalScreenContainer
      title={getText('find-meds')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: handleSubmit,
          logger: { id: 'add-person-submit-button' },
          text: getText('next'),
        },
      ]}
      scrollToTop={!!methods.getFieldState('option').error}
    >
      <View style={styles.container}>
        <Text style={styles.textTitle}>{getText('add-medications')}</Text>
      </View>
      <View style={styles.cardContainer}>
        <View>
          <InfoCard width={buttonWidth} height={104}>
            <View style={styles.cardView}>
              <View>
                <AddMedicationsIcon
                  size={80}
                  color={theme.palette.warning[50]}
                />
              </View>
              <View style={styles.textContainer}>
                <View style={{ marginTop: theme.getSpacing(2) }}>
                  <Text style={[styles.text]}>{getText('add-meds-text')}</Text>
                </View>
              </View>
            </View>
          </InfoCard>
        </View>
        <Form methods={methods}>
          <Form.Alert
            visible={!!methods.getFieldState('option').error}
            title={getText('unable-to-process-complete-required-fields')}
            intent="error"
          />
          <Form.Row>
            <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
              <RadioButtonGroupField
                name="option"
                testID={''}
                values={[
                  {
                    text: getText('prescriptions-at-file', {
                      pharmacyName: pharmacyName,
                    }),
                    value: 'find-medications-option',
                  },
                  {
                    text: getText('request-to-transfer'),
                    value: 'transfer-medications-option',
                  },
                  {
                    text: getText('skip-this-step'),
                    value: 'skip-option',
                    description: getText('do-this-medications-tab'),
                  },
                ]}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    </InternalScreenContainer>
  );
};

//TODO: Change this to use the InfoCardComponent on shared components file

const InfoCard: FunctionComponent<
  PropsWithChildren<{
    uri?: string;
    width?: string | number;
    height?: DimensionValue;
  }>
> = ({ uri, width = 100, height = 100, children }) => {
  const theme = useTheme();
  const styles = useStyles();
  const innerContent = uri ? (
    <ImageBackground
      source={{ uri: uri }}
      resizeMode="cover"
      style={{ height }}
    />
  ) : (
    children
  );

  return (
    <Surface
      style={[
        styles.button,
        {
          elevation: 2,
          borderRadius: theme.roundness,
          borderColor: theme.palette.gray[200],
          borderWidth: 1,
          height,
          overflow: 'hidden',
          backgroundColor: theme.palette.yellow[50],
          minHeight: height,
        },
      ]}
    >
      {innerContent}
    </Surface>
  );
};

export type AddMedicationsChooseOptionProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  textTitle: {
    marginBottom: theme.getSpacing(1),
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '600',
  },
  cardContainer: {
    marginTop: theme.getSpacing(1),
    alignItems: 'stretch',
    flex: 1,
  },
  cardView: {
    justifyContent: 'center',
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
    marginTop: theme.getSpacing(1),
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  button: {
    minWidth: 64,
    borderStyle: 'solid',
  },
  text: {
    color: theme.palette.gray[700],
    fontWeight: '500',
    fontSize: 14,
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.getSpacing(2),
    flexWrap: 'wrap',
    flex: 1,
  },
}));
