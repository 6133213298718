import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useNavigation } from '@react-navigation/native';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import { SendOptions, resendResetLink } from './reset-password-actions';
import { useResetPasswordState } from './reset-password-store';
import { LoginStackNavigationProp } from '../../navigation/LoginNavigation';
import { ResetLinkRadioButton } from './ResetLinkRadioButton';
import { ResetPasswordRequestDtoNotificationTypeEnum } from '@digitalpharmacist/users-service-client-axios';

export const ResetUnsuccessful: FunctionComponent<
  PropsWithChildren<ResetUnsuccessfulProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const navigation = useNavigation<LoginStackNavigationProp>();
  const { email } = useResetPasswordState();
  const onPress = async () => {
    await resendResetLink(email || '', navigation, sendResetLinkMethod);
  };
  const [sendResetLinkMethod, setSendResetLinkMethod] =
    useState<ResetPasswordRequestDtoNotificationTypeEnum>(
      ResetPasswordRequestDtoNotificationTypeEnum.Email,
    );

  type ResetLinkChoicesType = {
    text: string;
    value: SendOptions;
    description?: string;
  };

  const choices: Array<ResetLinkChoicesType> = [
    {
      text: getText('email-short'),
      value: SendOptions.email,
    },
    {
      text: getText('text-message'),
      value: SendOptions.sms,
      description: getText('link-will-be-sent'),
    },
  ];

  return (
    <ScreenContainer useBottomInset>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 25,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: 'center',
        }}
      >
        {getText('reset-unsuccessful')}
      </Text>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 14,
          paddingBottom: theme.getSpacing(2),
          textAlign: 'center',
        }}
      >
        {getText('link-expired')}
      </Text>
      <View style={styles.fieldContainer}>
        <Text style={styles.resetLinkOptionText}>
          {getText('how-would-you-like-to-receive-reset-link')}
        </Text>
        {choices.map((choice) => (
          <ResetLinkRadioButton
            key={choice.value}
            text={choice.text}
            description={choice.description}
            value={choice.value}
            selected={sendResetLinkMethod === choice.value}
            disabled={status === 'loading'}
            onPress={setSendResetLinkMethod}
          />
        ))}
      </View>
      <View style={{ margin: theme.getSpacing(1) }}>
        <View style={{ marginBottom: theme.getSpacing(2) }}>
          <Button
            onPress={onPress}
            hierarchy="primary"
            logger={{ id: 'reset-password-link-sent-back-to-login-button' }}
          >
            {getText('send-reset-link')}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  fieldContainer: {
    marginBottom: theme.getSpacing(2),
    marginTop: theme.getSpacing(2),
  },
  resetPasswordText: {
    color: theme.palette.gray[900],
    fontSize: 30,
    textAlign: 'center',
  },
  resetPasswordDescriptionText: {
    color: theme.palette.gray[600],
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 24,
  },
  resetLinkOptionText: {
    marginBottom: theme.getSpacing(2),
    color: theme.palette.gray[600],
    fontSize: 16,
    lineHeight: 24,
  },
}));

interface ResetUnsuccessfulProps {}
