import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Platform, View } from 'react-native';
import { Divider, IconButton } from 'react-native-paper';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';

import { getText } from 'assets/localization/localization';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import { Text } from 'assets/components/text/Text';
import { InternalScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { PrescriptionCard } from '../../components/prescription-card';
import { StoreSelector } from '../../components/store-selector';
import { MapSize } from '../../components/store-selector/types';
import { useAppStateStore } from '../../store/app-store';
import refillService from './refill-service';
import {
  useRefillMedicationsStore,
  useRefillReviewMethodStore,
} from './refill-store';
import { RefillTestIDs } from './RefillTestIDs';
import { useUserState } from '../../store/user-store';
import orderService from '../medications/medication-orders/order-service';
import { useOrderRefillStore } from '../medications/medication-orders/order-store';
import { usePatientRecordState } from '../account/patient/patient-store';
import { PrescriptionDetails } from '../../components/prescription-details/PrescriptionDetails';
import { PrescriptionDetailsHandler } from '../../components/prescription-details/PrescriptionDetailsProps';
import patientService from '../../api/patient-service';
import { UnableToProcess } from '../../components/unable-to-process/UnableToProcess';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { CloseIcon } from 'assets/icons';
import {
  loadLocationPickupMethods,
  loadStores,
} from '../../actions/app-actions';
import { logError } from 'assets/logging/logger';
import { StackHeaderProps } from '@react-navigation/stack';
import { Alert } from 'assets/components/alert';
import { ampli } from '../../ampli';

export const RefillReview: FunctionComponent<
  PropsWithChildren<RefillReviewProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const { user } = useUserState();
  const styles = useStyles();
  const { pharmacyId, stores, locationPickupMethods } = useAppStateStore();
  const {
    selectedLocationId,
    selectedMedications,
    selectedPatient,
    selectedPatientRecord: patientRecord,
    unableToSubmitMedications,
    updateMedicationsData,
  } = useRefillMedicationsStore();
  const {
    status,
    methodValue,
    methodName,
    isUrgent,
    isToday,
    submitRefill,
    updateReviewMethodData,
  } = useRefillReviewMethodStore();
  const { recordsUnderCare } = usePatientRecordState();

  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMethodError, setShowMethodError] = useState<boolean>(false);
  const [patientRecordError, setPatientRecordError] = useState<boolean>(false);
  const [otherInformationText, setOtherInformationText] = useState<string>('');
  const prescriptionDetailsRef = React.useRef<PrescriptionDetailsHandler>(null);
  const sheetRef = useRef<BottomSheetModal>(null);

  useEffect(() => {
    if (!stores.length) void loadStores();

    void loadPickupMethod();
  }, [selectedPatient]);

  useEffect(() => {
    void loadOtherInformationText();
  }, [patientRecord]);

  useEffect(() => {
    const selectedStore = stores.find(
      (store) => store.id === selectedLocationId,
    );
    setSelectedStore(selectedStore);
  }, [stores, selectedLocationId]);

  const loadPickupMethod = async () => {
    if (!selectedLocationId || !selectedPatient || !updateReviewMethodData) {
      return logError(
        new Error(
          'selectedLocationId or selectedPatient or updateReviewMethodData is missing',
        ),
      );
    }

    await loadLocationPickupMethods();

    const lastRefill = await refillService.getLastRefill(
      selectedLocationId,
      selectedPatient.id,
    );

    if (!lastRefill) return;

    const pickupMethod = locationPickupMethods.find(
      (it) => it.id === lastRefill.pickup_method_id,
    );

    if (pickupMethod) {
      updateReviewMethodData({
        methodValue: pickupMethod.id,
        methodName: pickupMethod.name,
        methodMessage: pickupMethod.confirmation,
      });
    } else {
      updateReviewMethodData({
        methodValue: 'not-selected',
        methodName: 'Not Selected',
        methodMessage: '',
      });
    }
  };

  const handleOnPressDone = async () => {
    if (
      !submitRefill ||
      !selectedPatient ||
      !selectedLocationId ||
      !patientRecord ||
      !user ||
      !user.patientRecordId ||
      !updateMedicationsData ||
      !selectedMedications
    )
      return;

    let hasValidationErrors = false;

    if (methodValue === '' || methodValue === 'not-selected') {
      setShowMethodError(true);
      hasValidationErrors = true;
    }

    if (
      patientRecord.allergies.length === 0 ||
      !patientRecord.gender ||
      patientRecord.prefers_easy_open_bottle_caps === undefined ||
      patientRecord.prefers_easy_open_bottle_caps === null
    ) {
      setPatientRecordError(true);
      hasValidationErrors = true;
    }

    if (hasValidationErrors) return;

    setIsLoading(true);

    await submitRefill(
      pharmacyId,
      selectedLocationId,
      selectedPatient.id,
      selectedMedications,
      user.id,
      isToday,
      isUrgent,
    );

    if (status === 'error') {
      updateMedicationsData({
        status: 'error',
      });
      sheetRef.current?.present();
      return;
    } else {
      navigation.navigate('refill-confirmation');
    }

    const prescriptions = await refillService.getPatientPrescriptions(
      selectedLocationId,
      selectedPatient.id,
    );

    updateMedicationsData({
      selectedMedications: [],
    });

    const caregiverPatientRecord = await patientService.findPatientRecord(
      user.patientRecordId,
    );

    const allOrders = await orderService.getPatientOrders(
      selectedLocationId,
      recordsUnderCare,
      caregiverPatientRecord,
    );

    useOrderRefillStore.setState({
      patientOrders: allOrders.sort((po1, po2) =>
        po1.order.created_at < po2.order.created_at ? 1 : -1,
      ),
    });

    setIsLoading(false);
  };

  const handleOtherInformationNavigation = () => {
    navigation.navigate('refill-other-information');
  };

  const handlePrescriptionMethodNavigation = () => {
    navigation.navigate('refill-prescription-method');
  };

  const loadOtherInformationText = async () => {
    if (!patientRecord) return;

    setOtherInformationText(getText('loading'));

    try {
      const otherInformationText =
        await refillService.getPatientMedicalInfoText(patientRecord);

      setOtherInformationText(otherInformationText);
    } catch (error) {
      setOtherInformationText('Error ' + getText('loading'));
    }
  };

  const removeMedications = (item: PrescriptionDto) => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      selectedMedications: selectedMedications?.filter(
        (x) => x.rx_number !== item.rx_number,
      ),
      unableToSubmitMedications: unableToSubmitMedications?.filter(
        (med) => med.rx_number !== item.rx_number,
      ),
    });
  };

  const handleOpenDetailsPress = (prescription: PrescriptionDto) => {
    prescriptionDetailsRef.current?.show(prescription);
  };

  return (
    <>
      <InternalScreenContainer
        title={getText('medications-fill-your-prescription')}
        useBottomInset={true}
        buttons={[
          {
            hierarchy: 'primary',
            onPress: handleOnPressDone,
            testID: RefillTestIDs.refillSubmitButton,
            logger: { id: RefillTestIDs.refillSubmitButton },
            disabled: selectedMedications?.length === 0,
            loading: isLoading,
            text: getText('submit'),
          },
        ]}
        scrollToTop={showMethodError || patientRecordError}
      >
        {(showMethodError || patientRecordError) && (
          <Alert
            intent={'error'}
            title={getText('unable-to-process-complete-required-fields')}
          />
        )}
        <View>
          <View style={{ marginTop: theme.getSpacing(2) }}>
            <Text style={styles.textTitle}>{getText('medications')}</Text>
          </View>
          {selectedMedications &&
            selectedMedications.map((item) => (
              <View key={item.rx_number}>
                <View style={{ paddingVertical: theme.getSpacing(2) }}>
                  <Divider />
                </View>
                <PrescriptionCard
                  key={item.rx_number}
                  showRxNumber={true}
                  showPatientInfo={true}
                  showStatus={true}
                  showDetailsIcon={true}
                  removable={selectedMedications.length > 1}
                  prescription={item}
                  checked={item.checked}
                  onRemove={() => removeMedications(item)}
                  onOpenDetailsPress={() => handleOpenDetailsPress(item)}
                />
              </View>
            ))}
        </View>
        <View>
          <View style={styles.container}>
            <View style={{ paddingVertical: theme.getSpacing(1) }}>
              <Text style={styles.textTitle}>{getText('order-details')}</Text>
              <Divider />
            </View>
            <View style={[styles.container]}>
              {selectedStore && (
                <StoreSelector
                  options={stores}
                  selectedOption={selectedStore}
                  mapProps={{ size: MapSize.lg }}
                  changeButtonShown={false}
                />
              )}
            </View>
          </View>
          <Divider />
          <ListMenu style={styles.container}>
            <ListItemLink
              onPress={handleOtherInformationNavigation}
              showIcon={false}
            >
              <Text style={styles.textTitle}>
                {getText('other-information')}
              </Text>
              <Text>{otherInformationText}</Text>
            </ListItemLink>
            {patientRecordError && (
              <Text style={{ color: 'red' }}>{getText('field-required')}</Text>
            )}
            <ListItemLink
              onPress={handlePrescriptionMethodNavigation}
              showIcon={false}
            >
              <Text style={styles.textTitle}>{getText('method')}</Text>
              <Text style={{ textTransform: 'capitalize' }}>{methodName}</Text>
            </ListItemLink>
            {showMethodError && (
              <Text style={{ color: 'red' }}>{getText('field-required')}</Text>
            )}
          </ListMenu>
        </View>
        <PrescriptionDetails ref={prescriptionDetailsRef} />
      </InternalScreenContainer>
      {isLoading && (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )}
      <BottomSheet
        bottomSheetRef={sheetRef}
        height={'90%'}
        onDismiss={() => sheetRef.current?.dismiss()}
        hideHandle={true}
        contentContainerStyle={{ flexGrow: 1 }}
        headerContent={
          <View style={styles.unableToSubmitHeader}>
            <View style={styles.sheetIconContainer}>
              <IconButton
                icon={CloseIcon}
                onPress={() => sheetRef.current?.dismiss()}
                size={24}
                color={theme.palette.gray[500]}
              />
            </View>
          </View>
        }
      >
        <UnableToProcess navigation={navigation} />
      </BottomSheet>
    </>
  );
};

export type RefillReviewProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  displayContent: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    fontWeight: '400',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  root: {
    flex: 1,
  },
  rowDisplay: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  medicationTitle: {
    ...theme.fonts.medium,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  descriptionTitle: {
    ...theme.fonts.medium,
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[700],
    marginVertical: theme.getSpacing(0.5),
  },
  medicationDetailsText: {
    ...theme.fonts.medium,
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[700],
  },
  viewItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: theme.getSpacing(1),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginVertical: theme.getSpacing(1),
  },
  nameText: {
    color: theme.palette.gray[900],
    fontWeight: '700',
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  addressText: {
    color: theme.palette.gray[900],
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  blueText: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.primary[400],
    margin: theme.getSpacing(0.5),
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  otherInformationModal: {
    padding: theme.getSpacing(2),
  },
  methodModal: {
    padding: theme.getSpacing(2),
    margin: theme.getSpacing(2),
  },
  methodOptions: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    margin: theme.getSpacing(1),
    padding: theme.getSpacing(1),
    borderRadius: theme.roundness,
  },
  insuranceModal: {
    padding: theme.getSpacing(2),
  },
  noteForPharmacyContainer: {
    borderWidth: 1,
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[100],
    backgroundColor: theme.palette.gray[100],
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.getSpacing(1),
    marginVertical: theme.getSpacing(1),
  },
  displayTitleContent: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
  },
  loadingIndicator: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: theme.getSpacing(2),
    zIndex: 10,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: -20,
  },
  sheetIconContainer: {
    position: 'absolute',
    right: -12,
    top: -5,
    zIndex: 1,
  },
  unableToSubmitHeader: {
    position: 'relative',
    marginBottom: theme.getSpacing(1),
  },
}));
