import { Icon } from 'assets/components/icon';
import { PhoneIcon } from 'assets/icons';
import theme, { makeStyles } from 'assets/theme';
import React from 'react';
import { Platform, Pressable, Linking, View } from 'react-native';
import { formatPhoneNumber } from '../../utils';
import { Text } from 'assets/components/text';

const PhoneNumber = ({
  showIcon = true,
  numberLabel,
  phoneNumber,
  mode = 'link',
  iconColor,
  labelColor,
  numberColor,
}: PhoneNumberProps) => {
  const styles = useStyles();
  const isWeb = Platform.OS === 'web';

  const callPhoneNumber = async () => {
    if (!isWeb) {
      await Linking.openURL(`tel:${phoneNumber}`);
    }
  };

  return (
    <Pressable
      disabled={isWeb}
      onPress={() => callPhoneNumber()}
      style={[
        mode === 'button' && !isWeb
          ? styles.borderedPhoneNumber
          : styles.simplePhoneContainer,
      ]}
    >
      {showIcon && (
        <Icon
          color={
            iconColor
              ? iconColor
              : isWeb || mode === 'button'
              ? theme.palette.gray[700]
              : theme.palette.primary[500]
          }
          icon={PhoneIcon}
          size={17}
        />
      )}
      {numberLabel && (
        <Text
          style={[
            styles.text,
            {
              color: labelColor
                ? labelColor
                : isWeb || mode === 'button'
                ? theme.palette.gray[700]
                : theme.palette.primary[500],
            },
          ]}
        >
          {numberLabel}
        </Text>
      )}
      <Text
        style={[
          styles.text,
          {
            color: numberColor
              ? numberColor
              : isWeb || mode === 'button'
              ? theme.palette.gray[700]
              : theme.palette.primary[500],
          },
        ]}
      >
        {formatPhoneNumber(phoneNumber)}
      </Text>
    </Pressable>
  );
};

const useStyles = makeStyles((theme) => ({
  simplePhoneContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(1),
    marginTop: theme.getSpacing(1),
  },
  borderedPhoneNumber: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    gap: theme.getSpacing(1),
    borderWidth: 1,
    borderColor: theme.palette.black[700],
    borderRadius: theme.roundness,
    paddingVertical: theme.getSpacing(1.5),
    paddingHorizontal: theme.getSpacing(2),
  },
  text: {
    ...theme.lumistryFonts.text.medium.regular,
  },
}));

type PhoneNumberProps = {
  numberLabel?: string;
  phoneNumber: string;
  mode: 'link' | 'button';
  showIcon?: boolean;
  iconColor?: string;
  labelColor?: string;
  numberColor?: string;
};

export default PhoneNumber;
