import { TranslationMapping } from "../localization";

const mapping: TranslationMapping = {
  "complete-checklist-items-in-order": "Vous devez compléter les éléments de la liste de contrôle afin",
  "answer-following-questions": "Veuillez répondre aux questions suivantes concernant votre site Web destiné aux patients.",
  "build-replace-website": "Nous aimerions que Lumistry construise un site Web pour remplacer mon site actuel.",
  "build-new-website": "Nous aimerions que Lumistry crée un site Web car nous n'en avons pas.",
  "no-build-existing-website": "Nous ne voulons pas que Lumistry crée un site Web car nous en avons déjà un.",
  "no-build-no-existing-website": "Nous ne voulons pas que Lumistry crée un site Web et nous n'en avons pas.",
  "brand-color-required": "La couleur de la marque est requise.",
  "website-access": "Accès au site web",
  "website-featured-services": "Services proposés sur le site Web",
  "select-6-services": "Veuillez en sélectionner jusqu'à 6 à mettre en évidence sur votre site Web.",
  "selected-services-tooltip-text": "Les services sélectionnés seront affichés dans la navigation du site Web et auront des sections dédiées sur le site Web.",
  "pharmacy-service-details": "Détails du service de pharmacie",
  "indicate-type-of-compounding":"Indiquez le type de composition que vous proposez :",

  "website-you-like-to-use": "Avez-vous un domaine de site Web que vous aimeriez utiliser?",
  "website-how-to-access-domain": "Savez-vous comment accéder à votre domaine?",
  "website-domain-first-tooltip": "Un domaine de site Web est un nom spécial que vous saisissez dans l'ordinateur pour accéder à un site Web spécifique sur Internet. Un exemple de domaine de site Web (ou d'adresse du site Web) serait www.votrepharmacie.com",
  "website-domain-second-tooltip": "Avez-vous accès au nom d'utilisateur et au mot de passe du registraire de domaine qui détient le domaine.",
  "website-completion-gratitude": "Merci d'avoir terminé la configuration du site Web. Un membre de l'équipe Lumistry vous contactera pour vous aider à terminer le reste du processus de création du site Web.",
  "publish-website-need-permission": "Pour publier votre nouveau site Web sur un domaine existant, Lumistry a besoin de l'autorisation de se connecter à votre hébergeur de domaine en votre nom.",
  "give-lumistry-permission": "J'autorise Lumistry à accéder au domaine suivant.",
  "please-provide-domain-details": "Veuillez fournir les détails suivants sur votre domaine",
  "domain-name": "Nom de domaine",
  "domain-host": "Hébergeur de domaine",
  "login-user-name": "Nom d'utilisateur connexion",
  "login-password": "Mot de passe",
  "checklist-app-config-submission-title": "Patient App",
  "checklist-app-config-submission-message": "Are you sure you are ready to submit your patient app to be published? ",
  "checklist-app-config-submission-description": "Once submitted, your patient app will have to be approved by the App Store and Google play before it can be listed. Once approved, any changes to branding, such as logos or color, must be re-approved by the App Store. ",
  "checklist-app-config-app-dev-account":"Apple Dev Account",
  "checklist-app-config-app-dev-account-desc": "An account will be created on your behalf using the following info:",
  "checklist-app-config-name":"App name",
  "checklist-app-config-name-required":"Required",
  "checklist-app-config-name-hint": "Appears in the mobile app (36 character limit)",
  "checklist-app-config-name-max-character": "Max limit 36 character",
  "checklist-app-config-dev-account": "Dev Account",
  "checklist-app-config-dev-account-description":"An account will be created on your behalf using the following info:",
  "checklist-app-config-duns-placeholder":"Select D-U-N-S",
  "checklist-app-config-duns-required":"D-U-N-S is required",
  "upload-a-pdf": "Télécharger un PDF...",
  "checklist-app-config-duns-tooltip": "Select the D-U-N-S you’d like associated with your patient app. This is often your primary location.",
  "checklist-app-config-market-name": "App marketplace name",
  "checklist-app-config-market-name-description": "Appears in Apple App Store and Google Play (30 character limit)",
  "checklist-app-config-market-name-max-characters": "Max limit 30 character",
  "checklist-app-config-market-name-not-valid":"Not valid",
  "checklist-app-config-market-name-required": "Required",
  "checklist-app-config-title": "App Settings",
  "checklist-app-config-id-title":"Driver’s license or government I.D.",
  "checklist-app-config-id-tooltip":"ID is used to gain approval for your App from App Store & Google Play",
  "checklist-app-config-website-url":"Website URL",
  "checklist-app-config-website-url-required": "Website URL is required",
  "checklist-app-config-website-url-not-valid": "Website URL is not valid",
  "select-brand-color": "Sélectionnez la couleur principale de votre marque",
  "specs-first-item": "Limite de taille de 10 Mo",
  "image-is-required": "Une imagen est requise",
  "valid-url": "Veuillez saisir une URL valide",
  "specs-second-item": "Format PNG ou SVG",
  "specs-third-item": "Transparent background",
  "brand-color": "Couleur de la marque",
  "white-text-readable": "Le texte blanc doit être lisible au-dessus de cette couleur. Les couleurs vives sont limitées.",
  "logo-and-icon": "Logo et icône",
  "high-quality-graphics": "Des graphismes de haute qualité sont la clé d'une application et d'un site Web d'aspect professionnel. Voir les spécifications:",
  "logo-light-bg": "Logo sur fond clair",
  "logo-dark-bg": "Logo sur fond sombre (facultatif)",
  "icon-apple-app": "Icône pour l'application Apple",
  "icon-android-app": "Icône pour l'application Android",
  "social-media": "Réseaux sociaux",
  "links-appear": "Ces liens apparaîtront sur le site de santé et dans d'autres communications avec les patients.",
  "fb-url": "L'adresse URL de Facebook",
  "insta-url": "URL Instagram",
  "twitter-url": "URL X (Twitter)",
  "setup-checklist": "Liste de contrôle de configuration",
  "need-to-do-first": "Ce que vous devez faire en premier...",
  "complete-healthsite-patient": "Les éléments du site de santé et de l'application patient doivent être complétés dès que possible afin que l'expérience patient de votre pharmacie puisse être activée. Veuillez d'abord remplir ces éléments.",
  "required": "Requis",
  "patient-agreements": "Patient agreements",
  "patient-agreements-description": "Terms of Use and a Privacy Policy must be added to your app for your patients to agree to.",
  "patient-agreements-use-template": "Use template",
  "patient-agreements-upload-pdf":"Upload pdf...",
  "patient-agreements-terms-option": "Terms of Use options",
  "patient-agreements-terms-template": "Terms of Use template",
  "patient-agreements-privacy-option": "Privacy Policy options",
  "patient-agreements-privacy-template": "Privacy Policy template",
  "patient-agreements-legal-notice": "Legal notice TBD",
  "patient-website": "Site Web des patients",
  "basic-setup": "Configuration de base",
  "media-settings": "Paramètres multimédias",
  "patient-app": "Application pour les patients",
  "checklist-item-completed": "Cet élément de la liste de contrôle est déjà complété",
  "other-settings": "Autres réglages",
  "checklist-items-in-settings": "TCes éléments de liste de contrôle peuvent également être trouvés dans la zone Paramètres.",
  "scanner-config": "Configuration du scanner",
  "clinical-services": "Services cliniques",
  "refill-settings": "Paramètres de recharge",
  "message-settings": "Paramètres des messages",
  "patient-agreements-desc": "Configurez vos conditions d'utilisation et votre politique de confidentialité pour les patients",
  "patient-website-desc": "Configurez et connectez le site Web de votre pharmacie.",
  "basic-setup-desc": "Merci d'avoir terminé la configuration étape par étape.",
  "media-settings-desc": "Image de marque, médias sociaux et accords avec les patients",
  "patient-app-desc": "Entrez la marque de votre pharmacie pour l'application patient de votre pharmacie.",
  "scanner-config-desc": "Haute priorité. Étape obligatoire pour utiliser les lecteurs de codes-barres",
  "clinical-services-desc": "Sélectionnez les services réservables proposés par votre pharmacie",
  "refill-settings-desc": "Copie d'erreur personnalisée, périodes de rappel, options de recharge automatique, plus encore....",
  "message-settings-desc": "Messages initiés par le patient, définition de la réponse automatique aux messages, plus encore...",
  "checklist-card-title":"Setup checklist",
  "checklist-card-subtitle":"Customize and get to know Lumistry.",
  "checklist-card-button":"Open checklist",
  "save-logout": "Sauvegarder & Déconnexion",
  "status-is-required": "Le statut est requis",
  "ob-something-went-wrong":"Something went wrong, please contact help and support.",
  "ob-address-title": 'Addresses',
  "ob-address-description": 'Please provide the following address information.',
  "ob-multi-location-title": "Location details",
  "ob-multi-location-subtitle":"Please complete each section.",
  "ob-multi-review-brand-name":"Brand name",
  "ob-multi-review-number-locations": "Number of locations",
  "pharmacy-store-address":'Adresse du magasin de pharmacie',
  "pharmacy-store-address-tooltip":'The physical location of the pharmacy',
  "account-billing-address":'Adresse de facturation du compte',
  "photo-error": "Photo (s) indisponible.Réessayez plus tard.",
  "account-billing-address-tooltip": 'Address associated with your payment method',
  "account-shipping-address": 'Account Shipping Address',
  "account-shipping-address-tooltip": 'Optional alternate shipping address',
  "same-as-pharmacy-store-address": "Same as Pharmacy Store Address",
  "on-premise-equipment": "Équipement sur site",
  "add-employee": "Ajouter employé",
  "brand-name-is-required": "Le nom de la marque est requis",
  "parent-company-is-required": "La société mère est requise",
  "ob-employees-title": "Salariés",
  "server-error": "Erreur interne du serveur",
  "ob-employees-generic-error": "Some entries are required or invalid. Please check all rows.",
  "ob-hours-generic-error": "Some entries are invalid. Please check all data.",
  "ob-employees-description": "Saisissez les employés et attribuez-leur un rôle. Les rôles déterminent les actions qu’un employé est autorisé à entreprendre. Utilisez les descriptions suivantes pour attribuer le rôle approprié.",
  "pms-does-not-require-opie-desc": "Votre logiciel de gestion de pharmacie ne nécessite pas d'appareil sur place pour se connecter à Lumistry. Vous pouvez passer à l'étape suivante.",
  "pms-requires-opie-desc": "Lumisty s'intégrera à votre système de gestion de pharmacie via un appareil physique. Cet appareil sera expédié une fois cette configuration terminée et un appel d'installation sera programmé.",
  "have-it-person": "¿Tiene una persona de TI que le ayude cuando su red o firewall necesiten actualizarse?*",
  "do-not-know": "Je ne sais pas",
  "faq": "FAQ",
  "visit-help-center": "Visitez le centre d'aide",
  "recipient_name": "Nom du destinataire",
  "firewall-provider": "Fournisseur de pare-feu",
  "name-is-not-valid": "El nombre no es válido",
  "phone-is-required": "Le numéro de téléphone est requis",
  "brand-name-is-not-valid": "Le nom de la marque n'est pas valide",
  "parent-company-is-not-valid": "La société mère n'est pas valide",
  "pharmacy-name-is-not-valid": "Le nom de la pharmacie n'est pas valide",
  "legal-entity-name-is-not-valid": "Le nom de l'entité juridique n'est pas valide",
  "dba-is-not-valid":"DBA n'est pas valide",
  "pharmacy-email-address-is-not-valid": "L'adresse e-mail de la pharmacie n'est pas valide",
  "pharmacy-ncpdp-is-not-valid": "NCPDP n'est pas valide",
  "pharmacy-npi-is-not-valid": "NPI n'est pas valide",
  "fax-is-not-valid":"Le numéro de fax n'est pas valide",
  "firewall-provider-is-required": "Un fournisseur de pare-feu est requis",
  "preferred-time-is-required": "L'heure préférée est requise",
  "firewall-provider-is-not-valid": "Le fournisseur de pare-feu n'est pas valide",
  "technician-name-is-not-valid": "Le nom du technicien n'est pas valide",
  "technician-email-is-not-valid": "L'e-mail du technicien n'est pas valide",
  "technician-phone-is-not-valid": "Le numéro de téléphone du technicien n'est pas valide",
  "recipient-name-is-not-valid": "Le nom du destinataire n'est pas valide",
  "recipient-name-is-required": "Le nom du destinataire est requis",
  "denied-password-change-text": "Vous avez créé votre compte de portail patient à l'aide de SSO, comme Google ou Apple Sign-On.Pour mettre à jour votre mot de passe, vous devrez accéder à vos paramètres SSO",
  "city-is-not-valid": "La ville n'est pas valide",
  "technician-name": "Nom du technicien",
  "technician-name-is-required": "Le nom du technicien est requis",
  "technician-email": "E-mail du technicien",
  "technician-email-is-required": "L'e-mail du technicien est requis",
  "technician-phone": "Numéro de téléphone du technicien",
  "technician-phone-is-required": "Le numéro de téléphone du technicien est requis",
  "address-device-shipped": "À quelle adresse souhaitez-vous que l'appareil soit expédié ?*",
  "po-boxes-not-accepted": "P.O. les cartons ne seront pas acceptés",
  "ship-to-pharmacy": "Adresse d'expédition à la pharmacie",
  "select-preferred-weekdays": "Veuillez sélectionner votre(vos) jour(s) de la semaine préféré pour la session d'installation à distance :*",
  "use-defaults": "Utiliser les valeurs par défaut",
  "select-all": "Tout sélectionner",
  "store-general-hours": "Ce sont les heures générales de votre magasin.",
  "holidays": "Vacances",
  "additional-closures-may-be-added": "Des fermetures supplémentaires pourront être ajoutées ultérieurement dans les paramètres.",
  "am-or-pm-installations": "Faut-il privilégier le matin ou l'après-midi pour un appel téléphonique d'installation à distance ?*",
  "signup": "S'inscrire",
  "login": "Connexion",
  "username": "Nom d'utilisateur",
  "password": "Mot de passe",
  "dashboard": "Tableau de bord",
  "medications": "Médicaments",
  "how-to-add-caregiver": "Comment ajouter un soignant ?",
  "add-caregiver-details": "Si vous souhaitez ajouter un soignant pour vous aider à gérer votre santé numériquement, il devra d'abord vous ajouter en tant que patient sous ses soins. Après cela, vous pourrez approuver ou refuser sa demande de accès des soignants à vos dossiers",
  "patients-under-care": "Patients sous soins",
  "caregivers": "Soignants",
  "messages": "Messages",
  "sender-subject-date": "Expéditeur, sujet, date",
  "template-name": "Nom du modèle",
  "assignee-summary": "Cessionnaire, résumé",
  "name-rxnumber-dob": "Nom, numéro de réception, date de naissance",
  "first-name-last-name-dob": "Prénom, nom, date de naissance",
  "patient-name-dob-form-name": "Nom du patient, date de naissance, nom du formulaire",
  "name-ncpdp-street-city": "Nom, NCPDP, rue, ville",
  "user-email-address": "Utilisateur, adresse email",
  "image-reading-error": "Impossible de charger l'image.Réessayez plus tard.",
  "profile": "Profil",
  "get-care": "Obtenez des soins",
  "lets-get-to-know-you": "Apprenons à vous connaître",
  "open-settings": "Configuration ouvrir",
  "patient-select": "Sélectionnez le patient avant de commencer.",
  "create-first-form": "Créez votre premier formulaire",
  "pharmacy-additional-closures-inactive": "inactive",
  "credentials": "Informations d'identification",
  "edit-brand-colors": "Modifier les couleurs de la marque",
  "invalid-hex-value": "Valeur hexagonale non valide",
  "lumistry-api-key": `La clé API que vous fournissez donnera à la lumistry accès à vos données Jotform, y compris les formulaires et les soumissions.Vos données seront maintenues en sécurité et ne seront utilisées que dans le but d'importer les formulaires que vous spécifiez dans la plate-forme de lumistry.`,
  "welcome": "Bienvenu",
  "logout": "Se déconnecter",
  "get-mobile-app": "Obtenez notre application mobile!",
  "front": "De face",
  "back": "Retour",
  "sent": "Envoyée",
  "all-tasks": "Toutes les tâches",
  "every-n-weeks": "Toutes les {{number}} semaines",
  "every-n-months": "Chaque {{number}} mois",
  "see-order-details": "Voir les détails de la commande",
  "rx-order-progress": "Ordre #{{orderId}} en cours",
  "prescriptions-filled": "{{filled}} des {{total}} ordonnances sont remplies",
  "manage-subscription": "Gérez vos prescriptions de votre famille et votre famille, communiquez avec votre pharmacie, accédez au matériel éducatif, planifiez les rendez-vous, et plus!",
  "coming-soon": "Prévu pour MVP, pas encore disponible",
  "after-adding-patient-message": "Après avoir ajouté un nouveau patient, veuillez revenir au message d'origine et cliquer à nouveau sur le lien.",
  "section-on-the-roadmap": "{{section}} sur la feuille de route, pas encore disponible",
  "go-to-form": "Aller à la forme",
  "bottle-cap-preference": "Préférences de bouchon de bouteille",
  "allergies-conditions-caregivers": "Allergies, affections, etc.",
  "insurance-id-card": "Assurance et I.D. Cartes",
  "patient-forms": "Formulaires des patients",
  "communication-preferences": "Préférences de communication",
  "sign-out": "Se déconnecter",
  "appointments": "Rendez-vous",
  "add-medications": "Ajouter des médicaments",
  "insurance-and-id-cards": "Assurances et Cartes d'Identité",
  "primary": "Primaire",
  "secondary": "Secondaire",
  "id-card": "Carte d'identité",
  "add-meds-text": "Vous pouvez facilement commander des renouvellements d'ordonnance et gérer les médicaments en les ajoutant maintenant, ou sauter cette étape pour le faire plus tard",
  "prescriptions-at-file": "Les ordonnances sont archivées à {{pharmacyName}}",
  "request-to-transfer": "Demande de transfert d'ordonnances d'une autre pharmacie",
  "skip-this-step": "Passer cette étape",
  "do-this-medications-tab": "Vous pouvez le faire dans l'onglet Médicaments",
  "account": "Compte",
  "your-account": "Votre Compte",
  "upcoming-refill": "Recharge à venir",
  "make-primary": "Rendre primaire",
  "updated": "Mise à jour le {{date}}",
  "send-message": "Envoyer le message",
  "closed": "Fermée",
  "another-account": "Un autre compte",
  "back-to-login": "Retour au login",
  "otc-medications": "Médicaments en vente libre",
  "anonymous-refill": "Recharge anonyme",
  "next": "Suivant",
  "not-yet-entered": "Pas encore entré",
  "revoke": "Révoquer",
  "about-this-app": "À propos de cette application",
  "about-this-app-title": "À Propos de Cette Application",
  "copyright-lumistry": "Droits d'auteur Lumistry",
  "all-rights-reserved": "Tous les droits sont réservés",
  "version": "Version",
  "scan-barcode": "Scanner le code barre",
  "add-card": "Ajouter une carte...",
  "primary-secondary-photo-id": "Primaire, secondaire, photo d'identité",
  "login-attempts-exceeded": "Tentatives de connexion dépassées",
  "max-attempts-exceeded": "Nombre maximal de tentatives dépassé",
  "account-locked-reason": "Votre compte a été verrouillé car vous avez atteint le nombre maximal de tentatives de connexion invalides autorisé.",
  "security-reason": "Pour des raisons de sécurité, veuillez choisir une option ci-dessous pour modifier votre mot de passe et récupérer votre compte.",
  "send-me-email": "Envoie-moi un email",
  "send-me-sms": "Envoyez-moi un SMS",
  "scanned": "Scanné",
  "save": "Enregistrer",
  "meds": "Médicaments",
  "orders": "Ordres",
  "reminders": "Rappels",
  "coming-up": "À venir :",
  "view": "Voir",
  "update-personal-info": "Mettre à jour les informations personnelles",
  "name-phone-email": "Nom, téléphone, email, etc.",
  "legal-guardian-confirmation-for-person": "Vous convenez que vous êtes un parent ou un tuteur légal pour la personne que vous ajoutez",
  "legal-guardian-agreement": "Oui, je suis un parent ou un tuteur légal pour {{firstName}} {{lastName}}.",
  "confirmation": "Confirmation",
  "add-minor-or-pet-patient": "Ajouter un patient mineur ou animal de compagnie",
  "your-allergies": "Allergies",
  "revoke-access-for-authorized-caregiver": "La révocation de l'accès signifie que {{firstName}} {{lastName}} ne fait plus partie de vos soignants autorisés.",
  "confirm-remove-patient": "Supprimer {{firstName}} {{lastName}} de votre liste signifie que vous n'aurez plus accès aux dossiers de {{firstName}} et ne gérerez plus la santé de {{firstName}} numériquement.",  "forgot-password": "Mot de passe oublié?",
  "camera-access-denied": "Impossible d'accéder à la caméra, veuillez accéder aux paramètres de votre application et accorder l'accès à la caméra.",
  "username-is-required": "Nom d'utilisateur est requis",
  "password-is-required": "Mot de passe est requis",
  "view-full-profile": "Voir le profil complet",
  "first-name-is-not-valid": "Le prénom n'est pas valide",
  "last-name-is-not-valid": "Le nom de famille n'est pas valide",
  "insurance-card": "Carte d'assurance",
  "barcode-scanner": "Scanner le code barre",
  "english": "Anglais",
  "spanish": "Espagnol",
  "french": "Français",
  "yes": "Oui",
  "no": "Non",
  "continue": "Continuer",
  "first-name": "Prénom",
  "first-name-is-required": "Prénom est requis",
  "last-name": "Nom de famille",
  "last-name-is-required": "Nom de famille est requis",
  "email-short": "E-mail",
  "delete-insurance": "Supprimer ces informations d'assurance",
  "confirm-delete-insurance": "Êtes-vous sûr de vouloir supprimer ces informations d'assurance",
  "delete-photo-id": "Supprimer cette photo d'identité.",
  "confirm-delete-id": "Êtes-vous sûr de vouloir supprimer cette photo d'identité.",
  "monday": "Lundi",
  "tuesday": "Mardi",
  "wednesday": "Mercredi",
  "thursday": "Jeudi",
  "friday": "Vendredi",
  "saturday": "Samedi",
  "sunday": "Dimanche",
  "email": "Adresse électronique",
  "photo-id-text": "Photo d'identité",
  "no-id-card": "Pas de carte d'identité",
  "email-capital-case": "Dirección de correo electrónico",
  "standard-text-apply": "Des tarifs de messagerie standard peuvent s'appliquer en fonction\non de votre fournisseur de services.",
  "email-is-required": "Email est requis",
  "email-continue": "Continuer avec l'e-mail",
  "mobile-number": "Numéro de téléphone mobile",
  "greeting": "Bonjour",
  "mobile-number-is-required": "Numéro de téléphone mobile est requis",
  "birth-date": "Date de naissance",
  "birth-date-is-required": "La date de naissance est requise",
  "lets-get-started": "Commençons par créer un compte.",
  "front-of-card": "Face de la carte",
  "add-front-of-card": "Face de la carte",
  "caregiver-approval-agreement": "Accord d'approbation de l'aidant",
  "no-known-allergies": "Aucune allergie connue",
  "known-allergies": "Allergies connues",
  "allergies-required": "Veuillez saisir les allergies ou sélectionner \"Aucune allergie connue\"",
  "more-information": "Plus d'information",
  "monthly-prescriptions": "Souhaitez-vous que ces prescriptions soient prêtes à la même heure chaque mois?",
  "as-an-authorized-caregiver": "En tant que soignant autorisé, vous pouvez :",
  "manage-prescriptions-digitally": "Gérez numériquement les ordonnances de {{firstName}}",
  "order-refills": "Commander des recharges pour {{firstName}}",
  "order-refills-prescriptions": "Commander des recharges pour ordonnances",
  "delivery": "Livraison",
  "mail": "Poster",
  "curbside": "Trottoir",
  "relation-to-primary-user": "Relation avec l'utilisateur principal",
  "would-like-easy-open-caps": "Veuillez sélectionner une préférence de bouchon de bouteille.",
  "prefers-easy-open-caps": "Bouchons à ouverture facile (non sécuritaires)",
  "prefers-safety-caps": "Bouchons (de sécurité) à l'épreuve des enfants",
  "basic-info": "Informations de base",
  "pick-up-prescriptions-for": "Procurez-vous des ordonnances pour {{firstName}}",
  "discuss-care-with": "Discutez des soins de {{firstName}} avec un membre de l'équipe de {{pharmacyName}}",
  "manage-health-and-clinic": "Gérer les rendez-vous de santé et de service clinique de {{firstName}}",
  "access-validity-puc": "Cet accès est valide jusqu'à ce que {{firstName}} ait 18 ans ou que vous supprimiez {{firstName}} de votre liste de personnes dont vous avez la charge.",
  "access-validity-puc-pet": "Cet accès est valable jusqu'à ce que vous retirez {{firstName}} de votre liste de patients sous votre garde.",
  "update-puc-list": "Vous pouvez mettre à jour votre liste en accédant à Personnes dont je m'occupe dans les paramètres du compte",
  "patient-record-created": "Dossier patient créé pour {{firstName}} {{lastName}}",
  "back-of-card": "Face arrière de la carte",
  "notification-preference": "Préférences de notification",
  "notification-preferences-details": "Les notifications sont utilisées pour vous informer lorsque votre\n commande d'ordonnance est prête, lorsque vous avez un \nrendez-vous à venir ou lorsque vous recevez un nouveau message de \nvotre équipe pharmaceutique.",
  "sms-text-message": "SMS (message texte)",
  "add-back-of-card": "Face arrière de la carte",
  "cancel": "Annuler",
  "cancelations": "Annulations",
  "retake": "Retourner",
  "use-photo": "Utiliser la photo",
  "password-must-include": "Le mot de passe doit inclure",
  "at-least-8-characters": "au moins 8 caractères",
  "at-least-1-number": "au moins 1 chiffre",
  "at-least-1-capital-letter": "au moins 1 lettre majuscule",
  "at-least-1-lowercase-letter": "au moins 1 lettre minuscule",
  "at-least-1-special-character": "au moins 1 caractère spécial",
  "privacy-policy-error": "Veuillez lire la politique de confidentialité avant d'accepter",
  "privacy-policy-agree": "J'accepte les termes et conditions ci-dessus",
  "privacy-policy-email-signup": "Inscrivez-vous aux courriels de ma pharmacie",
  "pharmacy-privacy-policy": "Politique de confidentialité de la Pharmacie",
  "terms-and-conditions": "Fournisseur de Logiciels Termes & Conditions",
  "terms-of-service": "Termes et conditions",
  "add-patient": "Ajouter un patient",
  "manage-prescriptions": "Gérez vos ordonnances numériquement",
  "pick-up-prescriptions": "Récupérez vos ordonnances",
  "discuss-care": "Discutez de vos soins avec un membre de l'équipe {{pharmacyName}}",
  "manage-health": "Gérez vos rendez-vous de santé et de services cliniques",
  "request-status": "En attente : soumis le {{submittedDate}}",
  "authorized-caregivers": "Aaidants autorisés",
  "terms-of-service-error": "Veuillez lire les conditions d'utilisation avant d'accepter",
  "terms-of-service-agree": "J'accepte les termes et conditions ci-dessus",
  "terms-of-service-email-signup": "Inscrivez-vous aux e-mails",
  "puc-permission-access-to-records": "Je confirme que {{firstName}} {{lastName}} est autorisé à accéder à mes dossiers et à gérer ma santé numériquement et que je peux révoquer l'accès à tout moment.",
  "if-you-authorize-person": "Si vous autorisez cette demande, cette personne peut :",
  "access-validity": "Cet accès est valide jusqu'à ce que vous le révoquiez.",
  "revoke-access": "Revocar acceso",
  "authorized-on": "Autorisé le {{authorizedDate}}",
  "revoke-access-in-settings": "Vous pouvez révoquer l'accès en accédant à vos informations médicales dans les paramètres du compte",
  "review-request": "Demande de révision...",
  "authorize": "Autoriser",
  "decline": "Déclin",
  "and": "et",
  "submit": "Soumettre",
  "2-of-2-agreements": "2 accords sur 2",
  "medical-info-step-3": "Étape 3 sur 8 / Ajoutez vos informations médicales",
  "password-entry-does-not-meet-criteria": "La saisie du mot de passe ne répond pas aux critères",
  "password-entries-do-not-match": "Les entrées de mot de passe ne correspondent pas",
  "create-your-account": "Créez votre compte",
  "please-enter-your-new-password": "Veuillez entrer votre nouveau mot de passe",
  "email-is-not-valid": "Email n'est pas valide",
  "phone-is-not-valid": "Le téléphone n'est pas valide",
  "create-account": "Créer un compte",
  "confirm-password": "Confirmer le mot de passe",
  "patient-info-step": "Étape x sur y / Confirmez vos informations",
  "communications-language": "Langue de communication",
  "prescriptions-communications": "Communications sur les ordonnances: comment souhaitez-vous être averti lorsque vos ordonnances sont prêtes?",
  "automated-voice-call": "Appel vocal automatisé",
  "text-message": "Message texte",
  "app-notifications": "Notifications d'application",
  "all-other-pharmacy-communications": "Toutes les autres communications de la pharmacie",
  "same-time-preferences": "Souhaitez-vous que ces prescriptions soient prêtes à la même heure chaque mois?",
  "additional-information": "Informations Complémentaires",
  "visually-impaired": "Malvoyant",
  "hearing-impaired": "Malentendant",
  "easy-open-bottle-caps": "Bouchons de bouteilles faciles à ouvrir",
  "personal-info-step-2": "Étape 2 sur 7 / Ajoutez vos informations personnelles",
  "country": "Pays",
  "country-is-required": "Pays est requis",
  "state": "État",
  "state-is-required": "État est requis",
  "city": "Ville",
  "city-is-required": "Ville est requise",
  "street-address": "Adresse",
  "street-address-is-required": "Adresse est requise",
  "street-address-line-2": "Adresse ligne 2",
  "zip-code": "Code postal",
  "zip-code-is-required": "Code postal est requis",
  "gender-is-required": "Le sexe est requis",
  "male": "Mâle",
  "female": "Femelle",
  "verification-code-resent": "Code de vérification renvoyé",
  "please-enter-code": "Veuillez saisir le code que nous avons envoyé par e-mail",
  "didnt-receive-email": "Vous n'avez pas reçu l'e-mail?",
  "please-check-spam-folder": "Veuillez vérifier votre dossier spam ou renvoyer le code",
  "didnt-receive-text-message": "Vous n'avez pas reçu le SMS ?",
  "phone-number-is-sms-capable": "Veuillez vous assurer que le numéro de téléphone que vous avez saisi est compatible avec les SMS ou renvoyez le code.",
  "email-address-is-correct": "Veuillez vous assurer que l'adresse e-mail\nque vous avez saisie est correcte ou renvoyez le code.",
  "standard-text-messages-apply": "Des tarifs de messagerie texte standard peuvent s'appliquer selon votre fournisseur de services.",
  "resend-code": "Renvoyer le code",
  "reset-password": "Réinitialiser le mot de passe",
  "reset-link-sent": "Si l'e-mail est associé à un compte actif, vous devriez recevoir un lien de réinitialisation.",
  "please-enter-email": "Veuillez saisir l'adresse e-mail associée à votre compte",
  "how-would-you-like-to-receive-reset-link": "Comment souhaitez-vous recevoir le lien de réinitialisation?",
  "using-the-phone-number-on-file": "En utilisant le numéro de téléphone enregistré",
  "send-reset-link": "Envoyer le lien de réinitialisation",
  "create-new-password": "Créer un nouveau mot de passe",
  "confirm-new-password": "Confirmer le mot de passe",
  "new-password": "Nouveau mot de passe",
  "for-security-reasons": "Pour des raisons de sécurité, votre mot de passe expire tous les 90 jours, veuillez mettre à jour votre mot de passe",
  "update-password": "Mettre à jour le mot de passe",
  "password-expiring-soon": "Le mot de passe expire bientôt",
  "password-expire-in-7-days": "Votre mot de passe expirera dans 7 jours",
  "update-password-now": "Voulez-vous mettre à jour votre mot de passe maintenant ?",
  "confirm-update-password": "Confirmez le nouveau mot de passe",
  "go-to-login": "Retour au login",
  "return-to-login": "Retour au login",
  "apple-signup": "S'inscrire auprès d'Apple",
  "apple-continue": "Continuer avec Apple",
  "apple-login": "Connexion Apple",
  "change-password": "Changer le mot de passe",
  "current-password": "Mot de passe actuel",
  "enter-existing-password": "Entrez votre mot de passe existant et le nouveau mot de passe ci-dessous.",
  "login-with-email": "Connexion avec l'email",
  "login-enter-email": "Insérer email",
  "no-photo": "Pas de photo",
  "passwords-must-match": "Les mots de passe ne correspondent pas",
  "password-incorrect": "Mot de passe incorrect",
  "dismiss": "Rejeter",
  "load-more": "Charger plus",
  "edit-notes": "Modifier les notes",
  "loading": "Chargement",
  "open-map": "Ouvrir la carte",
  "input-field": "Champ d'entrée",
  "text-field": "Champ de texte",
  "show-hide-password": "Afficher/masquer le mot de passe",
  "search": "Rechercher",
  "select": "Sélectionner",
  "use": "Utiliser",
  "no-options": "Aucune option",
  "email-or-password-incorrect": "E-mail ou mot de passe incorrect. Veuillez réessayer.",
  "unable-to-complete-registration": "Impossible de terminer l'inscription. Veuillez réessayer.",
  "unable-to-update-account": "Impossible de mettre à jour le compte. Veuillez réessayer.",
  "unable-to-complete-verification": "Impossible de terminer la vérification. Veuillez réessayer.",
  "unable-to-resend-verification-code": "Impossible de renvoyer le code de vérification. Veuillez réessayer.",
  "error-submitting": "Erreur lors de l'envoi. Veuillez réessayer.",
  "unable-to-send-reset-link": "Impossible d'envoyer le lien de réinitialisation. Veuillez réessayer.",
  "unable-to-change-password": "Impossible de modifier le mot de passe.Veuillez réessayer.",
  "store-selector-change": "Changement",
  "store-selector-search-address": "Code postal",
  "store-selector-change-store": "Changement butique",
  "store-selector-your-store": "Votre magasin",
  "store-selector-nearby-store": "Magasins à proximité",
  "store-selector-close": "Fermer",
  "store-selector-select": "Choisir",
  "store-selector-no-stores-error": "Impossible de trouver l'emplacement de la pharmacie",
  "feedback": "Avis",
  "feedback-give-feedback": "Donnez votre avis",
  "feedback-give-your-feedback": "Donnez votre avis",
  "feedback-please-enter-feedback": "Veuillez entrer votre avis",
  "feedback-how-to-improve-app": "How can we improve our app?",
  "feedback-give-feedback-about-pharmacy": "Give us feedback about our pharmacy",
  "feedback-sent": "Votre avis a été envoyé!",
  "feedback-thank-you": "Merci pour votre avis!",
  "feedback-what-type-of-feedback": "Quel type de commentaires laissez-vous aujourd'hui?",
  "feedback-target-is-required": "La cible de rétroaction est requise.",
  "feedback-feedback": "Entrez des informations supplémentaires",
  "feedback-feedback-is-required": "Des informations de commentaires sont requises.",
  "feedback-allowContact": "J'accepte que vous me contactiez pour plus de précisions.",
  "feedback-thanks-message": "Merci pour vos commentaires!",
  "feedback-reach-you-message": "Nous vous contacterons directement si nous avons des questions.",
  "feedback-back-to-profile": "Retour au profil",
  "feedback-error-occurred": "Une erreur est survenue.",
  "feedback-contact-info": "Informations de contact",
  "feedback-edit": "Éditer",
  "happy-birthday": "Joyeux anniversaire",
  "insurance-card-update": "Mise à jour de la carte d'assurance",
  "flu-shot-reminder": "Rappel du vaccin contre la grippe",
  "spring-promotions": "Promotions du printemps",
  "summer-promotions": "Promotions d'été",
  "fall-promotions": "Promotions d'automne",
  "thanksgiving": "Action de grâces",
  "seasons-greeting": "Vœux de saison",
  "happy-new-year": "Bonne année !",
  "happy-birthday-description": "Développer une relation personnelle et fidéliser la clientèle",
  "insurance-card-update-description": "Inviter les patients à fournir des informations d'assurance mises à jour",
  "flu-shot-reminder-description": "Rappelez aux patients les vaccins contre la grippe et invitez-les à prendre rendez-vous et à générer des revenus",
  "spring-promotions-description": "Faites la promotion des produits et services saisonniers que votre pharmacie a à offrir pendant la saison printanière, par exemple le soulagement des allergies",
  "summer-promotions-description": "Faites la promotion des produits et services saisonniers que votre pharmacie a à offrir pendant la saison estivale, par exemple, les produits UV, les insectifuges, le soulagement des piqûres d'insectes",
  "fall-promotions-description": "Faites la promotion des produits et services saisonniers que votre pharmacie a à offrir pendant la saison d'automne, par exemple, le soulagement du rhume et de la toux",
  "thanksgiving-description": "Développer une relation personnelle et fidéliser la clientèle",
  "seasons-greeting-description": "Développer une relation personnelle et fidéliser la clientèle",
  "happy-new-year-description": "Développer une relation personnelle et fidéliser la clientèle",
  "happy-birthday-message-subject": "Salutations de votre pharmacie locale !",
  "insurance-card-update-message-subject": "Carte d'assurance",
  "flu-shot-reminder-message-subject": "Vaccinations",
  "spring-promotions-message-subject": "Salutations de votre pharmacie locale !",
  "summer-promotions-message-subject": "Bonjour de votre pharmacie locale !",
  "fall-promotions-message-subject": "Bonjour de votre pharmacie locale !",
  "thanksgiving-message-subject": "Salutations de votre pharmacie locale !",
  "seasons-greeting-message-subject": "Salutations de votre pharmacie locale !",
  "happy-new-year-message-subject": "Salutations de votre pharmacie locale !",
  "happy-birthday-message-body": "Bonjour {{firstName}}, De la part de nous tous ici chez {{pharmacyName}}, nous vous souhaitons un très joyeux anniversaire ! Faites-nous savoir si nous pouvons faire quelque chose pour y parvenir. mieux!",
  "insurance-card-update-message-body": "Nous souhaitons nous assurer que nous disposons de vos informations les plus à jour. Si vous avez une nouvelle carte d'assurance, veuillez accéder aux paramètres du compte pour télécharger votre assurance la plus récente. informations. Merci!",
  "flu-shot-reminder-message-body": "La saison de la grippe est à nos portes et nous commençons à planifier les vaccinations contre la grippe. Utilisez notre application pour prendre rendez-vous dès aujourd'hui !",
  "spring-promotions-message-body": "Ne laissez pas vos allergies saisonnières vous empêcher de profiter du printemps ! Passez à la pharmacie aujourd'hui pour parcourir nos options de soulagement des allergies en vente libre, ou envoyez-nous un message via l'application si vous avez des questions. .",
  "summer-promotions-message-body": "Un temps plus chaud peut signifier plus de plaisir au soleil, mais cela signifie également plus d'insectes embêtants ou de coups de soleil. Rendez-vous à la pharmacie aujourd'hui pour faire le plein d'insectifuges et de crème solaire afin de garantir que vous et votre vos proches restent en sécurité tout en s'amusant au soleil !",
  "fall-promotions-message-body": "Le changement de temps vous fait tousser et vous sentir étouffé ? Passez à la pharmacie aujourd'hui pour découvrir nos médicaments en vente libre contre la toux et le rhume, ou envoyez-nous un message via l'application si vous avez des questions!",
  "thanksgiving-message-body": "C'est cette période de l'année où nous nous réunissons pour compter et réfléchir à nos bénédictions. Vous êtes l'une des nombreuses choses pour lesquelles nous sommes profondément reconnaissants. Merci de nous avoir choisis pour prendre soin de vous et de vos proches. mes proches. Je vous souhaite, à vous et aux vôtres, un joyeux Thanksgiving plein de joie et de bienveillance !",
  "seasons-greeting-message-body": "Je vous envoie la joie des fêtes et vous souhaite d'excellentes fêtes de fin d'année ! Profitez du temps agréable, de la bonne compagnie et de nombreux plats délicieux !",
  "happy-new-year-message-body": "Notre pharmacie vous souhaite une bonne année ! Au bonheur et à la santé pour vous et vos proches !",
  "when-to-send-every-year": "Quand envoyer chaque année",
  "configure-label-secure": "Configurer {{label}} (Sécurisé)",
  "feedback-email-address": "Adresse e-mail",
  "feedback-phone-number": "Numéro de téléphone",
  "feedback-app": "App",
  "feedback-location": "Boutique",
  "phone-number-footer": "téléphone: {{phone}}",
  "fax": "fax: {{fax}}",
  "digital-pharmacist": "Digital Pharmacist",
  "pharmacy-website": "Site Web et application mobile de la pharmacie fournis par",
  "refill-card-medication": "Médicament",
  "automated-messages": "Messages automatisés",
  "refill-card-due-date": "Recharge due",
  "prescription-find-server-error": "Erreur interne du serveur lors de la recherche de vos médicaments",
  "prescription-card-day-supply": "approvisionnement journalier",
  "link-will-be-sent": "Le lien sera envoyé au numéro de téléphone enregistré",
  "prescription-card-filled-on": "Rempli le",
  "prescription-card-refills": "Recharges restantes",
  "prescription-card-dob": "DOB",
  "medications-fill-your-prescription": "Remplir une ordonnance",
  "medications-ready-to-be-filled": "Prêt à être rempli",
  "medications-other-upcoming-refills": "Autres recharges à venir",
  "insurance-card-upload-error": "Impossible de télécharger la carte. Veuillez réessayer.",
  "camera-mask-label": "Centre la carte à l'intérieur du cadre",
  "primary-insurance-add-your-information": "Ajouter une carte d'assurance primaire",
  "secondary-insurance-add-your-information": "Ajouter une carte d'assurance secondaire",
  "photoId": "Ajouter une photo I.D.",
  "photo-take-card-picture": "Prenez une photo ou téléchargez une image de la face avant de la photo émise par le gouvernement I.D.carte",
  "insurance-take-card-picture": "Prenez une photo ou téléchargez une image des deux côtés de la carte d'assurance",
  "take-photo": "Prendre une photo",
  "choose-photo": "Choisir une photo",
  "insurance-bring-to-store": "je vais l'amener au magasin",
  "google-signup": "S'inscrire avec Google",
  "google-continue": "Continuer avec Google",
  "search-by-patient-details": "Rechercher par nom ou date de naissance",
  "google-login": "Connexion Google",
  "google-login-failed": "Échec de la connexion Google",
  "date-is-not-valid": "La date n'est pas valide",
  "patient-stores": "Trouvez votre magasin",
  "verify-your-email": "Vérifiez votre adresse e-mail",
  "verify-your-phone": "Vérifiez votre numéro de téléphone",
  "please-enter-your-information": "Veuillez entrer vos informations",
  "email-address": "Adresse e-mail",
  "no-patients-yet": "Aucun patient pour l'instant...",
  "integrate-pms-to-see-patients": "Intégrez-vous à votre PMS pour voir les patients ici.",
  "email-is-invalid": "L'e-mail est invalide",
  "passwords-do-not-match": "Les entrées de mot de passe ne correspondent pas",
  "password-does-not-meet-criteria": "La saisie du mot de passe ne répond pas aux critères",
  "follow-reset-password-instructions": "Veuillez suivre les instructions pour réinitialiser votre mot de passe.",
  "pharmacy-reset-link-sent": "Un courriel a été envoyé à ",
  "need-help": "Besoin d'aide?",
  "forms": "Formulaires",
  "tasks": "Tâches",
  "welcome-to-tasks": "Bienvenue dans Tâches",
  "create-new-task": "Créez une tâche pour commencer.",
  "new-task-button": "Nouvelle Tâche",
  "delegate-work-among-employees": "Déléguer le travail aux salariés",
  "communicate-a-sync-with-coworkers": "Communiquez de manière synchronisée avec vos collègues",
  "track-week-month-quarter-year-tasks": "Suivez les tâches hebdomadaires, mensuelles, trimestrielles et annuelles",
  "example-task-check-shelves": "Exemple de tâche : \"Vérifier les étagères pour les médicaments périmés\"",
  "welcome-to-services": "Bienvenue dans la configuration du service",
  "setup-bookable-service": "Configurez des services réservables pour commencer.",
  "new-service-button": "Nouveau service",
  "patients-book-via-mobile": "Les patients réservent via une application mobile ou un portail Web",
  "pharmacy-staff-can-also-book": "Le personnel de la pharmacie peut également réserver pour les patients",
  "use-pharmacy-hours-custom-availability": "Utilisez les heures d'ouverture de la pharmacie ou définissez une disponibilité personnalisée",
  "see-upcoming-appointments": "Voir les rendez-vous à venir sur le tableau de bord",
  "change-availability": "Modifier la disponibilité",
  "book-first-appointment": "Réservez votre premier rendez-vous",
  "new-appointment-button": "Nouveau rendez-vous",
  "no-upcoming-appointment": "Aucun rendez-vous à venir",
  "schedule-an-appointment": "Planifiez un rendez-vous pour un patient.",
  "patients": "Les patients",
  "refill-submissions": "Recharge",
  "refill-submissions-header": "Soumissions de recharge",
  "services": "Prestations de service",
  "same-mailing-address": "Pareil que l'adresse postale",
  "user": "Utilisateur",
  "users": "Utilisateurs",
  "new-user": "Nouvel utilisateur",
  "user-details": "Détails de l'utilisateur",
  "title": "Titre",
  "role": "Rôle",
  "days": "Jours",
  "show-grid": "Afficher sur la grille",
  "task-appear-grid": "La tâche apparaîtra sur la grille et la liste des vues.",
  "last-active": "Dernière activité",
  "new-task": "Nouvelle tâche",
  "summary": "Résumé",
  "unknown": "Inconnue",
  "year": "année",
  "month": "mois",
  "day": "jour",
  "week": "semaine",
  "apply-to-all": "S'applique à tous",
  "all-statuses": "Tous les statuts",
  "all-priorities": "Toutes les priorités",
  "all-visibilities": "Tous les visibilités",
  "all-types": "Tous les types",
  "person": "Personne",
  "subtitle": "Sous-titre",
  "edit-title": "Modifier le titre ",
  "edit-subtitle": "Modifier le sous-titre",
  "edit-checkbox-label": "Modifier l'étiquette de la case à cocher",
  "maximum-input-value": "Valeur d'entrée maximale",
  "set-to-default": "Définir par défaut",
  "availability-name": "Nom de disponibilité",
  "name-is-required": "Le nom est requis",
  "display-name": "Afficher un nom",
  "length": "Longueur",
  "account-holder": "Titulaire de compte",
  "restriction": "restrictions",
  "monday-abbreviation": "Lun",
  "tuesday-abbreviation": "Mar",
  "wednesday-abbreviation": "Mir",
  "thursday-abbreviation": "Jeu",
  "friday-abbreviation": "Ven",
  "saturday-abbreviation": "Sam",
  "sunday-abbreviation": "Dim",
  "frequency": "Fréquence",
  "end-date": "Date de fin",
  "sort-by-due-date": "Trier par date d'échéance",
  "sort-by-assignee": "Trier par cessionnaire",
  "sort-by-task-summary": "Trier par résumé des tâches",
  "ascending": "Ascendante",
  "descending": "Descendante",
  "arabic": "Arabe",
  "chinese": "Chinoise",
  "vietnamese": "Vietnamienne",
  "korean": "Coréenne",
  "german": "Allemande",
  "russian": "Russe",
  "fullname": "Nom et prénom",
  "signature": "signature",
  "type-your-question": "Taper une question",
  "select-single-option": "Veuillez sélectionner une seule option",
  "select-multiple-options": "Veuillez sélectionner plusieurs options",
  "please-upload-image": "Veuillez télécharger une image",
  "number": "Nombre",
  "medical-conditions-without-colon": "Les conditions médicales",
  "no-known-medical-conditions": "Aucune Condition Médicale Connue",
  "medical-conditions-non-capitalized": "Les conditions médicales",
  "please-select": "Veuillez sélectionner",
  "every-week": "Toutes les semaines",
  "weeks": "semaines",
  "every": "Toutes les",
  "months": "mois",
  "last-day": "Dernier jour",
  "enter-valid-phone-number": "s'il vous plaît entrer un numéro de téléphone valide.",
  "custom-domain": "Domaine personnalisé (facultatif)",
  "service-required": "Le service est requis",
  "required-field": "champs requis",
  "edit-form-title": "Modifier le titre du formulaire",
  "date-overrides": "Date Overrides",
  "description": "Description",
  "summary-is-required": "Un résumé est requis",
  "recurrence": "Récurrence",
  "one-time": "Une fois",
  "weekly": "Hebdomadaire",
  "monthly": "Mensuelle",
  "start-date": "Date de début",
  "high": "Haut",
  "low": "Faible",
  "medium": "Moyen",
  "assigned-to": "Assigné à",
  "nobody": "Personne",
  "created-by": "Créé par",
  "system-user": "Utilisateur du système",
  "task-details": "Détails de la tâche",
  "created": "Créé",
  "updated-without-date": "Mis à jour",
  "no-task": "Aucune tâche à montrer",
  "disable-filters": "Veuillez désactiver certains filtres ou détendre votre requête de recherche.",
  "no-task-disable-filters": "Aucune tâche à afficher, veuillez désactiver certains filtres ou assouplir votre requête de recherche.",
  "tasks-grid": "Grille de tâches",
  "search-submissions": "Recherchez les soumissions",
  "create-refill": "Créer une recharge",
  "appointment-filters": "Filtres de rendez-vous",
  "import-form": "Saisir",
  "make-default": "Faire défaut",
  "default-availability": "Disponibilité par défaut",
  "edit-date-override": "Modifier la date de remplacement",
  "no-services-booking": "Pas de services",
  "task-types": "Types de tâches",
  "characters": "personnages",
  "task-type-description-required": "Le type de tâche Description est requise",
  "task-type-description-exceeded": "La description ne doit pas dépasser 130 caractères",
  "delete-task-type-confirm": "En confirmant que vous supprimerez le type de tâche sélectionné.",
  "user-roles": "Rôles des utilisateurs",
  "task-filters": "Filtres",
  "error": "Erreur",
  "options": "options",
  "add-new-option": "Ajouter une nouvelle option",
  "edit-columns": "Modifier les colonnes",
  "add-new-column": "Ajouter une nouvelle colonne",
  "import": "Import",
  "grid": "Grille",
  "browse": "Parcourir",
  "task-type-required": "Le titre de type de tâche est requis",
  "task-type-title-exceeded": "Le titre ne doit pas dépasser 30 caractères",
  "form-imported-text": "Ce formulaire a été importé et il dispose d'une fonctionnalité qui n'est pas actuellement disponible.Vous pouvez toujours prévisualiser le formulaire, mais vous ne pouvez pas y apporter de modifications.",
  "form-not-imported-text": "Ce formulaire contient une fonctionnalité qui n'est pas actuellement prise en charge.Vous pouvez toujours prévisualiser le formulaire, mais vous ne pouvez pas y apporter de modifications.",
  "no-availability": "Pas de disponibilité",
  "new-availability": "Nouvelle disponibilité",
  "submission": "Soumission",
  "no-submission-found": "Aucune soumission trouvée",
  "no-form-templates": "Aucun modèle de formulaire disponible.",
  "lose-data-creating-form": "En confirmant que vous perdrez les progrès actuels sur la création d'un nouveau formulaire à partir d'un modèle.",
  "completed": "Complété",
  "due-date": "Date d'échéance",
  "are-you-sure-to-delete-task": "Êtes-vous sûr de vouloir supprimer la tâche",
  "are-you-sure-to-resolve-task": "Êtes-vous sûr de vouloir résoudre la tâche",
  "data": "Données",
  "changed-value": "Valeur modifiée",
  "easy-open-bottle-caps-field": "Bouchons de bouteille à ouverture facile",
  "med-sync-field": "Synchronisation des médicaments",
  "create-first-task": "Créez votre première tâche",
  "visibility-personal-policy": "Il sera consulté par vous et le cessionnaire (si elle est affectée)",
  "visibility-team-policy": "Il sera vu par tout le monde",
  "start-date-required": "La date de début est requise",
  "registered": "Inscrit",
  "activity": "Activité",
  "show": "Montrer",
  "view-activity-logs": "Afficher les journaux d'activité",
  "resend-invite": "Renvoyer l'invitation",
  "error-sending-invite": "Une erreur s'est produite lors de l'envoi de l'invitation",
  "delete": "Supprimer",
  "delete-confirmation": "Êtes-vous sûr de vouloir supprimer cet?",
  "cannot-undo-action": "Vous ne pouvez pas annuler cette action.",
  "set-your-preferences": "Marcher {{currentStep}} de {{totalSteps}} / Définissez vos préférences",
  "find-your-store": "Trouvez votre magasin",
  "confirm-your-store": "Marcher {{currentStep}} de {{totalSteps}} / Confirmez votre magasin",
  "done": "Fait",
  "do-this-later": "Faire ça plus tard",
  "entered-prescription-manually": "Entrée manuellement",
  "your-prescriptions": "Ordonnances",
  "add": "Ajouter",
  "drug-name": "Nom du médicament",
  "drug-name-is-required": "Le nom du médicament est requis",
  "step": "Etape",
  "rx-number-is-required": "Le numéro Rx est requis",
  "rx-number-is-duplicated": "Le numéro Rx est déjà présent",
  "change": "Modifier",
  "location": "Emplacement",
  "locations": "Emplacements",
  "patient-dob": "DDN du patient (jj/mm/aaaa)",
  "patient-dob-is-required": "La date de naissance est obligatoire",
  "patient-last-name-is-required": "Le nom de famille du patient est requis",
  "patient-last-name": "Nom du patient",
  "we-found-your-medications-alert-title": "Liste des médicaments trouvés.",
  "unable-to-locate-your-prescription-alert-title": "Impossible de localiser la liste des ordonnances.",
  "unable-to-locate-your-prescription-alert-description": "Veuillez confirmer que les informations ci-dessous correspondent à ce qui se trouve sur l'étiquette de prescription.",
  "prescription-information": "Informations sur la prescription",
  "integrated-prescription-flow-description": "Entrez tout numéro de prescription récent pour connecter la liste de prescription avec l'emplacement de la pharmacie sélectionnée.",
  "prescribed-for": "Prescrit pour",
  "prescriber": "Prescrit",
  "prescriber-info-not-available": "Informations non disponibles",
  "prescriber-name-not-available": "(Nom non disponible)",
  "prescriber-phone-not-available": "(Téléphone non disponible)",
  "rx-number": "Numéro de réception",
  "last-filled-on": "Dernier rempli le",
  "last-ordered-on": "Dernière commande le",
  "auto-refills": "Recharges automatiques",
  "auto-refill": "Recharge automatique",
  "auto-refill-not-eligible" : "Not eligible",
  "auto-refill-requested" : "Demandé",
  "auto-refill-patient-requested" : "Patient demandé",
  "request-auto-refill" : "Demander une recharge automatique",
  "refills-left": "Recharges restantes",
  "quantity-dispensed": "Quantité distribuée",
  "days-supply": "Jours d'approvisionnement",
  "directions": "Les directions",
  "questions-about-medication": "Questions sur ce médicament?",
  "ask-your-pharmacist": "Demandez à votre pharmacien",
  "message": "Message",
  "refill": "Recharge",
  "child": "Enfant",
  "spouse": "Conjoint",
  "parent": "Parent",
  "grandparent": "Grand-parent",
  "grandchild": "Petit enfant",
  "pet": "Animaux",
  "partner": "Partenaire",
  "other": "Autre personne sous ma garde",
  "resources": "RESSOURCES",
  "medication-guides": "Guides des Médicaments",
  "how-to-take": "Comment prendre ce médicament",
  "patient-education-sheets": "Feuilles d'éducation des Patients",
  "side-effects-interactions": "Effets secondaires, interactions médicamenteuses",
  "prescription-written": "Date de Rx",
  "instructions": "instructions",
  "placeholder-step": "Pas d'espace réservé",
  "instructions-api": "L'accès à un compte Jotform existant dans Lumistry nécessite une clé API trouvée dans les paramètres JotForm.",
  "pdf-instructions": "Le .pdf ci-dessous contient un court guide étape par étape pour obtenir cette clé.",
  "open-pdf": "Ouvrir le PDF avec des instructions",
  "not-implemented": "Pas encore implémenté",
  "prescription-by": "Ordonnance par",
  "name-not-available": 'Nom non disponible',
  "new-form": 'Nouvelle forme',
  "call-prescriber": "Prescripteur: ",
  "import-forms": "Importer des formulaires",
  "form": "Formulaire",
  "import-plan": "Plan d'importation",
  "number-of-forms": "Nombre de formulaires à importer",
  "link-expired": "Le lien a expiré ou est invalide, vous en aurez besoin d'un nouveau.",
  "reset-unsuccessful": "Échec de la réinitialisation",
  "order-submitted": "Votre commande est validée !",
  "order": "Commande",
  "picking-up-at": "Prise en charge à",
  "ready-today": "Prêt aujourd'hui entre",
  "hi": "Salut, {{firstName}}.",
  "create-your-password": "Veuillez créer votre mot de passe",
  "i-have-read-and-agree": "J'ai lu et accepté les",
  "pharmacy-terms-and-conditions": "Termes et Conditions",
  "privacy-policy": "Déclaration de confidentialité",
  "appointments-calendar": "Calendrier des rendez-vous",
  "terms-and-conditions-required": "Un accord sur les termes et conditions est requis",
  "privacy-policy-required": "L'accord de politique de confidentialité est requis",
  "at-least-one-selected": "Au moins une option doit être sélectionnée",
  'click-sections-above': "Cliquez sur le bouton Précédent pour consulter vos informations. Vous pourrez toujours apporter des modifications ultérieurement dans les paramètres. Cliquez sur “Soumettre“ pour lancer votre pharmacie!",
  "number-of-locations": "Número de ubicaciones",
  "pharmacy-taking-off": "Votre pharmacie prend son envol!",
  "next-steps": "Prochaines Etapes",
  "complete-new-account": "Remplissez la liste de contrôle du nouveau compte.",
  "employess-to-be-notified": "Les employés seront informés de s'inscrire.",
  "monitor-progress": "Suivez quotidiennement les progrès de votre équipe.",
  "check-progress": "Vérifiez l'avancement...",
  "go-to-dashboard": "Aller au tableau de Bord",
  "agreements": "Les accords",
  "equipment": "Équipement",
  "employees": "Employés",
  "addresses": "Adresses",
  "ein": "EIN",
  "store-address": "Adresse du magasin",
  "lumistry-setup": "Configuration Lumistry",
  "welcome-lumistry-setup": "Bienvenue dans la configuration Lumistry! Dans ce petit guide, il vous sera demandé des informations de haut niveau sur votre pharmacie, notamment:",
  "account-info": "Informations de compte",
  "location-info": "Informations de localisation",
  "ob-all-hours-desc": "Ces heures seront appliquées à tous les emplacements de la liste.",
  "confirm-information-collected": "Confirmer les informations collectées dans le processus de vente",
  "location-billing-account": "Localisation, facturation et compte",
  "require-additional-hardware": "Certains systèmes de gestion de pharmacie nécessitent un matériel supplémentaire.",
  "add-your-employees": "Ajoutez vos employés",
  "set-hours-timezone-holidays": "Définir les heures d'ouverture du magasin, le fuseau horaire et les jours fériés",
  "learn-about-employee-roles": "En savoir plus sur les rôles des employés",
  "lumistry-roles": "Rôles Lumistry",
  "employee-role-determines-action": "Le rôle d’un employé détermine les actions qu’il est autorisé à entreprendre.",
  "handles-organization-permissions": "Gère toutes les autorisations administratives de l'organisation (par exemple, propriétaire de pharmacie)",
  "location-level-leader": "Leader et décideur au niveau local. Le personnel peut avoir besoin de l'approbation du responsable pour accéder à certaines fonctionnalités de la plateforme.",
  "common-level-of-access": "Niveau d'accès le plus courant. Peut comprendre un pharmacien, un technicien, un pharmacien stagiaire, un caissier, etc.",

  "new-patient": "Nouveau  patient",
  "i-am-a-new-patient": "Je suis un nouveau patient",
  "patient-details": "Détails du patient",
  "edit": "Modifier",
  "date-of-birth": "Date de naissance",
  "date-of-birth-short": "DDN",
  "phone-number": "Numéro de téléphone",
  "edit-phone-number": "Numéro de Téléphone",
  "edit-mobile-number": "Numéro de mobile",
  "retrieving": "Récupération",
  "gender-text": "Genre",
  "language": "Langue",
  "conditions": "Conditions",
  "easy-open-bottle": "Bouteille facile à ouvrir",
  "med-sync": "Synchronisation Médicale",
  "photo-id": "Carte d'identité",
  "add-override": "Ajouter la remplacement",
  "new-appointment": "Nouveau Rendez-vous",
  "edit-appointment": "Modifier la Nomination",
  "download": "Télécharger",
  "chat": "Chatte",
  "photo": "Photo",
  "delete-image": "La suppression de cette image supprimera les informations de cette carte",
  "image": "Image",
  "notification": "Notification",
  "app-notification": "Notification de l'application",
  "list": "Liste",
  "providers": "Fournisseurs",
  "notes": "Notes",
  "internal-use-only": "Utilisation interne uniquement",
  "no-upcoming-appointments": "Aucun rendez-vous à venir",
  "no-refills-due": "Aucune recharge due",
  "to-get-started-choose": "Pour commencer,\nveuillez choisir l'une des options suivantes:",
  "face-id": "Identification faciale",
  "continuation-disclaimer": "En appuyant sur Continuer avec Google ou E-mail, vous acceptez cette Pharmacie",
  "medical-equipment-immunization": "Immunisations, équipement médical durable, préparation, consultation des patients, vaccin COVID-19",
  "services-offered": "Services offerts",
  "en-es-fr": "Anglais, Espagnol, Français",
  "languages-spoken": "Langues parlées à cet endroit",
  "holiday-hours-vary": "Les horaires des vacances peuvent varier.",
  "open-hours": "Ouvert aujourd'hui de: {{hours}}",
  "open-today": "Ouvert aujourd'hui 24h/24.",
  "store-hours": "Heures d'ouverture",
  "set-my-store": "Définir comme ma boutique",
  "upload-csv": "Télécharger csv",
  "personal-info": "Informations personnelles",
  "will-pickup-prescription-today": "Allez-vous récupérer votre ordonnance aujourd'hui?",
  "field-required": "Ce champ est obligatoire",
  "will-pickup-prescription-urgently": "Avez-vous besoin de retirer votre ordonnance de toute urgence?",
  "order-details": "Détails de la commande",
  "method": "Méthode",
  "note-for-pharmacy": "Note pour la pharmacie (facultatif)",
  "note-for-pharmacy-not-optional": "Note pour la pharmacie",
  "review": "Examen",
  "insurance-is-required": "Une assurance est requise",
  "insurance-change-last-refill": "L'assurance a-t-elle changé depuis la dernière recharge?",
  "prescriptions-is-required": "Une ordonnance est requise",
  "over-the-counter-medications": "Médicaments en vente libre",
  "please-review-messages-and-proceed": "Veuillez lire les messages ci-dessous et faites-nous savoir comment vous souhaitez procéder.",
  "how-to-proceed": "Comment procéder",
  "enter-patient-email": "Saisissez l'adresse e-mail du patient",
  "add-patient-request-submit": "Ajouter une demande de patient soumise",
  "enter-patient-information": "Saisir les informations du patient",
  "provide-an-email": "Veuillez fournir l'adresse e-mail que {{firstName}} {{lastName}} utilise pour le compte de {{firstName}} avec {{pharmacyName}}",
  "request-sent": "Demande envoyée à {{firstName}} {{lastName}}",
  "approval-needed": "L'approbation est nécessaire de cette personne dans les 14 jours",
  "person-has-an-account": "Si cette personne a un compte chez {{pharmacyName}}, elle recevra votre demande",
  "add-another-patient": "Ajouter une autre patiente",
  "add-another": "Ajouter un autre",
  "go-home": "Rentrer chez soi",
  "allow-pharmacy-contact-prescriber": "Autoriser la pharmacie à contacter le prescripteur",
  "continue-with-selected-items": "Continuer avec les éléments sélectionnés",
  "go-back": "Revenir en arrière",
  "go-back-update-order": "Revenir en arrière pour mettre à jour ma commande",
  "thank-you-for-choosing": "Merci d'avoir choisi",
  "return-to-order-details": "Return to order details",
  "add-people-under-care": "Ajouter des personnes dont vous avez la charge",
  "add-person": "Ajouter une personne",
  "unable-to-process-items": "Impossible de traiter un ou plusieurs éléments",
  "cannot-process-items": "Les éléments suivants ne peuvent pas être traités et seront supprimés de votre commande",
  "one-or-more-items-to-review" : "Un ou plusieurs éléments à examiner",
  "return-to-medications": "Retour aux médicaments",
  "no-item-left-in-order-cancel": "Il vous reste un article dans votre commande. Le supprimer annulera votre commande.",
  "no-item-left-in-order-cancelled": "Il ne vous reste plus aucun article dans votre commande. Votre commande sera annulée.",
  "we-will-process-following-items": "Nous procéderons au traitement des articles suivants, sauf si vous les supprimez de votre commande",
  "we-will-contact-prescriber": "Nous contacterons votre prescripteur pour autorisation sur les prescriptions suivantes. ",
  "please-review-the-messages-below": "Veuillez lire les messages ci-dessous et faites-nous savoir comment vous souhaitez procéder.",
  "ready-to-be-filled": "Prêt à remplir",
  "other-details": "Autres détails",
  "store-info": "Informations sur la boutique",
  "account-setup": "Configuration du compte",
  "close": "Fermer",
  "no-notes-attached-to-patient": "Aucune note n'est jointe à ce patient",
  "your-password-has-been-successfully-changed": "Votre mot de passe a été changé avec succès",
  "add-people-under-care-message-pt-1": "Vous pouvez trouver la personne que vous souhaitez ajouter en fournissant ses informations sur la page suivante",
  "add-people-under-care-message-pt-2": "Si la personne que vous ajoutez a plus de 18 ans, vous devrez également fournir l'adresse e-mail qu'elle utilise pour son compte chez",
  "other-information": "Autres informations",
  "notice": "Avis",
  "insurance": "Assurance",
  "update-weak-password": "Votre mot de passe ne répond pas aux exigences actuelles. Pour la sécurité et la protection de votre compte, veuillez en créer un nouveau.",
  "people-under-your-care": "Personnes sous votre garde",
  "people-under-your-care-empty-page": "Si vous gérez des ordonnances et des rendez-vous pour d'autres personnes ou animaux de compagnie, les avoir tous dans une seule liste est un moyen pratique de suivre les médicaments et les horaires de chacun, ce qui peut aider à éviter les renouvellements et les rendez-vous manqués",
  "add-person-or-pet": "Ajouter une personne ou un animal de compagnie",
  "medical-info": "Informations médicales",
  "none": "Rien",
  "refill-details": "Détails de la recharge",
  "success": "Succès",
  "prescription-no": "N° d'ordonnance",
  "linked-accounts": "Comptes liés",
  "linked-accounts-description": "Soignants, patients sous garde",
  "name": "Nom",
  "status": "Statut",
  "submitted": "Soumis",
  "channel": "Canal",
  "delivery-method": "Méthode de Livraison",
  "refill-comments": "Refill Comments",
  "patient-info": "Commentaires de recharge",
  "details": "Des détails",
  "phone": "Téléphone fixe",
  "address": "Adresse",
  "email-text": "E-mail",
  "prescription": "Ordonnance",
  "patient": "Patient",
  "date-submitted": "Date de soumission",
  "allergies-colon": "Allergies: ",
  "medical-conditions": "Conditions médicales: ",
  "refill-past-due": "Recharge en retard:",
  "refill-due": "Recharge due:",
  "store": "Boutique",
  "app": "App",
  "allergies": "Allergies",
  "medications-empty-state-title": "Médicaments",
  "medications-empty-state-description": "L'ajout de vos médicaments facilitera le suivi et la demande de renouvellement d'ordonnances importantes qui vous aideront à rester en bonne santé !",
  "medications-reminders-empty-state-title": "Rappels de médicaments",
  "medications-reminders-empty-state-description": "Les rappels de médicaments sont un bon moyen de suivre vos médicaments, ce qui peut vous aider à éviter des risques inutiles et des maladies graves",
  "add-meds": "Ajouter des médicaments",
  "transfer-meds": "Transférer des médicaments d'une autre pharmacie",
  "transfer-meds-tab": "Transférer des Méds",
  "pickup": "Ramasser",
  "select-an-option": "Veuillez sélectionner une option",
  "choose-from-the-list": "Veuillez choisir dans la liste des articles avant de cliquer sur le bouton de commande",
  "select-one-item": "Selecciona al menos un elemento",
  "show-more": "Montre plus",
  "street1": "Rue 1",
  "street2": "Rue 2",
  "gender-on-insurance": "Sexe (tel qu'il apparaît sur l'assurance)",
  "preferred-language": "Langue préférée",
  "notification-preferences": "Préférences de notification",
  "auto-voice-call": "Llamada de voz automatique",
  "edit-patient": "Modifier le patient",
  "show-less": "Montrer moins",
  "please-confirm-the-info-matches-prescription-label": "Veuillez confirmer que les informations ci-dessous correspondent à ce qui se trouve sur votre étiquette de prescription",
  "register-confirmation-heading": "Configuration d'un nouveau compte",
  "register-confirmation-body": "Un nouveau lien de configuration de compte a été envoyé à votre adresse e-mail. Veuillez vérifier votre e-mail et cliquez sur le lien fourni.",
  "orders-tab-empty-state-title": "Historique des commandes",
  "orders-tab-empty-state-description": "Passer une commande de recharge est facile depuis l'onglet Médicaments ! Après avoir passé une commande, les détails et le suivi iront ici",
  "start-an-order": "Commencer une commande",
  "show-locations": "Afficher les emplacements",
  "first-name-edit-user": "Prénom",
  "last-name-edit-user": "Nom de famille",
  "dob-edit-user": "Date de naissance (mm/dd/yyyy)",
  "information": "information",
  "intro": "Intro",
  "enter-email": "Entrez votre e-mail",
  "create-password": "Créer un mot de passe",
  "register-confirmation": "Confirmation d'enregistrement",
  "register": "S'inscrire",
  "reset-link": "Réinitialiser le lien",
  "reset-successful": "Réinitialisation réussie",
  "register-details": "Détails du registre",
  "phone-verification": "Vérification du téléphone",
  "patient-pharmacy-stores": "Patient-pharmacy-stores",
  "add-prescription": "Ajouter une prescription",
  "refill-guest": "Recharger l'invité",
  "home": "Maison",
  "settings": "Paramètres",
  "store-location": "Emplacement du magasin",
  "expiring-soon": "Expire bientôt",
  "schedule": "Calendrier",
  "find-time": "Trouvez un moment",
  "learn-more": "Apprendre encore plus",
  "next-available": "Suivant disponible",
  "not-available": "Actuellement indisponible",
  "minutes": "Minutes",
  "book-appointment": "Prendre rendez-vous",
  "upcoming": "A venir",
  "past": "Passé",
  "patient-insurance": "Assurance des patients",
  "barcode": "Code à barre",
  "patient-preferences": "Préférences des patients",
  "feedback-success": "Succès des commentaires",
  "people-under-care": "Les personnes dont vous avez la garde",
  "add-patient-under-care": "Ajouter un patient pris en charge",
  "add-patient-under-care-info": "Ajouter des informations sur le patient pris en charge",
  "add-patient-under-care-email": "Ajouter un e-mail patient sous soins",
  "add-patient-under-care-confirmation": "Ajouter un patient sous confirmation de soins",
  "patient-under-care-list": "Liste des patients sous soins",
  "on-hold": "En attente",
  "contact-info": "Coordonnées",
  "confirmation-message-sent": "Nous vous enverrons un message pour confirmer votre nouveau numéro de téléphone.",
  "confirmation-email-sent": "Nous vous enverrons un email pour confirmer votre nouvelle adresse email.",
  "update-info": "Mise à jour {{title}}",
  "verify-info": "Vérifier {{title}}",
  "no-puc-access": "Vous n'êtes actuellement pas autorisé à accéder au dossier de ce patient. Pour y accéder, veuillez accéder aux paramètres de votre compte et ajoutez-le en tant que patient sous soins.",
  "automatic-prescription-flow": "Flux de prescription automatique",
  "success-automatic-prescription-flow": "Succès du flux de prescription automatique",
  "rejected-reason-unknown": "Raison rejetée inconnue",
  "select-person": "Sélectionnez le patient pour ce rendez-vous. Si vous ne les voyez pas dans la liste, vous devrez d'abord les ajouter.",
  "myself": "Moi-même",
  "confirm": "Confirmer",
  "error-loading-appointments": "Erreur lors du chargement des rendez-vous",
  "appointment-scheduled": "rendez-vous est pris",
  "booking-error": "Une erreur s'est produite lors de la tentative de prise de rendez-vous. Veuillez réessayer.",
  "error-loading-slots": "Erreur lors du chargement des créneaux de rendez-vous",
  "error-loading-patient-record": "Erreur lors du chargement du dossier patient",
  "error-updating-patient-record": "Erreur mise à jour du dossier du patient",
  "error-booking-appointment": "Erreur lors de la prise de rendez-vous",
  "when": "Quand",
  "who": "Qui",
  "where": "Où",
  "vaccine-information-statement": "Vaccine Information Statement",
  "vaccine-benefits-and-risks": "Déclaration d'information sur les vaccins",
  "care-notes": "Notes d'entretien",
  "reschedule": "Replanifier",
  "cancel-appointment": "Annuler le rendez-vous",
  "error-loading-appointment": "Erreur lors du chargement du rendez-vous",
  "error-loading-appointment-type": "Erreur lors du chargement du type de rendez-vous",
  "refill-medications": "refill-medications",
  "add-order": 'Ajouter la Commande',
  "refill-medical-infos": "refill-medical-infos",
  "refill-review": "refill-review",
  "refill-other-information": "refill-other-information",
  "refill-prescription-method": "refill-prescription-method",
  "refill-insurance-method": "refill-insurance-method",
  "refill-confirmation": "refill-confirmation",
  "refill-order-details": "refill-order-details",
  "good-morning": "Bonjour",
  "good-afternoon": "Bon après-midi",
  "good-evening": "Bonsoir",
  "you-are-chatting-with": "Vous discutez avec:",
  "new-message": "Nouveau message",
  "no-messages": "Pas de messages",
  "messages-have-a-question": "Avoir une question? Besoin de modifier une commande ? Envoyez-nous un message sécurisé.",
  "new-message-placeholder": "Message...",
  "general": "Général",
  "billing": "Facturation",
  "med-info": "Avec infos",
  "side-effects": "Effets secondaires",
  "message-subject-label": "Sélectionnez le sujet qui correspond le mieux à votre message :",
  "send": "Envoyer",
  "appointment-rescheduled": "le rendez-vous est reprogrammé",
  "error-rescheduling-appointment": "Erreur lors de la reprogrammation du rendez-vous",
  "cancel-appointment-confirmation": "Êtes-vous sûr de vouloir annuler ce rendez-vous?",
  "appointment-canceled": "le rendez-vous est annulé",
  "previously-scheduled": "Précédemment programmé:",
  "cancel-note": "Remarque: Annuler ce rendez-vous ne le supprimera pas des calendriers tiers auxquels il a été ajouté.",
  "cancel-error": "Une erreur s'est produite lors de la tentative d'annulation du rendez-vous. Veuillez réessayer.",
  "error-cancel-appointment": "Erreur lors de l'annulation du rendez-vous",
  "keep": "Garder",
  "of": "sur",
  "prescription-card-last-filled": "Dernière recharge",
  "prescription-card-last-ordered": "Dernière commande:",
  "prescription-card-refill-due": "Recharge due",
  "remove": "Retirer",
  "remove-patient": "Retirer patiente",
  "user-not-found": "Utilisateur non trouvé",
  "invalid-confirmation-code": "Code de confirmation invalide",
  "contact-support": "Contactez le support",
  "many-failed-attempts": "De nombreuses tentatives infructueuses",
  "not-authorized": "Pas autorisé",
  "retrieve-your-prescriptions": "Récupérer la liste des ordonnances",
  "other-meds": "Ajouter des médicaments non sur cette liste",
  "try-again": "Essayer à nouveau",
  "notify-you": "Te notifier",
  "reschedule-appointment": "Re-planifier un rendez-vous",
  "relationship-to-me": "Relation avec moi",
  "unable-to-submit-people-under-care-request": "Impossible de soumettre les personnes sous demande de soins.",
  "tomorrow": "Demain",
  "today": "Aujourd'hui",
  "canceled": "Annulé",
  "events": "événements",
  "error-field-number": "Le champ doit être un nombre",
  "no-appointments": "Vous n'avez actuellement aucun rendez-vous.\nSélectionnez un service pour commencer.",
  "no-services": "Aucun service n'est disponible à la réservation.",
  "video-header": "Vidéos",
  "videos": "Vidéos",
  "videos-filter": "FILTRE",
  "videos-clear": "Clair",
  "videos-all": "Tout",
  "videos-medication": "Médicament",
  "full-access": "Accès total",
  "videos-condition": "Condition",
  "role-permission-description": "Voir les rôles et les autorisations pour une description complète de l'accès au système.",
  "png-only": "Seules les images PNG sont prises en charge",
  "expired-link": "This link has been expired",
  "used-password": "Votre nouveau mot de passe ne peut pas être l'un de vos 5 mots de passe précédents.Veuillez choisir un mot de passe que vous n'avez pas utilisé auparavant.",
  "valid-image-type": "Seules les images JPG, PNG ou SVG sont prises en charge",
  "valid-icon-dimensions": "Les dimensions de l'icône doivent être 1024x1024",
  "videos-administration": "Administration",
  "videos-general-health": "Santé générale",
  "videos-missing-medication-video": "Vidéo sur les médicaments manquants",
  "videos-search": "Recherche",
  "it-admin-description": "Système Manager (ajouter et supprimer les utilisateurs)",
  "brand-description": "Gestionnaire de niveau de marque",
  "corporate-description": "Gestionnaire de haut niveau",
  "manager-description": "Configurer le personnel, le gestionnaire et la non-accès par emplacement (écran suivant)",
  "remove-role": "Désactiver l'utilisateur",
  "location-employee": "Employé de localisation",
  "location-employee-description": "Configurer le personnel, le gestionnaire et la non-accès par emplacement (écran suivant)",
  "remove-role-description": "Plus autorisé dans le système.",
  "user-has-role-error": "L'utilisateur existe. Veuillez modifier l'utilisateur à gérer.",
  "videos-share": "Partager",
  "branding": "l'image de marque",
  "primary-color": "Couleur primaire",
  "text-color": "Couleur de texte",
  "accent": "accent",
  "logo-light": "Logo sur la lumière",
  "logo-dark": "Logo sur l'obscurité",
  "icon-android": "Icône Android",
  "icon-ios": "Icône iOS",
  "videos-category": "Catégorie",
  "videos-title": "Titre",
  "app-settings": "Application et site Web",
  "videos-language-spanish": "Voir en Espagnol",
  "videos-language-english": "Voir en Anglais",
  "videos-language-french": "Voir en Français",
  "videos-duration": "Durée",
  "videos-url": "URL",
  "copied-to-clipboard": "Copié dans le presse-papier",
  "videos-med-guides": "Guides médicaux",
  "videos-transcript": "Transcription",
  "delete-confirmation-modal": "Êtes-vous sûr de vouloir supprimer le {{itemType}} : {{itemName}}?",
  "service": "Service",
  "create-appointment": "Créer un rendez-vous",
  "limit-booking-duration": "Limiter la durée totale de la réservation",
  "limit-future-bookings": "Limiter les réservations futures",
  "limit-booking-frequency": "Limiter la fréquence de réservation",
  "before-event": "Avant l'événement",
  "search-locations": "Emplacements de recherche",
  "reminder-forms": "Ce service a des formulaires que vous devez remplir avant votre rendez-vous",
  "after-event": "Après l'événement",
  "into-future": "dans le futur",
  "within-date-range": "Dans une plage de dates",
  "add-limit": "Ajouter une limite",
  "calendar-days": "jours calendaires",
  "business-days": "jours ouvrés",
  "per-day": "Par jour",
  "per-week": "Par semaine",
  "per-month": "Par mois",
  "no-buffer-time": "Pas de temps de tampon",
  "content-box-title": "Affichage des tâches correspondant aux critères suivants:",
  "duration": "Durée",
  "time": "Temps",
  "check-later": "Veuillez revenir plus tard",
  "birthdate": "Date de naissance",
  "actions": "Actions",
  "service-record-enabled-tooltip": "Marquer le service comme indisponible pour la prise de rendez-vous",
  "service-record-disabled-tooltip": "Activer la prise de rendez-vous pour ce service",
  "copy-link-button-tooltip": "Copier dans le presse-papiers",
  "edit-service-record-tooltip": "Modifier le service",
  "delete-service-record-tooltip": "Supprimer le service",
  "selecting-all-pages": "Sélection de toutes les pages ({{count}} articles)",
  "select-all-pages": "Sélectionner toutes les pages",
  "selecting-current-page": "Sélection de cette page uniquement ({{count}} articles)",
  "select-current-page": "Sélectionnez cette page uniquement",
  "unselect-all": "Tout déselectionner",
  "others": "autres",
  "reorder": "Réorganiser",
  "order-refill": "Recharge de commande",
  "generic-bulk-confirmation-modal": "Êtes-vous sûr de vouloir {{bulkAction}} {{count}} {{type}} {{changeType}}?",
  "task": "tâche",
  "bulk-assignee-confirmation-modal": "Sélectionnez le destinataire pour {{count}} {{type}}.",
  "bulk-due-date-confirmation-modal": "Sélectionnez la date d'échéance pour {{count}} {{type}}",
  "unresolved": "Non résolu",
  "resolved": "Résolu",
  "in_progress": "En cours",
  "on_hold": "En attente",
  "task-resolve": "Résolution des tâches",
  "mark-resolved": "Marquer comme résolu",
  "mark-in-progress": "Marquer comme en cours",
  "mark-flagged": "Marquer comme signalé",
  "mark-unresolved": "Marquer comme non commencé",
  "mark-on-hold": "Marquer comme en attente",
  "change-assignee": "Modifier l'utilisateur assigné",
  "change-due": "Changer la date d'échéance",
  "task-in-progress": "Tâches en cours",
  "task-flag": "Drapeau de tâches",
  "assignee": "Cessionnaire",
  "not-started": "Non démarré",
  "over-the-counter": "En vente libre",
  "delete-task": "Supprimer la tâche",
  "venue": "Lieu",
  "private": "Privé",
  "public": "Public",
  "enable-form": "Activer le formulaire",
  "disable-form": "Désactiver le formulaire",
  "your-pharmacy": "Votre pharmacie",
  "edit-availability-record-tooltip": "Modifier la disponibilité",
  "delete-availability-record-tooltip": "Supprimer la disponibilité",
  "availability": "Disponibilité",
  "new-chat-message": "Nouveau message de conversation",
  "patient-is-required": "Patiente obligatoire",
  "subject-is-required": "Le sujet est requis",
  "message-or-attachment-is-required": "Un message ou une pièce jointe est requis",
  "message-characters-limit-five-thousand": "La limite de caractères des messages est de 5000",
  "find-template": "Trouver un modèle",
  "to": "Pour",
  "subject": "Sujet",
  "note-inbox-messages-should-be-clinical-in-nature": "Remarque: Les messages de la boîte de réception doivent être de nature clinique. L'envoi de messages marketing via cette plate-forme pourrait enfreindre la loi sur la protection des consommateurs par téléphone et les réglementations de la Federal Communications Commission. Ne demandez pas d'informations sur votre carte de crédit.",
  "all": "Tout",
  "unread": "Non lu",
  "unread-by-patient": "non lu par le patient",
  "includes-me": "M'inclut",
  "created-by-me": "Créé par moi",
  "last-seven-days": "Les 7 derniers jours",
  "filters": "Filtres",
  "clear": "Nettoyer",
  "send-attachment": "Envoyer la pièce jointe",
  "type-your-message": "Tapez votre message...",
  "mark-as-unread": "Masquer comme non lu",
  "chat-messages": "Messages de chat",
  "unread-messages": "Messages non lus",
  "find-patient": "Trouver un patient",
  "new-subject": "Nouveau sujet",
  "add-mobile-number-send-message": "Ajouter un numéro de mobile pour envoyer un message",
  "add-mobile-number": "Ajouter un numéro de mobile",
  "newest": "Le plus récent",
  "oldest": "Le plus ancien",
  "a-z": "A-Z",
  "new-chat": "Nouvelle conversation",
  "auto": "Auto",
  "previous-week": "Semaine précédente",
  "current-week": "Semaine en cours",
  "next-week": "La semaine prochaine",
  "secure-message": "message sécurisé",
  "unable-to-process": "Impossible de traiter votre demande",
  "try-again-later": "Veuillez réessayer plus tard. Si vous avez besoin d'une assistance immédiate, veuillez contacter votre pharmacie.",
  "message-pharmacy": "Message pharmacie",
  "call-pharmacy": "Appeler la pharmacie",
  "tasks-grid-no-results-title": "Vos critères de recherche n'ont aucune tâche dans la période actuelle",
  "tasks-grid-no-results-subtitle": "Nous n'avons trouvé aucune tâche dans la période actuelle en fonction de votre vue et de vos filtres. Utilisez les boutons Précédent / Suivant pour naviguer dans le temps, ou le bouton Semaine en cours pour réinitialiser la vue par défaut.",
  "get-directions": "Obtenir des directions",
  "explore-other-locations": "Découvrez d'autres lieux offrant ce service",
  "error-getting-locations": "Erreur lors de la récupération des emplacements",
  "make-appointment": "Prendre rendez-vous",
  "contact-pharmacy-for-assistance": "Contactez la pharmacie pour obtenir de l'aide concernant la prise de rendez-vous",
  "get-more-stores": "Obtenez plus de magasins",
  "zip-code-is-not-valid": "Le code postal n'est pas valide",
  "street-1-is-not-valid": "La rue 1 n'est pas valide",
  "street-2-is-not-valid": "La rue 2 n'est pas valide",
  "allergies-are-not-valid": "Les allergies-ne-sont-pas-valides, un exemple correct est all1, all2, all3...",
  "conditions-are-not-valid": "Les conditions ne sont pas valides, un exemple correct est cond1,cond2,cond3...",
  "error-loading-patients-under-care": "Erreur lors du chargement des patients sous soins",
  "add-new-patient": "Ajouter un nouveau patient...",
  "after-adding-patient": "Après avoir ajouté un nouveau patient, veuillez retourner aux rendez-vous et sélectionner le service souhaité.",
  "pending-requests": "Demandes en attente:",
  "add-calendar-event": "Ajouter au calendrier",
  "by-application": "sur demande",
  "by-email": "Par email",
  "by-sms": "Par SMS",
  "by-voice": "Par la voix",
  "error-loading-patient": "Une erreur s'est produite lors de la tentative d'obtention du patient. Veuillez réessayer.",
  "in": "dans",
  "ago": "il y a",
  "month-short": "mois",
  "hour-short": "dans",
  "day-short": "dans",
  "missing-important-patient-record-data": "Le dossier du patient ne contient pas de données d'importation.",
  "google": "Google",
  "apple": "Apple",
  "outlook": "Outlook",
  "reset-filters": "Réinitialiser les filtres",
  "calendar": "Calendrier",
  "no-medication-guide": "Actuellement indisponible. Si vous avez des questions sur ce médicament, contactez votre pharmacie.",
  "appointment-panel": "Panneau de nomination",
  "age": "Âge",
  "confirmed": "Confirmé",
  "pending": "En attente",
  "rejected": "Rejeté",
  "service-description": "Description du service",
  "additional-info": "Informations supplémentaires",
  "more": "Plus",
  "less": "Moins",
  "month-capital": "Mois",
  "week-capital": "Semaine",
  "day-capital": "Jour",
  "dont-see-service": "Vous ne voyez pas ce dont vous avez besoin?",
  "service-category": "Catégorie de services",
  "category": "Catégorie",
  "service-category-filter": "Catégorie de services",
  "service-type": "Type de service",
  "all-services": "Toutes les prestations",
  "all-service-categories": "Toutes les catégories de services",
  "all-service-types": "Tous types de prestations",
  "team": 'Équipe',
  "personal": "Personnel",
  "reschedule-note": "Remarque : La reprogrammation de ce rendez-vous ne le mettra pas à jour dans les calendriers tiers auxquels il a été ajouté.",
  "ordered": "Commandé",
  "reoccurrence-must-have-context": "Le champ de réapparition doit avoir un contexte de forme parentale",
  "start-date-lower-end-date": "La date de début doit être définie avant la date de fin.",
  "character-count": "Limiter {{max}} caractères, variables comprises. Nombre: {{count}}",
  "maxim-count-attachments": "Le nombre maximal de pièces jointes est de {{count}}.",
  "selecting-files-wrong": "Une erreur s'est produite lors de la sélection des fichiers.",
  "files-sending-error-try-again": "Une erreur s'est produite lors de l'envoi des fichiers. Veuillez réessayer.",
  "home-account-subheading": "Paramètres, assurance, aide, etc.",
  "home-medications-subheading": "Gérer les médicaments et commander des renouvellements",
  "home-messages-subheading": "Discutez en toute sécurité avec la pharmacie",
  "home-appointments-subheading": "Afficher ou planifier des services cliniques",
  "home-puc-subheading": "Ajouter une personne...",
  "create-form-first": "Veuillez d'abord créer un formulaire",
  "town-pharmacy": "Pharmacie de ville",
  "pharmacy-general-info": "Informations générales",
  "pharmacy-edit-business-info": "Modifier les informations commerciales",
  "pharmacy-general-details": "Détails",
  "pharmacy-general-info-limited-brand-name": "Limité à 16 caractères",
  "pharmacy-company-name": "Nom court pharmacie",
  "pharmacy-brand-full-name": "Nom complet de la pharmacie",
  "pharmacy-brand-admin": "Administrateur de marque",
  "pharmacy-parent-company": "Maison mère",
  "pharmacy-ncpdp": "NCPDP",
  "pharmacy-npi": "NPI",
  "pharmacy-pms": "PMS",
  "pharmacy-facebook": "Facebook",
  "pharmacy-instagram": "Instagram",
  "pharmacy-twitter": "X",
  "pharmacy-contact-info": "Informations de contact",
  "pharmacy-edit-contact-info": "Modifier les coordonnées",
  "pharmacy-email-address": "Adresse e-mail",
  "pharmacy-phone-number": "Numéro de téléphone",
  "pharmacy-fax-number": "Numéro de fax",
  "pharmacy-mailing-address": "Adresse postale",
  "pharmacy-billing-address": "Adresse de facturation",
  "pharmacy-brand-name-length": "Le nom ne doit pas dépasser 16 caractères",
  "pharmacy-npi-length": "Le NPI doit 10 caractères",
  "pharmacy-departments": "Départements",
  "pharmacy-names-details": "Des noms",
  "pharmacy-edit-default-department-hours": "Modifier les heures de département par défaut",
  "pharmacy-add-default-department-hours": "Ajouter les heures de département par défaut",
  "pharmacy-delete-department": "Voulez-vous supprimer le département?",
  "pharmacy-delete-department-info": "En confirmant vous supprimerez le département sélectionné.",
  "pharmacy-departments-name": "Département",
  "pharmacy-departments-hours": "Horaires du département",
  "pharmacy-departments-hours-subtitle": "Les heures par défaut pour tous les magasins. Les magasins individuels peuvent remplacer ces paramètres.",
  "pharmacy-departments-name-required": "Le nom du département est obligatoire.",
  "pharmacy-departments-add-hours": "Ajouter des heures",
  "pharmacy-departments-remove-hours": "Eliminar",
  "locations-all": "Tous les emplacements",
  "locations-new": "Nouveau magasin",
  "find-by-name-address-or-ncpdp": "Trouver par nom, adresse ou NCPDP",
  "locations-name": "Nom",
  "locations-tag": "Étiqueter",
  "locations-status": "Statut",
  "locations-NCPDP": "NCPDP",
  "locations-address": "Adresse",
  "locations-pharmacy-info": "Informations sur la pharmacie",
  "locations-contact-info": "Informations de contact",
  "locations-teams": "Équipes",
  "locations-groups": "Groupes",
  "locations-services": "Prestations de service",
  "pick-up-methods": "Méthodes de ramassage",
  "pick-up-methods-description": "Définissez les messages de confirmation de ramassage pour cet emplacement.",
  "locations-normal-hours": "Heures normales",
  "locations-holiday-closures": "Fermetures de vacances",
  "locations-holiday-closures-description": "Recurrant chaque année toute la journée.",
  "locations-additional-closures": "Fermetures supplémentaires",
  "locations-additional-closures-description": "Fermetures uniques à l'heure ou toute la journée.",
  "locations-edit-pharmacy-info": "Modifier les informations de pharmacie",
  "locations-edit-contact-info": "Modifier les coordonnées",
  "locations-edit-department-hours": "Éditer les heures du département",
  "locations-location-name": "Nom de la pharmacie (emplacement)",
  "locations-location-name-tooltip": "Le nom associé à votre NCPDP.Peut être la même que DBA.",
  "locations-internal-name": "Nom interne",
  "locations-internal-name-tooltip": "Cela apparaît dans la plate-forme de lumistry et vu uniquement par les employés de la pharmacie.",
  "locations-NPI": "NPI",
  "locations-store-number": "Numéro de magasin",
  "locations-time-zone": "Fuseau horaire",
  "locations-latitude": "Latitude",
  "locations-longitude": "Longitude",
  "locations-phone-number": "Numéro de téléphone",
  "locations-fax-number": "Numéro de fax",
  "locations-email-address": "Adresse e-mail",
  "locations-mailing-address": "Adresse postale",
  "locations-edit-in-store-method": "Modifier la méthode en magasin",
  "locations-edit-pick-up-method": "Modifier la méthode de ramassage",
  'prescription-help-copy': 'Aide aux prescriptions, services, etc.',
  "locations-active": "Active",
  "locations-inactive": "Inactive",
  "locations-required": "Champ requis",
  "locations-departments-remove-hours": "Retirer",
  "locations-departments-add-hours": "Ajouter des heures",
  "pharmacy-holiday-closures": "Fermetures de vacances",
  "pharmacy-holiday-closures-description": "Fermetures de magasins récurrentes annuelles.",
  "pharmacy-additional-closures": "Fermetures supplémentaires",
  "pharmacy-additional-closures-description": "Ce ne sont pas récurrents.",
  "pharmacy-additional-closures-active": "Active",
  "pharmacy-additional-name-required": "Le nom de fermeture est requis",
  "pharmacy-additional-date-required": "La date de fermeture est requise",
  "pharmacy-additional-hour-required": "L'intervalle de clôture est requis",
  "pharmacy-additional-label": "Étiquette",
  "locations-default": "Défaut",
  "locations-department": "Département",
  "locations-departments-name-required": "Le nom du département est requis.",
  "locations-use-default-settings": "utiliser les paramètres par défaut",
  "wrong-date-format": "Mauvaise date!",
  "all-day": "toute la journée",
  "tags": "Mots clés",
  "new": "Nouvelle",
  "url-is-not-valid": "L'URL n'est pas valide",
  "ok": "D'accord",
  "upload": "Télécharger",
  "replace": "Remplacer",
  "front-photo-missing": "Recto de la carte requis",
   "back-photo-missing": "Dos de la carte requis",
  "no-allergies": "Aucune allergie",
  "easy-open-bottle-caps-confirm": "Voulez-vous des capsules à ouverture facile ?",
  "allergy-info": "Confirmer que les informations sur les allergies sont à jour",
  "forms-fill-out": "Remplissez {{formsCount}} formulaires requis...",
  "forms-filled-in": "Remplissez les formulaires requis",
  "health-info": "Revoir les informations de santé liées",
  "insurance-card-reminder": "Apportez votre carte d'assurance",
  "unable-to-save-changes-try-again": "Impossible d'enregistrer les modifications, réessayez plus tard",
  "pre-appointment-checklist": "Liste de contrôle avant rendez-vous",
  "related-health-info": "informations relatives à la santé",
  "add-patients-start-messaging": "Ajoutez vos patients pour commencer à envoyer des messages",
  "upload-patients-csv": "Télécharger des patients via CSV",
  "error-updating-appointment": "Erreur lors de la reprogrammation du rendez-vous",
  "message-body-is-required": "Le corps du message est requis",
  "verify-patient": "Marquer le patient comme vérifié",
  "verify-patient-warning": "Ce dossier patient ne provient pas de votre logiciel de pharmacie. Il a été créé lorsque cette personne s'est inscrite pour se connecter à un compte sur le portail patient. Veuillez vous assurer de vérifier l’identité de cette personne avant d’échanger des informations sensibles.",
  "no-forms-required": "Aucun formulaire n'était requis pour ce rendez-vous",
  "form-unavailable": "Le formulaire n'est plus disponible",
  "form-not-started": "Formulaire non démarré",
  "form-submitted": "Le formulaire a été soumis",
  "preparing-submission": "Préparation de la soumission",
  "download-will-begin": "Votre téléchargement va bientôt commencer.",
  "delete-submission-confirmation": "Êtes-vous sûr de vouloir supprimer cette soumission ?",
  "not-entered": "Non saisi",
  "submitted-on": "Soumis le",
  "download-pdf": "Télécharger le PDF",
  "submission-modal": "Modalité de soumission",
  "medical-information": "Medical information",
  "yes-easy-caps": "Yes, I prefer easy-open caps",
  "no-safety-caps": "No, I prefer safety caps",
  "easy-open-bottle-caps-description": "Federal regulations require that most medications be packaged with child-resistant “safety” caps. Your choice of easy-open caps indicates your desire to have medications dispensed with non-safety caps.",
  "checklist-complete": "Liste de contrôle terminée",
  "checklist-incomplete": "Veuillez compléter la liste de contrôle",
  "service-status-updated": "L'état du service a été mis à jour avec succès",
  "error-loading-form": "Erreur lors du chargement du formulaire",
  "account-not-found-heading": "Compte non trouvé",
  "account-not-found-subheading": "Si vous avez créé votre compte à l'aide de votre connexion Apple ou Google, veuillez vous connecter avec ce compte",
  "account-not-found-subheading-sso": "Si vous avez créé votre compte avec votre adresse e-mail, veuillez vous connecter avec votre e-mail",
  "invalid-email-format": "Format d'e-mail invalide",
  "close-confirmation-question": "Êtes-vous sûr de vouloir quitter?",
  "close-confirmation-text": "Ce formulaire est nécessaire pour planifier votre rendez-vous.",
  "close-confirmation-exit": "Sortie",
  "close-confirmation-keep-appointment": "Tenir les rendez-vous",
  "last-submission": "Dernière soumission",
  "no-submissions": "Aucun formulaire n'a été soumis",
  "no-forms-to-display": "Aucun formulaire à afficher",
  "search-forms": "Formulaires de recherche",
  "no-submissions-found": "Aucun résultat trouvé pour ",
  "password-reset-subheading": "Pour la sécurité et la protection de votre compte, veuillez créer un nouveau mot de passe.",
  "save-password": "Enregistrer le mot de passe",
  "venue-in-person": "En Personne",
  "venue-virtual": "Vidéo",
  "venue-over-the-phone": "Téléphone",
  "get-the-app": "Téléchargez",
  "edit-reschedule-this-appointment": "Modifier ou reporter ce rendez-vous",
  "cancel-this-appointment": "Annuler ce rendez-vous",
  "message-this-patient": "Envoyer un message à ce patient",
  "contact-pharmacy-for-policy-info": "Veuillez contacter votre pharmacie locale pour plus d'informations sur leur",
  "prescriptions-empty-state-paragraph-one": "L'ajout de vos médicaments facilitera le suivi et la demande de renouvellement de prescriptions importantes qui vous aideront à rester en bonne santé !",
  "prescriptions-empty-state-paragraph-two": "Pour ajouter vos ordonnances maintenant, appuyez sur le bouton ci-dessous. Si vous souhaitez transférer vos médicaments depuis une autre pharmacie, veuillez appuyer sur le lien ci-dessus.",
  "prescriptions-empty-state-button-text": "Ajouter des médicaments",
  "otc-empty-state-paragraph-one": "Informer votre pharmacie de tous les médicaments, vitamines et suppléments que vous prenez peut l'aider à détecter d'éventuelles interactions médicamenteuses, vous gardant ainsi en meilleure santé et en sécurité !",
  "otc-empty-state-paragraph-two": "Cela inclut tous les médicaments que vous utilisez et qui ne nécessitent pas d'ordonnance",
  "otc-empty-state-button-text": "Ajouter des médicaments en vente libre",
  "viewing-medications-for": "Affichage des médicaments pour :",
  "mark-unread": "Marquer comme non lu",
  "error-occurred-title": "Une erreur inattendue s'est produite",
  "error-occurred-description": "Si vous avez besoin d'une assistance immédiate, veuillez contacter votre pharmacie.",
  "contact-prescription-ready": "Nous vous contacterons lorsque votre ordonnance sera prête",
  "task-type": "Type",
  "task-status": "Statut",
  "task-assigned-to": "Assigné à",
  "task-priority": "Priorité",
  "task-created-by": "Créé par",
  "task-due-by": "Date d'échéance",
  "task-visibility": "Visibilité",
  "integration": "Integration",
  "api-host": "API Host",
  "server-address": "Server Address",
  "integration-location-id": "ID d'emplacement d'intégration",
  "server-port": "Server Port",
  "api-key": "API Key",
  "setup-integration": "Setup Integration",
  "integration-missing": "Currently no integration setup",
  "edit-integration": "Edit Integration",
  "pharmacy-software": "Pharmacy Software",
  "confirming-lose-current-form": "En confirmant que vous perdrez les progrès actuels sur l'importation d'un formulaire.",
  "confirm-delete-availability": "Êtes-vous sûr de vouloir supprimer la disponibilité avec le nom: {{name}}?",
  "npi": "NPI",
  "hours": "Heures",
  "date": "date",
  "flagged": "Signalé",
  "assignee-required": "Le cessionnaire est requis",
  "access-private-public": "Accès: privé / public",
  "cannot-delete-task-type": "Impossible de supprimer les types de tâches qui sont actuellement utilisés",
  "cannot-edit-task-type": "Impossible de modifier le type de tâche par défaut",
  "trash": "Poubelle",
  "personal-task-assign": "Les tâches personnelles ne peuvent être affectées qu'au propriétaire / créateur de la tâche",
  "overdue": "En retard",
  "new-service": "Nouveau Service",
  "remove-item": "Retirer l'objet",
  "download-all-submissions": "Télécharger toutes les soumissions",
  "no-submissions-download": "Il n'y a pas de soumissions à télécharger",
  "copy-link": "Copier le lien",
  "form-was-imported": "Le formulaire a été importé",
  "form-submission-not-deleting": "Les formulaires avec les soumissions peuvent ne pas être supprimés",
  "drag-change-order": "Faites glisser pour changer la commande",
  "api-required": "La clé API est requise",
  "high-priority": "Haute priorité",
  "recurring": "Récurrente",
  "delete-availability-in-use-tooltip": "Cette disponibilité est utilisée pour les services.\nVeuillez supprimer/modifier la disponibilité du service,\npuis revenir pour supprimer la disponibilité.",
  "photo-id-2": "Photo d'identité",
  "must-valid-domain": "Doit être un domaine valide",
  "no-access": "Pas d'accès",
  "confirm-to-make": "Êtes-vous sûr de vouloir faire ce formulaire",
  "confirm-delete-availability-implicit": "Êtes-vous sûr de vouloir supprimer la disponibilité?",
  "confirm-delete-service-implicit": "Êtes-vous sûr de vouloir supprimer le service?",
  "something-went-wrong-implicit": "Quelque chose c'est mal passé. Merci d'essayer plus tard.",
  "prescription-info": "Informations sur la prescription",
  "confirm-to-delete-name": "Êtes-vous sûr de vouloir supprimer le formulaire avec le nom: {{name}}?",
  "add-to-list": " Ajouter à la liste",
  "adding-prescriptions-for": "Ajout d'ordonnances pour ",
  "find-meds": "Trouver des Médicaments",
  "rx-number-must-be-numeric": "Veuillez saisir uniquement des chiffres",
  "error-occurred-integration-save": "Une erreur s'est produite lors de la tentative d'enregistrement de l'intégration.",
  "error-occurred-integration-load-save": "Une erreur s'est produite lors de la tentative de chargement ou d'enregistrement de l'intégration. Veuillez réessayer.",
  "integration-saved": "Intégration enregistrée",
  "hide": "Cacher",
  "server-address-required": "L'adresse du serveur est requise",
  "integration-location-id-required": "L'ID de l'emplacement d'intégration est requis",
  "start-time-required": "L'heure de début est requise",
  "end-time-required": "L'heure de fin est requise",
  "custom-filters": "Filtres personnalisés",
  "appointments-filters-info-title": "Affichage des rendez-vous correspondant aux critères suivants :",
  "service-name-info-box": "Nom du service défini sur ",
  "service-category-info-box": "Catégorie de service définie sur ",
  "appointment-notes-update-error": "Une erreur s'est produite lors de la tentative de mise à jour des notes de rendez-vous. Veuillez réessayer.",
  "applied-to-all-locations": "Appliqué à tous les emplacements",
  "add-image": "Ajouter une image",
  "add-file": "Ajouter le fichier",
  "pharmacy-npi-number": "Numéro NPI de la pharmacie",
  "have-second-insurance": "Y a-t-il une assurance secondaire?",
  "my-tasks": "Mes tâches",
  "access-denied": "Accès refusé",
  "check-back-later": "Veuillez vérifier plus tard ou contacter la pharmacie si vous avez des questions.",
  "contact-administration": "Contactez l'administrateur informatique si vous avez des questions",
  "profile-settings": "Paramètres du profil",
  "new-messages": "Nouveaux messages",
  "this-week": "Cette semaine",
  "this-month": "Ce mois-ci",
  "older": "Plus ancien",
  "time-sensitive": "Urgent",
  "switch-user": "Changer d'utilisateur",
  "audit-trail": "Piste d'audit",
  "automated-actions": "Actions automatisées",
  "help-support": "Aide et support",
  "internal-demos": "Démos internes",
  "vow-ivr": "Vœu IVR",
  "wellness-classes": "Cours de bien-être",
  "event-logs": "Journaux d'événements",
  "subscribe-to-webhooks": "Abonnez-vous aux Webhooks",
  "realtime-updates": "Mises à jour en temps réel",
  "enabled": "Activé",
  "disabled": "Désactivé",
  "overwrite-defaults": "Écraser les valeurs par défaut",
  "changed-insurance": "L'assurance a-t-elle changé depuis la dernière recharge?",
  "notifications": "Notifications",
  "no-notifications": "Pas de notifications",
  "no-notifications-subtitle": "Nous vous avertirons lorsque quelque chose se produit",
  "add-manually": "Ajouter manuellement",
  "authentication-failed-generic": "authentification échouée",
  "sending": "Envoi en cours...",
  "read": "Lire",
  "delivered": "Livré",
  "failed-to-send": "Échec de l'envoi",
  "retry": "Recommencez",
  "realtime-updates-api-key": "Clé API des mises à jour en temps réel",
  "internal-webhook-keys-renewed": "Renouvellement de la clé API Webhook interne",
  "error-occurred-internal-webhook-keys-renew": "Erreur survenue lors du renouvellement de la clé API Webhook interne",
  "refresh": "Rafraîchir",
  "edit-rows": "Edit Ranse",
  "add-new-row": "Ajouter une nouvelle ligne",
  "every-month": "Chaque mois",
  "relationship-to-me-is-required": "Relation avec moi obligatoire",
  "at": "à",
  // Workflow Statuses
  "workflow-status-in-queue": "In queue",
  "workflow-status-out-of-stock": "Out of stock",
  "workflow-status-special-order": "Special order",
  "workflow-status-in-process": "In process",
  "workflow-status-awaiting-insurance-authorization": "Awaiting insurance",
  "workflow-status-awaiting-prescriber-authorization": "Awaiting prescriber",
  "workflow-status-ready-for-shipping": "Ready for shipping",
  "workflow-status-ready-for-delivery": "Ready for delivery",
  "workflow-status-partial-fill": "Partial fill",
  "workflow-status-partially-filled": "Partially filled",
  "workflow-status-delivery-in-progress": "Out for delivery",
  "workflow-status-ready-for-pickup": "Ready for pickup",
  "workflow-status-dispensed": "Dispensed",
  "workflow-status-mail": "Mail",
  "workflow-status-shipped": "Shipped",
  "workflow-status-delivered": "Delivered",
  "workflow-status-returned": "Returned",
  "workflow-status-returned-to-stock": "Returned to stock",
  "workflow-status-cancelled": "Canceled",
  "workflow-status-expired": "Expired",
  "workflow-status-discontinued": "Discontinued",
  "workflow-status-transferred": "Transferred",
  "workflow-status-insurance-refused": "Refused by insurance",
  "workflow-status-prescriber-refused": "Refused by prescriber",
  "workflow-status-filled": "Rx filled",
  "workflow-status-undefined": "-",
  // Workflow Statuses Long
  "workflow-status-in-queue-message": "This Rx is in the refill queue",
  "workflow-status-out-of-stock-message": "This Rx is out of stock",
  "workflow-status-special-order-message": "This Rx is on special order",
  "workflow-status-in-process-message": "This Rx is in the process of refilling",
  "workflow-status-awaiting-insurance-authorization-message": "This Rx is awaiting insurance authorization",
  "workflow-status-awaiting-prescriber-authorization-message": "This Rx is awaiting prescriber authorization",
  "workflow-status-ready-for-shipping-message": "This Rx is filled and ready for shipping",
  "workflow-status-ready-for-delivery-message": "This Rx is filled and ready for delivery",
  "workflow-status-partial-fill-message": "This Rx was partially filled",
  "workflow-status-partially-filled-message": "This Rx was partially filled",
  "workflow-status-delivery-in-progress-message": "This Rx has been sent out for delivery",
  "workflow-status-ready-for-pickup-message": "This Rx is filled and ready for pickup",
  "workflow-status-dispensed-message": "This prescription has been dispensed",
  "workflow-status-mail-message": "This prescription has been mailed",
  "workflow-status-shipped-message": "This prescription has been shipped",
  "workflow-status-delivered-message": "This prescription has been delivered",
  "workflow-status-returned-message": "This prescription was returned",
  "workflow-status-returned-to-stock-message": "This prescription was returned to stock",
  "workflow-status-cancelled-message": "This prescription was canceled",
  "workflow-status-expired-message": "This prescription has expired",
  "workflow-status-discontinued-message": "This prescription has been discontinued",
  "workflow-status-transferred-message": "This prescription was transferred",
  "workflow-status-insurance-refused-message": "This prescription was refused by the insurance provider",
  "workflow-status-prescriber-refused-message": "This prescription was refused by the prescriber",
  "workflow-status-filled-message": "This prescription has been filled",
  "workflow-status-undefined-message": "Info unavailable",
  // Rx Statuses
  "rx-status-no-refills": "No more refills",
  "rx-status-expired": "Rx expired",
  "rx-status-too-soon": "Rx too soon",
  "rx-status-not-found": "Rx not found",
  "rx-status-controlled": "Controlled",
  "rx-status-narcotic": "Narcotic",
  "rx-status-transferred": "Transferred",
  "rx-status-discontinued": "Discontinued",
  "rx-status-on-hold": "On hold",
  "rx-status-deleted": "Rx deleted",
  "rx-status-fillable": "Refill OK",
  "rx-status-partially-filled": "Partial fill",
  "rx-status-out-of-stock": "Out of stock",
  "rx-status-special-order": "Special order",
  "rx-status-deceased": "Patient deceased",
  "rx-status-undefined": "-",
  // Rx Statuses Long
  "rx-status-no-refills-message": "This Rx has no more refills available",
  "rx-status-expired-message": "This prescription has expired",
  "rx-status-too-soon-message": "It is too soon to refill this prescription",
  "rx-status-not-found-message": "There is no record of this prescription number",
  "rx-status-controlled-message": "This prescription is a controlled substance",
  "rx-status-narcotic-message": "This prescription is a narcotic",
  "rx-status-transferred-message": "This prescription was transferred",
  "rx-status-discontinued-message": "This prescription has been discontinued",
  "rx-status-on-hold-message": "This prescription is on hold",
  "rx-status-deleted-message": "This prescription was deleted",
  "rx-status-fillable-message": "This prescription is good for refilling",
  "rx-status-partially-filled-message": "This prescription was partially filled",
  "rx-status-out-of-stock-message": "This prescription is out of stock",
  "rx-status-special-order-message": "This prescription is on special order",
  "rx-status-deceased-message": "The prescription belongs to a deceased patient",
  "rx-status-undefined-message": "Info unavailable",
  // Order Statuses
  "order-status-received": "Received",
  "order-status-in-progress" : "In Progress",
  "order-status-filled": "Filled",
  "order-status-out-for-delivery": "Out for delivery",
  "order-status-ready-for-pickup": "Ready for pickup",
  "order-status-picked-up": "Picked up",
  "order-status-mailed": "Mailed",
  "order-status-shipped": "Shipped",
  "order-status-delivered": "Delivered",
  "order-status-returned-to-stock": "Returned to stock",
  "order-status-info-unavailable": "Info unavailable, contact your pharmacy",
  "order-status-undefined": "Info not available, try again later",
  // Order Progress Statuses
  "order-progress-not-started": "Not started",
  "order-progress-started": "Started",
  "order-progress-completed": "Completed",
  "order-progress-filled": "Filled",
  "order-progress-undefined": "(n/a)",
  // Refill Status
  "refill-status-passed": "Passé",
  "refill-status-not-passed": "Echoué",
  "refill-status-filter-all": "Tout",
  //
  "preview": "Aperçu",
  "form-not-editable": "Ce formulaire n'est pas modifiable",
  "form-preview": "Aperçu du formulaire",
  "dob-age": "Date de naissance (âge)",
  "form-name": "Nom du formulaire",
  "mark-all-as-read": "Tout marquer comme lu",
  "mark-as-read": "Marquer comme lu",
  "continuation-disclaimer-register": "En appuyant sur Suivant, vous acceptez les conditions d'utilisation de cette pharmacie.",
  "inactive": "Inactif",
  "submissions": "Soumissions",
  "last-modified": "Dernière modification",
  "this-patient-has-not-sent-or-received-any-messages": "Ce patient n'a envoyé ni reçu aucun message",
  "have-moment-break-ice-and-ask-them-how-they-are-doing": "Vous avez un moment? Brisez la glace et demandez-leur comment ils vont!",
  "copy-url": "Copier l'URL",
  "error-add-self-as-people-under-care-request":"Vous ne pouvez pas vous ajouter comme patient sous vos soins",
  "error-exist-record-in-people-under-care-request-list":"{{name}} est déjà sur votre liste de patients sous vos soins",
  "enable-datafeed": "Activer le flux de données",
  "server-port-required": "Le port du serveur est requis",
  "invalid-ip-url": "Veuillez saisir une adresse IP ou une URL valide",
  "numeric-only": "Veuillez saisir uniquement des valeurs numériques",
  "allergies-info-not-available": "Informations sur les allergies non disponibles",
  "info-not-available": "Informations non disponibles",
  "active": "Actif",
  "no-edit": "Aucune modification",
  "imported": "Importé",
  "please-confirm-your-choice": "Veuillez confirmer votre choix",
  "form-edit-warning-title":"Ce formulaire est associé à des soumissions",
  "form-edit-warning":"Toute modification apportée à ce formulaire peut entraîner la suppression des données soumises par les patients des formulaires qu'ils ont remplis. Pour éviter de perdre des informations importantes, vous pouvez télécharger les soumissions du formulaire avant d'apporter des modifications ou archiver ce formulaire et en créer un nouveau.",
  "form-save-confirm": "Confirmer l'enregistrement",
  "form-save-modal-message": "L'enregistrement de ces modifications peut entraîner une perte de données lors des soumissions de formulaires. Êtes-vous sur de vouloir continuer?",
  "form-save-save-button": "Sauvegarder",
  "file-too-large": "Le fichier est trop volumineux pour être joint, essayez un fichier plus petit. Taille maximale de téléchargement de fichier : 5 Mo",
  "order-tracker-title": "Order: {{patient}} on {{date}}",
  "order-tracker-description": "{{completed}} of {{total}} prescription(s) filled",
  "order-tracker-see-orders": "+{{total}} more",
  "order-tracker-in-progress": "In progress",
  "order-tracker-filled": "Filled",
  "find-pharmacy": "Trouver une Pharmacie",
  "reset": "Réinitialiser",
  "datafeed" : "Flux de données",
  "download-submissions":"Télécharger les soumissions",
  "inactive-form-error-title": "Oops! N'existe pas.",
  "inactive-form-error-description":"Ce formulaire n'accepte plus de soumissions. Si vous avez besoin d'aide, veuillez contacter votre pharmacie.",
  "form-open-error": "Se produjo un error al intentar abrir el formulario, inténtelo nuevamente más tarde.",
  "bulk-message-your-patients": "Envoyez des messages groupés à vos patients",
  "new-bulk-message": "Nouveau message groupé",
  "target-your-patients-with-clinical-opportunities-and-more": "Ciblez vos patients avec des opportunités cliniques et bien plus encore",
  "paperless-enrollment-title": "Vous êtes inscrit au sans papier",
  "paperless-enrollment-subtitle": "Être sans papier signifie que vous recevrez tous les documents et enregistrements importants sous forme numérique.",
  "paperless-enrollment-text": "Aucune action n'est requise pour continuer.",
  "paperless-enrollment-benefits-title": "Avantages d'être sans papier",
  "paperless-enrollment-first-benefit": "Impact environnemental positif",
  "paperless-enrollment-second-benefit": "Protection accrue contre le vol d'identité",
  "paperless-enrollment-third-benefit": "Amélioration des connaissances en matière de santé grâce à des vidéos sur les médicaments faciles à comprendre",
  "paperless-enrollment-fourth-benefit": "Accès pratique aux informations médicales et aux vidéos sur la santé depuis l'application",
  "paperless-enrollment-med-info": "Où puis-je trouver des informations médicales et des vidéos sur la santé ?",
  "paperless-screen-title": "Sans papier",
  "validation-start-hour-smaller": "L'heure de début doit être avant l'heure de fin!",
  "validation-end-hour-greater": "L'heure de fin doit être après l'heure de début!",
  "npi-required": "NPI is required",
  "api-key-required": "API Key is required",
  "username-required": "Username is required",
  "password-required": "Password is required",
  "patient-message-invalid-file-type": "Type de fichier invalide. Les types de fichiers pris en charge sont : JPEG, PNG, AVIF, WebP, HEIC, GIF, PDF",
  "patient-message-file-limit": "Limite dépassée. Nombre maximum de pièces jointes autorisées : 1",
  "bulk": "Masse",
  'appointment-created': 'Rendez-vous créé',
  'opted-sms-email':
    "Assurez-vous d'avoir activé les notifications par SMS ou par e-mail, afin de ne manquer aucune information médicale importante de votre pharmacie.",
  'sms-data-rates': "Des frais de SMS et de données peuvent s'appliquer.",
  'communication-preferences-benefit':
    'En nous faisant part de vos préférences de communication, nous pourrons vous contacter de la manière dont vous souhaiteriez être contacté.',
  'communications-notifications-etc': 'Communications, notifications, etc.',
  'med-info-description-start':
    'Vous pouvez facilement trouver des informations sur les médicaments et des vidéos sur la santé en appuyant sur ',
  'med-info-description-end':
    " l'icône sur votre ordonnance dans l'onglet Médicaments.",
  'medicine-name': 'Metformine HCL 500 mg, comprimé',
  'refill-due-date': 'Recharge due: 3 nov',
  'last-filled-date': 'Dernier remplissage',
  'med-info-location': 'Où trouver des informations sur les médicaments',
  'pharmacy-department-name-exist': 'Le nom du département existe déjà',
  'appointment-at': 'Rendez-vous à',
  'pharmacy-appointment': 'Rendez-vous en pharmacie',
  'welcome-to-forms': 'Bienvenue dans les formulaires',
  'create-new-form-or-upload': 'Commencez par créer un nouveau formulaire ou importez depuis Jotform.',
  'create-new-form-or-upload-forms': 'Créer un nouveau formulaire ou importer depuis JotForm',
  'collect-ephi-or-hipaa':
    'Créez un nouveau formulaire ou téléchargez-le depuis JotForm',
  'save-time-by-getting-info':
    'Gagnez du temps en vous informant avant les rendez-vous',
  'import-your-existing-templates':
    'Importez vos modèles existants depuis JotForm',
  'save-paper': 'Économisez du papier !',
  'all-forms-are-inactive': 'Tous les formulaires sont inactifs.',
  'patient-notifications': 'Notifications aux patients',
  'edit-after-hours': "Modifier après les heures d'ouverture",
  'error-occurred-after-hours-load-save': "Une erreur s'est produite lors de la tentative de chargement ou d'enregistrement du message en dehors des heures d'ouverture. Veuillez réessayer.",
  'message-settings-saved': 'Paramètres de message enregistrés',
  'auto-response-after-hours': "Réponse automatique après les heures d'ouverture",
  "error-occurred-integration-load": "Une erreur s'est produite lors de la tentative d'intégration",
  "forms-are-optional": "Les formulaires sont facultatifs",
  "forms-are-optional-checkbox-tooltip":"Cocher cette option permettra aux patients de prendre rendez-vous sans soumettre de formulaires",
  "pharmacy-info" :"Info pharmacie",
  "pharmacy-name":"Nom de la pharmacie",
  "pharmacy-name-is-required" :"Le nom de la pharmacie est requis",
  "about-your-transfer":"À propos de votre transfert",
  "what-meds-are-you-transferring":"Quels médicaments transférez-vous ?" ,
  "all-my-medications" :"Tous mes médicaments",
  "some-of-my-medications":"Certains de mes médicaments",
  "why-transfer-all-the-meds" :"Pourquoi transférer tous les médicaments...",
  "why-transfer-all-the-meds-description":"Avoir vos médicaments au même endroit est non seulement plus pratique pour vous, mais cela réduit également le risque d'interactions médicamenteuses et d'erreurs médicamenteuses.",
  "start-over-to-change-person":"Recommencer pour changer de personne",
  "fill-prescriptions-after-transferring":"Remplir les ordonnances après le transfert",
  "transfer-another":"Transférer un autre",
  "start-over":"Recommencer",
  "enter-your-medications" :"Entrez vos médicaments",
  "enter-more":"Entrez plus",
  "at-least-one-drug-name-is-required":"Au moins un nom de médicament est requis",
  "your-transfer-request-has-been-submitted":"Votre demande de transfert a été soumise",
  "your-transfer-request-has-been-submitted-and-filled":"Votre demande de transfert a été soumise et sera remplie",
  "pharmacy":"Pharmacie",
  "from-different-pharmacy": "D'une autre pharmacie",
  "whose-prescription-are-you-transferring":" À qui transférez-vous les ordonnances ?",
  "where-are-you-transferring-from":"D'où les transférez-vous ?",
  "another-pharmacy-location":"Un autre emplacement de la {{pharmacyName}}",
  "different-pharmacy":"Une pharmacie différente",
  "transfer-meds-header-title": "Transférer des Médicaments",
  "all-medications":"Tous médicaments",
  "no-results-found": "Aucun résultat trouvé",
  "med-info-preferences": "Préférences d'informations sur les médicaments",
  "select-med-info": "Sélectionnez la manière dont vous souhaitez recevoir des informations sur les médicaments:",
  "receive-digitally": "Recevez numériquement dans l'application",
  "receive-print-out": "Recevoir une impression papier",
  "patient-message-invalid-image-file-type": "Type de fichier invalide. Les types de fichiers pris en charge sont : JPEG, PNG, AVIF, WebP, HEIC",
  'welcome-to-submissions': 'Bienvenue aux soumissions',
  'all-forms-submitted-by-your-patients-will-appear-here':'Tous les formulaires soumis par vos patients apparaîtront ici.',
  'anytime-access-to-your-submissions':'Accès à tout moment à toutes vos soumissions de formulaires en un seul endroit',
  'easy-managment-without-need-to-print':"Gestion facile sans avoir besoin d'imprimer ou de numériser",
  'ability-to-download-single-or-multiple-forms':'Possibilité de télécharger un ou plusieurs formulaires',
  'savings-on-paper-and-toner-materials':'Économisez sur le papier et le toner!',
  'forms-services': 'Services',
  "fill-out-forms": "Remplir des formulaires",
  "do-it-later":"Fais-le plus tard",
  'fill-optional-forms-reminder':'Ce service dispose de formulaires que vous devez remplir avant votre rendez-vous',
  'template': 'Modèle',
  "appointments-on-service": "There are appointments on this service. It cannot be deleted. Disable the service so new appointments cannot be booked.",
  'offerings': 'Offrandes',
  'locations-languages-spoken': 'Langues',
  'refills': 'Recharges',
  'offered-to-patient': 'Offert au patient',
  'on-of': 'Sur/Désactivé',
  'appt': 'Appt',
  'pharmacy-hours': 'Heures de la pharmacie',
  "refill-not-passed": "La recharge n’a pas été effectuée",
  "new-form-submissions": "Soumissions de nouveaux formulaires",
  "tasks-completed": "Tâches accomplies",
  "messages-read": "Messages Lus",
  'select-chat-template': 'Sélectionnez un modèle de discussion',
  'use-template': 'Utilise le modèle',
  'insert-emoji': 'Insérer un emoji',
  'attach-files': 'Joindre des fichiers',
  "roles": "Les rôles",
  "global": "Mondiale",
  "role-corporate": "Corporative",
  "role-it-admin": "Il Adminait",
  "role-brand": "Marque",
  "role-manager": "Directrice",
  "role-staff": "Personnelle",
  "role-no-access": "Pas d'Accès",
  "role-lumistry-user": "Utilisateur de Lumistry",
  "role-lumistry-admin": "Utilisateur de Lumistry Admin",
  "profile-first-name": "Prénom",
  "profile-last-name": "Nom de famille",
  "mark-as-passed": "Marquer comme réussi",
  "mark-as-not-passed": "Marquer comme non réussi",
  "dashboard-no-appointments": "Pas de connexions",
  "dashboard-task": "Tâche",
  'contact-help-support': "Contactez le Support",
  "submission-view": "Afficher la Soumission du Formulaire",
  "form-view": "Vue de la forme",
  "upcoming-appointments": 'Rendez-vous à Venir',
  "canceled-appointments": "Rendez-vous Annulés",
  "past-appointments": "Rendez-vous Passés",
  "tasks-list-tab": "Liste des Tâches ",
  "tasks-grid-tab": "Grille de Tâches",
  "edit-form": "Modifier Forme",
  "tasks-grid-automation": "Automatisation des Tâches",
  "edit-location": "Modifier l'Emplacement",
  "new-availability-tab": "Nouvelle Disponibilité",
  "edit-service-tab": "Modifier le Service",
  'help-with-prescription': 'Aide avec une ordonnance',
  'tech-support': 'Support technique pour l’application',
  'need-help-prescription': 'J’ai besoin d’aide avec une ordonnance',
  'send-secure-message': 'Envoyer un message sécurisé',
  'call-the-pharmacy': 'Appelez la pharmacie',
  "help-and-support": "Aide et Support",
  "edit-user": "EModifier l'utilisateur",
  "send-a-secure-message":"Envoyer un message sécurisé",
  "share-video": "Partager la vidéo",
  "copy-link-to-share-video-message": "Copiez le lien ci-dessous pour le partager dans un message",
  "video-send": "Envoyer...",
  "copy": "Copie",
  "sender": "Expéditrice",
  "recipients": "Destinatarias",
  "timestamp": "Horodatage",
  "bulk-messages": "Messages en masse",
  "scheduled": "Programmé",
  "non-secure-messages": "Messages non sécurisés",
  "secure-chats": "Discussions sécurisées",
  "templates": "Modèles",
  "send-date": "Envoyer la date",
  "secure-chat": "Chat sécurisé",
  "secure": "Assurer",
  'in-app-chat': 'Chat dans l\'application',
  'non-secure': 'Non sécurisé',
  'sms-email-1-way-communication': 'SMS, e-mail (unidirectionnelle)',
  'sms-email': 'SMS/E-mail',
  'send-to-all-patients': 'Envoyer à tous les patients',
  'send-to-all': 'Envoyer à tous',
  'send-to-specific-people': 'Envoyer à des personnes spécifiques',
  'add-recipient': 'Ajouter un destinataire',
  'when-to-send': 'Quand envoyer',
  'immediate': 'Immédiat',
  'scheduled-not-available': 'Programmé (dans la portée MVP pas encore disponible)',
  'google-review': 'Google review',
  'recipient-criteria': 'Critères du destinataire',
  'bulk-message-added': 'Message groupé ajouté',
  'error-creating-bulk-message': 'Erreur lors de la création d\'un message groupé',
  'video-share-send-bulk-messages': 'Salut Prénom, votre recharge est prête à être ramassée.',
  "automations":"Automatisations",
  "automated-tasks":"Tâches Automatisées",
  "insurance-changes":"Changements d'assurance",
  "update-automation-error":"Impossible de mettre à jour Automation. Veuillez réessayer.",
  "get-automated-tasks-error":"Une erreur s'est produite lors de la tentative de chargement des tâches automatisées. Veuillez réessayer.",
  "configure":"Configurer",
  "info-cards": "Cartes d'information",
  "edit-info-card": "Modifier la carte d'information",
  "new-info-card": "Nouvelle carte d'information",
  "link": "Lien",
  "link-url": "URL de liaison",
  "link-label": "Étiquette de liaison",
  "one-or-two-words-are-suggested": "1 ou 2 mots sont suggérés",
  "max-char-allowed-message": "{{maxChar}} caractère maximum",
  "title-is-required": "Le titre est requis",
  "enable": "Activer",
  "disable": "Désactiver",
  "find-assignee-or-summary": "Rechercher un responsable ou un résumé",
  "task-types-description": "Les types de tâches aident à catégoriser les tâches. Ils s'affichent dans les vues Liste et Grille.",
  "created-date": "Date de création",
  "task-category": "Catégorie de tâche",
  "confirming-deactivate-user": "En confirmant vous désactiverez l'utilisateur",
  "bottle-caps-confirmation-message": "Souhaitez-vous des bouchons de bouteille ouverts faciles?",
  "character-count-of-total": "Nombre de caractères: {{count}}/{{total}}",
  "unexpected-error": 'Erreur inattendue',
  "patient-does-not-have-medications": "Ce patient n'a pas de médicaments dans cette pharmacie",
  "remaining-refills": "Restantes recharges",
  "rx": "Rx",
  "send-video": "Envoyer la vidéo",
  "enrolled":"Inscrit",
  "not-enrolled":"Non inscrit",
  "patient-record-updates": "Mises à jour des dossiers patients",
  "failed-refills": "Recharges échouées",
  "paperless-patient": "Patient sans papier",
  "allergy-updates": "Mises à jour sur les allergies",
  "patient-record-updates-description": "Crée une tâche avec les mises à jour apportées par le patient à son profil, telles que les capsules de bouteilles à ouverture facile, les modifications d'assurance et les conditions médicales, afin que vous puissiez facilement mettre à jour le PMS",
  "failed-refills-description": "Crée une tâche avec des recharges qui n'ont pas été traitées lorsque le PMS n'était pas disponible afin que vous puissiez les traiter à nouveau ultérieurement lorsque le PMS est disponible",
  "paperless-patient-description": "Crée une tâche avec une liste de patients qui ont mis à jour leurs options sans papier",
  "allergy-updates-description": "Crée une tâche avec toutes les mises à jour d'allergie apportées par le patient à son profil afin que vous puissiez facilement référencer ses médicaments et mettre à jour le PMS",
  "app-name": "Nom de l'application",
  "app-name-required": "Nom de l'application requis!",
  "app-name-tooltip-text": "Cela apparaîtra dans votre application mobile.Veuillez contacter le support client pour mettre à jour le nom de votre application.",
  "app-store-name": "Nom de l'App Store",
  "app-store-name-required": "Nom de l'App Store requis!",
  "app-store-name-tooltip-text": "Cela apparaîtra dans Apple App Store et Google Play.En longueur limitée.",
  "app-marketplace-name": "Nom du marché de l'application",
  "app-marketplace-name-required": "Nom du marché de l'application requis!",
  "app-marketplace-name-tooltip-text": "Cela apparaîtra dans Apple App Store et Google Play.En longueur limitée.",
  "android-identifier": "Identifiant Android",
  "android-store-link": "Lien de la boutique Android",
  "apple-identifier": "Identifiant de pomme",
  "apple-store-link": "Lien Apple Store",
  "ios-app-id": "ID d'application iOS",
  "ios-team-id": "ID d'équipe iOS",
  "ios-bundle-id": "ID de faisceau iOS",
  "onboarding": "Onboarding",
  "date-written":"Date d'écriture",
  "non-secure-message": "Aucun message sécurisé",
  "refill-errors": "Erreurs de recharge",
  "refill-errors-description": "La personnalisation est disponible uniquement avec une intégration PMS.",
  "rx-status": "Statut Rx",
  "allow-refill": "Autoriser la recharge",
  "refill-due-reminders": "Recharger les rappels dus",
  "edit-refill-due-reminders": "Modifier les rappels de recharge",
  "refill-due-reminders-non-controlled":"Substance non contrôlée",
  "refill-due-reminders-controlled":"Substance contrôlée",
  "refill-past-due-reminders":"Rappel de retard",
  "refill-due-reminders-non-controlled-long-text":"Substance non contrôlée (0-30 jours)",
  "refill-due-reminders-controlled-long-text":"Substance contrôlée (0-30 jours)",
  "refill-past-due-reminders-long-text":"Recharger le rappel en souffrance (1-14 jours)",
  "days-prior":"jours avant",
  "days-after":"jours après",
  "edit-refill-errors":"Modifier l'erreur de recharge - {{refillError}}",
  "refill-error-toggle-description": "L'activation de cette option permettra aux patients de demander cette recharge en ligne.",
  "refill-error-toggle-name": "Autoriser la recharge",
  "error-description": "Description de l'erreur",
  "error-name": "Nom de l'erreur",
  "character-count-of-total-refill-error":"Nombre de caractères:  {{charCount}}/{{count}}",
  "patient-can-start-new-chat": "Le patient peut démarrer une nouvelle conversation",
  "can-patient-start-chat-info": "Les patients peuvent toujours répondre à un fil de discussion existant à tout moment. Ce paramètre contrôle s'ils peuvent ou non démarrer un nouveau fil de discussion.",
  "refill-order":"Commande de recharge",
  "automated-workflow":"Flux de travail automatisé",
  "new-insurance-card-primary-front":"Ajouté - Carte d'assurance primaire - Recto",
  "new-insurance-card-primary-back":"Ajouté - Carte d'assurance primaire - Retour",
  "removed-insurance-card-primary-front":"Supprimé - Carte d'assurance primaire - Recto",
  "removed-insurance-card-primary-back":"Supprimé - Carte d'assurance primaire - Verso",
  "new-insurance-card-secondary-front":"Ajouté - Carte d'assurance secondaire - Recto",
  "new-insurance-card-secondary-back":"Ajouté - Carte d'assurance secondaire - Retour",
  "removed-insurance-card-secondary-front":"Supprimé - Carte d'assurance secondaire - Recto",
  "removed-insurance-card-secondary-back":"Supprimé - Carte d'assurance secondaire - Verso",
  "add-variable":"Ajouter une variable",
  "patient-first-name-messaging":"Prénom du patient",
  "patient-last-name-messaging":"Nom du patient",
  "pharmacy-phone-number-messaging":"Numéro de téléphone de la pharmacie",
  "add-attachment": "Ajouter une pièce jointe",
  "highlight-text-to-insert-link": "Surligner le texte pour insérer le lien",
  "appointment-details": "Détails du rendez-vous",
  "resolved-date": "Date résolue",
  "account-information": 'Information sur le compte',
  "please-confirm-or-fill-in": "Veuillez confirmer ou remplir les informations suivantes.",
  "primary-contact": "Premier contact",
  "business-information": "Informations d'affaires",
  "brand-name": "Marque",
  "brand-name-description": "À utiliser si votre pharmacie est associée à une marque globale. Peut être le même que le nom de la pharmacie pour les comptes à emplacement unique.",
  "for-iternal-use": "Pour un usage interne si vous en avez un.",
  "pharmacy-information": "Informations sur la pharmacie",
  "legal-entity-name": "Nom de l'entité juridique",
  "legal-entity-name-is-required": "Le nom de l'entité juridique est requis",
  "dba": "DBA",
  "dba-is-required": "Un DBA est requis",
  "location-email-address": "Adresse email de la pharmacie",
  "pharmacy-email-address-is-required": "L'adresse e-mail de la pharmacie est requise",
  "pharmacy-ncpdp-is-required": "NCPDP est requise",
  "pharmacy-npi-is-required": "NPI est requise",
  "duns-number": "Numéro DUNS",
  "duns-number-is-required": "Número DUNS est requise",
  "duns-is-not-valid": "Le numéro D-U-N-S n'est pas valide",
  "tax-id-is-not-valid": "Le numéro d'identification fiscale n'est pas valide",
  "tax-id": "Numéro d'identification fiscale/EIN",
  "tax-id-is-required": "Tax ID est requise",
  "pharmacy-managment-system": "Système de gestion de pharmacie",
  "pharmacy-managment-system-is-required": "Système de gestion de pharmacie est requise",
  "business-type": "Type d'entreprise",
  "business-type-is-required": "Type d'entreprise est requise",
  "time-zone-is-required": "Le fuseau horaire est requis",
  "duns-tooltip-before": "Le système de numérotation universelle des données, abrégé en DUNS ou D-U-N-S, est un système exclusif développé et géré par Dun & Bradstreet qui attribue un identifiant numérique unique, appelé « numéro DUNS » à une seule entité commerciale. Celui-ci est obtenu directement auprès de Dun & Bradstreet et est ",
  "duns-tooltip-after": "obligatoire.",
  "pharmacy-name-tooltip": "Le nom associé à votre NCPDP. Peut-être le même que DBA.",
  "error-occured-ob-save": "Une erreur s'est produite. Veuillez réessayer!",
  "legal-name-tooltip": "Le nom commercial légal sous lequel la pharmacie opère.",
  "dba-tooltip": "Le nom public sous lequel la pharmacie opère.",
  "mark-as-flagged": "Marquer comme signalé",
  "mark-as-unflagged": "Marquer comme non signalé",
  "vaccination-info": "Informations sur les vaccins",
  "jurisdiction": "Juridiction",
  "jurisdiction-is-not-valid": "La juridiction n'est pas valide",
  "jurisdiction-is-required": "La compétence est requise",
  "one-time-vaccination-info-warning": "L'ajout de ces informations est une action unique et ne peut pas être modifié une fois enregistré.",
  "amt-administered": "Montant. administré",
  "manufacturer": "Fabricant",
  "lot-nr": "Parcelle #",
  "amt-administered-is-required": "Montant. administré est requis",
  "manufacturer-is-required": "Le fabricant est requis",
  "lot-nr-is-required": "Le numéro de lot est requis",
  "exp-date": "Exp. date",
  "date-of-vis": "Date de la VIS",
  "date-administered": "Date d'administration",
  "exp-date-required": "Exp. la date est requise",
  "date-of-vis-required": "La date du VIS est requise",
  "date-administered-required": "La date d'administration est requise",
  "other-location": "Autre location",
  "arm": "Bras",
  "leg": "Jambe",
  "other-option": "Autre...",
  "im": "IM",
  "sq": "SQ",
  "route": "Itinéraire",
  "other-route": "Autre itinéraire",
  "side": "Côté",
  "left": "Gauche",
  "right": "Droit",
  "location-required": "L'emplacement est requis",
  "route-required": "L'itinéraire est obligatoire",
  "side-required": "Le côté est obligatoire",
  "other-location-required": "Un autre emplacement est requis",
  "other-route-required": "Un autre itinéraire est requis",
  "user-authentication": "Authentification d'utilisateur",
  "no-items-to-display-for": "Aucun élément à afficher",
  "how-many-days-reminder-tooltip": "C'est combien de jours avant la date d'échéance du renouvellement un rappel d'échéance du renouvellement sera envoyé au patient",
  "how-many-days-past-due-reminder-tooltip": "C'est combien de jours après la date d'échéance du renouvellement, une notification de renouvellement en souffrance sera envoyée au patient",
  "home-phone": "Téléphone fixe",
  "other-phone": "Autre télephone",
  "cell-phone": "Téléphone portable",
  "health-videos": "Vidéos de santé",
  "view-all": "Voir tout",
  "SMS": "SMS",
  "use-same-message-for-email": "utiliser le même message pour l'e-mail",
  "each-word-capital-first-letter": "Assurez-vous que la première lettre de chaque mot est capitalisée!",
  "refills-default-chat-message": "Ce message concerne le Rx# {{rxNumber}} {{drugName}} pour {{fullName}}. ",
  "rx-transfer-request": "Demande de transfert Rx",
  "rx-transfer-request-description": "",
  "pharmacy-phone": "Téléphone de pharmacie",
  "patient-requesting-transfer": "Le patient demande son transfert",
  "patient-wants-refill": "Le patient veut qu'ils soient remplis",
  "due-date-required": "La date d'échéance est requise",
  "business-info": "Informations commerciales",
  "label-and-url-both-required": "Veuillez fournir des valeurs à la fois pour l'étiquette et l'URL, ou laisser les deux champs vides.",
  "url": "URL",
  "company": "Entreprise",
  "space-craft-id": "SpaceCraftID",
  "pid": "PID",
  "default-location-id": "DefaultLocationId",
  "g-analytics-key": "G.Analyticskey",
  "account-url": "URL du Compte",
  "refill-url": "URL de Recharge",
  "transfer-url": "URL de Transfert",
  "number-is-not-valid": "Veuillez saisir uniquement les numéros",
  "website": "Site web",
  "add-vaccination-error": "Impossible d'ajouter des informations sur la vaccination. Veuillez réessayer.",
  "vaccination-info-pharmacy-use-only": "Informations sur la vaccination (usage en pharmacie uniquement)",
  "immunizer": "Immunisateur",
  "enter-age": "Entrez l'âge",
  "select-filter": "Sélectionnez le filtre",
  "filter-is-required-field": "Le filtre est un champ obligatoire",
  "must-meet-all-criteria-below": "Doit répondre à tous les critères ci-dessous",
  "send-to-filtered-list": "Envoyer à la liste filtrée",
  "age-is-required-field": "L'âge est un champ obligatoire",
  "zip-code-is-required-field": "L'âge est un champ obligatoire",
  "entered-age-is-invalid": "L'âge saisi n'est pas valide",
  "one-or-more-invalid-zip-codes-entered": "1 ou plusieurs codes postaux invalides saisis",
  "use-commas-for-multiple-values": "Utiliser des virgules pour plusieurs valeurs",
  "enter-zip-codes": "Entrez les codes postaux",
  "should-be-selected-only-one-gender": "Doit être sélectionné uniquement 1 sexe",
  "age-is-older-than": "L'âge est plus âgé que",
  "age-is-younger-than": "L'âge est plus jeune que",
  "age-is": "L'âge est",
  "age-is-not": "L'âge n'est pas",
  "gender-is-male": "Le sexe est masculin",
  "gender-is-female": "Le sexe est féminin",
  "zip-code-is-one-of": "Le code postal est l'un des",
  "zip-code-is-not-one-of": "Le code postal n'est pas l'un des",
  "add-vaccination-info": "Ajouter des informations sur la vaccination",
  "email-subject": "Sujet du courriel*",
  "email-message": "Message électronique*",
  "sms-message": "SMS",
  "date-and-time": "Date et l'heure",
  "autofill-info-not-available": "Informations non disponibles",
  "more-videos": "Plus de vidéos",
  "marketing-or-non-marketing-bulk-text": "Choisissez « Marketing » si votre message contient du matériel promotionnel visant à vendre ou à faire la publicité de produits et services liés à la santé. Sinon, choisissez « Non marketing ».",
  "marketing": "Marketing",
  "non-marketing": "Hors marketing",
  "message-characters-limit-160": "La limite de caractères des messages est de 160!",
  "chat-message": "Message de discussion",
  "pharmacy-mgmt-system": "Pharmacie mgmt.système",
  "pharmacy-mgmt-system-tooltip": "Veuillez contacter l'assistance client pour mettre à jour votre système de gestion de la pharmacie",
  "marketing-messaging-phone": "SMS marketing",
  "auto-fill-my-address": "Remplit automatiquement mon adresse",
  "non-marketing-messaging-phone": "SMS non marketing",
  "legal-entity-name-tooltip": "Le nom de l'entreprise juridique sous la pharmacie.",
  "time-overlapped": "Le temps s'est chevauché!",
  "refill-reminder-error-modal-tooltip": "Cette description sera présentée au patient dans le cas où cette erreur se produirait.",
  "patient-name": "Nom du patient",
  "edit-app-details": "Modifier les détails de l'application",
  "edit-website-details": "Modifier les détails du site Web",
  "rx-transfer-option": "Option de transfert RX",
  "invalid-hex-length-use-6-digits": "Utiliser une valeur hexadécimale à 6 chiffres",
  "cannot-reuse-last-five-password": "Vous ne pouvez réutiliser aucun de vos 5 derniers mots de passe, veuillez en choisir un nouveau",
  "requires-rebuilding-app": "Nécessite une application de reconstruction",
  "welcome-to-refills": "Bienvenue dans les recharges",
  "refill-subtitle": "Toutes les recharges soumises via Lumistry apparaîtront ici.",
  "refills-bullet-point-title": "Façons d'inciter les patients à recharger à l'aide de Lumistry :",
  "tell-patients-about-app": "Parlez à vos patients de votre nouvelle application mobile",
  "send-email-patient-portal-account": "Envoyer des invitations par e-mail pour créer un compte sur le portail patient",
  "send-text-for-patients-download-app": "Envoyez des messages texte aux patients pour qu'ils téléchargent votre application",
  "announce-ivr-promoting-digital-solutions": "Ajoutez une annonce à votre message d'accueil IVR faisant la promotion de vos solutions numériques",
  "unable-to-process-complete-required-fields": "Impossible de traiter la demande, veuillez remplir les champs obligatoires",
  "find-first-name-last-name-email": "Trouver le Prénom, Nom de famille, E-mail",
  "jurisdiction-tooltip": "État ou juridiction dans lequel la société a été formée ou incorporée",
  "switch-to-this-location": "Passez à cet emplacement",
  "outbound-sms-cap": "Plafond des messages SMS sortants",
  "outbound-sms-used": "Messages SMS sortants utilisés",
  "outbound-email-cap": "Plafond des e-mails sortants",
  "outbound-email-used": "Messages électroniques sortants utilisés",
  "message-plans": "Plans de messages",
  "internal-use": "Utilisation interne",
  "total-overage-amount": "Montant total excédentaire ($)",
  "payment-and-delivery-title":"Paiement et livraison",
  "payment-and-delivery-description":"Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  "notify-me":"Prévenez-moi",
  "reached-sending-limit": "Vous avez atteint la limite d'envoi de SMS ou d'e-mails",
  'edit-message-plans':'Modifier les plans de messagerie',
  "sms-email-cap-reached": "La limite des SMS et des e-mails a été dépassée. Voir ",
  "sms-cap-reached": "La limite de messages SMS a été dépassée. Voir ",
  "email-cap-reached": "La limite des messages électroniques a été dépassée. Voir ",
  "patient-portal-url": "URL du portail des patients",
  "form-error-occurred": "Une erreur de formulaire s'est produite. Veuillez corriger les erreurs ci-dessous et réessayer.",
  "messages-reload-application": "Impossible d'envoyer le message. Veuillez recharger l'application.",
  "no-insurance-card": "Pas de carte d'assurance",
  "tax-ein-format-is-invalid": "Le format d'identification fiscale / EIN n'est pas valide",
  "signup-date": "Date d'inscription",
  "one-pickup-method-must-be-enabled": "Au moins une méthode de ramassage doit être activée.",
  "email-password-incorrect-try-forgot-password": "L'adresse électronique et/ou le mot de passe sont incorrects. Veuillez réessayer ou sélectionner 'Mot de passe oublié?' pour réinitialiser votre mot de passe.",
  "billing-settings": "Paramètres de facturation",
  "service-min-length-error-message": "La durée doit être d'au moins 5 minutes",
  "qr-code": "QR code",
  "app-is-live": "L'application est en direct",
  "view-image": "Voir l'image",
  "at-least-one-recipient-is-required": "Au moins un destinataire est requis",
  "billable-limits": "Limites facturables",
  "non-billable-usage": "Utilisation non facturable",
  "usage":"Utilisation",
  "review-message": "Message de révision",
  "recipients-list": "Liste des destinataires",
  "name-dob": "Nom, DDN",
  "action": "Action",
  "you-have-no-bulk-message-recipients": "Vous n'avez aucun destinataire. Revenez en arrière et modifiez la liste des destinataires ou annulez votre message.",
  "one-or-more-recipients-have-been-removed": "1 ou plusieurs destinataires ont été supprimés.",
  "no-name-data": "Aucune donnée de nom",
  "no-data": "Aucune donnée",
  "website-settings-url-tooltip": "Veuillez contacter l'assistance client pour mettre à jour votre URL",
  "date-is-required": "La date est requise",
  "edit-billable-limits":"Modifier Limites facturables",
  "general-error-message": "{{message}} que quelque chose a mal tourné",
  "failed": "Échoué",
  "prescription-entered-already-exist-contact-pharmacy": "Le numéro d'ordonnance que vous avez saisi est déjà associé à un compte de portail patient existant. Veuillez essayer une autre ordonnance ou contacter la pharmacie pour obtenir de l'aide.",
  "email-was-registered-successfully": "L'e-mail a été enregistré avec succès.",
  "email-is-already-registered": "L'e-mail est déjà enregistré.",
  "apple-developer-account": "Compte de développeur Apple",
  "apple-developer-account-required": "Compte de développeur Apple requis!",
  "apple-developer-account-tooltip": "Trouvez-le dans votre compte de développeur Apple, c'est le nom de l'entité juridique auquel ce compte correspond",
};

export default mapping;
