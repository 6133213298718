import { FunctionComponent, PropsWithChildren } from 'react';
import theme, { makeStyles } from 'assets/theme';
import { Form, InternalScreenContainer } from 'assets/layout';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';
import { getText } from 'assets/localization/localization';
import { TransferMedsTestIDs } from './TransferMedsTestIDs';
import { Divider } from 'react-native-paper';
import { useTransferMedicationsStore } from './transfer-meds-store';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { InfoCard } from 'assets/components/info-card';
import { PillsIcon } from 'assets/icons';
import { StackHeaderProps } from '@react-navigation/stack';

export const AboutTransfer: FunctionComponent<
  PropsWithChildren<TransferMedicationsNavigationProp>
> = ({ navigation }) => {
  const styles = useStyles();
  const { updateTransferMedsData } = useTransferMedicationsStore();

  const methods = useForm({
    defaultValues: {
      transferSelection: '',
    },
  });

  const handleOnPress = () => {
    if (!updateTransferMedsData) return;
    if (methods.getValues().transferSelection === 'All medications') {
      updateTransferMedsData({
        allMedications: true,
      });
      navigation.navigate('transfer-medications', {
        screen: 'review-transfer',
      });
    } else {
      navigation.navigate('transfer-medications', {
        screen: 'enter-medications',
      });
    }
  };

  return (
    <InternalScreenContainer
      title={getText('transfer-meds-header-title')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: methods.handleSubmit(handleOnPress),
          testID: TransferMedsTestIDs.nextButton,
          logger: { id: TransferMedsTestIDs.nextButton },
          text: getText('next'),
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Alert
          visible={!!methods.getFieldState('transferSelection').error}
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
        />
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(2) }}>
            <View>
              <Text style={styles.title}>{getText('about-your-transfer')}</Text>
            </View>
            <View>
              <Divider />
            </View>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View>
              <Text style={styles.label}>
                {getText('what-meds-are-you-transferring')}
              </Text>
            </View>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              rules={{
                required: getText('field-required'),
              }}
              name="transferSelection"
              values={[
                {
                  text: getText('all-my-medications'),
                  value: 'All medications',
                },
                {
                  text: getText('some-of-my-medications'),
                  value: 'Some medications',
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(2) }}>
            <InfoCard
              icon={PillsIcon}
              title={getText('why-transfer-all-the-meds')}
              description={getText('why-transfer-all-the-meds-description')}
              size={80}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </InternalScreenContainer>
  );
};

export type TransferMedicationsNavigationProp = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  label: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
}));
