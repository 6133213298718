import theme, { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useEffect,
} from 'react';
import { Platform, ScrollView, View, useWindowDimensions } from 'react-native';
import { WebFooter } from '../../../../apps/mobile/modules/components/web-footer/WebFooter';
import { Button, ButtonProps, hierarchyOrder } from '../../components/button';
import { Text } from '../../components/text';
import { calculateScreenWidth } from '../../utils/screenContainerUtils';
import { ScreenContainer, ScreenContainerProps } from './ScreenContainer';

export const InternalScreenContainer: FunctionComponent<
  PropsWithChildren<InternalScreenContainerProps>
> = ({ children, scrollToTop = false, ...props }) => {
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const hasButtons = props.buttons && props.buttons.length > 0;

  const scrollViewRef = useRef<any>();

  useEffect(() => {
    if (scrollToTop) {
      scrollViewRef.current?.scrollTo({ y: 0, animated: true });
    }
  }, [scrollToTop]);

  const sortedButtons = props.buttons
    ? props.buttons.sort((a, b) => {
        return Platform.OS === 'web'
          ? hierarchyOrder[a.hierarchy] - hierarchyOrder[b.hierarchy]
          : hierarchyOrder[b.hierarchy] - hierarchyOrder[a.hierarchy];
      })
    : [];

  return (
    <>
      {Platform.OS !== 'web' ? (
        <ScreenContainer
          {...props}
          backgroundColor={theme.palette.white}
          ref={scrollViewRef}
        >
          <View style={styles.content}>
            {children}
            {hasButtons && (
              <View style={styles.buttonContainer}>
                {sortedButtons.map((button, index) => (
                  <View key={index}>
                    <Button {...button}>{button.text}</Button>
                  </View>
                ))}
              </View>
            )}
          </View>
        </ScreenContainer>
      ) : (
        <View
          style={[
            {
              backgroundColor: theme.palette.gray[50],
            },
            styles.container,
            props.style,
          ]}
        >
          <View
            style={[
              styles.webContent,
              {
                width: calculateScreenWidth(600, width, false, 40),
              },
            ]}
          >
            {props.title && (
              <View style={styles.header}>
                <Text style={styles.headerText}>{props.title}</Text>
              </View>
            )}
            <ScrollView
              style={styles.viewRenderer}
              contentContainerStyle={styles.contentContainer}
              ref={scrollViewRef}
            >
              {children}
            </ScrollView>
            {hasButtons && (
              <View style={styles.webButtonContainer}>
                {sortedButtons.map((button, index) => (
                  <View style={styles.webButton} key={index}>
                    <Button {...button}>{button.text}</Button>
                  </View>
                ))}
              </View>
            )}
          </View>
          {/* BLUES-5385  hide the footer from all web pages until there is a need for it */}
          {/* {props.showFooter && (
            <WebFooter
              phone="(888) 699-9803"
              email="help@digitalpharmacist.com"
              fax="(877) 456-2345"
            />
          )} */}
        </View>
      )}
    </>
  );
};

export interface InternalScreenContainerProps extends ScreenContainerProps {
  buttons?: InternalScreenButtonProps[];
  scrollToTop?: boolean;
}

export interface InternalScreenButtonProps extends Omit<ButtonProps, 'text'> {
  text: string;
}

const useStyles = makeStyles((theme) => ({
  webContent: {
    borderRadius: theme.roundness,
    shadowColor: theme.palette.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    flex: 1,
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 3,
    gap: theme.getSpacing(2),
    alignSelf: 'center',
    backgroundColor: theme.palette.white,
    padding: theme.getSpacing(2),
    marginVertical: 40,
  },
  container: {
    flex: 1,
    height: '100%',
  },
  content: {
    flex: 1,
    alignSelf: 'stretch',
  },
  header: {
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  contentContainer: {
    flexGrow: 1,
  },
  headerText: {
    ...theme.lumistryFonts.text.large.semiBold,
  },
  viewRenderer: {
    flex: 1,
  },
  webButtonContainer: {
    backgroundColor: theme.palette.white,
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    bottom: 0,
    flexWrap: 'wrap',
    gap: theme.getSpacing(1),
  },
  webButton: {
    minWidth: 120,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
    flexDirection: 'column',
    gap: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
  },
}));
