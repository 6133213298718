import {
  ForwardRefRenderFunction,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import React from 'react';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { BottomSheet, BottomSheetProps } from 'assets/components/bottom-sheet';
import { View, ViewStyle } from 'react-native';
import { useTheme } from 'react-native-paper';
import { makeStyles } from 'assets/theme';
import { CloseIcon } from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import { ModalTestIDs } from 'assets/components/modal';
import { BackdropPressBehavior } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetBackdrop/types';
import {
  BaseModalButtonProps,
  BaseModalHandler,
} from 'assets/components/base-modal/BaseModal';
import { ScrollView } from 'react-native';

export const NativeModalView: ForwardRefRenderFunction<
  BaseModalHandler,
  NativeModalProps
> = (props: NativeModalProps, ref: ForwardedRef<BaseModalHandler>) => {
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const styles = useStyles();
  const theme = useTheme();
  const buttonOrientation =
    props.buttonOrientation ||
    (props.buttons && props.buttons.length >= 3
      ? NativeButtonOrientation.Vertical
      : NativeButtonOrientation.Horizontal); //NOTE: set orientation to be vertical when there are more +3 buttons to
  const buttons = props.buttons;
  const height = props.height ? props.height : '100%';

  useImperativeHandle(ref, () => ({
    show: () => {
      sheetRef.current?.present();
    },
    hide: () => sheetRef.current?.dismiss(),
  }));

  const handleBottomSheetDismiss = () => {
    if (props.onClose) props.onClose();
    sheetRef.current?.dismiss();
  };

  const scrollViewRef = useRef<any>();

  useEffect(() => {
    if (props.scrollToTop) {
      scrollViewRef.current?.scrollTo({ y: 0, animated: true });
    }
  }, [props.scrollToTop]);

  return (
    <BottomSheet
      bottomSheetRef={sheetRef}
      height={height}
      onDismiss={handleBottomSheetDismiss}
      enablePanDownToClose={props.allowSwipe}
      backdropPressBehavior={props.backdropPressBehavior}
      enableOverDrag={props.enableOverDrag}
      headerContent={
        <View style={styles.headerContent}>
          <View style={styles.headerText}>
            <Text style={styles.title}>{props.title}</Text>
          </View>
          {props.showDismissButton && (
            <View style={styles.headerIconStyle}>
              <IconButton
                icon={CloseIcon}
                onPress={() => {
                  handleBottomSheetDismiss();
                }}
                size={20}
                color={theme.palette.gray[500]}
                logger={{
                  id: 'bottom-sheet-close-button',
                }}
              />
            </View>
          )}
        </View>
      }
      footerContent={
        <View
          style={
            buttonOrientation === NativeButtonOrientation.Vertical
              ? styles.buttonContainerVertical
              : styles.buttonContainerHorizontal
          }
        >
          {buttons &&
            buttons.map((button, index) => (
              <View
                testID={ModalTestIDs.getButtonIds(index)} //TODO Think of something better here?!
                key={index}
                style={
                  buttonOrientation === NativeButtonOrientation.Vertical
                    ? styles.buttonContainer
                    : styles.buttonContainer
                }
              >
                <Button accessibilityLabel={button.text} {...button}>
                  {button.text}
                </Button>
              </View>
            ))}
        </View>
      }
      {...props.bodySheetProps}
    >
      {!props.backdropPressBehavior ? (
        <ScrollView ref={scrollViewRef} contentContainerStyle={{ flexGrow: 1 }}>
          <View
            style={[
              {
                marginRight: theme.getSpacing(1),
                marginLeft: theme.getSpacing(1),
              },
              props.contentContainerStyles,
            ]}
          >
            {props.children}
          </View>
        </ScrollView>
      ) : (
        <View
          style={[
            {
              marginRight: theme.getSpacing(1),
              marginLeft: theme.getSpacing(1),
            },
            props.contentContainerStyles,
          ]}
        >
          {props.children}
        </View>
      )}
    </BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  // TODO styling
  buttonContainer: {
    alignContent: 'flex-end',
    marginTop: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
    marginStart: theme.getSpacing(1),
    flex: 1,
  },
  buttonContainerHorizontal: {
    alignContent: 'center',
    marginTop: theme.getSpacing(1),
    flexDirection: 'row-reverse',
  },
  buttonContainerVertical: {},
  buttonHorizontal: {
    marginTop: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
  },
  title: {
    ...theme.lumistryFonts.text.large.semiBold,
    color: theme.palette.gray[900],
    alignContent: 'center',
    alignSelf: 'center',
  },
  headerText: {
    alignContent: 'center',
    flex: 1,
    marginLeft: theme.getSpacing(4),
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.getSpacing(4),
  },
  headerIconStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

export const NativeModal = forwardRef<BaseModalHandler, NativeModalProps>(
  NativeModalView,
);

export type NativeModalProps = PropsWithChildren<{
  title: string;
  ref?: ForwardedRef<BaseModalHandler>;
  buttons?: BaseModalButtonProps[];
  isScrollable?: boolean;
  showDismissButton?: boolean;
  scrollToTop?: boolean;

  buttonOrientation?: NativeButtonOrientation;
  height?: string;

  allowSwipe: boolean;

  backdropPressBehavior: BackdropPressBehavior;
  enableOverDrag: boolean;

  bodySheetProps?: Pick<BottomSheetProps, 'contentContainerStyle'>;
  contentContainerStyles?: ViewStyle;

  onClose?: () => void;
  testID: string;
}>;

export enum NativeButtonOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}
