import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useTheme } from 'assets/theme';
import { View } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Form, ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from '../../navigation/AuthNavigation';
import { StoreInformation } from '../../components/store-selector/StoreInformation';
import { MapSize } from '../../components/store-selector/types';
import { useAppStateStore } from '../../store/app-store';
import { IconButton } from 'react-native-paper';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { updateLocationDetails } from './register-actions';

export const RegisterStoreDetails: FunctionComponent<
  PropsWithChildren<RegisterStoreDetailsProps>
> = ({ route }) => {
  const theme = useTheme();
  const isFocused = useIsFocused();
  const { storeId } = route.params as any;
  const navigation = useNavigation<AuthStackNavigationProp>();
  const { stores } = useAppStateStore();
  const [currentStore, setCurrentStore] = useState<PharmacyLocationDto>();

  const handleOnChange = () => {
    navigation.navigate('patient-stores');
  };

  useEffect(() => {
    updateLocationDetails(storeId);
  }, []);

  useEffect(() => {
    setCurrentStore(stores.find((x) => x.id === (storeId as string)));
  }, [storeId, stores, isFocused]);

  return (
    <ScreenContainer useBottomInset={true} useTopInset={true}>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 18,
            lineHeight: 28,
            fontWeight: '600',
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(2),
            textAlign: 'center',
            flex: 1,
          }}
        >
          {getText('store-info')}
        </Text>
        <IconButton
          onPress={() => navigation.goBack()}
          icon={CloseIcon}
          color={theme.palette.gray[500]}
        />
      </View>
      {currentStore && (
        <Form.Row>
          <Form.Column>
            <StoreInformation
              item={currentStore}
              onChangePress={handleOnChange}
              mapProps={{
                size: MapSize.lg,
              }}
              showFullDetails={true}
            />
          </Form.Column>
        </Form.Row>
      )}
    </ScreenContainer>
  );
};

type RegisterStoreDetailsProps = NativeStackScreenProps<
  AuthStackParamList,
  'patient-pharmacy-stores'
>;
