import pharmacyService from '../../../api/pharmacy-service';
import { LocationSearch } from '../../../types';
import { isZipCode } from '../utils';

// TODO: refactor in a better place
export const handleLocationDistanceSearch = async (search?: LocationSearch) => {
  const _isZipCode = isZipCode(search?.searchCriteria);

  try {
    if (!_isZipCode && !search?.latitude)
      throw Error('You should provide a zip code or user location!');

    const response = await pharmacyService.findLocationsByPharmacyId(
      undefined,
      undefined,
      undefined,
      undefined,
      `${search?.longitude}`,
      `${search?.latitude}`,
      search?.searchCriteria,
      undefined,
    );
    return response.locations;
  } catch (error) {
    // log error
    return [];
  }
};
