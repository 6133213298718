import { FunctionComponent, useEffect } from 'react';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { View } from 'react-native';
import theme, { getSpacing } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useRegisterState } from './register-store';
import { CreateAccountForm, createPassword } from './register-actions';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  CreatePasswordProps,
  LoginStackNavigationProp,
} from '../../navigation/LoginNavigation';
import { CreatePasswordTestIDs } from './RegisterTestIDs';
import { PasswordRules } from 'assets/common/PasswordRules';
import { getText } from 'assets/localization/localization';
import { ScreenContainer, Form } from 'assets/layout';
import { isPassword } from '@digitalpharmacist/validation-dp';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';

export const CreatePassword: FunctionComponent<CreatePasswordProps> = ({
  route,
}) => {
  const { temporaryUserRegistrationId } = route.params || {};

  const methods = useForm<CreateAccountForm>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });

  const passwordValue = methods.watch('password');
  const { status, error } = useRegisterState();
  const navigation = useNavigation<AuthStackNavigationProp>();
  const loginNav = useNavigation<LoginStackNavigationProp>();

  const handleSubmit = async () => {
    createPassword(methods.getValues(), navigation);
  };

  const handleGoToLogin = async () => {
    loginNav.navigate('intro');
  };

  //If this page is loaded without requiredd params, redirect to login
  useFocusEffect(() => {
    if (!temporaryUserRegistrationId) {
      handleGoToLogin();
    }
  });

  useEffect(() => {
    methods.setValue(
      'temporary_user_registration_id',
      temporaryUserRegistrationId,
    );
  }, [temporaryUserRegistrationId]);

  return (
    <ScreenContainer useBottomInset={true} keyboardVerticalOffset={150}>
      <View>
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 30,
            paddingTop: getSpacing(2),
            paddingBottom: getSpacing(1),
            textAlign: 'center',
          }}
        >
          {getText('create-new-password')}
        </Text>
        <Text
          style={{
            color: theme.palette.gray[600],
            fontSize: 16,
            paddingBottom: getSpacing(2),
            textAlign: 'center',
          }}
        >
          {getText('please-enter-your-new-password')}
        </Text>
      </View>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              testID={CreatePasswordTestIDs.passwordInput}
              label={getText('new-password')}
              name="password"
              rules={{
                required: getText('password-is-required'),
                validate: {
                  value: () => {
                    return isPassword(methods.getValues().password)
                      ? true
                      : getText('password-entry-does-not-meet-criteria');
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              testID={CreatePasswordTestIDs.passwordConfirmInput}
              label={getText('confirm-new-password')}
              name="passwordConfirm"
              rules={{
                required: getText('password-is-required'),
                validate: {
                  value: () => {
                    return methods.getValues().password ===
                      methods.getValues().passwordConfirm
                      ? true
                      : getText('passwords-must-match');
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Content>
          <PasswordRules passwordValue={passwordValue}></PasswordRules>
        </Form.Content>
        <Form.Actions>
          <View style={{ marginBottom: getSpacing(2) }}>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === 'loading'}
              logger={{ id: 'create-password-submit-button' }}
            >
              {getText('create-account')}
            </Button>
          </View>
          <View style={{ marginBottom: getSpacing(2) }}>
            <Button
              onPress={handleGoToLogin}
              hierarchy="tertiary"
              logger={{ id: 'create-password-back-to-login-button' }}
            >
              {getText('go-to-login')}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};
