import { UserNotificationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import { IconButton } from 'assets/components/icon-button';
import { BellIcon } from 'assets/icons';
import { logError } from 'assets/logging/logger';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Card } from 'react-native-paper';
import { useAppStateStore } from '../../store/app-store';
import { NotificationsEmptyState } from './NotificationsEmptyState';
import {
  handleMarkAllAsRead,
  handleMarkAsHidden,
  handleMarkAsRead,
  handleMarkAsUnread,
  handleNavigationScreen,
} from '../../actions/notification-actions';
import { refreshNotifications } from '../../actions/app-actions';
import { useOutsideClick } from './hooks/useOutsideClick';
import React from 'react';
import { NotificationList } from '../notifications/NotificationList';

export const NotificationsListWeb: FunctionComponent<
  PropsWithChildren<NotificationsListWebProps>
> = ({ notifications }) => {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);
  const styles = useStyles();
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const { status } = useAppStateStore();

  const ref = useOutsideClick(() => {
    setShowMenu(false);
  });

  const handleOpenMenu = async () => {
    try {
      await refreshNotifications();
    } catch {
      logError(new Error('Failed to get notifications'));
    }
    setShowMenu((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  return (
    <View ref={ref as any}>
      <IconButton
        icon={BellIcon}
        logger={{ id: 'notifications-list-web-icon-button' }}
        onPress={handleOpenMenu}
        color={theme.palette.white}
      />
      {showMenu && (
        <Card style={styles.menu}>
          <ScrollView style={styles.list}>
            {notifications.length > 0 ? (
              <NotificationList
                isLoading={status === 'loading'}
                closeMenu={handleCloseMenu}
                refreshNotifications={refreshNotifications}
                notifications={notifications}
                markAsHidden={handleMarkAsHidden}
                markAsRead={handleMarkAsRead}
                markAllAsRead={handleMarkAllAsRead}
                markAsUnread={handleMarkAsUnread}
                navigation={navigation}
                handleNavigationScreen={handleNavigationScreen}
              />
            ) : (
              <NotificationsEmptyState />
            )}
          </ScrollView>
        </Card>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: 65,
    maxHeight: 700,
    minHeight: 200,
    minWidth: 400,
    borderRadius: theme.roundness,
    overflow: 'hidden',
    boxShadow:
      ' 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
  },
  list: {
    paddingVertical: getSpacing(2),
    paddingHorizontal: getSpacing(2),
  },
}));

export interface NotificationsListWebProps {
  notifications: UserNotificationDto[];
}
