import * as Sentry from 'sentry-expo';

export const initLogger = (environment: SentryEnvironment) => {
  const isStagingOrUat = environment === 'staging' || environment === 'uat';
  const isDevelopment = environment === 'development';
  Sentry.init({
    dsn: 'https://2d043b1d3bfc1a1f7ea6a8f44470ad9a@o4505637551210496.ingest.sentry.io/4505637553373184',
    enableInExpoDevelopment: isStagingOrUat,
    debug: isStagingOrUat,
    environment: environment,
    enabled: !isDevelopment,
  });
};

export type SentryEnvironment =
  | 'development'
  | 'staging'
  | 'uat'
  | 'production';
