import { Button } from 'assets/components/button/Button';
import { Icon } from 'assets/components/icon';
import { CloseIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import {
  Modal,
  Platform,
  TouchableOpacity,
  View,
  Image,
  Dimensions,
} from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';

type Props = {
  visible: boolean;
  onClose: () => void;
  url: string;
};

export default function BaseImageViewer({ visible, onClose, url }: Props) {
  const theme = useTheme();
  const styles = useStyles();

  const mobileCloseButton = () => {
    return Platform.OS !== 'web' ? (
      <View style={styles.mobileIconStyle}>
        <TouchableOpacity onPress={() => onClose()}>
          <Icon color={theme.palette.white} size={24} icon={CloseIcon} />
        </TouchableOpacity>
      </View>
    ) : (
      <></>
    );
  };

  const customImage = () => {
    return (
      <View style={styles.customImageContainer}>
        <Image
          source={{ uri: url }}
          resizeMode={'contain'}
          style={styles.fullDimensions}
        />
      </View>
    );
  };

  const renderImage = Platform.OS === 'web' ? () => customImage() : undefined;

  return (
    <Modal visible={visible}>
      {Platform.OS === 'web' && (
        <View style={styles.closeModalContainer}>
          <Button
            onPress={() => onClose()}
            logger={{ id: 'close-image-viewer' }}
            hierarchy="primary"
          >
            {getText('close')}
          </Button>
        </View>
      )}
      {url && (
        <ImageViewer
          imageUrls={[{ url: url }]}
          renderImage={renderImage}
          index={0}
          onSwipeDown={() => onClose()}
          enableSwipeDown={true}
          renderIndicator={() => mobileCloseButton()}
          saveToLocalByLongPress={Platform.OS !== 'web'}
        />
      )}
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  closeModalContainer: {
    margin: theme.getSpacing(1),
    alignSelf: 'flex-end',
  },
  mobileIconStyle: {
    position: 'absolute',
    top: 45,
    right: 25,
  },
  webImage: {
    width: Dimensions.get('window').width * 0.75, // Set custom width
    height: Dimensions.get('window').height / 0.75, // Set custom height
    alignSelf: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  mobileImage: {
    width: Dimensions.get('window').width,
    height: 350,
    alignSelf: 'center',
  },
  customImageContainer: {
    width: '100%',
    height: '80%',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: 'auto',
  },
  fullDimensions: {
    width: '100%',
    height: '100%',
  },
}));
