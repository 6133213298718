import { NavigationContainerRef } from '../context';

/**
 * https://reactnavigation.org/docs/navigating-without-navigation-prop/#handling-initialization
 * @returns
 */
export function useAppNavigation<T>() {
  if (NavigationContainerRef.isReady()) {
    // Perform navigation if the react navigation is ready to handle actions
    return NavigationContainerRef as T;
  } else {
    // You can decide what to do if react navigation is not ready
    // You can ignore this, or add these actions to a queue you can call later
  }
}
