import { useTheme } from '../../theme';
import { ButtonHierarchy, ButtonSizes } from './Button';

export function useButtonVariantMappings() {
  const theme = useTheme();

  const buttonBackgroundColorMappings = new Map<
    ButtonHierarchy,
    { disabled: string; enabled: string }
  >([
    [
      'primary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'secondary',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'secondary-mixed',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'secondary-gray',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'tertiary',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
    [
      'tertiary-gray',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
    [
      'destructive',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
    [
      'destructive-tertiary',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
  ]);

  const buttonBorderColorMappings = new Map<
    ButtonHierarchy,
    { disabled: string; enabled: string }
  >([
    [
      'primary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'secondary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'secondary-mixed',
      {
        enabled: theme.palette.gray[300],
        disabled: theme.palette.gray[100],
      },
    ],
    [
      'secondary-gray',
      { enabled: theme.palette.gray[300], disabled: theme.palette.gray[100] },
    ],
    [
      'tertiary',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
    [
      'tertiary-gray',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
    [
      'destructive',
      { enabled: theme.palette.error[500], disabled: theme.palette.error[200] },
    ],
    [
      'destructive-tertiary',
      {
        enabled: theme.palette.transparent,
        disabled: theme.palette.transparent,
      },
    ],
  ]);

  const buttonTextColorMappings = new Map<
    ButtonHierarchy,
    { disabled: string; enabled: string }
  >([
    [
      'primary',
      { enabled: theme.palette.white, disabled: theme.palette.white },
    ],
    [
      'secondary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'secondary-mixed',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimary,
      },
    ],
    [
      'secondary-gray',
      { enabled: theme.palette.gray[700], disabled: theme.palette.gray[300] },
    ],
    [
      'tertiary',
      {
        enabled: theme.colors.brandedPrimary,
        disabled: theme.colors.brandedPrimaryDisabled,
      },
    ],
    [
      'tertiary-gray',
      { enabled: theme.palette.gray[700], disabled: theme.palette.gray[300] },
    ],
    [
      'destructive',
      { enabled: theme.palette.error[500], disabled: theme.palette.error[200] },
    ],
    [
      'destructive-tertiary',
      { enabled: theme.palette.error[500], disabled: theme.palette.error[200] },
    ],
  ]);

  const buttonModeMappings = new Map<ButtonHierarchy, 'contained' | 'outlined'>(
    [
      ['primary', 'contained'],
      ['secondary', 'outlined'],
      ['secondary-mixed', 'outlined'],
      ['secondary-gray', 'outlined'],
      ['tertiary', 'outlined'],
      ['tertiary-gray', 'outlined'],
      ['destructive', 'outlined'],
      ['destructive-tertiary', 'outlined'],
    ],
  );

  const buttonHeightMappings = new Map<ButtonSizes, number>([
    ['small', 8],
    ['medium', 10],
    ['large', 10],
    ['extra-large', 12],
    ['extra-extra-large', 14],
  ]);

  const buttonWidthMappings = new Map<ButtonSizes, number>([
    ['small', 90],
    ['medium', 100],
    ['large', 120],
    ['extra-large', 130],
    ['extra-extra-large', 140],
  ]);

  const buttonTextSizeMappings = new Map<ButtonSizes, number>([
    ['small', 14],
    ['medium', 14],
    ['large', 16],
    ['extra-large', 16],
    ['extra-extra-large', 18],
  ]);

  const buttonTextLineHeightMappings = new Map<ButtonSizes, number>([
    ['small', 20],
    ['medium', 20],
    ['large', 24],
    ['extra-large', 24],
    ['extra-extra-large', 28],
  ]);

  return {
    buttonBackgroundColorMappings,
    buttonBorderColorMappings,
    buttonTextColorMappings,
    buttonModeMappings,
    buttonHeightMappings,
    buttonWidthMappings,
    buttonTextSizeMappings,
    buttonTextLineHeightMappings,
  };
}
