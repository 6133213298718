import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { makeStyles } from 'assets/theme';
import { View } from 'react-native';
import { Modal } from 'assets/components/modal';
import {
  PharmacyLocationDto,
  NormalTimeRangeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import { StoreInformation } from './StoreInformation';
import {
  MapSize,
  StoreSelectorItemHandler,
  StoreSelectorItemInformationProps,
} from './types';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export const StoreSelectorInformationModal = forwardRef<
  StoreSelectorItemHandler,
  StoreSelectorItemInformationProps
>(
  (
    {
      currentDayOpenHours = (hoursAsStr: NormalTimeRangeDto[]) => '',
      daysAsStr = (hours: NormalTimeRangeDto) => '',
    }: StoreSelectorItemInformationProps,
    ref: ForwardedRef<StoreSelectorItemHandler>,
  ) => {
    const styles = useStyles();

    const [item, setItem] = useState<PharmacyLocationDto>();
    const modalRef = useRef<BaseModalHandler>(null);
    const handleShowModal = (item: PharmacyLocationDto) => {
      setItem(item);
      modalRef.current?.show();
    };

    const handleDismissModal = () => {
      modalRef.current?.hide();
      setItem(undefined);
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    return (
      <GenericModal
        webModalSize="sm"
        title={getText('store-info')}
        ref={modalRef}
        buttons={[
          {
            onPress: handleDismissModal,
            logger: { id: 'cancel-button-modal-store-selector' },
            hierarchy: 'secondary',
            text: getText('cancel'),
          },
        ]}
        isScrollable
        showDismissButton
      >
        <View>
          {item && (
            <StoreInformation
              item={item}
              onChangePress={() => {}}
              mapProps={{
                size: MapSize.lg,
              }}
              showFullDetails={true}
            />
          )}
        </View>
      </GenericModal>
    );
  },
);

const useStyles = makeStyles(() => ({
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
}));
