export const TransferMedsTestIDs = {
  pharmacyNameTextField: 'pharmacy-name-text-field',
  pharmacyNumberTextField: 'pharmacy-number-text-field',
  drugNameTextField: 'drug-name-text-field',
  whosePrescriptionDropdown: 'whose-prescription-dropdown',
  checkbox: 'checkbox',
  nextButton: 'next-button',
  doneButton: 'done-button',
  reviewButton: 'review-button',
  submitButton: 'submit-button',
  startOverButton: 'start-over-button',
  transferAnotherButton: 'transfer-another-button',
};
