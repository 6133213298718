import { useEffect, useState } from 'react';
import unifiedCommsService from '../api/unified-comms-service';
import { useUserState } from '../store/user-store';

export const useShowNewMessageButton = () => {
  const user = useUserState((state) => state.user);

  const [show, setShow] = useState(false);

  useEffect(() => {
    void (async () => {
      if (!user || !user.preferredPharmacyLocationId) {
        return;
      }

      const { is_patient_start_chat_available } =
        await unifiedCommsService.getDirectMessageSettings(
          user.pharmacyId,
          user.preferredPharmacyLocationId,
        );

      setShow(is_patient_start_chat_available);
    })();
  }, [user]);

  return show;
};
