import theme from 'assets/theme';
import { CSSProperties, FunctionComponent } from 'react';

export const IFrame: FunctionComponent<IFrameProps> = ({ src, style }) => {
  return (
    <iframe
      style={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.gray[500],
        borderRadius: theme.getSpacing(1),
        outline: 'none',
        boxSizing: 'border-box',
        boxShadow: 'none',
        height: '65vh',
        ...style,
      }}
      src={src}
      height="100%"
      width="100%"
    />
  );
};

interface IFrameProps {
  src: string;
  style?: CSSProperties;
}
