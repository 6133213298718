import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  Pressable,
  Platform,
} from 'react-native';
import { useTheme, makeStyles } from '../../theme';
import { Text } from '../text';
import BaseRadioButton, { BaseRadioButtonProps } from './BaseRadioButton';

export const RadioButton = <TValue extends RadioButtonValue = string>({
  selected,
  disabled,
  textColor,
  onValueChange,
  value,
  text,
  isLast,
  description,
  testID,
  mode = RadioButtonInputMode.OUTLINE,
}: PropsWithChildren<RadioButtonProps<TValue>>) => {
  const theme = useTheme();
  const styles = useStyles();
  const currentStateColor = disabled
    ? theme.palette.gray[300]
    : selected
    ? theme.palette.primary[500]
    : theme.palette.gray[700];

  return (
    <RadioButtonWrapper
      testID={testID}
      style={[
        styles.touchableContainer,
        mode === RadioButtonInputMode.OUTLINE && styles.outline,
        mode === RadioButtonInputMode.OUTLINE &&
          styles.touchableContainerWithDescription,
        mode === RadioButtonInputMode.OUTLINE &&
          styles.outlineContainerPaddings,
        mode === RadioButtonInputMode.FLAT && styles.flatContainerPaddings,
        selected && !disabled && styles.selected,
        isLast && styles.touchableContainerLast,
      ]}
      mode={mode}
      onPress={() => {
        if (disabled) return;
        if (onValueChange) {
          onValueChange(value);
        }
      }}
      disabled={disabled}
    >
      <Pressable
        style={styles.radioButtonContainer}
        onPress={() => {
          if (disabled) return;
          if (onValueChange) {
            onValueChange(value);
          }
        }}
      >
        <BaseRadioButton selected={selected} disabled={disabled} />
      </Pressable>
      <View style={styles.textContainer}>
        <View>
          <Text
            style={[styles.text, { color: textColor ?? currentStateColor }]}
          >
            {text}
          </Text>
        </View>
        {description && (
          <View>
            <Text style={styles.description}>{description}</Text>
          </View>
        )}
      </View>
    </RadioButtonWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  radioButtonContainer: {
    marginRight: theme.getSpacing(2),
  },
  touchableContainer: {
    flexDirection: 'row',
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
  },
  flatContainerPaddings: {
    padding: 0,
    paddingRight: theme.getSpacing(2),
  },
  outlineContainerPaddings: {
    padding: theme.getSpacing(2),
  },
  outline: {
    borderRadius: theme.roundness,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
  },
  touchableContainerWithDescription: {
    minHeight: 58,
  },
  touchableContainerLast: {
    marginBottom: 0,
  },
  selected: {
    color: theme.palette.primary[500],
    borderColor: theme.palette.primary[500],
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  text: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
    alignSelf: 'center',
  },
  description: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[500],
    marginTop: theme.getSpacing(0.5),
  },
}));

export interface RadioButtonProps<TValue extends RadioButtonValue>
  extends BaseRadioButtonProps {
  text: string;
  value: TValue;
  textColor?: string;
  onValueChange: (value: TValue) => void;
  isLast?: boolean;
  description?: string;
  mode?: RadioButtonInputMode;
  testID?: string;
}

export type RadioButtonValue = string | boolean;

export default RadioButton;

interface RadioButtonWrapperProps {
  mode?: RadioButtonInputMode;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  testID?: string;
  onPress?: () => void;
}

const RadioButtonWrapper: FunctionComponent<
  PropsWithChildren<RadioButtonWrapperProps>
> = ({ children, mode, style, disabled, testID, onPress }) => {
  // TODO: find a better way to write this with generics
  return mode === RadioButtonInputMode.FLAT ? (
    <View style={style} testID={testID}>
      {children}
    </View>
  ) : (
    <TouchableOpacity
      disabled={disabled}
      style={style}
      onPress={onPress}
      testID={testID}
    >
      {children}
    </TouchableOpacity>
  );
};

export enum RadioButtonInputMode {
  FLAT = 'flat',
  OUTLINE = 'outline',
}
