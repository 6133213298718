import {
  AutoFillRequestStatus,
  PrescriptionDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { requestAutoFill } from '../../screens/medications/medication-actions';
import { getLocationServiceConfigValueBySlug } from '../../actions/app-actions';

export const MedicationAutoFillInfo: FunctionComponent<
  MedicationAutoFillInfoProps
> = ({ prescription, onChange }) => {
  const styles = useStyles();
  type StyleTypes = (typeof styles)[keyof typeof styles];
  const [isLoading, setIsLoading] = useState(false);
  const { auto_fill_enrolled, auto_fill_request_status } = prescription;
  const isAutoFillEnabled =
    getLocationServiceConfigValueBySlug('auto-refill') === 'auto-refill-opt';

  const autoFillStatusOptions: Record<
    AutoFillStatusTypes,
    { text: string; style?: StyleTypes; isActionable?: boolean }
  > = {
    [AutoFillStatusTypes.Enrolled]: {
      text: getText('yes'),
    },
    [AutoFillStatusTypes.NotEnrolled]: {
      text: getText('not-enrolled'),
    },
    [AutoFillStatusTypes.Requested]: {
      text: getText('auto-refill-requested'),
    },
    [AutoFillStatusTypes.RequestAutoFill]: {
      text: getText('request-auto-refill'),
      style: styles.primaryText,
      isActionable: true,
    },
    [AutoFillStatusTypes.Requesting]: {
      text: getText('loading'),
    },
    [AutoFillStatusTypes.Hidden]: {
      text: '',
    },
  };

  const getAutoFillStatus = () => {
    if (isLoading && isAutoFillEnabled) {
      return AutoFillStatusTypes.Requesting;
    }
    if (auto_fill_enrolled) {
      return AutoFillStatusTypes.Enrolled;
    }
    if (!isAutoFillEnabled) {
      return AutoFillStatusTypes.Hidden;
    }
    if (auto_fill_enrolled === false) {
      return AutoFillStatusTypes.NotEnrolled;
    }
    if (auto_fill_request_status === AutoFillRequestStatus.Requested) {
      return AutoFillStatusTypes.Requested;
    }

    return AutoFillStatusTypes.RequestAutoFill;
  };

  const handleAutoRefillRequest = async () => {
    setIsLoading(true);

    try {
      const updatedPrescription = await requestAutoFill(prescription);
      onChange(updatedPrescription);
    } catch (error) {
      alert('Requesting auto refill FAILED');
    } finally {
      setIsLoading(false);
    }
  };

  const { autoFillOption, autoFillStatus } = useMemo(() => {
    const autoFillStatus = getAutoFillStatus();
    const autoFillOption = autoFillStatusOptions[autoFillStatus];
    return { autoFillStatus, autoFillOption };
  }, [prescription, isLoading]);

  if (autoFillStatus === AutoFillStatusTypes.Hidden) {
    return <></>;
  }

  return (
    <Form.Row>
      <Form.Column>
        <Text style={styles.labelStyle}>{getText('auto-refills')}</Text>
      </Form.Column>
      <Form.Column>
        <TouchableOpacity
          onPress={handleAutoRefillRequest}
          disabled={!autoFillOption.isActionable}
          testID="requestAutoRefill"
        >
          <Text style={{ ...styles.textStyle, ...autoFillOption.style }}>
            {autoFillOption.text}
          </Text>
        </TouchableOpacity>
      </Form.Column>
    </Form.Row>
  );
};

const useStyles = makeStyles((theme) => ({
  labelStyle: {
    ...theme.lumistryFonts.text.small.bold,
  },
  textStyle: {
    textAlign: 'right',
    color: theme.palette.gray[700],
  },
  primaryText: {
    color: theme.palette.primary[600],
  },
}));

enum AutoFillStatusTypes {
  Enrolled,
  NotEnrolled,
  Requested,
  Requesting,
  RequestAutoFill,
  Hidden,
}

export type MedicationAutoFillInfoProps = {
  prescription: PrescriptionDto;
  onChange: (prescription: PrescriptionDto) => void;
};
