import { getText } from 'assets/localization/localization';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import {
  useResendVerificationState,
  useVerificationState,
} from './verification-store';
import { StorageKeys } from '../../../enums/storage-keys';
import UsersServiceInstance from '../../api/users-service';
import {
  PatientUserDto,
  ResendPhoneNumberConfimationCodeRequestDto,
  UpdateUserRequest,
  UserLoginResponse,
  VerifyEmailDto,
  VerifyPhoneNumberRequest,
} from '@digitalpharmacist/users-service-client-axios';
import { ampli } from '../../common/ampliPatient';
import usersService from '../../api/users-service';
import { formatPhoneNumber } from '../../utils';
import AsyncStorageService from '../../api/async-storage-service';

export const verify = async (
  values: { confirmCode: string },
  type: 'email' | 'phone',
  navigation: AuthStackNavigationProp,
  emailAddress?: string,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  const email = useUserState.getState().user?.email!;
  const password = await AsyncStorageService.getItem(StorageKeys.Password);
  useVerificationState.setState({ error: undefined, status: 'loading' });
  useResendVerificationState.setState({ error: undefined, status: 'idle' });

  try {
    if (type === 'email') {
      const userVerification = {
        ...values,
        pharmacyId: pharmacyId,
        email: emailAddress ? emailAddress : email,
        password: password,
      };
      const userLoginResponse: UserLoginResponse =
        await UsersServiceInstance.userPatientAccountVerificationPost(
          userVerification,
        );
      if (!userLoginResponse.patient_user) {
        throw new Error(getText('unable-to-complete-verification'));
      }
      const user: PatientUserDto = userLoginResponse.patient_user;
      useVerificationState.setState({ status: 'success' });
      useUserState.setState({ user: user });
      await AsyncStorageService.removeItem(StorageKeys.Password);
      if (!userLoginResponse.accessToken) {
        throw new Error(getText('unable-to-complete-verification'));
      }
      await AsyncStorageService.setItem(
        StorageKeys.AccessToken,
        userLoginResponse.accessToken,
      );
      await AsyncStorageService.setItem(
        StorageKeys.RefreshToken,
        userLoginResponse.refreshToken,
      );
      ampli.emailVerified({
        emailVerificationSentTime: '',
        emailVerificationStatus: 'Verified',
        emailVerifiedTime: new Date().toISOString(),
      });
      navigation.navigate('register-details');
    } else if (type === 'phone') {
      const userVerification = {
        ...values,
        pharmacyId: pharmacyId,
        email: email,
      };
      const user: PatientUserDto =
        await UsersServiceInstance.verifyPhoneNumber(userVerification);
      ampli.accountVerified({
        verificationCodeSentTime: '',
        accountVerifiedTime: new Date().toISOString(),
        verificationMethod: 'SMS',
        verificationStatus: 'Verified',
      });
      useVerificationState.setState({ status: 'success' });
      useUserState.setState({ user: user });
      if (!user.preferredPharmacyLocationId) {
        navigation.navigate('patient-stores');
      }
    }
  } catch (e) {
    ampli.accountVerified({
      verificationCodeSentTime: '',
      accountVerifiedTime: new Date().toISOString(),
      verificationMethod: type,
      verificationStatus: 'Account verification failed',
    });
    useVerificationState.setState({
      error: { message: getText('unable-to-complete-verification') },
      status: 'error',
    });
  }
};

export const resendEmailConfirmationCode = async (
  email?: string,
): Promise<void> => {
  const userEmail = useUserState.getState().user?.email!;
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResendVerificationState.setState({ error: undefined, status: 'loading' });

  try {
    if (email) {
      await UsersServiceInstance.userPatientResendConfirmationCodePost({
        email,
        pharmacy_id: pharmacyId,
      });
    } else {
      await UsersServiceInstance.userPatientResendConfirmationCodePost({
        email: userEmail,
        pharmacy_id: pharmacyId,
      });
    }
    useResendVerificationState.setState({ status: 'success' });
    ampli.emailVerified({
      emailVerificationSentTime: new Date().toISOString(),
      emailVerificationStatus: 'Email verification code resent',
      emailVerifiedTime: '',
    });
  } catch (e) {
    ampli.emailVerified({
      emailVerificationSentTime: new Date().toISOString(),
      emailVerificationStatus: 'Email verification code resend failed',
      emailVerifiedTime: '',
    });
    useResendVerificationState.setState({
      error: {
        message: getText('unable-to-resend-verification-code'),
      },
      status: 'error',
    });
  }
};

const resendConfirmationByNumber = (
  phoneNumber: string,
): ResendPhoneNumberConfimationCodeRequestDto => {
  const formatPhone = formatPhoneNumber(phoneNumber);
  const resendPhoneNumberConfimationCodeRequestDto: ResendPhoneNumberConfimationCodeRequestDto =
    { phone_number: formatPhone };
  return resendPhoneNumberConfimationCodeRequestDto;
};

export const resendPhoneConfirmationCode = async (
  passedPhoneNumber?: string,
): Promise<void> => {
  useResendVerificationState.setState({ error: undefined, status: 'loading' });
  try {
    const patientId = useUserState.getState().user?.id;
    if (!patientId) return;
    const phoneNumber = useUserState.getState().user?.phoneNumber;
    const pendingPhoneNumber = useUserState.getState().user?.pendingPhoneNumber;
    const phone = passedPhoneNumber
      ? resendConfirmationByNumber(passedPhoneNumber)
      : phoneNumber
      ? resendConfirmationByNumber(phoneNumber)
      : pendingPhoneNumber
      ? resendConfirmationByNumber(pendingPhoneNumber)
      : undefined;

    if (!phone) throw new Error(getText('unable-to-resend-verification-code'));
    await UsersServiceInstance.userPatientResendPhoneNumberConfirmationCodePost(
      patientId,
      phone,
    );
    useResendVerificationState.setState({ status: 'success' });
    ampli.accountVerified({
      verificationCodeSentTime: new Date().toISOString(),
      accountVerifiedTime: '',
      verificationMethod: 'SMS',
      verificationStatus: 'Resent verification code',
    });
  } catch (e) {
    ampli.accountVerified({
      verificationCodeSentTime: new Date().toISOString(),
      accountVerifiedTime: '',
      verificationMethod: 'SMS',
      verificationStatus: 'Verification code resend failed',
    });
    useResendVerificationState.setState({
      error: {
        message: getText('unable-to-resend-verification-code'),
      },
      status: 'error',
    });
  }
};

export const updatePhoneNumberOrEmail = async (
  userInfo: UpdateUserRequest,
): Promise<void> => {
  useVerificationState.setState({ error: undefined, status: 'loading' });

  try {
    const userId = useUserState.getState().user?.id;
    if (!userId) {
      throw new Error(getText('unable-to-update-account'));
    }
    const user: PatientUserDto = await usersService.updatePatient(
      userId,
      userInfo,
    );
    useUserState.setState({
      user: user,
    });
    useVerificationState.setState({ status: 'success' });
    ampli.accountVerified({
      verificationCodeSentTime: new Date().toISOString(),
      accountVerifiedTime: '',
      verificationMethod: 'SMS',
      verificationStatus: 'Verification code sent',
    });
  } catch (e) {
    ampli.accountVerified({
      verificationCodeSentTime: new Date().toISOString(),
      accountVerifiedTime: '',
      verificationMethod: 'SMS',
      verificationStatus: 'Verification code send failed',
    });
    useVerificationState.setState({
      error: { message: getText('unable-to-update-account') },
      status: 'error',
    });
  }
};

export const verifyPhoneNumber = async (value: string): Promise<void> => {
  useVerificationState.setState({ error: undefined, status: 'loading' });
  try {
    const confirmCode: VerifyPhoneNumberRequest = {
      confirmCode: value,
    };
    const user: PatientUserDto =
      await usersService.verifyPhoneNumber(confirmCode);
    ampli.accountVerified({
      verificationCodeSentTime: '',
      accountVerifiedTime: new Date().toISOString(),
      verificationMethod: 'SMS',
      verificationStatus: 'Verified',
    });
    useVerificationState.setState({ status: 'success' });
    useUserState.setState({ user: user });
  } catch (e) {
    useVerificationState.setState({
      error: { message: getText('unable-to-update-account') },
      status: 'error',
    });
  }
};

export const verifyPatientEmail = async (value: string): Promise<void> => {
  useVerificationState.setState({ error: undefined, status: 'loading' });
  try {
    const confirmCode: VerifyEmailDto = {
      confirmCode: value,
    };
    const user: PatientUserDto =
      await usersService.patientUserVerifyEmail(confirmCode);
    ampli.accountVerified({
      verificationCodeSentTime: '',
      accountVerifiedTime: new Date().toISOString(),
      verificationMethod: 'Email',
      verificationStatus: 'Verified',
    });
    useVerificationState.setState({ status: 'success' });
    useUserState.setState({ user: user });
  } catch (e) {
    useVerificationState.setState({
      error: { message: getText('unable-to-update-account') },
      status: 'error',
    });
  }
};

export interface PhoneEmailVerification {
  value?: string;
  confirmCode: string;
  pharmacyId: string;
}
