// This is a function similar to lodash._get which allows you to access nested object properties using a string

import { logError } from '../logging/logger';

// The usage is get({ prop:{ nestedProp: 5 }}, 'prop.nestedProp') and will return 5
export const getNestedObjectValue = (
  object: Record<string, any>,
  path: string,
): any => {
  return path
    .replace(/\[/g, '.')
    .replace(/\]/g, '')
    .split('.')
    .reduce((obj = {}, key) => obj[key], object);
};

export const initUserway = (accountID: string) => {
  try {
    const script = document.createElement('script');
    script.src = 'https://cdn.userway.org/widget.js';
    script.setAttribute('data-account', accountID);
    script.setAttribute('data-position', '3');
    document.head.appendChild(script);
  } catch (error: unknown) {
    logError(error as Error);
  }
};
