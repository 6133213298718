import { BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { VUCA_API_BASE_URL } from '../common/constants';

export interface VideoDetailsModel {
  video_id: string;
  player_id: string;
  account_id: string;
  transcript: string;
}

export interface VideoCategoryModel {
  id: number;
  label: string;
}

export interface IVideoService {
  getVideoInfo(videoId: string, locale: string): Promise<VideoResponseModel>;
}

export interface MedicationGuidesPdfDownloadUri {
  [key: string]: string;
}

export interface VideoResponseModel {
  id: string;
  name: string;
  description: string;
  value: VideoDetailsModel;
  medication_guides: string[];
  medication_guides_title: string;
  medication_guides_pdf_download_uri: MedicationGuidesPdfDownloadUri;
  category: VideoCategoryModel;
}

export class VideoService extends BaseApiService implements IVideoService {
  constructor(
    baseUrl: string,
    _config: AxiosRequestConfig = {},
    enableAuth = true,
  ) {
    super(
      baseUrl,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      enableAuth,
    );
  }

  async getVideoInfo(
    videoId: string,
    locale = 'en-US',
  ): Promise<VideoResponseModel> {
    const params = {
      locale,
    };

    const { data } = await this.axiosInstance.get(`api/v2/videos/${videoId}`, {
      params,
    });

    return data;
  }
}

export default new VideoService(
  VUCA_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  false,
);
