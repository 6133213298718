import React, { PropsWithChildren, useImperativeHandle } from 'react';
import { ForwardRefRenderFunction, ForwardedRef } from 'react';
import { ModalButtonProps, ModalProps } from 'assets/components/modal';
import { ButtonHierarchy } from 'assets/components/button';
import { ViewStyle, View } from 'react-native';
import { NativeButtonOrientation } from '../base-modal/NativeModal';
import { BackdropPressBehavior } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetBackdrop/types';
import {
  BaseModal,
  BaseModalHandler,
} from 'assets/components/base-modal/BaseModal';
import { BottomSheetProps } from '../bottom-sheet/BottomSheet';

export const GenericModalView: ForwardRefRenderFunction<
  BaseModalHandler,
  GenericModalProps
> = (props: GenericModalProps, ref: ForwardedRef<BaseModalHandler>) => {
  const modalRef = React.useRef<BaseModalHandler>(null);

  const handleShow = () => {
    modalRef.current?.show();
  };

  const handleHide = () => {
    modalRef.current?.hide();
    if (props.onClose) props.onClose();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <BaseModal
      backdropPressBehavior="none"
      title={props.title}
      ref={modalRef}
      buttons={props.buttons}
      isScrollable={props.isScrollable}
      showDismissButton={props.showDismissButton}
      nativeButtonOrientation={props.nativeButtonOrientation}
      nativeHeight={props.nativeHeight}
      onClose={props.onClose}
      webModalSize={props.webModalSize}
      scrollToTop={props.scrollToTop}
      webModalProps={props.webModalProps}
      nativeModalProps={props.nativeModalProps}
      nativeModalBodyStyle={props.nativeModalBodyStyle}
      testID={'generic-modal-id'}
    >
      {props.children}
    </BaseModal>
  );
};

/**
 * Manually composed from {@link WebModalProps} and {@link NativeModalProps} so we intentionally choose what is supported
 * at each layer
 * */
export type GenericModalProps = PropsWithChildren<{
  title: string;
  ref?: ForwardedRef<BaseModalHandler>;
  buttons?: GenericModalButtonProps[];
  isScrollable?: boolean;
  showDismissButton?: boolean;
  scrollToTop?: boolean;

  // web only
  leftAlignedButton?: GenericModalButtonProps;
  webModalSize?: 'sm' | 'lg';
  webModalProps?: Pick<
    ModalProps,
    'height' | 'contentContainerStyle' | 'scrollViewStyle'
  >;

  // native only
  nativeButtonOrientation?: NativeButtonOrientation;
  nativeHeight?: string;
  nativeModalProps?: Pick<BottomSheetProps, 'contentContainerStyle'>;
  nativeModalBodyStyle?: ViewStyle;

  //bottomsheet
  backdropPressBehavior?: BackdropPressBehavior;

  onClose?: () => void;
}>;

// Currently, ModalButtonProps extends ButtonProps to add text (optional) and make hierarchy optional
// Yes, this is silly to undo those changes instead of extending ButtonProps directly, but this way
// ensures these props continue to match ModalButtonProps since they are passed directly into the next layer.
// This should reduce room for bugs until these layers are collapsed and simplified.
export interface GenericModalButtonProps
  extends Omit<ModalButtonProps, 'hierarchy' | 'text'> {
  text: string;
  hierarchy: ButtonHierarchy;
}

export const GenericModal = React.forwardRef<
  BaseModalHandler,
  GenericModalProps
>(GenericModalView);
