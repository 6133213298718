import { LoginStackNavigationProp } from '../../navigation/LoginNavigation';
import { useResetPasswordState } from './reset-password-store';
import { getText } from 'assets/localization/localization';
import { useAppStateStore } from '../../store/app-store';
import usersService from '../../api/users-service';
import { ampli } from '../../common/ampliPatient';
import {
  ChangePasswordRequestDto,
  ErrorType,
  ResetPasswordRequestDtoNotificationTypeEnum,
  ReusedPasswordError,
} from '@digitalpharmacist/users-service-client-axios';
import axios from 'axios';

export const sendResetLink = async (
  resetPasswordForm: ResetPasswordForm,
  navigation: LoginStackNavigationProp,
  notificationType: ResetPasswordRequestDtoNotificationTypeEnum,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  try {
    await usersService.resetPassword({
      ...resetPasswordForm,
      pharmacy_id: pharmacyId,
      notification_type: notificationType,
    });

    useResetPasswordState.setState({ status: 'success' });
    ampli.resetPassword({
      passwordResetStatus: 'Reset Link Sent',
      passwordResetTime: '',
      resetLinkSentTime: new Date().toISOString(),
    });
    navigation.navigate('reset-link-sent');
  } catch (e) {
    ampli.resetPassword({
      passwordResetStatus: 'Reset Link sending failed',
      passwordResetTime: '',
      resetLinkSentTime: new Date().toISOString(),
    });

    if (axios.isAxiosError(e) && e.response?.status === 404) {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: getText('unable-to-send-reset-link') },
      status: 'error',
    });
  }
};

export const resendResetLink = async (
  email: string,
  navigation: LoginStackNavigationProp,
  notificationType: ResetPasswordRequestDtoNotificationTypeEnum,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  try {
    await usersService.resetPassword({
      email,
      pharmacy_id: pharmacyId,
      notification_type: notificationType,
    });

    useResetPasswordState.setState({ status: 'success' });
    ampli.resetPassword({
      passwordResetStatus: 'Reset Link re-sent',
      passwordResetTime: '',
      resetLinkSentTime: new Date().toISOString(),
    });
    navigation.navigate('reset-link-sent');
  } catch (e) {
    ampli.resetPassword({
      passwordResetStatus: 'Reset Link re-send failed',
      passwordResetTime: '',
      resetLinkSentTime: new Date().toISOString(),
    });
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: getText('unable-to-send-reset-link') },
      status: 'error',
    });
  }
};

export const changePassword = async (
  changePasswordRequestDto: ChangePasswordRequestDto,
): Promise<void> => {
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  try {
    await usersService.userPatientPasswordChangePost(changePasswordRequestDto);
    useResetPasswordState.setState({ error: undefined, status: 'success' });
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 403) {
      useResetPasswordState.setState({
        error: { message: getText('password-incorrect') },
        status: 'error',
      });
    }
    if (axios.isAxiosError(e) && e.response?.status === 409) {
      const conflictErrorData = e.response.data as ReusedPasswordError;
      if (conflictErrorData.errorType === ErrorType.ReusedPassword) {
        useResetPasswordState.setState({
          error: { message: getText('cannot-reuse-last-five-password') },
          status: 'error',
        });
      }
    } else {
      useResetPasswordState.setState({
        error: { message: getText('unable-to-change-password') }, //TODO what is the message here??
        status: 'error',
      });
    }
  }
};

export const resetPassword = async (
  values: ResetPasswordModel,
  navigation: LoginStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResetPasswordState.setState({ error: undefined, status: 'loading' });

  try {
    await usersService.confirmPassword({
      pharmacy_id: pharmacyId,
      email: values.email,
      password: values.password,
      confirmation_code: values.confirmationCode,
      reset_password_link_id: values.resetPasswordLinkId,
    });
    useResetPasswordState.setState({ status: 'success' });
    ampli.resetPassword({
      passwordResetStatus: 'Password reset successful',
      passwordResetTime: new Date().toISOString(),
      resetLinkSentTime: '',
    });
    navigation.navigate('reset-successful');
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 409) {
      const conflictErrorData = error.response.data as ReusedPasswordError;
      if (conflictErrorData.errorType === ErrorType.ReusedPassword) {
        useResetPasswordState.setState({
          error: { message: getText('cannot-reuse-last-five-password') },
          status: 'error',
        });
      }
    } else {
      ampli.resetPassword({
        passwordResetStatus: 'Password reset failed',
        passwordResetTime: new Date().toISOString(),
        resetLinkSentTime: '',
      });
      navigation.navigate('reset-unsuccessful');
    }
  }
};

export enum SendOptions {
  email = 'email',
  sms = 'sms',
}

export interface ResetPasswordForm {
  email: string;
}

export interface ConfirmPasswordForm {
  password: string;
  passwordConfirm: string;
}

export interface ResetPasswordModel
  extends ConfirmPasswordForm,
    Omit<ResetPasswordForm, 'passwordConfirm'> {
  confirmationCode: string;
  resetPasswordLinkId: string;
}
