import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import englishTranslations from './en/translation';
import spanishTranslations from './es/translation';
import frenchTranslations from './fr/translation';

// future: it may be worth looking at more advanced library for things like pluralization and more features: https://medium.com/@jamuhl/translate-your-expo-io-react-native-mobile-application-aa220b2362d2
i18n.translations = {
  en: englishTranslations,
  es: spanishTranslations,
  fr: frenchTranslations,
};

i18n.locale = Localization.locale;
i18n.fallbacks = true;

export const getText = (key: translations, options?: Record<string, any>) => {
  return i18n.t(key, options);
};

export const getLocale = () => {
  return i18n.locale.substring(0, 2);
};

export function getNullableBooleanString(value: boolean | null | undefined): string {
  if (value === null || value === undefined) {
    return getText('not-yet-entered');
  }

  return value ? getText('yes') : getText('no');
}

export type TranslationMapping = Record<translations, string>;

export type ChecklistTranslations =
 | 'select-brand-color'
 | 'specs-first-item'
 | 'answer-following-questions'
 | 'website-featured-services'
 | 'select-6-services'
 | 'selected-services-tooltip-text'
 | 'pharmacy-service-details'
 | 'indicate-type-of-compounding'
 | 'build-replace-website'
 | 'build-new-website'
 | 'no-build-existing-website'
 | 'no-build-no-existing-website'
 | 'brand-color-required'
 | 'website-access'
 | 'website-you-like-to-use'
 | 'website-how-to-access-domain'
 | 'website-domain-first-tooltip'
 | 'website-domain-second-tooltip'
 | 'website-completion-gratitude'
 | 'publish-website-need-permission'
 | 'give-lumistry-permission'
 | 'please-provide-domain-details'
 | 'domain-name'
 | 'domain-host'
 | 'login-user-name'
 | 'login-password'
 | 'image-is-required'
 | 'valid-url'
 | 'specs-second-item'
 | 'specs-third-item'
 | 'brand-color'
 | 'white-text-readable'
 | 'logo-and-icon'
 | 'high-quality-graphics'
 | 'logo-light-bg'
 | 'logo-dark-bg'
 | 'icon-apple-app'
 | 'icon-android-app'
 | 'social-media'
 | 'links-appear'
 | 'fb-url'
 | 'insta-url'
 | 'twitter-url'
 | 'complete-checklist-items-in-order'
 | 'checklist-item-completed'
 | 'other-settings'
 | 'checklist-items-in-settings'
 | 'setup-checklist'
 | 'need-to-do-first'
 | 'complete-healthsite-patient'
 | 'required'
 | 'patient-agreements'
 | 'patient-agreements-description'
 | 'patient-agreements-use-template'
 | 'patient-agreements-upload-pdf'
 | 'patient-agreements-terms-option'
 | 'patient-agreements-terms-template'
 | 'patient-agreements-privacy-option'
 | 'patient-agreements-privacy-template'
 | 'patient-agreements-legal-notice'
 | 'patient-website'
 | 'basic-setup'
 | 'media-settings'
 | 'patient-app'
 | 'scanner-config'
 | 'clinical-services'
 | 'refill-settings'
 | 'message-settings'
 | 'patient-agreements-desc'
 | 'patient-website-desc'
 | 'basic-setup-desc'
 | 'media-settings-desc'
 | 'patient-app-desc'
 | 'scanner-config-desc'
 | 'clinical-services-desc'
 | 'refill-settings-desc'
 | 'message-settings-desc'
 | 'checklist-app-config-submission-title'
 | 'checklist-app-config-submission-message'
 | 'checklist-app-config-submission-description'
 | 'checklist-card-title'
 | 'upload-a-pdf'
 | 'checklist-card-subtitle'
 | 'checklist-app-config-title'
 | 'checklist-app-config-app-dev-account'
 | 'checklist-app-config-app-dev-account-desc'
 | 'checklist-app-config-name'
 | 'checklist-app-config-name-required'
 | 'checklist-app-config-name-hint'
 | 'checklist-app-config-name-max-character'
 | 'checklist-app-config-dev-account'
 | 'checklist-app-config-dev-account-description'
 | 'checklist-app-config-market-name'
 | 'checklist-app-config-market-name-description'
 | 'checklist-app-config-market-name-required'
 | 'checklist-app-config-market-name-not-valid'
 | 'checklist-app-config-market-name-max-characters'
 | 'checklist-app-config-duns-placeholder'
 | 'checklist-app-config-duns-required'
 | 'checklist-app-config-duns-tooltip'
 | 'checklist-app-config-website-url'
 | 'checklist-app-config-website-url-required'
 | 'checklist-app-config-website-url-not-valid'
 | 'checklist-app-config-id-title'
 | 'checklist-app-config-id-tooltip'
 | 'checklist-card-button';

export type OnboardingTranslations =
  | 'save-logout'
  | 'please-confirm-or-fill-in'
  | 'primary-contact'
  | 'business-information'
  | 'brand-name'
  | 'name-is-not-valid'
  | 'brand-name-is-not-valid'
  | 'parent-company-is-not-valid'
  | 'pharmacy-name-is-not-valid'
  | 'phone-is-required'
  | 'legal-entity-name-is-not-valid'
  | 'dba-is-not-valid'
  | 'pharmacy-email-address-is-not-valid'
  | 'pharmacy-ncpdp-is-not-valid'
  | 'pharmacy-npi-is-not-valid'
  | 'fax-is-not-valid'
  | 'brand-name-description'
  | 'for-iternal-use'
  | 'pharmacy-information'
  | 'legal-entity-name'
  | 'legal-entity-name-is-required'
  | 'dba'
  | 'dba-is-required'
  | 'brand-name-is-required'
  | 'parent-company-is-required'
  | 'location-email-address'
  | 'pharmacy-email-address-is-required'
  | 'pharmacy-ncpdp-is-required'
  | 'pharmacy-npi-is-required'
  | 'duns-number'
  | 'duns-number-is-required'
  | 'tax-id'
  | 'tax-id-is-required'
  | 'pharmacy-managment-system'
  | 'pharmacy-managment-system-is-required'
  | 'business-type'
  | 'business-type-is-required'
  | 'time-zone-is-required'
  | 'duns-tooltip-before'
  | 'duns-tooltip-after'
  | 'pharmacy-name-tooltip'
  | 'edit-form'
  | 'legal-name-tooltip'
  | 'dba-tooltip'
  | 'account-information'
  | 'denied-password-change-text'
  | 'server-error'
  | 'recipient_name'
  | 'status-is-required'
  | 'on-premise-equipment'
  | 'pms-requires-opie-desc'
  | 'pms-does-not-require-opie-desc'
  | 'have-it-person'
  | 'firewall-provider'
  | 'firewall-provider-is-required'
  | 'technician-name-is-required'
  | 'technician-email'
  | 'technician-name'
  | 'technician-email-is-required'
  | 'technician-phone'
  | 'technician-phone-is-required'
  | 'address-device-shipped'
  | 'po-boxes-not-accepted'
  | 'ship-to-pharmacy'
  | 'select-preferred-weekdays'
  | 'am-or-pm-installations'
  | 'do-not-know'
  | 'use-defaults'
  | 'select-all'
  | 'store-general-hours'
  | 'holidays'
  | 'additional-closures-may-be-added'
  | 'add-employee'
  | 'ob-employees-title'
  | 'ob-employees-generic-error'
  | 'ob-hours-generic-error'
  | 'ob-employees-description'
  | 'ob-address-title'
  | 'ob-address-description'
  | 'pharmacy-store-address'
  | 'pharmacy-store-address-tooltip'
  | 'account-billing-address'
  | 'account-billing-address-tooltip'
  | 'account-shipping-address'
  | 'account-shipping-address-tooltip'
  | 'same-as-pharmacy-store-address'
  | 'at-least-one-selected'
  | 'click-sections-above'
  | 'number-of-locations'
  | 'pharmacy-taking-off'
  | 'next-steps'
  | 'complete-new-account'
  | 'employess-to-be-notified'
  | 'photo-error'
  | 'monitor-progress'
  | 'check-progress'
  | 'go-to-dashboard'
  | 'agreements'
  | 'equipment'
  | 'employees'
  | 'addresses'
  | 'ein'
  | 'store-address'
  | 'lumistry-setup'
  | 'welcome-lumistry-setup'
  | 'account-info'
  | 'location-info'
  | 'ob-all-hours-desc'
  | 'confirm-information-collected'
  | 'location-billing-account'
  | 'require-additional-hardware'
  | 'add-your-employees'
  | 'set-hours-timezone-holidays'
  | 'learn-about-employee-roles'
  | 'lumistry-roles'
  | 'employee-role-determines-action'
  | 'handles-organization-permissions'
  | 'location-level-leader'
  | 'firewall-provider-is-not-valid'
  | 'technician-name-is-not-valid'
  | 'technician-email-is-not-valid'
  | 'technician-phone-is-not-valid'
  | 'preferred-time-is-required'
  | 'recipient-name-is-required'
  | 'recipient-name-is-not-valid'
  | 'error-occured-ob-save'
  | 'city-is-not-valid'
  | 'jurisdiction'
  | 'jurisdiction-is-required'
  | 'jurisdiction-is-not-valid'
  | 'duns-is-not-valid'
  | 'tax-id-is-not-valid'
  | 'ob-something-went-wrong'
  | 'ob-multi-location-title'
  | 'ob-multi-location-subtitle'
  | 'ob-multi-review-brand-name'
  | 'ob-multi-review-number-locations'
  | 'common-level-of-access';

export type translationsRxStatuses =
  // Workflow Status
  | 'workflow-status-in-queue'
  | 'workflow-status-out-of-stock'
  | 'workflow-status-special-order'
  | 'workflow-status-in-process'
  | 'workflow-status-awaiting-insurance-authorization'
  | 'workflow-status-awaiting-prescriber-authorization'
  | 'workflow-status-ready-for-shipping'
  | 'workflow-status-ready-for-delivery'
  | 'workflow-status-partial-fill'
  | 'workflow-status-partially-filled'
  | 'workflow-status-delivery-in-progress'
  | 'workflow-status-ready-for-pickup'
  | 'workflow-status-dispensed'
  | 'workflow-status-mail'
  | 'workflow-status-shipped'
  | 'workflow-status-delivered'
  | 'workflow-status-returned'
  | 'workflow-status-returned-to-stock'
  | 'branding'
  | 'primary-color'
  | 'text-color'
  | 'accent'
  | 'logo-light'
  | 'logo-dark'
  | 'icon-android'
  | 'icon-ios'
  | 'invalid-hex-value'
  | 'workflow-status-cancelled'
  | 'workflow-status-expired'
  | 'edit-brand-colors'
  | 'workflow-status-discontinued'
  | 'workflow-status-transferred'
  | 'workflow-status-insurance-refused'
  | 'workflow-status-prescriber-refused'
  | 'workflow-status-filled'
  | 'search-locations'
  | 'reminder-forms'
  | 'workflow-status-undefined'
  // Workflow Status Pharmacy Message
  | 'workflow-status-in-queue-message'
  | 'workflow-status-out-of-stock-message'
  | 'workflow-status-special-order-message'
  | 'workflow-status-in-process-message'
  | 'workflow-status-awaiting-insurance-authorization-message'
  | 'workflow-status-awaiting-prescriber-authorization-message'
  | 'workflow-status-ready-for-shipping-message'
  | 'workflow-status-ready-for-delivery-message'
  | 'workflow-status-partial-fill-message'
  | 'workflow-status-partially-filled-message'
  | 'workflow-status-delivery-in-progress-message'
  | 'workflow-status-ready-for-pickup-message'
  | 'workflow-status-dispensed-message'
  | 'workflow-status-mail-message'
  | 'workflow-status-shipped-message'
  | 'workflow-status-delivered-message'
  | 'workflow-status-returned-message'
  | 'workflow-status-returned-to-stock-message'
  | 'workflow-status-cancelled-message'
  | 'workflow-status-expired-message'
  | 'workflow-status-discontinued-message'
  | 'workflow-status-transferred-message'
  | 'workflow-status-insurance-refused-message'
  | 'workflow-status-prescriber-refused-message'
  | 'workflow-status-filled-message'
  | 'workflow-status-undefined-message'
  // Rx Status
  | 'rx-status-no-refills'
  | 'rx-status-expired'
  | 'rx-status-too-soon'
  | 'rx-status-not-found'
  | 'rx-status-controlled'
  | 'rx-status-narcotic'
  | 'rx-status-transferred'
  | 'rx-status-discontinued'
  | 'rx-status-on-hold'
  | 'rx-status-deleted'
  | 'rx-status-fillable'
  | 'rx-status-partially-filled'
  | 'rx-status-out-of-stock'
  | 'rx-status-special-order'
  | 'rx-status-deceased'
  | 'rx-status-undefined'
  // Rx Status Messages
  | 'rx-status-no-refills-message'
  | 'rx-status-expired-message'
  | 'rx-status-too-soon-message'
  | 'rx-status-not-found-message'
  | 'rx-status-controlled-message'
  | 'rx-status-narcotic-message'
  | 'rx-status-transferred-message'
  | 'rx-status-discontinued-message'
  | 'rx-status-on-hold-message'
  | 'rx-status-deleted-message'
  | 'rx-status-fillable-message'
  | 'rx-status-partially-filled-message'
  | 'rx-status-out-of-stock-message'
  | 'rx-status-special-order-message'
  | 'rx-status-deceased-message'
  | 'rx-status-undefined-message'
  // Order status
  | 'order-status-received'
  | 'order-status-in-progress'
  | 'order-status-filled'
  | 'order-status-out-for-delivery'
  | 'order-status-ready-for-pickup'
  | 'order-status-picked-up'
  | 'order-status-mailed'
  | 'order-status-shipped'
  | 'order-status-delivered'
  | 'order-status-returned-to-stock'
  | 'order-status-info-unavailable'
  | 'order-status-undefined'
  // Order Tracker
  | 'order-progress-not-started'
  | 'order-progress-started'
  | 'order-progress-completed'
  | "order-progress-filled"
  | 'order-progress-undefined'
  // Refill Status
  | 'refill-status-passed'
  | 'refill-status-not-passed'
  | 'refill-status-filter-all';

export type translations =
  | OnboardingTranslations
  | ChecklistTranslations
  | translationsRxStatuses
  | 'signup'
  | 'login'
  | 'username'
  | 'password'
  | 'dashboard'
  | 'medications'
  | 'messages'
  | 'profile'
  | 'get-care'
  | 'lets-get-to-know-you'
  | 'welcome'
  | 'logout'
  | 'front'
  | 'back'
  | 'otc-medications'
  | 'get-mobile-app'
  | 'go-to-form'
  | 'next'
  | 'scan-barcode'
  | 'scanned'
  | 'save'
  | 'forgot-password'
  | 'back-to-login'
  | 'camera-access-denied'
  | 'username-is-required'
  | 'password-is-required'
  | 'view-full-profile'
  | 'insurance-card'
  | 'barcode-scanner'
  | 'english'
  | 'spanish'
  | 'french'
  | 'contact-info'
  | 'yes'
  | 'no-puc-access'
  | 'no'
  | 'confirmation-message-sent'
  | 'confirmation-email-sent'
  | 'update-info'
  | 'verify-info'
  | 'continue'
  | 'first-name'
  | 'first-name-is-required'
  | 'last-name'
  | 'last-name-is-required'
  | 'email-short'
  | 'email'
  | 'email-is-required'
  | 'email-continue'
  | 'enter-patient-information'
  | 'mobile-number'
  | 'delete-image'
  | 'make-primary'
  | 'updated'
  | 'mobile-number-is-required'
  | 'birth-date'
  | 'birthdate'
  | 'birth-date-is-required'
  | 'retrieving'
  | 'lets-get-started'
  | 'front-of-card'
  | 'back-of-card'
  | 'coming-up'
  | 'view'
  | 'no-upcoming-appointments'
  | 'no-refills-due'
  | 'add-front-of-card'
  | 'photo'
  | 'add-back-of-card'
  | 'cancel'
  | 'retake'
  | 'use-photo'
  | 'password-must-include'
  | 'add-card'
  | 'primary-secondary-photo-id'
  | 'at-least-8-characters'
  | 'at-least-1-number'
  | 'at-least-1-capital-letter'
  | 'at-least-1-lowercase-letter'
  | 'at-least-1-special-character'
  | 'privacy-policy-error'
  | 'privacy-policy-agree'
  | 'privacy-policy-email-signup'
  | 'pharmacy-privacy-policy'
  | 'terms-and-conditions'
  | 'terms-of-service'
  | 'terms-of-service-error'
  | 'terms-of-service-agree'
  | 'terms-of-service-email-signup'
  | 'and'
  | 'submit'
  | '2-of-2-agreements'
  | 'medical-info-step-3'
  | 'password-entry-does-not-meet-criteria'
  | 'password-entries-do-not-match'
  | 'create-your-account'
  | 'please-enter-your-new-password'
  | 'email-is-not-valid'
  | 'phone-is-not-valid'
  | 'how-to-add-caregiver'
  | 'add-caregiver-details'
  | 'patients-under-care'
  | 'caregivers'
  | 'create-account'
  | 'name-dob'
  | 'sender-subject-date'
  | 'template-name'
  | 'assignee-summary'
  | 'name-rxnumber-dob'
  | 'first-name-last-name-dob'
  | 'patient-name-dob-form-name'
  | 'name-ncpdp-street-city'
  | 'user-email-address'
  | 'confirm-password'
  | 'patient-info-step'
  | 'communications-language'
  | 'prescriptions-communications'
  | 'automated-voice-call'
  | 'patient-select'
  | 'after-adding-patient'
  | 'text-message'
  | 'account-setup'
  | 'app-notifications'
  | 'all-other-pharmacy-communications'
  | 'same-time-preferences'
  | 'additional-information'
  | 'visually-impaired'
  | 'hearing-impaired'
  | 'easy-open-bottle-caps'
  | 'personal-info-step-2'
  | 'country'
  | 'country-is-required'
  | 'state'
  | 'state-is-required'
  | 'city'
  | 'city-is-required'
  | 'medical-conditions-non-capitalized'
  | 'street-address'
  | 'street-address-is-required'
  | 'street-address-line-2'
  | 'zip-code'
  | 'zip-code-is-required'
  | 'gender-is-required'
  | 'male'
  | 'female'
  | 'verification-code-resent'
  | 'please-enter-code'
  | 'didnt-receive-email'
  | 'please-check-spam-folder'
  | 'didnt-receive-text-message'
  | 'phone-number-is-sms-capable'
  | 'standard-text-messages-apply'
  | 'resend-code'
  | 'reset-password'
  | 'reset-link-sent'
  | 'access-validity-puc-pet'
  | 'please-enter-email'
  | 'how-would-you-like-to-receive-reset-link'
  | 'send-reset-link'
  | 'create-new-password'
  | 'for-security-reasons'
  | 'update-password'
  | 'password-expiring-soon'
  | 'password-expire-in-7-days'
  | 'update-password-now'
  | 'confirm-new-password'
  | 'happy-birthday'
  | 'insurance-card-update'
  | 'flu-shot-reminder'
  | 'spring-promotions'
  | 'summer-promotions'
  | 'fall-promotions'
  | 'thanksgiving'
  | 'seasons-greeting'
  | 'happy-new-year'
  | 'happy-birthday-description'
  | 'insurance-card-update-description'
  | 'flu-shot-reminder-description'
  | 'spring-promotions-description'
  | 'summer-promotions-description'
  | 'fall-promotions-description'
  | 'thanksgiving-description'
  | 'seasons-greeting-description'
  | 'happy-new-year-description'
  | 'happy-birthday-message-subject'
  | 'insurance-card-update-message-subject'
  | 'flu-shot-reminder-message-subject'
  | 'spring-promotions-message-subject'
  | 'summer-promotions-message-subject'
  | 'fall-promotions-message-subject'
  | 'thanksgiving-message-subject'
  | 'seasons-greeting-message-subject'
  | 'happy-new-year-message-subject'
  | 'happy-birthday-message-body'
  | 'insurance-card-update-message-body'
  | 'flu-shot-reminder-message-body'
  | 'spring-promotions-message-body'
  | 'summer-promotions-message-body'
  | 'fall-promotions-message-body'
  | 'thanksgiving-message-body'
  | 'seasons-greeting-message-body'
  | 'happy-new-year-message-body'
  | 'when-to-send-every-year'
  | 'configure-label-secure'
  | 'confirm-update-password'
  | 'new-password'
  | 'change-password'
  | 'your-account'
  | 'login-with-email'
  | 'login-enter-email'
  | 'go-to-login'
  | 'return-to-login'
  | 'passwords-must-match'
  | 'dismiss'
  | 'load-more'
  | 'loading'
  | 'open-map'
  | 'input-field'
  | 'text-field'
  | 'show-hide-password'
  | 'search'
  | 'select'
  | 'use'
  | 'no-options'
  | 'email-or-password-incorrect'
  | 'google-login'
  | 'google-continue'
  | 'google-signup'
  | 'google-login-failed'
  | 'unable-to-complete-registration'
  | 'unable-to-update-account'
  | 'unable-to-complete-verification'
  | 'unable-to-resend-verification-code'
  | 'error-submitting'
  | 'greeting'
  | 'unable-to-send-reset-link'
  | 'unable-to-change-password'
  | 'store-selector-change'
  | 'store-selector-search-address'
  | 'store-selector-change-store'
  | 'store-selector-your-store'
  | 'store-selector-nearby-store'
  | 'store-selector-close'
  | 'store-selector-select'
  | 'store-selector-no-stores-error'
  | 'feedback'
  | 'feedback-give-feedback'
  | 'feedback-give-your-feedback'
  | 'feedback-please-enter-feedback'
  | 'feedback-how-to-improve-app'
  | 'feedback-give-feedback-about-pharmacy'
  | 'feedback-sent'
  | 'feedback-thank-you'
  | 'feedback-what-type-of-feedback'
  | 'feedback-target-is-required'
  | 'feedback-feedback'
  | 'email-capital-case'
  | 'standard-text-apply'
  | 'email-capital-case'
  | 'standard-text-apply'
  | 'feedback-feedback-is-required'
  | 'feedback-allowContact'
  | 'feedback-thanks-message'
  | 'feedback-reach-you-message'
  | 'feedback-back-to-profile'
  | 'feedback-error-occurred'
  | 'feedback-contact-info'
  | 'feedback-edit'
  | 'photo-id-text'
  | 'no-id-card'
  | 'feedback-email-address'
  | 'link-will-be-sent'
  | 'feedback-phone-number'
  | 'feedback-app'
  | 'automated-messages'
  | 'feedback-location'
  | 'refill-card-medication'
  | 'refill-card-due-date'
  | 'prescription-card-day-supply'
  | 'prescription-card-filled-on'
  | 'prescription-card-last-filled'
  | 'prescription-card-last-ordered'
  | 'prescription-card-refill-due'
  | 'remove'
  | 'prescription-card-refills'
  | 'prescription-card-dob'
  | 'prescription-find-server-error'
  | 'medications-fill-your-prescription'
  | 'medications-ready-to-be-filled'
  | 'medications-other-upcoming-refills'
  | 'insurance-card-upload-error'
  | 'primary-insurance-add-your-information'
  | 'secondary-insurance-add-your-information'
  | 'placeholder-step'
  | 'photoId'
  | 'form'
  | 'import-plan'
  | 'instructions'
  | 'instructions-api'
  | 'pdf-instructions'
  | 'open-pdf'
  | 'insurance-take-card-picture'
  | 'photo-take-card-picture'
  | 'insurance-bring-to-store'
  | 'camera-mask-label'
  | 'take-photo'
  | 'choose-photo'
  | 'anonymous-refill'
  | 'new-form'
  | 'date-is-not-valid'
  | 'verify-your-email'
  | 'verify-your-phone'
  | 'please-enter-your-information'
  | 'email-address'
  | 'user-not-found'
  | 'invalid-confirmation-code'
  | 'contact-support'
  | 'patient-stores'
  | 'many-failed-attempts'
  | 'not-authorized'
  | 'done'
  | 'do-this-later'
  | 'entered-prescription-manually'
  | 'your-prescriptions'
  | 'add'
  | 'phone-number-footer'
  | 'fax'
  | 'digital-pharmacist'
  | 'pharmacy-website'
  | 'drug-name'
  | 'name-not-available'
  | 'appointments-calendar'
  | 'add-override'
  | 'not-implemented'
  | 'to'
  | 'chat'
  | 'preview'
  | 'download'
  | 'new-appointment'
  | 'drug-name-is-required'
  | 'rx-number-is-required'
  | 'rx-number-is-duplicated'
  | 'rx-number'
  | 'retrieve-your-prescriptions'
  | 'other-meds'
  | 'try-again'
  | 'step'
  | 'patient-dob'
  | 'patient-dob-is-required'
  | 'patient-last-name-is-required'
  | 'patient-last-name'
  | 'we-found-your-medications-alert-title'
  | 'unable-to-locate-your-prescription-alert-title'
  | 'unable-to-locate-your-prescription-alert-description'
  | 'image-reading-error'
  | 'prescription-information'
  | 'integrated-prescription-flow-description'
  | 'change'
  | 'location'
  | 'locations'
  | 'forms'
  | 'tasks'
  | 'welcome-to-tasks'
  | 'create-new-task'
  | 'new-task-button'
  | 'delegate-work-among-employees'
  | 'communicate-a-sync-with-coworkers'
  | 'track-week-month-quarter-year-tasks'
  | 'example-task-check-shelves'
  | 'welcome-to-services'
  | 'setup-bookable-service'
  | 'new-service-button'
  | 'patients-book-via-mobile'
  | 'pharmacy-staff-can-also-book'
  | 'use-pharmacy-hours-custom-availability'
  | 'see-upcoming-appointments'
  | 'change-availability'
  | 'book-first-appointment'
  | 'new-appointment-button'
  | 'no-upcoming-appointment'
  | 'schedule-an-appointment'
  | 'patients'
  | 'refill-submissions'
  | 'refill-submissions-header'
  | 'user'
  | 'users'
  | 'new-user'
  | 'user-details'
  | 'title'
  | 'role'
  | 'last-active'
  | 'registered'
  | 'activity'
  | 'show'
  | 'view-activity-logs'
  | 'resend-invite'
  | 'error-sending-invite'
  | 'delete'
  | 'delete-confirmation'
  | 'cannot-undo-action'
  | 'email-is-invalid'
  | 'passwords-do-not-match'
  | 'password-does-not-meet-criteria'
  | 'follow-reset-password-instructions'
  | 'pharmacy-reset-link-sent'
  | 'need-help'
  | 'set-your-preferences'
  | 'find-your-store'
  | 'confirm-your-store'
  | 'link-expired'
  | 'reset-unsuccessful'
  | 'order-submitted'
  | 'order'
  | 'picking-up-at'
  | 'ready-today'
  | 'notify-you'
  | 'apple-signup'
  | 'apple-continue'
  | 'apple-login'
  | 'prescribed-for'
  | 'last-filled-on'
  | 'last-ordered-on'
  | 'auto-refills'
  | 'auto-refill'
  | 'auto-refill-not-eligible'
  | 'auto-refill-requested'
  | 'auto-refill-patient-requested'
  | 'request-auto-refill'
  | 'refills-left'
  | 'quantity-dispensed'
  | 'days-supply'
  | 'directions'
  | 'questions-about-medication'
  | 'ask-your-pharmacist'
  | 'message'
  | 'refill'
  | 'open-settings'
  | 'child'
  | 'spouse'
  | 'parent'
  | 'grandparent'
  | 'grandchild'
  | 'pet'
  | 'partner'
  | 'other'
  | 'resources'
  | 'medication-guides'
  | 'how-to-take'
  | 'patient-education-sheets'
  | 'side-effects-interactions'
  | 'prescription-written'
  | 'prescription-by'
  | 'call-prescriber'
  | 'prescriber'
  | 'prescriber-info-not-available'
  | 'prescriber-name-not-available'
  | 'prescriber-phone-not-available'
  | 'hi'
  | 'create-your-password'
  | 'i-have-read-and-agree'
  | 'pharmacy-terms-and-conditions'
  | 'privacy-policy'
  | 'terms-and-conditions-required'
  | 'privacy-policy-required'
  | 'new-patient'
  | 'i-am-a-new-patient'
  | 'patient-details'
  | 'edit'
  | 'date-of-birth'
  | 'date-of-birth-short'
  | 'phone-number'
  | 'gender-text'
  | 'language'
  | 'conditions'
  | 'easy-open-bottle'
  | 'med-sync'
  | 'photo-id'
  | 'image'
  | 'notification'
  | 'app-notification'
  | 'list'
  | 'providers'
  | 'notes'
  | 'internal-use-only'
  | 'continuation-disclaimer'
  | 'to-get-started-choose'
  | 'face-id'
  | 'set-my-store'
  | 'medical-equipment-immunization'
  | 'services-offered'
  | 'en-es-fr'
  | 'languages-spoken'
  | 'holiday-hours-vary'
  | 'open-hours'
  | 'open-today'
  | 'store-hours'
  | 'upload-csv'
  | 'not-yet-entered'
  | 'personal-info'
  | 'will-pickup-prescription-today'
  | 'field-required'
  | 'will-pickup-prescription-urgently'
  | 'order-details'
  | 'method'
  | 'note-for-pharmacy'
  | 'note-for-pharmacy-not-optional'
  | 'review'
  | 'revoke'
  | 'insurance-is-required'
  | 'login-attempts-exceeded'
  | 'max-attempts-exceeded'
  | 'account-locked-reason'
  | 'send-me-email'
  | 'security-reason'
  | 'send-me-sms'
  | 'insurance-change-last-refill'
  | 'add-people-under-care-message-pt-1'
  | 'add-people-under-care-message-pt-2'
  | 'prescriptions-is-required'
  | 'over-the-counter-medications'
  | 'please-review-messages-and-proceed'
  | 'how-to-proceed'
  | 'enter-patient-email'
  | 'add-patient-request-submit'
  | 'first-name-is-not-valid'
  | 'last-name-is-not-valid'
  | 'provide-an-email'
  | 'request-sent'
  | 'revoke-access-for-authorized-caregiver'
  | 'legal-guardian-confirmation-for-person'
  | 'confirmation'
  | 'add-minor-or-pet-patient'
  | 'your-allergies'
  | 'legal-guardian-agreement'
  | 'meds'
  | 'orders'
  | 'reminders'
  | 'approval-needed'
  | 'person-has-an-account'
  | 'add-another-patient'
  | 'current-password'
  | 'enter-existing-password'
  | 'add-another'
  | 'go-home'
  | 'allow-pharmacy-contact-prescriber'
  | 'continue-with-selected-items'
  | 'go-back'
  | 'go-back-update-order'
  | 'thank-you-for-choosing'
  | 'add-people-under-care'
  | 'add-person'
  | 'return-to-order-details'
  | 'we-will-contact-prescriber'
  | 'we-will-process-following-items'
  | 'unable-to-process-items'
  | 'cannot-process-items'
  | 'return-to-medications'
  | 'no-item-left-in-order-cancel'
  | 'no-item-left-in-order-cancelled'
  | 'one-or-more-items-to-review'
  | 'please-review-the-messages-below'
  | 'ready-to-be-filled'
  | 'other-details'
  | 'other-information'
  | 'unable-to-submit-people-under-care-request'
  | 'relationship-to-me'
  | 'notice'
  | 'insurance'
  | 'appointments'
  | 'account'
  | 'add-medications'
  | 'add-meds-text'
  | 'caregiver-approval-agreement'
  | 'no-known-allergies'
  | 'known-allergies'
  | 'allergies-required'
  | 'more-information'
  | 'monthly-prescriptions'
  | 'as-an-authorized-caregiver'
  | 'manage-prescriptions-digitally'
  | 'order-refills'
  | 'delivery'
  | 'mail'
  | 'curbside'
  | 'email-address-is-correct'
  | 'relation-to-primary-user'
  | 'would-like-easy-open-caps'
  | 'prefers-easy-open-caps'
  | 'prefers-safety-caps'
  | 'pick-up-prescriptions-for'
  | 'discuss-care-with'
  | 'manage-health-and-clinic'
  | 'access-validity-puc'
  | 'update-puc-list'
  | 'basic-info'
  | 'patient-record-created'
  | 'prescriptions-at-file'
  | 'request-to-transfer'
  | 'do-this-medications-tab'
  | 'skip-this-step'
  | 'using-the-phone-number-on-file'
  | 'close'
  | 'closed'
  | 'store-info'
  | 'your-password-has-been-successfully-changed'
  | 'update-weak-password'
  | 'people-under-your-care'
  | 'people-under-your-care-empty-page'
  | 'medical-info'
  | 'none'
  | 'add-person-or-pet'
  | 'refill-details'
  | 'success'
  | 'prescription-no'
  | 'name'
  | 'status'
  | 'submitted'
  | 'channel'
  | 'delivery-method'
  | 'refill-comments'
  | 'png-only'
  | 'valid-image-type'
  | 'valid-icon-dimensions'
  | 'patient-info'
  | 'details'
  | 'phone'
  | 'request-status'
  | 'notification-preference'
  | 'notification-preferences-details'
  | 'sms-text-message'
  | 'every-n-months'
  | 'address'
  | 'email-text'
  | 'edit-notes'
  | 'prescription'
  | 'role-permission-description'
  | 'it-admin-description'
  | 'brand-description'
  | 'corporate-description'
  | 'manager-description'
  | 'remove-role'
  | 'remove-role-description'
  | 'patient'
  | 'password-incorrect'
  | 'date-submitted'
  | 'edit-phone-number'
  | 'edit-mobile-number'
  | 'allergies-colon'
  | 'medical-conditions'
  | 'refill-past-due'
  | 'expired-link'
  | 'linked-accounts'
  | 'linked-accounts-description'
  | 'used-password'
  | 'no-photo'
  | 'refill-due'
  | 'store'
  | 'app'
  | 'manage-prescriptions'
  | 'pick-up-prescriptions'
  | 'discuss-care'
  | 'manage-health'
  | 'add-patient'
  | 'if-you-authorize-person'
  | 'access-validity'
  | 'revoke-access'
  | 'authorized-on'
  | 'revoke-access-in-settings'
  | 'location-employee'
  | 'location-employee-description'
  | 'user-has-role-error'
  | 'full-access'
  | 'review-request'
  | 'authorize'
  | 'decline'
  | 'puc-permission-access-to-records'
  | 'authorized-caregivers'
  | 'medications-empty-state-title'
  | 'medications-empty-state-description'
  | 'medications-reminders-empty-state-title'
  | 'medications-reminders-empty-state-description'
  | 'add-meds'
  | 'transfer-meds'
  | 'transfer-meds-tab'
  | 'choose-from-the-list'
  | 'select-one-item'
  | 'pickup'
  | 'select-an-option'
  | 'please-confirm-the-info-matches-prescription-label'
  | 'register-confirmation-heading'
  | 'register-confirmation-body'
  | 'orders-tab-empty-state-title'
  | 'orders-tab-empty-state-description'
  | 'start-an-order'
  | 'show-locations'
  | 'first-name-edit-user'
  | 'last-name-edit-user'
  | 'information'
  | 'schedule'
  | 'find-time'
  | 'learn-more'
  | 'next-available'
  | 'not-available'
  | 'intro'
  | 'enter-email'
  | 'create-password'
  | 'register-confirmation'
  | 'register'
  | 'reset-link'
  | 'reset-successful'
  | 'register-details'
  | 'phone-verification'
  | 'patient-pharmacy-stores'
  | 'add-prescription'
  | 'refill-guest'
  | 'home'
  | 'settings'
  | 'store-location'
  | 'expiring-soon'
  | 'minutes'
  | 'book-appointment'
  | 'upcoming'
  | 'past'
  | 'patient-insurance'
  | 'barcode'
  | 'patient-preferences'
  | 'feedback-success'
  | 'people-under-care'
  | 'add-patient-under-care'
  | 'add-patient-under-care-info'
  | 'add-patient-under-care-email'
  | 'add-patient-under-care-confirmation'
  | 'insurance-and-id-cards'
  | 'primary'
  | 'secondary'
  | 'id-card'
  | 'patient-under-care-list'
  | 'automatic-prescription-flow'
  | 'section-on-the-roadmap'
  | 'bottle-cap-preference'
  | 'success-automatic-prescription-flow'
  | 'coming-soon'
  | 'allergies-conditions-caregivers'
  | 'insurance-id-card'
  | 'patient-forms'
  | 'communication-preferences'
  | 'sign-out'
  | 'select-person'
  | 'myself'
  | 'confirm'
  | 'on-hold'
  | 'rejected-reason-unknown'
  | 'show-more'
  | 'allergies'
  | 'street1'
  | 'street2'
  | 'gender-on-insurance'
  | 'prescription-help-copy'
  | 'preferred-language'
  | 'notification-preferences'
  | 'auto-voice-call'
  | 'edit-patient'
  | 'show-less'
  | 'appointment-scheduled'
  | 'booking-error'
  | 'error-loading-slots'
  | 'error-loading-patient-record'
  | 'error-updating-patient-record'
  | 'error-booking-appointment'
  | 'error-loading-appointments'
  | 'when'
  | 'who'
  | 'where'
  | 'vaccine-information-statement'
  | 'vaccine-benefits-and-risks'
  | 'care-notes'
  | 'reschedule'
  | 'about-this-app'
  | 'about-this-app-title'
  | 'copyright-lumistry'
  | 'all-rights-reserved'
  | 'version'
  | 'cancel-appointment'
  | 'reschedule-appointment'
  | 'error-loading-appointment'
  | 'error-loading-appointment-type'
  | 'refill-medications'
  | 'add-order'
  | 'refill-medical-infos'
  | 'submission-view'
  | 'canceled-appointments'
  | 'past-appointments'
  | 'form-view'
  | 'tasks-list-tab'
  | 'tasks-grid-tab'
  | 'edit-location'
  | 'tasks-grid-automation'
  | 'new-availability-tab'
  | 'edit-service-tab'
  | 'edit-appointment'
  | 'refill-review'
  | 'upcoming-appointments'
  | 'refill-other-information'
  | 'refill-prescription-method'
  | 'refill-insurance-method'
  | 'refill-confirmation'
  | 'refill-order-details'
  | 'good-morning'
  | 'good-afternoon'
  | 'good-evening'
  | 'you-are-chatting-with'
  | 'new-message'
  | 'no-messages'
  | 'messages-have-a-question'
  | 'new-message-placeholder'
  | 'general'
  | 'billing'
  | 'med-info'
  | 'side-effects'
  | 'message-subject-label'
  | 'another-account'
  | 'send'
  | 'appointment-rescheduled'
  | 'error-rescheduling-appointment'
  | 'cancel-appointment-confirmation'
  | 'appointment-canceled'
  | 'previously-scheduled'
  | 'cancel-note'
  | 'cancel-error'
  | 'error-cancel-appointment'
  | 'keep'
  | 'of'
  | 'tomorrow'
  | 'today'
  | 'canceled'
  | 'events'
  | 'error-field-number'
  | 'no-appointments'
  | 'no-services'
  | 'video-header'
  | 'videos'
  | 'videos-filter'
  | 'videos-clear'
  | 'videos-all'
  | 'videos-medication'
  | 'videos-condition'
  | 'videos-administration'
  | 'videos-missing-medication-video'
  | 'videos-general-health'
  | 'videos-search'
  | 'videos-share'
  | 'videos-category'
  | 'videos-title'
  | 'videos-language-spanish'
  | 'videos-language-english'
  | 'videos-language-french'
  | 'videos-duration'
  | 'videos-url'
  | 'copied-to-clipboard'
  | 'videos-med-guides'
  | 'videos-transcript'
  | 'delete-confirmation-modal'
  | 'service'
  | 'create-appointment'
  | 'limit-booking-frequency'
  | 'limit-booking-duration'
  | 'limit-future-bookings'
  | 'before-event'
  | 'after-event'
  | 'into-future'
  | 'within-date-range'
  | 'add-limit'
  | 'calendar-days'
  | 'business-days'
  | 'per-day'
  | 'per-week'
  | 'per-month'
  | 'no-notes-attached-to-patient'
  | 'no-buffer-time'
  | 'order-refill'
  | 'content-box-title'
  | 'cancelations'
  | 'duration'
  | 'time'
  | 'check-later'
  | 'actions'
  | 'service-record-enabled-tooltip'
  | 'service-record-disabled-tooltip'
  | 'copy-link-button-tooltip'
  | 'edit-service-record-tooltip'
  | 'delete-service-record-tooltip'
  | 'selecting-all-pages'
  | 'select-all-pages'
  | 'selecting-current-page'
  | 'select-current-page'
  | 'upcoming-refill'
  | 'send-message'
  | 'unselect-all'
  | 'login-attempts-exceeded'
  | 'max-attempts-exceeded'
  | 'account-locked-reason'
  | 'send-me-email'
  | 'security-reason'
  | 'send-me-sms'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'good-afternoon'
  | 'good-evening'
  | 'others'
  | 'reorder'
  | 'others'
  | 'generic-bulk-confirmation-modal'
  | 'task'
  | 'bulk-assignee-confirmation-modal'
  | 'bulk-due-date-confirmation-modal'
  | 'unresolved'
  | 'resolved'
  | 'in_progress'
  | 'on_hold'
  | 'task-resolve'
  | 'mark-resolved'
  | 'mark-in-progress'
  | 'mark-flagged'
  | 'mark-unresolved'
  | 'mark-on-hold'
  | 'change-assignee'
  | 'change-due'
  | 'task-in-progress'
  | 'task-flag'
  | 'task-filters'
  | 'error'
  | 'options'
  | 'add-new-option'
  | 'edit-columns'
  | 'add-new-column'
  | 'edit-rows'
  | 'add-new-row'
  | 'import'
  | 'grid'
  | 'browse'
  | 'unknown'
  | 'assignee'
  | 'year'
  | 'month'
  | 'apply-to-all'
  | 'all-statuses'
  | 'all-priorities'
  | 'all-visibilities'
  | 'all-types'
  | 'person'
  | 'edit-form-title'
  | 'subtitle'
  | 'required-field'
  | 'edit-title'
  | 'edit-subtitle'
  | 'edit-checkbox-label'
  | 'display-name'
  | 'length'
  | 'restriction'
  | 'account-holder'
  | 'monday-abbreviation'
  | 'tuesday-abbreviation'
  | 'wednesday-abbreviation'
  | 'thursday-abbreviation'
  | 'friday-abbreviation'
  | 'saturday-abbreviation'
  | 'sunday-abbreviation'
  | 'frequency'
  | 'set-to-default'
  | 'end-date'
  | 'custom-domain'
  | 'sort-by-due-date'
  | 'sort-by-assignee'
  | 'sort-by-task-summary'
  | 'chinese'
  | 'vietnamese'
  | 'german'
  | 'korean'
  | 'russian'
  | 'fullname'
  | 'please-upload-image'
  | 'signature'
  | 'type-your-question'
  | 'select-single-option'
  | 'select-multiple-options'
  | 'number'
  | 'please-select'
  | 'every-week'
  | 'every'
  | 'weeks'
  | 'every-month'
  | 'months'
  | 'last-day'
  | 'reoccurrence-must-have-context'
  | 'start-date-lower-end-date'
  | 'medical-conditions-without-colon'
  | 'no-known-medical-conditions'
  | 'enter-valid-phone-number'
  | 'ascending'
  | 'descending'
  | 'name-is-required'
  | 'availability-name'
  | 'service-required'
  | 'maximum-input-value'
  | 'week'
  | 'day'
  | 'date-overrides'
  | 'not-started'
  | 'over-the-counter'
  | 'delete-task'
  | 'venue'
  | 'private'
  | 'public'
  | 'enable-form'
  | 'disable-form'
  | 'your-pharmacy'
  | 'edit-availability-record-tooltip'
  | 'delete-availability-record-tooltip'
  | 'availability'
  | 'new-chat-message'
  | 'patient-is-required'
  | 'subject-is-required'
  | 'message-or-attachment-is-required'
  | 'message-characters-limit-five-thousand'
  | 'find-template'
  | 'subject'
  | 'note-inbox-messages-should-be-clinical-in-nature'
  | 'all'
  | 'unread'
  | 'unread-by-patient'
  | 'includes-me'
  | 'created-by-me'
  | 'last-seven-days'
  | 'filters'
  | 'clear'
  | 'send-attachment'
  | 'type-your-message'
  | 'mark-as-unread'
  | 'chat-messages'
  | 'unread-messages'
  | 'find-patient'
  | 'new-subject'
  | 'add-mobile-number-send-message'
  | 'add-mobile-number'
  | 'newest'
  | 'oldest'
  | 'a-z'
  | 'new-chat'
  | 'auto'
  | 'previous-week'
  | 'current-week'
  | 'next-week'
  | 'secure-message'
  | 'unable-to-process'
  | 'try-again-later'
  | 'message-pharmacy'
  | 'call-pharmacy'
  | 'tasks-grid-no-results-title'
  | 'tasks-grid-no-results-subtitle'
  | 'get-directions'
  | 'explore-other-locations'
  | 'error-getting-locations'
  | 'zip-code-is-not-valid'
  | 'street-1-is-not-valid'
  | 'street-2-is-not-valid'
  | 'allergies-are-not-valid'
  | 'conditions-are-not-valid'
  | 'make-appointment'
  | 'contact-pharmacy-for-assistance'
  | 'get-more-stores'
  | 'no-medication-guide'
  | 'error-loading-patients-under-care'
  | 'add-new-patient'
  | 'after-adding-patient-message'
  | 'pending-requests'
  | 'add-calendar-event'
  | 'by-application'
  | 'by-email'
  | 'by-sms'
  | 'by-voice'
  | 'error-loading-patient'
  | 'in'
  | 'ago'
  | 'month-short'
  | 'hour-short'
  | 'day-short'
  | 'missing-important-patient-record-data'
  | 'google'
  | 'apple'
  | 'outlook'
  | 'reset-filters'
  | 'calendar'
  | 'appointment-panel'
  | 'age'
  | 'confirmed'
  | 'pending'
  | 'rejected'
  | 'service-description'
  | 'additional-info'
  | 'more'
  | 'less'
  | 'month-capital'
  | 'week-capital'
  | 'day-capital'
  | 'dont-see-service'
  | 'service-category'
  | 'category'
  | 'service-category-filter'
  | 'service-type'
  | 'all-services'
  | 'all-service-categories'
  | 'all-service-types'
  | 'see-order-details'
  | 'team'
  | 'personal'
  | 'sent'
  | 'ordered'
  | 'reschedule-note'
  | 'character-count'
  | 'maxim-count-attachments'
  | 'selecting-files-wrong'
  | 'files-sending-error-try-again'
  | 'create-form-first'
  | 'pharmacy'
  | 'app-settings'
  | 'town-pharmacy'
  | 'pharmacy-general-info'
  | 'pharmacy-edit-business-info'
  | 'pharmacy-general-details'
  | 'pharmacy-general-info-limited-brand-name'
  | 'pharmacy-company-name'
  | 'pharmacy-brand-full-name'
  | 'pharmacy-ncpdp'
  | 'pharmacy-brand-admin'
  | 'pharmacy-parent-company'
  | 'pharmacy-npi'
  | 'pharmacy-pms'
  | 'pharmacy-facebook'
  | 'pharmacy-instagram'
  | 'pharmacy-twitter'
  | 'pharmacy-contact-info'
  | 'pharmacy-edit-contact-info'
  | 'pharmacy-email-address'
  | 'pharmacy-phone-number'
  | 'pharmacy-fax-number'
  | 'pharmacy-mailing-address'
  | 'pharmacy-billing-address'
  | 'pharmacy-brand-name-length'
  | 'pharmacy-npi-length'
  | 'pharmacy-departments'
  | 'pharmacy-names-details'
  | 'pharmacy-edit-default-department-hours'
  | 'pharmacy-add-default-department-hours'
  | 'pharmacy-delete-department'
  | 'pharmacy-delete-department-info'
  | 'pharmacy-departments-name'
  | 'pharmacy-departments-hours'
  | 'pharmacy-departments-hours-subtitle'
  | 'pharmacy-departments-name-required'
  | 'pharmacy-departments-add-hours'
  | 'pharmacy-departments-remove-hours'
  | 'locations-all'
  | 'locations-new'
  | 'find-by-name-address-or-ncpdp'
  | 'locations-name'
  | 'locations-tag'
  | 'locations-status'
  | 'locations-NCPDP'
  | 'locations-address'
  | 'locations-pharmacy-info'
  | 'locations-contact-info'
  | 'locations-teams'
  | 'locations-groups'
  | 'locations-services'
  | 'pick-up-methods'
  | 'pick-up-methods-description'
  | 'locations-normal-hours'
  | 'locations-holiday-closures'
  | 'locations-holiday-closures-description'
  | 'locations-additional-closures'
  | 'locations-additional-closures-description'
  | 'locations-edit-pharmacy-info'
  | 'locations-edit-contact-info'
  | 'locations-edit-department-hours'
  | 'locations-location-name'
  | 'locations-location-name-tooltip'
  | 'locations-internal-name'
  | 'locations-internal-name-tooltip'
  | 'locations-NPI'
  | 'locations-store-number'
  | 'locations-time-zone'
  | 'locations-latitude'
  | 'locations-longitude'
  | 'locations-phone-number'
  | 'locations-fax-number'
  | 'locations-email-address'
  | 'locations-mailing-address'
  | 'locations-edit-in-store-method'
  | 'locations-edit-pick-up-method'
  | 'locations-active'
  | 'locations-inactive'
  | 'locations-required'
  | 'locations-departments-remove-hours'
  | 'locations-departments-add-hours'
  | 'locations-use-default-settings'
  | 'pharmacy-holiday-closures'
  | 'pharmacy-holiday-closures-description'
  | 'pharmacy-additional-closures'
  | 'pharmacy-additional-closures-description'
  | 'pharmacy-additional-closures-active'
  | 'pharmacy-additional-closures-inactive'
  | 'pharmacy-additional-name-required'
  | 'pharmacy-additional-date-required'
  | 'pharmacy-additional-hour-required'
  | 'pharmacy-additional-label'
  | 'locations-default'
  | 'locations-department'
  | 'locations-departments-name-required'
  | 'wrong-date-format'
  | 'all-day'
  | 'tags'
  | 'new'
  | 'url-is-not-valid'
  | 'create-first-form'
  | 'ok'
  | 'upload'
  | 'credentials'
  | 'lumistry-api-key'
  | 'import-forms'
  | 'number-of-forms'
  | 'replace'
  | 'front-photo-missing'
  | 'back-photo-missing'
  | 'home-account-subheading'
  | 'home-medications-subheading'
  | 'home-messages-subheading'
  | 'home-appointments-subheading'
  | 'home-puc-subheading'
  | 'update-personal-info'
  | 'name-phone-email'
  | 'no-allergies'
  | 'forms-fill-out'
  | 'forms-filled-in'
  | 'health-info'
  | 'insurance-card-reminder'
  | 'changed-insurance'
  | 'unable-to-save-changes-try-again'
  | 'pre-appointment-checklist'
  | 'related-health-info'
  | 'allergy-info'
  | 'easy-open-bottle-caps-confirm'
  | 'message-body-is-required'
  | 'verify-patient'
  | 'verify-patient-warning'
  | 'no-forms-required'
  | 'form-unavailable'
  | 'form-not-started'
  | 'form-submitted'
  | 'preparing-submission'
  | 'download-will-begin'
  | 'delete-submission-confirmation'
  | 'not-entered'
  | 'submitted-on'
  | 'download-pdf'
  | 'submission-modal'
  | 'medical-information'
  | 'yes-easy-caps'
  | 'no-safety-caps'
  | 'easy-open-bottle-caps-description'
  | 'checklist-complete'
  | 'checklist-incomplete'
  | 'service-status-updated'
  | 'error-loading-form'
  | 'close-confirmation-question'
  | 'close-confirmation-text'
  | 'close-confirmation-exit'
  | 'close-confirmation-keep-appointment'
  | 'password-reset-subheading'
  | 'save-password'
  | 'venue-in-person'
  | 'venue-virtual'
  | 'venue-over-the-phone'
  | 'account-not-found-heading'
  | 'account-not-found-subheading'
  | 'account-not-found-subheading-sso'
  | 'get-the-app'
  | 'manage-subscription'
  | 'invalid-email-format'
  | 'rx-order-progress'
  | 'prescriptions-filled'
  | 'all-tasks'
  | 'every-n-weeks'
  | 'edit-reschedule-this-appointment'
  | 'cancel-this-appointment'
  | 'message-this-patient'
  | 'contact-pharmacy-for-policy-info'
  | 'prescriptions-empty-state-paragraph-one'
  | 'prescriptions-empty-state-paragraph-two'
  | 'prescriptions-empty-state-button-text'
  | 'otc-empty-state-paragraph-one'
  | 'otc-empty-state-paragraph-two'
  | 'otc-empty-state-button-text'
  | 'viewing-medications-for'
  | 'mark-unread'
  | 'error-updating-appointment'
  | 'error-occurred-title'
  | 'error-occurred-description'
  | 'contact-prescription-ready'
  | 'order-refills-prescriptions'
  | 'remove-patient'
  | 'confirm-remove-patient'
  | 'task-type'
  | 'characters'
  | 'task-type-required'
  | 'task-type-title-exceeded'
  | 'task-type-description-required'
  | 'task-type-description-exceeded'
  | 'delete-task-type-confirm'
  | 'delete-insurance'
  | 'confirm-delete-insurance'
  | 'delete-photo-id'
  | 'confirm-delete-id'
  | 'user-roles'
  | 'task-status'
  | 'task-assigned-to'
  | 'task-priority'
  | 'task-created-by'
  | 'task-due-by'
  | 'task-visibility'
  | 'integration'
  | 'api-host'
  | 'server-address'
  | 'integration-location-id'
  | 'server-port'
  | 'api-key'
  | 'setup-integration'
  | 'integration-missing'
  | 'edit-integration'
  | 'pharmacy-software'
  | 'npi'
  | 'dob-edit-user'
  | 'prescription-info'
  | 'add-to-list'
  | 'adding-prescriptions-for'
  | 'last-submission'
  | 'last-modified'
  | 'no-submissions'
  | 'no-forms-to-display'
  | 'hours'
  | 'date'
  | 'flagged'
  | 'recurring'
  | 'overdue'
  | 'trash'
  | 'api-required'
  | 'assignee-required'
  | 'drag-change-order'
  | 'remove-item'
  | 'form-submission-not-deleting'
  | 'download-all-submissions'
  | 'no-submissions-download'
  | 'copy-link'
  | 'form-was-imported'
  | 'access-private-public'
  | 'cannot-delete-task-type'
  | 'personal-task-assign'
  | 'new-service'
  | 'cannot-edit-task-type'
  | 'high-priority'
  | 'submissions'
  | 'search-forms'
  | 'no-submissions-found'
  | 'delete-availability-in-use-tooltip'
  | 'add-patients-start-messaging'
  | 'upload-patients-csv'
  | 'photo-id-2'
  | 'find-meds'
  | 'rx-number-must-be-numeric'
  | 'error-occurred-integration-save'
  | 'error-occurred-integration-load-save'
  | 'integration-saved'
  | 'hide'
  | 'server-address-required'
  | 'integration-location-id-required'
  | 'start-time-required'
  | 'end-time-required'
  | 'custom-filters'
  | 'appointments-filters-info-title'
  | 'service-name-info-box'
  | 'service-category-info-box'
  | 'applied-to-all-locations'
  | 'appointment-notes-update-error'
  | 'add-file'
  | 'add-image'
  | 'pharmacy-npi-number'
  | 'my-tasks'
  | 'access-denied'
  | 'check-back-later'
  | 'contact-administration'
  | 'new-messages'
  | 'this-week'
  | 'this-month'
  | 'older'
  | 'time-sensitive'
  | 'profile-settings'
  | 'switch-user'
  | 'services'
  | 'automated-actions'
  | 'wellness-classes'
  | 'same-mailing-address'
  | 'audit-trail'
  | 'days'
  | 'new-task'
  | 'description'
  | 'summary-is-required'
  | 'recurrence'
  | 'one-time'
  | 'weekly'
  | 'monthly'
  | 'summary'
  | 'high'
  | 'low'
  | 'medium'
  | 'visibility-team-policy'
  | 'visibility-personal-policy'
  | 'start-date-required'
  | 'start-date'
  | 'create-first-task'
  | 'assigned-to'
  | 'nobody'
  | 'created-by'
  | 'system-user'
  | 'updated-without-date'
  | 'completed'
  | 'created'
  | 'due-date'
  | 'no-task'
  | 'disable-filters'
  | 'no-task-disable-filters'
  | 'tasks-grid'
  | 'create-refill'
  | 'import-form'
  | 'no-form-templates'
  | 'lose-data-creating-form'
  | 'no-submission-found'
  | 'submissions'
  | 'search-submissions'
  | 'submission'
  | 'no-services-booking'
  | 'form-preview'
  | 'form-not-editable'
  | 'form-imported-text'
  | 'form-not-imported-text'
  | 'confirm-to-delete-name'
  | 'task-types'
  | 'make-default'
  | 'confirm-delete-availability-implicit'
  | 'default-availability'
  | 'confirming-lose-current-form'
  | 'confirm-delete-availability'
  | 'confirm-to-make'
  | 'edit-date-override'
  | 'confirm-delete-service-implicit'
  | 'something-went-wrong-implicit'
  | 'no-availability'
  | 'new-availability'
  | 'appointment-filters'
  | 'task-details'
  | 'are-you-sure-to-delete-task'
  | 'are-you-sure-to-resolve-task'
  | 'data'
  | 'changed-value'
  | 'easy-open-bottle-caps-field'
  | 'med-sync-field'
  | 'must-valid-domain'
  | 'no-access'
  | 'form-name'
  | 'show-grid'
  | 'task-appear-grid'
  | 'vow-ivr'
  | 'help-support'
  | 'internal-demos'
  | 'event-logs'
  | 'have-second-insurance'
  | 'subscribe-to-webhooks'
  | 'realtime-updates'
  | 'enabled'
  | 'disabled'
  | 'overwrite-defaults'
  | 'notifications'
  | 'no-notifications'
  | 'no-notifications-subtitle'
  | 'authentication-failed-generic'
  | 'add-manually'
  | 'realtime-updates-api-key'
  | 'internal-webhook-keys-renewed'
  | 'error-occurred-internal-webhook-keys-renew'
  | 'refresh'
  | 'relationship-to-me-is-required'
  | 'sending'
  | 'read'
  | 'delivered'
  | 'failed-to-send'
  | 'retry'
  | 'at'
  | 'preview'
  | 'form-not-editable'
  | 'form-preview'
  | 'dob-age'
  | 'form-name'
  | 'mark-all-as-read'
  | 'mark-as-read'
  | 'arabic'
  | 'continuation-disclaimer-register'
  | 'inactive'
  | 'submissions'
  | 'last-modified'
  | 'copy-url'
  | 'error-add-self-as-people-under-care-request'
  | 'error-exist-record-in-people-under-care-request-list'
  | 'enable-datafeed'
  | 'server-port-required'
  | 'invalid-ip-url'
  | 'numeric-only'
  | 'allergies-info-not-available'
  | 'info-not-available'
  | 'active'
  | 'no-edit'
  | 'imported'
  | 'order-tracker-title'
  | 'order-tracker-description'
  | 'order-tracker-see-orders'
  | 'order-tracker-in-progress'
  | 'order-tracker-filled'
  | 'find-pharmacy'
  | 'reset'
  | 'this-patient-has-not-sent-or-received-any-messages'
  | 'have-moment-break-ice-and-ask-them-how-they-are-doing'
  | 'copy-url'
  | 'please-confirm-your-choice'
  | 'form-edit-warning-title'
  | 'form-edit-warning'
  | 'form-save-confirm'
  | 'form-save-modal-message'
  | 'form-save-save-button'
  | 'form-open-error'
  | 'inactive-form-error-title'
  | 'inactive-form-error-description'
  | 'file-too-large'
  | 'datafeed'
  | 'download-submissions'
  | 'inactive-form-error-title'
  | 'inactive-form-error-description'
  | 'bulk-message-your-patients'
  | 'target-your-patients-with-clinical-opportunities-and-more'
  | 'paperless-enrollment-title'
  | 'paperless-enrollment-subtitle'
  | 'paperless-enrollment-text'
  | 'paperless-enrollment-benefits-title'
  | 'paperless-enrollment-first-benefit'
  | 'paperless-enrollment-second-benefit'
  | 'paperless-enrollment-third-benefit'
  | 'paperless-enrollment-fourth-benefit'
  | 'paperless-enrollment-med-info'
  | 'paperless-screen-title'
  | 'validation-start-hour-smaller'
  | 'validation-end-hour-greater'
  | 'npi-required'
  | 'api-key-required'
  | 'username-required'
  | 'password-required'
  | 'patient-message-invalid-file-type'
  | 'patient-message-file-limit'
  | 'patient-message-invalid-image-file-type'
  | 'bulk'
  | 'appointment-created'
  | 'pharmacy-department-name-exist'
  | 'forms-are-optional'
  | 'forms-are-optional-checkbox-tooltip'
  | 'opted-sms-email'
  | 'sms-data-rates'
  | 'communication-preferences-benefit'
  | 'communications-notifications-etc'
  | 'med-info-location'
  | 'med-info-description-start'
  | 'med-info-description-end'
  | 'medicine-name'
  | 'refill-due-date'
  | 'last-filled-date'
  | 'bulk'
  | 'appointment-at'
  | 'pharmacy-appointment'
  | 'med-info-preferences'
  | 'select-med-info'
  | 'receive-digitally'
  | 'receive-print-out'
  | 'fill-out-forms'
  | 'do-it-later'
  | 'bulk'
  | 'welcome-to-forms'
  | 'search-by-patient-details'
  | 'create-new-form-or-upload'
  | 'create-new-form-or-upload-forms'
  | 'collect-ephi-or-hipaa'
  | 'save-time-by-getting-info'
  | 'import-your-existing-templates'
  | 'save-paper'
  | 'all-forms-are-inactive'
  | 'patient-notifications'
  | 'edit-after-hours'
  | 'error-occurred-after-hours-load-save'
  | 'message-settings-saved'
  | 'auto-response-after-hours'
  | 'error-occurred-integration-load'
  | 'all-forms-are-inactive'
  | 'appointments-on-service'
  | 'fill-optional-forms-reminder'
  | 'no-results-found'
  | 'welcome-to-submissions'
  | 'all-forms-submitted-by-your-patients-will-appear-here'
  | 'anytime-access-to-your-submissions'
  | 'easy-managment-without-need-to-print'
  | 'ability-to-download-single-or-multiple-forms'
  | 'savings-on-paper-and-toner-materials'
  | 'forms-services'
  | 'all-forms-are-inactive'
  | 'last-filled-date'
  | 'pharmacy-info'
  | 'pharmacy-name'
  | 'pharmacy-name-is-required'
  | 'about-your-transfer'
  | 'what-meds-are-you-transferring'
  | 'all-my-medications'
  | 'some-of-my-medications'
  | 'why-transfer-all-the-meds'
  | 'why-transfer-all-the-meds-description'
  | 'start-over-to-change-person'
  | 'fill-prescriptions-after-transferring'
  | 'transfer-another'
  | 'start-over'
  | 'enter-your-medications'
  | 'at-least-one-drug-name-is-required'
  | 'enter-more'
  | 'your-transfer-request-has-been-submitted'
  | 'your-transfer-request-has-been-submitted-and-filled'
  | 'from-different-pharmacy'
  | 'whose-prescription-are-you-transferring'
  | 'where-are-you-transferring-from'
  | 'another-pharmacy-location'
  | 'different-pharmacy'
  | 'transfer-meds-header-title'
  | 'all-medications'
  | 'offerings'
  | 'locations-languages-spoken'
  | 'refills'
  | 'offered-to-patient'
  | 'on-of'
  | 'appt'
  | 'pharmacy-hours'
  | 'refill-not-passed'
  | 'new-form-submissions'
  | 'tasks-completed'
  | 'messages-read'
  | 'select-chat-template'
  | 'use-template'
  | 'insert-emoji'
  | 'attach-files'
  | 'roles'
  | 'global'
  | 'role-corporate'
  | 'role-it-admin'
  | 'role-brand'
  | 'role-manager'
  | 'role-staff'
  | 'role-no-access'
  | 'role-lumistry-user'
  | 'role-lumistry-admin'
  | 'profile-first-name'
  | 'profile-last-name'
  | 'mark-as-passed'
  | 'mark-as-not-passed'
  | 'dashboard-no-appointments'
  | 'dashboard-task'
  | 'help-and-support'
  | 'contact-help-support'
  | 'help-with-prescription'
  | 'tech-support'
  | 'need-help-prescription'
  | 'send-secure-message'
  | 'call-the-pharmacy'
  | 'help-and-support'
  | 'edit-user'
  | 'share-video'
  | 'copy-link-to-share-video-message'
  | 'video-send'
  | 'copy'
  | 'send-a-secure-message'
  | 'sender'
  |'recipients'
  | 'timestamp'
  | 'bulk-messages'
  | 'scheduled'
  | 'non-secure-messages'
  | 'secure-chats'
  | 'templates'
  | "send-date"
  | "secure-chat"
  | 'template'
  | 'new-bulk-message'
  | 'secure'
  | 'in-app-chat'
  | 'non-secure'
  | 'sms-email-1-way-communication'
  | 'sms-email'
  | 'send-to-all-patients'
  | 'send-to-all'
  | 'send-to-specific-people'
  | 'add-recipient'
  | 'when-to-send'
  | 'immediate'
  | 'scheduled-not-available'
  | 'recipients'
  | 'send-date'
  | 'recipient-criteria'
  | 'bulk-message-added'
  | 'error-creating-bulk-message'
  | 'google-review'
  | 'video-share-send-bulk-messages'
  | 'automations'
  | 'automated-tasks'
  | 'insurance-changes'
  | 'get-automated-tasks-error'
  | 'update-automation-error'
  | 'configure'
  | 'info-cards'
  | 'edit-info-card'
  | 'new-info-card'
  | 'link'
  | 'link-url'
  | 'link-label'
  | 'one-or-two-words-are-suggested'
  | 'max-char-allowed-message'
  | 'title-is-required'
  | 'enable'
  | 'disable'
  | 'find-assignee-or-summary'
  | 'task-types-description'
  | 'created-date'
  | 'task-category'
  | 'confirming-deactivate-user'
  | 'bottle-caps-confirmation-message'
  | 'character-count-of-total'
  | 'unexpected-error'
  | 'patient-does-not-have-medications'
  | 'remaining-refills'
  | 'rx'
  | 'no-patients-yet'
  | 'integrate-pms-to-see-patients'
  | 'send-video'
  | 'enrolled'
  | 'not-enrolled'
  | 'patient-record-updates'
  | 'failed-refills'
  | 'paperless-patient'
  | 'allergy-updates'
  | 'patient-record-updates-description'
  | 'failed-refills-description'
  | 'paperless-patient-description'
  | 'allergy-updates-description'
  | 'onboarding'
  | 'date-written'
  | 'non-secure-message'
  | 'date-written'
  | 'patient-can-start-new-chat'
  | 'new-insurance-card-primary-front'
  | 'new-insurance-card-primary-back'
  | 'removed-insurance-card-primary-front'
  | 'removed-insurance-card-primary-back'
  | 'new-insurance-card-secondary-front'
  | 'new-insurance-card-secondary-back'
  | 'removed-insurance-card-secondary-front'
  | 'removed-insurance-card-secondary-back'
  | 'automated-workflow'
  | 'date-written'
  | 'new-insurance-card-primary-front'
  | 'new-insurance-card-primary-back'
  | 'removed-insurance-card-primary-front'
  | 'removed-insurance-card-primary-back'
  | 'new-insurance-card-secondary-front'
  | 'new-insurance-card-secondary-back'
  | 'removed-insurance-card-secondary-front'
  | 'removed-insurance-card-secondary-back'
  | 'automated-workflow'
  | 'date-written'
  | 'app-name'
  | 'app-name-required'
  | 'app-name-tooltip-text'
  | 'app-marketplace-name'
  | 'app-marketplace-name-required'
  | 'app-marketplace-name-tooltip-text'
  | 'android-identifier'
  | 'android-store-link'
  | 'apple-identifier'
  | 'apple-store-link'
  | 'ios-app-id'
  | 'ios-team-id'
  | 'ios-bundle-id'
  | 'onboarding'
  | 'date-written'
  | 'refill-errors'
  | 'refill-errors-description'
  | 'rx-status'
  | 'allow-refill'
  | 'refill-due-reminders'
  | 'edit-refill-due-reminders'
  | 'refill-due-reminders-non-controlled'
  | 'refill-due-reminders-controlled'
  | 'refill-past-due-reminders'
  | 'refill-due-reminders-non-controlled-long-text'
  | 'refill-due-reminders-controlled-long-text'
  | 'refill-past-due-reminders-long-text'
  | 'days-prior'
  | 'days-after'
  | 'edit-refill-errors'
  | 'refill-error-toggle-description'
  | 'refill-error-toggle-name'
  | 'error-description'
  | 'error-name'
  | 'character-count-of-total-refill-error'
  | 'date-written'
  | 'date-written'
  | 'app-name'
  | 'app-name-tooltip-text'
  | 'app-store-name'
  | 'app-store-name-required'
  | 'app-store-name-tooltip-text'
  | 'android-identifier'
  | 'android-store-link'
  | 'apple-identifier'
  | 'apple-store-link'
  | 'ios-app-id'
  | 'ios-team-id'
  | 'ios-bundle-id'
  | 'patient-can-start-new-chat'
  | 'can-patient-start-chat-info'
  | 'new-insurance-card-primary-front'
  | 'new-insurance-card-primary-back'
  | 'removed-insurance-card-primary-front'
  | 'removed-insurance-card-primary-back'
  | 'new-insurance-card-secondary-front'
  | 'new-insurance-card-secondary-back'
  | 'removed-insurance-card-secondary-front'
  | 'removed-insurance-card-secondary-back'
  | 'automated-workflow'
  | 'add-variable'
  | 'patient-first-name-messaging'
  | 'patient-last-name-messaging'
  | 'pharmacy-phone-number-messaging'
  | 'add-attachment'
  | 'highlight-text-to-insert-link'
  | 'appointment-details'
  | 'mark-as-flagged'
  | 'mark-as-unflagged'
  | 'resolved-date'
  | 'refill-order'
  | 'vaccination-info'
  | 'one-time-vaccination-info-warning'
  | 'amt-administered'
  | 'manufacturer'
  | 'lot-nr'
  | 'amt-administered-is-required'
  | 'manufacturer-is-required'
  | 'lot-nr-is-required'
  | 'exp-date'
  | 'date-of-vis'
  | 'date-administered'
  | 'exp-date-required'
  | 'date-of-vis-required'
  | 'date-administered-required'
  | 'other-location'
  | 'arm'
  | 'leg'
  | 'other-option'
  | 'im'
  | 'sq'
  | 'route'
  | 'other-route'
  | 'side'
  | 'left'
  | 'right'
  | 'location-required'
  | 'route-required'
  | 'side-required'
  | 'other-location-required'
  | 'other-route-required'
  | 'user-authentication'
  | 'no-items-to-display-for'
  | 'how-many-days-reminder-tooltip'
  | 'how-many-days-past-due-reminder-tooltip'
  | 'home-phone'
  | 'other-phone'
  | 'cell-phone'
  | 'health-videos'
  | 'view-all'
  | 'SMS'
  | 'use-same-message-for-email'
  | 'rx-transfer-request'
  | 'rx-transfer-request-description'
  | 'pharmacy-phone'
  | 'patient-requesting-transfer'
  | 'patient-wants-refill'
  | 'due-date-required'
  | 'business-info'
  | 'label-and-url-both-required'
  | 'faq'
  | 'visit-help-center'
  | 'each-word-capital-first-letter'
  | 'url'
  | 'company'
  | 'space-craft-id'
  | 'pid'
  | 'default-location-id'
  | 'g-analytics-key'
  | 'account-url'
  | 'refill-url'
  | 'transfer-url'
  | 'number-is-not-valid'
  | 'website'
  | 'add-vaccination-error'
  | 'vaccination-info-pharmacy-use-only'
  | 'immunizer'
  | 'add-vaccination-info'
  | 'refills-default-chat-message'
  | 'email-subject'
  | 'email-message'
  | 'sms-message'
  | 'date-and-time'
  | 'autofill-info-not-available'
  | 'more-videos'
  | 'marketing-or-non-marketing-bulk-text'
  | 'marketing'
  | 'non-marketing'
  | 'message-characters-limit-160'
  | 'chat-message'
  | 'enter-age'
  | 'select-filter'
  | 'filter-is-required-field'
  | 'must-meet-all-criteria-below'
  | 'send-to-filtered-list'
  | 'age-is-required-field'
  | 'entered-age-is-invalid'
  | 'zip-code-is-required-field'
  | 'one-or-more-invalid-zip-codes-entered'
  | 'use-commas-for-multiple-values'
  | 'enter-zip-codes'
  | 'should-be-selected-only-one-gender'
  | 'age-is-older-than'
  | 'age-is-younger-than'
  | 'age-is'
  | 'age-is-not'
  | 'gender-is-male'
  | 'gender-is-female'
  | 'zip-code-is-one-of'
  | 'zip-code-is-not-one-of'
  | 'refills-default-chat-message'
  | 'pharmacy-mgmt-system'
  | 'pharmacy-mgmt-system-tooltip'
  | 'marketing-messaging-phone'
  | 'non-marketing-messaging-phone'
  | 'refill-reminder-error-modal-tooltip'
  | 'jurisdiction'
  | 'legal-entity-name-tooltip'
  | 'time-overlapped'
  | 'patient-name'
  | 'cannot-reuse-last-five-password'
  | 'requires-rebuilding-app'
  | 'edit-app-details'
  | 'edit-website-details'
  | 'rx-transfer-option'
  | 'invalid-hex-length-use-6-digits'
  | 'welcome-to-refills'
  | 'refill-subtitle'
  | 'refills-bullet-point-title'
  | 'tell-patients-about-app'
  | 'send-email-patient-portal-account'
  | 'send-text-for-patients-download-app'
  | 'announce-ivr-promoting-digital-solutions'
  | 'cannot-reuse-last-five-password'
  | 'unable-to-process-complete-required-fields'
  | 'jurisdiction-tooltip'
  | 'switch-to-this-location'
  | 'find-first-name-last-name-email'
  | 'patient-portal-url'
  | 'form-error-occurred'
  | 'messages-reload-application'
  | 'find-first-name-last-name-email'
  | 'cannot-reuse-last-five-password'
  | 'time-overlapped'
  | 'non-marketing-messaging-phone'
  | 'outbound-sms-cap'
  | 'outbound-sms-used'
  | 'outbound-email-cap'
  | 'outbound-email-used'
  | 'message-plans'
  | 'internal-use'
  | 'total-overage-amount'
  | 'payment-and-delivery-title'
  | 'payment-and-delivery-description'
  | 'notify-me'
  | 'reached-sending-limit'
  | 'edit-message-plans'
  | 'sms-cap-reached'
  | 'email-cap-reached'
  | 'sms-email-cap-reached'
  | 'auto-fill-my-address'
  | 'messages-reload-application'
  | 'no-insurance-card'
  | 'tax-ein-format-is-invalid'
  | 'email-password-incorrect-try-forgot-password'
  | 'signup-date'
  | 'tax-ein-format-is-invalid'
  | 'one-pickup-method-must-be-enabled'
  | 'email-password-incorrect-try-forgot-password'
  | 'billing-settings'
  | 'email-password-incorrect-try-forgot-password'
  | 'service-min-length-error-message'
  | 'one-pickup-method-must-be-enabled'
  | 'qr-code'
  | 'app-is-live'
  | 'view-image'
  | 'at-least-one-recipient-is-required'
  | 'billable-limits'
  | 'non-billable-usage'
  | 'usage'
  | 'review-message'
  | 'recipients-list'
  | 'name-dob'
  | 'action'
  | 'you-have-no-bulk-message-recipients'
  | 'one-or-more-recipients-have-been-removed'
  | 'no-name-data'
  | 'no-data'
  | 'website-settings-url-tooltip'
  | 'date-is-required'
  | 'edit-billable-limits'
  | 'general-error-message'
  | 'failed'
  | 'prescription-entered-already-exist-contact-pharmacy'
  | 'email-was-registered-successfully'
  | 'email-is-already-registered'
  | 'failed'
  | 'apple-developer-account'
  | 'apple-developer-account-required'
  | 'apple-developer-account-tooltip'
  | 'email-is-already-registered';
