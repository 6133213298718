import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Text } from 'assets/components/text/Text';
import { InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { RefillStackParamList } from './RefillNavigation';
import { RefillTestIDs } from './RefillTestIDs';

export const RefillOrderDetails: FunctionComponent<
  PropsWithChildren<RefillOrderDetailsProps>
> = ({ navigation }) => {
  const styles = useStyles();
  const handleOnPressDone = () => {
    navigation.navigate('refill-confirmation');
  };

  return (
    <InternalScreenContainer
      title={getText('medications-fill-your-prescription')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: handleOnPressDone,
          testID: RefillTestIDs.refillOrderDetailsSubmit,
          logger: { id: RefillTestIDs.refillOrderDetailsSubmit },
          text: getText('next'),
        },
      ]}
    >
      <Text style={styles.textTitle}>{RefillOrderDetails.name}</Text>
    </InternalScreenContainer>
  );
};

export type RefillOrderDetailsProps = NativeStackScreenProps<
  RefillStackParamList,
  'refill-order-details'
>;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
}));
