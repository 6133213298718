import create from 'zustand';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

import { ApiError, AsyncStatus } from '../../store/types';
import {
  CreateTransferPrescriptionDto,
  TransferPrescriptionRxDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import refillService from '../refill/refill-service';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';

export const useTransferMedicationsStore = create<TransferMedicationsState>(
  (set, get) => ({
    status: 'idle',
    error: undefined,
    pucFullName: undefined,
    locationPatientRecord: undefined,
    locationPatientRecordId: undefined,
    transferLocation: undefined,
    originated: undefined,
    medications: [],
    allMedications: false,
    fillPrescription: true,
    pharmacyName: '',
    pharmacyPhoneNumber: '',
    hasCanceledOrDone: undefined,
    userCanChose: true,
    updateTransferMedsData: (data: TransferMedicationsState) => {
      set({ ...data });
    },
    submitTransferPrescriptions: async () => {
      const state = get();
      const userId = useUserState.getState().user?.id;
      if (
        !userId ||
        !state.pharmacyName ||
        !state.pharmacyPhoneNumber ||
        !state.locationPatientRecord ||
        !state.medications ||
        !state.locationPatientRecordId
      ) {
        logError(new Error(getText('missing-important-patient-record-data')));
        throw new Error(getText('missing-important-patient-record-data'));
      }

      set({ ...state, status: 'loading' });

      const transfer: CreateTransferPrescriptionDto = {
        pharmacy_id: useAppStateStore.getState().pharmacyId,
        transfer_to_location_id: state.transferLocation,
        transfer_to_location_name: state.pharmacyName,
        transfer_to_location_phone: state.pharmacyPhoneNumber,
        location_patient_record_id: state.locationPatientRecord.id,
        refill_after_transfer: state.fillPrescription === true,
        user_id: userId,
        all_prescriptions: state.allMedications === true,
        prescriptions: state.medications.map((item) => ({
          rx_number: item.rx_number,
          drug_name: item.drug_name,
        })),
      };

      try {
        const data = await refillService.submitTransferPrescriptions(
          state.locationPatientRecord.location_id,
          transfer,
        );
      } catch (error) {
        console.error(error);
        set({ ...state, status: 'error' });
      }
    },
  }),
);
export interface TransferMedicationsState {
  status?: AsyncStatus;
  error?: ApiError;
  locationPatientRecord?: LocationPatientRecordDto;
  locationPatientRecordId?: string;
  pucFullName?: string;
  transferLocation?: string;
  originated?: 'onboarding' | 'medication' | 'another-pharmacy';
  fillPrescription?: boolean;
  allMedications?: boolean;
  medications?: TransferPrescriptionRxDto[];
  pharmacyName?: string;
  pharmacyPhoneNumber?: string;
  hasCanceledOrDone?: boolean;
  userCanChose?: boolean;
  submitTransferPrescriptions?: () => Promise<void>;
  updateTransferMedsData?: (data: TransferMedicationsState) => void;
}

export const resetTransferMedsState = () => {
  useTransferMedicationsStore.setState({
    status: 'idle',
    error: undefined,
    pucFullName: undefined,
    locationPatientRecord: undefined,
    locationPatientRecordId: undefined,
    transferLocation: undefined,
    originated: undefined,
    medications: [],
    allMedications: false,
    fillPrescription: true,
    pharmacyName: '',
    pharmacyPhoneNumber: '',
    hasCanceledOrDone: undefined,
    userCanChose: true,
  });
};
