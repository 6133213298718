import { Platform } from 'react-native';
export const calculateScreenWidth = (
  width: number,
  webMaxWidth: number,
  fullWidth: boolean,
  marginHorizontal = 16,
) => {
  let maxWidth = width;
  if (Platform.OS === 'web' && width > webMaxWidth) {
    maxWidth = fullWidth ? width : webMaxWidth;
  }
  return maxWidth + (fullWidth ? 0 : -marginHorizontal);
};
