import { Form, InternalScreenContainer } from 'assets/layout';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { getText } from 'assets/localization/localization';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { useForm } from 'react-hook-form';
import { CardType } from '@digitalpharmacist/patient-service-client-axios';
import { RefillStackParamList } from '../refill/RefillNavigation';
import { RefillTestIDs } from '../refill/RefillTestIDs';
import { useRefillMedicationsStore } from './refill-store';

export const CheckSecondInsurance: FunctionComponent<
  PropsWithChildren<CheckSecondInsuranceProps>
> = ({ navigation }) => {
  const styles = useStyles();

  const { isFirstTimeRegister } = useRefillMedicationsStore();

  const form = useForm({
    defaultValues: {
      secondInsurance: '',
    },
  });

  const handleOnPressNext = async () => {
    if (form.getValues().secondInsurance === 'yes') {
      navigation.navigate('edit-patient-card-refill', {
        cardType: CardType.InsuranceSecondary,
        nextNavigation: isFirstTimeRegister
          ? 'refill-details'
          : 'refill-review',
      });
      return;
    }
    return isFirstTimeRegister
      ? navigation.navigate('refill-details')
      : navigation.navigate('refill-review');
  };

  return (
    <InternalScreenContainer
      title={getText('medications-fill-your-prescription')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: form.handleSubmit(handleOnPressNext),
          testID: RefillTestIDs.refillOrderDetailsSubmit,
          logger: { id: RefillTestIDs.refillOrderDetailsSubmit },
          style: styles.nextButton,
          text: getText('next'),
        },
      ]}
    >
      <Form methods={form}>
        <Form.Alert
          visible={!!form.getFieldState('secondInsurance').error}
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
        />
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText('have-second-insurance')}
              rules={{
                required: getText('field-required'),
              }}
              name="secondInsurance"
              values={[
                {
                  text: getText('yes'),
                  value: 'yes',
                },
                {
                  text: getText('no'),
                  value: 'no',
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </InternalScreenContainer>
  );
};

export type CheckSecondInsuranceProps = NativeStackScreenProps<
  RefillStackParamList,
  'check-second-insurance'
>;

const useStyles = makeStyles((theme) => ({
  nextButton: {
    alignItems: 'center',
    width: '100%',
    marginTop: theme.getSpacing(1),
  },
}));
