import {
  CreateRecordUnderCareErrorType,
  PatientUnderCareRelationship,
} from '@digitalpharmacist/patient-service-client-axios';
import { Gender } from '@digitalpharmacist/users-service-client-axios';
import { getText } from 'assets/localization/localization';
import moment from 'moment';

/**
 * Perform a deep removal of all properties that are undefined. Does not mutate object
 */
export const removeUndefinedProperties = <T>(obj: T): T => {
  // general function is based on the deep clone from: https://www.npmjs.com/package/just-clone
  if (typeof obj == 'function') {
    return obj;
  }
  const result: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === undefined) {
      continue;
    }
    const type = {}.toString.call(value).slice(8, -1);
    if (type == 'Array' || type == 'Object') {
      result[key] = removeUndefinedProperties(value);
    } else if (type == 'Date') {
      result[key] = new Date((value as unknown as Date).getTime());
    } else {
      result[key] = value;
    }
  }
  return result;
};

/**
 * Perform a deep trim of all properties that are strings. Does not mutate object
 */
export const trimAllStringProperties = <T>(obj: T): T => {
  // general function is based on the deep clone from: https://www.npmjs.com/package/just-clone
  if (typeof obj == 'function') {
    return obj;
  }
  const result: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    const value = obj[key];
    const type = {}.toString.call(value).slice(8, -1);
    if (type == 'Array' || type == 'Object') {
      result[key] = trimAllStringProperties(value);
    } else if (type == 'Date') {
      result[key] = new Date((value as unknown as Date).getTime());
    } else if (type === 'String') {
      result[key] = (value as unknown as string).trim();
    } else {
      result[key] = value;
    }
  }
  return result;
};

export enum DateTimeFormat {
  ISODate = 'YYYY-MM-DD',
  USDateFormat = 'MM/DD/YYYY',
  // The following date format is used for validation by the isMatch method
  DOBDateFormat = 'MM/dd/yyyy',
}

const DEFAULT_DATE_FORMAT = DateTimeFormat.ISODate;

export const formatDate = (
  valueToFormat: string,
  format: string = DEFAULT_DATE_FORMAT,
) => {
  return moment(valueToFormat, format).toDate();
};

export const formatDateStringAsISO = (
  valueToFormat: string,
  inputFormat: string,
) => {
  const date = moment(valueToFormat, inputFormat).format(
    DateTimeFormat.ISODate,
  );
  return date;
};

export const formatISODateAsString = (
  valueToFormat: string,
  outputFormat: string,
) => {
  const date = moment(valueToFormat, DateTimeFormat.ISODate)
    .format(outputFormat)
    .toString();
  return date;
};

export const formatPhoneNumberAsString = (valueToFormat: string) => {
  //remove the country code if it exists and then format the phone number as 123-456-7890
  const phoneNumber = valueToFormat.replace('+1', '').replace(/\D/g, '');
  const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return undefined;
};

export const genderTranslationMap: Record<Gender, string> = {
  [Gender.Male]: getText('male'),
  [Gender.Female]: getText('female'),
};

export const relationshipTranslationMap: Record<
  PatientUnderCareRelationship,
  string
> = {
  [PatientUnderCareRelationship.Child]: getText('child'),
  [PatientUnderCareRelationship.Grandchild]: getText('grandchild'),
  [PatientUnderCareRelationship.Grandparent]: getText('grandparent'),
  [PatientUnderCareRelationship.Other]: getText('other'),
  [PatientUnderCareRelationship.Parent]: getText('parent'),
  [PatientUnderCareRelationship.Partner]: getText('partner'),
  [PatientUnderCareRelationship.Pet]: getText('pet'),
  [PatientUnderCareRelationship.Spouse]: getText('spouse'),
};

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

//regex for MM-DD-YYYY - for month will accept 1-12, for days 1-31, for years will accept 4-digits starting with 19-29
export const dateRegex =
  /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|29)\d\d$/;

export const RUCR409TranslationMap = (
  fullName: string,
  errorType: CreateRecordUnderCareErrorType,
): string => {
  switch (errorType) {
    case CreateRecordUnderCareErrorType.Self:
      return getText('error-add-self-as-people-under-care-request');
    case CreateRecordUnderCareErrorType.Exists:
      return getText('error-exist-record-in-people-under-care-request-list', {
        name: fullName,
      });
  }
};
