import { makeStyles } from 'assets/theme';
import React, { useEffect, useState } from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

interface TooltipProps {
  content: string;
  variant?: string;
  anchorSelect?: string;
}

const PatientTooltip: React.FC<TooltipProps> = ({
  anchorSelect,
  content,
  variant = 'dark',
}) => {
  const [TooltipComponent, setTooltipComponent] =
    useState<React.ElementType | null>(null);

  const styles = useStyles();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // we need to implement this approach because the library react-tooltip is not compatible with React Native mobile
      import('react-tooltip').then((module) => {
        const Tooltip = module.Tooltip;
        const tooltipWithProps = (props: any) => (
          <Tooltip
            opacity={1}
            style={styles.tooltip}
            place="bottom"
            {...props}
          />
        );
        setTooltipComponent(() => tooltipWithProps);
      });
    }
  }, [content]);

  return TooltipComponent ? (
    <TooltipComponent
      anchorSelect={anchorSelect}
      content={content}
      variant={variant}
    />
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontFamily: theme.lumistryFonts.text.medium.regular.fontFamily,
    borderRadius: theme.roundness,
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
    paddingLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
    maxWidth: 400,
  },
}));

export default PatientTooltip;
