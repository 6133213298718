import { ListAccordion } from 'assets/components/list-accordion';
import { Map, MapProps } from 'assets/components/map';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import {
  Linking,
  Platform,
  Pressable,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import { useAppStateStore } from '../../store/app-store';
import { formatPhoneNumber } from '../../utils';
import PhoneNumber from '../phone-number/PhoneNumber';
import renderHours from './LocationHours';
import { MapSize, StoreInformationProps } from './types';
import { currentDayOpenHours } from './utils';

export const StoreInformation: React.FunctionComponent<
  StoreInformationProps
> = ({
  item,
  onChangePress,
  mapProps,
  disabled = false,
  showChange: canChange,
  showPhone = true,
  showFullDetails = false,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const isWeb = Platform.OS === 'web';
  const isXsMap = mapProps?.size === MapSize.xs;

  const handleOnChangePress = () => {
    onChangePress(item);
  };

  const handleOnPhoneNumberPress = () => {
    Linking.openURL(`tel:${item.phone}`);
  };

  const { width } = useWindowDimensions();
  const mapWidth = (isWeb ? theme.webMaxWidth : width) - theme.getSpacing(2);

  const mapSizeConfig: Record<
    MapSize,
    Omit<MapProps, 'longitude' | 'latitude' | 'accessToken'>
  > = {
    md: { height: 200, width: mapWidth },
    sm: { height: 90, width: mapWidth },
    xs: { height: 90, width: 100 },
    lg: {
      height: 50,
      width: mapWidth,
    },
  };

  const {
    locationPharmacyHours,
    locationStoreHours,
    locationLanguages,
    locationOfferings,
  } = useAppStateStore();

  const DepartmentHours: FunctionComponent<{
    department: 'store' | 'pharmacy';
  }> = ({ department }) => {
    return (
      <Form.Row>
        <Form.Column>
          <View style={styles.hoursTitle}>
            <Text style={styles.hoursTitleText}>
              {department === 'store'
                ? getText('store-hours')
                : getText('pharmacy-hours')}
            </Text>
          </View>
          <Form.Row>
            <Form.Column>
              <ListAccordion
                title={getText('open-hours', {
                  hours: currentDayOpenHours(
                    department === 'store'
                      ? locationStoreHours
                      : locationPharmacyHours,
                  ),
                })}
                backgroundColor={theme.palette.white}
                titleStyle={styles.titleStyle}
                listStyle={styles.listStyle}
              >
                <Text style={styles.textColor}>
                  {getText('holiday-hours-vary')}
                </Text>
                {renderHours(
                  department === 'store'
                    ? locationStoreHours
                    : locationPharmacyHours,
                )}
              </ListAccordion>
            </Form.Column>
          </Form.Row>
        </Form.Column>
      </Form.Row>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flexDirection: isXsMap ? 'row' : 'column',
          flex: 1,
        }}
      >
        {mapProps && ((item.latitude && item.longitude) || item.address) && (
          <Map
            useWideGenericImage
            address={item.address}
            {...mapProps}
            {...(mapProps.size ? mapSizeConfig[mapProps.size] : {})}
          />
        )}
        <View style={[isXsMap && styles.leftSpacing, { flex: 1 }]}>
          <View style={styles.topSpacing}>
            <Text style={styles.selectedAddressTitle}>{item.name}</Text>
          </View>
          {item.address && (
            <View>
              <Text style={styles.selectedAddressSubTitle} numberOfLines={1}>
                {`${item.address.address1}, ${item.address.city}, ${item.address.state} ${item.address.postal_code}`}
              </Text>
            </View>
          )}
          <View
            style={[
              styles.bottomContainer,
              styles.topSpacing,
              !canChange && { flexDirection: 'row-reverse' },
            ]}
          >
            {canChange === true && (
              <TouchableOpacity onPress={handleOnChangePress}>
                <Text style={[styles.pressableText, isWeb && styles.pointer]}>
                  {getText('store-selector-change')}
                </Text>
              </TouchableOpacity>
            )}
            {item.phone && showPhone && (
              <View style={styles.phoneNumberSection}>
                <Pressable style={styles.changeText}>
                  {canChange === true && <Text>{getText('change')}</Text>}
                </Pressable>
                <View>
                  <PhoneNumber mode="link" phoneNumber={item.phone} />
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
      {showFullDetails && (
        <View>
          {locationStoreHours.length > 0 && (
            <DepartmentHours department={'store'} />
          )}
          {locationPharmacyHours.length > 0 && (
            <DepartmentHours department={'pharmacy'} />
          )}
          {locationLanguages && (
            <Form.Row>
              <Form.Column>
                <View style={styles.hoursTitle}>
                  <Text style={styles.hoursTitleText}>
                    {getText('languages-spoken')}
                  </Text>
                </View>
                <Text style={styles.textColor}>{locationLanguages}</Text>
              </Form.Column>
            </Form.Row>
          )}
          {locationOfferings && (
            <Form.Row>
              <Form.Column>
                <View style={styles.hoursTitle}>
                  <Text style={styles.hoursTitleText}>
                    {getText('services-offered')}
                  </Text>
                </View>
                <Text style={styles.textColor}>{locationOfferings}</Text>
              </Form.Column>
            </Form.Row>
          )}
        </View>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  selectedAddressTitle: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[900],
  },
  selectedAddressSubTitle: {
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  topSpacing: { marginTop: theme.getSpacing(1) },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[500],
  },
  phoneContainer: { flexDirection: 'row', alignItems: 'center' },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
  listStyle: {
    margin: 0,
    marginRight: -theme.getSpacing(1.5),
  },
  titleStyle: {
    color: theme.palette.gray[700],
    paddingLeft: 0,
  },
  hoursStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.getSpacing(1),
  },
  bottomSheetViewTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  hoursTitle: {
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
  },
  hoursTitleText: {
    fontSize: 14,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  textColor: {
    color: theme.palette.gray[700],
  },
  phoneNumberSection: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  changeText: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.blue[500],
  },
}));
