import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { CalendarIcon, CursorIcon, InfoIcon, RepeatIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { Surface } from 'react-native-paper';

export const MedicationInfo: FunctionComponent<MedicationInfoProps> = () => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.infoContainer}>
      <Text style={styles.infoDescription}>
        {getText('med-info-description-start')}
        <View style={styles.icon}>
          <Icon icon={InfoIcon} size={16} color={theme.palette.gray[500]} />
        </View>
        {getText('med-info-description-end')}
      </Text>
      <Surface style={styles.surface}>
        <View style={styles.surfaceInfo}>
          <View style={styles.medicine}>
            <Text style={styles.medicineText}>{getText('medicine-name')}</Text>
            <View>
              <View style={styles.pointer}>
                <View style={styles.pointerInfoIcon}>
                  <Icon
                    icon={InfoIcon}
                    size={16}
                    color={theme.palette.gray[500]}
                  />
                </View>
                <View style={styles.pointerCursorIcon}>
                  <Icon
                    icon={CursorIcon}
                    size={22}
                    color={theme.palette.gray[500]}
                  />
                </View>
              </View>
            </View>
          </View>

          <View style={styles.date}>
            <Icon icon={RepeatIcon} size={16} color={theme.palette.gray[500]} />
            <Text style={styles.dateText}>{getText('refill-due-date')}</Text>
          </View>
          <View style={styles.date}>
            <Icon
              icon={CalendarIcon}
              size={16}
              color={theme.palette.gray[500]}
            />
            <Text style={styles.dateText}>{getText('last-filled-date')}</Text>
          </View>
        </View>
      </Surface>
    </View>
  );
};

export type MedicationInfoProps = {};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    paddingTop: theme.getSpacing(1),
    gap: 36,
    alignItems: 'stretch',
    flex: 1,
  },
  infoDescription: {
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  surface: {
    elevation: 2,
    borderRadius: theme.roundness,
    overflow: 'hidden',
    backgroundColor: theme.palette.gray[100],
    paddingVertical: 50,
    paddingHorizontal: 43,
  },
  surfaceInfo: {
    paddingVertical: theme.getSpacing(1.5),
    paddingHorizontal: theme.getSpacing(2),
    backgroundColor: theme.palette.white,
    borderRadius: theme.getSpacing(1.5),
    shadowColor: theme.palette.gray[900],
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    elevation: 5,
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    alignItems: 'center',
  },
  medicine: {
    display: 'flex',
    flexDirection: 'row',
  },
  medicineText: {
    color: theme.palette.gray[900],
    fontSize: 14,
    lineHeight: 20,
  },
  dateText: {
    color: theme.palette.gray[700],
    fontSize: 14,
    lineHeight: 20,
  },
  pointer: {
    position: 'absolute',
    borderRadius: 100,
    backgroundColor: theme.palette.blueLight[200],
    width: 43,
    height: 43,
    top: -theme.getSpacing(0.5),
  },
  pointerInfoIcon: {
    paddingLeft: theme.getSpacing(1),
    paddingTop: theme.getSpacing(1),
  },
  pointerCursorIcon: {
    position: 'absolute',
    paddingLeft: theme.getSpacing(1.5),
    paddingTop: theme.getSpacing(2),
  },
  icon: {
    ...(Platform.OS === 'web' && {
      top: 3,
    }),
  },
}));
