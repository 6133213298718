import React, { FunctionComponent } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { View } from 'react-native';
import { Icon } from '../icon';
import { AlertTriangleIcon } from '../../icons';
import { makeStyles, useTheme } from '../../theme';
import { Text } from '../text';
import { DropdownSelectFieldProps } from './types';
import { DropdownSelect } from './DropdownSelect';
import { getNestedObjectValue } from '../../utils/common';

export const DropdownSelectField: FunctionComponent<
  DropdownSelectFieldProps
> = ({
  label,
  disabled = false,
  options,
  name,
  placeholder,
  labelInlined = false,
  rules,
  hideRequiredAsterisk,
  testID,
}) => {
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('Dropdown/Select field must have a parent form context');
  }

  const theme = useTheme();
  const styles = useStyles();

  const { control, formState } = formContext;
  const error = getNestedObjectValue(formState.errors, name);

  return (
    <View>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <DropdownSelect
            label={
              label && rules?.required && !hideRequiredAsterisk
                ? `${label} *`
                : label
            }
            disabled={disabled}
            options={options}
            value={value}
            placeholder={placeholder}
            labelInlined={labelInlined}
            onChange={onChange}
            testID={testID ?? `dropdown-form-field-${name}`}
          />
        )}
      />
      {!!error && (
        <>
          <View style={styles.container}>
            <View style={styles.icon}>
              <Icon
                icon={AlertTriangleIcon}
                color={theme.palette.error[600]}
                size={16}
              />
            </View>
            <Text
              testID={DropdownSelectIDs.errorMessage}
              style={styles.errorMessage}
            >
              {error.message}
            </Text>
          </View>
        </>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
  },
  errorMessage: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.error[600],
  },
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
}));

export const DropdownSelectIDs = {
  label: 'dropdown-label',
  errorMessage: 'dropdown-field-error-message',
};
