import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';
import { FC } from 'react';

export const CancelCircleIcon: FC<IconProps> = ({
  color,
  size,
  testID,
  ...restProps
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      color={color}
      testID={testID}
      {...restProps}
    >
      <Path
        d="M4.10829 4.10829L15.8916 15.8916M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996Z"
        stroke="#748995"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
