import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { useWindowDimensions, View } from 'react-native';
import { InsuranceHandler, InsuranceProps } from './InsuranceProps';
import { GenericBottomSheet } from 'assets/components/generic-bottom-sheet/GenericBottomSheet';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { getText } from 'assets/localization/localization';
import { Form } from 'assets/layout';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { useForm } from 'react-hook-form';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { InsuranceTestID } from './InsuranceTestID';

const InsuranceComponent: ForwardRefRenderFunction<
  InsuranceHandler,
  InsuranceProps
> = (
  { handleInsuranceSubmit }: InsuranceProps,
  ref: ForwardedRef<InsuranceHandler>,
) => {
  const modalRef = React.useRef<BaseModalHandler>(null);

  const [showError, setShowError] = useState(false);

  const { height } = useWindowDimensions();
  const styles = useStyles();
  const methods = useForm({
    defaultValues: {
      changedInsurance: '',
    },
  });

  const handleShow = () => {
    modalRef.current?.show();
  };

  const handleHide = () => {
    modalRef.current?.hide();
  };

  const handleOnPress = () => {
    if (methods.getValues().changedInsurance === '') {
      setShowError(true);
      return;
    }
    handleInsuranceSubmit(methods.getValues().changedInsurance);
    handleHide();
    methods.setValue('changedInsurance', '');
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <GenericBottomSheet
      ref={modalRef}
      title={getText('insurance-card')}
      height={height < 550 ? '80%' : height <= 800 ? '50%' : '50%'}
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: handleOnPress,
          testID: InsuranceTestID.okModalButton,
          logger: { id: InsuranceTestID.okModalButton },
        },
      ]}
    >
      <View>
        <Form methods={methods}>
          <Form.Alert
            visible={!!methods.getFieldState('changedInsurance').error}
            title={getText('unable-to-process-complete-required-fields')}
            intent="error"
          />
          <Form.Row>
            <Form.Column>
              <RadioButtonGroupField
                label={getText('changed-insurance')}
                rules={{
                  required: getText('field-required'),
                }}
                name="changedInsurance"
                values={[
                  {
                    text: getText('yes'),
                    value: 'yes',
                  },
                  {
                    text: getText('no'),
                    value: 'no',
                  },
                ]}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              {showError && (
                <Text style={styles.error}>{getText('field-required')}</Text>
              )}
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    </GenericBottomSheet>
  );
};

export const Insurance = forwardRef<InsuranceHandler, InsuranceProps>(
  InsuranceComponent,
);

const useStyles = makeStyles((theme) => ({
  error: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 24,
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    color: theme.palette.error[700],
  },
}));
