import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  createStackNavigator,
  StackHeaderProps,
} from '@react-navigation/stack';
import { GestureResponderEvent, Platform } from 'react-native';
import { NativeHeader } from 'assets/components/native-header';
import type { StackNavigationProp } from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import { CloseIcon } from 'assets/icons';
import { MultipleLocationsPharmacy } from './MultipleLocationsPharmacy';
import { SingleLocationPharmacy } from './SingleLocationPharmacy';
import {
  resetTransferMedsState,
  useTransferMedicationsStore,
} from './transfer-meds-store';
import { isUserAuthorized, useUserState } from '../../store/user-store';
import { AboutTransfer } from './AboutTransfer';
import { TransferMedsReview } from './TransferMedsReview';
import { EnterMedications } from './EnterMedications';
import { TransferMedsSuccess } from './TransferMedsConfirmation';
import { SelectLocation } from './SelectLocation';
import { PatientUnderCareNavigation } from '../account/patient/components/account-information/linked-accounts/PatientUnderCareNavigation';

const Stack = createStackNavigator<TransferMedicationsStackParamList>();

export const TransferMedicationsNavigation: FunctionComponent<
  PropsWithChildren<TransferMedicationsNavProps>
> = ({}) => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.user));

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: isAuthorized && Platform.OS !== 'web',
        header: TransferMedicationsNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen
          name="multiple-locations"
          component={MultipleLocationsPharmacy}
          options={{
            title: getText('transfer-meds-tab'),
            cardStyle: { flex: 1 },
          }}
        />
        <Stack.Screen
          name="single-location"
          component={SingleLocationPharmacy}
          options={{
            title: getText('transfer-meds-tab'),
          }}
        />
        <Stack.Screen
          name="location-select"
          component={SelectLocation}
          options={{
            title: getText('transfer-meds-tab'),
          }}
        />
        <Stack.Screen
          name="about-transfer"
          component={AboutTransfer}
          options={{
            title: getText('transfer-meds-tab'),
          }}
        />
        <Stack.Screen
          name="enter-medications"
          component={EnterMedications}
          options={{
            title: getText('transfer-meds-tab'),
          }}
        />
        <Stack.Screen
          name="review-transfer"
          component={TransferMedsReview}
          options={{
            title: getText('transfer-meds-tab'),
          }}
        />
        <Stack.Screen
          name="success-page"
          component={TransferMedsSuccess}
          options={{
            title: getText('transfer-meds-tab'),
          }}
        />
        <Stack.Screen
          name="people-under-care"
          component={PatientUnderCareNavigation}
          options={{ headerShown: false, title: getText('people-under-care') }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface TransferMedicationsNavProps {}

export type TransferMedicationsStackParamList = {
  'multiple-locations': undefined;
  'single-location': undefined;
  'location-select': undefined;
  'about-transfer': undefined;
  'enter-medications': undefined;
  'review-transfer': undefined;
  'success-page': undefined;
  'people-under-care': undefined;
};

export type TransferMedicationsStackNavProp =
  StackNavigationProp<TransferMedicationsStackParamList>;

export const TransferMedicationsNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const { originated } = useTransferMedicationsStore();

  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  const handleActionPress = (event: GestureResponderEvent) => {
    resetTransferMedsState();

    if (originated === 'onboarding') {
      props.navigation.navigate('app', { screen: 'home' });
    } else {
      props.navigation.navigate('app', { screen: 'medications' });
    }
  };

  return (
    <>
      <NativeHeader
        title={getText('transfer-meds-header-title')}
        actionLabel={getText('cancel')}
        actionIcon={CloseIcon}
        onActionPress={handleActionPress}
        onBack={handleBack}
        showAction={true}
        showBack={true}
      />
    </>
  );
};
