import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { ScrollView, View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useResetPasswordState } from './reset-password-store';
import { ResetPasswordForm, SendOptions } from './reset-password-actions';
import { useNavigation } from '@react-navigation/native';
import { LoginStackNavigationProp } from '../../navigation/LoginNavigation';
import { sendResetLink } from './reset-password-actions';
import { ResetPasswordTestIDs } from './ResetPasswordTestIDs';
import { emailRegex } from '../../common/form-utils';
import { getText } from 'assets/localization/localization';
import { ScreenContainer, Form } from 'assets/layout';
import { ResetLinkRadioButton } from './ResetLinkRadioButton';
import { ResetPasswordRequestDtoNotificationTypeEnum } from '@digitalpharmacist/users-service-client-axios';

type ResetLinkChoicesType = {
  text: string;
  value: SendOptions;
  description?: string;
};
const choices: Array<ResetLinkChoicesType> = [
  {
    text: getText('email-short'),
    value: SendOptions.email,
  },
  {
    text: getText('text-message'),
    value: SendOptions.sms,
    description: getText('link-will-be-sent'),
  },
];

export const ResetLink: FunctionComponent<
  PropsWithChildren<ResetLinkProps>
> = () => {
  const styles = useStyles();
  const navigation = useNavigation<LoginStackNavigationProp>();
  const methods = useForm<ResetPasswordForm>({
    defaultValues: {
      email: '',
    },
  });
  const { status, error } = useResetPasswordState();

  const [sendResetLinkMethod, setSendResetLinkMethod] =
    useState<ResetPasswordRequestDtoNotificationTypeEnum>(
      ResetPasswordRequestDtoNotificationTypeEnum.Email,
    );

  const handleSubmit = async () => {
    await sendResetLink(
      { ...methods.getValues() },
      navigation,
      sendResetLinkMethod,
    );
  };

  return (
    <ScreenContainer keyboardVerticalOffset={200}>
      <View style={styles.root}>
        <ScrollView>
          <View style={styles.fieldContainer}>
            <Text style={styles.resetPasswordText}>
              {getText('reset-password')}
            </Text>
            <Text style={styles.resetPasswordDescriptionText}>
              {getText('please-enter-email')}
            </Text>
          </View>
          <Form methods={methods}>
            <Form.Alert
              title={error?.message}
              intent="error"
              visible={!!error}
            />
            <Form.Row>
              <Form.Column>
                <TextField
                  testID={ResetPasswordTestIDs.emailInput}
                  label={getText('email')}
                  name="email"
                  rules={{
                    required: getText('email-is-required'),
                    pattern: {
                      value: emailRegex,
                      message: getText('email-is-not-valid'),
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === 'loading'}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Text style={styles.resetLinkOptionText}>
                {getText('how-would-you-like-to-receive-reset-link')}
              </Text>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                {choices.map((choice) => (
                  <ResetLinkRadioButton
                    key={choice.value}
                    text={choice.text}
                    description={choice.description}
                    value={choice.value}
                    selected={sendResetLinkMethod === choice.value}
                    disabled={status === 'loading'}
                    onPress={setSendResetLinkMethod}
                  />
                ))}
              </Form.Column>
            </Form.Row>
            <Form.Actions>
              <View style={styles.fieldContainer}>
                <Button
                  onPress={methods.handleSubmit(handleSubmit)}
                  hierarchy="primary"
                  loading={status === 'loading'}
                  logger={{ id: 'get-reset-password-link-submit-button' }}
                >
                  {getText('send-reset-link')}
                </Button>
              </View>
            </Form.Actions>
          </Form>
        </ScrollView>

        <View style={styles.fullWidth}>
          <Text style={styles.footerText}>
            {getText('standard-text-apply')}
          </Text>
        </View>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  fieldContainer: {
    marginBottom: theme.getSpacing(2),
  },
  resetPasswordText: {
    color: theme.palette.gray[900],
    fontSize: 30,
    textAlign: 'center',
  },
  resetPasswordDescriptionText: {
    color: theme.palette.gray[600],
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 24,
  },
  resetLinkOptionText: {
    color: theme.palette.gray[600],
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 24,
  },
  fullWidth: {
    width: '100%',
  },
  footerText: {
    fontSize: 12,
    textAlign: 'center',
    lineHeight: 18,
    color: theme.palette.gray[500],
  },
}));

interface ResetLinkProps {}
