import React, { FunctionComponent } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { AllergyTypeahead, AllergyTypeaheadProps } from './AllergyTypeahead';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { Icon } from 'assets/components/icon';
import { View } from 'react-native';
import { AlertTriangleIcon } from 'assets/icons/AlertTriangleIcon';

export const AllergyTypeaheadField: FunctionComponent<
  AllergyTypeaheadFieldProps
> = ({ name, rules, componentProps }) => {
  const styles = useStyles();
  const theme = useTheme();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('AllergyTypeaheadField must have a parent form context');
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <AllergyTypeahead
            {...componentProps}
            onChange={(e) => {
              onChange(e);
            }}
            allergyIds={value}
          />
        )}
        name={name}
        rules={rules}
      />
      {!!error && (
        <View style={styles.container}>
          <View style={styles.icon}>
            <Icon
              icon={AlertTriangleIcon}
              color={theme.palette.error[600]}
              size={16}
            />
          </View>
          <Text
            testID={AllergyTypeaheadFieldTestIDs.error}
            style={styles.errorMessage}
          >
            {error.message}
          </Text>
        </View>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
  icon: {
    marginRight: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(1),
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
}));

export interface AllergyTypeaheadFieldProps {
  name: string;
  rules?: RegisterOptions;
  componentProps?: AllergyTypeaheadProps;
}

export const AllergyTypeaheadFieldTestIDs = {
  error: 'allergy-typeahead-formControl-error',
};
