import { FunctionComponent, useEffect, useRef } from 'react';
import {
  AuthorType,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  setIncomeMessage,
  setRawConversations,
  setViewedConversationsSet,
  setUpdatedUserStatus,
  setSocket,
  buildMessageList,
  setCounts,
  setTypingMember,
  setIsLoading,
  removeSelectedConversation,
  removeSelectedMessages,
} from './messages-actions';
import { io, Socket } from 'socket.io-client';
import { useUserState } from '../../store/user-store';
import { UserTyping } from './types';
import { INBOX_SOCKET_URL } from '../../common/constants';
import { StorageKeys } from '../../../enums/storage-keys';
import UnifiedCommsService from '../../api/unified-comms-service';
import { compare } from 'assets/utils/messageUtils';
import { useMessagesState } from './messages-store';
import {
  EmittedMessage,
  EmittedUpdatedUserStatus,
  Order,
} from 'assets/types/messageTypes';
import AsyncStorageService from '../../api/async-storage-service';
import { AppState } from 'react-native';
import { markAsRead } from './utils';
import { useAppNavigation } from '../../providers/app-navigation';

export const onType = (
  conversationId: string,
  locationId: string,
  locationPatientId: string,
  userId: string,
  name: string,
  socket: Socket,
) => {
  const userTyping: UserTyping = {
    id: userId,
    name: name,
    location_id: locationId,
    conversation_id: conversationId,
    location_patient_id: locationPatientId,
    author_type: AuthorType.Patient,
  };
  socket.emit('typing', userTyping);
};

export const InitializeSockets: FunctionComponent<InitializeSocketsProps> = ({
  currentUrl,
}) => {
  const socket = useMessagesState((state) => state.socket);
  const rawConversations = useMessagesState((state) => state.rawConversations);
  const viewedConversationsSet = useMessagesState(
    (state) => state.viewedConversationsSet,
  );
  const selectedConversation = useMessagesState(
    (state) => state.selectedConversation,
  );
  const user = useUserState((state) => state.user);
  const typingTimer = useRef<ReturnType<typeof setTimeout>>();
  const pharmacyId = user?.pharmacyId ?? '';
  const locationId = user?.preferredPharmacyLocationId ?? '';
  const locationPatientRecordId = user?.preferredPharmacyLprId ?? '';
  const userId = user?.id ?? '';
  const patientRecordId = user?.patientRecordId ?? '';
  const navigation = useAppNavigation<StackNavigationProp<ParamListBase>>();

  useEffect(() => {
    if (socket && locationPatientRecordId) {
      socket.emit('join', {
        type: AuthorType.Patient,
        id: locationPatientRecordId,
      });
    }
  }, [socket, locationPatientRecordId]);

  useEffect(() => {
    void (async () => {
      if (socket) {
        await buildMessageList();
        setIsLoading(false);
      }
    })();
  }, [locationId, socket]);

  useEffect(() => {
    if (!socket) {
      void (async () => {
        const token = await AsyncStorageService.getItem(
          StorageKeys.AccessToken,
        );
        if (token) {
          const socketInit = io(INBOX_SOCKET_URL, {
            query: {
              client: AuthorType.Patient,
              'x-pharmacy-id': pharmacyId,
            },
            auth: {
              token,
            },
            transports: ['websocket'],
          });
          setSocket(socketInit);
        }
      })();
    } else {
      AppState.addEventListener('change', (state) => {
        if (state === 'active') {
          setSocket(undefined);
        }
        if (state === 'background') {
          setIsLoading(true);
        }
      });

      const onMessagesReceived = async (message: EmittedMessage) => {
        await setIncomeMessage(
          message.pharmacy_id,
          message.location_id,
          message.location_patient_id,
          message.conversation_id,
          message.author_type,
        );

        const isInSameConversation =
          selectedConversation === message.conversation_id;

        if (
          isInSameConversation &&
          message.author_type !== AuthorType.Patient
        ) {
          const pharmacyViewedAllMessages = rawConversations.find(
            (conversation) =>
              conversation.conversation_id === selectedConversation,
          );
          if (pharmacyViewedAllMessages) {
            await markAsRead(
              locationId,
              message.location_patient_id,
              message.conversation_id,
              pharmacyViewedAllMessages.pharmacy_viewed_all_messages,
            );
          }
        }
      };

      const onTypingReceived = (userTyping: UserTyping) => {
        if (userTyping.id === userId) {
          return;
        }

        clearTimeout(typingTimer.current);
        setTypingMember(userTyping);

        typingTimer.current = setTimeout(() => {
          setTypingMember(null);
        }, 1000);
      };

      const onNewConversation = async () => {
        if (!patientRecordId || !locationPatientRecordId || !locationId) {
          // TODO: find out why patientRecordId was added here, since it is not used in this function
          return;
        }

        const rawConversationsData: DirectMessagePatientDto[] =
          await UnifiedCommsService.getAllConversationsByPatientAndLocation(
            locationId,
            locationPatientRecordId,
          );

        const newViewedConversations = rawConversationsData
          .filter((conversation) => conversation.patient_viewed_all_messages)
          .map((conversation) => conversation.conversation_id);

        const sortedConversationsData = rawConversationsData.sort(
          (currentConversation, nextConversation) =>
            compare(
              currentConversation,
              nextConversation,
              'most_recent_qualifying_message_date',
              Order.DESC,
              true,
            ),
        );

        const newViewedConversationsSet = new Set(newViewedConversations);
        setRawConversations(sortedConversationsData);
        setViewedConversationsSet(newViewedConversationsSet);
      };

      const onUpdatedUserStatus = async (
        updatedUserStatus: EmittedUpdatedUserStatus,
      ) => {
        await setUpdatedUserStatus(updatedUserStatus);
      };

      const logConnectionError = (error: any) => {
        console.error('Websocket connection error message: ', error.message);
        console.error(
          'Websocket connection error description: ',
          error.description,
        );
      };

      const onLPRMerge = async () => {
        const rawConversationsData: DirectMessagePatientDto[] =
          await UnifiedCommsService.getAllConversationsByPatientAndLocation(
            locationId,
            locationPatientRecordId,
          );

        const sortedConversationsData = rawConversationsData.sort(
          (currentConversation, nextConversation) =>
            compare(
              currentConversation,
              nextConversation,
              'most_recent_qualifying_message_date',
              Order.DESC,
              true,
            ),
        );

        setRawConversations(sortedConversationsData);

        // if a patient is in conversation
        if (currentUrl?.includes('conversation-box')) {
          navigation?.navigate('app', { screen: 'messages' });

          removeSelectedConversation();
          removeSelectedMessages();
        }
      };

      socket.on('new_conversation', onNewConversation);
      socket.on('typing', onTypingReceived);
      socket.on('message', onMessagesReceived);
      socket.on('updated_viewed_user_status', onUpdatedUserStatus);
      socket.on('lpr_merge', onLPRMerge);
      socket.on('connect_error', logConnectionError);
      return () => {
        // socket.off('new_conversation', onNewConversation);
        // socket.off('message', onMessagesReceived);
        // socket.off('typing', onTypingReceived);
        // socket.off('updated_viewed_user_status', onUpdatedUserStatus);
        // socket.off('lpr_merge', onLPRMerge);
        // socket.off('connect_error', logConnectionError);
      };
    }
  }, [
    socket,
    pharmacyId,
    locationId,
    selectedConversation,
    locationPatientRecordId,
    userId,
    patientRecordId,
    socket?.connected,
  ]);

  useEffect(() => {
    const newViewedConversations = rawConversations
      .filter((conversation) => conversation.patient_viewed_all_messages)
      .map((conversation) => conversation.conversation_id);

    const newViewedConversationsSet = new Set(newViewedConversations);
    setViewedConversationsSet(newViewedConversationsSet);
  }, [rawConversations]);

  useEffect(() => {
    void (async () => {
      const count: number =
        rawConversations.length - viewedConversationsSet.size;
      setCounts({ unread: count });
    })();
  }, [rawConversations, viewedConversationsSet]);

  // since this is using react hooks like userEffect it is not an action
  // i assume this is used only to initialize some initial values
  // and then use the effects to change them
  // this means it can be converted to a component without an output
  // so that we can use this in a react way like <InitializeSockets /> on some upper level of the component tree
  return null;
};

interface InitializeSocketsProps {
  currentUrl?: string;
}
