import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { NewMessageHandler, NewMessageProps } from './MessageProps';
import { NewMessageModalComponent } from './NewMessageModalComponent';
import UnifiedCommsService from '../../api/unified-comms-service';
import { CreateConversationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { createLprIfNotExist } from '../../utils';
import { ampli } from '../../common/ampliPatient';

const NewMessageComponent: ForwardRefRenderFunction<
  NewMessageHandler,
  NewMessageProps
> = (
  {
    platform = Platform.OS,
    preSelectedSubject,
    preSelectedMessage,
    locationId,
    ...rest
  }: NewMessageProps,
  ref: ForwardedRef<NewMessageHandler>,
) => {
  const newMessageRef = React.useRef<NewMessageHandler>(null);
  const [isPatientStartChatAvailable, setIsPatientStartChatAvailable] =
    useState<boolean>(true);

  const handleShow = () => {
    newMessageRef.current?.show();
  };

  const handleHide = () => {
    newMessageRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleMessageSend = async (
    pharmacyId: string,
    locationId: string,
    patientRecord: PatientRecordDto,
    createConversationDto: CreateConversationDto,
    locationPatientId?: string,
  ) => {
    const { is_patient_start_chat_available } =
      await UnifiedCommsService.getDirectMessageSettings(
        pharmacyId,
        locationId,
      );

    setIsPatientStartChatAvailable(is_patient_start_chat_available);

    if (!is_patient_start_chat_available) return;

    const finalLocationPatientId = await createLprIfNotExist(
      locationPatientId,
      locationId,
      patientRecord,
    );

    const messageCreated = await UnifiedCommsService.createConversation(
      pharmacyId,
      locationId,
      finalLocationPatientId,
      createConversationDto,
    );

    if (rest.onMessageCreate) {
      rest.onMessageCreate();
    }

    ampli.messageSent({
      messageSentTime: new Date().toISOString(),
      messageSubject: createConversationDto.subject,
      messageID: '',
      messageStatus: 'sent',
      conversationID: messageCreated.id,
    });

    handleHide();
  };

  return (
    <NewMessageModalComponent
      {...rest}
      ref={newMessageRef}
      handleMessageSend={handleMessageSend}
      preSelectedSubject={preSelectedSubject}
      preSelectedMessage={preSelectedMessage}
      locationId={locationId}
      isPatientStartChatAvailable={isPatientStartChatAvailable}
    />
  );
};

export const NewMessage = forwardRef<NewMessageHandler, NewMessageProps>(
  NewMessageComponent,
);
