import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { USER_API_BASE_URL } from '../common/constants';
import {
  GeneralApi as UsersServiceClient,
  NoAuthApi as NoAuthUsersServiceClient,
  UserLogin,
  UserLoginResponse,
  VerifyPhoneNumberRequest,
  TermsAndConditionResponse,
  UpdateUserRequest,
  UserRegisterDto,
  PatientUserDto,
  CheckUserDto,
  UserPatientPasswordSetPost200Response,
  PharmacyUserDto,
  TokenResponseDto,
  RefreshTokenRequestDto,
  UserLoginSSOResponse,
  UserLoginSSOBody,
  ConfirmPasswordDto,
  ResetPasswordRequestDto,
  Ok,
  UserVerificationRequestDto,
  ChangePasswordRequestDto,
  ResendPhoneNumberConfimationCodeRequestDto,
  VerifyEmailDto,
  NotificationType,
} from '@digitalpharmacist/users-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IUsersService {
  logIn(values: UserLogin): Promise<UserLoginResponse | null>;
  getUser(userId: string): Promise<PatientUserDto>;
  getUserByEmail(email: string, pharmacyId: string): Promise<PatientUserDto>;
  verifyPhoneNumber(
    verifyPhoneNumberRequest: VerifyPhoneNumberRequest,
  ): Promise<PatientUserDto>;
  resetPassword(values: ResetPasswordRequestDto): Promise<Ok>;
  confirmPassword(values: ConfirmPasswordDto): Promise<Ok>;
  registerPatient(values: UserLogin): Promise<PatientUserDto>;
  updatePatient(
    userId: string,
    updateUserRequest: UpdateUserRequest,
  ): Promise<PatientUserDto>;
  userPatientIdPrivacyAndPolicyPatch(
    id: string,
  ): Promise<TermsAndConditionResponse>;
  userPatientIdTermsAndConditionPatch(
    id: string,
  ): Promise<TermsAndConditionResponse>;
  userPatientAccountVerificationPost(body: object): Promise<UserLoginResponse>;
  userPatientResendConfirmationCodePost(
    userDetails?: UserVerificationRequestDto,
  ): Promise<void>;
  userPatientResendPhoneNumberConfirmationCodePost(
    patientId: string,
    resendPhoneNumberConfimationCodeRequestDto?: ResendPhoneNumberConfimationCodeRequestDto,
  ): Promise<void>;
  checkUser(email: string, pharmacy_id: string): Promise<CheckUserDto>;
  passwordSet(values: object): Promise<UserPatientPasswordSetPost200Response>;
  googleLogin(values: object): Promise<UserLoginSSOResponse>;
  userPatientPasswordChangePost(
    changePasswordRequestDto?: ChangePasswordRequestDto,
  ): Promise<Ok>;
  getPharmacistUsersByIds(ids: string[]): Promise<PharmacyUserDto[]>;
  patientUserVerifyEmail(
    verifyEmailDto?: VerifyEmailDto,
  ): Promise<PatientUserDto>;
  sendNotificationWhenAccountLocked(
    email: string,
    notificationType: NotificationType,
    pharmacyId: string,
  ): Promise<void>;
}

export class UsersService extends BaseApiService implements IUsersService {
  private userServiceClient: UsersServiceClient;
  private noAuthUsersServiceClient: NoAuthUsersServiceClient; //Contains the endpoints that do not need auth verification

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.userServiceClient = new UsersServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
    this.noAuthUsersServiceClient = new NoAuthUsersServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async logIn(userLogin: UserLogin): Promise<UserLoginResponse> {
    return (
      await this.userServiceClient.userPatientLoginPost(userLogin, {
        disableAuth: true,
      })
    ).data;
  }

  async verifyPhoneNumber(
    verifyPhoneNumberRequest: VerifyPhoneNumberRequest,
  ): Promise<PatientUserDto> {
    return (
      await this.userServiceClient.userPatientPhoneNumberVerificationPost(
        verifyPhoneNumberRequest,
      )
    ).data;
  }

  async getUser(userId: string): Promise<PatientUserDto> {
    return (await this.userServiceClient.userPatientUserIdGet(userId)).data;
  }

  async getUserByEmail(
    email: string,
    pharmacyId: string,
  ): Promise<PatientUserDto> {
    return (
      await this.userServiceClient.userPatientEmailEmailGet(email, pharmacyId)
    ).data;
  }

  async getPharmacistUser(userId: string): Promise<PharmacyUserDto> {
    return (await this.userServiceClient.userPharmacistIdGet(userId)).data;
  }

  async getPharmacistUsersByIds(ids: string[]): Promise<PharmacyUserDto[]> {
    return (
      await this.userServiceClient.userPharmacistIdListPost({ userIdList: ids })
    ).data;
  }

  async resetPassword(values: ResetPasswordRequestDto): Promise<Ok> {
    const { data } =
      await this.userServiceClient.userPatientResetPasswordPost(values);
    return data;
  }

  async confirmPassword(values: ConfirmPasswordDto): Promise<Ok> {
    const { data } =
      await this.userServiceClient.userPatientConfirmPasswordPost(values);
    return data;
  }

  async registerPatient(values: UserRegisterDto): Promise<PatientUserDto> {
    return (await this.userServiceClient.userPatientRegisterPost(values)).data;
  }

  async updatePatient(
    userId: string,
    updateUserRequest: UpdateUserRequest,
  ): Promise<PatientUserDto> {
    return (
      await this.userServiceClient.userPatientUserIdPatch(
        userId,
        updateUserRequest,
      )
    ).data;
  }

  async userPatientIdPrivacyAndPolicyPatch(
    id: string,
  ): Promise<TermsAndConditionResponse> {
    return (await this.userServiceClient.userPatientIdPrivacyAndPolicyPatch(id))
      .data;
  }

  async userPatientIdTermsAndConditionPatch(
    id: string,
  ): Promise<TermsAndConditionResponse> {
    return (
      await this.userServiceClient.userPatientIdTermsAndConditionPatch(id)
    ).data;
  }

  async userPatientPasswordChangePost(
    changePasswordRequestDto?: ChangePasswordRequestDto,
  ): Promise<Ok> {
    return (
      await this.userServiceClient.userPatientPasswordChangePost(
        changePasswordRequestDto,
      )
    ).data;
  }

  async userPatientAccountVerificationPost(
    body: object,
  ): Promise<UserLoginResponse> {
    return (
      await this.userServiceClient.userPatientAccountVerificationPost(body)
    ).data;
  }

  async userPatientResendConfirmationCodePost(
    userDetails?: UserVerificationRequestDto,
  ): Promise<void> {
    await this.userServiceClient.userPatientResendConfirmationCodePost(
      userDetails,
    );
  }

  async userPatientResendPhoneNumberConfirmationCodePost(
    patientId: string,
    resendPhoneNumberConfimationCodeRequestDto?: ResendPhoneNumberConfimationCodeRequestDto,
  ): Promise<void> {
    await this.userServiceClient.userPatientPatientIdResendPhoneNumberConfirmationCodePost(
      patientId,
      resendPhoneNumberConfimationCodeRequestDto,
    );
  }

  async patientUserVerifyEmail(
    verifyEmailDto?: VerifyEmailDto,
  ): Promise<PatientUserDto> {
    return (await this.userServiceClient.patientUserVerifyEmail(verifyEmailDto))
      .data;
  }

  async checkUser(email: string, pharmacy_id: string): Promise<CheckUserDto> {
    return (
      await this.userServiceClient.userPatientCheckUserPharmacyIdEmailGet(
        pharmacy_id,
        email,
      )
    ).data;
  }

  async passwordSet(
    values: object,
  ): Promise<UserPatientPasswordSetPost200Response> {
    return (await this.userServiceClient.userPatientPasswordSetPost(values))
      .data;
  }

  async googleLogin(
    userLoginSSOBody: UserLoginSSOBody,
  ): Promise<UserLoginSSOResponse> {
    const { data } = await this.userServiceClient.userPatientLoginSsoPost(
      userLoginSSOBody,
      {
        disableAuth: false,
      },
    );
    return data;
  }

  async refreshToken(
    refreshTokenRequest: RefreshTokenRequestDto,
  ): Promise<TokenResponseDto> {
    const { data } = await this.userServiceClient.userPatientTokenRefreshPost(
      refreshTokenRequest,
      { disableAuth: true },
    );
    return data;
  }

  async sendNotificationWhenAccountLocked(
    email: string,
    notificationType: NotificationType,
    pharmacyId: string,
  ): Promise<void> {
    const { data } =
      await this.noAuthUsersServiceClient.userPatientEmailEmailLockedAccountPost(
        email,
        notificationType,
        pharmacyId,
        { disableAuth: true },
      );
    return data;
  }
}

export default new UsersService(
  USER_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
