import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { makeStyles, useTheme } from '../../theme';
import { Text } from '../text';
import {
  TypeaheadWithTagsAndEmpty,
  TypeaheadWithTagsAndEmptyProps,
} from './TypeaheadWithTagsAndEmpty';

export const TypeaheadWithTagsAndEmptyField: FunctionComponent<
  PropsWithChildren<TypeaheadWithTagsAndEmptyFieldProps>
> = ({
  name,
  rules,
  label,
  hideRequiredAsterisk,
  typeaheadWithTagsAndEmptyProps: typeaheadProps,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error(
      'TypeaheadWithTagsAndEmptyField must have a parent form context',
    );
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TypeaheadWithTagsAndEmpty
            {...typeaheadProps}
            onChange={(e) => {
              onChange(e);
            }}
            defaultValue={value}
            label={
              label && rules?.required && !hideRequiredAsterisk
                ? `${label} *`
                : label
            }
          />
        )}
        name={name}
        rules={rules}
      />
      {!!error && (
        <Text
          testID={TypeaheadWithTagsAndEmptyFieldTestIDs.error}
          style={styles.errorMessage}
        >
          {error.message}
        </Text>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
}));

export interface TypeaheadWithTagsAndEmptyFieldProps {
  name: string;
  rules?: RegisterOptions;
  label?: string;
  hideRequiredAsterisk?: boolean;
  typeaheadWithTagsAndEmptyProps: TypeaheadWithTagsAndEmptyProps;
}

export const TypeaheadWithTagsAndEmptyFieldTestIDs = {
  error: 'typeahead-with-tags-formControl-error',
};
