import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from './types';

export const PaperlessEnrollmentIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ size, testID }) => {
  return (
    <Svg width={size} height={size} fill="none" testID={testID}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6603 42.7976C18.5735 42.0144 18.4573 41.2365 18.3121 40.4651L18.3136 40.4644C17.3959 32.8002 11.9864 24.9977 5.47827 20.7891C12.3086 24.3647 17.9604 31.2739 19.7832 38.3726C23.9773 30.5189 17.3163 18.3404 0 14C5.91376 16.5556 1.84038 35.777 11.1125 40.4579C12.6825 41.2509 14.5001 41.5494 16.4579 41.102C16.6056 42.5589 16.6686 44.3201 16.5821 46C17.2688 44.8514 17.9615 43.7842 18.6603 42.7976Z"
        fill="#118F7E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3092 53.7094C13.7097 55.0283 13.1667 56.2633 13 56.654C13.841 58.1361 14.7236 59.5847 15.6467 61C17.0229 57.8909 18.5071 54.6704 20.0988 51.3384C21.667 48.0811 23.551 45.0284 25.7082 42.2189C28.2139 44.8056 31.1937 46.1441 34.2567 46.5053C52.344 48.637 63.8585 16.0456 75 18.0462C45.2469 7.51601 24.1907 19.0983 23.2535 34.8968C32.4599 26.1247 47.1947 21.3906 60.6341 22.7731C47.0952 22.6479 31.9036 28.9487 23.5283 39.4507L23.529 39.4533C21.8216 41.3163 20.2397 43.311 18.7992 45.4237C17.2715 47.7901 15.775 50.5524 14.3092 53.7094Z"
        fill="#374D58"
      />
    </Svg>
  );
};
