import {
  BrandingDto,
  NormalTimeRangeDto,
  PharmacyDto,
  PharmacyLocationDto,
  PickupMethodResponseDto,
  ServiceDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { LocationConfigDto } from '@digitalpharmacist/prescription-service-client-axios';
import { UserNotificationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { DeepLinkingConfig } from 'assets/core/deep-linking';
import { Platform } from 'react-native';
import create from 'zustand';
import { PHARMACY_ID, PHARMACY_SLUG } from '../common/constants';
import { AsyncStatus } from './types';

export interface AppState {
  slug?: string;
  pharmacyId: string;
  pharmacyName: string;
  // TODO: review pharmacy store data, in my opinion we should end in one pharmacy object (remove pharmacyId and pharmacyName, make the default like pharmacy: {id: PHARMACY_ID} as PharmacyDto)
  pharmacy: PharmacyDto;
  branding?: BrandingDto;
  deepLinkingConfig?: DeepLinkingConfig;
  stores: PharmacyLocationDto[];
  locationStoreHours: NormalTimeRangeDto[];
  locationPharmacyHours: NormalTimeRangeDto[];
  locationLanguages?: string;
  locationOfferings?: string;
  locationServices: ServiceDto[];
  locationPickupMethods: PickupMethodResponseDto[];
  isMobileWeb?: boolean;
  notifications: UserNotificationDto[];
  notificationsBadgeCount: number;
  locationPrescriptionConfig: LocationConfigDto;
  appNavigationLoaded: boolean;
  authNavigationLoaded: boolean;
  isFindingDigitalContentOpen: boolean;
  status: AsyncStatus;
  is_patient_start_chat_available: boolean;
}

const PlatformPharmacyId = Platform.select({ web: '', default: PHARMACY_ID });
const PlatformPharmacySlug = Platform.select({
  web: '',
  default: PHARMACY_SLUG,
});

export const useAppStateStore = create<AppState>((set) => ({
  pharmacyId: PlatformPharmacyId,
  slug: PlatformPharmacySlug,
  pharmacyName: '',
  pharmacy: {} as PharmacyDto,
  stores: [],
  locationPrescriptionConfig: {
    refill_configs: [],
  } as unknown as LocationConfigDto,
  locationPickupMethods: [],
  locationStoreHours: [],
  locationPharmacyHours: [],
  locationServices: [],
  notifications: [],
  notificationsBadgeCount: 0,
  appNavigationLoaded: false,
  authNavigationLoaded: false,
  isFindingDigitalContentOpen: false,
  status: 'idle',
  is_patient_start_chat_available: true,
}));
