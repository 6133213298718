import { FunctionComponent, PropsWithChildren, useState } from 'react';
import theme, { makeStyles } from 'assets/theme';
import { Form, InternalScreenContainer } from 'assets/layout';
import { Text } from 'assets/components/text';
import { Pressable, View } from 'react-native';
import { useForm } from 'react-hook-form';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import { TransferMedsTestIDs } from './TransferMedsTestIDs';
import { Divider } from 'react-native-paper';
import { useTransferMedicationsStore } from './transfer-meds-store';
import { StackHeaderProps } from '@react-navigation/stack';
import { TransferPrescriptionRxDto } from '@digitalpharmacist/prescription-service-client-axios';

export const EnterMedications: FunctionComponent<
  PropsWithChildren<TransferMedicationsNavigationProp>
> = ({ navigation }) => {
  const styles = useStyles();
  const [inputFields, setInputFields] = useState<TransferPrescriptionRxDto[]>([
    { rx_number: undefined, drug_name: '' },
    { rx_number: undefined, drug_name: '' },
    { rx_number: undefined, drug_name: '' },
  ]);
  const { updateTransferMedsData } = useTransferMedicationsStore();
  const [error, setError] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      drugName: '',
    },
  });
  const handleInputChange = (
    text: string,
    index: number,
    field: keyof TransferPrescriptionRxDto,
  ) => {
    setInputFields((prevInputFields) => {
      const newInputFields = [...prevInputFields];
      newInputFields[index] = {
        ...newInputFields[index],
        [field]: text,
      };
      return newInputFields.slice(); // Ensure a new reference to trigger a re-render
    });
  };

  const handleAddField = () => {
    setInputFields([...inputFields, { rx_number: undefined, drug_name: '' }]);
  };

  const handleOnPress = () => {
    if (!updateTransferMedsData) return;

    const filteredInputFields = inputFields.map((item) => ({
      rx_number: undefined,
      drug_name:
        item.drug_name !== 'initial value' && item.drug_name.trim() !== ''
          ? item.drug_name
          : 'initial value',
    }));

    const allFieldsAreInitialValue = filteredInputFields.every(
      (item) =>
        item.rx_number === undefined && item.drug_name === 'initial value',
    );

    const newValuesArray: TransferPrescriptionRxDto[] = filteredInputFields
      .filter((item) => item.drug_name !== 'initial value')
      .map((item) => ({
        rx_number: undefined,
        drug_name:
          item.drug_name !== 'initial value' && item.drug_name.trim() !== ''
            ? item.drug_name
            : 'initial value',
      }));

    const error = allFieldsAreInitialValue || newValuesArray.length === 0;

    if (!error) {
      updateTransferMedsData({
        medications: newValuesArray,
      });
      navigation.navigate('transfer-medications', {
        screen: 'review-transfer',
      });
    } else {
      setError(true);
      return;
    }
  };

  return (
    <InternalScreenContainer
      title={getText('transfer-meds-header-title')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: handleOnPress,
          testID: TransferMedsTestIDs.nextButton,
          logger: { id: 'refill-medication-selection-next' },
          text: getText('next'),
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Alert
          visible={!!methods.getFieldState('drugName').error}
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
        />
        <View>
          <Text style={styles.title}>{getText('enter-your-medications')}</Text>
        </View>
        <View>
          <Divider />
        </View>
        {error && (
          <Text style={styles.errorText}>
            {getText('at-least-one-drug-name-is-required')}
          </Text>
        )}
        <View style={{ marginVertical: theme.getSpacing(1) }}>
          {inputFields.map((value: any, index: number) => (
            <TextField
              key={index}
              placeholder={getText('drug-name')}
              onChange={(text) => handleInputChange(text, index, 'drug_name')}
              name={`input${index}`}
              value={value}
              style={{ marginVertical: theme.getSpacing(1) }}
            />
          ))}
        </View>
        <Pressable onPress={handleAddField}>
          <View>
            <Text style={styles.enterMore}>{getText('enter-more')}</Text>
          </View>
        </Pressable>
      </Form>
    </InternalScreenContainer>
  );
};

export type TransferMedicationsNavigationProp = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    marginVertical: theme.getSpacing(1),
  },
  enterMore: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[600],
  },
  errorText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.error[600],
  },
}));
