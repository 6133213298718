import { EmbeddingControlHeader, InternalLinkData } from './types';

export const MESSAGE_LIMIT = 5000;

export const MESSAGES_TAKE = 30;

export const SCHEME_URL_PATTERN = /.+:\/\//i;

export const embeddingControlHeaders: EmbeddingControlHeader[] = [
  {
    name: 'content-security-policy',
    directiveDisallowingEmbedding: "frame-ancestors 'self'",
  },
  {
    name: 'content-security-policy',
    directiveDisallowingEmbedding: "frame-ancestors  'self'",
  },
  {
    name: 'x-frame-options',
    directiveDisallowingEmbedding: 'sameorigin',
  },
  {
    name: 'x-frame-options',
    directiveDisallowingEmbedding: 'deny',
  },
];

export const URL_PATTERN_WITH_PROTOCOL =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
export const URL_PATTERN_WITHOUT_PROTOCOL =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
export const URL_VIDEO_PATTERN = /https?:\/\/.*\/app\/home\?.*videoId=/i;

export const LINK_TO_STORE_HOURS = /HERE/;

export const intitialInternalLinkData: InternalLinkData = {
  internalId: '',
  isOpened: false,
};

export const SYSTEM_GENERATED_ID = 'SYSTEM_GENERATED_ID';
