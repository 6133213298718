import React, { FunctionComponent } from 'react';
import { BottomTabs } from 'assets/components/bottom-tabs';
import { Dashboard } from '../screens/dashboard/Dashboard';
import { Icon } from 'assets/components/icon';
import {
  HomeIcon,
  MedicationIcon,
  MessageSquareIcon,
  CalendarIcon,
  AccountCircleIcon,
} from 'assets/icons';
import { Messages } from '../screens/messages/Messages';
import { AccountNavigation } from '../screens/account/AccountNavigation';
import { getText } from 'assets/localization/localization';
import { FillInForm } from '../screens/fill-in-form/FillInForm';
import { MedicationsNavigation } from '../screens/medications/MedicationsNavigation';
import { AppointmentsNavigation } from '../screens/appointments/AppointmentsNavigation';
import { AppointmentDetails } from '../screens/appointment/AppointmentDetails';
import { OrderDetails } from '../screens/medications/medication-orders/OrderDetails';
import { useMessagesState } from '../screens/messages/messages-store';
import { AppNavigationTestIDs } from './AppNavigationTestIDs';

export const BottomTabsNavigation: FunctionComponent = () => {
  const count = useMessagesState((state) => state.count);

  return (
    <BottomTabs
      screens={[
        {
          name: 'home',
          label: getText('home'),
          component: Dashboard,
          icon: ({ color, size }) => (
            <Icon icon={HomeIcon} color={color} size={size} />
          ),
          testID: AppNavigationTestIDs.dashboard,
        },
        {
          name: 'medications',
          label: getText('medications'),
          component: MedicationsNavigation,
          icon: ({ color, size }) => (
            <Icon icon={MedicationIcon} color={color} size={size} />
          ),
          testID: AppNavigationTestIDs.medications,
        },
        {
          name: 'messages',
          label: getText('messages'),
          component: Messages,
          icon: ({ color, size }) => (
            <Icon icon={MessageSquareIcon} color={color} size={size} />
          ),
          testID: AppNavigationTestIDs.messages,
          options: {
            tabBarBadge: count.unread,
          },
        },
        {
          name: 'appointments',
          label: getText('appointments'),
          component: AppointmentsNavigation,
          icon: ({ color, size }) => (
            <Icon icon={CalendarIcon} color={color} size={size} />
          ),
          testID: AppNavigationTestIDs.appointments,
        },
        {
          name: 'account',
          label: getText('account'),
          component: AccountNavigation,
          icon: ({ color, size }) => (
            <Icon icon={AccountCircleIcon} color={color} size={size} />
          ),
          testID: AppNavigationTestIDs.account,
        },
        // The forms screen should be a part of the `app` parent route
        // but we don't want to show a direct link in the bottom nav
        {
          name: 'forms',
          label: '',
          component: FillInForm,
          testID: AppNavigationTestIDs.forms,
          options: {
            tabBarItemStyle: {
              display: 'none',
            },
          },
        },
        {
          name: 'appointment',
          label: '',
          component: AppointmentDetails,
          testID: AppNavigationTestIDs.appointment,
          options: {
            tabBarItemStyle: {
              display: 'none',
            },
          },
        },
        {
          name: 'order-details-bottom-tab',
          label: '',
          component: OrderDetails,
          testID: AppNavigationTestIDs['order-details'],
          options: {
            tabBarItemStyle: {
              display: 'none',
            },
          },
        },
      ]}
    />
  );
};
