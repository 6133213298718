import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Linking, Platform, View, Image, Dimensions } from 'react-native';
import { Text } from 'assets/components/text';
import { BubbleProps, IMessage } from 'react-native-gifted-chat';
import ParsedText from 'react-native-parsed-text';
import {
  timeAugmenter,
  timeOppositeAugmenter,
} from '../../common/datetime-utils';
import { useTheme } from 'react-native-paper';
import { makeStyles } from 'assets/theme';
import {
  AttachmentDto,
  AuthorType,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native-gesture-handler';
import { getText, translations } from 'assets/localization/localization';
import {
  IImageModalState,
  IMessageExtended,
  Image as IImage,
  MessageStatus,
} from 'assets/types/messageTypes';
import FileStorageService from '../../api/file-storage-service';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useUserState } from '../../store/user-store';
import { DocumentView } from '../../components/document-view';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import {
  LINK_TO_STORE_HOURS,
  SCHEME_URL_PATTERN,
  SYSTEM_GENERATED_ID,
  URL_PATTERN_WITHOUT_PROTOCOL,
  URL_PATTERN_WITH_PROTOCOL,
} from './constants';
import {
  getAttachmentUrl,
  renderHyperlink,
  renderHyperlinkRegex,
} from 'assets/utils/messageUtils';
import { TIMESTAMP_THRESHOLD } from 'assets/utils/messageConstants';
import { getFullNameInitials } from 'assets/utils/messageUtils';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import BaseImageViewer from '../../components/BaseImageViewer';
import { DocumentPickerAsset } from 'expo-document-picker';
import { ImagePickerAsset } from 'expo-image-picker';

type IBubbleProps = {
  args: Readonly<BubbleProps<IMessageExtended>>;
  messages?: IMessageExtended[];
  conversation?: DirectMessagePatientDto;
  status: MessageStatus;
  handleMessageSend: (message?: IMessage) => void;
  onLinkClick: (link: string) => void;
  setShowStoreInfo: (show: boolean) => void;
  forceUpdate: number;
};

const Bubble: FC<IBubbleProps> = ({
  messages,
  args,
  conversation,
  status,
  handleMessageSend,
  onLinkClick,
  setShowStoreInfo,
  forceUpdate,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const user = useUserState((state) => state.user);
  const pharmacyId = user?.pharmacyId;
  const locationId = user?.preferredPharmacyLocationId;
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [imagesUrls, setImagesUrls] = useState<Record<string, string>>({});
  const pdfSheetRef = React.useRef<BaseModalHandler>(null);
  const [imageModal, setImageModal] = useState(false);
  const [imageModalState, setImageModalState] = useState<IImageModalState>({
    show: false,
    uri: '',
    stored_filename: '',
  });

  const images: (IImage | ImagePickerAsset | DocumentPickerAsset)[] =
    useMemo(() => {
      return args.currentMessage?.attachments?.images ?? [];
    }, [args.currentMessage?.attachments?.images]);

  const files: (AttachmentDto | DocumentPickerAsset)[] = useMemo(() => {
    return args.currentMessage?.attachments?.files ?? [];
  }, [args.currentMessage?.attachments?.files]);

  const refreshImagesUrls = useCallback(async () => {
    if (images && images.length && pharmacyId && locationId) {
      const readyImagesWithUrl = await Promise.all(
        images
          .filter((image: IImage | ImagePickerAsset | DocumentPickerAsset) =>
            Boolean((image as IImage).id),
          )
          .map((image: IImage | ImagePickerAsset | DocumentPickerAsset) => {
            return getAttachmentUrl({
              attachment: image as IImage,
              FileStorageService: FileStorageService,
              pharmacyId: pharmacyId,
              locationId: locationId,
            });
          }),
      );

      const newImagesUrls = readyImagesWithUrl.reduce(
        (accumulator: Record<string, string>, currentAttachment: IImage) => {
          return {
            ...accumulator,
            [currentAttachment.id]: currentAttachment.url,
          };
        },
        {},
      );

      setImagesUrls(newImagesUrls);
    }
  }, [pharmacyId, locationId, images, setImagesUrls, getAttachmentUrl]);

  useEffect(() => {
    refreshImagesUrls();
  }, [forceUpdate, refreshImagesUrls]);

  const handleBottomSheetDismiss = () => {
    pdfSheetRef.current?.hide();
  };

  const openDocument = async (attachment: AttachmentDto) => {
    if (!locationId || !pharmacyId) return;

    const urlResponse = await FileStorageService.readUrl(
      LocationCategory.DirectMessage,
      locationId,
      attachment.stored_filename,
      pharmacyId,
    );

    if (attachment.name.endsWith('pdf')) {
      pdfSheetRef.current?.show();

      setPdfUrl(urlResponse.data.url);
    } else {
      pdfSheetRef.current?.hide();

      if (Platform.OS === 'web') {
        const url: string = urlResponse.data.url;
        const supported = await Linking.canOpenURL(url);

        if (supported) {
          await Linking.openURL(url);
        } else {
          console.error(`No possibility to open url ${url}`);
        }
      } else {
        const url: string = urlResponse.data.url;
        const { uri } = await FileSystem.downloadAsync(
          url,
          `${FileSystem.documentDirectory}${attachment.stored_filename}`,
        );

        const UTI = 'public.item';
        await Sharing.shareAsync(uri, { UTI });
      }
    }
  };

  const StatusLine = () => {
    if (!conversation) {
      return null;
    }

    if (!args.currentMessage) {
      return null;
    }

    const isFailed = !args.currentMessage.sent;
    const isNotPending = !args.currentMessage.pending;
    const lastRightMessageId = useMemo(() => {
      const messagesLocal = messages ?? [];

      return messagesLocal.find(
        (message) => message.user._id === args.user?._id,
      )?._id;
    }, [messages]);

    const retryIsClicked = useCallback(() => {
      if (isFailed && args.currentMessage) {
        handleMessageSend(args.currentMessage);
      }
    }, [handleMessageSend, isFailed]);

    const Error = () => (
      <View style={styles.error}>
        <Text style={styles.errorText}>{getText('failed-to-send')}, </Text>
        <TouchableWithoutFeedback onPress={retryIsClicked}>
          <Text style={styles.retry}>{getText('retry')}</Text>
        </TouchableWithoutFeedback>
      </View>
    );

    const TextStatus = ({ status }: { status: translations }) => (
      <Text style={styles.subText}>{getText(status)}</Text>
    );

    if (
      args.currentMessage._id === lastRightMessageId &&
      status === MessageStatus.Sending
    ) {
      return <TextStatus status={MessageStatus.Sending} />;
    }

    if (isFailed && isNotPending) {
      return <Error />;
    }

    if (args.currentMessage._id === lastRightMessageId) {
      if (status === MessageStatus.Error) {
        return <Error />;
      }

      return <TextStatus status={MessageStatus.Delivered} />;
    }

    return null;
  };

  const NameComponent = (props: {
    args: Readonly<BubbleProps<IMessageExtended>>;
  }) => {
    const prevMessage: IMessageExtended | undefined =
      props.args.previousMessage;
    const currentMessage: IMessageExtended | undefined =
      props.args.currentMessage;

    if (!currentMessage) {
      return null;
    }

    const isAttachmentPresentInPrevMessage =
      prevMessage &&
      prevMessage.attachments &&
      (prevMessage.attachments.files.length ||
        prevMessage.attachments.images.length)
        ? true
        : false;
    const noContentInPrevMessage = isAttachmentPresentInPrevMessage
      ? false
      : !prevMessage?.text;
    // if no prevMessage than value will be always less than the current message time to set isCurrentMessageTimeMoreThanThreshold === true
    const prevMessageTimePlusThreshold = prevMessage
      ? timeAugmenter(prevMessage.createdAt, 'm', TIMESTAMP_THRESHOLD)
      : timeOppositeAugmenter(currentMessage.createdAt, 'm', 1);
    // calculate if the current message was sent more than TIMESTAMP_THRESHOLD minutes ago from the previous message
    const isCurrentMessageTimeMoreThanThreshold =
      currentMessage.createdAt > prevMessageTimePlusThreshold;
    const isSameAuthorFromPrevMessage =
      prevMessage?.user?._id === currentMessage.user?._id &&
      currentMessage.user?._id !== undefined;
    const isCurrentMessageAndAuthorExists =
      currentMessage && currentMessage.user.name;
    const shouldNameBePresent =
      noContentInPrevMessage ||
      isCurrentMessageTimeMoreThanThreshold ||
      !isSameAuthorFromPrevMessage;

    if (isCurrentMessageAndAuthorExists && shouldNameBePresent) {
      const author = currentMessage.user;
      const name =
        author._id === SYSTEM_GENERATED_ID
          ? author.name
          : getFullNameInitials({ fullName: author.name });

      return <Text style={styles.subText}>{name}</Text>;
    } else {
      return null;
    }
  };

  const onOpenLink = (link: string) => {
    const linkToOpen = SCHEME_URL_PATTERN.test(link) ? link : `https://${link}`;
    onLinkClick(linkToOpen);
  };

  const onOpenHyperLink = (link: string) => {
    const url = link.split('href=')[1].split(' ')[0];
    onLinkClick(url);
  };

  const isLeft = args.position === 'left';
  const isOwner = args.user?._id === args.currentMessage?.user._id;
  const isSystemGenerated =
    args.currentMessage?.author_type === AuthorType.SystemGenerated;
  const attachmentsPresent = Boolean(images.length || files.length);

  const { width, height } = Dimensions.get('window');

  function showFile(attachment: AttachmentDto | DocumentPickerAsset) {
    // TODO: bad but fast way to solve. This kind of files is only for temporary shown of files that are sending at the moment
    // if attachment has `file` property, then it has type DocumentPickerAsset
    if ((attachment as DocumentPickerAsset).file) {
      return (
        <View key={attachment.name.replaceAll(/\s+/g, '-')}>
          <Text style={styles.link}>{attachment.name}</Text>
        </View>
      );
    }

    // if attachment does not have `file` property, then it has type AttachmentDto
    const attachmentFile = attachment as AttachmentDto;
    return (
      <View key={attachmentFile.id}>
        <TouchableOpacity onPress={() => openDocument(attachmentFile)}>
          <Text style={styles.link}>{attachmentFile.name}</Text>
        </TouchableOpacity>
      </View>
    );
  }

  function showImage(
    attachment: IImage | ImagePickerAsset | DocumentPickerAsset,
  ) {
    // TODO: bad but fast way to solve. This kind of files is only for temporary shown of files that are sending at the moment
    // if attachment does not have `id` property, then it has type ImagePickerAsset (DocumentPickerAsset comes from pharmacy side)
    if (!(attachment as IImage).id) {
      return (
        <View style={styles.imageWrapper} key={new Date().getTime()}>
          <Image
            source={{ uri: (attachment as ImagePickerAsset).uri }}
            style={styles.image}
          />
        </View>
      );
    }

    // if attachment has `id` property, then it has type IImage
    const attachmentImage = attachment as IImage;
    return (
      <View style={styles.imageWrapper} key={attachmentImage.id}>
        <TouchableOpacity
          onPress={async () => {
            const url = await getAttachmentUrl({
              attachment: attachmentImage,
              FileStorageService: FileStorageService,
              pharmacyId: pharmacyId!,
              locationId: locationId!,
            });
            if (url) {
              setImageModal(true);
              setImageModalState({
                show: true,
                stored_filename: attachmentImage.stored_filename,
                uri: url.url,
              });
            }
          }}
        >
          <Image
            source={
              imagesUrls[attachmentImage.id]
                ? { uri: imagesUrls[attachmentImage.id] }
                : (attachmentImage.noImageUrl as any)
            }
            style={styles.image}
          />
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View
      style={[
        { flex: 1, alignItems: 'flex-end' },
        isLeft && { alignItems: 'flex-start' },
      ]}
    >
      <NameComponent args={args} />
      <View
        style={[
          styles.bubbleContainer,
          isLeft && { marginLeft: 0, marginRight: 60 },
          isOwner && { backgroundColor: theme.palette.primary[200] },
        ]}
      >
        {args.currentMessage?.text ? (
          <ParsedText
            style={styles.bubbleText}
            maxFontSizeMultiplier={1.5}
            minimumFontScale={0.8}
            parse={[
              {
                pattern: renderHyperlinkRegex,
                style: { textDecorationLine: 'underline' },
                onPress: onOpenHyperLink,
                renderText: renderHyperlink,
              },
              {
                pattern: URL_PATTERN_WITH_PROTOCOL,
                style: { textDecorationLine: 'underline' },
                onPress: onOpenLink,
              },
              {
                pattern: URL_PATTERN_WITHOUT_PROTOCOL,
                style: { textDecorationLine: 'underline' },
                onPress: onOpenLink,
              },
              {
                pattern: LINK_TO_STORE_HOURS,
                style: isSystemGenerated
                  ? { textDecorationLine: 'underline' }
                  : {},
                onPress: () => {
                  if (isSystemGenerated) {
                    setShowStoreInfo(true);
                  }
                },
              },
            ]}
          >
            {args.currentMessage.text}
          </ParsedText>
        ) : null}
        {attachmentsPresent && args.currentMessage?.attachments && (
          <View style={styles.attachmentBubble}>
            {files.length > 0 && (
              <View style={styles.filesBubble}>
                {files.map((attachment: AttachmentDto | DocumentPickerAsset) =>
                  showFile(attachment),
                )}
              </View>
            )}
            {images.length > 0 && (
              <View style={styles.imagesContainer}>
                {images.map(
                  (
                    attachment: IImage | ImagePickerAsset | DocumentPickerAsset,
                  ) => showImage(attachment),
                )}
              </View>
            )}
          </View>
        )}
      </View>
      <StatusLine />
      <GenericModal
        webModalSize="lg"
        title=""
        ref={pdfSheetRef}
        buttons={[
          {
            text: getText('close'),
            onPress: handleBottomSheetDismiss,
            hierarchy: 'primary',
            logger: { id: 'close-document-modal' },
          },
        ]}
      >
        <View style={styles.attachmentContainer}>
          <View
            style={[
              {
                height: height * 0.8,
                width: width * 0.9,
              },
              styles.attachmentWrapper,
            ]}
          >
            <DocumentView src={pdfUrl} streaming={true} style={{ flex: 1 }} />
          </View>
        </View>
      </GenericModal>
      <BaseImageViewer
        url={imageModalState.uri}
        visible={imageModal}
        onClose={() => setImageModal(false)}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 60,
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[700],
    padding: 10,
    borderRadius: 8,
    maxWidth: '90%',
  },
  bubbleText: {
    ...theme.fonts.regular,
    fontSize: 14,
    ...(Platform.OS === 'web' && {
      overflowWrap: 'break-word',
    }),
  },
  subText: {
    color: theme.palette.gray[500],
    fontSize: 11,
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
  },
  error: {
    flexDirection: 'row',
  },
  errorText: {
    color: theme.palette.error[500],
    fontSize: 11,
  },
  retry: {
    fontSize: 11,
    color: theme.palette.primary[500],
  },
  attachmentBubble: {
    flexDirection: 'column',
  },
  imagesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    margin: theme.getSpacing(1),
  },
  image: {
    height: 100,
    width: 100,
    zIndex: 1,
  },
  filesBubble: {
    flexDirection: 'column',
  },
  closeButtonContainer: {
    marginLeft: 'auto',
    width: 70,
  },
  closeModalButton: {
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.primary[800],
    borderRadius: 5,
    margin: theme.getSpacing(1),
  },
  link: {
    textDecorationLine: 'underline',
  },
  pdfWindowDismissBtn: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  attachmentContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  attachmentWrapper: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

export default Bubble;
