import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const AppHeartIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, colorSecondary, size, testID }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={color || theme.palette.gray[700]}
        fillRule="evenodd"
        d="M54.465 65.629V57.85c-.089-.008.09-1.319 0-1.326-1.807-.443-3.614-1.77-4.247-3.096V59.3c0 1.708-1.416 3.112-3.139 3.112H19.385c-1.723 0-3.14-1.404-3.14-3.112V14.484c0-1.71 1.43-3.114 3.14-3.114H47.08c1.71 0 3.14 1.404 3.14 3.114v3.57h4.246c0-1.327-.154-5.834 0-5.966v-.047c0-3.505-2.895-6.368-6.422-6.368h-29.62C14.894 5.673 12 8.536 12 12.04V65.63C12 69.134 14.887 72 18.422 72h29.621c3.535 0 6.422-2.863 6.422-6.371ZM29.871 9.564c-.7 0-.7-1.041 0-1.041h6.723c.7 0 .7 1.041 0 1.041h-6.723Zm0 59.065c-2.199 0-2.199-3.383 0-3.383h6.723c2.199 0 2.199 3.383 0 3.383h-6.723Z"
        clipRule="evenodd"
      />
      <Path
        fill={colorSecondary || theme.colors.brandedText}
        fillRule="evenodd"
        d="M47.148 45.982c-1.329.898-2.604 1.642-3.723 2.182-4.24-2.04-10.714-7.037-13.926-12.202l5.654-.006a.97.97 0 0 0 .615-.216l.002.002 3.156-2.564 3.47 7.233a.974.974 0 0 0 1.273.455.943.943 0 0 0 .506-.547l3.673-10.403 3.663 6.464c-.364.277-.712.577-1.044.897-2.153 2.088-3.353 4.897-3.353 7.85 0 .287.011.572.034.855Zm11.813-2.9c.995.181 1.748 1.028 1.748 2.046 0 1.148-.961 2.08-2.147 2.08-1.048 0-1.923-.73-2.109-1.693-.237-1.22-.774-2.205-1.677-3.081l-.766-.743c-.522-.507.486-1.484 1.008-.977l.766.742c.905.877 1.92 1.397 3.177 1.625ZM43.425 23.854c5.775-5.6 15.88-1.72 15.88 6.378 0 1.224-.333 2.504-.911 3.794-1.864.026-3.668.489-5.273 1.331l-4.636-8.185h-.001a.95.95 0 0 0-.532-.432.965.965 0 0 0-1.218.583l-3.603 10.208-2.979-6.212a.917.917 0 0 0-.147-.231.98.98 0 0 0-1.353-.117l-3.85 3.128-6.308.007c-.6-1.317-.949-2.624-.949-3.874 0-8.098 10.106-11.978 15.88-6.378Z"
        clipRule="evenodd"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        d="M65.96 52.382a10.324 10.324 0 0 1-7.324 3.036c-2.857 0-5.447-1.16-7.323-3.036a10.326 10.326 0 0 1-3.04-7.327 10.33 10.33 0 0 1 3.04-7.328 10.326 10.326 0 0 1 7.323-3.036c2.86 0 5.449 1.16 7.324 3.036A10.33 10.33 0 0 1 69 45.055c0 2.86-1.161 5.45-3.04 7.327Zm-14.787-7.997h2.238v1.34h-2.238v-1.34Zm8.135-6.816v2.246H57.97V37.57h1.337ZM57.97 52.54v-2.245h1.337v2.245H57.97Zm8.13-6.814h-2.239v-1.341H66.1v1.34Zm-7.465 7.68a8.345 8.345 0 0 0 8.357-8.35 8.322 8.322 0 0 0-2.442-5.906 8.34 8.34 0 0 0-5.915-2.447c-2.31 0-4.401.935-5.912 2.447a8.318 8.318 0 0 0-2.445 5.905c0 2.309.934 4.396 2.445 5.906a8.337 8.337 0 0 0 5.912 2.446Z"
      />
    </Svg>
  );
};
