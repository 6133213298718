import React from 'react';
import { View, Platform, TouchableOpacity } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import {
  PreferredHomeLocationInformationProps,
  StoreSelectorItemHandler,
} from './types';
import { Linking } from 'react-native';
import { Map } from 'assets/components/map';
import { Button } from 'assets/components/button';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import moment from 'moment';
import { InfoIcon } from 'assets/icons';
import { currentDayOpenHours, getDayAsStr } from './utils';
import { StoreSelectorInformationBottomSheet } from './StoreSelectorInformationBottomSheet';
import { Distance } from '../../types';
import { updateLocationDetails } from '../../screens/register/register-actions';
import PhoneNumber from '../phone-number/PhoneNumber';
import { StoreSelectorInformationModal } from './StoreSelectorInformationModal';
import { useAppStateStore } from '../../store/app-store';

export const PreferredStoreInformation: React.FunctionComponent<
  PreferredHomeLocationInformationProps
> = ({
  item,
  onChangePress,
  openingHours,
  showChangeButton,
  onSendMessagePress,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const isWeb = Platform.OS === 'web';
  const storeSelectorInformationRef =
    React.useRef<StoreSelectorItemHandler>(null);
  const handleOnLocationChanged = (value: PharmacyLocationDto) => {
    onChangePress(value);
  };

  const { is_patient_start_chat_available } = useAppStateStore();

  const handleOnPhoneNumberPress = () => {
    Linking.openURL(`tel:${item.phone}`);
  };

  const getOpeningHours = (hour: number, minute: number) => {
    return moment({ hour, minute }).local().format('h:mma').replace(':00', '');
  };

  const openingHoursGenerator = () => {
    return `${getOpeningHours(
      openingHours?.start_hour!,
      openingHours?.start_minute!,
    )} - ${getOpeningHours(
      openingHours?.end_hour!,
      openingHours?.end_minute!,
    )}`;
  };

  const handleInfoButtonOnPress = async (
    item: Distance<PharmacyLocationDto>,
  ) => {
    storeSelectorInformationRef.current?.show(item as PharmacyLocationDto);
    if (item.id) await updateLocationDetails(item.id);
  };

  return (
    <View>
      <View
        style={{
          flexDirection: 'column',
          marginBottom: theme.getSpacing(2),
        }}
      >
        <View>
          <View style={styles.inlinePosition}>
            <Text style={styles.selectedAddressTitle}>
              {getText('your-pharmacy')}
            </Text>
            {showChangeButton && (
              <TouchableOpacity onPress={() => handleOnLocationChanged(item)}>
                <Text style={styles.pressableText}>
                  {getText('store-selector-change')}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={[styles.inlinePosition, styles.topSpacing]}>
            {item && (
              <View style={{ flex: 3 }}>
                <Text
                  numberOfLines={2}
                  style={[styles.selectedAddressSubTitle, { width: '70%' }]}
                >
                  {item.name}
                  {'  '}
                  <TouchableOpacity
                    onPress={() => handleInfoButtonOnPress(item)}
                  >
                    <InfoIcon size={16} color={theme.palette.gray[500]} />
                  </TouchableOpacity>
                </Text>
                {item.address && (
                  <View>
                    <Text style={styles.selectedAddressText}>
                      {item.address.address1}
                    </Text>
                    {item.address.address2 && (
                      <Text style={styles.selectedAddressText}>
                        {item.address.address2}
                      </Text>
                    )}
                    <Text
                      style={styles.selectedAddressText}
                    >{`${item.address.city}, ${item.address.state} ${item.address.postal_code}`}</Text>
                  </View>
                )}
                {openingHours ? (
                  <Text style={styles.hoursText}>
                    {getText('open-hours', {
                      hours: openingHoursGenerator(),
                    })}
                  </Text>
                ) : (
                  <Text style={{ color: theme.colors.error }}>
                    {getText('closed')}
                  </Text>
                )}
                {item.phone && (
                  <PhoneNumber
                    mode="link"
                    showIcon={false}
                    phoneNumber={item.phone}
                  />
                )}
              </View>
            )}
            <View style={styles.mapWrapper}>
              <Map hasBorder address={item.address} height={90} width={90} />
            </View>
          </View>
        </View>
        <View style={Platform.OS === 'web' ? styles.webButtonView : undefined}>
          {is_patient_start_chat_available ? (
            <Button
              style={{
                marginTop: theme.getSpacing(3),
              }}
              hierarchy="primary"
              size="large"
              logger={{ id: 'send_message' }}
              onPress={onSendMessagePress}
            >
              {getText('send-message')}
            </Button>
          ) : null}
        </View>
      </View>

      <StoreSelectorInformationModal
        ref={storeSelectorInformationRef}
        currentDayOpenHours={currentDayOpenHours}
        daysAsStr={getDayAsStr}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  selectedAddressTitle: {
    ...theme.fonts.medium,
    fontWeight: '700',
    fontSize: 20,
    lineHeight: 22,
    color: theme.palette.gray[900],
  },
  selectedAddressSubTitle: {
    ...theme.fonts.regular,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  selectedAddressText: {
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  topSpacing: { marginTop: theme.getSpacing(2) },
  inlinePosition: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  pressableText: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[600],
  },
  hoursText: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[500],
  },
  phoneContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  webButtonView: { width: 240, alignSelf: 'center' },
  mapWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
