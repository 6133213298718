import React, { FunctionComponent } from 'react';
import { NavigationTabBar } from '../../components/navigation-tab-bar/NavigationTabBar';
import { Medications } from './Medications';
import { LandingHeader } from '../../components/landing-header';
import { useAppStateStore } from '../../store/app-store';
import { getText } from 'assets/localization/localization';
import { StackNavigationProp } from '@react-navigation/stack';
import { Platform } from 'react-native';
// import { Reminders } from './medications-reminder/Reminders';
import { Orders } from './medication-orders/Orders';
import { MedicationTransferIcon, ShoppingBagIcon } from 'assets/icons';

export const MedicationsNavigation: FunctionComponent = () => {
  const { pharmacyName } = useAppStateStore();
  return (
    <>
      {Platform.OS !== 'web' && (
        <LandingHeader pharmacyName={pharmacyName} greetings="Medications" />
      )}
      <NavigationTabBar
        screens={[
          {
            name: 'medications-tab',
            component: Medications,
            testID: 'meds-tab-test-id',
            title: getText('meds'),
            icon: Platform.OS === 'web' ? MedicationTransferIcon : undefined,
          },
          {
            title: getText('orders'),
            component: Orders,
            testID: 'orders-tab-test-id',
            name: 'orders',
            icon: Platform.OS === 'web' ? ShoppingBagIcon : undefined,
          },
          // {
          //   // TODO: This is left a comment because it will be added in a later moment [J.K]
          //   title: getText('reminders'),
          //   component: Reminders,
          //   testID: 'reminders-tab-test-id',
          //   name: 'reminders',
          // },
        ]}
      />
    </>
  );
};
export type MedicationStackNavigationProp =
  StackNavigationProp<MedicationsNavigationStackParamList>;

export type MedicationsNavigationStackParamList = {
  medications: undefined;
  orders: undefined;
  reminders: undefined;
};
