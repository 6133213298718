import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import { DocumentPickerResult, getDocumentAsync } from 'expo-document-picker';
import { EmailIcon, FilePlusIcon, ImageIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { useUserState } from '../../store/user-store';
import UnifiedCommsService from '../../api/unified-comms-service';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  setNewMessageDocumentAttachments,
  setNewMessageImagesAttachments,
} from './messages-actions';
import { useMessagesState } from './messages-store';
import { Alert } from 'assets/components/alert';
import * as ImagePicker from 'expo-image-picker';
import { imagePickerUtils } from '../../utils';

const MAX_ATTACHMENTS = 1;
const FILE_SIZE_LIMIT = 5000000;
const ACCEPTED_MIME_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/avif',
  'image/webp',
  'image/heic',
  'image/gif',
];

export const ChatBoxActions: FunctionComponent<ChatBoxActionProps> = ({
  conversation,
  onClose,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const user = useUserState((state) => state.user);
  const newMessageAttachmentsImages = useMessagesState(
    (state) => state.newMessageAttachmentsImages,
  );
  const newMessageAttachmentsDocuments = useMessagesState(
    (state) => state.newMessageAttachmentsDocuments,
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const locationId = user?.preferredPharmacyLocationId;

  const chooseFile = async () => {
    const docsResult: DocumentPickerResult = await getDocumentAsync({
      multiple: false,
      copyToCacheDirectory: false,
    });

    if (docsResult.canceled) {
      return;
    }

    const fileSize = docsResult.assets.reduce(
      (acc, item) => acc + (item.size ?? 0),
      0,
    );

    if (!docsResult.assets || fileSize > FILE_SIZE_LIMIT) {
      setErrorMessage(getText('file-too-large'));
      return;
    }

    if (
      !docsResult.assets[0].mimeType ||
      !ACCEPTED_MIME_TYPES.includes(docsResult.assets[0].mimeType)
    ) {
      setErrorMessage(getText('patient-message-invalid-file-type'));
      return;
    }

    const totalAttachments = [
      ...newMessageAttachmentsDocuments,
      ...docsResult.assets,
    ];
    const uniqueNames: string[] = [];
    const uniqueAttachments = totalAttachments.filter((attachment) => {
      const isDuplicate = uniqueNames.includes(attachment.name);

      if (!isDuplicate) {
        uniqueNames.push(attachment.name);
        return true;
      }

      return false;
    });

    const documentsAndImagesAmount =
      newMessageAttachmentsImages.length + uniqueAttachments.length;

    if (documentsAndImagesAmount > MAX_ATTACHMENTS) {
      setErrorMessage(getText('patient-message-file-limit'));
    } else {
      setNewMessageDocumentAttachments(uniqueAttachments);
      onClose(false);
    }
  };

  const onAddImageClick = async () => {
    // No permissions request is necessary for launching the image library
    const result: ImagePicker.ImagePickerResult =
      await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
      });

    if (result.canceled) {
      return;
    }

    let totalSize = 0;
    if (Platform.OS === 'web') {
      totalSize = await imagePickerUtils.getImagesSizeInWeb(result.assets);
    } else {
      totalSize = await imagePickerUtils.getImagesSizeInMobile(result.assets);
    }

    if (totalSize > FILE_SIZE_LIMIT) {
      setErrorMessage(getText('file-too-large'));
      return;
    }

    const totalImages = [...newMessageAttachmentsImages, ...result.assets];

    const uniqueUris: string[] = [];
    const uniqueImagesAttachments = totalImages.filter((image) => {
      const isDuplicate = uniqueUris.includes(image.uri);

      if (!isDuplicate) {
        uniqueUris.push(image.uri);
        return true;
      }

      return false;
    });

    const documentsAndImagesAmount =
      newMessageAttachmentsDocuments.length + uniqueImagesAttachments.length;

    if (documentsAndImagesAmount > MAX_ATTACHMENTS) {
      setErrorMessage(getText('patient-message-file-limit'));
    } else {
      setNewMessageImagesAttachments(uniqueImagesAttachments);
      onClose(false);
    }
  };

  const markAsUnread = async () => {
    if (locationId) {
      await UnifiedCommsService.updateUserViewedStatus(
        locationId,
        conversation.location_patient_id,
        conversation.conversation_id,
        {
          patient_viewed_all_messages: false,
          pharmacy_viewed_all_messages:
            conversation.pharmacy_viewed_all_messages,
        },
      );
    }

    onClose(true);
  };

  return (
    <View style={{ margin: theme.getSpacing(1) }}>
      {errorMessage && <Alert intent="error" title={errorMessage} />}
      <ListMenu>
        <ListItemLink onPress={chooseFile} showIcon={false}>
          <View style={styles.listItemContainer}>
            <FilePlusIcon color={theme.palette.primary[600]} size={20} />
            <Text style={styles.textTitle}>{getText('add-file')}</Text>
          </View>
        </ListItemLink>
        <ListItemLink onPress={onAddImageClick} showIcon={false}>
          <View style={styles.listItemContainer}>
            <ImageIcon color={theme.palette.primary[600]} size={20} />
            <Text style={styles.textTitle}>{getText('add-image')}</Text>
          </View>
        </ListItemLink>
        <ListItemLink
          onPress={markAsUnread}
          showIcon={false}
          showDivider={false}
        >
          <View style={styles.listItemContainer}>
            <EmailIcon color={theme.palette.primary[600]} size={20} />
            <Text style={styles.textTitle}>{getText('mark-unread')}</Text>
          </View>
        </ListItemLink>
      </ListMenu>
    </View>
  );
};

interface ChatBoxActionProps {
  conversation: DirectMessagePatientDto;
  onClose: (closeWindow: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: theme.palette.primary[600],
    fontSize: 16,
    fontWeight: '500',
    marginLeft: theme.getSpacing(0.5),
    marginVertical: theme.getSpacing(1),
  },

  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
