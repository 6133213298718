import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import vucaService from '../../api/vuca-service';
import videoService, { VideoResponseModel } from '../../api/video-service';
import { BrightcoveVideoPlayer } from '../../components/brightcove-video-player';
import { logError } from 'assets/logging/logger';
import { getText } from 'assets/localization/localization';
import { usePatientRecordState } from '../account/patient/patient-store';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { ChevronRightIcon } from 'assets/icons';
import { Icon } from 'assets/components/icon';
import { customValuePrefix } from 'assets/components/typeahead/types';

export const ConditionVideosSection: FunctionComponent<
  PropsWithChildren<ConditionVideosSectionProps>
> = ({}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { patientRecord } = usePatientRecordState();

  const [conditionVideos, setConditionVideos] = useState<VideoResponseModel[]>(
    [],
  );

  if (!patientRecord) {
    throw new Error('Patient record not found');
  }

  const fetchConditionVideos = async () => {
    try {
      const medicalConditions = patientRecord.medical_conditions.filter(
        (x) => !x.startsWith(customValuePrefix),
      );

      const conditionVideosPromises = medicalConditions.map(
        async (condition) => {
          const conditionDetails = await vucaService.conditionFind(condition);
          return conditionDetails.flatMap(
            (condition) =>
              condition.videos?.map((video) => video.video_id) || [],
          );
        },
      );

      const allConditionVideos = await Promise.all(conditionVideosPromises);
      const videoIds = allConditionVideos.flat().filter(Boolean);

      if (videoIds.length > 0) {
        fetchVideoDetails(videoIds);
      }
    } catch (error) {
      logError(new Error(`Error fetching condition videos: ${error}`));
    }
  };

  const fetchVideoDetails = async (videoIds: string[]) => {
    const videoDetailsPromises = videoIds.map(async (videoId: string) => {
      const details = await videoService.getVideoInfo(videoId);
      return details;
    });
    try {
      const videoDetails = await Promise.all(videoDetailsPromises);
      if (videoDetails) {
        const uniqueVideos = videoDetails.filter(
          (video, index, self) =>
            index === self.findIndex((v) => v.id === video.id),
        );
        setConditionVideos(uniqueVideos);
      }
    } catch (error) {
      logError(new Error(`Error fetching video details: ${error}`));
    }
  };

  useEffect(() => {
    if (patientRecord.medical_conditions.length > 0) {
      fetchConditionVideos();
    }
  }, [patientRecord]);

  const conditionVideosModalRef = React.useRef<BaseModalHandler>(null);

  const handleOpenConditionVideosModal = () => {
    conditionVideosModalRef.current?.show();
    setShowConditionsSection(false);
  };

  const handleCloseConditionVideosModal = () => {
    setShowConditionsSection(true);
  };

  // This is only needed because we need to hide the video section when the modal is open
  // so that we don't end up with duplicate iframe ids. This causes UI issues.
  const [showConditionsSection, setShowConditionsSection] = useState(true);

  return conditionVideos.length > 0 ? (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.heading}>{getText('health-videos')}</Text>
          {conditionVideos.length > 1 && (
            <TouchableOpacity
              style={styles.viewAllButton}
              onPress={handleOpenConditionVideosModal}
            >
              <Text style={styles.viewAllButtonText}>
                {getText('view-all')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <View>
          <View style={styles.innerContainer}>
            {showConditionsSection && (
              <View style={styles.videoContainer}>
                <BrightcoveVideoPlayer
                  style={{
                    height: Platform.OS === 'web' ? 300 : 210,
                  }}
                  videoInfo={{
                    videoId: conditionVideos[0].value.video_id,
                    accountId: conditionVideos[0].value.account_id,
                    playerId: conditionVideos[0].value.player_id,
                  }}
                />
                <Text
                  style={styles.videoTitle}
                  numberOfLines={3}
                  ellipsizeMode="tail"
                >
                  {conditionVideos[0].name}
                </Text>
              </View>
            )}
          </View>
          {conditionVideos.length > 1 && (
            <TouchableOpacity
              onPress={handleOpenConditionVideosModal}
              style={styles.moreButton}
            >
              <Text style={styles.moreButtonText}>
                {getText('more-videos')}
              </Text>
              <Icon
                icon={ChevronRightIcon}
                size={20}
                color={theme.palette.primary[600]}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <GenericModal
        ref={conditionVideosModalRef}
        title={getText('health-videos')}
        isScrollable
        onClose={handleCloseConditionVideosModal}
        children={conditionVideos.map((video, index) => (
          <View key={index}>
            <BrightcoveVideoPlayer
              style={{
                height: Platform.OS === 'web' ? 322 : 225,
              }}
              videoInfo={{
                videoId: video.value.video_id,
                accountId: video.value.account_id,
                playerId: video.value.player_id,
              }}
            />
            <Text
              style={styles.videoTitle}
              numberOfLines={3}
              ellipsizeMode="tail"
            >
              {video.name}
            </Text>
          </View>
        ))}
      />
    </>
  ) : null;
};

type ConditionVideosSectionProps = {};

const useStyles = makeStyles((theme) => ({
  container: {
    marginVertical: theme.getSpacing(4),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  heading: {
    ...theme.lumistryFonts.label.xLarge.bold,
    flex: 1,
    alignSelf: 'flex-start',
  },
  viewAllButton: {
    alignSelf: 'flex-end',
    flex: 1,
  },
  viewAllButtonText: {
    textAlign: 'right',
    ...theme.lumistryFonts.label.medium.regular,
    color: theme.palette.primary[600],
  },
  innerContainer: {
    marginTop: theme.getSpacing(3),
    minHeight: 300,
  },
  videoContainer: {
    paddingVertical: theme.getSpacing(2.5),
    paddingHorizontal: theme.getSpacing(2),
    backgroundColor: theme.palette.white,
    borderRadius: theme.roundness,
    shadowColor: theme.palette.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
  },
  videoTitle: {
    ...theme.lumistryFonts.label.small.semiBold,
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(2),
  },
  moreButton: {
    marginTop: theme.getSpacing(3),
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },

  moreButtonText: {
    ...theme.lumistryFonts.label.large.regular,
    color: theme.palette.primary[600],
    paddingRight: theme.getSpacing(0.5),
  },
}));
