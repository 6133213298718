import {
  BrandingDto,
  PharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { PatientUserDto } from '@digitalpharmacist/users-service-client-axios';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { StorageKeys } from '../../enums/storage-keys';
import appConfigService from '../api/app-config-service';
import pharmacyService from '../api/pharmacy-service';
import unifiedCommsService from '../api/unified-comms-service';
import usersService from '../api/users-service';
import {
  refreshCaregiverRequestsState,
  refreshPatientRecordState,
} from '../screens/account/patient/patient-actions';
import { usePatientRecordState } from '../screens/account/patient/patient-store';
import { useLoginState } from '../screens/login/login-store';
import { useAppStateStore } from '../store/app-store';
import { useUserState } from '../store/user-store';
import { isMobileBrowser } from '../utils';
import PharmacyService from '../api/pharmacy-service';
import RefillService from '../screens/refill/refill-service';
import { IntegrationType } from '@digitalpharmacist/prescription-service-client-axios';
import AsyncStorageService from '../api/async-storage-service';
import axios from 'axios';
import { PUBLIC_FILE_STORAGE_SERVICE_BASE_URL } from '../common/constants';
import { ampli } from '../ampli';

export const URL_MALFORMED_ERROR = 'URL_MALFORMED';
export const PHARMACY_NOT_EXIST_ERROR = 'URL_MALFORMED';

export const checkMobileBrowser = async () => {
  const isMobileWeb = await isMobileBrowser();
  useAppStateStore.setState({ isMobileWeb });
};

export const loadUser = async () => {
  try {
    const accessToken = await AsyncStorageService.getItem(
      StorageKeys.AccessToken,
    );
    const userId = await AsyncStorageService.getItem(StorageKeys.UserId);

    if (accessToken && userId) {
      useLoginState.setState({ status: 'success' });
      const user: PatientUserDto = await usersService.getUser(userId);
      useUserState.setState({
        user: { ...user },
      });
    }
  } catch (e) {
    logError(e as Error);
  }
};

export const loadPatientData = async () => {
  const { user } = useUserState.getState();
  if (!user) return;
  let locationPatientRecordId: string | undefined;
  if (user.patientRecordId) {
    await refreshPatientRecordState(user.patientRecordId);
    await refreshCaregiverRequestsState();

    const patientRecord = usePatientRecordState.getState().patientRecord;
    if (!patientRecord)
      throw new Error(getText('error-loading-patient-record'));
    const locationPatientRecord = patientRecord.location_patient_records.find(
      (record) => record.location_id === user.preferredPharmacyLocationId,
    );
    locationPatientRecordId = locationPatientRecord?.id;
  }

  useUserState.setState({
    user: { ...user, preferredPharmacyLprId: locationPatientRecordId },
  });
};

export const loadStores = async () => {
  useAppStateStore.setState({ status: 'loading' });
  try {
    const response = await pharmacyService.findLocationsByPharmacyId();
    useAppStateStore.setState({
      stores: response.locations,
      status: 'success',
    });
  } catch (error) {
    useAppStateStore.setState({ status: 'error' });
  }
};

export const loadPharmacyBrandingInfo = async (): Promise<
  BrandingDto | undefined
> => {
  const { slug, pharmacyId } = useAppStateStore.getState();

  try {
    const pharmacy: PharmacyDto = await pharmacyService.findOne(
      slug || pharmacyId,
    );

    const brandingDto: BrandingDto = await pharmacyService.findPharmacyBranding(
      pharmacy.id,
    );

    const { display_name } = await PharmacyService.getAppConfig(pharmacy.id);

    useAppStateStore.setState({
      pharmacy: pharmacy,
      pharmacyId: pharmacy.id,
      pharmacyName: display_name,
      branding: brandingDto,
    });

    if (Platform.OS === 'web') {
      setFavicon();
    }

    return brandingDto;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404)
      throw Error(PHARMACY_NOT_EXIST_ERROR);
  }
};

export const loadDeepLinkingConfig = async () => {
  const { pharmacyId } = useAppStateStore.getState();
  const { slug } = useAppStateStore.getState();
  if (!slug) {
    logError(
      new Error('Slug is not defined when loading deep linking config.'),
    );
    throw Error(
      'Slug is not defined, please make sure to call loadSlugPrefixForWeb before calling loadDeepLinkingConfig',
    );
  }
  const appScheme = slug.replace(/_/g, '-');
  const dlc = await appConfigService.getDeepLinkingConfig(
    pharmacyId,
    slug,
    appScheme,
  );
  useAppStateStore.setState({ deepLinkingConfig: dlc });
};

/**
 * This actions loads into the app state the slug in case the env is `web` otherwise we are sure that we already have the `pharmacyId` / `slug` info in memory
 * @returns
 */
export const loadSlugPrefixForWeb = async (): Promise<void> => {
  if (Platform.OS === 'web') {
    // parse slug
    const { path } = await Linking.parseInitialURLAsync();
    const slug = path?.split('/')[0];
    if (!slug) {
      // For localhost, we want to default to Cameron's.
      // Adding this so we don't have to type the slug every time.
      if (window.location.hostname === 'localhost') {
        useAppStateStore.setState({ slug: 'camero_pharma' });
      } else {
        throw Error(URL_MALFORMED_ERROR);
      }
    } else useAppStateStore.setState({ slug });
  }
  return Promise.resolve();
};

export const refreshNotifications = async () => {
  const userId = await AsyncStorageService.getItem(StorageKeys.UserId);
  if (!userId) {
    logError(
      new Error('Failed to get notitifications list, user is not defined.'),
    );
    return;
  }
  try {
    const response = await unifiedCommsService.getNotifications(userId);
    useAppStateStore.setState({
      notifications: response.notifications,
      notificationsBadgeCount: response.unread_count,
    });
    await Notifications.setBadgeCountAsync(response.unread_count);
  } catch (e) {
    logError(new Error('Failed to get notifications list', e as Error));
  }
};

export const loadLocationServicesConfig = async () => {
  const { user } = useUserState.getState();
  const locationId = user?.preferredPharmacyLocationId;

  if (!locationId) {
    return;
  }

  try {
    const locationServices =
      await PharmacyService.findLocationServices(locationId);
    useAppStateStore.setState({ locationServices });
  } catch (error) {
    logError(error as Error);
    useAppStateStore.setState({ locationServices: [] });
  }
};

export const loadLocationPrescriptionConfig = async () => {
  const { user } = useUserState.getState();

  const locationId = user?.preferredPharmacyLocationId;

  if (!locationId) {
    return;
  }

  try {
    const locationConfig = await RefillService.getLocationConfig(locationId);
    useAppStateStore.setState({ locationPrescriptionConfig: locationConfig });
  } catch (error) {
    logError(error as Error);
  }
};

export const loadLocationPickupMethods = async () => {
  const { user } = useUserState.getState();
  const locationId = user?.preferredPharmacyLocationId;

  if (!locationId) {
    return;
  }

  try {
    const pickupMethods =
      await PharmacyService.findLocationPickupMethods(locationId);

    const availablePickupMethods = pickupMethods.filter((pm) => pm.active);

    useAppStateStore.setState({
      locationPickupMethods: availablePickupMethods,
    });
  } catch (error) {
    logError(error as Error);
    useAppStateStore.setState({ locationPickupMethods: [] });
  }
};
export const getLocationServiceConfigValueBySlug = (
  serviceName: string,
): string | undefined => {
  const locationOption = useAppStateStore
    .getState()
    .locationServices.find((locSrv) => locSrv.slug === serviceName);

  const optionsValue = locationOption?.options.find(
    (option) => option.selected,
  );

  return optionsValue?.slug;
};

export const isLocationIntegrated = () => {
  const { integration_type } =
    useAppStateStore.getState().locationPrescriptionConfig;

  return (
    integration_type === IntegrationType.Integrated ||
    integration_type === IntegrationType.DataFeed
  );
};

export const openFindingDigitalContent = () => {
  useAppStateStore.setState({
    isFindingDigitalContentOpen: true,
  });
};

export const closeFindingDigitalContent = () => {
  useAppStateStore.setState({
    isFindingDigitalContentOpen: false,
  });
};

export const setFavicon = () => {
  const { pharmacyId } = useAppStateStore.getState();
  const favicons = document.querySelectorAll(
    'link[rel="icon"], link[rel="shortcut icon"]',
  );
  if (!favicons || !pharmacyId) return;
  favicons.forEach((favicon) => {
    favicon.setAttribute(
      'href',
      /* Using the ios icon here because it uses the logo on light
         in a square icon, like we would want a favicon to be. */
      `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding/icon_ios.png`,
    );
  });
};

export const setAmplitudeUserProperties = (pharmacyName: string) => {
  const { user } = useUserState.getState();
  if (user) {
    ampli.identify(user.id, {
      pharmacy_id: user.pharmacyId,
      pharmacy_name: pharmacyName,
      location_id: user.preferredPharmacyLocationId!,
    });
  }
};

export const loadMessagesConfig = async () => {
  const { user } = useUserState.getState();

  const locationId = user?.preferredPharmacyLocationId;

  if (!locationId) {
    return;
  }

  try {
    const { is_patient_start_chat_available } =
      await unifiedCommsService.getDirectMessageSettings(
        user.pharmacyId,
        locationId,
      );
    useAppStateStore.setState({ is_patient_start_chat_available });
  } catch (error) {
    logError(error as Error);
  }
};
