import { FunctionComponent, PropsWithChildren, useState } from 'react';
import * as validate from '@digitalpharmacist/validation-dp';
import theme, { makeStyles } from 'assets/theme';
import { Form, InternalScreenContainer } from 'assets/layout';
import { Text } from 'assets/components/text';
import { View, Dimensions } from 'react-native';
import { useForm } from 'react-hook-form';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import { TransferMedsTestIDs } from './TransferMedsTestIDs';
import { Divider } from 'react-native-paper';
import { useTransferMedicationsStore } from './transfer-meds-store';
import { StackHeaderProps } from '@react-navigation/stack';
import { useUserState } from '../../store/user-store';
import patientService from '../../api/patient-service';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

export const SingleLocationPharmacy: FunctionComponent<
  PropsWithChildren<TransferMedicationsNavigationProp>
> = ({ navigation }) => {
  const styles = useStyles();
  const {
    originated,
    userCanChose,
    locationPatientRecordId,
    updateTransferMedsData,
  } = useTransferMedicationsStore();
  const { user } = useUserState();
  const [keyboardContainerHeight, setKeyboardContainerHeight] = useState<
    number | string
  >();

  const methods = useForm({
    defaultValues: {
      pharmacyName: '',
      pharmacyPhoneNumber: '',
    },
  });

  const minKeyboardContainerHeight = 610;
  const windowHeight = Dimensions.get('window').height;
  const kbContainerHeight = windowHeight * 0.8;

  const handleOnPress = () => {
    void (async () => {
      if (
        !updateTransferMedsData ||
        !user?.patientRecordId ||
        !user.preferredPharmacyLocationId
      )
        return;

      updateTransferMedsData({
        pharmacyName: methods.getValues().pharmacyName,
        pharmacyPhoneNumber: methods
          .getValues()
          .pharmacyPhoneNumber.replace(/-/g, ''),
      });

      let selectedLPRId = locationPatientRecordId;
      if (!locationPatientRecordId || originated === 'onboarding') {
        const patientRecord =
          await patientService.patientRecordInitLocationPatientRecord(
            user.patientRecordId,
            { location_id: user.preferredPharmacyLocationId },
          );
        selectedLPRId = patientRecord.location_patient_records.find(
          (lpr) => lpr.location_id === user.preferredPharmacyLocationId,
        )?.id;
        if (!user.patientRecordId)
          throw new Error(getText('error-loading-patient-record'));
      }
      let lprRecord: LocationPatientRecordDto | undefined;

      if (selectedLPRId) {
        lprRecord = await patientService.findLocationPatientRecord(
          user.preferredPharmacyLocationId,
          selectedLPRId,
        );
      }

      updateTransferMedsData({
        locationPatientRecord: lprRecord,
        locationPatientRecordId: selectedLPRId,
      });
      methods.reset();

      if (userCanChose) {
        navigation.navigate('transfer-medications', {
          screen: 'about-transfer',
        });
      } else {
        navigation.navigate('transfer-medications', {
          screen: 'enter-medications',
        });
      }
    })();
  };

  const handleOnFocusEvent = () => {
    setKeyboardContainerHeight((prev) =>
      kbContainerHeight < minKeyboardContainerHeight
        ? minKeyboardContainerHeight
        : kbContainerHeight,
    );
  };

  const handleOnBlurEvent = () => {
    setKeyboardContainerHeight((prev) => undefined);
  };

  return (
    <InternalScreenContainer
      title={getText('transfer-meds-header-title')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: methods.handleSubmit(handleOnPress),
          testID: TransferMedsTestIDs.reviewButton,
          logger: { id: TransferMedsTestIDs.reviewButton },
          text: getText('review'),
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Alert
          visible={
            !!methods.getFieldState('pharmacyName').error ||
            !!methods.getFieldState('pharmacyPhoneNumber').error
          }
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
        />
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(2) }}>
            <View>
              <Text style={styles.title}>{getText('pharmacy-info')}</Text>
            </View>
            <View>
              <Divider />
            </View>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(2) }}>
            <TextField
              label={getText('pharmacy-name')}
              name="pharmacyName"
              rules={{
                required: getText('pharmacy-name-is-required'),
              }}
              testID={TransferMedsTestIDs.pharmacyNameTextField}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('phone-number')}
              name="pharmacyPhoneNumber"
              rules={{
                required: getText('mobile-number-is-required'),
                validate: {
                  value: (value) => {
                    return validate.isPhoneNumber(
                      // Backend is expecting 1 digit country code in front of the phone number
                      value ? '+1' + value : '',
                    )
                      ? true
                      : getText('phone-is-not-valid');
                  },
                },
              }}
              type="telephoneNumber"
              testID={TransferMedsTestIDs.pharmacyNumberTextField}
              onFocus={handleOnFocusEvent}
              onBlur={handleOnBlurEvent}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </InternalScreenContainer>
  );
};

export type TransferMedicationsNavigationProp = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
}));
