import React, {
  useEffect,
  useState,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { View } from 'react-native';
import { TypeaheadBaseItem } from '../typeahead/types';
import { Text } from '../text';
import { makeStyles, useTheme } from '../../theme';
import {
  TypeaheadWithTags,
  TypeaheadWithTagsProps,
} from '../typeahead-with-tags/TypeaheadWithTags';
import { RadioButtonGroupInput } from '../radio-button-group/RadioButtonGroupInput';

export interface TypeaheadWithTagsAndEmptyProps extends TypeaheadWithTagsProps {
  explicitEmptyLabel: string;
  explicitNotEmptyLabel: string;
  emptyValue: string | TypeaheadBaseItem;
}

enum ExplicitEmptyState {
  Empty = 'empty',
  NotEmpty = 'not_empty',
}

export const TypeaheadWithTagsAndEmpty: FunctionComponent<
  PropsWithChildren<TypeaheadWithTagsAndEmptyProps>
> = ({
  getOptionValue: parentGetOptionValue,
  defaultValue,
  emptyValue,
  onChange,
  label,
  explicitEmptyLabel,
  explicitNotEmptyLabel,
  disabled,
  testID,
  ...otherProps
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const getOptionValueFallback = function (
    option: string | TypeaheadBaseItem,
  ): string {
    if (typeof option === 'string') {
      return option;
    }
    return option.value;
  };

  const getOptionValue = parentGetOptionValue || getOptionValueFallback;

  const [selectedValues, setSelectedValues] = useState(defaultValue ?? []);
  const [explicitEmptyState, setExplicitEmptyState] = useState<
    ExplicitEmptyState | undefined
  >(undefined);

  function isExplicitlyEmpty(values: (string | TypeaheadBaseItem)[]) {
    return (
      values.length === 1 &&
      getOptionValue(values[0]) === getOptionValue(emptyValue)
    );
  }

  function filterOutEmptyOption(
    items: Array<string | TypeaheadBaseItem>,
  ): Array<string | TypeaheadBaseItem> {
    return items.filter(
      (x) => getOptionValue(x) !== getOptionValue(emptyValue),
    );
  }

  useEffect(() => {
    if (defaultValue === undefined || defaultValue.length === 0) return; // assumes checkbox is undefined

    if (isExplicitlyEmpty(defaultValue)) {
      setExplicitEmptyState(ExplicitEmptyState.Empty);
    } else {
      setSelectedValues(defaultValue);
      setExplicitEmptyState(ExplicitEmptyState.NotEmpty);
    }
  }, [defaultValue]);

  const onChanged = (items: (string | TypeaheadBaseItem)[]) => {
    let newItems = items;
    setExplicitEmptyState(ExplicitEmptyState.NotEmpty);
    setSelectedValues(newItems);
    onChange?.(newItems);
  };

  const handleExplicitEmptyRadioButtonPress = (value: string) => {
    switch (value) {
      case ExplicitEmptyState.Empty: {
        // Do nothing if we are already in this state
        if (explicitEmptyState === ExplicitEmptyState.Empty) {
          return;
        }

        setExplicitEmptyState(ExplicitEmptyState.Empty);
        const newValue = [emptyValue];
        setSelectedValues(newValue);
        onChange?.(newValue);
        break;
      }
      case ExplicitEmptyState.NotEmpty: {
        // Do nothing if we are already in this state
        if (explicitEmptyState === ExplicitEmptyState.NotEmpty) {
          return;
        }

        setExplicitEmptyState(ExplicitEmptyState.NotEmpty);
        const newValue = filterOutEmptyOption(selectedValues);
        setSelectedValues(newValue);
        onChange?.(newValue);
        break;
      }
      default:
        throw new Error('Unsupported radio button');
    }
  };

  const typeaheadProps: TypeaheadWithTagsProps = {
    ...otherProps,
    getOptionValue,
  };

  return (
    <View>
      {label && <Text style={styles.label}>{label}</Text>}

      <View style={{ marginBottom: theme.getSpacing(1) }}>
        <RadioButtonGroupInput
          value={explicitEmptyState}
          testID={testID && testID + '-rdb'}
          values={[
            {
              text: explicitEmptyLabel,
              value: ExplicitEmptyState.Empty,
            },
            {
              text: explicitNotEmptyLabel,
              value: ExplicitEmptyState.NotEmpty,
            },
          ]}
          onValueChange={handleExplicitEmptyRadioButtonPress}
        />
      </View>

      <TypeaheadWithTags
        {...typeaheadProps}
        // don't display the explicit empty value
        defaultValue={selectedValues?.filter(
          (x) => getOptionValue(x) !== getOptionValue(emptyValue),
        )}
        onChange={onChanged}
        disabled={disabled || explicitEmptyState === ExplicitEmptyState.Empty}
        testID={testID && testID + '-tags'}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(1),
  },
}));
