import { FunctionComponent, PropsWithChildren } from 'react';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { getText } from 'assets/localization/localization';
import { IconButton } from 'assets/components/icon-button';
import { BellIcon, CloseIcon } from 'assets/icons';
import { NotificationsEmptyState } from './NotificationsEmptyState';
import { UserNotificationDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import { logError } from 'assets/logging/logger';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import {
  handleMarkAsRead,
  handleMarkAsHidden,
  handleMarkAsUnread,
  handleMarkAllAsRead,
  handleNavigationScreen,
} from '../../actions/notification-actions';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import { refreshNotifications } from '../../actions/app-actions';
import { NotificationList } from '../notifications/NotificationList';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export const NotificationsListNative: FunctionComponent<
  PropsWithChildren<NotificationsListNativeProps>
> = ({ bottomSheetRef, notifications }) => {
  const theme = useTheme();
  const styles = useStyles();
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const { status } = useAppStateStore();

  const handleOpenBottomSheet = async () => {
    try {
      await refreshNotifications();
    } catch {
      logError(new Error('Failed to get notifications'));
    }
    bottomSheetRef.current?.show();
  };

  const handleBottomSheetDismiss = () => {
    bottomSheetRef.current?.hide();
  };

  return (
    <>
      <IconButton
        icon={BellIcon}
        onPress={handleOpenBottomSheet}
        color={theme.palette.white}
        logger={{ id: 'notifications-list-web-icon-button' }}
      />
      <GenericModal
        title={getText('notifications')}
        showDismissButton
        ref={bottomSheetRef}
        buttons={[
          {
            hierarchy: 'secondary',
            onPress: handleBottomSheetDismiss,
            logger: { id: 'notifications-list-close-button' },
            text: getText('close'),
          },
        ]}
      >
        {notifications.length > 0 ? (
          <NotificationList
            closeBottomSheet={handleBottomSheetDismiss}
            notifications={notifications}
            markAsRead={handleMarkAsRead}
            markAllAsRead={handleMarkAllAsRead}
            markAsHidden={handleMarkAsHidden}
            markAsUnread={handleMarkAsUnread}
            refreshNotifications={refreshNotifications}
            navigation={navigation}
            isLoading={status === 'loading'}
            handleNavigationScreen={handleNavigationScreen}
          />
        ) : (
          <NotificationsEmptyState />
        )}
      </GenericModal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  contentContainerStyle: {
    marginHorizontal: getSpacing(2),
    paddingTop: getSpacing(2),
    paddingBottom: getSpacing(4),
  },
  bottomSheetViewTitle: {
    ...theme.fonts.medium,
    fontSize: 18,
  },
  bottomSheetViewTitleContainer: {
    marginLeft: theme.getSpacing(4),
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  footerContainer: {
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  headerContainer: {
    flexDirection: 'row',
    margin: theme.getSpacing(1),
  },
  iconContainer: {
    justifyContent: 'flex-end',
  },
}));

export interface NotificationsListNativeProps {
  bottomSheetRef: React.RefObject<BaseModalHandler>;
  notifications: UserNotificationDto[];
}
