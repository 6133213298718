import {
  ForwardRefRenderFunction,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  BaseModal,
  BaseModalButtonProps,
  BaseModalHandler,
} from 'assets/components/base-modal/BaseModal';
import React from 'react';

export const GenericBottomSheetView: ForwardRefRenderFunction<
  BaseModalHandler,
  GenericBottomSheetProps
> = (props: GenericBottomSheetProps, ref: ForwardedRef<BaseModalHandler>) => {
  const modalRef = React.useRef<BaseModalHandler>(null);

  const handleShow = () => {
    modalRef.current?.show();
  };

  const handleHide = () => {
    modalRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));
  return (
    <BaseModal
      backdropPressBehavior={'close'}
      title={props.title}
      ref={modalRef}
      buttons={props.buttons}
      isScrollable={props.isScrollable}
      showDismissButton={props.showDismissButton}
      nativeButtonOrientation={props.buttonOrientation}
      nativeHeight={props.height}
      onClose={props.onClose}
      testID={'generic-bottom-sheet-id'}
    >
      {props.children}
    </BaseModal>
  );
};

export const GenericBottomSheet = forwardRef<
  BaseModalHandler,
  GenericBottomSheetProps
>(GenericBottomSheetView);

export type GenericBottomSheetProps = PropsWithChildren<{
  title: string;
  ref?: ForwardedRef<BaseModalHandler>;
  buttons?: BaseModalButtonProps[];
  isScrollable?: boolean;
  showDismissButton?: boolean;

  buttonOrientation?: NativeButtonOrientation;
  height?: string;

  onClose?: () => void;
}>;

export enum NativeButtonOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}
