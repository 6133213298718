import React, {
  ForwardRefRenderFunction,
  ForwardedRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { EditContactInfo } from '../account/patient/components/account-information/EditContactInfo';
import { ConfirmationWithVerificationCode } from '../account/patient/components/account-information/ConfirmWithCode';
import { useForm } from 'react-hook-form';
import {
  PhoneEmailVerification,
  resendPhoneConfirmationCode,
  updatePhoneNumberOrEmail,
  verifyPatientEmail,
  verifyPhoneNumber,
} from './verification-actions';
import { getText } from 'assets/localization/localization';
import * as validate from '@digitalpharmacist/validation-dp';
import {
  useResendVerificationState,
  useVerificationState,
} from './verification-store';
import { UpdateUserRequest } from '@digitalpharmacist/users-service-client-axios';
import * as Linking from 'expo-linking';
import { useUserState } from '../../store/user-store';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';

export const EditVerifiedPropertyView: ForwardRefRenderFunction<
  BaseModalHandler,
  EditVerifiedPropertyProps
> = (props: EditVerifiedPropertyProps, ref: ForwardedRef<BaseModalHandler>) => {
  const editContactRef = React.useRef<BaseModalHandler>(null);
  const { user } = useUserState();
  const email = user?.email;
  const [verifiedProperty, setVerifiedProperty] = useState<VerifiedProperty>(
    props.verifiedProperty,
  );

  const form = useForm<PhoneEmailVerification>({
    defaultValues: {
      value: '',
    },
  });
  const [currentMode, setCurrentMode] = useState<Mode>(Mode.Update);
  const buttonTitle = getText('next');
  //const { currentUrl } = useDeepLinkingContext();
  const [confirmationCode, setConfirmationCode] = useState<string>();

  useEffect(() => {
    if (props.currentUrl && props.currentUrl !== '') {
      const { queryParams } = Linking.parse(props.currentUrl);
      if (queryParams) {
        const confirmCode: string = queryParams.confirmCode as string;
        if (!queryParams.confirmCode) return;
        if (!email) return;
        setVerifiedProperty(VerifiedProperty.Email);
        showDeepLinkModal(confirmCode, email);
      }
    }
  }, [props.currentUrl]);

  const handleShow = () => {
    clearFormState();
    editContactRef.current?.show();
  };

  const showDeepLinkModal = (confirmCode: string, email: string) => {
    setConfirmationCode(confirmCode);
    setCurrentMode(Mode.Verify);
    form.setValue('value', email);
    editContactRef.current?.show();
  };

  const handleHide = () => {
    clearFormState();
    editContactRef.current?.hide();
  };

  const clearFormState = () => {
    useVerificationState.setState({ error: undefined, status: 'loading' });
    useResendVerificationState.setState({
      error: undefined,
      status: 'loading',
    });
    setCurrentMode(Mode.Update);
    form.reset();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const onNextPress = async () => {
    try {
      const isFormValid = await form.trigger(); // Triggers validation for all fields
      const formValue = form.getValues().value;
      if (isFormValid && formValue) {
        const userInfo: UpdateUserRequest =
          verifiedProperty === VerifiedProperty.Phone
            ? { phoneNumber: formValue }
            : { email: formValue };
        await updatePhoneNumberOrEmail(userInfo).then(() => {
          if (isStatusSuccess()) setCurrentMode(Mode.Verify);
        });
      }
    } catch (error) {
      //TODO: review console error
      console.error('Validation error:', error);
    }
  };

  const handleResendCode = async () => {
    if (verifiedProperty === VerifiedProperty.Phone) {
      await resendPhoneConfirmationCode(form.getValues().value);
    } else {
      await updatePhoneNumberOrEmail({ email: form.getValues().value });
    }
  };

  const onCancelPress = () => {
    handleHide();
  };

  const isStatusSuccess = (): boolean => {
    const status = useVerificationState.getState().status;
    if (status === 'success') return true;
    return false;
  };

  const handleConfirmCode = async (confirmCode: string) => {
    if (verifiedProperty === VerifiedProperty.Email) {
      await verifyPatientEmail(confirmCode);
    } else if (verifiedProperty === VerifiedProperty.Phone) {
      await verifyPhoneNumber(confirmCode);
    }
    if (isStatusSuccess()) handleHide();
  };

  return (
    <GenericModal
      buttons={[
        {
          hierarchy: 'primary',
          onPress: () => {
            currentMode === Mode.Update ? onNextPress() : handleConfirmCode;
          },
          logger: { id: 'edit-contact-ok-button' },
          text: currentMode === Mode.Update ? buttonTitle : getText('submit'),
        },
      ]}
      title={VerifiedPropertyConfig[props.verifiedProperty].title}
      ref={editContactRef}
      isScrollable={true}
    >
      {currentMode === Mode.Update ? (
        <EditContactInfo
          handleSubmit={onNextPress}
          verifiedProperty={verifiedProperty}
          form={form}
        ></EditContactInfo>
      ) : (
        <ConfirmationWithVerificationCode
          handleSubmit={handleConfirmCode}
          handleResendCode={handleResendCode}
          verifiedProperty={props.verifiedProperty}
          confirmValue={form.getValues('value')!}
          confirmationCode={confirmationCode}
        ></ConfirmationWithVerificationCode>
      )}
    </GenericModal>
  );
};

interface EditVerifiedPropertyProps {
  verifiedProperty: VerifiedProperty;
  currentUrl?: string;
}

export const EditVerifiedProperty = React.forwardRef<
  BaseModalHandler,
  EditVerifiedPropertyProps
>(EditVerifiedPropertyView);

export enum VerifiedProperty {
  Email = 'email',
  Phone = 'phone',
}

enum Mode {
  Update = 'update',
  Verify = 'verify',
}

export const VerifiedPropertyConfig: Record<
  VerifiedProperty,
  {
    label: string;
    title: string;
    subtitle: string;
    requiredMessage: string;
    validationMessage: string;
    inputType: 'emailAddress' | 'telephoneNumber';
    validation: (value: string) => boolean;
    extraTitle: string;
  }
> = {
  [VerifiedProperty.Email]: {
    label: getText('email-address'),
    title: getText('update-info', { title: getText('email-capital-case') }),
    subtitle: getText('confirmation-email-sent'),
    requiredMessage: getText('email-is-required'),
    validationMessage: getText('email-is-not-valid'),
    extraTitle: getText('standard-text-apply'),
    inputType: 'emailAddress',
    validation: (value: string) => {
      return validate.isEmail(value);
    },
  },
  [VerifiedProperty.Phone]: {
    label: getText('edit-mobile-number'),
    title: getText('update-info', { title: getText('edit-phone-number') }),
    subtitle: getText('confirmation-message-sent'),
    requiredMessage: getText('mobile-number-is-required'),
    validationMessage: getText('phone-is-not-valid'),
    extraTitle: getText('standard-text-apply'),
    inputType: 'telephoneNumber',
    validation: (value: string) => {
      return validate.isPhoneNumber(value ? '+1' + value : '');
    },
  },
};
