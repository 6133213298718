import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Path, Svg } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const ShoppingBagWithGreenTagIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, colorSecondary, size, testID }) => {
  const theme = useTheme();

  return (
    <Svg width={size} height={size} fill="none" testID={testID}>
      <Path
        d="M18.4411 23.2716L22.2983 27.3506V33.2849L9.91837 45.5702L0.152588 35.7617L12.5068 23.2716H18.4411ZM15.0452 30.9906C15.9079 31.8512 17.3046 31.8512 18.1663 30.9874C19.0291 30.1235 19.0291 28.7253 18.1663 27.8647C17.3046 27.004 15.9066 27.0072 15.0452 27.8711C14.1834 28.7349 14.1834 30.1299 15.0452 30.9906Z"
        fill={colorSecondary || theme.colors.brandedText}
      />
      <Path
        d="M14.0581 21.5H16.4125V19.7404H56.4369V68.0058H16.4125V42L14.0581 44.5L14.0581 70.3602H59.2266L60.9483 65.6513H63.5001V11.5H17.0477L17.5897 12.9641L18.3633 14.5057L14.0581 16.6583L14.0581 21.5ZM59.3023 63.2969L58.7918 64.693L58.7918 17.0888L58.3092 16.1935L61.1457 15.114V63.2969H59.3023ZM55.2799 17.3859H17.8676L21.525 15.5572L20.6706 13.8543H56.1888L53.5302 15.1924L55.2799 17.3859Z"
        fill={color || theme.palette.gray[700]}
      />
      <Path
        d="M25.1806 28.6047C25.1806 29.9879 26.0929 31.1575 27.3482 31.5459V35.3609C27.3482 39.88 31.2945 43.4078 36.0301 43.4078C40.765 43.4078 44.7119 39.88 44.7119 35.3609V31.5321C45.9449 31.1303 46.8358 29.9717 46.8358 28.6047C46.8358 26.9051 45.4583 25.5251 43.7567 25.5251C42.0555 25.5251 40.678 26.9052 40.678 28.6047C40.678 29.8012 41.3606 30.8381 42.3575 31.3476V35.3609C42.3575 38.3983 39.6539 41.0534 36.0301 41.0534C32.4057 41.0534 29.7026 38.3983 29.7026 35.3609V31.3248C30.6758 30.8077 31.3388 29.7838 31.3388 28.6047C31.3388 26.9049 29.9606 25.5251 28.2597 25.5251C26.5588 25.5251 25.1806 26.9049 25.1806 28.6047ZM28.2597 29.3287C27.8591 29.3287 27.535 29.0041 27.535 28.6047C27.535 28.2043 27.86 27.8795 28.2597 27.8795C28.6594 27.8795 28.9844 28.2043 28.9844 28.6047C28.9844 29.0041 28.6603 29.3287 28.2597 29.3287ZM43.0324 28.6047C43.0324 29.0044 43.3564 29.3287 43.7567 29.3287C44.1576 29.3287 44.4814 29.0043 44.4814 28.6047C44.4814 28.2041 44.1567 27.8795 43.7567 27.8795C43.3573 27.8795 43.0324 28.204 43.0324 28.6047Z"
        fill={color || theme.palette.gray[700]}
      />
    </Svg>
  );
};
