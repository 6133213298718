import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useForm } from 'assets/form';
import { Form, InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import patientService from '../../../api/patient-service';
import { MedicalInfoFormFields } from '../../../components/medical-info/MedicalInfoFormFields';
import { useOrderRefillStore } from '../../medications/medication-orders/order-store';
import { RefillGuestTestIDs } from '../../refill-guest/RefillGuestTestIDs';
import { RefillStackParamList } from '../RefillNavigation';
import { useRefillMedicationsStore } from '../refill-store';

export const OtherInformationModal: FunctionComponent<
  PropsWithChildren<OtherInformationNavigationProps>
> = ({ navigation }) => {
  const { selectedPatientRecord: patientRecord, updateMedicationsData } =
    useRefillMedicationsStore();
  const { updateOrderRefillData } = useOrderRefillStore();

  if (!patientRecord) throw Error('No Patient Record');

  const form = useForm({
    defaultValues: {
      gender: patientRecord.gender,
      allergies: patientRecord.allergies,
      medical_conditions: patientRecord.medical_conditions,
      prefers_easy_open_bottle_caps:
        patientRecord.prefers_easy_open_bottle_caps,
    },
  });

  const handleOnPressDone = async () => {
    if (!updateMedicationsData || !updateOrderRefillData) return;

    const updatePatientRecord = {
      gender: form.getValues().gender,
      allergies: form.getValues().allergies,
      medical_conditions: form.getValues().medical_conditions,
      prefers_easy_open_bottle_caps:
        form.getValues().prefers_easy_open_bottle_caps === true,
    };

    updateMedicationsData({
      selectedPatientRecord: {
        ...patientRecord,
        ...updatePatientRecord,
      },
    });

    updateOrderRefillData({
      selectedPatientRecord: {
        ...patientRecord,
        ...updatePatientRecord,
      },
    });

    await patientService.updatePatientRecord(
      patientRecord.id,
      updatePatientRecord,
    );

    navigation.navigate('refill-review');
  };

  return (
    <InternalScreenContainer
      title={getText('medications-fill-your-prescription')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: form.handleSubmit(handleOnPressDone),
          testID: RefillGuestTestIDs.refillGuestFindStoreNext,
          logger: { id: RefillGuestTestIDs.refillGuestFindStoreNext },
          text: getText('review'),
        },
      ]}
      scrollToTop={
        !!form.getFieldState('allergies').error ||
        !!form.getFieldState('medical_conditions').error ||
        !!form.getFieldState('prefers_easy_open_bottle_caps').error
      }
    >
      <Form methods={form}>
        <Form.Alert
          visible={
            !!form.getFieldState('allergies').error ||
            !!form.getFieldState('gender').error
          }
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
        />
        <MedicalInfoFormFields showGenderInformation={true} />
      </Form>
    </InternalScreenContainer>
  );
};

type OtherInformationNavigationProps = NativeStackScreenProps<
  RefillStackParamList,
  'refill-other-information'
>;
