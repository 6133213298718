import { View } from 'react-native';
import { Logo } from '../logo/Logo';
import React, { useRef } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { GradientView } from 'assets/components/gradient-view';

const IntroWebHeader = () => {
  const styles = useStyles();
  const theme = useTheme();
  const gradientIdRef = useRef();

  return (
    <GradientView
      style={styles.header}
      contentHeight={60}
      fillGradientStops={[
        { stopColor: theme.colors.gradientStart, offset: 0 },
        { stopColor: theme.colors.gradientEnd, offset: 100 },
      ]}
      gradientId={gradientIdRef.current}
    >
      <Logo width={190} height={40} />
    </GradientView>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    alignItems: 'flex-start',
  },
}));

export default IntroWebHeader;
