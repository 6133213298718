import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { IconProps } from './types';

export const CursorIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg width={size} height={size} color={color} testID={testID} fill="none">
      <G clipRule="evenodd" filter="url(#a)">
        <Path
          fill="#fff"
          fillRule="evenodd"
          d="M5.83 14.473c-.277-.376-.615-1.145-1.216-2.079-.34-.528-1.185-1.523-1.436-2.028-.218-.446-.195-.646-.143-1.016.092-.658.722-1.17 1.394-1.102.508.052.939.411 1.326.75.234.205.522.602.695.826.16.206.199.29.369.534.225.322.295.48.21.127-.07-.52-.184-1.408-.348-2.193-.125-.595-.156-.688-.275-1.145-.126-.486-.19-.827-.31-1.342-.081-.365-.23-1.11-.27-1.53-.055-.573-.084-1.507.259-1.937.269-.336.886-.438 1.269-.23.501.27.786 1.05.916 1.362.234.56.379 1.206.505 2.055.16 1.08.456 2.58.465 2.895.024-.386-.066-1.2-.003-1.572.056-.336.32-.727.651-.833.28-.089.608-.121.896-.057.307.067.63.301.75.522.354.654.361 1.99.376 1.92.084-.395.07-1.289.278-1.66.137-.246.486-.467.672-.503.287-.054.64-.07.943-.008.244.051.573.362.662.51.214.36.335 1.38.371 1.738.015.148.073-.411.287-.771.397-.67 1.803-.8 1.857.67.024.684.02.653.02 1.114 0 .542-.012.868-.04 1.26-.03.419-.114 1.366-.236 1.825-.084.316-.363 1.025-.638 1.45 0 0-1.051 1.31-1.166 1.9-.115.59-.077.594-.1 1.012-.022.417.119.966.119.966s-.785.109-1.207.037c-.383-.066-.857-.882-.979-1.131-.168-.344-.527-.278-.667-.024-.22.401-.694 1.121-1.029 1.166-.653.088-2.01.033-3.07.021 0 0 .18-1.06-.223-1.423-.298-.271-.812-.822-1.12-1.11l-.813-.966Z"
        />
        <Path
          stroke="#101828"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.75}
          d="M5.83 14.473c-.277-.376-.615-1.145-1.216-2.079-.34-.528-1.185-1.523-1.436-2.028-.218-.446-.195-.646-.143-1.016.092-.658.722-1.17 1.394-1.102.508.052.939.411 1.326.75.234.205.522.602.695.826.16.206.199.29.369.534.225.322.295.48.21.127-.07-.52-.184-1.408-.348-2.193-.125-.595-.156-.688-.275-1.145-.126-.486-.19-.827-.31-1.342-.081-.365-.23-1.11-.27-1.53-.055-.573-.084-1.507.259-1.937.269-.336.886-.438 1.269-.23.501.27.786 1.05.916 1.362.234.56.379 1.206.505 2.055.16 1.08.456 2.58.465 2.895.024-.386-.066-1.2-.003-1.572.056-.336.32-.727.651-.833.28-.089.608-.121.896-.057.307.067.63.301.75.522.354.654.361 1.99.376 1.92.084-.395.07-1.289.278-1.66.137-.246.486-.467.672-.503.287-.054.64-.07.943-.008.244.051.573.362.662.51.214.36.335 1.38.371 1.738.015.148.073-.411.287-.771.397-.67 1.803-.8 1.857.67.024.684.02.653.02 1.114 0 .542-.012.868-.04 1.26-.03.419-.114 1.366-.236 1.825-.084.316-.363 1.025-.638 1.45 0 0-1.051 1.31-1.166 1.9-.115.59-.077.594-.1 1.012-.022.417.119.966.119.966s-.785.109-1.207.037c-.383-.066-.857-.882-.979-1.131-.168-.344-.527-.278-.667-.024-.22.401-.694 1.121-1.029 1.166-.653.088-2.01.033-3.07.021 0 0 .18-1.06-.223-1.423-.298-.271-.812-.822-1.12-1.11l-.813-.966Z"
        />
      </G>
      <Path
        fill="#101828"
        fillRule="evenodd"
        d="M10 11.5a.5.5 0 0 0-1 0v3a.5.5 0 0 0 1 0v-3Zm1.5-.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5Zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
