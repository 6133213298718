import { View } from 'react-native';
import { getText } from 'assets/localization/localization';
import {
  PatientRecordDto,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { PatientUnderCareForm } from '../../patient-actions';
import React, {
  ForwardRefRenderFunction,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { PatientUnderCareEditDetailsEnum } from '../../patient-store';
import { UseFormReturn } from 'react-hook-form';
import { PersonalInformationComponent } from '../account-information/PersonalInformationComponent';
import { MedicalInformation } from '../account-information/MedicalInformation';
import {
  EditPersonalInformation,
  EditPersonalInformationMode,
} from '../manage-people-under-care-details/EditPersonalInformation';
import { EditMedicalInformation } from '../manage-people-under-care-details/EditMedicalInformation';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';

export const PersonalInfoModal: ForwardRefRenderFunction<
  BaseModalHandler,
  PersonalInfoModalProps
> = (props: PersonalInfoModalProps, ref: ForwardedRef<BaseModalHandler>) => {
  const modalRef = React.useRef<BaseModalHandler>(null);

  const handleShow = () => {
    modalRef.current?.show();
  };

  const handleHide = () => {
    modalRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleMedicalInfoSubmit = () => {
    props.changeViewMode(false);

    const { allergies, prefers_easy_open_bottle_caps, medical_conditions } =
      props.form.getValues();

    props.handleSave({
      allergies,
      prefers_easy_open_bottle_caps,
      medical_conditions,
    });
  };

  return (
    <>
      <GenericModal
        title={props.title}
        buttons={[
          !props.viewMode
            ? {
                text: getText('remove-patient'),
                hierarchy: 'destructive',
                onPress: () => props.handleRemovePatientUnderCare(props.record),
                logger: { id: 'remove-patient-access-modal-send-button' },
              }
            : {
                text: getText('save'),
                hierarchy: 'primary',
                onPress: props.form.handleSubmit(props.handleSubmit),
                logger: { id: 'save-patient-access-modal-send-button' },
              },
        ]}
        isScrollable={true}
        ref={modalRef}
      >
        <View>
          <View>
            {!props.viewMode ? (
              <View>
                <PersonalInformationComponent
                  patientRecord={props.patientRecord}
                  onEditPress={() => {
                    props.onEditPress(
                      PatientUnderCareEditDetailsEnum.PersonalInfo,
                      props.form,
                    );
                  }}
                  title={props.subtitle}
                  record={props.record}
                ></PersonalInformationComponent>
                <MedicalInformation
                  patientRecord={props.patientRecord}
                  onEditPress={() => {
                    props.onEditPress(
                      PatientUnderCareEditDetailsEnum.MedicalInfo,
                      props.form,
                    );
                  }}
                ></MedicalInformation>
              </View>
            ) : PatientUnderCareEditDetailsEnum.PersonalInfo ===
              props.editDetails ? (
              <EditPersonalInformation
                changeViewMode={props.changeViewMode}
                relationshipProps={props.relationshipProps}
                handleSave={props.handleSave}
                patientRecord={props.patientRecord}
                record={props.record}
                form={props.form}
                mode={EditPersonalInformationMode.PersonUnderCare}
              ></EditPersonalInformation>
            ) : PatientUnderCareEditDetailsEnum.MedicalInfo ===
              props.editDetails ? (
              <EditMedicalInformation
                patientRecord={props.patientRecord}
                changeViewMode={props.changeViewMode}
                handleSave={props.handleSave}
                record={props.record}
                form={props.form}
              ></EditMedicalInformation>
            ) : (
              // TODO Insurance and Id info will come here - out of scope for BLUES-2677
              <></>
            )}
          </View>
        </View>
      </GenericModal>
    </>
  );
};

interface PersonalInfoModalProps {
  handleRemovePatientUnderCare: (record?: RecordUnderCareDto) => void;
  record?: RecordUnderCareDto;
  patientRecord: PatientRecordDto;
  changeViewMode: (val: boolean) => void;
  viewMode: boolean;
  relationshipProps?: Array<{ label: string; value: string }>;
  handleSave: (updatePR: UpdatePatientRecordDto) => void;
  handleSubmit: () => void;
  onEditPress: (
    editDetail: PatientUnderCareEditDetailsEnum,
    methods: UseFormReturn<PatientUnderCareForm>,
  ) => void;
  whichDetailsToEdit: (
    editType: PatientUnderCareEditDetailsEnum.PersonalInfo,
  ) => void;
  editDetails: PatientUnderCareEditDetailsEnum;
  form: UseFormReturn<PatientUnderCareForm>;
  title: string;
  subtitle: string;
}

export const PersonalInfoMdl = React.forwardRef<
  BaseModalHandler,
  PersonalInfoModalProps
>(PersonalInfoModal);
