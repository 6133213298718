import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, Platform } from 'react-native';
import { Button } from 'assets/components/button';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';

/*
 * Show a message to the user that they denied camera access and need to update their system settings
 */
export const CameraAccessDenied: FunctionComponent<
  PropsWithChildren<CameraAccessDeniedProps>
> = ({ onSettingsPress, onBackPress }) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <Text>{getText('camera-access-denied')}</Text>
      {Platform.OS !== 'web' && (
        <Button
          onPress={onSettingsPress}
          hierarchy={'primary'}
          size="medium"
          logger={{ id: 'camera-access-denied-button' }}
          style={styles.settingsButton}
        >
          {getText('open-settings')}
        </Button>
      )}
      <Button
        onPress={onBackPress}
        hierarchy={'tertiary'}
        size="medium"
        logger={{ id: 'camera-access-denied-button' }}
      >
        {getText('back')}
      </Button>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: theme.getSpacing(1),
  },
  settingsButton: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
  },
}));

interface CameraAccessDeniedProps {
  onBackPress: () => void;
  onSettingsPress?: () => void;
}
