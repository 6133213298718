import type { StackNavigationProp } from '@react-navigation/stack';
import {
  StackHeaderProps,
  StackScreenProps,
  createStackNavigator,
} from '@react-navigation/stack';
import { GradientView } from 'assets/components/gradient-view';
import { NativeHeader } from 'assets/components/native-header';
import { ChevronLeftIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { GestureResponderEvent } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { IconButton, Text } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Logo } from '../components/logo/Logo';
import { Intro } from '../screens/intro/Intro';
import { AccountNotFound } from '../screens/login/AccountNotFound';
import { Login } from '../screens/login/Login';
import { LoginAttemptsExceeded } from '../screens/login/LoginAttemptsExceeded';
import { LoginEnterEmail } from '../screens/login/LoginEnterEmail';
import { CreatePassword } from '../screens/register/CreatePassword';
import { RegisterConfirmation } from '../screens/register/RegisterConfirmation';
import { ResetLink } from '../screens/reset-password/ResetLink';
import { ResetLinkSent } from '../screens/reset-password/ResetLinkSent';
import { ResetPassword } from '../screens/reset-password/ResetPassword';
import { ResetSuccessful } from '../screens/reset-password/ResetSuccessful';
import { ResetUnsuccessful } from '../screens/reset-password/ResetUnsuccessful';
import { UpdateWeakPassword } from '../screens/reset-password/UpdateWeakPassword';

const Stack = createStackNavigator<LoginStackParamList>();

export const LoginNavigation: FunctionComponent = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: LoginNavigationHeader,
        title: getText('app'), // fallback title
      }}
    >
      <Stack.Screen
        name="intro"
        component={Intro}
        options={{ title: getText('intro') }}
      />
      <Stack.Screen
        name="login-enter-email"
        component={LoginEnterEmail}
        options={{ title: getText('login') }}
      />
      <Stack.Screen
        name="login"
        component={Login}
        options={{ title: getText('login') }}
      />
      <Stack.Screen
        name="account-not-found"
        component={AccountNotFound}
        options={{ title: getText('account-not-found-heading') }}
      />
      <Stack.Screen
        name="login-attempts-exceeded"
        component={LoginAttemptsExceeded}
        options={{ title: getText('login-attempts-exceeded') }}
      />
      <Stack.Screen
        name="create-password"
        component={CreatePassword}
        options={{ title: getText('create-password') }}
      />
      <Stack.Screen
        name="register-confirmation"
        component={RegisterConfirmation}
        options={{ title: getText('register-confirmation') }}
      />
      <Stack.Screen
        name="reset-link"
        component={ResetLink}
        options={{ title: getText('reset-link') }}
      />
      <Stack.Screen
        name="reset-link-sent"
        component={ResetLinkSent}
        options={{ title: getText('reset-link-sent') }}
      />
      <Stack.Screen
        name="reset-unsuccessful"
        component={ResetUnsuccessful}
        options={{ title: getText('reset-unsuccessful') }}
      />
      <Stack.Screen
        name="reset-successful"
        component={ResetSuccessful}
        options={{ title: getText('reset-successful') }}
      />
      <Stack.Screen
        name="reset-password"
        component={ResetPassword}
        options={{ title: getText('reset-password') }}
      />
      <Stack.Screen
        name="update-weak-password"
        component={UpdateWeakPassword}
        options={{ title: getText('update-weak-password') }}
      />
    </Stack.Navigator>
  );
};

export type LoginStackParamList = {
  intro: undefined;
  login: undefined;
  'login-enter-email': undefined;
  'create-password': CreatePasswordParamsProps;
  'register-confirmation': undefined;
  'login-attempts-exceeded': undefined;
  'privacy-policy': undefined;
  'terms-of-service': undefined;
  'reset-password': ResetPasswordParamsProps;
  'update-weak-password': UpdateWeakPasswordParamsProps;
  'reset-link': undefined;
  'reset-link-sent': undefined;
  'reset-successful': undefined;
  'reset-unsuccessful': undefined;
  'account-not-found': AccountNotFoundParamsProps | undefined;
};

export type LoginStackNavigationProp = StackNavigationProp<LoginStackParamList>;

export interface LoginNavigationProps extends StackHeaderProps {
  showBack?: boolean;
  routeName?: string;
}

export const LoginNavigationHeader: FunctionComponent<
  PropsWithChildren<LoginNavigationProps>
> = (props) => {
  const theme = useTheme();
  const routeName = props.route.name as keyof LoginStackParamList;

  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  if (
    routeName === 'intro' ||
    routeName === 'login' ||
    routeName === 'login-enter-email'
  ) {
    return <></>;
  }

  return (
    <>
      <SafeAreaView
        style={{
          position: 'absolute',
          left: 0,
          zIndex: 1,
        }}
      >
        {props.showBack && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onPress={() =>
              props.navigation.navigate(
                props.routeName as keyof LoginStackParamList,
              )
            }
          >
            <IconButton
              icon={ChevronLeftIcon}
              color={theme.colors.brandedPrimary}
              size={24}
            />

            <Text
              style={{
                color: theme.colors.brandedPrimary,
                fontSize: 16,
                fontWeight: '500',
                lineHeight: 24,
              }}
            >
              {getText('back')}
            </Text>
          </TouchableOpacity>
        )}
      </SafeAreaView>

      <GradientView
        contentStyle={{ justifyContent: 'center', alignItems: 'center' }}
        fillGradientStops={[
          { stopColor: '#F5F9FD', offset: 0 },
          { stopColor: '#F5F9FD', offset: 100 },
        ]}
        contentHeight={200}
      >
        <SafeAreaView>
          <Logo width={222} height={100} isOnDark={false} />
        </SafeAreaView>
      </GradientView>
    </>
  );
};

export interface AccountSetupNavigationProps extends StackHeaderProps {
  showBack?: boolean;
}

export const AccountSetupNavigationHeader: FunctionComponent<
  AccountSetupNavigationProps
> = (props) => {
  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  return (
    <NativeHeader
      title={getText('account-setup')}
      onBack={handleBack}
      showBack={props.showBack}
    />
  );
};

export type CreatePasswordProps = StackScreenProps<
  LoginStackParamList,
  'create-password'
>;

export type ResetPasswordProps = StackScreenProps<
  LoginStackParamList,
  'reset-password'
>;

export type UpdateWeakPasswordProps = StackScreenProps<
  LoginStackParamList,
  'update-weak-password'
>;

export type AccountNotFoundProps = StackScreenProps<
  LoginStackParamList,
  'account-not-found'
>;

export type CreatePasswordParamsProps = {
  temporaryUserRegistrationId: string;
};

export type ResetPasswordParamsProps = {
  email: string;
  confirmation_code: string;
  reset_password_link_id: string;
};

export type UpdateWeakPasswordParamsProps = ResetPasswordParamsProps;

export type AccountNotFoundParamsProps = {
  isSSO?: boolean;
};
