import React from 'react';
import { AppRedirectionContext, AppRedirectionContextType } from '../context';
import { AppRedirectionConfig } from '../types';

export function useAppRedirectionContext(): AppRedirectionContextType {
  return React.useContext(AppRedirectionContext);
}

export function useAppRedirection(): AppRedirectionConfig {
  const { appRedirectionConfig } = useAppRedirectionContext();
  return appRedirectionConfig;
}
