import { AddPrescriptionDto } from '@digitalpharmacist/patient-service-client-axios';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import axios from 'axios';
import patientService from '../../api/patient-service';
import { useUserState } from '../../store/user-store';
import {
  getLocationPatientRecordIdFromPUC,
  refreshCaregiverRequestsState,
  refreshPatientRecordState,
} from '../account/patient/patient-actions';
import refillService from '../refill/refill-service';
import { useRefillMedicationsStore } from '../refill/refill-store';
import {
  defaultAddMedicationsState,
  useAddMedicationsStore,
} from './add-medications-store';
import { isLocationIntegrated } from '../../actions/app-actions';

export const patientRecordAddPrescription = async (
  addPrescription: AddPrescriptionDto,
  navigation: NavigationProp<ParamListBase>,
) => {
  const patientRecordId = useAddMedicationsStore.getState().patientRecord?.id;
  const userPatientRecordId = useUserState.getState().user?.patientRecordId;

  if (!patientRecordId) {
    return logError(new Error('PatientRecord missing'));
  }

  if (!userPatientRecordId) {
    return logError(new Error('User patientRecordId is undefined'));
  }

  try {
    const patientResponse = await patientService.patientRecordAddPrescription(
      patientRecordId,
      {
        location_id: addPrescription.location_id,
        rx_number: addPrescription.rx_number,
        last_name: addPrescription.last_name,
        date_of_birth: addPrescription.date_of_birth,
      },
    );

    const createdLocationPatientRecordId =
      patientResponse.location_patient_records.find(
        (lpr) => lpr.location_id === addPrescription.location_id,
      )?.id;

    if (!createdLocationPatientRecordId) {
      useAddMedicationsStore.setState({
        status: 'error',
        error: {
          message: getText(
            'unable-to-locate-your-prescription-alert-description',
          ),
        },
      });
      return;
    }

    useAddMedicationsStore.setState({
      status: 'idle',
      error: undefined,
      patientRecord: patientResponse,
      locationPatientRecordId: createdLocationPatientRecordId,
    });

    if (userPatientRecordId === patientRecordId) {
      void refreshPatientRecordState(userPatientRecordId);
    }

    void refreshCaregiverRequestsState();

    navigation.navigate('list-found-medications');
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      (error.response?.status === 400 || error.response?.status === 404)
    ) {
      useAddMedicationsStore.setState({
        status: 'error',
        error: {
          message: getText(
            'unable-to-locate-your-prescription-alert-description',
          ),
        },
      });
    } else {
      useAddMedicationsStore.setState({
        status: 'error',
        error: {
          message: getText(
            'prescription-entered-already-exist-contact-pharmacy',
          ),
        },
      });
    }
  }
};

export const loadPatientRecord = async (patientRecordId: string) => {
  const locationId = useUserState.getState().user?.preferredPharmacyLocationId;

  useAddMedicationsStore.setState({ status: 'loading' });

  try {
    const patientRecord =
      await patientService.findPatientRecord(patientRecordId);

    const locationPatientRecordId = patientRecord.location_patient_records.find(
      (p) => p.location_id === locationId,
    )?.id;

    useAddMedicationsStore.setState({
      status: 'idle',
      patientRecord: patientRecord,
      locationPatientRecordId,
    });
  } catch (e) {
    useAddMedicationsStore.setState({
      status: 'error',
      error: {
        message: getText('error-loading-patient-record'),
      },
    });
  }
};

export const resetAddPrescriptionState = () => {
  useAddMedicationsStore.setState(defaultAddMedicationsState);
};

export const loadPrescriptions = async () => {
  const { locationPatientRecordId } = useAddMedicationsStore.getState();
  const locationId = useUserState.getState().user?.preferredPharmacyLocationId;

  if (!locationId) return logError(new Error('Missing Location'));

  if (!locationPatientRecordId)
    return logError(new Error('Missing Location Patient Record'));

  const prescriptions = await refillService.getPatientPrescriptions(
    locationId,
    locationPatientRecordId,
  );

  useRefillMedicationsStore.setState({
    allMedications: prescriptions.map((prx) => ({
      ...prx,
      checked: false,
    })),
  });
  useAddMedicationsStore.setState({
    prescriptions: prescriptions,
  });
};

export const openAddMedicationManually = async (
  patientRecordId: string,
  navigation: StackNavigationProp<ParamListBase, string, undefined>,
  originated: 'medication' | 'onboarding' = 'medication',
) => {
  // when the location is not integrated we check for lpr existence
  const locationPatientRecordId =
    await ensureLocationPatientRecordExist(patientRecordId);

  if (!locationPatientRecordId) {
    return logError(new Error('LocationPatientRecordId is undefined'));
  }

  useAddMedicationsStore.setState({
    originated: originated,
    locationPatientRecordId: locationPatientRecordId,
    prescriptions: useRefillMedicationsStore.getState().allMedications || [],
  });

  await loadPatientRecord(patientRecordId);

  if (originated === 'medication') {
    navigation.navigate('add-medications', {
      screen: 'enter-medications',
    });
  }
  if (originated === 'onboarding') {
    navigation.navigate('enter-medications');
  }
};

export const openAddMedicationIntegrated = async (
  patientRecordId: string,
  navigation: StackNavigationProp<ParamListBase, string, undefined>,
  originated: 'medication' | 'onboarding' = 'medication',
) => {
  useAddMedicationsStore.setState({
    originated: originated,
    locationPatientRecordId: undefined,
    prescriptions: [],
  });
  // this will populate the useAddPrescriptionStore with data
  await loadPatientRecord(patientRecordId);

  if (originated === 'medication') {
    navigation.navigate('add-medications', {
      screen: 'find-medications',
    });
  }
  if (originated === 'onboarding') {
    navigation.navigate('find-medications');
  }
};

export const openAddMedications = async (
  patientRecordId: string,
  navigation: StackNavigationProp<ParamListBase, string, undefined>,
  originated: 'medication' | 'onboarding' = 'medication',
) => {
  const locationId = useUserState.getState().user?.preferredPharmacyLocationId;
  const isIntegrated = isLocationIntegrated();

  if (!locationId) {
    return logError(new Error('User preferredPharmacyLocationId is undefined'));
  }

  if (!patientRecordId) {
    return logError(new Error('Inputted patientRecordId is undefined'));
  }

  // Integrated (find rx in pms)
  if (isIntegrated) {
    await openAddMedicationIntegrated(patientRecordId, navigation, originated);
  }
  // Manually (enter rx manually)
  else {
    await openAddMedicationManually(patientRecordId, navigation, originated);
  }
};

export const ensureLocationPatientRecordExist = async (
  patientRecordId: string,
) => {
  const locationId = useUserState.getState().user?.preferredPharmacyLocationId;
  const userPatientRecordId = useUserState.getState().user?.patientRecordId;

  if (!locationId) {
    return logError(new Error('User preferredPharmacyLocationId is undefined'));
  }

  if (!userPatientRecordId) {
    return logError(new Error('User patientRecordId is undefined'));
  }

  const locationPatientRecordId =
    getLocationPatientRecordIdFromPUC(patientRecordId);

  if (locationPatientRecordId) {
    return locationPatientRecordId;
  }

  // LPR is missing try to create it
  const patientRecord =
    await patientService.patientRecordInitLocationPatientRecord(
      patientRecordId,
      { location_id: locationId },
    );

  const createdLprId = patientRecord.location_patient_records.find(
    (lpr) => lpr.location_id === locationId,
  )?.id;

  if (!createdLprId) {
    return logError(new Error('Error InitLocationPatientRecord on service'));
  }

  // If we are creating lpr in user then refresh user record
  if (userPatientRecordId === patientRecordId) {
    await refreshPatientRecordState(userPatientRecordId);
  }

  await refreshCaregiverRequestsState();

  return createdLprId;
};

export const setFindMedsScreenAsSeen = async () => {
  const { updateUser } = useUserState.getState();

  return updateUser({
    hasSeenFindMedsScreen: true,
  });
};
