import React, {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  BottomSheetScrollView,
  BottomSheetView,
  BottomSheetModal,
  BottomSheetFooter,
  BottomSheetFooterProps,
  BottomSheetBackdrop,
} from '@gorhom/bottom-sheet';
import { PlatformType } from '../types';
import { Platform, ViewStyle, Keyboard } from 'react-native';
import theme, { makeStyles } from '../../theme';
import { Text } from '../text';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useWindowDimensions } from 'react-native';
import {
  BottomSheetBackdropProps,
  BackdropPressBehavior,
} from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetBackdrop/types';

export const BottomSheet: FunctionComponent<
  PropsWithChildren<BottomSheetProps>
> = ({
  children,
  platform = Platform.OS,
  height = '40%',
  bottomSheetRef,
  onDismiss,
  enablePanDownToClose = true,
  title,
  headerContent,
  headerContentStyle,
  footerContent,
  footerContentStyle,
  contentContainerStyle,
  hideHandle = false,
  backdropPressBehavior,
  enableOverDrag,
}) => {
  const styles = useStyles();

  const insets = useSafeAreaInsets();
  const { height: windowHeight } = useWindowDimensions();
  const bottomInsetPercent = (insets.bottom / windowHeight) * 100;

  const convertHeight = (value: string) => {
    const heightAsNumber = Number(value.replace('%', ''));
    return heightAsNumber + bottomInsetPercent + '%';
  };

  const snapPoints = useMemo(
    () =>
      height && Array.isArray(height)
        ? height.map(convertHeight)
        : [convertHeight(height)],
    [height],
  );

  const footerComponentMemo = useMemo(() => {
    if (footerContent)
      return (props: BottomSheetFooterProps) => (
        <BottomSheetFooter
          style={{ ...styles.containerPaddings, ...footerContentStyle }}
          {...props}
        >
          <SafeAreaView edges={['bottom']}>{footerContent}</SafeAreaView>
        </BottomSheetFooter>
      );
  }, [footerContent]);

  const renderBackdrop = useMemo(() => {
    return (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        opacity={0.5}
        enableTouchThrough={false}
        appearsOnIndex={0}
        disappearsOnIndex={-1}
        style={[{ backgroundColor: theme.colors.backdrop }]}
        pressBehavior={backdropPressBehavior}
        {...props}
      />
    );
  }, []);

  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      (e) => setKeyboardHeight(e.endCoordinates.height),
    );

    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => setKeyboardHeight(0),
    );

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  return platform !== 'web' ? (
    <BottomSheetModal
      index={0}
      style={styles.root}
      ref={bottomSheetRef}
      snapPoints={snapPoints}
      enablePanDownToClose={enablePanDownToClose}
      onDismiss={onDismiss}
      footerComponent={footerComponentMemo}
      android_keyboardInputMode={
        platform === 'android' ? 'adjustResize' : undefined
      }
      keyboardBehavior={platform === 'ios' ? 'fillParent' : undefined}
      keyboardBlurBehavior={platform === 'ios' ? 'restore' : undefined}
      topInset={insets.top}
      handleComponent={hideHandle ? null : undefined}
      backdropComponent={renderBackdrop}
      handleIndicatorStyle={{ display: 'none' }}
      enableOverDrag={enableOverDrag}
    >
      <SafeAreaView style={{ flex: 1 }} edges={['bottom']}>
        {title && (
          <BottomSheetView
            testID={BottomSheetTestIDs.titleContent}
            style={[styles.containerPaddings]}
          >
            <Text style={styles.title}>{title}</Text>
          </BottomSheetView>
        )}

        {headerContent && (
          <BottomSheetView
            testID={BottomSheetTestIDs.headerContent}
            style={[styles.containerPaddings, headerContentStyle]}
          >
            {headerContent}
          </BottomSheetView>
        )}
        {backdropPressBehavior === 'close' ? (
          <BottomSheetView
            testID={BottomSheetTestIDs.content}
            style={[styles.containerPaddings]}
            enableFooterMarginAdjustment
          >
            {children}
          </BottomSheetView>
        ) : (
          <BottomSheetScrollView
            testID={BottomSheetTestIDs.content}
            style={[styles.containerPaddings]}
            enableFooterMarginAdjustment
            contentContainerStyle={[
              contentContainerStyle,
              { paddingBottom: Platform.OS === 'ios' ? keyboardHeight : 0 },
            ]}
          >
            {children}
          </BottomSheetScrollView>
        )}
      </SafeAreaView>
    </BottomSheetModal>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    shadowColor: theme.colors.backdrop,
    shadowOpacity: 0.1,
  },
  title: {
    alignSelf: 'center',
    fontSize: 18,
    fontWeight: '600',
    fontFamily: 'Lato_700Bold',
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  containerPaddings: {
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
}));
export interface BottomSheetProps {
  platform?: PlatformType;
  height?: string | string[];
  bottomSheetRef?: React.RefObject<BottomSheetModal>;
  onDismiss?: () => void;
  title?: string;
  footerContent?: React.ReactNode;
  footerContentStyle?: ViewStyle;
  headerContent?: React.ReactNode;
  headerContentStyle?: ViewStyle;
  contentContainerStyle?: ViewStyle;
  hideHandle?: boolean;
  enablePanDownToClose?: boolean;
  backdropPressBehavior?: BackdropPressBehavior;
  enableOverDrag?: boolean;
}

export const BottomSheetTestIDs = {
  content: 'bottom-sheet-content',
  noScrollContent: 'bottom-sheet-no-scroll-content',
  titleContent: 'bottom-sheet-title-content',
  headerContent: 'bottom-sheet-header-content',
};
