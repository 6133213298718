import { FunctionComponent, PropsWithChildren } from 'react';
import theme, { makeStyles } from 'assets/theme';
import { Form, InternalScreenContainer } from 'assets/layout';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { getText } from 'assets/localization/localization';
import { TransferMedsTestIDs } from './TransferMedsTestIDs';
import { Divider } from 'react-native-paper';
import {
  resetTransferMedsState,
  useTransferMedicationsStore,
} from './transfer-meds-store';
import { Alert } from 'assets/components/alert';
import { useForm } from 'react-hook-form';
import { useUserState } from '../../store/user-store';
import { StackHeaderProps } from '@react-navigation/stack';
import {
  ensureLocationPatientRecordExist,
  setFindMedsScreenAsSeen,
} from '../add-medications/add-medications-actions';
import PhoneNumber from '../../components/phone-number/PhoneNumber';

export const TransferMedsSuccess: FunctionComponent<
  PropsWithChildren<TransferMedicationsNavigationProp>
> = ({ navigation }) => {
  const styles = useStyles();
  const { user } = useUserState();
  const {
    status,
    pucFullName,
    pharmacyName,
    allMedications,
    pharmacyPhoneNumber,
    fillPrescription,
    originated,
    medications,
    locationPatientRecord,
  } = useTransferMedicationsStore();

  const methods = useForm({
    defaultValues: {
      fillPrescriptions: true,
    },
  });

  const handleOnPressDone = async () => {
    if (originated === 'onboarding') {
      navigation.navigate('app', {
        screen: 'home',
      });
      await setFindMedsScreenAsSeen();
      await ensureLocationPatientRecordExist(user?.patientRecordId!);
    } else {
      navigation.navigate('app', {
        screen: 'medications',
      });
    }
    resetTransferMedsState();
  };

  const handleOnPressTransferAnother = () => {
    if (originated === 'onboarding') {
      navigation.navigate('transfer-medications', {
        screen: 'single-location',
      });
      useTransferMedicationsStore.setState({
        originated: 'onboarding',
        allMedications: false,
        transferLocation: undefined,
        medications: [],
        fillPrescription: true,
        pharmacyName: '',
        pharmacyPhoneNumber: '',
      });
    } else {
      navigation.navigate('transfer-medications', {
        screen: 'multiple-locations',
      });
      useTransferMedicationsStore.setState({
        originated: 'medication',
        allMedications: false,
        transferLocation: undefined,
        medications: [],
        fillPrescription: true,
        pharmacyName: '',
        pharmacyPhoneNumber: '',
      });
    }
  };

  return (
    <InternalScreenContainer
      title={getText('transfer-meds-header-title')}
      buttons={[
        {
          hierarchy: 'secondary',
          onPress: methods.handleSubmit(handleOnPressTransferAnother),
          testID: TransferMedsTestIDs.transferAnotherButton,
          logger: { id: TransferMedsTestIDs.transferAnotherButton },
          text: getText('transfer-another'),
        },
        {
          hierarchy: 'primary',
          onPress: methods.handleSubmit(handleOnPressDone),
          testID: TransferMedsTestIDs.doneButton,
          logger: { id: TransferMedsTestIDs.doneButton },
          text: getText('done'),
        },
      ]}
    >
      {status === 'error' ? (
        <Alert
          title={getText('error-occurred-title')}
          description={getText('error-occurred-description')}
          intent="error"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      ) : (
        <Alert
          title={
            fillPrescription
              ? getText('your-transfer-request-has-been-submitted-and-filled')
              : getText('your-transfer-request-has-been-submitted')
          }
          intent="success"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      )}

      <Form methods={methods}>
        <Form.Alert
          visible={!!methods.getFieldState('fillPrescriptions').error}
          title={getText('unable-to-process-complete-required-fields')}
          intent="error"
        />
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>{getText('person')}</Text>
          </Form.Column>
        </Form.Row>
        <View>
          <Divider />
        </View>
        <Form.Row>
          <Form.Column>
            <Text style={styles.data}>
              {user?.patientRecordId ===
              locationPatientRecord?.patient_record_id
                ? getText('myself')
                : originated === 'onboarding'
                ? getText('myself')
                : locationPatientRecord?.first_name &&
                  locationPatientRecord.last_name
                ? `${locationPatientRecord.first_name} ${locationPatientRecord.last_name}`
                : pucFullName}
            </Text>
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>{getText('medications')}</Text>
          </Form.Column>
        </Form.Row>
        <View>
          <Divider />
        </View>
        <Form.Row>
          <Form.Column>
            {allMedications ? (
              <Text style={styles.data}>{getText('all-medications')}</Text>
            ) : (
              medications &&
              medications.map((item, index) => (
                <Text style={styles.data} key={index}>
                  {item.drug_name}
                </Text>
              ))
            )}
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>{getText('pharmacy')}</Text>
          </Form.Column>
        </Form.Row>
        <View>
          <Divider />
        </View>
        <Form.Row>
          <Form.Column>
            <Text style={styles.data}>{pharmacyName}</Text>
            <View style={styles.rowView}>
              <Text style={styles.data}>
                <PhoneNumber
                  mode="link"
                  phoneNumber={pharmacyPhoneNumber!}
                  showIcon={false}
                />
              </Text>
            </View>
          </Form.Column>
        </Form.Row>
      </Form>
    </InternalScreenContainer>
  );
};

export type TransferMedicationsNavigationProp = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
  changeText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
    flex: 1,
    flexDirection: 'column-reverse',
  },
  data: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(0.5),
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(2),
    marginTop: theme.getSpacing(1),
  },
}));
