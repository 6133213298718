import React, { PropsWithChildren, useImperativeHandle } from 'react';
import { ForwardRefRenderFunction, ForwardedRef } from 'react';
import { Platform, ViewStyle } from 'react-native';
import { ModalButtonProps, ModalProps } from 'assets/components/modal';
import { ButtonHierarchy } from 'assets/components/button';
import {
  NativeButtonOrientation,
  NativeModal,
  NativeModalProps,
} from 'assets/components/base-modal/NativeModal';
import { WebModal } from 'assets/components/base-modal/WebModal';
import { BackdropPressBehavior } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetBackdrop/types';
import { GenericModalButtonProps } from '../generic-modal/GenericModal';
import { getText } from '../../localization/localization';
import { BottomSheetProps } from '../bottom-sheet';

export const BaseModalView: ForwardRefRenderFunction<
  BaseModalHandler,
  BaseModalProps
> = (
  {
    title,
    children,
    showDismissButton = true,
    nativeHeight,
    isScrollable,
    leftAlignedButton,
    nativeButtonOrientation,
    webModalProps,
    webModalSize,
    nativeModalProps,
    nativeModalBodyStyle,
    buttons = [],
    onClose,
    backdropPressBehavior,
    scrollToTop,
    testID,
  },
  ref: ForwardedRef<BaseModalHandler>,
) => {
  const modalRef = React.useRef<BaseModalHandler>(null);

  const handleShow = () => {
    modalRef.current?.show();
  };

  const handleHide = () => {
    modalRef.current?.hide();
    if (onClose) onClose();
  };

  // Initializing webButtons with a default close button if buttons array is empty
  const closeButton: GenericModalButtonProps = {
    hierarchy: 'secondary-gray',
    onPress: handleHide,
    logger: { id: 'close-generic-modal-button' },
    text: getText('close'),
  };
  const webButtons = buttons.length > 0 ? buttons : [closeButton];

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  if (webModalSize === 'lg') {
    webModalProps = {
      ...webModalProps,
      height: 800,
      contentContainerStyle: { flex: 1 },
      scrollViewStyle: { flex: 1 },
    };
  }

  return Platform.OS === 'web' ? (
    <WebModal
      onClose={onClose}
      ref={modalRef}
      title={title}
      children={children}
      isScrollable={isScrollable}
      showDismissButton={showDismissButton}
      leftAlignedButton={leftAlignedButton}
      buttons={webButtons}
      size={webModalSize || 'sm'}
      modalProps={webModalProps}
      scrollToTop={scrollToTop}
    />
  ) : (
    <NativeModal
      onClose={onClose}
      ref={modalRef}
      title={title}
      children={children}
      height={nativeHeight}
      showDismissButton={showDismissButton}
      buttonOrientation={nativeButtonOrientation}
      buttons={leftAlignedButton ? [...buttons, leftAlignedButton] : buttons}
      allowSwipe={false}
      backdropPressBehavior={
        backdropPressBehavior ? backdropPressBehavior : 'none'
      }
      enableOverDrag={backdropPressBehavior ? false : true}
      bodySheetProps={nativeModalProps}
      scrollToTop={scrollToTop}
      contentContainerStyles={nativeModalBodyStyle}
      testID={testID}
    />
  );
};

/**
 * Manually composed from {@link WebModalProps} and {@link NativeModalProps} so we intentionally choose what is supported
 * at each layer
 * */
export type BaseModalProps = PropsWithChildren<{
  title: string;
  ref?: ForwardedRef<BaseModalHandler>;
  buttons?: BaseModalButtonProps[];
  isScrollable?: boolean;
  showDismissButton?: boolean;
  scrollToTop?: boolean;

  // web only
  leftAlignedButton?: BaseModalButtonProps;
  webModalSize?: 'sm' | 'lg';
  webModalProps?: Pick<
    ModalProps,
    'height' | 'contentContainerStyle' | 'scrollViewStyle'
  >;

  // native only
  nativeButtonOrientation?: NativeButtonOrientation;
  nativeHeight?: string;
  nativeModalProps?: Pick<BottomSheetProps, 'contentContainerStyle'>;
  nativeModalBodyStyle?: ViewStyle;

  //bottomsheet
  backdropPressBehavior?: BackdropPressBehavior;
  enableOverDrag?: boolean;

  onClose?: () => void;
  testID: string;
}>;

// Currently, ModalButtonProps extends ButtonProps to add text (optional) and make hierarchy optional
// Yes, this is silly to undo those changes instead of extending ButtonProps directly, but this way
// ensures these props continue to match ModalButtonProps since they are passed directly into the next layer.
// This should reduce room for bugs until these layers are collapsed and simplified.
export interface BaseModalButtonProps
  extends Omit<ModalButtonProps, 'hierarchy' | 'text'> {
  text: string;
  hierarchy: ButtonHierarchy;
}

export interface BaseModalHandler {
  show: () => void;
  hide: () => void;
}

export const BaseModal = React.forwardRef<BaseModalHandler, BaseModalProps>(
  BaseModalView,
);
