import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { InternalScreenContainer } from 'assets/layout';
import { Platform, useWindowDimensions } from 'react-native';
import { useTransferMedicationsStore } from './transfer-meds-store';
import { useUserState } from '../../store/user-store';
import { getText } from 'assets/localization/localization';
import { TransferMedsTestIDs } from './TransferMedsTestIDs';
import { useAppStateStore } from '../../store/app-store';
import {
  StoreSelectorItemHandler,
  StoreSelectorListHandler,
} from '../../components/store-selector/types';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { StoreSelectorList } from '../../components/store-selector/list';
import { StoreSelectorInput } from '../../components/store-selector/input/StoreSelectorInput';
import { handleLocationDistanceSearch } from '../../components/store-selector/actions';
import { ScrollView } from 'react-native-gesture-handler';
import { StackHeaderProps } from '@react-navigation/stack';
import { StoreSelectorInformationModal } from '../../components/store-selector/StoreSelectorInformationModal';
import {
  currentDayOpenHours,
  getDayAsStr,
} from '../../components/store-selector/utils';
import { StoreSelectorInformationBottomSheet } from '../../components/store-selector/StoreSelectorInformationBottomSheet';
import { loadStores } from '../../actions/app-actions';

export const SelectLocation: FunctionComponent<
  PropsWithChildren<TransferMedicationsNavigationProp>
> = ({ navigation }) => {
  const styles = useStyles();
  const theme = useTheme();
  const storeSelectorRef = useRef<StoreSelectorListHandler>(null);
  const storeSelectorInformationRef = useRef<StoreSelectorItemHandler>(null);

  const { stores } = useAppStateStore();
  const [inputValue, setInputValue] = useState('');
  const { user } = useUserState();
  const { userCanChose, updateTransferMedsData } =
    useTransferMedicationsStore();

  useEffect(() => {
    if (stores.length < 1) void loadStores();
    const selectedStore = stores.find(
      (x) => x.id === user?.preferredPharmacyLocationId,
    );
    if (!updateTransferMedsData || !selectedStore) return;

    updateTransferMedsData({
      pharmacyName: selectedStore.name,
      pharmacyPhoneNumber: selectedStore.phone,
    });
  }, []);

  const handleOnPress = () => {
    if (userCanChose) {
      navigation.navigate('transfer-medications', {
        screen: 'about-transfer',
      });
    } else {
      navigation.navigate('transfer-medications', {
        screen: 'enter-medications',
      });
    }
  };

  const handleFromAnotherPharmacy = () => {
    if (!updateTransferMedsData) return;
    useTransferMedicationsStore.setState({
      originated: 'another-pharmacy',
    });
    navigation.navigate('transfer-medications', {
      screen: 'single-location',
    });
  };

  const handleOnLocationChanged = (value: PharmacyLocationDto) => {
    if (!updateTransferMedsData) return;
    updateTransferMedsData({
      transferLocation: value.id,
      pharmacyName: value.name,
      pharmacyPhoneNumber: value.phone?.replace(/-/g, ''),
    });
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  return (
    <InternalScreenContainer
      buttons={[
        {
          hierarchy: 'tertiary',
          onPress: handleFromAnotherPharmacy,
          testID: TransferMedsTestIDs.reviewButton,
          logger: { id: TransferMedsTestIDs.reviewButton },
          text: getText('from-different-pharmacy'),
        },
        {
          hierarchy: 'primary',
          onPress: handleOnPress,
          testID: TransferMedsTestIDs.reviewButton,
          logger: { id: TransferMedsTestIDs.reviewButton },
          text: getText('review'),
        },
      ]}
    >
      <ScrollView style={styles.scrollView}>
        <StoreSelectorInput onInputChange={handleInputChange} />

        <StoreSelectorList
          options={stores}
          selectedOption={stores.find(
            (x) => x.id === user?.preferredPharmacyLocationId,
          )}
          searchCriteria={inputValue}
          onChange={handleOnLocationChanged}
          searchAction={handleLocationDistanceSearch}
          onInfoButtonPress={(item) =>
            storeSelectorInformationRef.current?.show(item)
          }
          ref={storeSelectorRef}
        />
      </ScrollView>

      <StoreSelectorInformationModal
        ref={storeSelectorInformationRef}
        currentDayOpenHours={currentDayOpenHours}
        daysAsStr={getDayAsStr}
      />
    </InternalScreenContainer>
  );
};

export type TransferMedicationsNavigationProp = StackHeaderProps;

const calcScrollViewHeight = () => {
  const { height } = useWindowDimensions();
  let maxHeight = height;
  if (maxHeight <= 600) {
    maxHeight = 250;
  } else if (maxHeight > 600 && maxHeight <= 800) {
    maxHeight = 400;
  } else maxHeight = 600;
  return maxHeight;
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: 'normal',
    fontWeight: theme.fonts.medium,
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  helperText: {
    marginVertical: theme.getSpacing(0.5),
    fontWeight: theme.fonts.medium,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  addNewPatient: {
    marginVertical: theme.getSpacing(1),
    fontStyle: 'normal',
    fontWeight: theme.fonts.medium,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  scrollView: {
    height: calcScrollViewHeight(),
    flexGrow: 1,
  },
}));
