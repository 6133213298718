import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import React, {
  ForwardRefRenderFunction,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import {
  BaseModalButtonProps,
  BaseModalHandler,
} from 'assets/components/base-modal/BaseModal';
import { GenericBottomSheet } from 'assets/components/generic-bottom-sheet/GenericBottomSheet';

export const ConfirmDeleteActionModal: ForwardRefRenderFunction<
  BaseModalHandler,
  ConfirmDeleteModalProps
> = (props: ConfirmDeleteModalProps, ref: ForwardedRef<BaseModalHandler>) => {
  const styles = useStyles();
  const modalRef = React.useRef<BaseModalHandler>(null);

  const handleShow = () => {
    modalRef.current?.show();
  };

  const handleHide = () => {
    modalRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <GenericBottomSheet
      ref={modalRef}
      title={props.title}
      buttons={props.buttons}
      height={props.height}
    >
      {props.subtitle && (
        <View style={{ alignContent: 'center' }}>
          <Text style={styles.modalText}>{props.subtitle}</Text>
        </View>
      )}
    </GenericBottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: '400',
  },
}));

interface ConfirmDeleteModalProps {
  height: string;
  buttons?: BaseModalButtonProps[];
  title: string;
  subtitle: string;
}

export const ConfirmDeleteModal = React.forwardRef<
  BaseModalHandler,
  ConfirmDeleteModalProps
>(ConfirmDeleteActionModal);
