import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Platform, View } from 'react-native';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { StoreSelector } from './StoreSelector';
import { Text } from 'assets/components/text';
import theme, { makeStyles } from 'assets/theme';
import { StoreSelectorProps } from './types/StoreSelectorProps';
import { Icon } from 'assets/components/icon';
import { AlertTriangleIcon } from 'assets/icons';

export const StoreSelectorField: FunctionComponent<
  PropsWithChildren<StoreSelectorFieldProps>
> = ({ name, rules, platform = Platform.OS, ...rest }) => {
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('StoreSelectorField must have a parent form context');
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <StoreSelector onChange={onChange} selectedOption={value} {...rest} />
        )}
        name={name}
        rules={rules}
      />
      {!!error && (
        <View style={styles.container}>
          <View style={styles.icon}>
            <Icon
              icon={AlertTriangleIcon}
              color={theme.palette.error[600]}
              size={16}
            />
          </View>
          <Text
            testID={StoreSelectorFieldTestIDs.error}
            style={styles.errorMessage}
          >
            {error.message}
          </Text>
        </View>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
  },
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
}));

export interface StoreSelectorFieldProps extends StoreSelectorProps {
  name: string;
  rules?: RegisterOptions;
}

export const StoreSelectorFieldTestIDs = {
  error: 'store-selector-formControl-error',
};
