import React, { FunctionComponent, PropsWithChildren } from 'react';
import { VerificationCode } from 'assets/components/verification-code';
import { Text } from 'assets/components/text';
import { TouchableOpacity, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Alert } from 'assets/components/alert';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import { Portal, Provider } from 'react-native-paper';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import {
  useResendVerificationState,
  useVerificationState,
} from '../../../../verify/verification-store';
import {
  VerifiedProperty,
  VerifiedPropertyConfig,
} from '../../../../verify/EditVerifiedProperty';
import { formatPhone } from '../../../../../utils';

export const ConfirmationWithVerificationCode: FunctionComponent<
  PropsWithChildren<{
    handleSubmit: (confirmCode: string) => void;
    handleResendCode: () => void;
    verifiedProperty?: VerifiedProperty;
    confirmValue: string;
    confirmationCode?: string;
  }>
> = ({
  handleSubmit,
  handleResendCode,
  verifiedProperty,
  confirmValue,
  confirmationCode,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const verificationState = useVerificationState();
  const resendVerificationState = useResendVerificationState();
  const didntReceiveTitle =
    verifiedProperty === VerifiedProperty.Phone
      ? getText('didnt-receive-text-message')
      : getText('didnt-receive-email');
  const isCapableTitle =
    verifiedProperty === VerifiedProperty.Phone
      ? getText('phone-number-is-sms-capable')
      : getText('email-address-is-correct');

  const extraTitle = getText('standard-text-apply');

  return (
    <View style={{ alignItems: 'center' }}>
      {!!verificationState.error && (
        <Alert
          title={verificationState.error.message}
          intent="error"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      )}
      {!!resendVerificationState.error && (
        <Alert
          title={resendVerificationState.error.message}
          intent="error"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      )}
      {resendVerificationState.status === 'success' && (
        <Alert
          title={getText('verification-code-resent')}
          intent="success"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      )}
      <View style={{ alignItems: 'center', marginBottom: theme.getSpacing(2) }}>
        <Text
          style={{
            color: theme.palette.gray[600],
          }}
        >
          {getText('please-enter-code')}
        </Text>
        <Text style={{ marginTop: theme.getSpacing(1) }}>
          {verifiedProperty === VerifiedProperty.Phone
            ? formatPhone(confirmValue)
            : confirmValue}
        </Text>
      </View>
      <View style={{ marginBottom: theme.getSpacing(2), alignItems: 'center' }}>
        <VerificationCode
          onSubmit={handleSubmit}
          disabled={verificationState.status === 'loading'}
          initialCode={confirmationCode}
        />
        {verificationState.status === 'loading' && (
          <Provider>
            <Portal>
              <LoadingIndicator color="#428EC6" />
            </Portal>
          </Provider>
        )}
      </View>
      <View
        style={{
          alignItems: 'center',
        }}
      >
        <TouchableOpacity onPress={handleResendCode}>
          <Text style={styles.pressableText}>{getText('resend-code')}</Text>
        </TouchableOpacity>
        <View style={{ marginTop: theme.getSpacing(1) }}>
          <Text style={styles.text}>{didntReceiveTitle}</Text>
          <Text style={styles.text}>{isCapableTitle}</Text>
        </View>
      </View>
      <View style={{ marginTop: theme.getSpacing(2) }}>
        <Text style={styles.bottomText}>{extraTitle}</Text>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[500],
    fontStyle: 'normal',
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  text: {
    ...theme.fonts.regular,
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.gray[600],
    fontWeight: '400',
    lineHeight: 24,
    alignContent: 'center',
  },
  bottomText: {
    ...theme.fonts.regular,
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
    color: theme.palette.gray[600],
    marginTop: theme.getSpacing(4),
    textAlign: 'center',
  },
}));
