import create from 'zustand';
import { MessagesState } from './types';
import { MESSAGES_TAKE } from './constants';

export const useMessagesState = create<MessagesState>(() => ({
  showModal: false,
  selectedConversation: null,
  viewedConversationsSet: new Set(),
  currentPatientRecord: undefined,
  selectedMessages: [],
  messagesPagination: {
    skip: 0,
    take: MESSAGES_TAKE,
    count: 0,
  },
  failedMessagesInConversation: {},
  rawConversations: [],
  initialSubject: undefined,
  isOpenNewChatModal: false,
  disabled: false,
  count: {},
  socket: undefined,
  typingMember: null,
  newMessageAttachmentsDocuments: [],
  newMessageAttachmentsImages: [],
  isLoading: true,
  composerHeight: 0,
}));
