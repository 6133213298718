import create from 'zustand';
import { Platform } from 'react-native';
import { ApiError, AsyncStatus } from '../../store/types';
import {
  LocationPatientRecordDto,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import {
  CreateRefillDto,
  IngressMethod,
  PrescriptionDto,
  RefillDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import refillService from './refill-service';
import { ampli } from '../../common/ampliPatient';

const defaultState: Omit<
  RefillMedicationsState,
  'updateMedicationData' | 'resetRefillMedicationState'
> = {
  status: 'idle',
  selectedMedications: [],
  allMedications: [],
  selectedPatient: undefined,
  showRefillMedications: true,
  isFirstTimeRegister: true,
  selectedPatientRecord: undefined,
  selectedPatientRecordId: undefined,
  selectedPrescriptionId: undefined,
  selectedLocationId: undefined,
  notificationRefreshTrigger: undefined,
  unableToSubmitMedications: [],
};

export const useRefillMedicationsStore = create<RefillMedicationsState>(
  (set) => ({
    ...defaultState,
    updateMedicationsData: (data: RefillMedicationsState) => {
      set({ ...data });
    },
    resetRefillMedicationState: () => {
      set({
        ...defaultState,
      });
    },
  }),
);

interface RefillMedicationsState {
  status?: AsyncStatus;
  error?: ApiError;
  selectedMedications?: Array<PrescriptionDto & { checked?: boolean }>;
  allMedications?: Array<PrescriptionDto & { checked?: boolean }>;
  selectedPatient?: LocationPatientRecordDto;
  showRefillMedications?: boolean;
  isFirstTimeRegister?: boolean;
  selectedPatientRecord?: PatientRecordDto;
  selectedLocationId?: string;
  selectedPatientRecordId?: string;
  selectedPrescriptionId?: string;
  unableToSubmitMedications?: PrescriptionDto[];
  notificationRefreshTrigger?: number;
  updateMedicationsData?: (data: RefillMedicationsState) => void;
  resetRefillMedicationState?: () => void;
}

export interface ResourceItem {
  value: string;
  text: string;
}

export const useRefillReviewMethodStore = create<RefillReviewMethodState>(
  (set, get) => ({
    status: 'idle',
    methodValue: 'not-selected',
    methodName: 'Not Selected',
    methodMessage: '',
    noteValue: '',
    submittedRefill: undefined,
    updateReviewMethodData: (data: RefillReviewMethodState) => {
      set({ ...data });
    },
    submitRefill: async (
      pharmacy_id: string,
      location_id: string,
      patient_id: string,
      selectedMedications: PrescriptionDto[],
      submittedBy: string,
      isToday?: boolean,
      isUrgent?: boolean,
    ) => {
      const state = get();

      set({ ...state, status: 'loading' });

      const refill: CreateRefillDto = {
        pharmacy_id: pharmacy_id,
        location_id: location_id,
        patient_id: patient_id,
        is_today: isToday,
        is_urgent: isUrgent,
        ingress_method:
          Platform.OS === 'web' ? IngressMethod.Web : IngressMethod.Mobile,
        pickup_method_id: state.methodValue,
        refill_items: selectedMedications.map((med) => ({
          rx_number: med.rx_number,
          rx_name: med.drug_name,
        })),
        submitted_by: submittedBy,
      };

      try {
        const data = await refillService.submitPatientRefill(
          location_id,
          patient_id,
          refill,
        );

        ampli.orderPlaced({
          autorefillSelection: '',
          fulfillmentMethod: data.pickup_method_name,
          orderChannel: data.ingress_method,
          refillID: data.refill_id,
          refillMedicationID: data.order_id ?? '',
          refillStatus: data.refill_status ?? '',
          refillSubmittedTime: new Date().toISOString(),
        });

        set({
          ...state,
          status: 'idle',
          submittedRefill: data,
        });
      } catch (error) {
        //TODO: review consol log
        console.error(error);
        set({ ...state, status: 'error' });
      }
    },
  }),
);

interface RefillReviewMethodState {
  status?: AsyncStatus;
  error?: ApiError;
  methodValue: string;
  methodName: string;
  methodMessage: string;
  noteValue?: string;
  submittedRefill?: RefillDto;
  updateReviewMethodData?: (data: RefillReviewMethodState) => void;
  isToday?: boolean;
  isUrgent?: boolean;
  submitRefill?: (
    pharmacy_id: string,
    location_id: string,
    patient_id: string,
    selectedMedications: PrescriptionDto[],
    submittedBy: string,
    isToday?: boolean,
    isUrgent?: boolean,
  ) => Promise<void>;
}

export const useRefillReviewInsuranceStore = create<RefillReviewInsuranceState>(
  (set) => ({
    status: 'idle',
    insuranceValue: 'Not selected',
    updateReviewInsuranceData: (data: RefillReviewInsuranceState) => {
      set({ ...data });
    },
  }),
);

interface RefillReviewInsuranceState {
  status?: AsyncStatus;
  error?: ApiError;
  insuranceValue: string;
  updateReviewInsuranceData?: (data: RefillReviewInsuranceState) => void;
}

export const resetRefillMedicationState = () => {
  const { resetRefillMedicationState } = useRefillMedicationsStore.getState();
  if (!resetRefillMedicationState) return;
  resetRefillMedicationState();
};
