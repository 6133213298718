import React from 'react';
import { DeepLinkingContext, DeepLinkingContextType } from '../context';
import { DeepLinkingConfig } from '../types';

export function useDeepLinkingContext(): DeepLinkingContextType {
  return React.useContext(DeepLinkingContext);
}

export function useDeepLinking(): DeepLinkingConfig {
  const { deepLinkingConfig } = useDeepLinkingContext();
  return deepLinkingConfig;
}
