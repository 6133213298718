import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Alert } from 'assets/components/alert';
import { Text } from 'assets/components/text/Text';
import { InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { ScrollView } from 'react-native';
import { Divider } from 'react-native-paper';
import { PrescriptionCard } from '../../components/prescription-card';
import { PrescriptionDetails } from '../../components/prescription-details/PrescriptionDetails';
import { PrescriptionDetailsHandler } from '../../components/prescription-details/PrescriptionDetailsProps';
import { useUserState } from '../../store/user-store';
import { AddMedsStackParamList } from './AddMedicationsNavigation';
import { FindPrescriptionFlowTestIDs } from './AddMedicationsTestIDs';
import {
  loadPrescriptions,
  resetAddPrescriptionState,
  setFindMedsScreenAsSeen,
} from './add-medications-actions';
import { useAddMedicationsStore } from './add-medications-store';

export const ListMedicationsIntegrated: FunctionComponent<
  PropsWithChildren<ListMedicationsIntegratedProps>
> = () => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const styles = useStyles();
  const locationId = useUserState((s) => s.user?.preferredPharmacyLocationId);
  const { locationPatientRecordId, prescriptions, originated } =
    useAddMedicationsStore();
  const prescriptionDetailsRef = React.useRef<PrescriptionDetailsHandler>(null);

  useEffect(() => {
    void loadPrescriptions();
  }, [locationId, locationPatientRecordId]);

  const handleOnPressDone = () => {
    if (originated === 'onboarding') {
      void setFindMedsScreenAsSeen();
    }

    navigation.navigate('app', { screen: 'medications' });

    resetAddPrescriptionState();
  };

  const handleOpenDetailsPress = (prescription: PrescriptionDto) => {
    prescriptionDetailsRef.current?.show(prescription);
  };

  return (
    <InternalScreenContainer
      title={getText('find-meds')}
      buttons={[
        {
          hierarchy: 'primary',
          onPress: handleOnPressDone,
          testID: FindPrescriptionFlowTestIDs.nextButton,
          logger: { id: 'add-prescription' },
          text: getText('done'),
        },
        {
          hierarchy: 'tertiary',
          onPress: () => navigation.navigate('enter-medications'),
          testID: FindPrescriptionFlowTestIDs.doThisLaterButton,
          logger: { id: 'add-prescription' },
          text: getText('other-meds'),
        },
      ]}
    >
      <Alert
        title={getText('we-found-your-medications-alert-title')}
        intent={'success'}
      />
      <Text style={styles.textTitle}>{getText('your-prescriptions')}</Text>
      <Divider />
      <ScrollView>
        {prescriptions.map((item) => (
          <PrescriptionCard
            key={item.rx_number}
            prescription={item}
            showRxNumber={true}
            showPatientInfo={true}
            showStatus={true}
            showDetailsIcon={true}
            onOpenDetailsPress={() => handleOpenDetailsPress(item)}
          />
        ))}
      </ScrollView>
      <PrescriptionDetails ref={prescriptionDetailsRef} />
    </InternalScreenContainer>
  );
};

export type ListMedicationsIntegratedProps = NativeStackScreenProps<
  AddMedsStackParamList,
  'list-found-medications'
>;

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(2),
  },
}));
