import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { WizardProgress } from 'assets/components/wizard-progress';
import React, { FunctionComponent } from 'react';
import { makeStyles } from 'assets/theme';
import {
  OrderDto,
  OrderProgressStatus,
} from '@digitalpharmacist/order-service-client-axios';
import { getText } from 'assets/localization/localization';
import orderService from '../medication-orders/order-service';
import { formatDate } from '../../../common/datetime-utils';

export type OrderProgressTrackerProps = {
  order: OrderDto;
  onOpenDetailsPress: () => void;
};

const OrderStepperStatus = {
  [OrderProgressStatus.NotStarted]: 1,
  [OrderProgressStatus.Started]: 2,
  [OrderProgressStatus.Filled]: 3,
  [OrderProgressStatus.Completed]: 4,
};

export const OrderProgressTracker: FunctionComponent<
  OrderProgressTrackerProps
> = ({ order, onOpenDetailsPress = () => undefined }) => {
  const styles = useStyles();
  // order status
  const orderProgressStatus =
    orderService.getOrderProgressStatus(order) ||
    OrderProgressStatus.NotStarted;
  const orderStepperStatus: number = OrderStepperStatus[orderProgressStatus];
  // order status counts
  const totalItems = order.items?.length || 0;
  const totalItemsCompleted =
    order.items?.filter(
      (o) =>
        o.order_progress_status === OrderProgressStatus.Filled ||
        o.order_progress_status === OrderProgressStatus.Completed,
    ).length || 0;
  // ui
  const patientName = `${order.patient_first_name || ''} ${
    order.patient_last_name ? order.patient_last_name[0].toUpperCase() : ''
  }.`;
  const date = formatDate(order.created_at);

  const title = getText('order-tracker-title', { patient: patientName, date });
  const description = getText('order-tracker-description', {
    completed: totalItemsCompleted,
    total: totalItems,
  });

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>{title}</Text>
      <View style={{ width: '100%' }}>
        <WizardProgress
          numberOfSteps={3}
          currentStep={orderStepperStatus}
          type="withoutTitle"
        />
      </View>
      <View style={styles.stepperLabelContainer}>
        <Text style={styles.stepperLabel}>
          {getText('order-status-received')}
        </Text>
        <Text style={styles.stepperLabel}>
          {getText('order-tracker-in-progress')}
        </Text>
        <Text style={styles.stepperLabel}>
          {getText('order-progress-filled')}
        </Text>
      </View>
      <Text style={styles.detailText}> {description}</Text>
      <Text style={styles.link} onPress={onOpenDetailsPress}>
        {getText('see-order-details')}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.getSpacing(2),
    gap: theme.getSpacing(0.5),
    backgroundColor: theme.palette.yellow[50],
    border: `1px solid ${theme.palette.gray[300]}`,
    borderRadius: 6,
    marginVertical: theme.getSpacing(1),
  },
  link: {
    textAlign: 'right',
    color: theme.palette.primary[600],
    cursor: 'pointer',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
  },
  detailText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  stepperLabelContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 20,
  },
  stepperLabel: {
    fontFamily: 'Lato_700Bold',
    color: theme.palette.gray[700],
    width: 72,
    textAlign: 'center',
  },
}));
