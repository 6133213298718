import { Button } from 'assets/components/button';
import { MessageSquareIcon, PhoneIcon } from 'assets/icons';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'react-native';
import { Linking, Platform, View } from 'react-native';
import { NewMessageHandler } from '../../../../messages/MessageProps';
import { useAppStateStore } from '../../../../../store/app-store';
import { useUserState } from '../../../../../store/user-store';
import { getText } from 'assets/localization/localization';

export const HelpWithPrescription: FunctionComponent<
  PropsWithChildren<HelpWithPrescriptionProps>
> = ({ newMessageRef, closeModal }) => {
  const styles = useStyles();
  const { stores, is_patient_start_chat_available } = useAppStateStore();
  const { user } = useUserState();

  const preferredLocation = stores.find(
    (x) => x.id === user?.preferredPharmacyLocationId,
  );

  const handleOnMessagePress = () => {
    newMessageRef.current?.show();
    closeModal();
  };

  const handleOnPhoneNumberPress = () => {
    if (preferredLocation?.phone && Platform.OS !== 'web') {
      void Linking.openURL(`tel:${preferredLocation.phone}`);
    }
  };

  return (
    <View style={styles.container}>
      {is_patient_start_chat_available ? (
        <Button
          hierarchy="primary"
          testID={'message-button-id'}
          logger={{ id: 'message-button-id' }}
          icon={MessageSquareIcon}
          onPress={handleOnMessagePress}
        >
          {getText('send-secure-message')}
        </Button>
      ) : null}
      <Button
        disabled={Platform.OS === 'web'}
        hierarchy="tertiary-gray"
        testID={'phone-button-id'}
        logger={{ id: 'phone-button-id' }}
        icon={PhoneIcon}
        onPress={handleOnPhoneNumberPress}
      >
        {getText('call-the-pharmacy')}
      </Button>
      {Platform.OS === 'web' && (
        <Text style={styles.phoneNumber}>{preferredLocation?.phone}</Text>
      )}
    </View>
  );
};

export type HelpWithPrescriptionProps = {
  newMessageRef: React.RefObject<NewMessageHandler>;
  closeModal: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingHorizontal: theme.getSpacing(1.5),
    gap: theme.getSpacing(1.5),
  },
  phoneNumber: {
    ...theme.lumistryFonts.text.medium.regular,
  },
}));
