import {
  PatientRecordDto,
  UpdateAddressDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { useForm } from 'assets/form';
import { useCurrentUrl } from 'assets/hooks';
import { Form, InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { View } from 'react-native';
import patientService from '../../../api/patient-service';
import {
  DateTimeFormat,
  formatDateStringAsISO,
  formatISODateAsString,
} from '../../../common/form-utils';
import { useUserState } from '../../../store/user-store';
import {
  EditVerifiedProperty,
  VerifiedProperty,
} from '../../verify/EditVerifiedProperty';
import { AccountStackParamList } from '../AccountNavigation';
import { ContactInformation } from './components/account-information/ContactInformation';
import { MedicalInformation } from './components/account-information/MedicalInformation';
import {
  EditPersonalInformation,
  EditPersonalInformationMode,
} from './components/manage-people-under-care-details/EditPersonalInformation';
import { PersonalInformationComponent } from './components/account-information/PersonalInformationComponent';
import { EditMedicalInformation } from './components/manage-people-under-care-details/EditMedicalInformation';
import { PatientUnderCareForm } from './patient-actions';
import {
  PatientUnderCareEditDetailsEnum,
  usePatientRecordState,
} from './patient-store';

export const PersonalInformation: FunctionComponent<
  PropsWithChildren<PersonalInformationProps>
> = ({ navigation }) => {
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const { user } = useUserState();
  const methods = useForm<PatientUnderCareForm>({
    defaultValues: {},
  });
  const [editDetails, setWhichEditDetails] = useState(
    PatientUnderCareEditDetailsEnum.PersonalInfo,
  );
  const [viewMode, switchViewMode] = useState(false);
  const { error } = usePatientRecordState();
  const editPhoneRef = React.useRef<BaseModalHandler>(null);
  const editEmailRef = React.useRef<BaseModalHandler>(null);
  const editPersonalInfoRef = React.useRef<BaseModalHandler>(null);
  const [verifiedPropertyForModal, setVerifiedPropertyForModal] =
    useState<VerifiedProperty>(VerifiedProperty.Email);
  const { url } = useCurrentUrl();

  useEffect(() => {
    usePatientRecordState.setState({ error: undefined, status: 'idle' });
    loadPatientRecord();
  }, []);

  const loadPatientRecord = async () => {
    if (user && user.patientRecordId) {
      setPatientRecord(
        await patientService.findPatientRecord(user.patientRecordId),
      );
    }
  };

  const onEditPress = (
    editDetail: PatientUnderCareEditDetailsEnum,
    methods: UseFormReturn<PatientUnderCareForm>,
  ) => {
    whichDetailsToEdit(editDetail);
    showEditPersonalModal();
    changeViewMode(true);
    if (!patientRecord) {
      throw new Error('No patient record found');
    }
    const {
      address1 = '',
      address2 = '',
      city = '',
      state = '',
      country = 'US',
      postal_code = '',
    } = patientRecord.address || {};

    methods.setValue('first_name', patientRecord.first_name);
    methods.setValue('last_name', patientRecord.last_name);
    methods.setValue(
      'date_of_birth',
      formatISODateAsString(
        patientRecord.date_of_birth,
        DateTimeFormat.USDateFormat,
      ),
    );
    methods.setValue('gender', patientRecord.gender);
    methods.setValue('address1', address1);
    methods.setValue('address2', address2);
    methods.setValue('city', city);
    methods.setValue('state', state);
    methods.setValue('country', country);
    methods.setValue('postal_code', postal_code);

    return methods;
  };

  const whichDetailsToEdit = (editType: PatientUnderCareEditDetailsEnum) => {
    setWhichEditDetails(editType);
  };

  const updatePatientRecord = async (updatePR: UpdatePatientRecordDto) => {
    //TODO: extract to enclosing scope
    if (patientRecord) {
      const result = await patientService.updatePatientRecord(
        patientRecord.id,
        updatePR,
      );
      setPatientRecord(result);
      usePatientRecordState.setState({
        patientRecord: result,
      });
    }
  };

  const handleSubmit = async () => {
    const updatedFormValues = methods.getValues();

    const addressDto: UpdateAddressDto = {};
    if (updatedFormValues.address1 !== '')
      addressDto.address1 = updatedFormValues.address1;
    if (updatedFormValues.address2 !== '')
      addressDto.address2 = updatedFormValues.address2;
    if (updatedFormValues.city !== '') addressDto.city = updatedFormValues.city;
    if (updatedFormValues.state !== '')
      addressDto.state = updatedFormValues.state;
    if (updatedFormValues.postal_code !== '')
      addressDto.postal_code = updatedFormValues.postal_code;
    if (
      updatedFormValues.address1 !== '' &&
      updatedFormValues.city !== '' &&
      updatedFormValues.state !== '' &&
      updatedFormValues.postal_code !== '' &&
      updatedFormValues.country !== ''
    ) {
      addressDto.country = updatedFormValues.country;
    }

    const isAddressComplete =
      updatedFormValues.address1 &&
      updatedFormValues.city &&
      updatedFormValues.country &&
      updatedFormValues.state &&
      updatedFormValues.postal_code
        ? true
        : false;

    const updatePR: UpdatePatientRecordDto = {
      first_name: updatedFormValues.first_name,
      last_name: updatedFormValues.last_name,
      date_of_birth: formatDateStringAsISO(
        updatedFormValues.date_of_birth,
        DateTimeFormat.USDateFormat,
      ),
      gender: updatedFormValues.gender,
      address: isAddressComplete ? addressDto : null,
    };

    updatePatientRecord(updatePR).catch((error) => {
      //TODO: [ERROR_HANDLING] please review this error alert
      logError(error);
    });
    hideEditPersonalModal();
  };

  const hideEditPersonalModal = () => {
    methods.clearErrors();
    editPersonalInfoRef.current?.hide();
  };

  const showEditPersonalModal = () => {
    methods.clearErrors();
    editPersonalInfoRef.current?.show();
  };

  const changeViewMode = (editMode: boolean) => {
    switchViewMode(editMode);
  };

  const onEmailChangePress = () => {
    setVerifiedPropertyForModal(VerifiedProperty.Email);
    editEmailRef.current?.show();
  };

  const onPhoneChangePress = () => {
    setVerifiedPropertyForModal(VerifiedProperty.Phone);
    editPhoneRef.current?.show();
  };
  const formError =
    !!methods.getFieldState('address1').error ||
    !!methods.getFieldState('state').error ||
    !!methods.getFieldState('gender').error ||
    !!methods.getFieldState('city').error ||
    !!methods.getFieldState('date_of_birth').error ||
    !!methods.getFieldState('postal_code').error ||
    !!methods.getFieldState('first_name').error ||
    !!methods.getFieldState('last_name').error;

  return (
    <InternalScreenContainer title={getText('personal-info')} showFooter>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        {patientRecord && user && (
          <View>
            <PersonalInformationComponent
              patientRecord={patientRecord}
              onEditPress={() =>
                onEditPress(
                  PatientUnderCareEditDetailsEnum.PersonalInfo,
                  methods,
                )
              }
              form={methods}
            ></PersonalInformationComponent>
            <ContactInformation
              registrationMethod={user.registrationMethod}
              patientRecord={patientRecord}
              onEmailChangePress={() => onEmailChangePress()}
              onPhoneChangePress={() => onPhoneChangePress()}
              form={methods}
            ></ContactInformation>
            {verifiedPropertyForModal === VerifiedProperty.Email && (
              <EditVerifiedProperty
                verifiedProperty={VerifiedProperty.Email}
                ref={editEmailRef}
                currentUrl={url}
              ></EditVerifiedProperty>
            )}
            {verifiedPropertyForModal === VerifiedProperty.Phone && (
              <EditVerifiedProperty
                verifiedProperty={VerifiedProperty.Phone}
                ref={editPhoneRef}
              ></EditVerifiedProperty>
            )}
          </View>
        )}
        {editDetails === PatientUnderCareEditDetailsEnum.PersonalInfo &&
          patientRecord && (
            <GenericModal
              title={getText('update-personal-info')}
              buttons={[
                {
                  hierarchy: 'primary',
                  onPress: methods.handleSubmit(handleSubmit),
                  logger: { id: 'save-personal-info-button' },
                  text: getText('save'),
                },
              ]}
              ref={editPersonalInfoRef}
              isScrollable={true}
              scrollToTop={formError}
            >
              <View>
                <View>
                  {!viewMode ? (
                    <View>
                      <PersonalInformationComponent
                        patientRecord={patientRecord}
                        onEditPress={() => {
                          onEditPress(
                            PatientUnderCareEditDetailsEnum.PersonalInfo,
                            methods,
                          );
                        }}
                      ></PersonalInformationComponent>
                      <MedicalInformation
                        patientRecord={patientRecord}
                        onEditPress={() => {
                          onEditPress(
                            PatientUnderCareEditDetailsEnum.MedicalInfo,
                            methods,
                          );
                        }}
                      ></MedicalInformation>
                    </View>
                  ) : PatientUnderCareEditDetailsEnum.PersonalInfo ===
                    editDetails ? (
                    <EditPersonalInformation
                      changeViewMode={changeViewMode}
                      handleSave={handleSubmit}
                      patientRecord={patientRecord}
                      form={methods}
                      formError={formError}
                      mode={EditPersonalInformationMode.Self}
                    ></EditPersonalInformation>
                  ) : PatientUnderCareEditDetailsEnum.MedicalInfo ===
                    editDetails ? (
                    <EditMedicalInformation
                      patientRecord={patientRecord}
                      changeViewMode={changeViewMode}
                      handleSave={handleSubmit}
                      form={methods}
                    ></EditMedicalInformation>
                  ) : (
                    // TODO Insurance and Id info will come here - out of scope for BLUES-2677
                    <></>
                  )}
                </View>
              </View>
            </GenericModal>
          )}
      </Form>
    </InternalScreenContainer>
  );
};

type PersonalInformationProps = NativeStackScreenProps<
  AccountStackParamList,
  'personal-information'
>;
