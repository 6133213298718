import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useNavigation } from '@react-navigation/native';
import { LoginStackNavigationProp } from '../../navigation/LoginNavigation';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import { Alert } from 'assets/components/alert';

export const ResetSuccessful: FunctionComponent<
  PropsWithChildren<ResetSuccessfulProps>
> = () => {
  const theme = useTheme();
  const navigation = useNavigation<LoginStackNavigationProp>();

  return (
    <ScreenContainer>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 30,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(1),
          textAlign: 'center',
        }}
      >
        {getText('create-new-password')}
      </Text>

      <Alert
        title={getText('your-password-has-been-successfully-changed')}
        intent="success"
      />

      <View style={{ marginTop: theme.getSpacing(2) }}>
        <Button
          onPress={() => navigation.navigate('intro')}
          hierarchy="primary"
          logger={{ id: 'reset-successful-go-to-login-button' }}
        >
          {getText('go-to-login')}
        </Button>
      </View>
    </ScreenContainer>
  );
};

interface ResetSuccessfulProps {}
