import { FunctionComponent, useState, useCallback, useEffect } from 'react';
import { Platform, View, Keyboard, Image } from 'react-native';
import {
  GiftedChat,
  GiftedChatProps,
  IMessage,
  ActionsProps,
  BubbleProps,
  InputToolbar,
  Composer,
  DayProps,
} from 'react-native-gifted-chat';
import { DocumentPickerAsset } from 'expo-document-picker';
import {
  DocumentIcon,
  MessageSendIcon,
  MicIcon,
  PlusSquareIcon,
  XIcon,
} from 'assets/icons';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { notImplementedAlert } from 'assets/utils/alert';
import { IconButton } from 'assets/components/icon-button';
import { getText } from 'assets/localization/localization';
import { onType } from './InitializeSockets';
import WritingBar from 'assets/components/writing-bar/WritingBar';
import { MESSAGE_LIMIT } from './constants';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Icon } from 'assets/components/icon';
import React from 'react';
import {
  AuthorType,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { useMessagesState } from './messages-store';
import { useUserState } from '../../store/user-store';
import { IMessageExtended, MessageStatus } from 'assets/types/messageTypes';
import Bubble from './Bubble';
import {
  setNewMessageDocumentAttachments,
  setNewMessageImagesAttachments,
  setComposerHeight,
} from './messages-actions';
import { ImagePickerAsset } from 'expo-image-picker';
import TimeStampComponent from './TimeStampComponent';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import pharmacyService from '../../api/pharmacy-service';
import { MapSize } from '../../components/store-selector/types';
import { StoreInformation } from '../../components/store-selector/StoreInformation';
import { updateLocationDetails } from '../register/register-actions';
import ModalOrBottomSheet from './ModalOrBottomSheet';
import { GenericBottomSheet } from 'assets/components/generic-bottom-sheet/GenericBottomSheet';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { ChatBoxActions } from './ChatBoxActions';
import {
  ANDROID_HEIGHT_DIFFERENCE,
  ATTACHMENT_HEIGHT_DIFFERENCE,
  DEFAULT_HEIGHT_DIFFERENCE,
  DEFAULT_HEIGHT_SUBTRACTION,
  MAX_KEYBOARD_HEIGHT,
  MOBILE_HEIGHT,
} from '../../../../pharmacy/modules/screens/messages/data';
import { IMAGE_VALID_PERIOD } from 'assets/utils/messageUtils';
import { Text } from 'assets/components/text';
import { ampli } from '../../common/ampliPatient';

let UPDATE_IMAGES_LINKS_TIMEOUT: number;

export const ChatBox: FunctionComponent<ChatBoxProps> = (props) => {
  const user = useUserState((state) => state.user);
  const socket = useMessagesState((state) => state.socket);
  const typingMember = useMessagesState((state) => state.typingMember);
  const newMessageAttachmentsImages = useMessagesState(
    (state) => state.newMessageAttachmentsImages,
  );
  const newMessageAttachmentsDocuments = useMessagesState(
    (state) => state.newMessageAttachmentsDocuments,
  );
  const composerHeight = useMessagesState((state) => state.composerHeight);
  const [text, setText] = useState<string>('');
  const styles = useStyles();
  const theme = useTheme();
  const sheetRef = React.useRef<BaseModalHandler>(null);
  const locationPatientRecordId = user?.preferredPharmacyLprId ?? '';
  const locationId = user?.preferredPharmacyLocationId ?? '';

  const [location, setLocation] = useState<PharmacyLocationDto | undefined>();
  const [showStoreInfo, setShowStoreInfo] = useState(false);

  const [keyboardShown, setKeyboardShow] = useState(false);

  const [isLoadingEarlier, setIsLoadingEarlier] = useState(false);
  const [forceUpdateImages, setForceUpdateImages] = useState(0);

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardShow(true);
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardShow(false);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    void (async () => {
      const pl = await pharmacyService.findPharmacyLocation(locationId);

      setLocation(pl);

      void updateLocationDetails(locationId);
    })();
  }, [locationId]);

  const openActions = () => {
    sheetRef.current?.show();
  };

  const closeAction = () => {
    sheetRef.current?.hide();
  };

  const removeDocumentAttachment = (name: string) => {
    const documentsList = newMessageAttachmentsDocuments.filter(
      (attachment) => {
        if (attachment.name === name) {
          return false;
        }
        return true;
      },
    );

    setNewMessageDocumentAttachments(documentsList);
  };

  const removeImageAttachment = (uri: string) => {
    const imagesList = newMessageAttachmentsImages.filter((attachment) => {
      if (attachment.uri === uri) {
        return false;
      }
      return true;
    });

    setNewMessageImagesAttachments(imagesList);
  };

  const handleMessageSend = useCallback(
    (message?: IMessage) => {
      if (props.onSend) {
        if (!message) {
          const propsUser = props.user;
          const textToSend = text.trim();
          if (
            (textToSend ||
              newMessageAttachmentsDocuments.length ||
              newMessageAttachmentsImages.length) &&
            props.onSend &&
            propsUser
          ) {
            const newMessage = {
              _id:
                (props.messageIdGenerator && props.messageIdGenerator()) ??
                `${propsUser._id} ${textToSend}`,
              text: textToSend,
              user: propsUser,
              createdAt: new Date(),
              pending: true,
              attachments: {
                files: newMessageAttachmentsDocuments,
                images: newMessageAttachmentsImages,
              },
            };
            props.onSend([newMessage]);

            ampli.messageSent({
              messageSentTime: new Date().toISOString(),
              messageSubject: props.conversation?.subject ?? '',
              messageID: newMessage._id,
              messageStatus: 'sent',
              conversationID: props.conversation?.conversation_id ?? '',
            });

            setText('');
            setNewMessageDocumentAttachments([]);
            setNewMessageImagesAttachments([]);
          }
        } else {
          props.onSend([message]);
        }
      }
    },
    [text, props.onSend],
  );

  const onEndReached = async () => {
    if (isLoadingEarlier) return;
    setIsLoadingEarlier(true);
    await props.loadMore();
    setIsLoadingEarlier(false);
  };

  const renderBubble = useCallback(
    (args: Readonly<BubbleProps<IMessageExtended>>) => (
      <View
        shouldRasterizeIOS
        renderToHardwareTextureAndroid
        style={{ maxWidth: '100%' }}
      >
        <Bubble
          args={args}
          messages={props.messages}
          conversation={props.conversation}
          status={props.status}
          handleMessageSend={handleMessageSend}
          onLinkClick={props.onLinkClick}
          setShowStoreInfo={setShowStoreInfo}
          forceUpdate={forceUpdateImages}
        />
      </View>
    ),
    [props.messages, props.status, forceUpdateImages],
  );

  const renderActions = useCallback((props: Readonly<ActionsProps>) => {
    return (
      <TouchableOpacity style={styles.actions} onPress={openActions}>
        <Icon
          icon={PlusSquareIcon}
          color={theme.palette.gray[500]}
          size={28}
        ></Icon>
      </TouchableOpacity>
    );
  }, []);

  const renderComposer = () => {
    return (
      <View style={styles.composerContainer}>
        <View
          style={[
            styles.composerInputWrapper,
            {
              height:
                composerHeight +
                (newMessageAttachmentsDocuments.length ||
                newMessageAttachmentsImages.length
                  ? 76
                  : 0),
            },
          ]}
        >
          {newMessageAttachmentsDocuments.length ? (
            <View style={styles.composerAttachments}>
              {newMessageAttachmentsDocuments.map(
                (newMessageAttachment: DocumentPickerAsset) => (
                  <View
                    style={styles.composerAttachment}
                    key={`document-${newMessageAttachment.name}`}
                  >
                    <IconButton
                      icon={XIcon}
                      style={styles.removeAttachment}
                      onPress={() =>
                        removeDocumentAttachment(newMessageAttachment.name)
                      }
                      logger={{ id: 'remove-document' }}
                    />
                    <Icon
                      icon={DocumentIcon}
                      color={theme.palette.gray[500]}
                      size={40}
                    />
                    <Text numberOfLines={1} style={styles.attachmentName}>
                      {newMessageAttachment.name}
                    </Text>
                  </View>
                ),
              )}
            </View>
          ) : null}
          {newMessageAttachmentsImages.length ? (
            <View style={styles.composerAttachments}>
              {newMessageAttachmentsImages.map((image: ImagePickerAsset) => (
                <View
                  style={styles.composerAttachment}
                  key={`image-${image.uri}`}
                >
                  <IconButton
                    icon={XIcon}
                    style={styles.removeAttachment}
                    onPress={() => removeImageAttachment(image.uri)}
                    logger={{ id: 'remove-image' }}
                  />
                  <View style={styles.imageContainer}>
                    <Image
                      style={styles.image}
                      source={{
                        uri: image.uri,
                      }}
                      resizeMode="contain"
                    />
                  </View>
                </View>
              ))}
            </View>
          ) : null}
          <Composer
            placeholder={`${getText('secure-message')}...`}
            text={text}
            onTextChanged={onInputTextChanged}
            keyboardAppearance="default"
            textInputStyle={styles.composerStyle}
            textInputProps={{
              maxLength: MESSAGE_LIMIT,
              maxFontSizeMultiplier: 1.5,
            }}
            multiline
            onInputSizeChanged={onInputSizeChanged}
          />
        </View>

        {text ||
        newMessageAttachmentsDocuments.length ||
        newMessageAttachmentsImages.length ? (
          <IconButton
            icon={MessageSendIcon}
            logger={{ id: 'send-message' }}
            style={styles.composerIcons}
            onPress={() => handleMessageSend()}
          />
        ) : (
          <IconButton
            icon={MicIcon}
            logger={{ id: 'mic-press' }}
            style={styles.composerIcons}
            onPress={notImplementedAlert}
          />
        )}
      </View>
    );
  };

  const renderInputToolbar = () => {
    return (
      <InputToolbar
        renderActions={renderActions}
        renderComposer={renderComposer}
        containerStyle={styles.inputToolbar}
      />
    );
  };

  const onInputTextChanged = useCallback(
    (text: string) => {
      if (props.conversation && socket && locationId && locationPatientRecordId)
        onType(
          props.conversation.conversation_id,
          locationId,
          locationPatientRecordId,
          String(props.user?._id) ?? '',
          props.user?.name ?? '',
          socket,
        );
      setText(text);
    },
    [
      setText,
      onType,
      props.conversation,
      socket,
      locationId,
      locationPatientRecordId,
      props.user?._id,
      props.user?.name,
    ],
  );

  const onInputSizeChanged = (layout: { width: number; height: number }) => {
    let height = Platform.OS === 'web' ? 0 : MOBILE_HEIGHT;
    height += layout.height;
    height = height < MAX_KEYBOARD_HEIGHT ? height : MAX_KEYBOARD_HEIGHT;
    setComposerHeight(height);
  };

  const renderDay = useCallback(
    (args: DayProps<IMessage>) => <TimeStampComponent args={args} />,
    [],
  );

  const renderLoadEarlier = useCallback(
    () => (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator />
      </View>
    ),
    [],
  );

  const onScroll = () => {
    if (!UPDATE_IMAGES_LINKS_TIMEOUT) {
      setForceUpdateImages((prev) => prev + 1);

      UPDATE_IMAGES_LINKS_TIMEOUT = window.setTimeout(() => {
        window.clearTimeout(UPDATE_IMAGES_LINKS_TIMEOUT);
      }, IMAGE_VALID_PERIOD);
    }
  };

  const getChatFooterHeight = () => {
    let height = 0;

    if (keyboardShown) {
      if (Platform.OS === 'android') {
        height += ANDROID_HEIGHT_DIFFERENCE;
      }
    }

    if (
      newMessageAttachmentsDocuments.length ||
      newMessageAttachmentsImages.length
    ) {
      height += ATTACHMENT_HEIGHT_DIFFERENCE;
    } else {
      height += DEFAULT_HEIGHT_DIFFERENCE;
    }

    return (height += composerHeight - DEFAULT_HEIGHT_SUBTRACTION);
  };

  return (
    <>
      <GiftedChat
        {...props}
        isKeyboardInternallyHandled={false}
        messages={props.messages}
        text={text}
        renderBubble={renderBubble}
        shouldUpdateMessage={(props, nextProps) => {
          return props.currentMessage !== nextProps.currentMessage;
        }}
        renderAvatar={() => null}
        showAvatarForEveryMessage={true}
        maxInputLength={MESSAGE_LIMIT}
        renderInputToolbar={renderInputToolbar}
        onInputTextChanged={onInputTextChanged}
        renderDay={renderDay}
        infiniteScroll={true}
        loadEarlier={isLoadingEarlier}
        renderLoadEarlier={renderLoadEarlier}
        listViewProps={{
          disableVirtualization: true, // TODO: might be a temporary solution, because it is a deprecated property in FlatList
          onEndReached: onEndReached,
          onEndReachedThreshold: 0.7,
          onScroll: onScroll,
          scrollEventThrottle: 500,
        }}
        renderFooter={() => {
          if (props.conversation) {
            return (
              <WritingBar
                typingMember={typingMember}
                conversationId={props.conversation.conversation_id}
                appSource={AuthorType.Patient}
              />
            );
          }
        }}
        bottomOffset={
          newMessageAttachmentsDocuments.length > 0 ||
          newMessageAttachmentsImages.length > 0
            ? -60
            : 0
        }
        renderChatFooter={() => {
          return (
            <View
              style={{
                height: getChatFooterHeight(),
              }}
            ></View>
          );
        }}
      />
      {props.conversation ? (
        <GenericBottomSheet
          title={''}
          height={keyboardShown ? '70%' : '40%'}
          ref={sheetRef}
        >
          <View>
            <ChatBoxActions
              conversation={props.conversation}
              onClose={closeAction}
            />
          </View>
        </GenericBottomSheet>
      ) : null}
      {location ? (
        <ModalOrBottomSheet
          show={showStoreInfo}
          close={() => setShowStoreInfo(false)}
        >
          <StoreInformation
            onChangePress={() => {}}
            item={location}
            mapProps={{
              size: MapSize.lg,
            }}
            showFullDetails={true}
          />
        </ModalOrBottomSheet>
      ) : null}
    </>
  );
};

export interface ChatBoxProps extends GiftedChatProps {
  conversation?: DirectMessagePatientDto;
  messages: IMessageExtended[];
  status: MessageStatus;
  onLinkClick: (link: string) => void;
  loadMore: () => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 60,
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[700],
    padding: 10,
    borderRadius: 8,
    maxWidth: '90%',
  },
  bubbleText: {
    ...theme.fonts.regular,
    fontSize: 14,
    ...(Platform.OS === 'web' && {
      overflowWrap: 'break-word',
    }),
  },
  subText: {
    color: theme.palette.gray[500],
    fontSize: 11,
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
  },

  composerStyle: {
    color: theme.palette.gray[500],
    width: '100%',
    flex: 1,
    paddingTop: Platform.OS === 'web' ? theme.getSpacing(2) : 0,
    marginTop: Platform.OS === 'web' ? 0 : theme.getSpacing(2),
    paddingBottom: Platform.OS === 'web' ? theme.getSpacing(0.5) : 0,
  },

  composerContainer: {
    position: 'absolute',
    left: 36,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.palette.gray[400],
    width: Platform.OS === 'web' ? '90%' : '88%',
    marginRight: theme.getSpacing(1),
    alignItems: 'flex-end',
    marginLeft: theme.getSpacing(1),
  },

  composerInputWrapper: {
    flex: 1,
    alignSelf: 'flex-start',
  },

  composerAttachments: {
    flexDirection: 'row',
    borderRadius: 8,
  },

  composerAttachment: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.palette.gray[400],
    margin: theme.getSpacing(0.5),
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.gray[100],
  },

  attachmentName: {
    maxWidth: 50,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingTop: 0,
    color: theme.palette.gray[500],
  },

  removeAttachment: {
    borderWidth: 1,
    borderRadius: 20,
    height: 20,
    width: 20,
    borderColor: theme.palette.gray[400],
    backgroundColor: theme.palette.white,
    position: 'absolute',
    top: -11,
    right: -11,
  },

  inputToolbar: {
    borderTopWidth: 0,
    marginBottom: getSpacing(1),
  },

  composerIcons: {
    marginBottom: 4,
    marginRight: 4,
  },

  sendIcon: {
    marginBottom: theme.getSpacing(2),
  },

  actions: {
    marginLeft: theme.getSpacing(1),
    marginBottom: 12,
  },
  closeButtonContainer: {
    width: 70,
  },
  filesBubble: {
    flexDirection: 'column',
  },
  imageContainer: {
    display: 'flex',
    width: 50,
    height: 50,
  },
  image: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  loadingIndicator: {
    margin: 5,
  },
}));
