import React, { FunctionComponent, useEffect } from 'react';
import { AuthNavigationHeader } from './AuthNavigation';
import { AccountStackParamList } from '../screens/account/AccountNavigation';
import {
  RefillNavigation,
  RefillStackParamList,
} from '../screens/refill/RefillNavigation';
import { NavigatorScreenParams } from '@react-navigation/native';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { BottomTabsNavigation } from './BottomTabsNavigation';
import { WebTabsNavigation } from './WebTabsNavigation';
import { Platform } from 'react-native';
import { PatientUnderCareNavigation } from '../screens/account/patient/components/account-information/linked-accounts/PatientUnderCareNavigation';
import { getText } from 'assets/localization/localization';
import { ConversationNavigation } from '../screens/messages/ConversationNavigation';
import { TransferMedicationsNavigation } from '../screens/transfer-medications/TransferMedsNavigation';
import { InitializeSockets } from '../screens/messages/InitializeSockets';
import { useAppRedirectionContext } from '../providers/app-redirection/hooks';
import { AddMedicationsNavigation } from '../screens/add-medications/AddMedicationsNavigation';
import { MedicationInfoModal } from '../screens/paperless-enrollment/MedicationInfoModal';
import { useCurrentUrl } from 'assets/hooks';

const AppStack = createStackNavigator<AppNavigationParamList>();

export const AppNavigation: FunctionComponent = () => {
  const { setAppRedirectionConfig } = useAppRedirectionContext();
  useEffect(() => {
    setAppRedirectionConfig({
      arePublicRoutesLoaded: false,
      arePrivateRoutesLoaded: true,
    });

    return () => setAppRedirectionConfig({ arePrivateRoutesLoaded: false });
  }, []);

  const tabNavigation = Platform.select({
    native: BottomTabsNavigation,
    web: WebTabsNavigation,
    default: BottomTabsNavigation,
  });

  const { url: currentUrl } = useCurrentUrl();

  return (
    <>
      <AppStack.Navigator
        screenOptions={{
          header: AuthNavigationHeader,
          headerShown: false,
          title: getText('app'), // fallback title
        }}
      >
        <AppStack.Screen name="app" component={tabNavigation} />
        <AppStack.Screen
          name="refill"
          component={RefillNavigation}
          options={{ title: getText('refill') }}
        />
        <AppStack.Screen
          //
          name="conversation"
          component={ConversationNavigation}
          options={{ title: getText('messages') }}
        />
        <AppStack.Screen
          name="people-under-care"
          component={PatientUnderCareNavigation}
        />
        <AppStack.Screen
          name="add-medications"
          component={AddMedicationsNavigation}
          initialParams={{
            showBack: true,
          }}
          options={{ title: getText('add-prescription') }}
        />
        <AppStack.Screen
          name="transfer-medications"
          component={TransferMedicationsNavigation}
          options={{ title: getText('transfer-meds') }}
        />
      </AppStack.Navigator>
      <InitializeSockets currentUrl={currentUrl} />
      <MedicationInfoModal />
    </>
  );
};

export type AppNavigationParamList = {
  app: undefined;
  home: HomeScreenParamsProps | undefined;
  medications: NavigatorScreenParams<RefillStackParamList>;
  messages: undefined;
  account: NavigatorScreenParams<AccountStackParamList>;
  refill: undefined;
  forms: {
    form_id: string;
    location_id: string;
  };
  conversation: undefined;
  'add-medications': { showBack: boolean } | undefined;
  'people-under-care': undefined;
  'conversation-box': undefined;
  'transfer-medications': undefined;
  appointments: undefined;
  appointment: {
    appointment_id: string;
    location_id: string;
    openFormFillModal?: boolean;
  };
};

export type HomeScreenParamsProps = {
  videoId?: string;
  locale?: string;
};

export type DashboardScreenRouteProp = BottomTabNavigationProp<
  AppNavigationParamList,
  'home'
>;
export type MedicationsScreenRouteProp = BottomTabNavigationProp<
  AppNavigationParamList,
  'medications'
>;
export type MessagesScreenRouteProp = BottomTabNavigationProp<
  AppNavigationParamList,
  'messages'
>;
export type ProfileScreenRouteProp = BottomTabNavigationProp<
  AppNavigationParamList,
  'account'
>;
export type FillInFormScreenRouteProp = BottomTabNavigationProp<
  AppNavigationParamList,
  'forms'
>;
export type AppointmentsScreenRouteProp = BottomTabNavigationProp<
  AppNavigationParamList,
  'appointments'
>;
export type AppointmentDetailsScreenRouteProp = BottomTabNavigationProp<
  AppNavigationParamList,
  'appointment'
>;
