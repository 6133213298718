import React from 'react';
import {
  View,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  Pressable,
  Keyboard,
  Platform,
} from 'react-native';
import { makeStyles, useTheme } from '../../theme';
import { Text } from '../text';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { BottomSheet } from '../bottom-sheet';
import { TypeaheadNativeBase } from './TypeaheadNativeBase';
import { uniqueWithMap } from './utils';
import { TypeaheadContext } from './context';
import { TypeaheadService } from './services';
import { TypeaheadBaseItem, TypeaheadProps } from './types';

export const TypeaheadNative = <T extends string | TypeaheadBaseItem>(
  props: TypeaheadProps<T>,
) => {
  return <TypeaheadNativeProvider {...props} />;
};

const TypeaheadNativeProvider = <T extends string | TypeaheadBaseItem>(
  props: TypeaheadProps<T>,
) => {
  const providerValue = React.useMemo(() => new TypeaheadService(), [props]);
  return (
    <TypeaheadContext.Provider value={providerValue}>
      <TypeaheadNativeComponent {...props} />
    </TypeaheadContext.Provider>
  );
};

const TypeaheadNativeComponent = <T extends string | TypeaheadBaseItem>({
  label,
  options,
  defaultValue,
  emptyValue,
  hintMessage,
  disabled = false,
  multiple = false,
  onChange = (value: T[]) => {},
  onInputChange = (value: NativeSyntheticEvent<TextInputChangeEventData>) => {},
  asyncOptions,
  getOptionText = (option) =>
    (option as TypeaheadBaseItem).text ?? (option as string),
  getOptionValue = (option) =>
    (option as TypeaheadBaseItem).value ?? (option as string),
  useCustomNoOptionsValuePrefix = false,
}: TypeaheadProps<T>) => {
  const theme = useTheme();
  const styles = useStyles();

  // initializations
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const [selectedValues, setSelectedValues] = React.useState(
    uniqueWithMap(defaultValue, getOptionValue) as T[],
  );
  const handleOpenBottomSheet = () => {
    sheetRef.current?.present();
  };

  const TypeaheadNativeContext = React.useContext(TypeaheadContext);
  React.useEffect(() => {
    const newValues = uniqueWithMap(defaultValue, getOptionValue) as T[];
    setSelectedValues(newValues);
    TypeaheadNativeContext.setSelectedItems(newValues);
  }, [defaultValue]);

  // actions
  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
    const selectedItems = TypeaheadNativeContext.getSelectedItems();
    setSelectedValues(selectedItems);
    onChange(selectedItems);
  };

  const handleReturnKeyPressed = () => {
    // wait for keyboard to dismiss and then dismiss the bottomsheet
    Keyboard.addListener('keyboardDidHide', () => {
      Keyboard.removeAllListeners('keyboardDidHide');
      setTimeout(
        () => {
          handleBottomSheetDismiss();
        },
        Platform.OS == 'android' ? 100 : 0,
      );
    });
    Keyboard.dismiss();
  };

  const hasValues = selectedValues.length > 0;

  return (
    <View testID={TypeaheadNativeTestIDs.typeaheadNative}>
      <View>
        {label && (
          <Text
            testID={TypeaheadNativeTestIDs.label}
            style={{ marginBottom: theme.getSpacing(1) }}
          >
            {label}
          </Text>
        )}
        <Pressable
          onPress={handleOpenBottomSheet}
          style={styles.pressable}
          testID={TypeaheadNativeTestIDs.typeaheadNativePressable}
        >
          <View style={[styles.fill, styles.justifyCenter]}>
            {hasValues && (
              <Text
                ellipsizeMode="tail"
                numberOfLines={1}
                style={styles.secondaryText}
              >
                {hintMessage}
              </Text>
            )}
            <Text
              style={[
                styles.primaryText,
                !hasValues && { color: theme.palette.gray[500] },
              ]}
              ellipsizeMode="tail"
              numberOfLines={1}
            >
              {selectedValues.map((x) => getOptionText(x)).join(', ') ||
                hintMessage}
            </Text>
          </View>
        </Pressable>
      </View>
      {!disabled && (
        <BottomSheet
          bottomSheetRef={sheetRef}
          height={'90%'}
          onDismiss={handleBottomSheetDismiss}
        >
          <TypeaheadNativeBase
            options={options}
            defaultValue={selectedValues}
            emptyValue={emptyValue}
            hintMessage={hintMessage}
            disabled={disabled}
            multiple={multiple}
            onInputChange={onInputChange}
            asyncOptions={asyncOptions}
            getOptionText={(option) => getOptionText(option as T)}
            getOptionValue={(option) => getOptionValue(option as T)}
            onReturnKeyPressed={handleReturnKeyPressed}
            onClose={handleBottomSheetDismiss}
            context={TypeaheadNativeContext}
            useCustomNoOptionsValuePrefix={useCustomNoOptionsValuePrefix}
          />
        </BottomSheet>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  fill: { flex: 1 },
  justifyCenter: { justifyContent: 'center' },
  pressable: {
    height: 58,
    borderRadius: theme.roundness,
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
    paddingLeft: theme.getSpacing(1.5),
    paddingRight: theme.getSpacing(1.5),
    gap: theme.getSpacing(0.5),
  },
  primaryText: {
    ...theme.lumistryFonts.label.large,
    color: theme.palette.gray[900],
  },
  secondaryText: {
    ...theme.lumistryFonts.label.xSmall,
    color: theme.palette.gray[500],
  },
}));

export const TypeaheadNativeTestIDs = {
  label: 'TypeaheadNative-label',
  typeaheadNative: 'typeahead-native-id',
  typeaheadNativePressable: 'typeahead-native-pressable-id',
};
