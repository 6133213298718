import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { Button } from 'assets/components/button';
import { makeStyles } from 'assets/theme';
import { Platform } from 'react-native';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { useForm } from 'assets/form';
import { usePatientIntakeState } from './patient-store';
import { PatientFeedback, updatePatientIntake } from './patient-actions';
import { AccountStackParamList } from '../AccountNavigation';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { TextField } from 'assets/components/text-field';
import { CheckboxField } from 'assets/components/checkbox';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import Constants from 'expo-constants';
import { useUserState } from '../../../store/user-store';
import { useAppStateStore } from '../../../store/app-store';
import { GiveFeedbackTestIDs } from './GiveFeedbackTestIDs';
import { ScreenContainer, Form } from 'assets/layout';
import { StoreSelectorField } from '../../../components/store-selector';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';

export const GiveFeedback: FunctionComponent<
  PropsWithChildren<GiveFeedbackProps>
> = ({ navigation }) => {
  const styles = useStyles();
  const { user } = useUserState();
  const { pharmacyId, stores } = useAppStateStore();

  const methods = useForm<PatientFeedback & { location: PharmacyLocationDto }>({
    defaultValues: {
      target: 'app',
      osVersion: Platform.OS,
      appVersion: Constants.expoConfig?.version,
      appName: Constants.expoConfig?.name,
      locationId: undefined,
      location: undefined,
      patientId: '', // TODO: get patientID from user
      pharmacyId: pharmacyId,
      device: Constants.deviceName,
    },
  });

  const { watch } = methods;
  const [target] = watch(['target']);
  const { status, error } = usePatientIntakeState();

  const handleSubmit = async () => {
    const formValues = methods.getValues();
    // data normalization
    formValues.locationId =
      formValues.target === 'app' ? undefined : formValues.location.id;

    updatePatientIntake(formValues);
    methods.reset();
    navigation.navigate('feedback-success');
  };

  // TODO: to discus which state to use for preferred location
  // userState or patientIntakeState
  useEffect(() => {
    const preferredLocation = stores.find(
      (x) => x.id === user?.preferredPharmacyLocationId,
    );
    if (preferredLocation) {
      methods.setValue('location', preferredLocation);
      methods.setValue('locationId', preferredLocation.id);
    }
  }, [stores, user?.preferredPharmacyLocationId]);

  return (
    <ScreenContainer>
      <Text style={styles.message} testID={GiveFeedbackTestIDs.message}>
        {getText('feedback-what-type-of-feedback')}
      </Text>
      <Form methods={methods}>
        <Form.Alert
          intent="error"
          title={getText('feedback-error-occurred')}
          style={styles.alert}
          testID={GiveFeedbackTestIDs.alert}
          visible={!!error}
        />
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              name="target"
              rules={{
                required: getText('feedback-target-is-required'),
              }}
              disabled={status === 'loading'}
              testID={GiveFeedbackTestIDs.target}
              values={[
                {
                  text: getText('app'),
                  value: 'app',
                  description: getText('feedback-how-to-improve-app'),
                },
                {
                  text: getText('store'),
                  value: 'location',
                  description: getText('feedback-give-feedback-about-pharmacy'),
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        {target === 'location' && (
          <Form.Row>
            <Form.Column>
              <StoreSelectorField name="location" options={stores} />
            </Form.Column>
          </Form.Row>
        )}
        <Form.Row>
          <Form.Column>
            <TextField
              placeholder={getText('feedback-feedback')}
              name="feedback"
              rules={{
                required: getText('feedback-feedback-is-required'),
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              testID={GiveFeedbackTestIDs.feedback}
              multiline
              numberOfLines={5}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <CheckboxField
              label={getText('feedback-allowContact')}
              name="allowContact"
              disabled={status === 'loading'}
              testID={GiveFeedbackTestIDs.allowContact}
            />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            testID={GiveFeedbackTestIDs.submit}
            onPress={handleSubmit}
            hierarchy="primary"
            loading={status === 'loading'}
            logger={{ id: 'feedback-submit-button' }}
          >
            {getText('submit')}
          </Button>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.getSpacing(2),
  },
  message: {
    color: theme.palette.gray[900],
    fontSize: 14,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
    width: '100%',
  },
}));

type GiveFeedbackProps = NativeStackScreenProps<
  AccountStackParamList,
  'feedback'
>;
