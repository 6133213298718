import theme, { getSpacing, makeStyles, useTheme } from 'assets/theme';
import React from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Divider } from 'react-native-paper';
import RenderHtml from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';
import {
  PatientEduHowUseIcon,
  PatientEduInteractionsIcon,
  PatientEduMissDoseIcon,
  PatientEduSideEffectsIcon,
  PatientEduStorageIcon,
  PatientEduTellCareIcon,
  PatientEduWatchForIcon,
  PatientEduWhatMedIcon,
} from 'assets/icons';
import { IconProps } from 'assets/icons/types';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
export const EducationInfoSheet = ({ medication }: any) => {
  const theme = useTheme();
  const styles = useStyles();
  const { width } = useWindowDimensions();

  const questionImages: {
    [key: number]: React.FunctionComponent<React.PropsWithChildren<IconProps>>;
  } = {
    0: PatientEduWhatMedIcon,
    1: PatientEduTellCareIcon,
    2: PatientEduHowUseIcon,
    3: PatientEduMissDoseIcon,
    4: PatientEduInteractionsIcon,
    5: PatientEduWatchForIcon,
    6: PatientEduSideEffectsIcon,
    7: PatientEduStorageIcon,
  };
  return (
    medication &&
    medication.patient_educationsheet && (
      <ScrollView style={styles.container}>
        <View>
          {medication.patient_educationsheet.sections.map(
            (section: any, index: any) => (
              <View style={{ marginTop: getSpacing(4) }}>
                <View style={styles.titleWrapper}>
                  <Icon icon={questionImages[index]} size={60} />
                  <Text style={styles.sectionTitle}>{section.title}</Text>
                </View>
                <Divider style={styles.divider} />
                <RenderHtml
                  baseStyle={{ textAlign: 'left' }}
                  contentWidth={width}
                  tagsStyles={tagsStyle as any}
                  source={{ html: section.content }}
                />
              </View>
            ),
          )}
        </View>
      </ScrollView>
    )
  );
};

const tagsStyle = {
  p: {
    ...theme.lumistryFonts.text.medium.regular,
    lineHeight: 24,
  },
  b: {
    fontWeight: '600',
    color: theme.palette.black[700],
  },
};

const useStyles = makeStyles({
  container: {
    marginTop: getSpacing(2),
    borderColor: theme.palette.gray[400],
    borderBottomWidth: 1,
  },
  sectionTitle: {
    flex: 1,
    ...theme.lumistryFonts.text.medium.bold,
    paddingBottom: getSpacing(1.5),
    textAlign: 'left',
    alignSelf: 'center',
  },
  titleWrapper: {
    flexDirection: 'row',
    gap: 16,
  },
  divider: {
    marginTop: getSpacing(2),
    borderColor: theme.palette.gray[400],
    borderBottomWidth: 1,
  },
});
