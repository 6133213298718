import {
  NormalTimeRangeDto,
  ServiceDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
export const isNumber = (str: string): boolean => {
  return /^\d+$/.test(str);
};

export const isZipCode = (str?: string): boolean => {
  return !!str && isNumber(str) && str.length === 5;
};

export const currentDayOpenHours = (hoursAsStr: NormalTimeRangeDto[]) => {
  const day = new Date().getDay();
  const todayOpenHours = hoursAsStr.find((openHour) => {
    return openHour.day === day + 1;
  });
  if (
    !todayOpenHours ||
    (todayOpenHours.start_hour === todayOpenHours.end_hour &&
      todayOpenHours.start_minute === todayOpenHours.end_minute)
  ) {
    return getText('closed');
  } else {
    return getHoursAsStr(todayOpenHours);
  }
};

export const getActiveLocationServices = (locationServices: ServiceDto[]) => {
  let activeLocationServices: string[] = [];
  locationServices.forEach((service) => {
    if (service.type === 'select') {
      service.options.forEach((option) => {
        if (option.selected) {
          activeLocationServices.push(option.name);
        }
      });
    }
    if (service.type === 'boolean') {
      service.options.forEach((option) => {
        if (option.selected) {
          activeLocationServices.push(service.name);
        }
      });
    }
  });
  return activeLocationServices;
};

export const getHoursAsStr = (hours: NormalTimeRangeDto) => {
  const startHour = hours.start_hour;
  const startMinute = hours.start_minute;
  const endHour = hours.end_hour;
  const endMinute = hours.end_minute;

  if (
    (startHour === endHour && startMinute === endMinute) ||
    startHour === null ||
    endHour === null
  ) {
    return getText('closed');
  }

  const startHourStr =
    startHour % 12 === 0 ? '12' : (startHour % 12).toString();
  const endHourStr = endHour % 12 === 0 ? '12' : (endHour % 12).toString();
  const startMinuteStr =
    startMinute < 10 ? `0${startMinute}` : startMinute.toString();
  const endMinuteStr = endMinute < 10 ? `0${endMinute}` : endMinute.toString();
  const startAmPm = startHour < 12 ? 'AM' : 'PM';
  const endAmPm = endHour < 12 ? 'AM' : 'PM';
  return `${startHourStr}:${startMinuteStr}${startAmPm} - ${endHourStr}:${endMinuteStr}${endAmPm}`;
};

export const getDayAsStr = (hours: NormalTimeRangeDto) => {
  switch (hours.day) {
    case 1:
      return getText('sunday');
    case 2:
      return getText('monday');
    case 3:
      return getText('tuesday');
    case 4:
      return getText('wednesday');
    case 5:
      return getText('thursday');
    case 6:
      return getText('friday');
    case 7:
      return getText('saturday');
    default:
      return '';
  }
};
