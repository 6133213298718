import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const NurseDeskIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, colorSecondary, size, testID }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={colorSecondary || theme.colors.brandedText}
        d="M35.917 57.183h-3.474v3.491l-3.474-.002v3.476h3.474v3.474h3.474v-3.474h3.474v-3.491l-3.474.017v-3.491Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        d="M5.146 51.731v27.795h2.978V54.71h52.112v24.817h2.977V51.731h-8.02c-.25-3.553-.953-9.015-1.928-12.167-.353-1.137-1.815-1.706-3.068-2.193-.278-.108-.545-.213-.788-.318-.343-.148-1.091-.282-1.762-.402-.441-.079-.848-.151-1.084-.218-.132 1.092-.723 2.136-1.564 3.186l-3.127 4.246v7.866h-1.985v-8.189h.003c0-.203.062-.407.19-.586l1.413-1.913c-1.487-1.546-2.64-3.03-2.834-4.61-.236.067-.643.14-1.085.219-.67.12-1.416.253-1.759.401-.243.105-.51.21-.788.318-1.253.487-2.715 1.056-3.068 2.193-.975 3.152-1.677 8.614-1.928 12.167H5.146Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        d="M11.102 70.094v9.432h2.978v-6.454h40.448v6.454h2.978v-9.432H11.102Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        fillRule="evenodd"
        d="M41.495 24.065c.616 1.975 3.477 4.037 5.695 4.146-.091 2.775-1.248 4.733-2.196 5.708 1.33.2 4.353.305 5.122.2 2.132-4.064.864-10.255-2.248-13.29l.255-3.003a.993.993 0 0 0-.826-1.06l-4.501-.749a1.018 1.018 0 0 0-.348-.005l-4.469.747a.99.99 0 0 0-.88 1.067l.255 3.003c-3.115 3.05-4.378 9.229-2.248 13.29.967.085 3.605 0 5.124-.2-1.25-1.288-2.278-3.477-2.201-6.747 1.58-.315 2.923-1.338 3.392-3.107h.074Zm4.276-1.985h-6.32l-.298-3.51 3.459-.575 3.457.576-.298 3.509Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
