import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Platform, View } from 'react-native';
import * as Sentry from '@sentry/react-native';
import { Button } from 'assets/components/button';
import { NativeHeader } from 'assets/components/native-header';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';

export const ErrorBoundary: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const styles = useStyles();

  return (
    <Sentry.ErrorBoundary
      onError={(error) => logError(error)}
      fallback={({ resetError }) => (
        <>
          <NativeHeader title={getText('unexpected-error')} />
          <View style={styles.container}>
            <Text style={styles.title}>{getText('error-occurred-title')}</Text>
            <Text style={styles.subtitle}>
              {getText('error-occurred-description')}
            </Text>
            <View style={styles.buttonContainer}>
              <Button
                onPress={resetError}
                hierarchy="primary"
                logger={{ id: 'error-boundary-reset' }}
              >
                {getText('home')}
              </Button>
            </View>
          </View>
        </>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    ...theme.lumistryFonts.label.large.bold,
    textAlign: 'center',
  },
  subtitle: {
    ...theme.lumistryFonts.label.small.regular,
    color: theme.palette.gray[700],
    paddingHorizontal: 50,
    paddingVertical: theme.getSpacing(3),
    textAlign: 'center',
  },
  buttonContainer: {
    width: Platform.OS === 'web' ? 400 : '70%',
  },
}));
