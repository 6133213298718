import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigation } from '@react-navigation/native';
import { getText } from 'assets/localization/localization';
import { BookingStep, useBookAppointmentState } from './book-appointment-store';
import {
  createBooking,
  disableNextButton,
  resetBookAppointment,
  setProcessError,
  setShowBookAppointment,
  setStep,
  setLocation,
  updateBooking,
  clearLocation,
  setStepsMap,
  setFormSubmissions,
  setCurrentForm,
  getAppointmentTypeDetails,
  setSelectedSlot,
} from './book-appointment-actions';
import { BookAppointmentSteps } from './BookAppointmentSteps';
import {
  CreateBookingDto,
  UpdateBookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { useUserState } from '../../../store/user-store';
import { formatDateTimeApi } from '../../../common/datetime-utils';
import moment from 'moment';
import { useAppStateStore } from '../../../store/app-store';
import { refreshAppointmentsList } from '../appointments-actions';
import {
  refreshAppointmentDetails,
  setOpenFormFillModal,
} from '../../appointment/appointment-details-actions';
import { AppointmentsScreenRouteProp } from '../../../navigation/AppNavigation';
import { AddToCalendar, AddToCalendarHandler } from './AddToCalendar';
import { getMinutesBetweenDates } from './book-appointment-utils';
import { BookAppointmentPatientRecord } from './BookAppointmentPatientRecord';
import { BookAppointmentDate } from './BookAppointmentDate';
import { BookAppointmentTime } from './BookAppointmentTime';
import { BookAppointmentConfirmation } from './BookAppointmentConfirmation';
import { BookAppointmentForms } from './BookAppointmentForms';
import { setStatus } from '../../fill-in-form/fill-in-form-actions';
import {
  CloseConfirmation,
  CloseConfirmationHandler,
} from './CloseConfirmation';
import { FormStatus } from '@digitalpharmacist/forms-service-client-axios';
import { LoadingIndicator } from '../../../../../../packages/assets/components/loading-indicator';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { usePatientRecordState } from '../../account/patient/patient-store';

export const BookAppointment: FunctionComponent<BookAppointmentProps> = ({
  onDismiss,
}) => {
  const sheetRef = useRef<BaseModalHandler>(null);
  const addToCalendarRef = useRef<AddToCalendarHandler>(null);
  const closeConfirmationRef = useRef<CloseConfirmationHandler>(null);

  const {
    step,
    stepName,
    stepsMap,
    nextButtonStatus,
    selectedSlot,
    bookingStatus,
    showBookAppointment,
    appointmentType,
    isReschedule,
    booking,
    location,
    processError,
    selectedPatient,
    selectedLocation,
    locationAppointmentTypeId,
    formSubmissions,
    currentForm,
    validFormsCount,
    appointmentTypeDetails,
    appointmentTypeDetailsStatus,
    availableAppointmentLocationsStatus,
    slotsStatus,
  } = useBookAppointmentState((state) => ({
    ...state,
    locationAppointmentTypeId: state.availableAppointmentLocations.find(
      (item) => item.location_id === state.selectedLocation?.id,
    )?.appointment_type_ids[0],
    validFormsCount:
      state.appointmentTypeDetails?.forms.filter(
        (form) => form.form_status === FormStatus.Enabled,
      ).length ?? 0,
  }));
  const { user } = useUserState();
  const { pharmacyId, stores } = useAppStateStore();
  const navigation = useNavigation<AppointmentsScreenRouteProp>();
  const [bookingSteps, setBookingSteps] = useState<JSX.Element[]>([]);
  const { status: patientRecordStatus } = usePatientRecordState();

  const handleOnAddToCalendar = () => {
    addToCalendarRef.current?.show();
  };

  const handleCloseConfirmation = () => {
    closeConfirmationRef.current?.show();
  };

  useEffect(() => {
    const steps = [
      <BookAppointmentPatientRecord navigation={navigation} />,
      <BookAppointmentDate />,
      <BookAppointmentTime />,
      <BookAppointmentConfirmation
        onAddToCalendarPress={handleOnAddToCalendar}
        areFormsOptional={appointmentTypeDetails?.forms_optional}
      />,
    ];

    if (isReschedule) {
      steps.shift();
      setStepsMap([
        BookingStep.Date,
        BookingStep.Time,
        BookingStep.Confirmation,
      ]);
    } else if (
      !isReschedule &&
      validFormsCount &&
      appointmentTypeDetails?.forms_optional === false
    ) {
      steps.splice(3, 0, <BookAppointmentForms />);
      setStepsMap([
        BookingStep.Patient,
        BookingStep.Date,
        BookingStep.Time,
        BookingStep.Forms,
        BookingStep.Confirmation,
      ]);
    }

    setBookingSteps(steps);
  }, [isReschedule, appointmentType, validFormsCount]);

  useEffect(() => {
    if (location && appointmentType && !appointmentTypeDetails) {
      getAppointmentTypeDetails(pharmacyId, location.id, appointmentType.id);
    }
  }, [location, appointmentType]);

  useEffect(() => {
    if (bookingStatus === 'success' || bookingStatus === 'error') {
      setStep(step + 1);
    }
  }, [bookingStatus]);

  useEffect(() => {
    if (showBookAppointment) {
      sheetRef.current?.show();
    } else {
      sheetRef.current?.hide();
    }

    // check if there is a selected location for the book appointment screen
    if (showBookAppointment && !location && user?.preferredPharmacyLocationId) {
      // use preferred location as a fallback
      const findPreferredLocation = stores.find(
        (store) => store.id === user.preferredPharmacyLocationId,
      );
      setLocation(findPreferredLocation!);
    }

    if (!showBookAppointment && location) {
      // clear configured location on modal close
      clearLocation();
    }
  }, [showBookAppointment]);

  const modalButtonsDisabled = useMemo(
    () =>
      availableAppointmentLocationsStatus === 'loading' ||
      slotsStatus === 'loading' ||
      bookingStatus === 'loading' ||
      patientRecordStatus === 'loading',
    [
      availableAppointmentLocationsStatus,
      slotsStatus,
      bookingStatus,
      patientRecordStatus,
    ],
  );

  const handleDismiss = () => {
    setShowBookAppointment(false);
    if (stepName === BookingStep.Forms) {
      handleCloseConfirmation();
    } else {
      dismissModal();
    }
  };

  const handleConfirmClose = () => {
    dismissModal();
  };

  const dismissModal = () => {
    if (bookingStatus === 'success') {
      refreshAppointmentsList();

      if (isReschedule) {
        refreshAppointmentDetails();
      }
    }

    resetBookAppointment();
    onDismiss();
  };

  const handleNextPress = () => {
    if (step < stepsMap.indexOf(BookingStep.Confirmation) - 1) {
      setStep(step + 1);
    }

    if (step < stepsMap.indexOf(BookingStep.Time)) {
      disableNextButton();
      setSelectedSlot(undefined);
    }

    if (step < stepsMap.indexOf(BookingStep.Forms)) {
      disableNextButton();
    }

    if (step === stepsMap.indexOf(BookingStep.Confirmation) - 1) {
      handleBooking();
    }

    if (stepName === BookingStep.Confirmation) {
      if (
        validFormsCount &&
        appointmentTypeDetails?.forms_optional &&
        location &&
        booking
      ) {
        setOpenFormFillModal(true);
        navigation.navigate('appointment', {
          appointment_id: booking.id,
          location_id: location.id,
        });
      }

      setShowBookAppointment(false);
      handleDismiss();
    }

    if (processError) {
      setProcessError(false);
    }
  };

  const handleBackPress = () => {
    if (
      stepName === BookingStep.Confirmation &&
      appointmentTypeDetails?.forms_optional
    ) {
      dismissModal();
      return;
    }
    setStep(step - 1);
    setFormSubmissions([]);
    setCurrentForm(0);

    if (processError) {
      setStatus('idle');
      setProcessError(false);
    }
  };

  const handleBooking = () => {
    if (isReschedule) {
      handleRescheduleBooking();
    } else {
      handleCreateBooking();
    }
  };

  const handleCreateBooking = () => {
    if (!selectedLocation || !selectedPatient || !locationAppointmentTypeId)
      return;

    const isRecordUnderCare = 'record_under_care' in selectedPatient;

    const locationAppointmentTypeOverride =
      location!.id !== user!.preferredPharmacyLocationId
        ? locationAppointmentTypeId
        : appointmentType!.id;

    const bookingData: CreateBookingDto = {
      appointment_type_id: locationAppointmentTypeOverride,
      patient_user_id: user!.id,
      patient_record_id: isRecordUnderCare
        ? selectedPatient.record_under_care.id
        : selectedPatient.id,
      title: appointmentType!.title,
      description: appointmentType!.description,
      startTime: selectedSlot!.time,
      endTime: formatDateTimeApi(
        moment(selectedSlot!.time).add(appointmentType!.length, 'minutes'),
      ),
      timeZone: 'US/Central',
      submissions: formSubmissions,
      isPatientCreatedBooking: true,
    };

    createBooking(pharmacyId, selectedLocation.id, bookingData);
  };

  const handleRescheduleBooking = () => {
    if (!booking || !selectedLocation) return;

    const bookingData: UpdateBookingDto = {
      ...booking,
      startTime: selectedSlot!.time,
      endTime: formatDateTimeApi(
        moment(selectedSlot!.time).add(appointmentType!.length, 'minutes'),
      ),
      timeZone: undefined,
    };

    updateBooking(pharmacyId, selectedLocation.id, booking.id, bookingData);
  };

  const getOkButtonText = () => {
    if (stepName === BookingStep.Confirmation) {
      return appointmentTypeDetails?.forms_optional
        ? getText('fill-out-forms')
        : getText('done');
    }

    return getText('next');
  };

  const getCancelButtonText = () => {
    if (
      stepName === BookingStep.Confirmation &&
      appointmentTypeDetails?.forms_optional
    ) {
      return getText('do-it-later');
    }
    return getText('back');
  };

  const getTitle = () => {
    if (isReschedule) {
      return getText('reschedule-appointment');
    }

    if (stepName === BookingStep.Forms) {
      return `${appointmentType!.title} (${currentForm + 1} ${getText(
        'of',
      )} ${validFormsCount})`;
    }

    return getText('book-appointment');
  };

  return (
    <>
      <GenericModal
        title={getTitle()}
        ref={sheetRef}
        isScrollable
        webModalSize="lg"
        webModalProps={{
          contentContainerStyle: { flex: 1 },
          scrollViewStyle: { flex: 1 },
        }}
        nativeModalBodyStyle={{ flex: 1 }}
        nativeModalProps={{ contentContainerStyle: { flex: 1 } }}
        onClose={handleDismiss}
        buttons={[
          {
            onPress: handleNextPress,
            logger: { id: 'book-appointment-ok-button-modal' },
            text: getOkButtonText(),
            disabled: nextButtonStatus === 'disabled' || modalButtonsDisabled,
            loading: bookingStatus === 'loading',
            hierarchy: 'primary',
          },
          (step > 0 && step < bookingSteps.length - 1) ||
          (stepName === BookingStep.Confirmation &&
            appointmentTypeDetails?.forms_optional)
            ? {
                onPress: handleBackPress,
                text: getCancelButtonText(),
                logger: { id: 'book-appointment-back-button-modal' },
                hierarchy: 'tertiary-gray',
                disabled: modalButtonsDisabled,
              }
            : {
                onPress: handleDismiss,
                logger: { id: `book-appointment-done` },
                text: getText('do-it-later'),
                hierarchy: 'tertiary-gray',
              },
        ]}
      >
        {appointmentTypeDetailsStatus === 'loading' ? (
          <LoadingIndicator />
        ) : (
          <BookAppointmentSteps
            navigation={navigation}
            bookingSteps={bookingSteps}
          />
        )}
      </GenericModal>

      {booking && (
        <AddToCalendar
          ref={addToCalendarRef}
          title={
            selectedLocation?.name
              ? `${getText('appointment-at')} ${selectedLocation.name}`
              : getText('pharmacy-appointment')
          }
          notes={''}
          startDate={new Date(booking.startTime)}
          endDate={new Date(booking.endTime)}
          location={`${
            selectedLocation?.address?.address1
              ? selectedLocation.address.address1 + ','
              : ''
          } ${
            selectedLocation?.address?.city
              ? selectedLocation.address.city + ','
              : ''
          } ${
            selectedLocation?.address?.state
              ? selectedLocation.address.state + ','
              : ''
          } ${
            selectedLocation?.address?.postal_code
              ? selectedLocation.address.postal_code + ','
              : ''
          }`}
          durationInMinutes={getMinutesBetweenDates(
            booking.startTime,
            booking.endTime,
          )}
        />
      )}

      <CloseConfirmation
        ref={closeConfirmationRef}
        onConfirm={handleConfirmClose}
      />
    </>
  );
};

export interface BookAppointmentProps {
  onDismiss: () => void;
}
