import { TranslationMapping } from "../localization";

const mapping: TranslationMapping = {
  "complete-checklist-items-in-order": "You must complete the Checklist items in order",
  "answer-following-questions": "Please answer the following questions about your patient-facing website.",
  "build-replace-website": "We would like Lumistry to build a website to replace my existing one.",
  "build-new-website": "We would like Lumistry to build a website because we don't have one.",
  "no-build-existing-website": "We don't want Lumistry to build a website because we already have one.",
  "no-build-no-existing-website": "We don't want Lumistry to build a website and we don't have one.",
  "brand-color-required": "Brand color is required.",
  "website-access": "Website access",
  "website-featured-services": "Website featured services",
  "select-6-services": "Please select up to 6  to highlight on your website.",
  "selected-services-tooltip-text": "The selected services will be displayed in the website navigation and will have dedicated sections on the website.",
  "pharmacy-service-details": "Pharmacy service details",
  "indicate-type-of-compounding":"Indicate the type of compounding that you offer:",
  "website-you-like-to-use": "Do you have a website domain you would like to use?",
  "website-how-to-access-domain": "Do you know how to access your domain?",
  "website-domain-first-tooltip": "A website domain is a special name you type into the computer to go to a specific website on the internet. An example of a website domain (or the address of the website) would be www.yourpharmacy.com",
  "website-domain-second-tooltip": "Do you have access to the username and password of the domain registrar that holds the domain.",
  "website-completion-gratitude": "Thank you for completing the website setup. A Lumistry team member will reach out to help you complete the rest of the website process.",
  "publish-website-need-permission": "To publish your new website to an existing domain Lumistry needs permission to login to your domain host on your behalf.",
  "give-lumistry-permission": "I give Lumistry permission access the following domain.",
  "please-provide-domain-details": "Please provide the following details about your domain",
  "domain-name": "Domain name",
  "domain-host": "Domain host",
  "login-user-name": "Login user name",
  "login-password": "Login password",
  "checklist-app-config-submission-title": "Patient App",
  "checklist-app-config-submission-message": "Are you sure you are ready to submit your patient app to be published? ",
  "checklist-app-config-submission-description": "Once submitted, your patient app will have to be approved by the App Store and Google play before it can be listed. Once approved, any changes to branding, such as logos or color, must be re-approved by the App Store. ",
  "checklist-app-config-app-dev-account":"Apple Dev Account",
  "checklist-app-config-app-dev-account-desc": "An account will be created on your behalf using the following info:",
  "checklist-app-config-name":"App name",
  "checklist-app-config-name-required":"App name is Required",
  "checklist-app-config-name-hint": "Appears in the mobile app (36 character limit)",
  "checklist-app-config-name-max-character": "Max limit 36 character",
  "checklist-app-config-dev-account": "Dev Account",
  "checklist-app-config-dev-account-description":"An account will be created on your behalf using the following info:",
  "checklist-app-config-duns-placeholder":"Select D-U-N-S",
  "checklist-app-config-duns-required":"D-U-N-S is required",
  "checklist-app-config-duns-tooltip": "Select the D-U-N-S you’d like associated with your patient app. This is often your primary location.",
  "checklist-app-config-market-name": "App marketplace name",
  "checklist-app-config-market-name-description": "Appears in Apple App Store and Google Play (30 character limit)",
  "checklist-app-config-market-name-max-characters": "Max limit 30 character",
  "checklist-app-config-market-name-not-valid":"Not valid",
  "checklist-app-config-market-name-required": "App marketplace name is Required",
  "checklist-app-config-title": "App Settings",
  "checklist-app-config-id-title":"Driver’s license or government I.D.",
  "checklist-app-config-id-tooltip":"ID is used to gain approval for your App from App Store & Google Play",
  "checklist-app-config-website-url":"Website URL",
  "checklist-app-config-website-url-required": "Website URL is required",
  "checklist-app-config-website-url-not-valid": "Website URL is not valid",
  "select-brand-color": "Select your brand's primary color",
  "specs-first-item": "10 MB size limit",
  "image-is-required": "Image is required",
  "valid-url": "Please enter a valid URL",
  "specs-second-item": "PNG or SVG format",
  "specs-third-item": "Transparent background",
  "brand-color": "Brand color",
  "white-text-readable": "White text must be readable on top of this color. Bright colors are restricted.",
  "logo-and-icon": "Logo and icon",
  "high-quality-graphics": "High-quality graphics are key to a professional-looking app and website. See specs:",
  "logo-light-bg": "Logo on light background",
  "logo-dark-bg": "Logo on dark background (Optional)",
  "icon-apple-app": "Icon for Apple app",
  "icon-android-app": "Icon for Android app",
  "social-media": "Social media",
  "links-appear": "These links will appear on the healthsite and other patient communications.",
  "fb-url": "Facebook URL",
  "insta-url": "Instagram URL",
  "twitter-url": "X (Twitter) URL",
  "setup-checklist": "Setup Check list",
  "need-to-do-first": "What you need to do first...",
  "complete-healthsite-patient": "The healthsite and patient app items need to be completed as soon as possible so that your pharmacy's patient experience can be activated.Please complete these items first.",
  "required": "Required",
  "patient-agreements": "Patient agreements",
  "patient-agreements-description": "Terms of Use and a Privacy Policy must be added to your app for your patients to agree to.",
  "patient-agreements-use-template": "Use template",
  "patient-agreements-upload-pdf":"Upload pdf...",
  "patient-agreements-terms-option": "Terms of Use options",
  "patient-agreements-terms-template": "Terms of Use template",
  "patient-agreements-privacy-option": "Privacy Policy options",
  "patient-agreements-privacy-template": "Privacy Policy template",
  "patient-agreements-legal-notice": "Legal notice TBD",
  "patient-website": "Patient website",
  "checklist-item-completed": "This checklist item is already completed",
  "other-settings": "Other settings",
  "checklist-items-in-settings": "These checklist items can also be found in the Settings area.",
  "basic-setup": "Basic setup",
  "media-settings": "Media settings",
  "patient-app": "Patient app",
  "scanner-config": "Scanner config",
  "clinical-services": "Clinical services",
  "refill-settings": "Refill settings",
  "message-settings": "Message settings",
  "patient-agreements-desc": "Setup your Terms of Use and Privacy Policy for patients",
  "patient-website-desc": "Setup and connect your pharmacy's website.",
  "basic-setup-desc": "Thank you for completing the step-by-step setup.",
  "media-settings-desc": "Branding, social media, and patient agreements",
  "patient-app-desc": "Enter your pharmacy's branding for your pharmacy's patient app.",
  "scanner-config-desc": "High priority. Required step for using barcode scanners",
  "clinical-services-desc": "Select bookable services  your pharmacy offers",
  "refill-settings-desc": "Custom error copy, reminder periods, auto-refill options, more....",
  "message-settings-desc": "Patient initiated messages, set auto-response to messages, more...",
  "checklist-card-title":"Setup checklist",
  "checklist-card-subtitle":"Customize and get to know Lumistry.",
  "checklist-card-button":"Open checklist",
  "save-logout": "Save & Logout",
  "on-premise-equipment": "On-premise equipment",
  "ob-something-went-wrong":"Something went wrong, please contact help and support.",
  "ob-address-title": 'Addresses',
  "ob-address-description": 'Please provide the following address information.',
  "ob-multi-location-title": "Location details",
  "ob-multi-location-subtitle":"Please complete each section.",
  "ob-multi-review-brand-name":"Brand name",
  "ob-multi-review-number-locations": "Number of locations",
  "pharmacy-store-address":'Pharmacy store address',
  "pharmacy-store-address-tooltip":'The physical location of the pharmacy',
  "account-billing-address":'Account billing address',
  "account-billing-address-tooltip": 'Address associated with your payment method',
  "account-shipping-address": 'Account Shipping Address',
  "denied-password-change-text": "You've created your patient portal account using SSO, such as Google or Apple sign-on. To update your password, you will need to go into your SSO settings",
  "account-shipping-address-tooltip": 'Optional alternate shipping address',
  "same-as-pharmacy-store-address": "Same as Pharmacy Store Address",
  "add-employee": "Add employee",
  "ob-employees-title": "Employees",
  "ob-employees-generic-error": "Some entries are required or invalid. Please check all rows.",
  "ob-hours-generic-error": "Some entries are invalid. Please check all data.",
  "ob-employees-description": "Enter employees and assign them a role. Roles determine what actions an employee has permission to take. Use the following descriptions to assign the correct role.",
  "pms-does-not-require-opie-desc": "Your pharmacy management software does not require an onsite device to connect with Lumistry. You may continue to the next step.",
  "pms-requires-opie-desc": "Lumisty will integrate with your pharmacy management system through a physical device. This device will be shipped once you complete this setup and an installation call will be scheduled.",
  "have-it-person": "Do you have an IT person that helps when your network or firewall needs updating?* ",
  "do-not-know": "I don't know",
  "firewall-provider-is-not-valid": "Firewall provider is not valid",
  "technician-name-is-not-valid": "Technician name is not valid",
  "preferred-time-is-required": "Preferred time is required",
  "technician-email-is-not-valid": "Technician email is not valid",
  "technician-phone-is-not-valid": "Technician phone number is not valid",
  "recipient-name-is-not-valid": "Recipient name is not valid",
  "recipient-name-is-required": "Recipient name is required",
  "city-is-not-valid": "City is not valid",
  "firewall-provider": "Firewall provider",
  "name-is-not-valid": "Name is not valid",
  "brand-name-is-not-valid": "Brand name is not valid",
  "parent-company-is-not-valid": "Parent company is not valid",
  "pharmacy-name-is-not-valid": "Pharmacy name is not valid",
  "phone-is-required": "Phone number is required",
  "legal-entity-name-is-not-valid": "Legal entity name is not valid",
  "dba-is-not-valid":"DBA is not valid",
  "pharmacy-email-address-is-not-valid": "Pharmacy email address is not valid",
  "pharmacy-ncpdp-is-not-valid": "NCPDP is not valid",
  "pharmacy-npi-is-not-valid": "NPI is not valid",
  "fax-is-not-valid":"Fax number is not valid",
  "firewall-provider-is-required": "Firewall provider is required",
  "faq": "FAQ",
  "visit-help-center": "Visit the help center",
  "recipient_name":"Recipient name",
  "technician-name": "Technician name",
  "error-occured-ob-save": "An error occurred. Please try again!",
  "technician-name-is-required": "Technican name is required",
  "upload-a-pdf": "Upload a pdf...",
  "technician-email": "Technician email",
  "technician-email-is-required": "Technician email is required",
  "technician-phone": "Technician phone number",
  "technician-phone-is-required": "Technician phone number is required",
  "address-device-shipped": "What address would you like the device shipped to?*",
  "po-boxes-not-accepted": "P.O. boxes will not be accepted",
  "ship-to-pharmacy": "Ship to Pharmacy store address",
  "select-preferred-weekdays": "Please select your preferred day(s) of the week for the remote installation session:*",
  "use-defaults": "Use defaults",
  "select-all": "Select all",
  "store-general-hours": "These are your store’s general hours.",
  "holidays": "Holidays",
  "jurisdiction": "Jurisdiction",
  "jurisdiction-is-not-valid": "Jurisdiction is not valid",
  "jurisdiction-is-required": "Jurisdiction is required",
  "additional-closures-may-be-added": "Additional closures may be added later in settings.",
  "am-or-pm-installations": "Is AM or PM preferred for a remote installation phone call?*",
  "status-is-required": "Status is required",
  "signup": "Create an account",
  "login": "Login",
  "username": "Username",
  "password": "Password",
  "dashboard": "Home",
  "medications": "Medications",
  "unknown": "Unknown",
  "import": "Import",
  "messages": "Messages",
  "profile": "Profile",
  "get-care": "Get Care",
  "open-settings": "Open Settings",
  "anonymous-refill": "Refill without an account",
  "apple-signup": "Sign up with Apple",
  "apple-continue": "Continue with Apple",
  "apple-login": "Apple login",
  "lets-get-to-know-you": "Let's get to know you",
  "assigned-to": "Assigned to",
  "assignee-required": "Assignee is required",
  "sort-by-due-date": "Sort by due date",
  "sort-by-assignee": "Sort by assignee",
  "sort-by-task-summary": "Sort by task summary",
  "ascending": "Ascending",
  "descending": "Descending",
  "arabic": "Arabic",
  "chinese": "Chinese",
  "vietnamese": "Vietnamese",
  "korean": "Korean",
  "german": "German",
  "russian": "Russian",
  "fullname": "Full name",
  "nobody": "Nobody",
  "welcome": "Welcome",
  "no-services": "No services",
  "logout": "Logout",
  "make-primary": "Make primary",
  "make-default": "Make default",
  "default-availability": "Default availability",
  "updated": "Updated {{date}}",
  "front": "Front",
  "chat": "Chat",
  "back": "Back",
  "otc-medications": "Over-The-Counter Medications",
  "overdue": "Overdue",
  "next": "Next",
  "not-yet-entered": "Not yet entered",
  "scan-barcode": "Scan Barcode",
  "add-card": "Add a card...",
  "app-settings": "App and Website",
  "branding": "Branding",
  "primary-color": "Primary color",
  "text-color": "Text color",
  "accent": "Accent",
  "invalid-hex-value": "Invalid hex value",
  "edit-brand-colors": "Edit Brand Colors",
  "edit-location": "Edit Location",
  "logo-light": "Logo on light",
  "logo-dark": "Logo on dark",
  "icon-android": "Icon Android",
  "icon-ios": "Icon iOS",
  "primary-secondary-photo-id": "Primary, secondary, photo I.D",
  "scanned": "Scanned",
  "save": "Save",
  "search-locations": "Search Locations",
  "reminder-forms": "This service has forms that you must complete prior to your appointment",
  "forgot-password": "Forgot password?",
  "coming-up": "Coming up:",
  "view": "View",
  "png-only": "Only PNG images are supported",
  "valid-image-type": "Only JPG, PNG or SVG images are supported",
  "valid-icon-dimensions": "Icon dimensions should be 1024x1024",
  "back-to-login": "Back to login",
  "camera-access-denied": "Unable to access camera, please go to your app settings and grant camera access.",
  "username-is-required": "Username is required",
  "password-is-required": "Password is required",
  "summary-is-required": "Summary is required",
  "recurrence": "Recurrence",
  "recurring": "Recurring",
  "high-priority": "High priority",
  "view-full-profile": "View Full Profile",
  "insurance-card": "Insurance Card",
  "barcode-scanner": "Barcode Scanner",
  "english": "English",
  "spanish": "Spanish",
  "french": "French",
  "frequency": "Frequency",
  "yes": "Yes",
  "no": "No",
  "continue": "Continue",
  "first-name": "First name",
  "first-name-is-required": "First name is required",
  "last-name": "Last name",
  "last-name-is-required": "Last name is required",
  "email-short": "Email",
  "email": "Email address",
  "email-capital-case": "Email Address",
  "every-month": "Every month",
  "months": "months",
  "last-day": "Last Day",
  "reoccurrence-must-have-context": "Reoccurrence field must have a parent form context",
  "start-date-lower-end-date": "Start date needs to be set before the end date.",
  "must-valid-domain": "Must be a valid domain",
  "email-is-required": "Email is required",
  "email-continue": "Continue with email",
  "mobile-number": "Mobile number",
  "mobile-number-is-required": "Mobile number is required",
  "birth-date": "Date of birth (mm/dd/yyyy)",
  "coming-soon": "Planned for MVP, not yet available",
  "section-on-the-roadmap": "{{section}} on the roadmap, not yet available",
  "insurance-and-id-cards": "Insurance and I.D. Cards",
  "primary": "Primary",
  "secondary": "Secondary",
  "id-card": "I.D. Card",
  "bottle-cap-preference": "Bottle cap preference",
  "allergies-conditions-caregivers": "Allergies, conditions, etc.",
  "insurance-id-card": "Insurance and I.D. Cards",
  "patient-forms": "Patient Forms",
  "communication-preferences": "Communication Preferences",
  "sign-out": "Sign out",
  "high": "High",
  "low": "Low",
  "medium": "Medium",
  "submission": "Submission",
  "subtitle": "Subtitle",
  "no-submission-found": "No submissions found",
  "didnt-receive-text-message": "Didn't receive the text message?",
  "phone-number-is-sms-capable": "Please make sure that the phone number\nyou entered is SMS-capable or resend code.",
  "email-address-is-correct": "Please make sure that the email address\nyou entered is correct or resend code.",
  "standard-text-messages-apply": "Standard text messaging rates may apply\ndepending on your service provider.",
  "standard-text-apply": "Standard messaging rates may apply depending\non your service provider.",
  "birth-date-is-required": "Date of birth is required",
  "lets-get-started": "Let's get started by creating an account.",
  "front-of-card": "Front of card",
  "back-of-card": "Back of card",
  "please-upload-image": "Please upload an image",
  "service-required": "Service is required",
  "add-front-of-card": "Add front of card",
  "add-back-of-card": "Add Back of card",
  "cancel": "Cancel",
  "cancelations": "Cancelations",
  "retake": "Retake",
  "signature": "Signature",
  "use-photo": "Use Photo",
  "password-must-include": "Password must include:",
  "at-least-8-characters": "At least 8 characters",
  "task-type-required": "Task type title is required",
  "task-type-title-exceeded": "The title must not exceed 30 characters",
  "task-type-description-required": "Task type description is required",
  "task-type-description-exceeded": "The description must not exceed 130 characters",
  "at-least-1-number": "At least 1 number",
  "characters": "characters",
  "at-least-1-capital-letter": "At least 1 capital letter",
  "at-least-1-lowercase-letter": "At least 1 lowercase letter",
  "at-least-1-special-character": "At least 1 special character",
  "privacy-policy-error": "Please read the privacy policy before agreeing",
  "privacy-policy-agree": "I have read and agree to the Privacy Policy",
  "privacy-policy-email-signup": "Sign up for email from my pharmacy",
  "pharmacy-privacy-policy": "Pharmacy Privacy Policy",
  "terms-and-conditions": "Software Provider Terms & Conditions",
  "terms-of-service": "Terms and Conditions",
  "terms-of-service-error": "Please read the terms of service before agreeing",
  "terms-of-service-agree": "I have read and agree to the Terms and Conditions",
  "terms-of-service-email-signup": "Sign up for email from software provider",
  "and": "and",
  "submit": "Submit",
  "update-personal-info": "Update Personal Info",
  "name-phone-email": "Name, phone, email, etc.",
  "2-of-2-agreements": "2 of 2 agreements",

  "medical-info-step-3": "Step 3 of 8 / Add your medical information",
  "password-entry-does-not-meet-criteria": "Password entry does not meet criteria",
  "password-entries-do-not-match": "Password entries do not match",
  "create-your-account": "Create Your Account",
  "please-enter-your-new-password": "Please enter your new password",
  "email-is-not-valid": "Email is not valid",
  "phone-is-not-valid": "Phone number is not valid",
  "create-account": "Create account",
  "confirm-password": "Confirm password",
  "patient-info-step": "Step x of y / Confirm your information",
  "communications-language": "Communications Language",
  "prescriptions-communications": "Prescription Communications: How would you like to be notified when your prescriptions are ready?",
  "automated-voice-call": "Automated Voice Call",
  "text-message": "Text message",
  "app-notifications": "App Notifications",
  "all-priorities": "All priorities",
  "all-visibilities": "All visibilities",
  "all-types": "All types",
  "all-other-pharmacy-communications": "All other pharmacy communications",
  "same-time-preferences": "Would you like for these prescriptions to be ready at the same time each month?",
  "additional-information": "Additional information",
  "visually-impaired": "Visually Impaired",
  "hearing-impaired": "Hearing Impaired",
  "easy-open-bottle-caps": "Easy-open bottle caps",
  "personal-info-step-2": "Step 2 of 7 / Add your personal information",
  "country": "Country",
  "country-is-required": "Country is required",
  "state": "State",
  "state-is-required": "State is required",
  "city": "City",
  "city-is-required": "City is required",
  "street-address": "Street Address",
  "street-address-is-required": "Street Address is required",
  "street-address-line-2": "Street Address Line 2",
  "zip-code": "ZIP Code",
  "zip-code-is-required": "ZIP Code is required",
  "gender-is-required": "Gender is required",
  "male": "Male",
  "female": "Female",
  "no-patients-yet": "No patients yet...",
  "integrate-pms-to-see-patients": "Integrate with your PMS to see patients here.",
  "sent": "Sent",
  "how-to-add-caregiver": "How do I add a caregiver?",
  "add-caregiver-details": " If you would like to add a caregiver to help you manage your health digitally, they will first need to add you as a patient under their care. After that, you can approve or deny their request for caregiver access to your records.  ",
  "patients-under-care": "Patients under care",
  "caregivers": "Caregivers",
  "verification-code-resent": "Verification code resent",
  "please-enter-code": "Please enter the code we sent to",
  "didnt-receive-email": "Didn't receive the email? ",
  "please-check-spam-folder": "Please check your spam folder or resend code",
  "resend-code": "Resend Code",
  "restriction": "Restrictions",
  "reset-password": "Reset password",
  "reset-link-sent": "If the email is associated with an active account, you should receive a reset link.",
  "please-enter-email": "Please enter the email address\nassociated with your account",
  "how-would-you-like-to-receive-reset-link": "How would you like to receive the reset link?",
  "photo-error": "Photo(s) unavailable. Try again later.",
  "using-the-phone-number-on-file": "Using the phone number on file",
  "link-will-be-sent": "Link will be sent to the phone number on file",
  "send-reset-link": "Send reset link",
  "create-new-password": "Create new password",
  "for-security-reasons": "For security reasons, your password expires every 90 days, Please update your password.",
  "update-password": "Update password",
  "password-expiring-soon": "Password expiring soon",
  "password-expire-in-7-days": "Your password will expire in 7 days.",
  "update-password-now": "Do you want to update your password now?",
  "new-password": "New password",
  "confirm-new-password": "Confirm password",
  "confirm-update-password": "Confirm new password",
  "change-password": "Change password",
  "current-password": "Current password",
  "enter-existing-password": "Enter your existing password and the new password below.",
  "login-with-email": "Login with email",
  "login-enter-email": "Enter email",
  "go-to-login": "Go to login",
  "return-to-login": "Return to login",
  "passwords-must-match": "Passwords do not match",
  "dismiss": "Dismiss",
  "load-more": "Load More",
  "loading": "Loading",
  "open-map": "Open Map",
  "input-field": "Input field",
  "text-field": "Text field",
  "show-hide-password": "Show/Hide Password",
  "search": "Search",
  "select": "Select",
  "use": "Use",
  "no-options": "No options",
  "options": "Options",
  "add-new-option": "Add new option",
  "no-task": "No task to show",
  "disable-filters": "Please disable some filters or relax your search query.",
  "no-task-disable-filters": "No task to show. Please disable some filters or relax your search query.",
  "email-or-password-incorrect": "Invalid email or password entered, please try again.",
  "unable-to-complete-registration": "Unable to complete registration. Please try again.",
  "unable-to-update-account": "Unable to update account. Please try again.",
  "unable-to-complete-verification": "Unable to complete verification. Please try again.",
  "unable-to-resend-verification-code": "Unable to resend verification code. Please try again.",
  "error-submitting": "Error submitting. Please try again.",
  "new-form": 'New form',
  "trash": "Trash",
  "name-not-available": 'Name not available',
  "no-photo": "No Photo",
  "unable-to-send-reset-link": "Unable to send reset link. Please try again.",
  "unable-to-change-password": "Unable to change password. Please try again.",
  "store-selector-change": "Change",
  "store-selector-search-address": "ZIP code",
  "store-selector-change-store": "Change Store",
  "store-selector-your-store": "Your Store",
  "store-selector-nearby-store": "Nearby Stores",
  "store-selector-close": "Close",
  "store-selector-select": "Select",
  "not-implemented": "Not Yet Implemented",
  "store-selector-no-stores-error": "Unable to find pharmacy location",
  "retrieving": "Retrieving...",
  "feedback": "Feedback",
  "feedback-give-feedback": "Give Feedback",
  "feedback-give-your-feedback": "Give your feedback",
  "feedback-please-enter-feedback": "Please enter feedback",
  "feedback-how-to-improve-app": "How can we improve our app?",
  "feedback-give-feedback-about-pharmacy": "Give us feedback about our pharmacy",
  "feedback-sent": "Feedback sent!",
  "feedback-thank-you": "Thank you for your feedback!",
  "feedback-what-type-of-feedback": "What type of feedback are you leaving today?",
  "feedback-target-is-required": "Feedback target is required.",
  "feedback-feedback": "Enter additional information",
  "feedback-feedback-is-required": "Feedback info is required.",
  "feedback-allowContact": "I agree to you contacting me for further clarifications.",
  "feedback-thanks-message": "Thanks for your feedback!",
  "feedback-reach-you-message": "We will reach out to you directly if we have questions.",
  "feedback-back-to-profile": "Back to profile",
  "feedback-error-occurred": "An error has occurred.",
  "feedback-contact-info": "Contact info",
  "feedback-edit": "Edit",
  "feedback-email-address": "Email address",
  "feedback-phone-number": "Phone number",
  "feedback-app": "App",
  "feedback-location": "Store",
  "refill-card-medication": "Medication",
  "refill-card-due-date": "Refill due",
  "prescription-card-day-supply": "day supply",
  "prescription-card-filled-on": "Filled on:",
  "prescription-card-last-filled": "Last filled:",
  "prescription-card-last-ordered": "Last Ordered:",
  "prescription-card-refill-due": "Refill due:",
  "remove": "Remove",
  "remove-patient": "Remove patient",
  "remove-item": "Remove item",
  "form-submission-not-deleting": "Forms with submissions may not be deleted",
  "prescription-card-refills": "Refills remaining",
  "prescription-card-dob": "DOB",
  "name-dob": "Name, DOB",
  "sender-subject-date": "Sender, subject, date",
  "template-name": "Template name",
  "assignee-summary": "Assignee, summary",
  "name-rxnumber-dob": "Name, Rx number, DOB",
  "first-name-last-name-dob": "First name, last name, DOB",
  "patient-name-dob-form-name": "Patient name, DOB, form name",
  "name-ncpdp-street-city": "Name, NCPDP, street, city",
  "user-email-address": "User, email address",
  "medications-fill-your-prescription": "Fill Prescription",
  "medications-ready-to-be-filled": "Ready to be filled",
  "medications-other-upcoming-refills": "Other upcoming refills",
  "insurance-card-upload-error": "Unable to upload card. Please try again.",
  "camera-mask-label": "Center the card inside the frame",
  "primary-insurance-add-your-information": "Add primary insurance card",
  "secondary-insurance-add-your-information": "Add secondary insurance card",
  "photoId": "Add photo I.D.",
  "photo-id-text": "Photo I.D.",
  "no-id-card": "No I.D. card",
  "delete-image": "Deleting this image will remove this card information",
  "photo-take-card-picture": "Take a picture or upload an image of the front side of the government-issued photo I.D. card",
  "insurance-take-card-picture": "Take a picture or upload an image of both sides of the insurance card",
  "photo": "Photo",
  "take-photo": "Take Photo",
  "choose-photo": "Choose Photo",
  "insurance-bring-to-store": "I will bring it to the store",
  "google-signup": "Sign up with Google",
  "google-continue": "Continue with Google",
  "google-login": "Google login",
  "google-login-failed": "Google Login Failed",
  "date-is-not-valid": "Date is not valid",
  "verify-your-email": "Verify your email address",
  "verify-your-phone": "Verify your phone number",
  "please-enter-your-information": "Please enter your information",
  "email-address": "Email address",
  "patient-stores": "Find your store",
  "user-not-found": "Unknown error. Please make sure that your email address and the pharmacy you’ve selected are correct.",
  "invalid-confirmation-code": "Invalid reset link, click here to get a new one",
  "contact-support": "Unknown error, please try again. If problem persists, please contact support.",
  "many-failed-attempts": "Too many failed attempts, please try again in an hour",
  "not-authorized": "Invalid reset link, click here to get a new one",
  "done": "Done",
  "do-this-later": "Do this later",
  "entered-prescription-manually": "Entered Manually",
  "your-prescriptions": "Prescriptions",
  "add": "Add",
  "drug-name": "Drug name",
  "drag-change-order": "Drag to change the order",
  "drug-name-is-required": "Drug name is required",
  "rx-number": "Rx number",
  "retrieve-your-prescriptions": "Retrieve prescription list",
  "other-meds": "Add meds not on this list",
  "try-again": "Try again",
  "step": "Step",
  "rx-number-is-required": "Rx number is required",
  "rx-number-is-duplicated": "Rx number is already present",
  "change": "Change",
  "location": "Location",
  "locations": "Locations",
  "patient-dob": "Patient DOB (mm/dd/yyyy)",
  "patient-dob-is-required": "Patient DOB is required",
  "date": "Date",
  "patient-last-name-is-required": "Patient last name is required",
  "patient-last-name": "Patient last name",
  "we-found-your-medications-alert-title": "Medication list found.",
  "unable-to-locate-your-prescription-alert-title": "Unable to locate prescription list.",
  "unable-to-locate-your-prescription-alert-description": "Please confirm that the information below matches what's on the prescription label.",
  "prescription-information": "Prescription information",
  "integrated-prescription-flow-description": "Enter any recent prescription number to connect the prescription list with the selected pharmacy location.",
  "forms": "Forms",
  "tasks": "Tasks",
  "welcome-to-tasks": "Welcome to Tasks",
  "create-new-task": "Create a task to get started.",
  "new-task-button": "New Task",
  "delegate-work-among-employees": "Delegate work among employees",
  "communicate-a-sync-with-coworkers": "Communicate a-sync with coworkers",
  "track-week-month-quarter-year-tasks": "Track weekly, monthly, quarterly and yearly tasks",
  "example-task-check-shelves": "Example task: \"Check shelves for expired medications\"",
  "welcome-to-services": "Welcome to service setup",
  "setup-bookable-service": "Setup bookable services to get started.",
  "new-service-button": "New service",
  "patients-book-via-mobile": "Patients book via mobile app or web portal",
  "pharmacy-staff-can-also-book": "Pharmacy staff can also book for patients",
  "use-pharmacy-hours-custom-availability": "Use pharmacy hours or set custom availability",
  "see-upcoming-appointments": "See upcoming appointments on the dashboard",
  "change-availability": "Change availability",
  "book-first-appointment": "Book your first appointment",
  "new-appointment-button": "New appointment",
  "no-upcoming-appointment": "No Upcoming Appointments",
  "schedule-an-appointment": "Schedule an appointment for a patient.",
  "task-types": "Task types",
  "tasks-grid": "Tasks Grid",
  "patients": "Patients",
  "grid": "Grid",
  "browse": "Browse",
  "create-first-form": "Create your first form",
  "credentials": "Credentials",
  "form-imported-text": "This form was imported and it features a functionality that is not currently available. You can still preview the form, but you cannot make edits to it.",
  "form-not-imported-text": "This form contains a functionality that is not currently supported. You can still preview the form, but you cannot make edits to it.",
  "lumistry-api-key": 'The API key you provide will give Lumistry access to your Jotform data including forms and submissions. Your data will be kept secure and will only be used for the purpose of importing the forms you specify into theLumistry platform.',
  "refill-submissions": "Refills",
  "refill-submissions-header": "Refill Requests",
  "user": "User",
  "users": "Users",
  "copy-link": "Copy link",
  "form-was-imported": "Form was imported",
  "search-submissions": "Search submissions",
  "new-user": "New user",
  "user-details": "User Details",
  "title": "Title",
  "role": "Role",
  "last-active": "Last Active",
  "registered": "Registered",
  "activity": "Activity",
  "show": "Show",
  "edit": "Edit",
  "view-activity-logs": "View activity logs",
  "resend-invite": "Resend invite",
  "error-sending-invite": "An error occurred while sending invite",
  "delete": "Delete",
  "delete-confirmation": "Are you sure you want to delete this?",
  "confirm-delete-availability-implicit": "Are you sure you want to delete the availability?",
  "confirm-delete-service-implicit": "Are you sure you want to delete the service?",
  "cannot-undo-action": "You cannot undo this action.",
  "email-is-invalid": "Email is invalid",
  "passwords-do-not-match": "Password entries do not match",
  "password-does-not-meet-criteria": "Password entry does not meet criteria",
  "follow-reset-password-instructions": "Please follow the instructions to reset your password.",
  "pharmacy-reset-link-sent": "An email has been sent to ",
  "need-help": "Need Help?",
  "set-your-preferences": "Step {{currentStep}} of {{totalSteps}} / Set your preferences",
  "find-your-store": "Find your store",
  "confirm-your-store": "Step {{currentStep}} of {{totalSteps}} / Confirm your store",
  "prescribed-for": "Prescribed for",
  "placeholder-step": "Placeholder Step",
  "instructions": "Instructions",
  "instructions-api": "Accessing an existing JotForm account in Lumistry requires an API key found in the Jotform settings.",
  "pdf-instructions": "The .pdf below contains a short, step-by-step guide for obtaining this key.",
  "open-pdf": "Open PDF With Instructions",
  "last-filled-on": "Last filled on",
  "last-ordered-on": "Last ordered on",
  "auto-refills": "Auto-refills",
  "auto-refill": "Auto-refill",
  "auto-refill-not-eligible" : "Not eligible",
  "auto-refill-requested" : "Requested",
  "auto-refill-patient-requested" : "Patient requested",
  "request-auto-refill" : "Request auto-refill",
  "refills-left": "Refills left",
  "quantity-dispensed": "Quantity dispensed",
  "days-supply": "Days supply",
  "directions": "Directions",
  "questions-about-medication": "Questions about this medication?",
  "ask-your-pharmacist": "Ask your pharmacist",
  "message": "Message",
  "refill": "Refill",
  "child": "Child",
  "spouse": "Spouse",
  "parent": "Parent",
  "grandparent": "Grandparent",
  "grandchild": "Grandchild",
  "pet": "Pet",
  "partner": "Partner",
  "other": "Other person under my care",
  "resources": "RESOURCES",
  "medication-guides": "Medication Guides",
  "how-to-take": "How to take this medication",
  "patient-education-sheets": "Patient Education Sheets",
  "side-effects-interactions": "Side effects, drug interactions",
  "prescription-written": "Rx date written",
  "prescription-by": "Prescription by",
  "call-prescriber": "Prescriber: ",
  "prescriber": "Prescriber",
  "prescriber-info-not-available": "Info not available",
  "prescriber-name-not-available": "(Name not available)",
  "prescriber-phone-not-available": "(Phone not available)",
  "link-expired": "The link has expired or is invalid, you'll need a new one.",
  "reset-unsuccessful": "Reset unsuccessful",
  "order-submitted": "Your order is submitted!",
  "order": "Order",
  "picking-up-at": "Picking up at",
  "ready-today": "Ready today between",
  "notify-you": "We'll be sure to notify you",
  "hi": "Hi, {{firstName}}.",
  "create-your-password": "Please create your password",
  "i-have-read-and-agree": "I have read and agree to the",
  "pharmacy-terms-and-conditions": "Terms and Conditions",
  "privacy-policy": "Privacy Statement",
  "terms-and-conditions-required": "Terms and Conditions agreement is required",
  "privacy-policy-required": "Privacy Statement agreement is required",
  "at-least-one-selected": "At least one option should be selected",
  "click-sections-above": "Click the back button to review your information. You can still make changes later in the settings. Click “Submit” to launch your pharmacy!",
  "number-of-locations": "Number of locations",
  "pharmacy-taking-off": "Your pharmacy is taking off!",
  "next-steps": "Next Steps",
  "complete-new-account": "Complete the new account checklist.",
  "employess-to-be-notified": "Employees will be notified to sign-up.",
  "monitor-progress": "Monitor your team’s progress daily.",
  "check-progress": "Check the progress...",
  "go-to-dashboard": "Go to Dashboard",
  "agreements": "Agreements",
  "equipment": "Equipment",
  "employees": "Employees",
  "addresses": "Addresses",
  "ein": "EIN",
  "store-address": "Store address",
  "lumistry-setup": "Lumistry setup",
  "welcome-lumistry-setup": "Welcome to Lumistry setup! In this short guide you will be asked some high level information about your pharmacy including:",
  "account-info": "Account info",
  "location-info": "Location info",
  "ob-all-hours-desc": "These hours will be applied to all locations in the list.",
  "confirm-information-collected": "Confirm information collected in the sales process.",
  "location-billing-account": "Set location, billing, & account addresses.",
  "require-additional-hardware": "Some pharmacy management systems require additional hardware.",
  "add-your-employees": "Add your employees.",
  "set-hours-timezone-holidays": "Set store hours, time zone, and holidays.",
  "learn-about-employee-roles": "Learn about employee roles",
  "lumistry-roles": "Lumistry Roles",
  "employee-role-determines-action": "An employee’s role determines what actions that employee has permission to take.",
  "handles-organization-permissions": "Handles all administrative permissions for the organization (ex. Pharmacy owner)",
  "location-level-leader": "Location level leader and decision maker. Staff may need Manager approval to access certain functionalities within the platform.",
  "common-level-of-access": "Most common level of access. May comprise of Pharmacist, Technician, Pharmacist Intern, Cashier, etc.",
  "new-patient": "New patient",
  "i-am-a-new-patient": "I am a new patient",
  "continuation-disclaimer": "By tapping Continue with Google or email, you agree to this Pharmacy's",
  "patient-details": "Patient Details",
  "date-of-birth": "Date of birth",
  "date-of-birth-short": "DOB",
  "phone-number": "Phone number",
  "gender-text": "Gender",
  "language": "Language",
  "conditions": "Conditions",
  "easy-open-bottle": "Easy-open bottle",
  "med-sync": "Med Sync",
  "photo-id": "I.D. Card",
  "image": "Image",
  "notification": "Notification",
  "app-notification": "App notification",
  "list": "List",
  "providers": "Providers",
  "puc-permission-access-to-records": "I confirm that {{firstName}} {{lastName}} has permission to access my records and manage health digitally and that I can revoke access at any time.",
  "confirm-remove-patient": "Removing {{firstName}} {{lastName}} from your list means that you will no longer have access to {{firstName}}'s records and manage {{firstName}}'s health digitally.",
  "if-you-authorize-person": "If you authorize this request, then this person can:",
  "access-validity": "This access is valid until you revoke it.",
  "revoke-access-in-settings": "You can revoke access by going to your Medical Info in Account Settings",
  "review-request": "Review request...",
  "authorize": "Authorize",
  "edit-phone-number": "Phone Number",
  "decline": "Decline",
  "notes": "Notes",
  "account-holder": "Account holder",
  "monday-abbreviation": "Mon",
  "tuesday-abbreviation": "Tue",
  "wednesday-abbreviation": "Wed",
  "thursday-abbreviation": "Thu",
  "friday-abbreviation": "Fri",
  "saturday-abbreviation": "Sat",
  "sunday-abbreviation": "Sun",
  "internal-use-only": "Internal use only",
  "to-get-started-choose": "To get you started,\nplease choose one of the following:",
  "face-id": "Face ID",
  "medical-equipment-immunization": "Immunizations, Durable Medical Equipment, Compounting, Patient Consultation, COVID-19 Vaccine",
  "services-offered": "Services offered",
  "en-es-fr": "English, Spanish, French",
  "languages-spoken": "Languages spoken at this location",
  "holiday-hours-vary": "Holiday hours may vary.",
  "open-hours": "Open today: {{hours}}",
  "open-today": "Open today 24 hours.",
  "store-hours": "Store Hours",
  "set-my-store": "Set as my store",
  "manage-prescriptions": "Manage your prescriptions digitally",
  "pick-up-prescriptions": "Pick up your prescriptions",
  "discuss-care": "Discuss your care with a {{pharmacyName}} team member",
  "manage-health": "Manage your health and clinical service appointments",
  "upload-csv": "Upload CSV",
  "add-patient": "Add Patient",
  "revoke-access": "Revoke access",
  "authorized-on": "Authorized on {{authorizedDate}}",
  "request-status": "Pending: submitted on {{submittedDate}}",
  "authorized-caregivers": "authorized caregivers",
  "personal-info": "Personal Info",
  "person": "Person",
  "will-pickup-prescription-today": "Will you be picking your prescription up today?",
  "field-required": "This field is required",
  "will-pickup-prescription-urgently": "Do you need to pickup your prescription urgently?",
  "order-details": "Order Details",
  "method": "Method",
  "api-required": "API key is required",
  "note-for-pharmacy": "Note for the pharmacy (optional)",
  "note-for-pharmacy-not-optional": "Note for the pharmacy",
  "review": "Review",
  "insurance-is-required": "Insurance is required",
  "insurance-change-last-refill": "Has the insurance changed since the last refill?",
  "prescription-find-server-error": "Internal Server Error during finding your medications",
  "prescriptions-is-required": "Prescriptions is required",
  "over-the-counter-medications": "Over-the-counter medications",
  "please-review-messages-and-proceed": "Please review the messages below and let us know how you would like to proceed.",
  "how-to-proceed": "How to proceed",
  "enter-patient-email": "Enter patient email address",
  "add-patient-request-submit": "Add patient request submitted",
  "provide-an-email": "Please provide the email address that {{firstName}} {{lastName}} uses for {{firstName}}'s account with {{pharmacyName}}",
  "request-sent": "Request sent to {{firstName}} {{lastName}}",
  "approval-needed": "Approval is needed from this person within 14 days",
  "person-has-an-account": "If this person has an account at {{pharmacyName}}, then they will receive your request",
  "add-another-patient": "Add another patient",
  "add-another": "Add another",
  "patient-select": "Please select the patient before you begin.",
  "after-adding-patient-message": "After adding a new patient, please return to the original message and click the link again.",
  "another-account": "Another account",
  "go-to-form": "Go to form",
  "new-task": "New task",
  "summary": "Summary",
  "description": "Description",
  "get-mobile-app": "Get our mobile app!",
  "go-home": "Go home",
  "about-this-app": "About this app",
  "about-this-app-title": "About This App",
  "copyright-lumistry": "Copyright Lumistry",
  "all-rights-reserved": "All Rights Reserved",
  "version": "Version",
  "enter-patient-information": "Enter patient information",
  "allow-pharmacy-contact-prescriber": "Allow pharmacy to contact prescriber",
  "continue-with-selected-items": "Continue with the selected items",
  "go-back": "Go back",
  "go-back-update-order": "Go back to update my order",
  "thank-you-for-choosing": "Thank you for choosing",
  "return-to-order-details": "Return to order details",
  "appointments": "Appointments",
  "add-medications": "Add medications",
  "add-meds-text": "You can order prescription refills easily and manage medications by adding them now, or skip this step to do it later",
  "prescriptions-at-file": "Prescriptions are on file at {{pharmacyName}}",
  "request-to-transfer": "Request to transfer prescriptions from a different pharmacy",
  "skip-this-step": "Skip this step",
  "do-this-medications-tab": "You can do this in the Medications tab",
  "account": "Account",
  "your-account": "Your Account",
  "add-people-under-care": "Add people under your care",
  "add-people-under-care-message-pt-1": "You can find the person you want to add by providing their information on the next page",
  "add-people-under-care-message-pt-2": "If the person you are adding is 18+ years old, then you will also need to provide the email address that they use for their account at",
  "add-person": "Add a person",
  "we-will-process-following-items": "We will proceed with processing the following items unless you remove them from your order",
  "cannot-process-items": "The following cannot be processed and will be removed from your order",
  "return-to-medications": "Return to Medications",
  "no-item-left-in-order-cancel": "You have one item left in your order. Removing it will cancel your order.",
  "no-item-left-in-order-cancelled": "You have no items left in your order. Your order will be canceled.",
  "unable-to-process-items": "Unable to process one or more items",
  "one-or-more-items-to-review" : "One or more items to review",
  "canceled-appointments": "Canceled Appointments",
  "add-override": "Add override",
  "edit-appointment": "Edit Appointment",
  "year": "year",
  "month": "month",
  "day": "day",
  "week": "week",
  "we-will-contact-prescriber": "We will contact your prescriber for authorization on the following prescriptions. ",
  "please-review-the-messages-below": "Please review the messages below and let us know how you would like to proceed.",
  "ready-to-be-filled": "Ready to be filled",
  "close": "Close",
  "closed": "Closed",
  "greeting": "Good Morning",
  "your-password-has-been-successfully-changed": "Your password has been successfully changed",
  "other-details": "Other details",
  "store-info": "Store info",
  "account-setup": "Account Setup",
  "other-information": "Other information",
  "insurance": "Insurance",
  "revoke": "Revoke",
  "first-name-is-not-valid": "First name is not valid",
  "login-attempts-exceeded": "Login attempts exceeded",
  "max-attempts-exceeded": "Maximum attempts exceeded",
  "account-locked-reason": "Your account has been locked because you have reached the maximum number of invalid sign-in attempts allowed.",
  "security-reason": "For security purposes, please choose an option below to change your password and recover your account.",
  "send-me-email": "Send me an email",
  "edit-title": "Edit Title",
  "edit-subtitle": "Edit Subtitle",
  "edit-checkbox-label": "Edit Checkbox Label",
  "maximum-input-value": "Maximum input value",
  "send-me-sms": "Send me a text message",
  "last-name-is-not-valid": "Last name is not valid",
  "update-weak-password": "Your password does not meet current requirements. For the security and protection of your account, please create a new one.",
  "revoke-access-for-authorized-caregiver": "Revoking access means that {{firstName}} {{lastName}} is no longer one of your authorized caregivers.",
  "basic-info": "Basic info",
  "legal-guardian-confirmation-for-person": "You agree that you are a Parent or Legal Guardian for the person you are adding",
  "legal-guardian-agreement": "Yes, I am a Parent or Legal Guardian for {{firstName}} {{lastName}}.",
  "delete-insurance": "Delete this insurance information",
  "confirm-delete-insurance":"Are you sure you want to delete this insurance information",
  "delete-photo-id": "Delete this photo I.D.",
  "confirm-delete-id": "Are you sure you want to delete this photo I.D.",
  "people-under-your-care": "Patients under your care",
  "add-minor-or-pet-patient": "Add minor or pet patient",
  "your-allergies": "Allergies",
  "confirmation": "Confirmation",
  "linked-accounts": "Linked Accounts",
  "linked-accounts-description": "Caregivers, patients under care",
  "people-under-your-care-empty-page": "If you're managing prescriptions and appointments for other people or pets, having them all in one list is a convenient way to keep track of everyone's medications and schedules which can help avoid missed refills and appointments.",
  "add-person-or-pet": "Add person or pet",
  "unable-to-submit-people-under-care-request": "Unable to submit people under care request",
  "relationship-to-me": "Relationship to me",
  "medical-info": "Medical Info",
  "brand-name-is-required": "Brand name is required",
  "parent-company-is-required": "Parent company is required",
  "none": "None",
  "notice": "Notice",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "sunday": "Sunday",
  "meds": "Meds",
  "orders": "Orders",
  "reminders": "Reminders",
  "upcoming-appointments": 'Upcoming Appointments',
  "no-upcoming-appointments": "No upcoming appointments",
  "no-refills-due": "No refills due",
  "refill-details": "Refill Details",
  "success": "Success",
  "prescription-no": "Prescription No.",
  "name": "Name",
  "status": "Status",
  "submitted": "Submitted",
  "channel": "Channel",
  "delivery-method": "Delivery Method",
  "refill-comments": "Refill Comments",
  "patient-info": "Patient Info",
  "details": "Details",
  "phone": "Phone",
  "address": "Address",
  "email-text": "Email",
  "prescription": "Prescription",
  "patient": "Patient",
  "date-submitted": "Date submitted",
  "allergies-colon": "Allergies: ",
  "medical-conditions": "Medical conditions: ",
  "refill-past-due": "Refill past due:",
  "past-appointments": "Past Appointments",
  "refill-due": "Refill due:",
  "store": "Store",
  "app": "App",
  "allergies": "Allergies",
  "number": "Number",
  "medical-conditions-without-colon": "Medical conditions",
  "no-known-medical-conditions": "No known Medical Conditions",
  "medical-conditions-non-capitalized": "Medical conditions",
  "caregiver-approval-agreement": "Caregiver Approval Agreement",
  "no-known-allergies": "No known allergies",
  "known-allergies": "Known allergies",
  "allergies-required": "Please enter allergies or select \"No known allergies\"",
  "more-information": "More information",
  "monthly-prescriptions": "Would you like for these prescriptions to be ready at the same time each month?",
  "as-an-authorized-caregiver": "As an authorized Caregiver, you can:",
  "manage-prescriptions-digitally": "Manage {{firstName}}'s prescriptions digitally",
  "order-refills": "Order refills for {{firstName}}",
  "create-first-task": "Create your first task",
  "order-refills-prescriptions": "Order refills for your prescriptions",
  "delivery": "Delivery",
  "mail": "Mail",
  "curbside": "Curbside",
  "task-details": "Task Details",
  "are-you-sure-to-delete-task": "Are you sure you want to delete the task",
  "are-you-sure-to-resolve-task": "Are you sure you want to resolve the task",
  "data": "Data",
  "changed-value": "Changed Value",
  "easy-open-bottle-caps-field": "Easy-Open Bottle Caps",
  "med-sync-field": "Med Sync",
  "created-by": "Created by",
  "system-user": "System User",
  "relation-to-primary-user": "Relation to primary user",
  "would-like-easy-open-caps": "Please select a bottle cap preference.",
  "prefers-easy-open-caps": "Easy-open (non-safety) caps",
  "prefers-safety-caps": "Child-resistant (safety) caps",
  "pick-up-prescriptions-for": "Pick up prescriptions for {{firstName}}",
  "discuss-care-with": "Discuss {{firstName}}'s care with a {{pharmacyName}} team member",
  "manage-health-and-clinic": "Manage {{firstName}}'s health and clinical service appointments",
  "access-validity-puc": "This access is valid until {{firstName}} turns 18 or you remove {{firstName}} from your list of people under your care.",
  "access-validity-puc-pet": "This access is valid until you remove {{firstName}} from your list of patients under your care.",
  "update-puc-list": "You can update your list by going to People Under My Care in Account Settings",
  "patient-record-created": "Patient Record created for {{firstName}} {{lastName}}",
  "medications-empty-state-title": "Medications",
  "phone-number-footer": "phone: {{phone}}",
  "notification-preference": "Notification preferences",
  "notification-preferences-details": "Notifications are used to let you know when your \nprescription order is ready, when you have an upcoming \nappointment, or when you get a new message from \nyour pharmacy team.",
  "sms-text-message": "SMS (text message)",
  "fax": "fax: {{fax}}",
  "digital-pharmacist": "Digital Pharmacist",
  "pharmacy-website": "Pharmacy website and mobile app provided by",
  "medications-empty-state-description": "Adding your medications will make it easy to track and request refills on important prescriptions that help keep you healthy!",
  "medications-reminders-empty-state-title": "Medication Reminders",
  "medications-reminders-empty-state-description": "Medication reminders is a good way to stay on track of your meds which can help avoid unnecessary risk and serious illness",
  "add-meds": "Add Meds",
  "transfer-meds": "Transfer meds from another pharmacy",
  "transfer-meds-tab": "Transfer Meds",
  "pickup": "Pickup",
  "select-an-option": "Please select an option",
  "choose-from-the-list": "Please choose from the list of items before clicking the order button",
  "select-one-item": "Select at least one item",
  "show-more": "Show more",
  "street1": "Street 1",
  "street2": "Street 2",
  "please-select": "Please select",
  "select-single-option": "Please select a single option",
  "select-multiple-options": "Please select multiple options",
  "gender-on-insurance": "Gender (as it appears on insurance)",
  "preferred-language": "Preferred language",
  "notification-preferences": "Notification Preferences",
  "auto-voice-call": "Auto voice call",
  "edit-patient": "Edit Patient",
  "edit-columns": "Edit Columns",
  "add-new-column": "Add a new column",
  "edit-rows": "Edit Rows",
  "edit-form-title": "Edit form title",
  "add-new-row": "Add new row",
  "show-less": "Show less",
  "please-confirm-the-info-matches-prescription-label": "Please confirm that the information below matches what's on your prescription label",
  "register-confirmation-heading": "New account setup",
  "register-confirmation-body": "A new account setup link has been sent to your email. Please check your email and click the link provided.",
  "schedule": "Schedule",
  "orders-tab-empty-state-title": "Order history",
  "orders-tab-empty-state-description": "Placing a refill order is easy from the Meds tab! After you've placed an order, details and tracking will go here",
  "start-an-order": "Start an order",
  "show-locations": "Show locations",
  "first-name-edit-user": "First name",
  "last-name-edit-user": "Last name",
  "dob-edit-user": "DOB (mm/dd/yyyy)",
  "new-appointment": "New Appointment",
  "information": "Information",
  "find-time": "Find a time",
  "learn-more": "Learn more",
  "next-available": "Next available",
  "not-available": "Currently not available",
  "intro": "Intro",
  "enter-email": "Enter email",
  "create-password": "Create password",
  "register-confirmation": "Register confirmation",
  "register": "Register",
  "reset-link": "Reset link",
  "reset-successful": "Reset successful",
  "register-details": "Register details",
  "phone-verification": "Phone verification",
  "patient-pharmacy-stores": "Patient pharmacy stores",
  "add-prescription": "Add prescription",
  "refill-guest": "Refill guest",
  "home": "Home",
  "settings": "Settings",
  "store-location": "Store location",
  "expiring-soon": "Expiring soon",
  "minutes": "Minutes",
  "book-appointment": "Book Appointment",
  "appointments-calendar": "Appointments Calendar",
  "upcoming": "Upcoming",
  "past": "Past",
  "patient-insurance": "Patient insurance",
  "barcode": "Barcode",
  "patient-preferences": "Patient preferences",
  "feedback-success": "Feedback success",
  "people-under-care": "Patients Under Your Care",
  "add-patient-under-care": "Add patient under care",
  "add-patient-under-care-info": "Add patient under care info",
  "add-patient-under-care-email": "Add patient under care email",
  "add-patient-under-care-confirmation": "Add patient under care confirmation",
  "patient-under-care-list": "Patient under care list",
  "contact-info": "Contact info",
  "automatic-prescription-flow": "Automatic prescription flow",
  "success-automatic-prescription-flow": "Success automatic prescription flow",
  "on-hold": "On hold",
  "rejected-reason-unknown": "Rejected reason unknown",
  "select-person": "Select the patient for this appointment. If you don't see them on the list, you will need to add them first.",
  "myself": "Myself",
  "confirm": "Confirm",
  "error-loading-appointments": "Error loading appointments",
  "error": "Error",
  "see-order-details": "See order details",
  "appointment-scheduled": "appointment is scheduled",
  "booking-error": "Something went wrong when trying to book the appointment. Please try again.",
  "error-loading-slots": "Error loading appointment slots",
  "error-loading-patient-record": "Error loading the patient record",
  "error-updating-patient-record": "Error updating the patient record",
  "error-booking-appointment": "Error booking the appointment",
  "when": "When",
  "who": "Who",
  "image-reading-error": "Unable to load the image. Try again later.",
  "where": "Where",
  "vaccine-information-statement": "Vaccine Information Statement",
  "vaccine-benefits-and-risks": "Benefits and risks of a vaccine",
  "care-notes": "Care notes",
  "reschedule": "Reschedule",
  "cancel-appointment": "Cancel appointment",
  "error-loading-appointment": "Error loading appointment",
  "error-loading-appointment-type": "Error loading appointment type",
  "refill-medications": "refill-medications",
  "refill-medical-infos": "refill-medical-infos",
  "add-order": 'Add Order',
  "refill-review": "refill-review",
  "refill-other-information": "refill-other-information",
  "refill-prescription-method": "refill-prescription-method",
  "refill-insurance-method": "refill-insurance-method",
  "refill-confirmation": "refill-confirmation",
  "refill-order-details": "refill-order-details",
  "upcoming-refill": "Upcoming refill",
  "good-morning": "Good morning",
  "send-message": "Send message",
  "no-notes-attached-to-patient": "No notes are attached to this patient",
  "good-afternoon": "Good afternoon",
  "good-evening": "Good evening",
  "you-are-chatting-with": "You are chatting with:",
  "new-message": "New Message",
  "no-messages": "No messages",
  "messages-have-a-question": "Have a question? Need to change an order? Send us a secure message.",
  "new-message-placeholder": "Message...",
  "general": "General",
  "billing": "Billing",
  "med-info": "Med info",
  "side-effects": "Side effects",
  "message-subject-label": "Select the subject that best fits your message:",
  "send": "Send",
  "reschedule-appointment": "Reschedule appointment",
  "appointment-rescheduled": "appointment is rescheduled",
  "error-rescheduling-appointment": "Error rescheduling the appointment",
  "cancel-appointment-confirmation": "Are you sure you would like to cancel this appointment?",
  "appointment-canceled": "appointment is canceled",
  "expired-link": "This link has been expired",
  "used-password": "Your new password cannot be one of your previous 5 passwords. Please choose a password that you have not previously used.",
  "previously-scheduled": "Previously scheduled:",
  "cancel-note": "Note: Canceling this appointment will not remove it from any third-party calendars it has been added to.",
  "cancel-error": "Something went wrong when trying to cancel the appointment. Please try again.",
  "error-cancel-appointment": "Error canceling the appointment",
  "something-went-wrong-implicit": "Something went wrong, please try again later.",
  "keep": "Keep",
  "of": "of",
  "tomorrow": "Tomorrow",
  "today": "Today",
  "canceled": "Canceled",
  "events": "events",
  "error-field-number": "Field must be a number",
  "no-appointments": "You currently have no appointments.\nSelect a service to get started.",
  "no-services-booking": "There are no services available for booking.",
  "role-permission-description": "See Roles and Permissions for a full description about system access.",
  "download-all-submissions": "Download all submissions",
  "no-submissions-download": "There are no submissions to download",
  "it-admin-description": 'System manager (add and remove users)',
  "brand-description": 'Brand-level manager',
  "corporate-description": 'Top-level manager',
  "full-access": "Full access",
  "manager-description": 'Configure staff, manager and no-access by location (next screen)',
  "remove-role": "Deactivate user",
  "location-employee": "Location employee",
  "user-has-role-error": "User exists. Please edit user to manage.",
  "location-employee-description": "Configure staff, manager and no-access by location (next screen)",
  "remove-role-description": "No longer allowed in the system.",
  "video-header": "Videos",
  "videos": "Videos",
  "videos-filter": "FILTER",
  "videos-clear": "Clear",
  "videos-all": "All",
  "videos-medication": "Medication",
  "videos-condition": "Condition",
  "videos-administration": "Administration",
  "videos-missing-medication-video": "Missing Medication Video",
  "videos-general-health": "General health",
  "videos-search": "Search",
  "videos-share": "Share",
  "videos-category": "Category",
  "videos-title": "Title",
  "videos-language-spanish": "View in Spanish",
  "videos-language-english": "View in English",
  "videos-language-french": "View in French",
  "videos-duration": "Duration",
  "videos-url": "URL",
  "copied-to-clipboard": "Copied to clipboard",
  "videos-med-guides": "Med Guides",
  "videos-transcript": "Transcript",
  "delete-confirmation-modal": "Are you sure you want to delete the {{itemType}}: {{itemName}}?",
  "confirm-to-delete-name": "Are you sure you want to delete the form with the name: {{name}}?",
  "service": "Service",
  "services": "Services",
  "new-service": "New Service",
  "confirming-lose-current-form": "By confirming you will lose the current progress on importing a form.",
  "confirm-delete-availability": "Are you sure you want to delete the availability with the name: {{name}}?",
  "same-mailing-address": "Same as mailing address",
  "create-appointment": "Create an appointment",
  "order-refill": "Order refill",
  "limit-booking-frequency": "Limit booking frequency",
  "limit-booking-duration": "Limit total booking duration",
  "limit-future-bookings": "Limit future bookings",
  "before-event": "Before event",
  "after-event": "After event",
  "into-future": "into the future",
  "within-date-range": "Within a date range",
  "add-limit": "Add limit",
  "calendar-days": "calendar days",
  "business-days": "business days",
  "per-day": "Per day",
  "per-week": "Per week",
  "per-month": "Per month",
  "no-buffer-time": "No buffer time",
  "content-box-title": "Displaying tasks that match the following criteria:",
  "duration": "Duration",
  "time": "Time",
  "check-later": "Please check back later",
  "no-availability": "No availability",
  "birthdate": "Birthdate",
  "no-access": "No Access",
  "actions": "Actions",
  "access-private-public": "Access: Private/Public",
  "start-date": "Start date",
  "cannot-delete-task-type": "Can't delete task types that are currently in use",
  "cannot-edit-task-type": "Can't edit default task type",
  "personal-task-assign": "Personal tasks can be assigned only to the owner/creator of the task",
  "start-date-required": "Start date is required",
  "service-record-enabled-tooltip": "Mark service as unavailable for making appointments",
  "service-record-disabled-tooltip": "Enable making appointments for this service",
  "copy-link-button-tooltip": "Copy to clipboard",
  "edit-service-record-tooltip": "Edit service",
  "edit-service-tab": "Edit Service",
  "delete-service-record-tooltip": "Delete service",
  "selecting-all-pages": "Selecting all pages ({{count}} items)",
  "select-all-pages": "Select all pages",
  "selecting-current-page": "Selecting this page only ({{count}} items)",
  "select-current-page": "Select this page only",
  "unselect-all": "Unselect all",
  "others": "others",
  "reorder": "Reorder",
  "generic-bulk-confirmation-modal": "Are you sure you want to {{bulkAction}} {{count}} {{type}} {{changeType}}?",
  "task": "task",
  "bulk-assignee-confirmation-modal": "Select the assignee for {{count}} {{type}}.",
  "bulk-due-date-confirmation-modal": "Select the due date for {{count}} {{type}}",
  "unresolved": "Unresolved",
  "resolved": "Resolved",
  "in_progress": "In Progress",
  "on_hold": "On hold",
  "task-resolve": "Tasks Resolve",
  "mark-resolved": "Mark as Resolved",
  "mark-in-progress": "Mark as In Progress",
  "mark-flagged": "Mark as Flagged",
  "flagged": "Flagged",
  "mark-unresolved": "Mark as Not Started",
  "mark-on-hold": "Mark as On Hold",
  "change-assignee": "Change Assigned User",
  "user-roles": "User Roles",
  "task-filters": "Task Filters",
  "change-due": `Change Due Date`,
  "task-in-progress": "Tasks In Progress",
  "task-flag": "Tasks Flag",
  "assignee": "Assignee",
  "not-started": "Not Started",
  "over-the-counter": "Over the counter",
  "delete-task": "Delete Task",
  "venue": "Venue",
  "private": "Private",
  "enable-form": "Enable form",
  "disable-form": "Disable form",
  "public": "Public",
  "your-pharmacy": "Your pharmacy",
  "edit-availability-record-tooltip": "Edit Availability",
  "delete-availability-record-tooltip": "Delete Availability",
  "availability": "Availability",
  "new-chat-message": "New Chat Message",
  "patient-is-required": "Patient is required",
  "subject-is-required": "Subject is required",
  "message-or-attachment-is-required": "Message or attachment is required",
  "message-characters-limit-five-thousand": "Message characters limit is 5000",
  "find-template": "Find template",
  "to": "To",
  "subject": "Subject",
  "note-inbox-messages-should-be-clinical-in-nature": "Note: Inbox messages should be clinical in nature. Sending marketing messages through this platform could violate TCPA and FCC regulations. Do not request credit card information.",
  "all": "All",
  "unread": "Unread",
  "unread-by-patient": "Unread by patient",
  "includes-me": "Includes me",
  "created-by-me": "Created by me",
  "last-seven-days": "Last 7 days",
  "filters": "Filters",
  "clear": "Clear",
  "send-attachment": "Send Attachment",
  "type-your-message": "Type your message...",
  "mark-as-unread": "Mark as unread",
  "chat-messages": "Chat Messages",
  "unread-messages": "Unread Messages",
  "find-patient": "Find patient",
  "new-subject": "New subject",
  "type-your-question": "Type a question",
  "add-mobile-number-send-message": "Add a mobile number to send a message",
  "add-mobile-number": "Add mobile number",
  "automated-messages": "Automated Messages",
  "edit-mobile-number": "Mobile number",
  "newest": "Newest",
  "oldest": "Oldest",
  "a-z": "A-Z",
  "new-chat": "New chat",
  "auto": "Auto",
  "happy-birthday": "Happy birthday",
  "insurance-card-update": "Insurance card update",
  "flu-shot-reminder": "Flu shot reminder",
  "spring-promotions": "Spring promotions",
  "summer-promotions": "Summer promotions",
  "fall-promotions": "Fall promotions",
  "thanksgiving": "Thanksgiving",
  "seasons-greeting": "Season’s greetings",
  "happy-new-year": "Happy New Year!",
  "happy-birthday-description": "Develop a personal relationship and increase customer loyalty",
  "insurance-card-update-description": "Prompt patients for updated insurance information",
  "flu-shot-reminder-description": "Remind patients about flu shots and prompt them to schedule an appointment and help drive revenue",
  "spring-promotions-description": "Promote seasonal products and services that your pharmacy has to offer during the spring season, e.g., allergy relief",
  "summer-promotions-description": "Promote seasonal products and services that your pharmacy has to offer during the summer season, e.g., UV products, insect repellents, bug bite relief",
  "fall-promotions-description": "Promote seasonal products and services that your pharmacy has to offer during the fall season, e.g., cold & cough relief",
  "thanksgiving-description": "Develop a personal relationship and increase customer loyalty",
  "seasons-greeting-description": "Develop a personal relationship and increase customer loyalty",
  "happy-new-year-description": "Develop a personal relationship and increase customer loyalty",
  "happy-birthday-message-subject": "Greetings from your local pharmacy!",
  "insurance-card-update-message-subject": "Insurance Card",
  "flu-shot-reminder-message-subject": "Vaccinations",
  "spring-promotions-message-subject": "Greetings from your local pharmacy!",
  "summer-promotions-message-subject": "Greetings from your local pharmacy!",
  "fall-promotions-message-subject": "Greetings from your local pharmacy!",
  "thanksgiving-message-subject": "Greetings from your local pharmacy!",
  "seasons-greeting-message-subject": "Greetings from your local pharmacy!",
  "happy-new-year-message-subject": "Greetings from your local pharmacy!",
  "happy-birthday-message-body": "Hi {{firstName}}, From all of us here at {{pharmacyName}}, we wish you a very happy birthday! Let us know if there's anything we can do to make it better!",
  "insurance-card-update-message-body": "We would like to make sure that we have your most up-to-date information. If you have a new insurance card, please go to Account settings to upload your most recent insurance information. Thank you!",
  "flu-shot-reminder-message-body": "Flu season is upon us and we're starting to schedule flu vaccinations. Use our app to book your appointment today!",
  "spring-promotions-message-body": "Don’t let your seasonal allergies keep you from enjoying spring! Stop by the pharmacy today to browse our OTC allergy relief options, or send us a message through the app if you have any questions.",
  "summer-promotions-message-body": "Warmer weather might mean more fun in the sun, but it also means more pesky bugs or sunburn. Head to the pharmacy today to stock up on insect repellents and sunscreen to ensure that you and your loved ones remain safe while having fun in the sun!",
  "fall-promotions-message-body": "Change in the weather has you coughing and feeling stuffy? Stop by the pharmacy today to browse our over-the-counter cough and cold relief medications, or send us a message through the app if you have any questions!",
  "thanksgiving-message-body": "It’s that time of year where we gather together to count and reflect on our blessings. You are one of the many things we are deeply grateful for. Thank you for choosing us to care for you and your loved ones. Wishing you and yours a happy Thanksgiving full of joy and kindness!",
  "seasons-greeting-message-body": "Sending holiday cheer and wishing you a great holiday season! Enjoy the cozy weather, good company, and lots of delicious food!",
  "happy-new-year-message-body": "Our pharmacy wishes you a Happy New Year! Here's to happiness and health for you and your loved ones!",
  "when-to-send": "When to send every year",
  "previous-week": "Previous week",
  "current-week": "Current week",
  "next-week": "Next week",
  "password-incorrect": "Password incorrect",
  "secure-message": "Secure Message",
  "unable-to-process": "Unable to process your request",
  "try-again-later": "Please try again later. If you need immediate assistance, please contact your pharmacy.",
  "message-pharmacy": "Message pharmacy",
  "call-pharmacy": "Call pharmacy",
  "tasks-grid-no-results-title": "Your search criteria doesn't have any tasks in the current time-frame",
  "tasks-grid-no-results-subtitle": "We couldn't find any tasks in the current time-frame based on your view and filters. Use the Previous / Next buttons to navigate in time, or the Current week button to reset the view to default.",
  "get-directions": "Get directions",
  "explore-other-locations": "Explore other locations offering this service",
  "error-getting-locations": "Error getting locations",
  "zip-code-is-not-valid": "ZIP code is not valid",
  "street-1-is-not-valid": "Street 1 is not valid",
  "street-2-is-not-valid": "Street 2 is not valid",
  "allergies-are-not-valid": "Allergies-are-not-valid, a correct example is all1,all2,all3...",
  "conditions-are-not-valid": "Conditions are not valid, a correct example is cond1,cond2,cond3...",
  "make-appointment": "Make an appointment",
  "contact-pharmacy-for-assistance": "Contact the pharmacy for further assistance with booking your appointment",
  "get-more-stores": "Get more stores",
  "no-medication-guide": "Currently unavailable. If you have questions about this medication, contact your pharmacy.",
  "error-loading-patients-under-care": "Error loading patients under care",
  "add-new-patient": "Add new patient...",
  "after-adding-patient": "After adding a new patient, please return to Appointments and select your desired service.",
  "pending-requests": "Pending requests:",
  "add-calendar-event": "Add to calendar",
  "import-forms": "Import forms",
  "import-form": "Import form",
  "number-of-forms": "Number of forms to import",
  "by-application": "By application",
  "by-email": "By email",
  "by-sms": "By SMS",
  "form": "Form",
  "import-plan": "Import Plan",
  "by-voice": "By voice",
  "error-loading-patient": "An error occurred while trying to get the patient. Please try again.",
  "in": "in",
  "ago": "ago",
  "month-short": "m",
  "hour-short": "h",
  "day-short": "d",
  "hours": "Hours",
  "missing-important-patient-record-data": "The patient record is missing import data.",
  "google": "Google",
  "apple": "Apple",
  "outlook": "Outlook",
  "reset-filters": "Reset filters",
  "calendar": "Calendar",
  "appointment-panel": "Appointment Panel",
  "appointment-filters": "Appointment Filters",
  "age": "Age",
  "confirmed": "Confirmed",
  "pending": "Pending",
  "edit-notes": "Edit Notes",
  "rejected": "Rejected",
  "service-description": "Service description",
  "additional-info": "Additional info",
  "more": "More",
  "less": "Less",
  "month-capital": "Month",
  "week-capital": "Week",
  "day-capital": "Day",
  "one-time": "One-time",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "dont-see-service": "Don't see what you need?",
  "search-by-patient-details": "Search by Name or DOB",
  "service-category": "Service category",
  "category": "Category",
  "service-category-filter": "Service Category",
  "service-type": "Service Type",
  "all-services": "All services",
  "all-service-categories": "All service categories",
  "all-service-types": "All service types",
  "team": 'Team',
  "personal": "Personal",
  "days": "Days",
  "visibility-personal-policy": "It will be viewed by you and the assignee (if assigned)",
  "visibility-team-policy": "It will be viewed by everybody",
  "show-grid": "Show on grid",
  "task-appear-grid": "The task will appear on the Grid and List views.",
  "reschedule-note": "Note: Rescheduling this appointment will not update it in any third-party calendars it has been added to.",
  "ordered": "Ordered",
  "character-count": "Limit {{max}} characters including variables. Count: {{count}}",
  "maxim-count-attachments": "Maxim count of attachments is {{count}}.",
  "selecting-files-wrong": "Something went wrong selecting files.",
  "files-sending-error-try-again": "Something went wrong sending files. Please, try again.",
  "home-account-subheading": "Settings, insurance, help, etc",
  "home-medications-subheading": "Manage meds, and order refills",
  "home-messages-subheading": "Securely chat with the pharmacy",
  "home-appointments-subheading": "View or schedule clinical services",
  "home-puc-subheading": "Add a person...",
  "create-form-first": "Please create a form first",
  "town-pharmacy": "Town Pharmacy",
  "pharmacy-general-info": "General info",
  "pharmacy-edit-business-info": "Edit business info",
  "pharmacy-general-details": "Details",
  "pharmacy-general-info-limited-brand-name": "Limited to 16 characters",
  "pharmacy-company-name": "Pharmacy short name",
  "pharmacy-brand-full-name": "Pharmacy full name",
  "pharmacy-brand-admin": "Brand admin",
  "pharmacy-parent-company": "Parent company",
  "pharmacy-ncpdp": "NCPDP",
  "pharmacy-npi": "NPI",
  "pharmacy-pms": "PMS",
  "pharmacy-facebook": "Facebook",
  "pharmacy-instagram": "Instagram",
  "pharmacy-twitter": "X",
  "pharmacy-contact-info": "Contact info",
  "pharmacy-edit-contact-info": "Edit Contact info",
  "pharmacy-email-address": "Email address",
  "pharmacy-phone-number": "Phone number",
  "pharmacy-fax-number": "Fax number",
  "pharmacy-mailing-address": "Mailing address",
  "pharmacy-billing-address": "Billing address",
  "pharmacy-brand-name-length": "The name must not exceed 16 characters",
  "pharmacy-npi-length": "The NPI must 10 characters",
  "pharmacy-departments": "Departments",
  "pharmacy-names-details": "Names",
  "display-name": "Display name",
  "length": "Length",
  "pharmacy-edit-default-department-hours": "Edit Default Department Hours",
  "pharmacy-add-default-department-hours": "Add Default Department Hours",
  "pharmacy-delete-department": "Do you want to delete the Department?",
  "pharmacy-delete-department-info": "By confirming you will delete the selected department.",
  "delete-task-type-confirm": "By confirming you will delete the selected task type.",
  "pharmacy-departments-name": "Department",
  "pharmacy-departments-hours": "Department hours",
  "pharmacy-departments-hours-subtitle": "The default hours for all stores. Individual stores can override these settings.",
  "pharmacy-departments-name-required": "Department Name is Required.",
  "pharmacy-departments-add-hours": "Add hours",
  "pharmacy-departments-remove-hours": "Remove",
  "locations-all": "All locations",
  "locations-new": "New store",
  "find-by-name-address-or-ncpdp": "Find by name, address or NCPDP",
  "locations-name": "Name",
  "locations-tag": "Tag",
  "locations-status": "Status",
  "locations-NCPDP": "NCPDP",
  "locations-address": "Address",
  "locations-pharmacy-info": "Pharmacy info",
  "locations-contact-info": "Contact info",
  "locations-teams": "Teams",
  "locations-groups": "Groups",
  "locations-services": "Services",
  "pick-up-methods": "Pickup methods",
  "pick-up-methods-description": "Set the pickup confirmation messages for this location.",
  "locations-normal-hours": "Normal hours",
  "locations-holiday-closures": "Holiday closures",
  "locations-holiday-closures-description": "Annually recurring all day  closures.",
  "locations-additional-closures": "Additional closures",
  "locations-additional-closures-description": "One-time closures by the hour or all day.",
  "locations-edit-pharmacy-info": "Edit Pharmacy Info",
  "locations-edit-contact-info": "Edit Contact Info",
  "locations-edit-department-hours": "Edit Department Hours",
  "locations-location-name": "Pharmacy(location) name",
  "locations-location-name-tooltip": "The name associated with your NCPDP. May be the same as DBA.",
  "locations-internal-name": "Internal name",
  "locations-internal-name-tooltip": "This appears in the Lumistry platform and only seen by pharmacy employees.",
  "locations-NPI": "NPI",
  "locations-store-number": "Store number",
  "locations-time-zone": "Time zone",
  "locations-latitude": "Latitude",
  "locations-longitude": "Longitude",
  "locations-phone-number": "Phone number",
  "locations-fax-number": "Fax number",
  "locations-email-address": "Email address",
  "locations-mailing-address": "Mailing address",
  "locations-edit-in-store-method": "Edit In-Store Method",
  "locations-edit-pick-up-method": "Edit Pickup Method",
  "locations-active": "Active",
  "locations-inactive": "Inactive",
  "locations-required": "Field is required",
  "locations-departments-remove-hours": "Remove",
  "locations-departments-add-hours": "Add hours",
  "all-statuses": "All statuses",
  "pharmacy-holiday-closures": "Holiday closures",
  "pharmacy-holiday-closures-description": "Annually recurring store closures.",
  "pharmacy-additional-closures": "Additional closures",
  "pharmacy-additional-closures-description": "These are not recurring.",
  "pharmacy-additional-closures-active": "Active",
  "pharmacy-additional-closures-inactive": "Inactive",
  "pharmacy-additional-name-required": "Closure name is required",
  "pharmacy-additional-date-required": "Closure date is required",
  "pharmacy-additional-hour-required": "Closure hours interval is required",
  "pharmacy-additional-label": "Label",
  "locations-default": "Default",
  "locations-department": "Department",
  "locations-departments-name-required": "Department Name is Required.",
  "locations-use-default-settings": "Use default settings",
  "wrong-date-format": "Wrong date!",
  "all-day": "all day",
  "tags": "Tags",
  "new": "New",
  "new-availability": "New availability",
  "new-availability-tab": "New Availability",
  "url-is-not-valid": "URL is not valid",
  "ok": "OK",
  "upload": "Upload",
  "replace": "Replace",
  "front-photo-missing": "Front of card required",
  "back-photo-missing": "Back of card required",
  "no-allergies": "No allergies",
  "easy-open-bottle-caps-confirm": "Would you like easy-open bottle caps?",
  "allergy-info": "Confirm that allergy info is up-to-date",
  "forms-fill-out": "Fill out {{formsCount}} required forms...",
  "every-n-weeks": "Every {{number}} weeks",
  "every-n-months": "Every {{number}} months",
  "required-field": "Required field",
  "no-puc-access": "You don't currently have permission to access this patient's record. To gain access, please go to your Account settings and add them as a Patient Under Care.",
  "forms-filled-in": "Fill out required forms",
  "health-info": "Review related health info",
  "insurance-card-reminder": "Bring insurance card",
  "changed-insurance": "Has the insurance changed since the last refill?",
  "unable-to-save-changes-try-again": "Unable to save changes, try again later",
  "pre-appointment-checklist": "Pre-appointment checklist",
  "related-health-info": "Related health info",
  "confirmation-message-sent": "We’ll send you a message to confirm your new phone number.",
  "confirmation-email-sent": "We’ll send you an email to confirm your new email address.",
  "update-info": "Update {{title}}",
  "verify-info": "Verify {{title}}",
  "error-updating-appointment": "Error updating the appointment",
  "message-body-is-required": "Message body is required",
  "verify-patient": "Mark patient as verified",
  "verify-patient-warning": "This patient record did not originate from your Pharmacy Software. It was created when this person registered for a patient portal account login. Please make sure that you verify this person’s identity before exchanging sensitive information.",
  "no-forms-required": "No forms were required for this appointment",
  "form-unavailable": "Form is no longer available",
  "form-not-started": "Form not started",
  "form-submitted": "Form has been submitted",
  "no-form-templates": "No form templates available.",
  "lose-data-creating-form": "By confirming you will lose the current progress on creating a new form from a template.",
  "download": "Download",
  "preparing-submission": "Preparing the submission",
  "download-will-begin": "Your download will begin shortly.",
  "delete-submission-confirmation": "Are you sure you want to delete this submission?",
  "not-entered": "Not entered",
  "submitted-on": "Submitted On",
  "download-pdf": "Download PDF",
  "submission-modal": "Submission Modal",
  "submission-view": "View Form Submission",
  "edit-form": "Edit Form",
  "form-view": "Form View",
  "medical-information": "Medical information",
  "yes-easy-caps": "Yes, I prefer easy-open caps",
  "no-safety-caps": "No, I prefer safety caps",
  "easy-open-bottle-caps-description": "Federal regulations require that most medications be packaged with child-resistant “safety” caps. Your choice of easy-open caps indicates your desire to have medications dispensed with non-safety caps.",
  "checklist-complete": "Checklist completed",
  "checklist-incomplete": "Please complete the checklist",
  "service-status-updated": "Service status updated successfully",
  "error-loading-form": "Error loading form",
  "account-not-found-heading": "Account not found",
  "every-week": "Every week",
  "every": "Every",
  "weeks": "weeks",
  "account-not-found-subheading": "If you created your account using your Apple or Google Sign-On, please sign in with that account",
  "account-not-found-subheading-sso": "If you created your account using your email address, please sign in with your email",
  "invalid-email-format": "Invalid email format",
  "close-confirmation-question": "Are you sure you want to exit?",
  "close-confirmation-text": "This form is required to schedule your appointment.",
  "close-confirmation-exit": "Exit",
  "close-confirmation-keep-appointment": "Keep appointment",
  "password-reset-subheading": "For the security and protection of your account, please create a new password.",
  "save-password": "Save password",
  "venue-in-person": "In Person",
  "confirm-to-make": "Are you sure you want to make this form",
  "venue-virtual": "Video",
  "venue-over-the-phone": "Phone",
  "get-the-app": "Get the app",
  "all-tasks": "All tasks",
  "rx-order-progress": "Order #{{orderId}} In Progress",
  "prescriptions-filled": "{{filled}} of {{total}} prescriptions are filled",
  "manage-subscription": "Manage your and your family's prescriptions, communicate with your pharmacy, access educational materials, schedule appointments, and more!",
  "edit-reschedule-this-appointment": "Edit or reschedule this appointment",
  "cancel-this-appointment": "Cancel this appointment",
  "message-this-patient": "Message this patient",
  "contact-pharmacy-for-policy-info": "Please contact your local pharmacy for more information about their",
  "prescriptions-empty-state-paragraph-one": "Adding your medications will make it easy to track and request refills on important prescriptions that help keep you healthy!",
  "prescriptions-empty-state-paragraph-two": "To add your prescriptions now, tap the button below. If you would like to transfer your medications from another pharmacy, please tap the link above.",
  "prescriptions-empty-state-button-text": "Add meds",
  "otc-empty-state-paragraph-one": "Letting your pharmacy know about all the medications, vitamins and supplements you take can help them look out for possible drug interactions, keeping you safer and healthier!",
  "otc-empty-state-paragraph-two": "This includes any medications you use that don’t require a prescription",
  "otc-empty-state-button-text": "Add OTC meds",
  "viewing-medications-for": "Viewing medications for:",
  "mark-unread": "Mark unread",
  "error-occurred-title": "An unexpected error has occurred",
  "error-occurred-description": "If you need immediate assistance, please contact your pharmacy.",
  "contact-prescription-ready": "We will contact you when your prescription is ready",
  "task-type": "Type",
  "task-status": "Status",
  "task-assigned-to": "Assigned to",
  "task-priority": "Priority",
  "task-created-by": "Created by",
  "created": "Created",
  "create-refill": "Create Refill",
  "updated-without-date": "Updated",
  "completed": "Completed",
  "task-due-by": "Due by",
  "integration": "Integration",
  "due-date": "Due date",
  "api-host": "API Host",
  "server-address": "Server Address",
  "integration-location-id": "Integration Location ID",
  "server-port": "Server Port",
  "api-key": "API Key",
  "setup-integration": "Setup Integration",
  "integration-missing": "Currently no integration setup",
  "edit-integration": "Edit Integration",
  "edit-date-override": "Edit date override",
  "date-overrides": "Date overrides",
  "pharmacy-software": "Pharmacy Software",
  "npi": "NPI",
  "task-visibility": "Visibility",
  "prescription-info": "Prescription info",
  "add-to-list": " Add to list",
  "adding-prescriptions-for": "Adding prescriptions for ",
  "last-submission": "Last submission",
  "no-submissions": "No forms has been submitted",
  "no-forms-to-display": "No forms to display",
  "search-forms": "Search forms",
  "no-submissions-found": "No results found for ",
  "delete-availability-in-use-tooltip": "This availability is in use for services. Please\nremove/change the service availability and\nthen come back to remove the availability.",
  "add-patients-start-messaging": "Add your patients to start messaging",
  "upload-patients-csv": "Upload patients via CSV",
  "photo-id-2": "Photo I.D.",
  "find-meds": "Find Meds",
  "rx-number-must-be-numeric": "Please enter only numbers",
  "error-occurred-integration-save": "Error occurred while trying to save integration.",
  "error-occurred-integration-load-save": "An error occurred while trying to load or save integration. Please try again.",
  "integration-saved": "Integration saved",
  "hide": "Hide",
  "enter-valid-phone-number": "Please enter a valid phone number.",
  "end-date": "End date",
  "server-address-required": "Server Address is required",
  "server-error": "Internal Server Error",
  "integration-location-id-required": "Integration Location ID is required",
  "start-time-required": "Start time is required",
  "end-time-required": "End time is required",
  "custom-filters": "Custom Filters",
  "custom-domain": "Custom Domain (Optional)",
  "appointments-filters-info-title": "Displaying appointments that match following criteria:",
  "service-name-info-box": "Service Name set to ",
  "service-category-info-box": "Service Category set to ",
  "applied-to-all-locations": "Applied to all locations",
  "apply-to-all": "Apply to all",
  "set-to-default": "Set to default",
  "availability-name": "Availability name",
  "name-is-required": "Name is required",
  "appointment-notes-update-error": "An error occurred while trying to update the appointment notes. Please try again.",
  "add-image": "Add image",
  "configure-label-secure": "Configure {{label}} (Secure)",
  "add-file": "Add file",
  "pharmacy-npi-number": "Pharmacy NPI Number",
  "have-second-insurance": "Is there a secondary insurance?",
  "my-tasks": "My tasks",
  "access-denied": "Access denied",
  "check-back-later": "Please check back later or contact the pharmacy if you have questions.",
  "contact-administration": "Contact the IT administrator if you have questions",
  "new-messages": "New messages",
  "this-week": "This week",
  "this-month": "This month",
  "older": "Older",
  "time-sensitive": "Time sensitive",
  "profile-settings": "Profile settings",
  "switch-user": "Switch users",
  "audit-trail": "Audit trail",
  "automated-actions": "Automated actions",
  "help-support": "Help and support",
  "internal-demos": "Internal Demos",
  "vow-ivr": "Vow IVR",
  "wellness-classes": "Wellness classes",
  "event-logs": "Event logs",
  "subscribe-to-webhooks": "Subscribe to Realtime Updates (Webhooks)",
  "realtime-updates": "Realtime Updates",
  "enabled": "Enabled",
  "disabled": "Disabled",
  "overwrite-defaults": "Overwrite Defaults",
  "notifications": "Notifications",
  "no-notifications": "No Notifications",
  "no-notifications-subtitle": "We'll notify you when something happens",
  "add-manually": "Add manually",
  "authentication-failed-generic": "Authentication failed",
  "realtime-updates-api-key": "Realtime Updates API Key",
  "internal-webhook-keys-renewed": "Internal Webhook API Key Renewed",
  "error-occurred-internal-webhook-keys-renew": "Error Occurred while renewing Internal Webhook API Key",
  "refresh": "Refresh",
  "relationship-to-me-is-required": "Relationship to me is required",
  "sending": 'Sending...',
  "read": "Read",
  "delivered": "Delivered",
  "failed-to-send": "Failed to send",
  "retry": "Retry",
  "at": "at",
  "workflow-status-in-queue": "In queue",
  "workflow-status-out-of-stock": "Out of stock",
  "workflow-status-special-order": "Special order",
  "workflow-status-in-process": "In process",
  "workflow-status-awaiting-insurance-authorization": "Awaiting insurance",
  "workflow-status-awaiting-prescriber-authorization": "Awaiting prescriber",
  "workflow-status-ready-for-shipping": "Ready for shipping",
  "workflow-status-ready-for-delivery": "Ready for delivery",
  "workflow-status-partial-fill": "Partial fill",
  "workflow-status-partially-filled": "Partially filled",
  "workflow-status-delivery-in-progress": "Out for delivery",
  "workflow-status-ready-for-pickup": "Ready for pickup",
  "workflow-status-dispensed": "Dispensed",
  "workflow-status-mail": "Mail",
  "workflow-status-shipped": "Shipped",
  "workflow-status-delivered": "Delivered",
  "workflow-status-returned": "Returned",
  "workflow-status-returned-to-stock": "Returned to stock",
  "workflow-status-cancelled": "Canceled",
  "workflow-status-expired": "Expired",
  "workflow-status-discontinued": "Discontinued",
  "workflow-status-transferred": "Transferred",
  "workflow-status-insurance-refused": "Refused by insurance",
  "workflow-status-prescriber-refused": "Refused by prescriber",
  "workflow-status-filled": "Rx filled",
  "workflow-status-undefined": "-",
  // Workflow Statuses Long
  "workflow-status-in-queue-message": "This Rx is in the refill queue",
  "workflow-status-out-of-stock-message": "This Rx is out of stock",
  "workflow-status-special-order-message": "This Rx is on special order",
  "workflow-status-in-process-message": "This Rx is in the process of refilling",
  "workflow-status-awaiting-insurance-authorization-message": "This Rx is awaiting insurance authorization",
  "workflow-status-awaiting-prescriber-authorization-message": "This Rx is awaiting prescriber authorization",
  "workflow-status-ready-for-shipping-message": "This Rx is filled and ready for shipping",
  "workflow-status-ready-for-delivery-message": "This Rx is filled and ready for delivery",
  "workflow-status-partial-fill-message": "This Rx was partially filled",
  "workflow-status-partially-filled-message": "This Rx was partially filled",
  "workflow-status-delivery-in-progress-message": "This Rx has been sent out for delivery",
  "workflow-status-ready-for-pickup-message": "This Rx is filled and ready for pickup",
  "workflow-status-dispensed-message": "This prescription has been dispensed",
  "workflow-status-mail-message": "This prescription has been mailed",
  "workflow-status-shipped-message": "This prescription has been shipped",
  "workflow-status-delivered-message": "This prescription has been delivered",
  "workflow-status-returned-message": "This prescription was returned",
  "workflow-status-returned-to-stock-message": "This prescription was returned to stock",
  "workflow-status-cancelled-message": "This prescription was canceled",
  "workflow-status-expired-message": "This prescription has expired",
  "workflow-status-discontinued-message": "This prescription has been discontinued",
  "workflow-status-transferred-message": "This prescription was transferred",
  "workflow-status-insurance-refused-message": "This prescription was refused by the insurance provider",
  "workflow-status-prescriber-refused-message": "This prescription was refused by the prescriber",
  "workflow-status-filled-message": "This prescription has been filled",
  "workflow-status-undefined-message": "Info unavailable",
  // Rx Statuses
  "rx-status-no-refills": "No more refills",
  "rx-status-expired": "Rx expired",
  "rx-status-too-soon": "Rx too soon",
  "rx-status-not-found": "Rx not found",
  "rx-status-controlled": "Controlled",
  "rx-status-narcotic": "Narcotic",
  "rx-status-transferred": "Transferred",
  "rx-status-discontinued": "Discontinued",
  "rx-status-on-hold": "On hold",
  "rx-status-deleted": "Rx deleted",
  "rx-status-fillable": "Refill OK",
  "rx-status-partially-filled": "Partial fill",
  "rx-status-out-of-stock": "Out of stock",
  "rx-status-special-order": "Special order",
  "rx-status-deceased": "Patient deceased",
  "rx-status-undefined": "-",
  // Rx Statuses Long
  "rx-status-no-refills-message": "This Rx has no more refills available",
  "rx-status-expired-message": "This prescription has expired",
  "rx-status-too-soon-message": "It is too soon to refill this prescription",
  "rx-status-not-found-message": "There is no record of this prescription number",
  "rx-status-controlled-message": "This prescription is a controlled substance",
  "rx-status-narcotic-message": "This prescription is a narcotic",
  "rx-status-transferred-message": "This prescription was transferred",
  "rx-status-discontinued-message": "This prescription has been discontinued",
  "rx-status-on-hold-message": "This prescription is on hold",
  "rx-status-deleted-message": "This prescription was deleted",
  "rx-status-fillable-message": "This prescription is good for refilling",
  "rx-status-partially-filled-message": "This prescription was partially filled",
  "rx-status-out-of-stock-message": "This prescription is out of stock",
  "rx-status-special-order-message": "This prescription is on special order",
  "rx-status-deceased-message": "The prescription belongs to a deceased patient",
  "rx-status-undefined-message": "Info unavailable",
  // Order Statuses
  "order-status-received": "Received",
  "order-status-filled": "Filled",
  "order-status-in-progress" : "In Progress",
  "order-status-out-for-delivery": "Out for delivery",
  "order-status-ready-for-pickup": "Ready for pickup",
  "order-status-picked-up": "Picked up",
  "order-status-mailed": "Mailed",
  "order-status-shipped": "Shipped",
  "order-status-delivered": "Delivered",
  "order-status-returned-to-stock": "Returned to stock",
  "order-status-info-unavailable": "Info unavailable, contact your pharmacy",
  "order-status-undefined": "Info not available, try again later",
  // Order Progress Statuses
  "order-progress-not-started": "Not started",
  "order-progress-started": "Started",
  "order-progress-completed": "Completed",
  "order-progress-filled": "Filled",
  "order-progress-undefined": "(n/a)",
  // Refill Status
  "refill-status-passed": "Passed",
  "refill-status-not-passed": "Not Passed",
  "refill-status-filter-all": "All",
  //
  "preview": "Preview",
  "form-not-editable": "This form is not editable",
  "form-preview": "Form Preview",
  "dob-age": "DOB (age)",
  "form-name": "Form name",
  "mark-all-as-read": "Mark all as read",
  "mark-as-read": "Mark as read",
  "continuation-disclaimer-register": "By tapping Next, you agree to this Pharmacy's",
  "no-edit": "No edit",
  "imported": "Imported",
  "inactive": "Inactive",
  "active": "Active",
  "submissions": "Submissions",
  "last-modified": "Last modified",
  "this-patient-has-not-sent-or-received-any-messages": "This patient has not sent or received any messages",
  "have-moment-break-ice-and-ask-them-how-they-are-doing": "Have a moment? Break the ice and ask them how they\'re doing!",
  "copy-url": "Copy URL",
  "error-add-self-as-people-under-care-request":"You may not add yourself as a patient under your care",
  "error-exist-record-in-people-under-care-request-list":"{{name}} is already on your list of Patients Under Your Care",
  "enable-datafeed": "Enable Datafeed",
  "server-port-required": "Server Port is Required",
  "invalid-ip-url": "Please enter a valid IP address or URL",
  "numeric-only": "Please enter only numeric values",
  "find-pharmacy": "Find Pharmacy",
  "allergies-info-not-available": "Allergy info not available",
  "info-not-available": "Info not available",
  "sender": "Sender",
  "recipients": "Recipients",
  "timestamp": "Timestamp",
  "bulk-messages": "Bulk Messages",
  "scheduled": "Scheduled",
  "non-secure-messages": "Non-secure messages",
  "secure-chats": "Secure chats",
  "templates": "Templates",
  "please-confirm-your-choice": "Please confirm your choice",
  "form-edit-warning-title":"This form has submissions associated with it",
  "form-edit-warning":"Making changes to this form may result in patient-submitted data being removed from the forms that they’ve completed. To avoid losing important information, you can download the form submissions before making changes or archive this form and create a new one.",
  "form-save-confirm": "Confirm save",
  "form-save-modal-message": "Saving these changes may result in data loss inside form submissions. Are you sure you want to proceed?",
  "form-save-save-button": "Save",
  "file-too-large": "The file is too large to attach, try a smaller file. Max file upload size: 5 MB",
  "order-tracker-title": "Order: {{patient}} on {{date}}",
  "order-tracker-description": "{{completed}} of {{total}} prescription(s) filled",
  "order-tracker-see-orders": "+{{total}} more",
  "order-tracker-in-progress" : "In progress",
  "order-tracker-filled": "Filled",
  "reset": "Reset",
  "datafeed" : "Datafeed",
  "download-submissions":"Download submissions",
  "inactive-form-error-title": "Oops! Does not exist.",
  "inactive-form-error-description": "This form is no longer accepting submissions. If you need assistance, please contact your pharmacy.",
  "form-open-error": "An error occurred while trying to open the form, please try again later.",
  "bulk-message-your-patients": "Bulk message your patients",
  "new-bulk-message": "New bulk message",
  "target-your-patients-with-clinical-opportunities-and-more": "Target your patients with clinical opportunities and more",
  "paperless-enrollment-title": "You’re Enrolled in Paperless",
  "paperless-enrollment-subtitle": "Being paperless means you will receive all important documents and records digitally.",
  "paperless-enrollment-text": "No action is required for you to continue.",
  "paperless-enrollment-benefits-title": "Benefits of being paperless",
  "paperless-enrollment-first-benefit": "Positive environmental impact",
  "paperless-enrollment-second-benefit": "Increased protection from identity theft",
  "paperless-enrollment-third-benefit": "Improved health literacy through easy-to-understand medication videos",
  "paperless-enrollment-fourth-benefit": "Convenient access to med info and health videos from within the app",
  "paperless-enrollment-med-info": "Where can I find med info & health videos?",
  "paperless-screen-title": "Paperless",
  "validation-start-hour-smaller": "Start time must be before end time!",
  "validation-end-hour-greater": "End time must be after start time!",
  "npi-required": "NPI is required",
  "api-key-required": "API Key is required",
  "username-required": "Username is required",
  "password-required": "Password is required",
  "patient-message-invalid-file-type": "Invalid file type. Supported file types are: JPEG, PNG, AVIF, WebP, HEIC, GIF, PDF",
  "patient-message-file-limit": "Limit exceeded. Max number of attachments allowed: 1",
  "bulk": 'Bulk',
  "appointment-created":"Appointment created",
  "opted-sms-email": "Make sure that you’re opted in to SMS or email notifications, so that you don’t miss any important medical information from your pharmacy.",
  "sms-data-rates": "SMS & data rates may apply.",
  "communication-preferences-benefit": "Letting us know about your communication preferences will enable us to contact you in the ways that you would like to be contacted",
  "communications-notifications-etc": "Communications, notifications, etc.",
  "med-info-description-start": "You can easily find medication info and health videos by tapping on the ",
  "med-info-description-end": " icon on your prescription within the medications tab.",
  "medicine-name": "Metformin HCL 500mg tablet",
  "refill-due-date": "Refill due: Nov 3",
  "last-filled-date": "Last filled",
  "med-info-location": "Where to find medication info",
  "pharmacy-department-name-exist": "Department name already exists",
  "appointment-at":"Appointment at",
  "pharmacy-appointment":"Pharmacy appointment",
  "fill-out-forms": "Fill out forms",
  "do-it-later":"Do it later",
  'welcome-to-forms': 'Welcome to forms',
  'create-new-form-or-upload': 'Get started by creating a new form or importing from Jotform.',
  'create-new-form-or-upload-forms': 'Create a new form or import from Jotform',
  'collect-ephi-or-hipaa': 'Collect ePHI in a HIPAA-compliant way',
  'save-time-by-getting-info': 'Save time by getting info before appointments',
  'import-your-existing-templates':
  'Import your existing templates from Jotform',
  'save-paper': 'Save paper!',
  'all-forms-are-inactive': 'All forms are inactive.',
  'patient-notifications': 'Patient Notifications',
  'edit-after-hours': 'Edit After Hours',
  'error-occurred-after-hours-load-save': 'An error occurred while trying to load or save after hours message. Please try again.',
  'message-settings-saved': "Message Settings Saved",
  'auto-response-after-hours': 'Auto-response after hours',
  "error-occurred-integration-load": "Error occurred while trying to get Integration",
  "forms-are-optional": "Forms are optional",
  "forms-are-optional-checkbox-tooltip":"Checking this option will allow patients to book appointments without submitting forms",
  "no-results-found": "No results found",
  "med-info-preferences": "Medication info preferences",
  "select-med-info": "Select how you would like to receive medication info:",
  "receive-digitally": "Receive digitally in-app",
  "receive-print-out": "Receive paper printout",
  "patient-message-invalid-image-file-type": "Invalid image type. Supported images types are: JPEG, PNG, AVIF, WebP, HEIC",
  'welcome-to-submissions': 'Welcome to submissions',
  'all-forms-submitted-by-your-patients-will-appear-here':'All forms submitted by your patients will appear here.',
  'anytime-access-to-your-submissions':'Anytime access to all your form submissions in one place',
  'easy-managment-without-need-to-print':'Easy management without the need to print or scan',
  'ability-to-download-single-or-multiple-forms':'Ability to download single or multiple forms',
  'savings-on-paper-and-toner-materials':'Savings on paper and toner materials!',
  "pharmacy-info" :" Pharmacy info",
  "pharmacy-name":"Pharmacy name",
  "pharmacy-name-is-required" :"Pharmacy name is required",
  "about-your-transfer":"About your transfer",
  "what-meds-are-you-transferring":"What medications are you transferring?" ,
  "all-my-medications" :"All my medications",
  "some-of-my-medications":"Some of my medications",
  "why-transfer-all-the-meds" :"Why transfer all the meds...",
  "why-transfer-all-the-meds-description":"Having your meds in one place is not only more convenient for you, it also leads to a lower risk of drug interactions and medication errors.",
  "start-over-to-change-person":"Start over to change person",
  "fill-prescriptions-after-transferring":"Fill prescriptions after transferring",
  "transfer-another":"Transfer another",
  "start-over":"Start over",
  "enter-your-medications" :"Enter your medications",
  "enter-more":"Enter more",
  "at-least-one-drug-name-is-required":"At least one drug name is required",
  "your-transfer-request-has-been-submitted":"Your transfer request has been submitted",
  "your-transfer-request-has-been-submitted-and-filled":"Your transfer request has been submitted and will be filled",
  "pharmacy":"Pharmacy",
  "from-different-pharmacy": "From a different pharmacy",
  "whose-prescription-are-you-transferring":"Whose prescriptions are you transferring?",
  "where-are-you-transferring-from":"Where are you transferring them from?",
  "another-pharmacy-location":"Another {{pharmacyName}} location",
  "different-pharmacy":"A different pharmacy",
  "transfer-meds-header-title": "Transfer Meds",
  "all-medications":"All medications",
  "appointments-on-service": "There are appointments on this service. It cannot be deleted.\nDisable the service so new appointments cannot be booked.",
  'fill-optional-forms-reminder':'This service has forms that you must complete prior to your appointment',
  'template': 'Template',
  'forms-services': 'Services',
  'offerings': 'Offerings',
  'locations-languages-spoken': 'Languages',
  'refills': 'Refills',
  'offered-to-patient': 'Offered to patient',
  'on-of': 'On/Off',
  'appt': 'Appt',
  'pharmacy-hours': 'Pharmacy hours',
  "refill-not-passed":"Refill Requests Not Passed",
  "new-form-submissions": "New Form Submissions",
  "tasks-completed": "Tasks completed",
  "messages-read": "Messages read",
  'select-chat-template': 'Select Chat Template',
  'use-template': 'Use template',
  'insert-emoji': 'Insert emoji',
  'attach-files': 'Attach files',
  "roles": "Roles",
  "global": "Global",
  "role-corporate": "Corporate",
  "role-it-admin": "IT Admin",
  "role-brand": "Brand",
  "role-manager": "Manager",
  "role-staff": "Staff",
  "role-no-access": "No Access",
  "role-lumistry-user": "Lumistry User",
  "role-lumistry-admin": "Lumistry Admin",
  "profile-first-name": "First name",
  "profile-last-name": "Last name",
  "mark-as-passed": "Mark as passed",
  "mark-as-not-passed": "Mark as not passed",
  "dashboard-no-appointments": "No appointments",
  "dashboard-task": "Task",
  'contact-help-support': "Contact Support",
  'help-with-prescription': 'Help with a prescription',
  'prescription-help-copy': 'Help with prescriptions, services, etc',
  'tech-support': 'Tech support for the app',
  'need-help-prescription': 'I need help with a prescription',
  'send-secure-message': 'Send a secure message',
  'call-the-pharmacy': 'Call the pharmacy',
  "help-and-support": "Help and Support",
  "edit-user": "Edit User",
  "tasks-list-tab": "Tasks List",
  "tasks-grid-tab": "Tasks Grid",
  "tasks-grid-automation": "Tasks Automation",
  "send-a-secure-message":"Send a secure message",
  "send-date": "Send date",
  "secure-chat": "Secure chat",
  "share-video": "Share Video",
  "copy-link-to-share-video-message": "Copy the link below to share it in a message",
  "video-send": "Send...",
  "copy": "Copy",
  'secure': 'Secure',
  'in-app-chat': 'In-app chat',
  'non-secure': 'Non-secure',
  'sms-email-1-way-communication': 'SMS, email (1-way)',
  'sms-email': 'SMS/Email',
  'send-to-all-patients': 'Send to all patients',
  'send-to-all': 'Send to all',
  'send-to-specific-people': 'Send to specific people',
  'add-recipient': 'Add recipient',
  'when-to-send-every-year': 'When to send every year',
  'immediate': 'Immediate',
  'scheduled-not-available': 'Scheduled (within MVP scope not yet available)',
  'google-review': 'Google review',
  'recipient-criteria': 'Recipient criteria',
  'bulk-message-added': 'Bulk message added',
  'error-creating-bulk-message': 'Error creating a bulk message',
  'video-share-send-bulk-messages': 'Hi first name, your refill is ready to be picked up.',
  "automations":"Automations",
  "automated-tasks":"Automated Tasks",
  "insurance-changes":"Insurance changes",
  "update-automation-error":"Could not update Automation. Please try again.",
  "get-automated-tasks-error":"An error occurred while trying to load the automated tasks. Please try again.",
  "configure":"Configure",
  "info-cards": "Info cards",
  "edit-info-card": "Edit Info Card",
  "new-info-card": "New Info Card",
  "link": "Link",
  "link-url": "Link URL",
  "link-label": "Link Label",
  "one-or-two-words-are-suggested": "1 or 2 words are suggested",
  "max-char-allowed-message": "{{maxChar}} character maximum",
  "title-is-required": "Title is required",
  "enable": "Enable",
  "disable": "Disable",
  "find-assignee-or-summary": "Find Assignee or Summary",
  "task-types-description": "Task Types help categorize tasks. They display on the List and Grid View.",
  "created-date": "Created date",
  "task-category": "Task Category",
  "confirming-deactivate-user": "By confirming you will deactivate the user",
  "bottle-caps-confirmation-message": "Would you like easy open bottle caps?",
  "character-count-of-total": "Character count: {{count}}/{{total}}",
  "unexpected-error": 'Unexpected Error',
  "patient-does-not-have-medications":"This patient does not have medications in this pharmacy",
  "remaining-refills":"Refills remaining",
  "rx":"Rx",
  "send-video":"Send video",
  "enrolled":"Enrolled",
  "not-enrolled":"Not enrolled",
  "patient-record-updates": "Patient record updates",
  "failed-refills": "Failed refills",
  "paperless-patient": "Paperless Patient",
  "allergy-updates": "Allergy updates",
  "patient-record-updates-description": "Creates a task with updates patient made to their profile like Easy-Open Bottle Caps, Insurance Changes and Medical Conditions so you can easily update PMS",
  "failed-refills-description": "Creates a task with refills that weren't processed when PMS wasn't available so you can process them again at a later time when the PMS is available",
  "paperless-patient-description": "Creates a task with a list of patients that updated their paperless options",
  "allergy-updates-description": "Creates a task with any allergy updates patient made to their profile so you can easily reference their medications and update PMS",
  "onboarding": "Onboarding",
  "date-written":"Date written",
  "app-name": "App name",
  "app-name-required": "App name required!",
  "app-name-tooltip-text": "This will appear in your mobile app. Please contact customer support to update your app name.",
  "app-store-name": "App store name",
  "app-store-name-required": "App store name required!",
  "app-store-name-tooltip-text": "This will appear in Apple App Store and Google Play. Limited in length.",
  "app-marketplace-name": "App marketplace name",
  "app-marketplace-name-required": "App marketplace name required!",
  "app-marketplace-name-tooltip-text": "This will appear in Apple App Store and Google Play. Limited in length.",
  "android-identifier": "Android Identifier",
  "android-store-link": "Android store link",
  "apple-identifier": "Apple Identifier",
  "apple-store-link": "Apple Store Link",
  "ios-app-id": "iOS App ID",
  "ios-team-id": "iOS Team ID",
  "ios-bundle-id": "iOS Bundle ID",
  "non-secure-message": "Non-secure message",
  "patient-can-start-new-chat": "Patient can start new chat",
  "can-patient-start-chat-info": "Patients can always reply to an existing thread at any time. This setting controls whether or not they can start a new thread.",
  "refill-order":"Refill order",
  "automated-workflow":"Automated Workflow",
  "new-insurance-card-primary-front":"Added - Primary Insurance card - Front",
  "new-insurance-card-primary-back":"Added - Primary Insurance card - Back",
  "removed-insurance-card-primary-front":"Removed - Primary Insurance card - Front",
  "removed-insurance-card-primary-back":"Removed - Primary Insurance card - Back",
  "new-insurance-card-secondary-front":"Added - Secondary Insurance card - Front",
  "new-insurance-card-secondary-back":"Added - Secondary Insurance card - Back",
  "removed-insurance-card-secondary-front":"Removed - Secondary Insurance card - Front",
  "removed-insurance-card-secondary-back":"Removed - Secondary Insurance card - Back",
  "add-variable": "Add variable",
  "patient-first-name-messaging": "Patient first name",
  "patient-last-name-messaging": "Patient last name",
  "pharmacy-phone-number-messaging": "Pharmacy phone number",
  "add-attachment": "Add attachment",
  "highlight-text-to-insert-link": "Highlight text to insert link",
  "appointment-details": "Appointment Details",
  "account-information": "Account information",
  "please-confirm-or-fill-in": "Please confirm or fill-in the following info.",
  "primary-contact": "Primary Contact",
  "business-information": "Business Information",
  "brand-name": "Brand name",
  "brand-name-description": "Use if your pharmacy is associated with an overarching brand. May be the same as Pharmacy name for single location accounts.",
  "for-iternal-use": "For internal use if you have one.",
  "pharmacy-information": "Pharmacy Information",
  "legal-entity-name": "Legal entity name",
  "legal-entity-name-is-required": "Legal entity name is required",
  "dba": "DBA",
  "dba-is-required": "DBA is required",
  "location-email-address": "Pharmacy email address",
  "pharmacy-email-address-is-required": "Pharmacy email address is required",
  "pharmacy-ncpdp-is-required": "NCPDP is required",
  "pharmacy-npi-is-required": "NPI is required",
  "duns-number": "D-U-N-S Number",
  "duns-number-is-required": "D-U-N-S Number is required",
  "duns-is-not-valid": "D-U-N-S Number is not valid",
  "tax-id-is-not-valid": "Tax ID is not valid",
  "tax-id": "Tax ID/EIN",
  "tax-id-is-required": "Tax ID is required",
  "pharmacy-managment-system": "Pharmacy managment system",
  "pharmacy-managment-system-is-required": "Pharmacy managment system is required",
  "business-type": "Business type",
  "business-type-is-required": "Business type is required",
  "time-zone-is-required": "Time zone is required",
  "duns-tooltip-before": "The Data Universal Numbering System, abbreviated as DUNS or D-U-N-S, is a proprietary system developed and managed by Dun & Bradstreet that assigns a unique numeric identifier, referred to as a DUNS number to a single business entity. This is obtained directly from Dun & Bradstreet and is ",
  "duns-tooltip-after": "required.",
  "pharmacy-name-tooltip": "The name associated with your NCPDP. May be the same as DBA.",
  "legal-name-tooltip": "The legal business name the pharmacy operates under.",
  "dba-tooltip": "The public facing name the pharmacy operates under.",
  "resolved-date": "Resolved date",
  "no-items-to-display-for": "No items to display for",
  "mark-as-flagged": "Mark as flagged",
  "mark-as-unflagged": "Mark as unflagged",
  "vaccination-info": "Vaccination info",
  "one-time-vaccination-info-warning": "Adding this information is a one-time action and cannot be edited once saved.",
  "amt-administered": "Amt. administered",
  "manufacturer": "Manufacturer",
  "lot-nr": "Lot #",
  "amt-administered-is-required": "Amt. administered is required",
  "manufacturer-is-required": "Manufacturer is required",
  "lot-nr-is-required": "Lot # is required",
  "exp-date": "Exp. date",
  "date-of-vis": "Date of VIS",
  "date-administered": "Date administered",
  "exp-date-required": "Exp. date is required",
  "date-of-vis-required": "Date of VIS is required",
  "date-administered-required": "Date administered is required",
  "other-location": "Other location",
  "arm": "Arm",
  "leg": "Leg",
  "other-option": "Other...",
  "im": "IM",
  "sq": "SQ",
  "route": "Route",
  "other-route": "Other route",
  "side": "Side",
  "left": "Left",
  "right": "Right",
  "location-required": "Location is required",
  "route-required": "Route is required",
  "side-required": "Side is required",
  "other-location-required": "Other location is required",
  "other-route-required": "Other route is required",
  "user-authentication": "User Authentication",
  "refill-errors":"Refill errors",
  "refill-errors-description":"Customization is available only with a PMS integration.",
  "rx-status":"Rx status",
  "allow-refill":"Allow refill",
  "refill-due-reminders":"Refill due reminders",
  "edit-refill-due-reminders":"Edit Refill Due Reminders",
  "refill-due-reminders-non-controlled":"Non-controlled substance",
  "refill-due-reminders-controlled":"Controlled substance",
  "refill-past-due-reminders":"Past due reminder",
  "refill-due-reminders-non-controlled-long-text":"Non-controlled substance (0-30 days)",
  "refill-due-reminders-controlled-long-text":"Controlled substance (0-30 days)",
  "refill-past-due-reminders-long-text":"Refill past due reminder (1-14 days)",
  "days-prior":"days prior",
  "days-after":"days after",
  "edit-refill-errors":"Edit Refill Error - {{refillError}}",
  "refill-error-toggle-description":"Enabling this option will allow patients to request this refill online.",
  "refill-error-toggle-name":"Allow refill",
  "error-description":"Error description",
  "error-name":"Error name",
  "character-count-of-total-refill-error":"Character count: {{charCount}}/{{count}}",
  "how-many-days-reminder-tooltip":"This is how many days before the refill due date a refill due reminder will be sent to the patient",
  "how-many-days-past-due-reminder-tooltip":"This is how many days after the refill due date a refill past due notification will be sent to the patient",
  "home-phone": "Home Phone",
  "other-phone": "Other Phone",
  "cell-phone": "Cell Phone",
  "health-videos": "Health videos",
  "view-all": "View all",
  "SMS": "SMS",
  "use-same-message-for-email": "Use same message for email",
  "each-word-capital-first-letter": "Ensure that the first letter of each word is capitalized!",
  "refills-default-chat-message": "This message is regarding Rx# {{rxNumber}} {{drugName}} for {{fullName}}.‏‏‎ ‎",
  "rx-transfer-request": "Rx Transfer Request",
  "rx-transfer-request-description": "",
  "pharmacy-phone": "Pharmacy phone",
  "patient-requesting-transfer": "Patient is requesting to transfer",
  "patient-wants-refill": "Patient wants these filled",
  "due-date-required": "Due date is required",
  "business-info": "Business info",
  "label-and-url-both-required": "Please provide values for both the label and the URL, or leave both fields empty.",
  "url": "URL",
  "company": "Company",
  "space-craft-id": "SpaceCraftID",
  "pid": "PID",
  "default-location-id": "defaultLocationId",
  "g-analytics-key": "G.AnalyticsKey",
  "account-url": "Account URL",
  "refill-url": "Refill URL",
  "transfer-url": "Transfer URL",
  "number-is-not-valid": "Please enter only numbers",
  "website": "Website",
  "vaccination-info-pharmacy-use-only": "Vaccination info (pharmacy use only)",
  "immunizer": "Immunizer",
  "add-vaccination-error": "Could not add vaccination information. Please try again.",
  "enter-age": "Enter age",
  "select-filter": "Select filter",
  "filter-is-required-field": "Filter is a required field",
  "must-meet-all-criteria-below": "Must meet all criteria below",
  "send-to-filtered-list": "Send to filtered list",
  "age-is-required-field": "Age is a required field",
  "entered-age-is-invalid": "Entered age is invalid",
  "zip-code-is-required-field": "ZIP code is a required field",
  "one-or-more-invalid-zip-codes-entered": "1 or more invalid ZIP codes entered",
  "use-commas-for-multiple-values": "Use commas for multiple values",
  "enter-zip-codes": "Enter ZIP codes",
  "should-be-selected-only-one-gender": "Should be selected only 1 gender",
  "auto-fill-my-address": "Auto-fill my address",
  "age-is-older-than": "Age is older than",
  "age-is-younger-than": "Age is younger than",
  "age-is": "Age is",
  "age-is-not": "Age is not",
  "gender-is-male": "Gender is male",
  "gender-is-female": "Gender is female",
  "zip-code-is-one-of": "ZIP code is one of",
  "zip-code-is-not-one-of": "ZIP code is not one of",
  "add-vaccination-info": "Add vaccination info",
  "email-subject": "Email subject*",
  "email-message": "Email message",
  "sms-message": "SMS message",
  "date-and-time": "Date and time",
  "autofill-info-not-available": "Info not available",
  "more-videos": "More videos",
  "marketing-or-non-marketing-bulk-text": "Choose 'Marketing' if your message contains promotional material aimed at selling or advertising health-related products and services. Otherwise, choose ‘Non-marketing’.",
  "marketing": "Marketing",
  "non-marketing": "Non-marketing",
  "message-characters-limit-160": "Message characters limit is 160!",
  "chat-message": "Chat message",
  "pharmacy-mgmt-system": "Pharmacy mgmt. system",
  "pharmacy-mgmt-system-tooltip": "Please contact customer support to update your pharmacy management system",
  "marketing-messaging-phone": "Marketing SMS",
  "non-marketing-messaging-phone": "Non-marketing SMS",
  "outbound-sms-cap":"Outbound SMS messages cap",
  "outbound-sms-used":"Outbound SMS messages used",
  "outbound-email-cap":"Outbound email messages cap",
  "outbound-email-used":"Outbound email messages used",
  "message-plans":"Message Plans",
  "internal-use":"Internal Use",
  "legal-entity-name-tooltip": "The legal business name the pharmacy operates under.",
  "time-overlapped": "Time overlapped!",
  "refill-reminder-error-modal-tooltip": "This description will be shown to the patient in the event that this error occurs.",
  "patient-name": "Patient name",
  "edit-app-details": "Edit App Details",
  "edit-website-details": "Edit Website Details",
  "rx-transfer-option": "Rx Transfer Option",
  "invalid-hex-length-use-6-digits": "Use 6 digits Hex value",
  "cannot-reuse-last-five-password": "You cannot reuse any of your last 5 passwords, please choose a new one",
  "total-overage-amount": "Total overage amount ($)",
  "payment-and-delivery-title":"Payment And Delivery",
  "payment-and-delivery-description":"Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  "notify-me":"Notify me",
  "requires-rebuilding-app": "Requires rebuilding app",
  "welcome-to-refills":"Welcome to Refills",
  "refill-subtitle":"All refills submitted through Lumistry will appear here.",
  "refills-bullet-point-title":"Ways to get patients to refill using Lumistry:",
  "tell-patients-about-app":"Tell your patients about your new mobile app",
  "send-email-patient-portal-account":"Send out email invites to create a patient portal account",
  "send-text-for-patients-download-app":"Send out text messages for patients to download your app",
  "announce-ivr-promoting-digital-solutions":"Add an announcement to your IVR greeting promoting your digital solutions",
  "unable-to-process-complete-required-fields": "Unable to process request, please complete the required fields",
  "find-first-name-last-name-email": "Find First name, Last name, Email",
  "jurisdiction-tooltip": "State or jurisdiction in which the company was formed or incorporated",
  "switch-to-this-location": "Switch to this location",
  "patient-portal-url": "Patient portal URL",
  "form-error-occurred":"A form error has occurred. Please correct the errors below and try again.",
  "messages-reload-application":"Unable to send message. Please reload the application.",
  "no-insurance-card": "No Insurance card",
  "reached-sending-limit":"You have reached the sending limit for Sms or Emails ",
  'edit-message-plans':'Edit Message Plans',
  "sms-email-cap-reached":"SMS and Email Message limit has exceeded. See ",
  "sms-cap-reached": "SMS Message limit has exceeded. See ",
  "email-cap-reached": "Email Message limit has exceeded. See ",
  "tax-ein-format-is-invalid": "Tax ID/EIN format is invalid",
  "email-password-incorrect-try-forgot-password": "Email address and/or password incorrect. Please try again or select “Forgot password?” to reset your password.",
  "signup-date": "Signup date",
  "billing-settings": "Billing Settings",
  "service-min-length-error-message": "Length must be at least 5 minutes",
  "one-pickup-method-must-be-enabled":"At least one pickup method must be enabled.",
  "qr-code": "QR code",
  "app-is-live": "App is live",
  "view-image": "View image",
  "at-least-one-recipient-is-required": "At least one recipient is required",
  "billable-limits":"Billable limits",
  "non-billable-usage":"Non-billable usage",
  "usage":"Usage",
  "review-message": "Review Message",
  "recipients-list": "Recipients List",
  "action": "Action",
  "you-have-no-bulk-message-recipients": "You have no recipients. Go back and edit the recipients list or cancel your message.",
  "one-or-more-recipients-have-been-removed": "1 or more recipients have been removed.",
  "no-name-data": "No name data",
  "no-data": "No data",
  "website-settings-url-tooltip": "Please contact customer support to update your URL",
  "date-is-required": "Date is required",
  "edit-billable-limits":"Edit Billable Limits",
  "general-error-message": "{{message}} something went wrong",
  "email-was-registered-successfully":"Email was registered successfully.",
  "email-is-already-registered":"Email is already registered.",
  "failed": "Failed",
  "apple-developer-account": "Apple developer account",
  "apple-developer-account-required": "Apple developer account required!",
  "apple-developer-account-tooltip": "Find it in your Apple Developer Account, this is the Legal Entity name to which this account corresponds",
  "prescription-entered-already-exist-contact-pharmacy":"The prescription number that you entered is already associated with an existing patient portal account. Please try another prescription or contact the pharmacy for further assistance.",
};

export default mapping;
