import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const CovidShotIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, colorSecondary, size, testID }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={colorSecondary || theme.colors.brandedText}
        d="M55.362 39.556 68 26.918l-2.864-.953-11.68 11.679-1.522-.385-2.384 2.385 3.817 3.817 2.384-2.384-.389-1.521ZM46.105 46.905l1.987 4.058-1.098 1.098-2.549-2.546-.953.953 2.546 2.547-1.269 1.27-1.27-1.27-.955.954 1.272 1.27-1.27 1.27-2.548-2.547-.954.954 2.547 2.546-1.269 1.269-1.269-1.269-.956.954 1.271 1.27-1.43 1.432-6.045-6.045L42.047 44.92l4.059 1.986ZM35.349 36.78a2.745 2.745 0 1 0-5.49 0 2.745 2.745 0 0 0 5.49 0Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        d="M32.603 30.375a6.405 6.405 0 0 1 6.142 8.233L41 36.403l6.48.54 1.038-1.077h-.099a15.77 15.77 0 0 0-3.804-9.414l8.597-8.303a2.747 2.747 0 0 0 3.977-2.456 2.747 2.747 0 1 0-5.244 1.144l-8.597 8.3a15.937 15.937 0 0 0-2.198-1.696l2.34-4.161a2.745 2.745 0 1 0-1.593-.896l-2.34 4.158a15.785 15.785 0 0 0-6.954-1.604c-.726 0-1.442.05-2.141.144l-1.034-5.278a3.663 3.663 0 0 0-1.631-6.94 3.661 3.661 0 0 0-.157 7.32l1.027 5.248a15.833 15.833 0 0 0-7.065 3.948L19.5 23.439a2.058 2.058 0 1 0-4.036-.58 2.058 2.058 0 0 0 2.798 1.923l2.101 1.942a15.796 15.796 0 0 0-3.155 6.305l-1.772-.326a2.748 2.748 0 0 0-5.46.43 2.747 2.747 0 0 0 5.13 1.365l1.777.326c-.081.64-.122 1.294-.122 1.957 0 2.739.695 5.314 1.917 7.56l-7.482 4.64a3.661 3.661 0 1 0 .962 1.548l7.484-4.64a15.848 15.848 0 0 0 6.278 5.26l8.37-8.184a6.408 6.408 0 0 1-8.094-6.184 6.406 6.406 0 0 1 6.408-6.406Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        fillRule="evenodd"
        d="M33.8 72.57a1.35 1.35 0 0 0 .147-1.736l19.962-19.96-.004-.005c.36-.36.499-.91.31-1.42l-1.793-4.804a1.351 1.351 0 0 0-.328-.54l-3.179-3.18a1.334 1.334 0 0 0-.483-.311l-4.76-1.775a1.352 1.352 0 0 0-1.535.263L22.175 59.064a1.348 1.348 0 0 0-1.734 2.054l4.771 4.771-5.727 5.727-2.387-2.386a1.35 1.35 0 1 0-1.907 1.91l6.68 6.68a1.352 1.352 0 0 0 1.91 0 1.352 1.352 0 0 0 0-1.91l-2.386-2.386 5.725-5.725 4.77 4.771a1.352 1.352 0 0 0 1.91 0Zm-6.68-8.588-3.021-3.024 19.339-19.337 3.777 1.409 2.769 2.771 1.406 3.772-19.34 19.34-3.02-3.024 1.75-1.75 2.067 2.07 3.817-3.818-6.044-6.044-3.818 3.817 2.068 2.067-1.75 1.75Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
