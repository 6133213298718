import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { CheckIcon } from '../../icons/CheckIcon';
import { makeStyles, useTheme } from '../../theme';
import { Icon } from '../icon/Icon';
import { Text } from '../text';

export const WizardProgress: FunctionComponent<WizardProgressProps> = (
  props = { type: 'withoutTitle', currentStep: 0, numberOfSteps: 1 },
) => {
  return props.type === 'withoutTitle' ? (
    <WizardStepsNumber {...props} />
  ) : props.type === 'withTitle' ? (
    <WizardStepsText {...props} />
  ) : null;
};

const WizardStepsNumber: FunctionComponent<WizardStepsNumberProps> = (
  props = { type: 'withoutTitle', currentStep: 0, numberOfSteps: 1 },
) => {
  return (
    <BaseWizardStep
      numberOfSteps={props.numberOfSteps}
      currentStep={props.currentStep}
    />
  );
};

const WizardStepsText: FunctionComponent<WizardStepsTextProps> = (
  props = { type: 'withTitle', currentStep: 0, steps: ['NO Steps configured'] },
) => {
  return (
    <BaseWizardStep
      numberOfSteps={props.steps.length}
      currentStep={props.currentStep}
      steps={props.steps}
    />
  );
};

const BaseWizardStep: FunctionComponent<BaseWizardStepProps> = (
  props = { currentStep: 0, numberOfSteps: 1 },
) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.root}>
      {new Array(props.numberOfSteps).fill(0).map((x, index) => (
        <View style={styles.stepContainer} key={index}>
          <View style={styles.item}>
            <View style={[styles.line, index !== 0 && styles.lineColor]}></View>
            <View style={[getShadowStyle(index, props.currentStep)]}>
              <View
                style={[
                  styles.marker,
                  getMarkerProgressStyle(index, props.currentStep),
                ]}
              >
                {props.currentStep <= index + 1 && (
                  <View style={[styles.dot]}></View>
                )}
                {props.currentStep > index + 1 && (
                  <View style={[styles.icon]}>
                    <Icon icon={CheckIcon} color={theme.palette.white} />
                  </View>
                )}
              </View>
            </View>

            <View
              style={[
                styles.line,
                index < props.numberOfSteps - 1 && styles.lineColor,
              ]}
            ></View>
          </View>
          {props.steps && (
            <View style={styles.textContainer}>
              <Text
                numberOfLines={1}
                style={[
                  index === props.currentStep - 1 && styles.textCurrentStep,
                  index > props.currentStep - 1 && styles.textUpcomingStep,
                ]}
              >
                {props.steps[index]}
              </Text>
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const getShadowStyle = (index: number, currentStep: number) => {
  const styles = useStyles();
  if (index + 1 == currentStep) {
    return styles.shadow;
  } else return styles.noShadow;
};

const getMarkerProgressStyle = (index: number, currentStep: number) => {
  const styles = useStyles();
  if (currentStep > index) {
    return index + 1 === currentStep
      ? styles.currentMarker
      : styles.markerComplete;
  }
  return styles.markerIncomplete;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  stepContainer: { flex: 1 },
  marker: {
    width: 16,
    height: 16,
    borderRadius: 8,
  },
  markerComplete: {
    backgroundColor: theme.palette.success[600],
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  currentMarker: {
    backgroundColor: theme.palette.primary[600],
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  markerIncomplete: {
    backgroundColor: theme.palette.gray[300],
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  lineColor: {
    backgroundColor: theme.palette.gray[300],
  },
  line: {
    height: 1,
    flex: 1,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  dot: {
    backgroundColor: theme.palette.white,
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 12,
  },
  icon: {
    marginLeft: 4,
    marginTop: 4,
  },
  shadow: {
    backgroundColor: theme.palette.primary[100],
    width: 40,
    height: 40,
    borderRadius: 20,
    padding: 4,
  },
  noShadow: {
    backgroundColor: 'transparent',
  },
  textContainer: { alignItems: 'center' },
  textCurrentStep: {
    color: theme.palette.primary[500],
  },
  textUpcomingStep: {
    color: theme.palette.gray[300],
  },
}));

export type WizardProgressProps = WizardStepsNumberProps | WizardStepsTextProps;

type WizardStepsNumberProps = {
  type?: 'withoutTitle';
  currentStep: number;
  numberOfSteps: number;
};

type WizardStepsTextProps = {
  type?: 'withTitle';
  currentStep: number;
  steps: Array<string>;
};

type BaseWizardStepProps = {
  currentStep: number;
  numberOfSteps: number;
  steps?: Array<string>;
};
