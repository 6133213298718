import { FunctionComponent, useState } from 'react';
import { Animated, Platform, TouchableOpacity, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { CircleBulletIcon, GeneralMessageCircleIcon } from 'assets/icons';
import ParsedText from 'react-native-parsed-text';
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
} from '@react-navigation/native';
import { useUserState } from '../../store/user-store';
import { Icon } from 'assets/components/icon';
import { SubjectOptions } from './MessageProps';
import { RectButton } from 'react-native-gesture-handler';
import { getText } from 'assets/localization/localization';
import UnifiedCommsService from '../../api/unified-comms-service';
import { useMessagesState } from './messages-store';
import { DateTimeFormat, formatISODateAsString } from '../../common/form-utils';
import {
  renderHyperlink,
  renderHyperlinkRegex,
} from 'assets/utils/messageUtils';
import { toIsoString } from '../../common/datetime-utils';
import { ConversationItemWrapper } from './ConversationItemWrapper';
import { ampli } from '../../ampli';

export const ConversationItem: FunctionComponent<ConversationItemProps> = ({
  conversation,
  isViewed,
}) => {
  const rowRefs = new Map();
  const styles = useStyles();
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const user = useUserState((state) => state.user);
  const [isSwiped, setIsSwiped] = useState<boolean>(false);
  const viewedConversationsSet = useMessagesState(
    (state) => state.viewedConversationsSet,
  );
  const locationId = user?.preferredPharmacyLocationId;

  const textColor = () => {
    return isViewed ? styles.readMessage : styles.unreadMessage;
  };

  const getIcon = () => {
    // eslint is wrong about this. It is possible for this to be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (SubjectOptions[conversation.subject]) {
      return SubjectOptions[conversation.subject];
    } else {
      return GeneralMessageCircleIcon;
    }
  };

  const markAsUnread = async () => {
    if (locationId) {
      await UnifiedCommsService.updateUserViewedStatus(
        locationId,
        conversation.location_patient_id,
        conversation.conversation_id,
        {
          patient_viewed_all_messages: false,
          pharmacy_viewed_all_messages:
            conversation.pharmacy_viewed_all_messages,
        },
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      rowRefs.get(conversation.conversation_id)?.close();
    }
  };

  // Not sure what types these properties are supposed to be. Googled it, couldn't find anything.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderRightActions = (progress: unknown, dragX: any) => {
    if (viewedConversationsSet.has(conversation.conversation_id)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const trans = dragX.interpolate({
        inputRange: [-10, 50],
        outputRange: [25, 57],
      });
      return (
        <RectButton onPress={markAsUnread}>
          <Animated.Text
            style={[
              styles.markUnread,
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                transform: [{ translateX: trans }],
              },
            ]}
          >
            {getText('mark-unread')}
          </Animated.Text>
        </RectButton>
      );
    }
  };

  const onPress = () => {
    if (!isSwiped) {
      const params = {
        conversationId: conversation.conversation_id,
        locationPatientRecordId: conversation.location_patient_id,
        locationId: locationId ?? '',
        patientViewedAllMessages: viewedConversationsSet.has(
          conversation.conversation_id,
        ),
        pharmacyViewedAllMessages: conversation.pharmacy_viewed_all_messages,
        title:
          conversation.subject +
          ' - ' +
          (conversation.first_name?.pharmacy ?? 'no_first_name_pharmacy') + // The data is from ElasticSearch and could be in a bad state if repopulation were not run
          ' ' +
          (conversation.last_name?.pharmacy ?? 'no_last_name_pharmacy'), // The data is from ElasticSearch and could be in a bad state if repopulation were not run
      };

      ampli.messageRead({
        conversationID: conversation.conversation_id,
        messageID: '',
        messageReadTime: new Date().toISOString(),
        messageSubject: conversation.subject,
      });

      const screenName =
        Platform.OS === 'web' ? 'conversation-box' : 'conversation';
      const navigationParams =
        Platform.OS === 'web' ? params : { screen: 'conversation-box', params };
      navigation.navigate(screenName, navigationParams);
    }
  };

  const onSwipeableWillOpen = () => {
    if (!viewedConversationsSet.has(conversation.conversation_id)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      rowRefs.get(conversation.conversation_id).close();
    }
    setIsSwiped(true);
  };

  const onSwipeableClose = () => {
    setIsSwiped(false);
  };

  const messageTimeInLocalTime = toIsoString(
    new Date(conversation.most_recent_qualifying_message_date).toString(),
  );

  return (
    <ConversationItemWrapper
      conversation={conversation}
      rowRefs={rowRefs}
      viewedConversationsSet={viewedConversationsSet}
      renderRightActions={renderRightActions}
      onSwipeableWillOpen={onSwipeableWillOpen}
      onSwipeableClose={onSwipeableClose}
      markAsUnread={markAsUnread}
    >
      <TouchableOpacity style={[styles.container]} onPress={onPress}>
        <View style={[styles.circles, isViewed && styles.hideColumn]}>
          <CircleBulletIcon size={15} color={theme.palette.primary[600]} />
        </View>
        <View style={styles.circles}>
          <Icon icon={getIcon()} size={32} color={theme.palette.gray[400]} />
        </View>
        <View style={styles.rightBlock}>
          <View style={styles.topRow}>
            <Text style={[styles.subject, textColor()]}>
              {conversation.subject}
            </Text>
            <Text style={[styles.date, textColor()]}>
              {formatISODateAsString(
                messageTimeInLocalTime,
                DateTimeFormat.USDateFormat,
              )}
            </Text>
          </View>
          <Text numberOfLines={1} style={[textColor(), styles.message]}>
            <ParsedText
              maxFontSizeMultiplier={1.5}
              minimumFontScale={0.8}
              parse={[
                {
                  pattern: renderHyperlinkRegex,
                  renderText: renderHyperlink,
                },
              ]}
            >
              {conversation.most_recent_qualifying_message}
            </ParsedText>
          </Text>
        </View>
      </TouchableOpacity>
    </ConversationItemWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
    width: '82%',
  },

  hideColumn: {
    opacity: 0,
  },

  topRow: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  unreadMessage: {
    color: theme.palette.gray[900],
    fontWeight: 'bold',
    fontFamily: 'Lato_700Bold',
  },

  readMessage: {
    color: theme.palette.gray[700],
  },

  message: {
    fontSize: 12,
  },

  circles: {
    alignSelf: 'center',
    paddingRight: theme.getSpacing(1),
  },

  date: {
    width: '50%',
    textAlign: 'right',
    fontSize: 12,
  },

  subject: {
    width: '50%',
    fontSize: 14,
    paddingBottom: theme.getSpacing(1),
  },

  rightBlock: {
    width: '100%',
  },

  markUnread: {
    marginLeft: theme.getSpacing(2),
    marginBottom: theme.getSpacing(0.5),
    backgroundColor: '#EDAB00',
    width: 57,
    fontSize: 12,
    height: '100%',
    textAlign: 'center',
    flex: 1,
    color: theme.palette.white,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    textAlignVertical: 'center',
    alignContent: 'center',
    paddingTop:
      Platform.OS === 'ios' ? theme.getSpacing(1) + theme.getSpacing(0.5) : 0,
  },
}));

interface ConversationItemProps {
  conversation: DirectMessagePatientDto;
  isViewed: boolean;
  isSelected?: boolean;
}
