import { FunctionComponent, PropsWithChildren, useState } from 'react';
import theme, { makeStyles } from 'assets/theme';
import { Form, InternalScreenContainer } from 'assets/layout';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { getText } from 'assets/localization/localization';
import { TransferMedsTestIDs } from './TransferMedsTestIDs';
import { Divider } from 'react-native-paper';
import {
  resetTransferMedsState,
  useTransferMedicationsStore,
} from './transfer-meds-store';
import { CheckboxInput } from 'assets/components/checkbox';
import { useForm } from 'react-hook-form';
import { useUserState } from '../../store/user-store';
import { StackHeaderProps } from '@react-navigation/stack';
import PhoneNumber from '../../components/phone-number/PhoneNumber';
import { SafeAreaView } from 'react-native';

export const TransferMedsReview: FunctionComponent<
  PropsWithChildren<TransferMedicationsNavigationProp>
> = ({ navigation }) => {
  const styles = useStyles();
  const { user } = useUserState();
  const [fillPrescriptions, setFillPrescriptions] = useState<boolean>(true);

  const {
    locationPatientRecord: patientRecord,
    pucFullName,
    originated,
    medications,
    allMedications,
    pharmacyName,
    pharmacyPhoneNumber,
    updateTransferMedsData,
    submitTransferPrescriptions,
  } = useTransferMedicationsStore();

  const methods = useForm({
    defaultValues: {
      fill: true,
    },
  });

  const handleFillPrescription = (
    checked: boolean | ((prevState: boolean) => boolean),
  ) => {
    setFillPrescriptions(checked);
  };

  const handleOnPressSubmit = () => {
    if (!updateTransferMedsData || !submitTransferPrescriptions) return;
    updateTransferMedsData({
      fillPrescription: fillPrescriptions,
    });

    void submitTransferPrescriptions();

    navigation.navigate('transfer-medications', { screen: 'success-page' });
  };

  const handleOnPressStartOver = () => {
    if (originated === 'onboarding') {
      resetTransferMedsState();
      useTransferMedicationsStore.setState({
        originated: 'onboarding',
        allMedications: false,
        transferLocation: undefined,
        medications: [],
        fillPrescription: true,
        pharmacyName: '',
        pharmacyPhoneNumber: '',
      });
      navigation.navigate('transfer-medications', {
        screen: 'single-location',
      });
    } else {
      resetTransferMedsState();
      useTransferMedicationsStore.setState({
        originated: 'medication',
        allMedications: false,
        transferLocation: undefined,
        medications: [],
        fillPrescription: true,
        pharmacyName: '',
        pharmacyPhoneNumber: '',
      });
      navigation.navigate('transfer-medications', {
        screen: 'multiple-locations',
      });
    }
  };

  return (
    <View style={styles.container}>
      <InternalScreenContainer
        title={getText('transfer-meds-header-title')}
        buttons={[
          {
            hierarchy: 'tertiary',
            onPress: handleOnPressStartOver,
            testID: TransferMedsTestIDs.startOverButton,
            logger: { id: TransferMedsTestIDs.startOverButton },
            text: getText('start-over'),
          },
          {
            hierarchy: 'primary',
            onPress: handleOnPressSubmit,
            testID: TransferMedsTestIDs.submitButton,
            logger: { id: TransferMedsTestIDs.submitButton },
            text: getText('submit'),
          },
        ]}
        scrollToTop={!!methods.getFieldState('fill').error}
      >
        <Form methods={methods}>
          <Form.Alert
            visible={!!methods.getFieldState('fill').error}
            title={getText('unable-to-process-complete-required-fields')}
            intent="error"
          />
          <Form.Row>
            <Form.Column>
              <View style={styles.rowSpaceBetween}>
                <View>
                  <Text style={styles.title}>{getText('person')}</Text>
                </View>
              </View>
            </Form.Column>
          </Form.Row>
          <View>
            <Divider />
          </View>
          <Form.Row>
            <Form.Column>
              <Text style={styles.data}>
                {user?.patientRecordId === patientRecord?.patient_record_id
                  ? getText('myself')
                  : originated === 'onboarding'
                  ? getText('myself')
                  : patientRecord?.first_name && patientRecord.last_name
                  ? `${patientRecord.first_name} ${patientRecord.last_name}`
                  : pucFullName}
              </Text>
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column>
              <View style={styles.rowSpaceBetween}>
                <View>
                  <Text style={styles.title}>{getText('medications')}</Text>
                </View>
              </View>
            </Form.Column>
          </Form.Row>
          <View>
            <Divider />
          </View>
          <Form.Row>
            <Form.Column>
              {allMedications ? (
                <Text style={styles.data}>{getText('all-medications')}</Text>
              ) : (
                medications &&
                medications.map((item, index) => (
                  <Text style={styles.data} key={index}>
                    {item.drug_name}
                  </Text>
                ))
              )}
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <View style={styles.rowSpaceBetween}>
                <View>
                  <Text style={styles.title}>{getText('pharmacy')}</Text>
                </View>
              </View>
            </Form.Column>
          </Form.Row>
          <View>
            <Divider />
          </View>
          <Form.Row>
            <Form.Column>
              <Text style={styles.data}>{pharmacyName}</Text>
              <View style={styles.rowView}>
                <Text style={styles.data}>
                  <PhoneNumber
                    mode="link"
                    phoneNumber={pharmacyPhoneNumber!}
                    showIcon={false}
                  />
                </Text>
              </View>
            </Form.Column>
          </Form.Row>
        </Form>
        <View style={styles.buttonContainer}>
          <View style={styles.rowView}>
            <View style={styles.checkbox}>
              <CheckboxInput
                checked={fillPrescriptions}
                onPress={(checked) => handleFillPrescription(checked)}
                testID={TransferMedsTestIDs.checkbox}
              />
            </View>
            <View>
              <Text style={styles.checkboxText}>
                {getText('fill-prescriptions-after-transferring')}
              </Text>
            </View>
          </View>
        </View>
      </InternalScreenContainer>
    </View>
  );
};

export type TransferMedicationsNavigationProp = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
  changeText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
    flex: 1,
    flexDirection: 'column-reverse',
  },
  data: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(0.5),
  },
  buttonContainer: {
    flex: 100,
    justifyContent: 'flex-end',
    width: '100%',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  checkboxText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  checkbox: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginVertical: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(2),
    marginTop: theme.getSpacing(1),
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
}));
