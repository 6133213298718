/* eslint-disable i18next/no-literal-string */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from 'assets/theme';
import { View, Image } from 'react-native';
import { Divider } from 'react-native-paper';
import * as Linking from 'expo-linking';
import { Text } from 'assets/components/text';

export const PharmacyNotFound: FunctionComponent = () => {
  const styles = useStyles();
  const [currentUrlPath, setCurrentUrlPath] = useState<string | null>('');

  useEffect(() => {
    void (async () => {
      const { path } = await Linking.parseInitialURLAsync();
      setCurrentUrlPath(path);
    })();
  }, []);

  return (
    <View style={styles.root}>
      <View style={styles.subRoot}>
        <View style={styles.logoContainer}>
          <Image
            resizeMode="contain"
            source={require('../../../assets/icon.png')}
            style={styles.logo}
          />
        </View>
        <Divider style={styles.divider} />
        <View style={styles.mainTextContainer}>
          <Text style={styles.mainTextStyle}>404. </Text>
          <Text style={styles.secondaryTextStyle}>Page not found.</Text>
        </View>
        <View style={styles.mainTextContainer}>
          <Text style={styles.secondaryTextStyle}>
            The requested URL path
            <Text style={styles.mainTextStyle}> /{currentUrlPath}</Text> was not
            found on this server
          </Text>
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.white,
    alignItems: 'center',
  },
  subRoot: {
    marginLeft: theme.getSpacing(2),
    marginRight: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(1),
    maxWidth: 400,
  },
  logoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    resizeMode: 'contain',
    width: 200,
    height: 200,
  },
  divider: {
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  mainTextContainer: {
    marginTop: theme.getSpacing(3),
    alignItems: 'center',
    flexDirection: 'row',
  },
  mainTextStyle: {
    ...theme.lumistryFonts.label.xLarge.semiBold,
    color: theme.palette.gray[900],
  },
  secondaryTextStyle: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.gray[700],
  },
}));
