import React, { PropsWithChildren, useRef } from 'react';
import * as Linking from 'expo-linking';
import { AppNavigationConfig, AppNavigationProviderProp } from '../types';
import { AppNavigationContext, NavigationContainerRef } from '../context';
import {
  LinkingOptions,
  NavigationContainer as ReactNavigationNavigationContainer,
} from '@react-navigation/native';
import { setCurrentScreen } from 'assets/logging/logger';
import { navigationTheme } from '../config';

export const AppNavigationProvider: React.FunctionComponent<
  PropsWithChildren<AppNavigationProviderProp>
> = ({ children, appNavigationConfig }) => {
  const { prefix, root } = appNavigationConfig;
  const routeNameRef = useRef<string>();
  const linking: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: [Linking.createURL('/')],
    config: {
      screens: {
        [root]: '/:prefix/*',
      },
    },
    getStateFromPath: (initialPath: string) => {
      const { path, queryParams } = Linking.parse(initialPath);

      const routes = path?.split('/');
      const prefixRoute = routes?.shift() ?? '/'; // first part of the route

      const params: Record<string, any> =
        routes?.reduceRight(
          // create nested params recursively
          (acc, curr, index) => ({
            screen: curr,
            params: index === routes.length - 1 ? queryParams : acc,
          }),
          {},
        ) || {};

      // set the slug params value on the root route state
      params['prefix'] = prefixRoute || prefix;

      return {
        routes: [
          {
            name: root,
            params,
          },
        ],
      };
    },
  };

  const [currentAppNavigation, setCurrentAppNavigation] =
    React.useState<AppNavigationConfig>(appNavigationConfig);

  const setAppNavigationConfig = (override: Partial<AppNavigationConfig>) => {
    setCurrentAppNavigation((curr) => ({
      ...curr,
      ...override,
    }));
  };

  return (
    <AppNavigationContext.Provider
      value={{
        appNavigationConfig: currentAppNavigation,
        setAppNavigationConfig: setAppNavigationConfig,
      }}
    >
      <ReactNavigationNavigationContainer
        linking={linking}
        ref={NavigationContainerRef}
        theme={navigationTheme}
        onReady={() => {
          routeNameRef.current = NavigationContainerRef.getCurrentRoute()?.name;
        }}
        documentTitle={{
          formatter: appNavigationConfig.titleFormatter,
        }}
        // commenting initial state because seems to be handled automatically from 'getStateFromPath'
        // letting this note - TODO remove this after redirection / deep linking / universal links passes the tests
        // initialState={{
        //   routes: [
        //     {
        //       name: root,
        //       params: {
        //         prefix,
        //       },
        //     },
        //   ],
        // }}
        onStateChange={async (state) => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName =
            NavigationContainerRef.getCurrentRoute()?.name;

          if (previousRouteName !== currentRouteName) {
            await setCurrentScreen(currentRouteName);
          }

          routeNameRef.current = currentRouteName;
        }}
      >
        {children}
      </ReactNavigationNavigationContainer>
    </AppNavigationContext.Provider>
  );
};
