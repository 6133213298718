import { OrderStatus } from '@digitalpharmacist/order-service-client-axios';
import {
  IntegrationType,
  PrescriptionDto,
  RxStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import { Button } from 'assets/components/button';
import { CheckboxInput } from 'assets/components/checkbox';
import { IconButton } from 'assets/components/icon-button';
import { Icon } from 'assets/components/icon/Icon';
import { Text } from 'assets/components/text/Text';
import {
  AlertCircleIcon,
  AlertTriangleIcon,
  CalendarIcon,
  InfoIcon,
  RefreshSquareIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import moment from 'moment';
import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { View } from 'react-native';
import { formatDate, formatDateFromApi } from '../../common/datetime-utils';
import { RefillTestIDs } from '../../screens/refill/RefillTestIDs';
import { useAppStateStore } from '../../store/app-store';
import { OrderStatusBadge } from './OrderStatusBadge';
import { PrescriptionCardTestIDs } from './PrescriptionCardTestID';

export const PrescriptionCard: FunctionComponent<
  PropsWithChildren<PrescriptionCardProps>
> = ({
  prescription,
  orderStatus,
  showOrderStatus,
  selectable = false,
  pucStyle = false,
  showRefillStatus = false,
  showRxNumber = false,
  showPatientInfo = false,
  showStatus = false,
  showDetailsIcon = false,
  checked = false,
  removable = false,
  onPress = () => undefined,
  onRemove = () => undefined,
  onOpenDetailsPress = () => undefined,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const locationPrescriptionConfig = useAppStateStore(
    (s) => s.locationPrescriptionConfig,
  );

  const handleOnPress = (checked: boolean) => {
    onPress(prescription, checked);
  };

  const isFillableRxStatus = (rxStatus: RxStatus): boolean => {
    return rxStatus === RxStatus.Fillable;
  };

  const getRxStatusMessage = (rxStatus: RxStatus): string => {
    const rxConfig = locationPrescriptionConfig.refill_configs.find(
      (config) => config.rx_status === rxStatus,
    );

    if (rxConfig) return rxConfig.message_patient;

    return 'Info not available';
  };

  const getNextRefillObject = () => {
    if (!prescription.next_refill_date) return undefined;

    const nextRefillDate = moment
      .utc(prescription.next_refill_date)
      .startOf('day');
    const dateText = formatDate(prescription.next_refill_date);
    const now = moment.utc().startOf('day'); // Current UTC day

    if (prescription.auto_fill_enrolled) {
      return {
        label: getText('auto-refill'),
        color: theme.palette.success[500],
        iconColor: theme.palette.success[500],
        icon: RefreshSquareIcon,
        dateText,
      };
    }

    if (nextRefillDate.isBefore(now)) {
      return {
        label: getText('refill-past-due'),
        color: theme.palette.warning[500],
        iconColor: theme.palette.warning[500],
        icon: AlertCircleIcon,
        dateText,
      };
    }

    return {
      label: getText('refill-due'),
      color: theme.palette.gray[700],
      iconColor: theme.palette.gray[500],
      icon: RefreshSquareIcon,
      dateText,
    };
  };

  const getLastRefillObject = () => {
    if (prescription.last_refill_date) {
      const dateText = formatDate(prescription.last_refill_date);

      return {
        label: getText('prescription-card-last-filled'),
        color: theme.palette.gray[500],
        iconColor: theme.palette.gray[400],
        icon: CalendarIcon,
        dateText,
      };
    }

    if (prescription.last_order_date) {
      const dateText = formatDate(prescription.last_order_date);

      return {
        label: getText('prescription-card-last-ordered'),
        color: theme.palette.gray[500],
        iconColor: theme.palette.gray[400],
        icon: CalendarIcon,
        dateText,
      };
    }

    return undefined;
  };

  const nextRefillObject = useMemo(
    () => getNextRefillObject(),
    [prescription.next_refill_date, prescription.auto_fill_enrolled],
  );

  const lastRefillObject = useMemo(
    () => getLastRefillObject(),
    [prescription.last_refill_date, prescription.last_order_date],
  );

  const dateOfBirth = useMemo(
    () => formatDateFromApi(prescription.patient_date_of_birth),
    [prescription.patient_date_of_birth],
  );

  const rxStatusMessage = useMemo(
    () => prescription.rx_status && getRxStatusMessage(prescription.rx_status),
    [locationPrescriptionConfig, prescription.rx_status],
  );

  return (
    <View style={{ marginBottom: theme.getSpacing(1) }}>
      <View style={styles.rowSpaceBetween}>
        {selectable && (
          <View style={styles.checkbox}>
            <CheckboxInput
              checked={checked}
              onPress={handleOnPress}
              testID={PrescriptionCardTestIDs.checkbox}
            />
          </View>
        )}
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View style={{ width: '100%' }}>
            <View style={styles.rowView}>
              <Text style={pucStyle ? styles.pucTextTitle : styles.textTitle}>
                {prescription.drug_name}
                {showDetailsIcon && (
                  <IconButton
                    icon={InfoIcon}
                    color={theme.palette.gray[500]}
                    onPress={onOpenDetailsPress}
                    logger={{ id: 'med-details-icon-button' }}
                    size={19}
                    style={styles.infoIcon}
                    testID="med-details-icon-button"
                  />
                )}
              </Text>
            </View>
            {prescription.integration_type === IntegrationType.Manual && (
              <Text style={styles.textDetails}>
                {getText('entered-prescription-manually')}
              </Text>
            )}
            {showRxNumber && (
              <Text style={styles.textDetails}>
                Rx: {prescription.rx_number}
              </Text>
            )}
            {showRefillStatus && (
              <>
                {nextRefillObject && (
                  <View style={styles.rowView}>
                    <Icon
                      icon={nextRefillObject.icon}
                      color={nextRefillObject.iconColor}
                      size={14}
                    />
                    <Text
                      style={{
                        ...styles.textDetails,
                        color: nextRefillObject.color,
                      }}
                    >
                      {nextRefillObject.label} {nextRefillObject.dateText}
                    </Text>
                  </View>
                )}
                {lastRefillObject && (
                  <View style={styles.rowView}>
                    <Icon
                      icon={lastRefillObject.icon}
                      color={lastRefillObject.iconColor}
                      size={14}
                    />
                    <Text style={styles.textDetails}>
                      {lastRefillObject.label} {lastRefillObject.dateText}
                    </Text>
                  </View>
                )}
              </>
            )}
            {showPatientInfo && (
              <Text style={styles.textDetails}>
                {prescription.patient_first_name}{' '}
                {prescription.patient_last_name} ({dateOfBirth})
              </Text>
            )}
            {(showOrderStatus || orderStatus) && (
              <View style={styles.orderStatusTag}>
                <OrderStatusBadge orderStatus={orderStatus} />
              </View>
            )}
          </View>
        </View>
        {removable && (
          <View>
            <Button
              hierarchy="tertiary"
              onPress={onRemove}
              testID={RefillTestIDs.removePrescriptionButton}
              logger={{ id: RefillTestIDs.removePrescriptionButton }}
            >
              {getText('remove')}
            </Button>
          </View>
        )}
      </View>
      {showStatus &&
        prescription.rx_status &&
        !isFillableRxStatus(prescription.rx_status) && (
          <View style={styles.rowView}>
            <Icon
              icon={AlertTriangleIcon}
              color={theme.palette.error[600]}
              size={14}
            />
            <Text style={styles.textDetailsError}>{rxStatusMessage}</Text>
          </View>
        )}
    </View>
  );
};

export interface PrescriptionCardProps {
  prescription: PrescriptionDto;
  orderStatus?: OrderStatus;
  showOrderStatus?: boolean;
  selectable?: boolean;
  showRefillStatus?: boolean;
  pucStyle?: boolean;
  showStatus?: boolean;
  showDetailsIcon?: boolean;
  showRxNumber?: boolean;
  showPatientInfo?: boolean;
  checked?: boolean;
  removable?: boolean;
  onPress?: (medication: PrescriptionDto, checked: boolean) => void;
  onRemove?: () => void;
  onOpenDetailsPress?: () => void;
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],

    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  pucTextTitle: {
    ...theme.fonts.medium,
    fontWeight: '600',
    fontSize: 14,
    color: theme.palette.gray[900],
    flexShrink: 1,
    flex: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  textDetails: {
    marginTop: 2,
    marginLeft: 2,
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[700],
  },
  textDetailsError: {
    marginTop: 2,
    marginLeft: 2,
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    height: 20,
    color: theme.palette.error[600],
  },
  rowView: {
    marginTop: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  checkbox: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginVertical: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
  },
  orderStatusTag: {
    marginTop: theme.getSpacing(0.5),
  },
  infoIcon: {
    height: 21,
    margin: 0,
    paddingTop: theme.getSpacing(0.5),
  },
}));
