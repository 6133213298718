import React, { PropsWithChildren, useEffect, useState } from 'react';
import * as Linking from 'expo-linking';
import { AppRedirectionConfig, AppRedirectionProviderProp } from '../types';
import { AppRedirectionContext } from '../context';
import { Platform } from 'react-native';
import { ParamListBase } from '@react-navigation/native';
import { useCurrentUrl } from 'assets/hooks';
import { StackNavigationProp } from '@react-navigation/stack';
import { DefaultAppRedirectionConfig } from '../config';
import { useAppNavigation } from '../../app-navigation';

export const AppRedirectionProvider: React.FunctionComponent<
  PropsWithChildren<AppRedirectionProviderProp>
> = ({
  children,
  appRedirectionConfig,
  returnUrlKey = 'returnUrl',
  isAuthorized,
  prefixKey,
  rootPath,
}) => {
  const { url: currentUrl, setUrl: setCurrentUrl } = useCurrentUrl();
  const [redirectUrl, setRedirectUrl] = useState('');
  const navigation = useAppNavigation<StackNavigationProp<ParamListBase>>();

  const [currentAppRedirection, setCurrentAppRedirection] =
    React.useState<AppRedirectionConfig>(
      appRedirectionConfig || DefaultAppRedirectionConfig,
    );

  const setAppRedirectionConfig = (override: Partial<AppRedirectionConfig>) => {
    setCurrentAppRedirection((curr) => ({
      ...curr,
      ...override,
    }));
  };

  const navigateTo = (path: string, queryParams: Record<string, string>) => {
    // considering that each route will be separated by '/'
    // we should never use that character as the name of a screen as
    // <Stack.Screen name="profile/settings" component={} /> as this will be treated as 2 routes
    // instead of the slash we should use dash '-' (as it is implemented till now)
    const routes = path.split('/');
    const prefix = routes.shift() ?? '/'; // first part of the route

    const params: Record<string, any> =
      routes.length > 0
        ? routes.reduceRight(
            // create nested params recursively
            (acc, curr, index) => ({
              screen: curr,
              params: index === routes.length - 1 ? queryParams : acc,
            }),
            {},
          )
        : // otherwise returns actual query parameters
          queryParams;

    // set the slug params value on the root route state
    params[prefixKey] = prefix;

    navigation?.navigate(rootPath, params);
  };

  const handleAuthorizationChange = (initialUrl: string) => {
    const { path, queryParams } = Linking.parse(initialUrl);
    if (!path) return;

    if (!isAuthorized && currentAppRedirection.arePublicRoutesLoaded) {
      if (!queryParams?.[returnUrlKey]) {
        const params: any = {
          // take current return key otherwise take url as return key
          [returnUrlKey]:
            queryParams?.[returnUrlKey] ??
            [
              path,
              queryParams &&
                new URLSearchParams(
                  queryParams as Record<string, string>,
                ).toString(),
            ]
              .filter((x) => x)
              .join('?'),
        };
        if (Platform.OS === 'web') navigation?.setParams(params);
        else setRedirectUrl(params?.[returnUrlKey]);
      }
    }

    if (isAuthorized && currentAppRedirection.arePrivateRoutesLoaded) {
      // if the url contains the return key on query params then redirect to that url
      if (queryParams?.[returnUrlKey]) {
        const [returnUrlPath, returnUrlQueryParams] = (
          queryParams[returnUrlKey] as string
        ).split('?');

        navigateTo(
          returnUrlPath,
          Object.fromEntries(new URLSearchParams(returnUrlQueryParams)),
        );
      } else if (redirectUrl) {
        const [returnUrlPath, returnUrlQueryParams] = redirectUrl.split('?');

        navigateTo(
          returnUrlPath,
          Object.fromEntries(new URLSearchParams(returnUrlQueryParams)),
        );
        setRedirectUrl('');
        setCurrentUrl('');
      }
    }
  };

  const handleUniversalLink = () => {
    // in case of universal links the current url is changing only once when the user clicks on a link
    // after that it is changing only the navigation state
    const { path, queryParams } = Linking.parse(currentUrl);
    if (!path) return;
    navigateTo(path, queryParams as Record<string, string>);
    if (isAuthorized) setCurrentUrl(''); // in order to clean the state after authorized redirect
  };

  // subscribe to url changes (universal links case)
  useEffect(() => {
    if (!currentAppRedirection.handleAutomaticRedirection) return;
    if (Platform.OS === 'web') return; // mobile only
    if (!currentUrl) return;
    // We want to ignore app links, only handle http(s) universal links
    if (!currentUrl.includes('http')) return;

    void handleUniversalLink();
  }, [currentUrl, navigation]);

  // subscribe to authorization state changes
  useEffect(() => {
    if (!currentUrl) return;
    if (!currentAppRedirection.handleAutomaticRedirection) return;

    void handleAuthorizationChange(currentUrl);
  }, [
    isAuthorized,
    currentAppRedirection.handleAutomaticRedirection,
    currentAppRedirection.arePrivateRoutesLoaded,
    currentAppRedirection.arePublicRoutesLoaded,
  ]);

  return (
    <AppRedirectionContext.Provider
      value={{
        appRedirectionConfig: currentAppRedirection,
        setAppRedirectionConfig: setAppRedirectionConfig,
      }}
    >
      {children}
    </AppRedirectionContext.Provider>
  );
};
