import React from 'react';
import { AppRedirectionConfig } from '../types';
import { DefaultAppRedirectionConfig } from '../config';

export type AppRedirectionContextType = {
  appRedirectionConfig: AppRedirectionConfig;
  setAppRedirectionConfig: (override: Partial<AppRedirectionConfig>) => void;
};

export const AppRedirectionContext =
  React.createContext<AppRedirectionContextType>({
    appRedirectionConfig: DefaultAppRedirectionConfig,
    setAppRedirectionConfig: () => {},
  });

export const AppRedirectionConsumer = AppRedirectionContext.Consumer;
