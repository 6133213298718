import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const MicIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 14 20"
      fill="none"
      testID={testID}
    >
      <Path
        d="M12.8337 8.33301V9.99967C12.8337 11.5468 12.2191 13.0305 11.1251 14.1245C10.0312 15.2184 8.54742 15.833 7.00033 15.833M7.00033 15.833C5.45323 15.833 3.9695 15.2184 2.87554 14.1245C1.78157 13.0305 1.16699 11.5468 1.16699 9.99967V8.33301M7.00033 15.833V19.1663M3.66699 19.1663H10.3337M7.00033 0.833008C6.33728 0.833008 5.7014 1.0964 5.23256 1.56524C4.76372 2.03408 4.50033 2.66997 4.50033 3.33301V9.99967C4.50033 10.6627 4.76372 11.2986 5.23256 11.7674C5.7014 12.2363 6.33728 12.4997 7.00033 12.4997C7.66337 12.4997 8.29925 12.2363 8.76809 11.7674C9.23693 11.2986 9.50033 10.6627 9.50033 9.99967V3.33301C9.50033 2.66997 9.23693 2.03408 8.76809 1.56524C8.29925 1.0964 7.66337 0.833008 7.00033 0.833008Z"
        stroke="#748995"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
