import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import { Text } from 'assets/components/text/Text';
import { InternalScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { Platform, View } from 'react-native';
import { Divider } from 'react-native-paper';
import { PrescriptionCard } from '../../../components/prescription-card';
import { StoreSelector } from '../../../components/store-selector';
import { MapSize } from '../../../components/store-selector/types';
import { useOrderRefillStore } from './order-store';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import {
  OrderItemDto,
  OrderProgressStatus,
  OrderStatus,
} from '@digitalpharmacist/order-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';
import { NativeHeader } from '../../../../../../packages/assets/components/native-header';
import { useUserState } from '../../../store/user-store';
import { useRefillMedicationsStore } from '../../refill/refill-store';
import { StackHeaderProps } from '@react-navigation/stack';
import refillService from '../../refill/refill-service';
import {
  IntegrationType,
  PrescriptionDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import { usePatientIntakeState } from '../../account/patient/patient-store';
import { CardType } from '@digitalpharmacist/patient-service-client-axios';
import { Insurance } from '../insurance-bottomsheet/Insurance';
import patientService from '../../../api/patient-service';
import { PrescriptionDetails } from '../../../components/prescription-details/PrescriptionDetails';
import { PrescriptionDetailsHandler } from '../../../components/prescription-details/PrescriptionDetailsProps';
import { logError } from 'assets/logging/logger';
import { MessageSquareIcon } from 'assets/icons';
import {
  NewMessageHandler,
  SubjectOptionsEnum,
} from '../../messages/MessageProps';
import { NewMessage } from '../../messages/NewMessage';
import { buildMessageList } from '../../messages/messages-actions';
import { loadStores } from '../../../actions/app-actions';
import { InternalScreenButtonProps } from 'assets/layout/screen/InternalScreenContainer';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export const OrderDetails: FunctionComponent<
  PropsWithChildren<OrderDetailsProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    orderRefill,
    selectedPatient,
    patientOrders,
    origin,
    selectedPatientRecord: patientRecord,
  } = useOrderRefillStore();
  const { stores, is_patient_start_chat_available } = useAppStateStore();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const { user } = useUserState();
  const { allMedications, updateMedicationsData } = useRefillMedicationsStore();
  const [otherInformationText, setOtherInformationText] = useState<string>('');
  const { data, updateData } = usePatientIntakeState();
  const insuranceRef = React.useRef<BaseModalHandler>(null);
  const prescriptionDetailsRef = React.useRef<PrescriptionDetailsHandler>(null);
  const newMessageRef = React.useRef<NewMessageHandler>(null);

  const selectedPatientOrder = patientOrders?.find(
    (patientOrder) => patientOrder.order.order_id === orderRefill?.order_id,
  );

  const handleMessagePress = () => {
    newMessageRef.current?.show();
  };

  const onMessageCreate = async () => {
    await buildMessageList();
  };

  useEffect(() => {
    if (!stores.length) void loadStores();
  }, []);

  useEffect(() => {
    if (!orderRefill?.location_id) return;

    const selectedStore = stores.find(
      (store) => store.id === orderRefill.location_id,
    );
    setSelectedStore(selectedStore);
  }, [stores]);

  useEffect(() => {
    void loadOtherInformationText();
  }, [orderRefill]);

  const loadOtherInformationText = async () => {
    if (!orderRefill) return;

    setOtherInformationText(getText('loading'));

    try {
      const otherInformationText =
        await refillService.getRefillPatientMedicalInfoText(orderRefill);

      setOtherInformationText(otherInformationText);
    } catch (error) {
      setOtherInformationText('Error ' + getText('loading'));
    }
  };

  const handleBack = () => {
    switch (origin) {
      case 'medications':
        return navigation.navigate('medications', {
          screen: 'medications-tab',
        });
      case 'orders':
        return navigation.navigate('orders', { screen: 'order' });
      default:
        navigation.goBack();
    }
  };

  const handleOnPress = async () => {
    if (
      !user?.preferredPharmacyLocationId ||
      !orderRefill ||
      !patientRecord ||
      !updateMedicationsData
    )
      return;

    const refillItems = [];
    for (const ri of orderRefill.refill_items) {
      refillItems.push({
        item_name: ri.rx_number,
        item_description: ri.rx_name,
      } as OrderItemDto);
    }

    const selectedMeds = [];
    for (const ri of orderRefill.refill_items) {
      selectedMeds.push({
        ...ri.prescription,
      } as PrescriptionDto);
    }

    updateMedicationsData({
      selectedLocationId: user.preferredPharmacyLocationId,
      selectedMedications: selectedMeds,
      selectedPatient: selectedPatient,
      selectedPatientRecord: patientRecord,
    });

    if (
      patientRecord.insurance_card_primary_front_url ||
      patientRecord.insurance_card_primary_back_url
    ) {
      return insuranceRef.current?.show();
    }
    navigation.navigate('refill', { screen: 'refill-review' });
  };

  const handleInsuranceSubmit = async (value: string) => {
    if (value === 'yes') {
      const patientId = useUserState.getState().user?.patientRecordId;
      if (!patientId || !user?.patientRecordId) return;

      const patientRecord = await patientService.findPatientRecord(
        user.patientRecordId,
      );

      if (
        !patientRecord.insurance_card_secondary_back_url ||
        !patientRecord.insurance_card_secondary_front_url
      ) {
        navigation.navigate('refill', {
          screen: 'edit-patient-card-refill',
          params: {
            cardType: CardType.InsurancePrimary,
            nextNavigation: 'refill-review',
          },
        });
        return;
      }

      if (!updateData) return;

      updateData({
        isRefill: true,
        data: data,
        status: 'success',
      });
      return navigation.navigate('refill', { screen: 'change-insurance' });
    }

    navigation.navigate('refill', { screen: 'refill-review' });
  };

  const handleOpenPrescriptionDetailsPress = (
    prescription: PrescriptionDto | undefined,
  ) => {
    if (!prescription) return logError(new Error('Prescription Missing'));

    prescriptionDetailsRef.current?.show(prescription);
  };

  const orderItemsList = useMemo(() => {
    return orderRefill?.refill_items.map((refillItem) => {
      const prescription = refillItem.prescription!;

      const orderItem = selectedPatientOrder?.order.items?.find(
        (oi) => refillItem.rx_number === oi.item_name,
      );

      return {
        prescription,
        orderItem,
        refillItem,
      };
    });
  }, [orderRefill, selectedPatientOrder?.order.items]);

  const getScreenButtons = () => {
    const buttons: InternalScreenButtonProps[] = [];
    if (is_patient_start_chat_available) {
      buttons.push({
        hierarchy: 'tertiary',
        onPress: handleMessagePress,
        testID: 'message-button',
        icon: MessageSquareIcon,
        logger: { id: 'message-button' },
        text: getText('send-a-secure-message'),
      });
    }

    if (
      selectedPatientOrder?.orderProgressStatus === undefined ||
      selectedPatientOrder.orderProgressStatus === OrderProgressStatus.Completed
    ) {
      buttons.push({
        hierarchy: 'primary',
        onPress: handleOnPress,
        testID: 'order-medication-next',
        logger: { id: 'order-medication-next' },
        text: getText('reorder'),
      });
    }

    return buttons;
  };

  const onRefillButtonPress = (medication: PrescriptionDto) => {
    if (!updateMedicationsData || !selectedPatient?.patient_record_id) return;

    updateMedicationsData({
      allMedications: allMedications?.map((x) => ({
        ...x,
        checked:
          x.prescription_id === medication.prescription_id ? true : x.checked,
      })),
      selectedMedications: [medication],
      selectedPatientRecordId: selectedPatient.patient_record_id,
    });
    prescriptionDetailsRef.current?.hide();
    navigation.navigate('medications', { screen: 'medications-tab' });
  };

  return (
    <>
      {Platform.OS !== 'web' && (
        <NativeHeader
          title={getText('order-details')}
          onBack={handleBack}
          showAction={false}
          showBack={true}
        />
      )}
      <InternalScreenContainer
        buttons={getScreenButtons()}
        title={getText('order-details')}
      >
        <View>
          <View style={{ marginTop: theme.getSpacing(2) }}>
            <Text style={styles.textTitle}>{getText('medications')}</Text>
          </View>
          {orderItemsList &&
            orderItemsList.map(({ refillItem, prescription, orderItem }) => {
              const showOrderStatus =
                prescription.integration_type !== IntegrationType.Manual;

              return (
                <View key={refillItem.rx_number}>
                  <View style={{ paddingVertical: theme.getSpacing(2) }}>
                    <Divider />
                  </View>
                  <PrescriptionCard
                    showRxNumber={true}
                    showPatientInfo={true}
                    showDetailsIcon={true}
                    key={refillItem.rx_number}
                    prescription={prescription}
                    orderStatus={orderItem?.order_status}
                    showOrderStatus={showOrderStatus}
                    onOpenDetailsPress={() =>
                      handleOpenPrescriptionDetailsPress(prescription)
                    }
                  />
                </View>
              );
            })}
        </View>
        <View>
          <View style={styles.container}>
            <View style={{ paddingVertical: theme.getSpacing(1) }}>
              <Text style={styles.textTitle}>{getText('order-details')}</Text>
              <Divider />
            </View>
            <View style={styles.container}>
              {selectedStore && (
                <StoreSelector
                  options={stores}
                  selectedOption={selectedStore}
                  mapProps={{ size: MapSize.lg }}
                  changeButtonShown={false}
                />
              )}
            </View>
          </View>
          <Divider />
          <ListMenu style={styles.container}>
            <ListItemLink showIcon={false}>
              <Text style={styles.textTitle}>
                {getText('other-information')}
              </Text>
              <Text>{otherInformationText}</Text>
            </ListItemLink>
            <ListItemLink showIcon={false}>
              <Text style={styles.textTitle}>{getText('method')}</Text>
              <Text style={{ textTransform: 'capitalize' }}>
                {orderRefill?.pickup_method_name}
              </Text>
            </ListItemLink>
          </ListMenu>
        </View>
        <NewMessage
          preSelectedSubject={SubjectOptionsEnum.MedicationInfo}
          ref={newMessageRef}
          onMessageCreate={onMessageCreate}
        />
        <Insurance
          ref={insuranceRef}
          handleInsuranceSubmit={handleInsuranceSubmit}
        />
        <PrescriptionDetails
          ref={prescriptionDetailsRef}
          onRefillButtonPress={onRefillButtonPress}
        />
      </InternalScreenContainer>
    </>
  );
};

export type OrderDetailsProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  noteForPharmacyTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginBottom: theme.getSpacing(1),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginVertical: theme.getSpacing(1),
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
}));
