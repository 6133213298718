import React, { FunctionComponent, useCallback } from 'react';
import { Platform, View } from 'react-native';
import shallow from 'zustand/shallow';
import {
  useRoute,
  useFocusEffect,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Text } from 'assets/components/text';
import { ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { useAppointmentTypesState } from './appointment-types/appointment-types-store';
import { AppointmentTypesList } from './appointment-types/AppointmentTypesList';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { useAppointmentsState } from './appointments-store';
import { Appointment } from './Appointment';
import { Pagination } from '../../components/pagination/Pagination';
import {
  getAppointments,
  getRouteLocationAppointmentTypes,
  setOffsetUpcoming,
} from './appointments-actions';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { formatDateTimeApi } from '../../common/datetime-utils';
import moment from 'moment';
import {
  AppointmentTypeDto,
  SortOrder,
} from '@digitalpharmacist/appointment-service-client-axios';
import { getText } from 'assets/localization/localization';
import {
  setAppointmentType,
  setLocation,
  setShowBookAppointment,
} from './book-appointment/book-appointment-actions';
import { BookAppointment } from './book-appointment/BookAppointment';
import { Button } from 'assets/components/button';
import { NewMessage } from '../messages/NewMessage';
import {
  NewMessageHandler,
  SubjectOptionsEnum,
} from '../messages/MessageProps';
import { buildMessageList } from '../messages/messages-actions';

interface UpcomingAppointmentsRouteParams {
  appointment_type_id?: string;
  location_id?: string;
}

type UpcomingAppointmentsRouteParamList = {
  Upcoming: UpcomingAppointmentsRouteParams;
};

export const UpcomingAppointments: FunctionComponent<
  UpcomingAppointmentsProps
> = () => {
  const isFocused = useIsFocused();
  const route = useRoute(),
    navigation =
      useNavigation<
        StackNavigationProp<UpcomingAppointmentsRouteParamList, 'Upcoming'>
      >(),
    routeParams =
      route.params && (route.params as UpcomingAppointmentsRouteParams),
    appointmentTypeRouteParam = routeParams?.appointment_type_id,
    locationIdRouteParam = routeParams?.location_id;
  const theme = useTheme();
  const styles = useStyles();
  const { is_patient_start_chat_available } = useAppStateStore();

  const { typesStatus, appointmentTypes, appointmentGroups } =
    useAppointmentTypesState(
      (state) => ({
        typesStatus: state.status,
        appointmentTypes: state.appointmentTypes,
        appointmentGroups: state.appointmentGroups,
      }),
      shallow,
    );
  const userId = useUserState((state) => state.user?.id);
  const preferredPharmacyLocationId = useUserState(
    (state) => state.user?.preferredPharmacyLocationId,
  );
  const patientRecordId = useUserState((state) => state.user?.patientRecordId);

  const {
    upcomingAppointments,
    appointmentsStatus,
    offsetUpcoming,
    limit,
    location,
    routeLocationAppointmentTypes,
    routeLocationAppointmentTypesStatus,
  } = useAppointmentsState(
    (state) => ({
      upcomingAppointments: state.upcomingAppointments,
      appointmentsStatus: state.status,
      offsetUpcoming: state.offsetUpcoming,
      limit: state.limit,
      location: state.location,
      routeLocationAppointmentTypes: state.routeLocationAppointmentTypes,
      routeLocationAppointmentTypesStatus:
        state.routeLocationAppointmentTypesStatus,
    }),
    shallow,
  );

  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const availableStores = useAppStateStore((state) => state.stores);

  const handleNext = () => {
    setOffsetUpcoming(offsetUpcoming + limit);
    getAppointmentsData();
  };

  const handlePrev = () => {
    setOffsetUpcoming(offsetUpcoming - limit);
    getAppointmentsData();
  };

  const getAppointmentsData = () => {
    if (location?.id) {
      void getAppointments(pharmacyId, location.id, SortOrder.Asc, 'upcoming', {
        patientId: userId,
        ...(patientRecordId && { patientRecordId }),
        minEndDate: formatDateTimeApi(moment()),
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      if (routeLocationAppointmentTypes.length && appointmentTypeRouteParam) {
        const foundAppointmentTypeBasedOnRouteParam =
          routeLocationAppointmentTypes.find(
            (type) => type.id === appointmentTypeRouteParam,
          );
        if (foundAppointmentTypeBasedOnRouteParam) {
          setAppointmentType(foundAppointmentTypeBasedOnRouteParam);

          // find the location based on the route parameter
          if (locationIdRouteParam && availableStores.length) {
            const foundLocationBasedOnRouteParam = availableStores.find(
              (store) => store.id === locationIdRouteParam,
            );
            if (foundLocationBasedOnRouteParam) {
              setLocation(foundLocationBasedOnRouteParam);
            }
          }

          setShowBookAppointment(true);
        }
      }

      return () => {
        navigation.setParams({
          appointment_type_id: undefined,
          location_id: undefined,
        });
      };
    }, [
      routeLocationAppointmentTypes,
      locationIdRouteParam,
      appointmentTypeRouteParam,
    ]),
  );

  // If there is a location route parameter set up then we have to get the additional
  // appointment types for the location from the parameter in order to be able to make a booking.
  useFocusEffect(
    useCallback(() => {
      if (locationIdRouteParam) {
        getRouteLocationAppointmentTypes(pharmacyId, locationIdRouteParam);
      }
    }, [locationIdRouteParam]),
  );

  const onMessageCreate = async () => {
    await buildMessageList();
  };

  const newMessageRef = React.useRef<NewMessageHandler>(null);

  const handleMessagePress = () => {
    newMessageRef.current?.show();
  };

  const getSortedAppointmentTypes = (types: AppointmentTypeDto[]) => {
    return types.sort((a, b) =>
      a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }),
    );
  };

  return (
    <ScreenContainer
      showFooter
      title={getText('appointments')}
      backgroundColor={theme.palette.gray[50]}
    >
      <View>
        {appointmentsStatus === 'loading' || !upcomingAppointments ? (
          <LoadingIndicator />
        ) : (
          <>
            {upcomingAppointments.total === 0 ? (
              <View style={styles.emptyContainer}>
                <Text style={styles.emptyTitle}>
                  {getText('no-appointments')}
                </Text>
              </View>
            ) : (
              upcomingAppointments.results.map((appointment) => (
                <Appointment
                  appointment={appointment}
                  type="upcoming"
                  key={appointment.id}
                />
              ))
            )}
          </>
        )}
        {upcomingAppointments && upcomingAppointments.total > limit ? (
          <Pagination
            currentPage={(offsetUpcoming + limit) / limit}
            totalPages={Math.ceil(upcomingAppointments.total / limit)}
            onPrevPress={handlePrev}
            onNextPress={handleNext}
          />
        ) : null}

        <Text style={styles.title}>{getText('make-appointment')}</Text>

        <Text style={styles.locationName}>{location?.name}</Text>
        {typesStatus === 'loading' ||
        routeLocationAppointmentTypesStatus === 'loading' ? (
          <LoadingIndicator />
        ) : !appointmentTypes.length ? (
          <>
            <Text style={styles.emptyTitle}>{getText('no-services')}</Text>
          </>
        ) : (
          <AppointmentTypesList
            types={getSortedAppointmentTypes(appointmentTypes)}
            groups={appointmentGroups}
          />
        )}

        <>
          <Text style={styles.title}>{getText('dont-see-service')}</Text>
          <Text style={styles.subtitle}>
            {getText('contact-pharmacy-for-assistance')}
          </Text>
          {is_patient_start_chat_available ? (
            <Button
              hierarchy="secondary"
              size="large"
              onPress={handleMessagePress}
              logger={{ id: 'appointment-send-message' }}
              style={styles.messageButton}
            >
              {getText('send-message')}
            </Button>
          ) : null}
        </>
        {isFocused && (
          <BookAppointment onDismiss={() => setShowBookAppointment(false)} />
        )}
      </View>
      {preferredPharmacyLocationId && (
        <NewMessage
          preSelectedSubject={SubjectOptionsEnum.Appointments}
          ref={newMessageRef}
          onMessageCreate={onMessageCreate}
          locationId={preferredPharmacyLocationId}
        />
      )}
    </ScreenContainer>
  );
};

interface UpcomingAppointmentsProps {}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.medium,
    fontSize: 20,
    lineHeight: 22,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(1),
  },
  emptyContainer: {
    marginBottom: theme.getSpacing(2),
  },
  emptyTitle: {
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  container: {
    marginTop: theme.getSpacing(Platform.OS === 'web' ? 2 : 4),
  },
  buttonLabel: {
    color: theme.palette.primary[600],
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    letterSpacing: 0,
  },
  buttonBorderColor: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    marginLeft: theme.getSpacing(1),
    padding: theme.getSpacing(2),
  },
  subtitle: {
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
    fontSize: 14,
    lineHeight: 16,
  },
  locationName: {
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
    fontSize: 16,
    lineHeight: 18,
  },
  messageButton: {
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(1),
  },
}));
