import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TextProps } from 'react-native';
import { Text as PaperText } from 'react-native-paper';

export const Text: FunctionComponent<PropsWithChildren<TextProps>> = (
  props,
) => {
  return (
    <PaperText
      {...props}
      // TODO: the typings seemed to be fixed, will need to migrate project to using Text directly
      //
      // Fix for typing, hopefully this component can be removed in later library upgrade.
      //
      // This github issue describes the problem, but the recommendation of upgrading react-native
      // types is NOT a good suggestion, we will wait until expo v45 to upgrade react-native:
      // https://github.com/callstack/react-native-paper/issues/3027
      maxFontSizeMultiplier={1.5}
      minimumFontScale={0.85}
    >
      {props.children}
    </PaperText>
  );
};
