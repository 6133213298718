import { OrderDto } from '@digitalpharmacist/order-service-client-axios';
import { CardType } from '@digitalpharmacist/patient-service-client-axios';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { StackHeaderProps } from '@react-navigation/stack';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { ShoppingBagWithGreenTagIcon } from 'assets/icons';
import { ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { FlatList, Platform, RefreshControl, View } from 'react-native';
import patientService from '../../../api/patient-service';
import { EmptyStatePage } from '../../../components/empty-state-page/EmptyStatePage';
import { OrderCard } from '../../../components/order-card';
import { PrescriptionDetails } from '../../../components/prescription-details/PrescriptionDetails';
import { PrescriptionDetailsHandler } from '../../../components/prescription-details/PrescriptionDetailsProps';
import { useUserState } from '../../../store/user-store';
import {
  usePatientIntakeState,
  usePatientRecordState,
} from '../../account/patient/patient-store';
import refillService from '../../refill/refill-service';
import { Insurance } from '../insurance-bottomsheet/Insurance';
import orderService from './order-service';
import { useOrderRefillStore } from './order-store';
import { useFocusEffect } from '@react-navigation/native';
import { logError } from 'assets/logging/logger';
import { useRefillMedicationsStore } from '../../refill/refill-store';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export const Orders: FunctionComponent<PropsWithChildren<OrderProps>> = ({
  navigation,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const locationId = useUserState((s) => s.user?.preferredPharmacyLocationId);
  const patientRecordId = useUserState((s) => s.user?.patientRecordId);
  const { allMedications, updateMedicationsData } = useRefillMedicationsStore();
  const { patientOrders, updateOrderRefillData } = useOrderRefillStore();
  const { patientRecord, recordsUnderCare } = usePatientRecordState();
  const { data, updateData } = usePatientIntakeState();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isFocusLoading, setIsFocusLoading] = React.useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const insuranceRef = React.useRef<BaseModalHandler>(null);
  const prescriptionDetailsRef = React.useRef<PrescriptionDetailsHandler>(null);
  const { patientRecordItems } = usePatientRecordState();

  useEffect(() => {
    setIsLoading(true);

    void (async () => {
      await loadOrders();
      setIsLoading(false);
    })();
  }, [locationId, patientRecordItems.length]);

  useFocusEffect(
    React.useCallback(() => {
      setIsFocusLoading(true);

      void (async () => {
        await loadOrders();
        setIsFocusLoading(false);
      })();
    }, [locationId, patientRecordItems.length]),
  );

  const loadOrders = async () => {
    if (!locationId || !updateOrderRefillData || !patientRecord) return;

    const allOrders = await orderService.getPatientOrders(
      locationId,
      recordsUnderCare,
      patientRecord,
    );

    updateOrderRefillData({
      patientOrders: allOrders,
      origin: 'orders',
    });
  };

  const handleOpenOrderDetails = async (order: OrderDto) => {
    if (!updateOrderRefillData) return;

    const refill = await refillService.getOrderRefill(
      order.location_id,
      order.patient_id,
      order.order_id,
    );

    const patient = await patientService.findLocationPatientRecord(
      order.location_id,
      order.patient_id,
    );

    if (!patient)
      return logError(new Error(getText('error-loading-patient-record')));

    const selectedPatientRecord = await patientService.findPatientRecord(
      // We know that in this phase the LPR has always the patient_record_id
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      patient.patient_record_id!,
    );

    updateOrderRefillData({
      orderRefill: refill,
      selectedPatient: patient,
      selectedPatientRecord: selectedPatientRecord,
      origin: 'orders',
    });

    return Platform.OS === 'web'
      ? navigation.navigate('app', { screen: 'order-details-web-tab' })
      : navigation.navigate('app', { screen: 'order-details-bottom-tab' });
  };

  const handleInsuranceSubmit = async (value: string) => {
    // TODO: Klajd + Jorgen this should be for all PR and PUC insurance management
    if (value === 'yes') {
      if (!patientRecordId) return;

      const patientRecord =
        await patientService.findPatientRecord(patientRecordId);

      if (
        !patientRecord.insurance_card_secondary_back_url ||
        !patientRecord.insurance_card_secondary_front_url
      ) {
        navigation.navigate('refill', {
          screen: 'edit-patient-card',
          params: {
            cardType: CardType.InsurancePrimary,
            nextNavigation: 'refill-review',
          },
        });
        return;
      }

      if (!updateData) return;

      updateData({
        isRefill: true,
        data: data,
        status: 'success',
      });

      return navigation.navigate('refill', { screen: 'change-insurance' });
    }

    navigation.navigate('refill', { screen: 'refill-review' });
  };

  const handleOpenPrescriptionDetails = (prescription: PrescriptionDto) => {
    prescriptionDetailsRef.current?.show(prescription);
  };

  const handlePullDownRefresh = React.useCallback(() => {
    setIsRefreshing(true);

    void loadOrders().then(() => {
      setIsRefreshing(false);
    });
  }, [locationId, patientRecordItems.length]);

  if (isLoading) {
    return (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator />
      </View>
    );
  }

  const onRefillButtonPress = async (medication: PrescriptionDto) => {
    const patient = await patientService.findLocationPatientRecord(
      medication.location_id,
      medication.patient_id,
    );

    if (!updateMedicationsData || !patient?.patient_record_id) return;
    updateMedicationsData({
      allMedications: allMedications?.map((x) => ({
        ...x,
        checked:
          x.prescription_id === medication.prescription_id ? true : x.checked,
      })),
      selectedMedications: [medication],
      selectedPatientRecordId: patient.patient_record_id,
    });
    prescriptionDetailsRef.current?.hide();
    navigation.navigate('medications', { screen: 'medications-tab' });
  };

  return (
    <ScreenContainer
      disableScrollView={Platform.OS !== 'web'}
      showFooter
      backgroundColor={theme.palette.gray[50]}
    >
      {isFocusLoading && (
        <View style={styles.focusLoadingIndicator}>
          <LoadingIndicator />
        </View>
      )}

      {patientOrders?.length === 0 ? (
        <EmptyStatePage
          title={getText('orders-tab-empty-state-title')}
          description={getText('orders-tab-empty-state-description')}
          buttonText={getText('start-an-order')}
          icon={ShoppingBagWithGreenTagIcon}
          onPress={() => {
            navigation.navigate('medications-tab');
          }}
        />
      ) : (
        <FlatList
          data={patientOrders}
          keyExtractor={(patientOrder) => patientOrder.order.order_id}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={handlePullDownRefresh}
            />
          }
          renderItem={({ item: patientOrder }) => (
            <OrderCard
              key={patientOrder.order.order_id}
              order={patientOrder.order}
              orderProgressStatus={patientOrder.orderProgressStatus}
              onOpenOrderDetails={() =>
                handleOpenOrderDetails(patientOrder.order)
              }
              onOpenPrescriptionDetails={handleOpenPrescriptionDetails}
            />
          )}
        />
      )}
      <Insurance
        ref={insuranceRef}
        handleInsuranceSubmit={handleInsuranceSubmit}
      />
      <PrescriptionDetails
        ref={prescriptionDetailsRef}
        onRefillButtonPress={onRefillButtonPress}
      />
    </ScreenContainer>
  );
};

export type OrderProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '45%',
    marginVertical: theme.getSpacing(2),
  },
  focusLoadingIndicator: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: theme.getSpacing(2),
    backgroundColor: 'rgba(250, 250, 250, 0.3)',
    zIndex: 10,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: -20,
  },
}));
