import React from 'react';

export type AppNotificationsContextType = {
  refreshNotifications?: () => Promise<void>;
};

export const AppNotificationsContext =
  React.createContext<AppNotificationsContextType>({});

export const AppNotificationsConsumer = AppNotificationsContext.Consumer;
