import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { Platform, View } from 'react-native';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import { PatientUnderCareForm } from '../patient-actions';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import states from '../../../../types/states';
import { UseFormReturn } from 'react-hook-form';
import { Form } from 'assets/layout';
import { ZipCodeField } from './ZipCodeField';
import { AddressDto } from '@digitalpharmacist/patient-service-client-axios';

export const Address: FunctionComponent<
  PropsWithChildren<{
    form: UseFormReturn<PatientUnderCareForm>;
    autoFillConfig?: {
      label: string;
      address: AddressDto;
    };
  }>
> = ({ form, autoFillConfig }) => {
  const theme = useTheme();
  const address1 = form.watch('address1');
  const address2 = form.watch('address2');
  const city = form.watch('city');
  const postal_code = form.watch('postal_code');
  const country = form.watch('country');
  const state = form.watch('state');
  const actualStatesKey = country === 'CA' ? 'CA' : 'US';

  const isFormRequired = () =>
    address1 || address2 || city || state || postal_code ? true : false;

  const [addressFormIsRequired, setAddressFormIsRequired] = useState(
    isFormRequired(),
  );

  useEffect(() => {
    const listOfStates = states[actualStatesKey];
    const stateExistOnActualList =
      listOfStates.filter((x) => x.value === state).length > 0;
    if (!stateExistOnActualList) form.setValue('state', '');
  }, [state]);

  useEffect(() => {
    setAddressFormIsRequired(isFormRequired());
  }, [address1, address2, city, state, postal_code]);

  useEffect(() => {
    if (address2) {
      form.trigger(['address1', 'city', 'state', 'postal_code']);
    }
  }, [addressFormIsRequired]);

  const options = [
    { value: '', label: `${getText('select')}...` },
    ...states[actualStatesKey],
  ];

  const styles = useStyles();
  const isWeb = Platform.OS === 'web';

  function autoFillAddress(): void {
    if (autoFillConfig) {
      form.setValue('address1', autoFillConfig.address.address1);
      form.setValue('address2', autoFillConfig.address.address2 ?? '');
      form.setValue('state', autoFillConfig.address.state);
      form.setValue('postal_code', autoFillConfig.address.postal_code);
      form.setValue('city', autoFillConfig.address.city);
    }
  }

  return (
    <View>
      <Form.Row>
        <Form.Column>
          <View style={{ marginTop: theme.getSpacing(2) }}>
            <Form.Row
              style={{
                marginTop: theme.getSpacing(2),
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text>
                {getText('address') + (addressFormIsRequired ? ' *' : '')}
              </Text>

              <View style={{ marginLeft: 'auto' }}>
                {autoFillConfig && (
                  <Text
                    onPress={autoFillAddress}
                    style={[
                      styles.pressableTextRevoke,
                      styles.leftSpacing,
                      isWeb && styles.pointer,
                    ]}
                  >
                    {autoFillConfig.label}
                  </Text>
                )}
              </View>
            </Form.Row>
          </View>
          <TextField
            name={'address1'}
            label={getText('street-address')}
            testID={'street-address'}
            rules={{
              required:
                addressFormIsRequired && getText('street-address-is-required'),
            }}
          />
        </Form.Column>
      </Form.Row>
      <TextField
        name={'address2'}
        label={getText('street-address-line-2')}
        testID={'street-address-2'}
      />
      <Form.Row style={{ marginTop: theme.getSpacing(2) }}>
        <Form.Column>
          <TextField
            name={'city'}
            label={getText('city')}
            testID={'city'}
            rules={{
              required: addressFormIsRequired && getText('city-is-required'),
            }}
          />
        </Form.Column>
        <Form.Column>
          <View
            testID={'state'}
            style={Platform.OS == 'android' && { marginTop: 7 }}
          >
            <DropdownSelectField
              options={options}
              name="state"
              testID="state"
              label={getText('state')}
              labelInlined={true}
              rules={{
                required: addressFormIsRequired && getText('state-is-required'),
              }}
            />
          </View>
        </Form.Column>
      </Form.Row>
      <Form.Row style={{ marginTop: theme.getSpacing(0.5) }}>
        <Form.Column>
          <ZipCodeField name="postal_code" required={addressFormIsRequired} />
        </Form.Column>
        <Form.Column>
          <View
            testID={'country'}
            style={Platform.OS == 'android' && { marginTop: 7 }}
          >
            <DropdownSelectField
              name="country"
              testID="country"
              label={getText('country')}
              labelInlined={true}
              disabled={true}
              options={[
                {
                  label: 'US',
                  value: 'US',
                },
              ]}
            />
          </View>
        </Form.Column>
      </Form.Row>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    backgroundColor: theme.palette.gray[100],
    width: '100%',
  },
  emptyContainer: {
    marginTop: theme.getSpacing(4),
    alignItems: 'center',
    paddingTop: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    display: 'flex',
  },
  emptyTextTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  textDetails: {
    marginTop: 2,
    marginLeft: theme.getSpacing(3),
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    height: 16,
    color: theme.palette.gray[600],
  },
  textDetailsContainer: {
    marginLeft: theme.getSpacing(0.5),
  },
  text: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(2),
    paddingStart: theme.getSpacing(1),
    paddingEnd: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
  },
  emptyText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
    textAlign: 'center',
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  rowDirection: {
    flexDirection: 'row',
  },
  otherDetailsText: {
    color: theme.palette.gray[700],
    marginTop: theme.getSpacing(1),
  },
  marginLeft: { marginLeft: theme.getSpacing(0.5) },
  detailsText: {
    fontWeight: '600',
    fontSize: 24,
    lineHeight: 26,
    color: theme.palette.gray[700],
  },
  rightSpacing: {
    marginRight: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(0.5),
  },
  authorizationContainer: {
    flexDirection: 'row',
  },
  requestApprovedText: {
    color: theme.palette.gray[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(0.5),
  },
  nameText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
  },
  bottomLineBold: {
    margin: theme.getSpacing(2),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  bottomLineThin: {
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 0.5,
    marginLeft: theme.getSpacing(3),
    marginTop: theme.getSpacing(2),
  },
  viewMargin: {
    margin: theme.getSpacing(2),
  },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
  requestPendingStyle: {
    color: theme.palette.warning[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '45%',
    marginVertical: theme.getSpacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(1),
  },
  title: {
    ...theme.lumistryFonts.label.xxLarge.bold,
  },
}));
