import {
  CardType,
  LocationPatientRecordDto,
  MinLocationPatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { useIsFocused } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import { Button } from 'assets/components/button';
import {
  DropdownSelect,
  DropdownSelectField,
  DropdownSelectHandler,
} from 'assets/components/dropdown-select';
import { Icon } from 'assets/components/icon';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Text } from 'assets/components/text';
import { useForm } from 'assets/form';
import { ArrowLeftDownIcon, PillsIcon, ShoppingCartIcon } from 'assets/icons';
import { Form, InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Platform, RefreshControl, TouchableOpacity, View } from 'react-native';
import { Divider } from 'react-native-paper';
import patientService from '../../api/patient-service';
import { EmptyStatePage } from '../../components/empty-state-page/EmptyStatePage';
import { ErrorOccurredPage } from '../../components/error-occurred/ErrorOccurred';
import { PrescriptionCard } from '../../components/prescription-card';
import { PrescriptionDetails } from '../../components/prescription-details/PrescriptionDetails';
import { PrescriptionDetailsHandler } from '../../components/prescription-details/PrescriptionDetailsProps';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import {
  refreshCaregiverRequestsState,
  userHasAccessToRecord,
} from '../account/patient/patient-actions';
import {
  usePatientIntakeState,
  usePatientRecordState,
} from '../account/patient/patient-store';
import { openAddMedications } from '../add-medications/add-medications-actions';
import refillService from '../refill/refill-service';
import { useRefillMedicationsStore } from '../refill/refill-store';
import { UnableToSubmit } from '../refill/unable-to-submit/UnableToSubmit';
import { UnableToSubmitHandler } from '../refill/unable-to-submit/UnableToSubmitProps';
import { useTransferMedicationsStore } from '../transfer-medications/transfer-meds-store';
import { Insurance } from './insurance-bottomsheet/Insurance';
import { processInsurance } from './medication-actions';
import { MedicationsEmptyStateSmall } from './medications-empty-state/MedicationsEmptyStateSmall';
import { OrderMedicationsAlert } from './order-medications-alert/OrderMedicationAlert';
import { OrderProgressTrackerList } from './order-progress-tracker/OrderProgressTrackerList';
import { loadLocationPickupMethods } from '../../actions/app-actions';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';

export type PatientRecordItem = {
  patientRecordId: string;
  fullName: string;
  location_patient_record: MinLocationPatientRecordDto[];
};

export const Medications: FunctionComponent<
  PropsWithChildren<MedicationsProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const locationId = useUserState((s) => s.user?.preferredPharmacyLocationId);
  const patientRecordId = useUserState((s) => s.user?.patientRecordId);
  const isFocused = useIsFocused();
  const stores = useAppStateStore((s) => s.stores);
  const {
    isFirstTimeRegister,
    allMedications,
    unableToSubmitMedications,
    selectedPatient,
    updateMedicationsData,
    selectedPrescriptionId,
    selectedPatientRecordId: selectedPatientRecordIdState,
    selectedMedications,
    notificationRefreshTrigger,
  } = useRefillMedicationsStore();
  const { data, updateData } = usePatientIntakeState();
  const orderAlertRef = React.useRef<BaseModalHandler>(null);
  const unableToSubmitRef = React.useRef<UnableToSubmitHandler>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = React.useState<boolean>(false);
  const [overTheCounterMeds, setOverTheCounterMeds] = useState([]);
  const { patientRecordItems } = usePatientRecordState();
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const insuranceRef = React.useRef<BaseModalHandler>(null);
  const dropdownSelectRef = React.useRef<DropdownSelectHandler>(null);
  const prescriptionDetailsRef = React.useRef<PrescriptionDetailsHandler>(null);
  const [insuranceSubmittedValue, setInsuranceSubmittedValue] =
    useState<boolean>(false);
  const displayOrderButton =
    !errorOccurred && !isLoading && allMedications?.length !== 0;

  const selectedPatientRecordId = selectedPatientRecordIdState
    ? selectedPatientRecordIdState
    : patientRecordId;

  const [watcherSelectedPR, setWatcherSelectedPR] = useState<
    string | undefined
  >(selectedPatientRecordId ?? undefined);

  const getLocationPatientRecordFromPUC = (): string | undefined => {
    const pucRecord = patientRecordItems.find(
      (puc) => puc.patientRecordId === watcherSelectedPR,
    );
    const lprRecord = pucRecord?.location_patient_record.find(
      (lpc) => lpc.location_id === locationId,
    );
    return lprRecord?.id;
  };

  //!render the view if patient record id does not exist
  if (!selectedPatientRecordId) {
    logError(new Error(getText('error-loading-patient-record')));
    return <></>;
  }

  useEffect(() => {
    if (!updateMedicationsData || !selectedMedications) return;
    if (!selectedPatientRecordId) {
      let selectedPatientRecordItem = patientRecordItems.find(
        (pri) => pri.patientRecordId === selectedPatientRecordId,
      );
      // if the selected value has been deleted then select the default one which is Myself
      if (!selectedPatientRecordItem) {
        selectedPatientRecordItem = patientRecordItems[0];
      }
      updateMedicationsData({
        selectedPatientRecordId: selectedPatientRecordItem.patientRecordId,
      });
    } else {
      setWatcherSelectedPR(selectedPatientRecordId);
    }
  }, [selectedPatientRecordId]);

  // Load caregivers when change Patient selection
  useEffect(() => {
    void refreshCaregiverRequestsState();
  }, [selectedPatientRecordId]);

  // Load Prescriptions based on selected Location/Patient
  useEffect(() => {
    if (!isFocused || !locationId) return;
    setIsLoading(true);
    void (async () => {
      await loadPrescriptions();
      setIsLoading(false);
    })();
  }, [
    selectedPatientRecordId,
    watcherSelectedPR,
    isFocused,
    notificationRefreshTrigger,
    locationId,
  ]);

  useEffect(() => {
    if (!selectedPatientRecordId) return;
    if (!selectedPrescriptionId) return;
    if (!allMedications?.length) return;
    if (!updateMedicationsData) return;

    const selectedPrescription = allMedications.find(
      (p) => p.prescription_id === selectedPrescriptionId,
    );

    if (!selectedPrescription) return;

    // note: reset selectedPrescriptionId after found prescription object
    void updateMedicationsData({
      selectedPrescriptionId: undefined,
    });

    prescriptionDetailsRef.current?.show(selectedPrescription);
  }, [selectedPrescriptionId, allMedications]);

  const loadPrescriptions = async () => {
    const selectedLPRId = getLocationPatientRecordFromPUC();

    if (!updateMedicationsData || !locationId) return;
    let prescriptions: PrescriptionDto[] = [];
    let lprRecord: LocationPatientRecordDto | undefined;
    let isFirstTimeRegister = false;

    if (selectedLPRId) {
      lprRecord = await patientService.findLocationPatientRecord(
        locationId,
        selectedLPRId,
      );

      isFirstTimeRegister = refillService.hasPatientMissingInfos(lprRecord);

      prescriptions = await refillService.getPatientPrescriptions(
        locationId,
        selectedLPRId,
      );
    }

    updateMedicationsData({
      showRefillMedications: false,
      isFirstTimeRegister: isFirstTimeRegister,
      selectedPatient: lprRecord,
      allMedications: prescriptions
        .sort((a, b) => {
          if (!a.next_refill_date) return 1;
          if (!b.next_refill_date) return -1;
          return a.next_refill_date > b.next_refill_date ? 1 : -1;
        })
        .map((prx) => ({
          ...prx,
          checked:
            !!selectedMedications?.filter((x) =>
              x.rx_number === prx.rx_number ? true : false,
            )?.length ?? false,
        })),
      selectedMedications: [],
    });
  };

  const handlePullDownRefresh = React.useCallback(() => {
    setIsRefreshing(true);

    void loadPrescriptions().then(() => {
      setIsRefreshing(false);
    });
  }, [locationId, selectedPatientRecordId]);

  const handleSelectMedication = (
    medication: PrescriptionDto,
    checked: boolean,
  ) => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      allMedications: allMedications?.map((x) => ({
        ...x,
        checked: x.rx_number === medication.rx_number ? checked : x.checked,
      })),
    });
  };

  const handleShowUnableToSubmit = () => {
    unableToSubmitRef.current?.show();
  };

  const handleUnableToSubmit = async (value: string) => {
    await processInsurance(
      selectedPatientRecordId,
      value,
      insuranceSubmittedValue,
      navigation,
    );

    if (value === 'back') {
      unableToSubmitRef.current?.hide();
    }
    setInsuranceSubmittedValue(false);
  };

  const handleOrderRefill = async () => {
    if (
      !locationId ||
      !updateMedicationsData ||
      !allMedications ||
      !selectedPatient?.id ||
      !selectedPatientRecordId
    ) {
      return logError(
        new Error('Missing essential Information when calling order refill'),
      );
    }
    void loadLocationPickupMethods();

    const selectedMedications = allMedications.filter((x) => x.checked);
    // If no medication selected show alert modal
    if (selectedMedications.length === 0) {
      return orderAlertRef.current?.show();
    }

    const unableToRefillMedications =
      await refillService.checkPatientPrescriptions(
        selectedMedications,
        locationId,
        selectedPatient.id,
      );

    const selectedPatientRecord = await patientService.findPatientRecord(
      selectedPatientRecordId,
    );

    updateMedicationsData({
      selectedLocationId: locationId,
      selectedPatientRecord: selectedPatientRecord,
      selectedMedications: selectedMedications,
      unableToSubmitMedications: unableToRefillMedications,
    });

    if (
      selectedPatientRecord.insurance_card_primary_front_url ||
      selectedPatientRecord.insurance_card_primary_back_url
    ) {
      return insuranceRef.current?.show();
    }

    if (!isFirstTimeRegister && unableToRefillMedications.length > 0) {
      return handleShowUnableToSubmit();
    }

    //refresh records under care state every time we want to order a refill
    await refreshCaregiverRequestsState();
    const hasAccess: boolean = await userHasAccessToRecord(
      selectedPatientRecordId,
    );
    if (!hasAccess) {
      alert(getText('no-puc-access'));
      return logError(
        new Error(getText('missing-important-patient-record-data')),
      );
    }

    return isFirstTimeRegister
      ? navigation.navigate('refill', { screen: 'refill-medical-infos' })
      : navigation.navigate('refill', { screen: 'edit-patient-card' });
  };

  const handleAddAnotherMed = async () => {
    if (!watcherSelectedPR) return;
    const hasAccess = await userHasAccessToRecord(watcherSelectedPR);

    if (!hasAccess) {
      alert(getText('no-puc-access'));

      return logError(
        new Error(getText('missing-important-patient-record-data')),
      );
    }

    if (!watcherSelectedPR) {
      return logError(new Error('No Patient Selected'));
    }

    await openAddMedications(watcherSelectedPR, navigation);
  };

  const handleAddAnotherOTCMed = async () => {
    const hasAccess: boolean = await userHasAccessToRecord(
      selectedPatientRecordId,
    );
    if (!hasAccess) {
      alert(getText('no-puc-access'));
      return logError(
        new Error(getText('missing-important-patient-record-data')),
      );
    }
    navigation.navigate('add-medications', {
      screen: 'enter-medications',
    });
  };

  const handleOnPressError = () => {
    navigation.navigate('app', { screen: 'home' });
  };

  const handleInsuranceSubmit = async (value: string) => {
    const hasAccess: boolean = await userHasAccessToRecord(
      selectedPatientRecordId,
    );
    if (!hasAccess) {
      alert(getText('no-puc-access'));
      return logError(
        new Error(getText('missing-important-patient-record-data')),
      );
    }
    if (value === 'yes') {
      setInsuranceSubmittedValue(true);
      // TODO: Klajd + Jorgen why we are using the user.patientRecordId and not the selected PatientRecord as we want to change the  PUC case
      const patientId = patientRecordId;
      if (!patientId) return;
      if (!updateData) return;
      const selectedPatientRecord = await patientService.findPatientRecord(
        selectedPatientRecordId,
      );

      if (
        !selectedPatientRecord.insurance_card_secondary_back_url ||
        !selectedPatientRecord.insurance_card_secondary_front_url
      ) {
        unableToSubmitMedications!.length > 0
          ? handleShowUnableToSubmit()
          : navigation.navigate('refill', {
              screen: 'edit-patient-card-refill',
              params: {
                cardType: CardType.InsurancePrimary,
                nextNavigation: 'refill-review',
              },
            });
        return;
      }

      updateData({
        isRefill: true,
        data: data,
        status: 'success',
      });
      return unableToSubmitMedications!.length > 0
        ? handleShowUnableToSubmit()
        : navigation.navigate('refill', { screen: 'change-insurance' });
    }

    return unableToSubmitMedications!.length > 0
      ? handleShowUnableToSubmit()
      : navigation.navigate('refill', { screen: 'refill-review' });
  };

  const handleOpenDetailsPress = (prescription: PrescriptionDto) => {
    prescriptionDetailsRef.current?.show(prescription);
  };

  const handleTransferMedsNavigation = () => {
    useTransferMedicationsStore.setState({
      status: 'idle',
      error: undefined,
      pucFullName: undefined,
      locationPatientRecord: undefined,
      locationPatientRecordId: undefined,
      transferLocation: undefined,
      originated: 'medication',
      medications: [],
      allMedications: false,
      fillPrescription: true,
      pharmacyName: '',
      pharmacyPhoneNumber: '',
      hasCanceledOrDone: undefined,
      userCanChose: true,
    });
    if (stores.length > 1) {
      navigation.navigate('transfer-medications', {
        screen: 'multiple-locations',
      });
    } else {
      navigation.navigate('transfer-medications', {
        screen: 'single-location',
      });
    }
  };

  const onRefillButtonPress = (medication: PrescriptionDto) => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      allMedications: allMedications?.map((x) => ({
        ...x,
        checked:
          x.prescription_id === medication.prescription_id ? true : x.checked,
      })),
    });
    prescriptionDetailsRef.current?.hide();
  };

  return (
    <>
      <InternalScreenContainer
        showFooter
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={handlePullDownRefresh}
          />
        }
        buttons={
          Platform.OS === 'web' && displayOrderButton
            ? [
                {
                  hierarchy: 'primary',
                  testID: 'order-button-id',
                  logger: { id: 'order-button-id' },
                  icon: ShoppingCartIcon,
                  onPress: handleOrderRefill,
                  text: getText('order'),
                },
              ]
            : []
        }
      >
        {errorOccurred ? (
          <ErrorOccurredPage onPress={handleOnPressError} />
        ) : (
          <>
            <OrderProgressTrackerList />
            <View style={{ marginVertical: theme.getSpacing(1) }}>
              {patientRecordItems.length > 1 && (
                <>
                  <View>
                    <Text style={styles.helperText}>
                      {getText('viewing-medications-for')}
                    </Text>
                  </View>
                  <DropdownSelect
                    testID="selectedPatientRecordId"
                    labelInlined={false}
                    options={patientRecordItems.map((item) => ({
                      label: item.fullName,
                      value: item.patientRecordId,
                    }))}
                    value={watcherSelectedPR}
                    ref={dropdownSelectRef}
                    onChange={setWatcherSelectedPR}
                  />
                </>
              )}
              <View style={{ marginTop: theme.getSpacing(1) }}>
                <TouchableOpacity
                  testID="transferMedicationsFor"
                  style={styles.rowView}
                  onPress={handleTransferMedsNavigation}
                >
                  <Icon
                    icon={ArrowLeftDownIcon}
                    size={20}
                    color={theme.palette.primary[600]}
                  />
                  <Text style={styles.link}>{getText('transfer-meds')}</Text>
                </TouchableOpacity>
              </View>
            </View>
            {isLoading ? (
              <View style={styles.loadingIndicator}>
                <LoadingIndicator />
              </View>
            ) : allMedications?.length === 0 ? (
              <EmptyStatePage
                title={getText('medications-empty-state-title')}
                description={getText('medications-empty-state-description')}
                buttonText={getText('add-meds')}
                icon={PillsIcon}
                onPress={handleAddAnotherMed}
              />
            ) : (
              <>
                <View style={{ marginVertical: theme.getSpacing(1) }}>
                  <View style={styles.rowSpaceBetween}>
                    <Text style={styles.titleText}>
                      {getText('prescription')}
                    </Text>
                    {allMedications && allMedications.length > 0 && (
                      <Text style={styles.link} onPress={handleAddAnotherMed}>
                        {getText('add-another')}
                      </Text>
                    )}
                  </View>

                  {allMedications?.length ? (
                    allMedications.map((medication) => (
                      <View key={medication.rx_number}>
                        <View style={{ marginBottom: theme.getSpacing(1) }}>
                          <Divider />
                        </View>
                        <PrescriptionCard
                          checked={medication.checked}
                          showDetailsIcon={true}
                          selectable={true}
                          showRefillStatus={true}
                          onPress={handleSelectMedication}
                          onOpenDetailsPress={() =>
                            handleOpenDetailsPress(medication)
                          }
                          prescription={medication}
                        />
                      </View>
                    ))
                  ) : (
                    <>
                      <View style={{ marginBottom: theme.getSpacing(1) }}>
                        <Divider />
                      </View>
                      <MedicationsEmptyStateSmall
                        firstParagraph={getText(
                          'prescriptions-empty-state-paragraph-one',
                        )}
                        secondParagraph={getText(
                          'prescriptions-empty-state-paragraph-two',
                        )}
                        buttonText={getText(
                          'prescriptions-empty-state-button-text',
                        )}
                        onPress={handleAddAnotherMed}
                      />
                    </>
                  )}
                </View>
                <View
                  style={{
                    display: 'none',
                    marginVertical: theme.getSpacing(1),
                  }}
                >
                  <View style={styles.rowSpaceBetween}>
                    <Text style={styles.titleText}>
                      {getText('over-the-counter')}
                    </Text>
                    {overTheCounterMeds && overTheCounterMeds.length > 0 && (
                      <Text
                        style={styles.link}
                        onPress={handleAddAnotherOTCMed}
                      >
                        {getText('add-another')}
                      </Text>
                    )}
                  </View>
                  <View style={{ marginBottom: theme.getSpacing(1) }}>
                    <Divider />
                  </View>
                  {overTheCounterMeds && overTheCounterMeds.length > 0 ? (
                    overTheCounterMeds.map((otc, index) => (
                      <View key={index}>
                        <PrescriptionCard
                          showDetailsIcon={true}
                          checked={false}
                          selectable={true}
                          onPress={handleSelectMedication}
                          prescription={otc}
                          onOpenDetailsPress={() => handleOpenDetailsPress(otc)}
                        />
                      </View>
                    ))
                  ) : (
                    <MedicationsEmptyStateSmall
                      firstParagraph={getText('otc-empty-state-paragraph-one')}
                      secondParagraph={getText('otc-empty-state-paragraph-two')}
                      buttonText={getText('otc-empty-state-button-text')}
                      onPress={handleAddAnotherOTCMed}
                    />
                  )}
                </View>

                {orderAlertRef && <OrderMedicationsAlert ref={orderAlertRef} />}
              </>
            )}
          </>
        )}
      </InternalScreenContainer>
      <UnableToSubmit
        ref={unableToSubmitRef}
        handleUnableToSubmit={handleUnableToSubmit}
      />
      <Insurance
        ref={insuranceRef}
        handleInsuranceSubmit={handleInsuranceSubmit}
      />
      <PrescriptionDetails
        ref={prescriptionDetailsRef}
        onRefillButtonPress={onRefillButtonPress}
      />
      {Platform.OS !== 'web' && displayOrderButton && (
        <View style={styles.stickyButton}>
          <Button
            hierarchy="primary"
            testID={'order-button-id'}
            logger={{ id: 'order-button-id' }}
            icon={ShoppingCartIcon}
            onPress={handleOrderRefill}
          >
            {getText('order')}
          </Button>
        </View>
      )}
    </>
  );
};

export type MedicationsProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
  divider: {
    margin: theme.getSpacing(1),
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.getSpacing(2),
    gap: theme.getSpacing(0.5),
    backgroundColor: '#FEFBE8',
    border: '1px solid #BCC8CE',
    borderRadius: 6,
    marginVertical: theme.getSpacing(1),
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(2),
    marginTop: theme.getSpacing(1),
  },
  link: {
    textAlign: 'right',
    color: theme.palette.primary[600],
  },
  stickyButton: {
    position: 'absolute',
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    resizeMode: 'contain',
    right: 30,
    bottom: 30,
    alignSelf: 'flex-end',
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
  },
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '45%',
    marginVertical: theme.getSpacing(2),
  },
  helperText: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  focusLoadingIndicator: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: theme.getSpacing(2),
    backgroundColor: 'rgba(250, 250, 250, 0.3)',
    zIndex: 10,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: -20,
  },
}));
