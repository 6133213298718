import { getLocale } from 'assets/localization/localization';
import moment, { Moment } from 'moment-timezone';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY',
  DEFAULT_DATE_API_FORMAT = 'YYYY-MM-DD',
  DEFAULT_DATE_TIME_API_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  DEFAULT_TIME_FORMAT = 'hh:mm A',
  DATE_FORMAT_MMM_D = 'MMM D',
  DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';

// Setting CST timezone as a default one for Pharmacies
export const DEFAULT_PHARMACY_TIMEZONE = 'America/Chicago';

// Format date to the format expected by the API
export const formatDateTimeApi = (dateToFormat: Moment): string => {
  return moment.utc(dateToFormat).format(DEFAULT_DATE_TIME_API_FORMAT);
};

// Format date from the format expected by the API to UI
export const formatDateFromApi = (dateApiFormat: string): string => {
  return moment(dateApiFormat, DEFAULT_DATE_API_FORMAT).format(
    DEFAULT_DATE_FORMAT,
  );
};

// Format date from the formatted 'MM/DD/YYYY' to expected by the API
export const formatDateFromUI = (dateUiFormatted: string): string => {
  return moment(dateUiFormatted, DEFAULT_DATE_FORMAT).format(
    DEFAULT_DATE_API_FORMAT,
  );
};

// Convert UTC time to Pharmacy timezone and format
export const convertDateTimeFromUtcToPharmacyTimezone = (
  dateToFormat: string | Moment,
  pharmacyTimezone: string,
  format: string,
): string => {
  return moment.utc(dateToFormat).tz(pharmacyTimezone).format(format);
};

/**
 * Formats a date string to the preferred format
 */
export const formatDate = (
  valueToFormat: string,
  format: string = DEFAULT_DATE_FORMAT,
) => {
  return moment(valueToFormat).format(format);
};

export const formatDateTime = (
  valueToFormat: string,
  inputFormat: string,
  outputFormat: string,
) => {
  const dateTime = moment(valueToFormat, inputFormat)
    .local()
    .format(outputFormat);
  return dateTime;
};

export const timeAugmenter = (
  value: Date | number | undefined,
  unit: moment.unitOfTime.DurationConstructor,
  quantity: number,
) => {
  return moment(value).add(quantity, unit).toDate();
};

export const timeOppositeAugmenter = (
  value: Date | number | undefined,
  unit: moment.unitOfTime.DurationConstructor,
  quantity: number,
) => {
  return moment(value).subtract(quantity, unit).toDate();
};

// Format date string to MMM D format based on language
export const formatDateToMMMD = (dateValue: string): string => {
  const momentDate = moment(dateValue);
  const formattedDate = [
    momentDate.toDate().toLocaleString(getLocale(), {
      month: 'short',
    }),
    momentDate.toDate().getDate(),
  ].join(' ');

  return formattedDate;
};

export function toIsoString(dateString: string) {
  const date = new Date(dateString);
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num: number) => {
    return (num < 10 ? '0' : '') + num;
  };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
}

export const formatTimestampDate = (valueToFormat: string) => {
  // toIsoString() here to remove
  // Deprecation warning: value provided is not in a recognized ISO format.
  // moment construction falls back to js Date(), which is not reliable across all browsers and versions.
  // Non ISO date formats are discouraged and will be removed in an upcoming major release.
  // Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.
  const dateTime = moment(toIsoString(valueToFormat)).calendar(null, {
    sameDay: '[Today] h:mm A',
    lastDay: '[Yesterday] h:mm A',
    lastWeek: 'dddd h:mm A',
    sameElse: 'ddd, MMM D [at] h:mm A',
  });
  return dateTime;
};
