import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import {
  ScrollView,
  View,
  StyleProp,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';

export interface PharmacyScreenContainerHandler {
  scrollTo?(
    y?:
      | number
      | {
          x?: number | undefined;
          y?: number | undefined;
          animated?: boolean | undefined;
        },
    x?: number,
    animated?: boolean,
  ): void;
}

export const PharmacyScreenContainer = forwardRef<
  PharmacyScreenContainerHandler,
  PharmacyScreenContainerProps
>(
  (
    { children, backgroundColor, style, disableScrollView = true, small },
    ref,
  ) => {
    const theme = useTheme();
    const scrollViewRef = useRef<ScrollView>(null);
    const styles = useStyles();

    useImperativeHandle(ref, () => ({
      scrollTo: scrollViewRef.current?.scrollTo,
    }));

    const ViewRenderer = disableScrollView ? View : ScrollView;

    const { width } = useWindowDimensions();
    let maxWidth = width;
    if (width > theme.webMaxWidth) {
      maxWidth = theme.webMaxWidth;
    }

    return (
      <View
        style={[
          styles.root,
          { backgroundColor: backgroundColor ?? theme.palette.white },
        ]}
      >
        <ViewRenderer
          style={[
            small ? styles.smallContainer : styles.container,
            {
              backgroundColor: backgroundColor ?? theme.palette.white,
              width: small ? maxWidth - theme.getSpacing(2) : 'auto',
            },
            style,
          ]}
        >
          {children}
        </ViewRenderer>
      </View>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
  },
  root: {
    padding: theme.getSpacing(3),
    flex: 1,
    overflow: 'scroll',
  },
  smallContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    flexDirection: 'column',
    alignSelf: 'center',
  },
}));

export interface PharmacyScreenContainerProps {
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  children?: React.ReactNode;
  disableScrollView?: boolean;
  small?: boolean;
}
