import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useTheme } from 'assets/theme';
import { ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { useNavigation } from '@react-navigation/native';
import { StoreSelectorList } from '../../components/store-selector/list';
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from '../../navigation/AuthNavigation';
import { useAppStateStore } from '../../store/app-store';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { StoreSelectorInput } from '../../components/store-selector/input/StoreSelectorInput';
import { ScrollView, View } from 'react-native';
import { registerStore } from './register-actions';
import { Button } from 'assets/components/button';
import { handleLocationDistanceSearch } from '../../components/store-selector/actions';
import { loadStores } from '../../actions/app-actions';
import { LoadingIndicator } from 'assets/components/loading-indicator';

export const RegisterStore: FunctionComponent<
  PropsWithChildren<RegisterStoreProps>
> = () => {
  const theme = useTheme();
  const { stores, status } = useAppStateStore();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const [inputSearch, setInputSearch] = useState<string>('');
  const navigation = useNavigation<AuthStackNavigationProp>();

  useEffect(() => {
    void loadStores();
    return () => setInputSearch('');
  }, []);

  const handleOnChange = (value: PharmacyLocationDto) => {
    setSelectedStore(value);
  };

  const handleSubmit = () => {
    if (selectedStore) registerStore(selectedStore.id, navigation);
  };

  const handleOnInfoButtonPress = (value: PharmacyLocationDto) => {
    navigation.navigate('patient-pharmacy-stores', {
      storeId: value.id,
    } as any);
  };

  return (
    <ScreenContainer disableScrollView={true} useBottomInset>
      <View
        style={{
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
          marginBottom: theme.getSpacing(1),
        }}
      >
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 16,
            fontWeight: '600',
            lineHeight: 24,
            marginBottom: theme.getSpacing(1),
          }}
        >
          {getText('find-your-store')}
        </Text>
      </View>
      <View
        style={{
          marginBottom: theme.getSpacing(1),
        }}
      >
        <StoreSelectorInput onInputChange={(value) => setInputSearch(value)} />
      </View>
      <View
        style={{
          flex: 1,
        }}
      >
        {status === 'loading' ? (
          <View>
            <LoadingIndicator></LoadingIndicator>
          </View>
        ) : (
          <ScrollView style={{ marginBottom: theme.getSpacing(4) }}>
            <StoreSelectorList
              options={stores}
              selectedOption={selectedStore}
              onChange={handleOnChange}
              searchCriteria={inputSearch}
              onInfoButtonPress={handleOnInfoButtonPress}
              searchAction={handleLocationDistanceSearch}
            />
          </ScrollView>
        )}
      </View>
      <View>
        <Button
          hierarchy="primary"
          logger={{ id: 'patient-pharmacy-submit-button' }}
          onPress={handleSubmit}
        >
          {getText('set-my-store')}
        </Button>
      </View>
    </ScreenContainer>
  );
};

type RegisterStoreProps = NativeStackScreenProps<
  AuthStackParamList,
  'patient-stores'
>;
