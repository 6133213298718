import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Linking, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { TECH_SUPPORT_EMAIL } from '../../../../../common/constants';
import { useAppStateStore } from '../../../../../store/app-store';
import { useUserState } from '../../../../../store/user-store';

export const TechSupport: FunctionComponent<
  PropsWithChildren<TechSupportProps>
> = () => {
  const styles = useStyles();
  const { pharmacyName, stores } = useAppStateStore();
  const user = useUserState((x) => x.user);

  const handleTechSupportOnPress = () => {
    const preferredLocation = stores.find(
      (x) => x.id === user?.preferredPharmacyLocationId,
    )?.name;

    let preFilledInfo = `App name: ${
      Constants.expoConfig?.name || 'Not Available'
    }\nApp version: ${
      Constants.expoConfig?.version || 'Not Available'
    }\nDevice info: ${
      Device.modelName || 'Not Available'
    }\nDevice OS + version: ${Device.osName} ${Device.osVersion}\n`;
    if (Platform.OS === 'web') {
      preFilledInfo += `Browser info: ${Constants.deviceName}\n`;
    }
    preFilledInfo += `Pharmacy name + location: ${pharmacyName}, ${preferredLocation}\nUser's email address: ${
      user?.email || 'Not Available'
    }`;

    let preFilledSubjectHeader = `${
      Device.modelName || 'Device Not Available'
    } Tech Support Request | ${
      Constants.expoConfig?.name || 'Pharmacy Name Not Available'
    }`;

    const emailBody = `
    \n\n\n\n\n\n${preFilledInfo}
  `;

    const encodedEmailBody = encodeURIComponent(emailBody);
    const encodedEmailSubject = encodeURIComponent(preFilledSubjectHeader);
    const mailtoURL = `mailto:${TECH_SUPPORT_EMAIL}?subject=${encodedEmailSubject}&body=${encodedEmailBody}`;
    void Linking.openURL(mailtoURL);
  };

  return (
    <TouchableOpacity onPress={handleTechSupportOnPress}>
      <Text style={styles.subtitle}>{getText('tech-support')}</Text>
    </TouchableOpacity>
  );
};

export type TechSupportProps = {};

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.gray[700],
    fontSize: 18,
    lineHeight: 28,
    fontFamily: theme.fonts.regular.fontFamily,
    cursor: 'pointer',
  },
}));
