import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import type {
  NativeStackScreenProps,
  NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import { InternalScreenContainer, ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { Surface } from 'react-native-paper';
import {
  LinkedAccountsTab,
  PatientUnderCareEditDetailsEnum,
  usePatientRecordState,
  usePatientSubmissionsState,
} from '../../../patient-store';
import { PatientUnderCareStackParamList } from './PatientUnderCareNavigation';
import {
  PatientUnderCareForm,
  refreshCaregiverRequestsState,
  removeRecordUnderCare,
  resetRecordUnderCareState,
  updatePatientUnderCareForm,
} from '../../../patient-actions';
import {
  PatientRecordDto,
  PatientUnderCareRelationship,
  RecordUnderCareDto,
  UpdateAddressDto,
  UpdatePatientRecordDto,
  UpdateRecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import patientService from '../../../../../../api/patient-service';
import {
  DateTimeFormat,
  formatDateStringAsISO,
  formatISODateAsString,
} from '../../../../../../common/form-utils';
import { UseFormReturn, useForm } from 'react-hook-form';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { AsyncStatus } from '../../../../../../store/types';

import { Icon } from 'assets/components/icon';
import {
  AddPersonIcon,
  CalendarIcon,
  DocumentIcon,
  MedicationTransferIcon,
  PlusIcon,
} from 'assets/icons';
import {
  NavigationProp,
  ParamListBase,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { AppointmentDetailsScreenRouteProp } from '../../../../../../navigation/AppNavigation';
import { PrescriptionCard } from '../../../../../../components/prescription-card';
import { useUserState } from '../../../../../../store/user-store';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { useAppStateStore } from '../../../../../../store/app-store';
import {
  BookingPageDto,
  SortOrder,
} from '@digitalpharmacist/appointment-service-client-axios';
import appointmentService from '../../../../../../api/appointment-service';
import refillService from '../../../../../refill/refill-service';
import { formatDateToMMMD } from '../../../../../../common/datetime-utils';
import { CountBadge } from 'assets/components/count-badge/CountBadge';
import { useRefillMedicationsStore } from '../../../../../refill/refill-store';
import moment from 'moment';
import { logError } from 'assets/logging/logger';
import { PersonalInfoMdl } from '../../record-under-care-info/PersonalInfoModal';
import { PrescriptionDetailsHandler } from '../../../../../../components/prescription-details/PrescriptionDetailsProps';
import formsService from '../../../../../../api/forms-service';
import { SubmissionCoreMetadataDto } from '@digitalpharmacist/forms-service-client-axios';
import { AccountStackNavigationProp } from '../../../../AccountNavigation';
import { ConfirmDeleteModal } from '../../confirm-delete-action/ConfirmDeleteActionModal';
import { EmptyStatePage } from '../../../../../../components/empty-state-page/EmptyStatePage';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { IconButton } from 'assets/components/icon-button';
import { useFormattedRelationship } from '../../../hooks/useFormattedRelationship';
import { PrescriptionDetails } from '../../../../../../components/prescription-details';

export const PatientUnderCareList: FunctionComponent<
  PropsWithChildren<PatientUnderCareListProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { recordsUnderCare, caregiverRequests, status } =
    usePatientRecordState();

  useFocusEffect(
    useCallback(() => {
      void refreshCaregiverRequestsState();
      usePatientRecordState.setState({
        status: 'success',
        linkedAccountsSelectedTab: LinkedAccountsTab.Pucs,
      });
    }, []),
  );

  const onPress = () => {
    navigation.navigate('add-patient-under-care-info');
  };

  const handleActionPress = () => {
    void resetRecordUnderCareState();
    navigation.navigate('add-patient-under-care-info');
  };

  if (status !== 'success') {
    return (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator></LoadingIndicator>
      </View>
    );
  } else {
    return caregiverRequests.length == 0 && recordsUnderCare.length == 0 ? (
      <InternalScreenContainer>
        <EmptyStatePage
          icon={AddPersonIcon}
          iconColor={theme.palette.white}
          title={getText('people-under-your-care')}
          description={getText('people-under-your-care-empty-page')}
          buttonText={getText('add-person-or-pet')}
          onPress={onPress}
        />
      </InternalScreenContainer>
    ) : (
      <ScreenContainer style={styles.container}>
        {Platform.OS === 'web' && (
          <View style={styles.header}>
            <Text style={styles.title}>
              {getText('people-under-your-care')}
            </Text>
            <IconButton
              icon={PlusIcon}
              logger={{ id: 'plusIcon' }}
              onPress={handleActionPress}
            />
          </View>
        )}
        {recordsUnderCare.map((record) => (
          <View key={record.id} style={styles.container}>
            <InfoCard
              name={`${record.record_under_care.first_name} ${record.record_under_care.last_name}`.trim()}
              date={formatISODateAsString(
                record.record_under_care.date_of_birth,
                DateTimeFormat.USDateFormat,
              )}
              relationship={record.relationship}
              submittedDate={record.created_at}
              isRequest={false}
              record={record}
              status={status}
              navigation={navigation}
            />
          </View>
        ))}
        {caregiverRequests.map((caregiver) => (
          <View key={caregiver.id} style={styles.container}>
            <RequestInfoCard
              name={`${caregiver.first_name} ${caregiver.last_name}`.trim()}
              dob={formatISODateAsString(
                caregiver.date_of_birth,
                DateTimeFormat.USDateFormat,
              )}
              relationship={caregiver.relationship}
              status={status}
              submittedDate={formatDateToMMMD(caregiver.created_at)}
            />
          </View>
        ))}
      </ScreenContainer>
    );
  }
};

const RequestInfoCard: FunctionComponent<
  PropsWithChildren<{
    name?: string;
    dob?: string;
    relationship?: string;
    status: AsyncStatus;
    submittedDate: string;
  }>
> = ({ name, dob, relationship, status, submittedDate }) => {
  const theme = useTheme();
  const styles = useStyles();

  const formattedRelationship = useFormattedRelationship(relationship);

  return (
    <Surface
      style={[
        {
          elevation: 2,
          overflow: 'hidden',
          backgroundColor: theme.palette.white,
          minHeight: 100,
          width: '100%',
          marginBottom: theme.getSpacing(1),
        },
      ]}
    >
      <View style={{ margin: theme.getSpacing(2) }}>
        <View style={styles.requestTextContainer}>
          <Text style={styles.detailsText}>{name}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginBottom: theme.getSpacing(1),
          }}
        >
          <Text style={styles.otherDetailsText}>{dob}</Text>
          <Text
            style={[
              styles.otherDetailsText,
              { paddingLeft: theme.getSpacing(0.5) },
            ]}
          >
            {'('}
            {formattedRelationship}
            {')'}
          </Text>
        </View>
        <View style={styles.authorizationContainer}>
          <View style={styles.rightSpacing}>
            <Icon
              icon={CalendarIcon}
              color={theme.palette.warning[500]}
              size={14}
            ></Icon>
          </View>
          <Text style={styles.requestPendingStyle}>
            {getText('request-status', {
              submittedDate: submittedDate,
            })}
          </Text>
        </View>
      </View>
    </Surface>
  );
};
//TODO: Change this to use the InfoCardComponent on shared components file

const InfoCard: FunctionComponent<
  PropsWithChildren<{
    name?: string;
    date?: string;
    relationship?: string;
    submittedDate?: string; //leaving this but in the new figma design we don't show submitted data like we did earlier
    isRequest: boolean;
    record: RecordUnderCareDto;
    status: AsyncStatus;
    navigation: NativeStackNavigationProp<
      PatientUnderCareStackParamList,
      'linked-accounts',
      undefined
    >;
  }>
> = ({ name, date, relationship, isRequest, record, status, navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isWeb = Platform.OS === 'web';
  const nativeRef = React.useRef<BottomSheetModal>(null);
  const [showModal, setShowModal] = useState(false);
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();
  const [viewMode, switchViewMode] = useState(false);
  const [title, setTitle] = useState(getText('patient-info'));
  const [editDetails, setWhichEditDetails] = useState(
    PatientUnderCareEditDetailsEnum.PersonalInfo,
  );
  const methods = useForm<PatientUnderCareForm>({});
  const [form, setForm] =
    useState<UseFormReturn<PatientUnderCareForm>>(methods);
  const appNav = useNavigation<AppointmentDetailsScreenRouteProp>();
  const { pharmacyId } = useAppStateStore();
  const { user } = useUserState();
  const [pucMedications, setPucMedications] = useState<PrescriptionDto[]>();
  const [pucAppointments, setPucAppointments] = useState<BookingPageDto>();
  const [pucSubmissions, setPucSubmissions] =
    useState<SubmissionCoreMetadataDto[]>();
  const [statusText, setStatusText] = useState(getText('retrieving')); //TODO error case handling
  const nav = useNavigation<NavigationProp<ParamListBase>>();
  const AccNavigation = useNavigation<AccountStackNavigationProp>();
  const { updateMedicationsData } = useRefillMedicationsStore();
  const editPersonalInfoRef = React.useRef<BaseModalHandler>(null);
  const [loading, setLoading] = useState(true);
  const prescriptionDetailsRef = React.useRef<PrescriptionDetailsHandler>(null);
  const confirmDeletePatientModalRef = React.useRef<BaseModalHandler>(null);

  const formattedRelationship = useFormattedRelationship(relationship);

  useEffect(() => {
    loadPUCDetails();
    refreshPatientRecord()
      .catch((e) => {
        logError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleRemovePatientUnderCare = async (record?: RecordUnderCareDto) => {
    // TODO Do we need error handling? This probably applies more broadly to these types of calls. (Error handling)

    if (!record) throw new Error('No patient record has been found');
    await removeRecordUnderCare(record.caregiver_id, record.id);
    await setVisibility(false);
    confirmDeletePatientModalRef.current?.hide();
  };

  const handleHideConfirmDeleteModal = () => {
    confirmDeletePatientModalRef.current?.hide();
    showEditPersonalModal();
  };

  const showConfirmDeleteModal = () => {
    confirmDeletePatientModalRef.current?.show();
    hideEditPersonalModal();
  };

  const loadPUCDetails = async () => {
    if (!user?.preferredPharmacyLocationId) {
      throw new Error(getText('store-selector-no-stores-error'));
    }
    const currentLocationId = user.preferredPharmacyLocationId;
    const filtered_location_patient_records =
      record.location_patient_records.filter(
        (minLPR) => minLPR.location_id === currentLocationId,
      );
    setPucMedications(undefined);
    //upcoming TODO parallelize these
    if (filtered_location_patient_records.length === 0) {
      setPucMedications([]);
    } else {
      const location_patient_record_id =
        filtered_location_patient_records[0].id;
      const medication_list: PrescriptionDto[] =
        await refillService.getPatientPrescriptions(
          user.preferredPharmacyLocationId,
          location_patient_record_id,
        );
      setPucMedications(medication_list);
    }

    setPucAppointments(undefined);

    const upcoming_appointment: BookingPageDto =
      await appointmentService.findBookings(
        pharmacyId,
        currentLocationId,
        0,
        7,
        SortOrder.Asc,
        {
          patientRecordId: record.record_under_care.id,
          minEndDate: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        },
      );

    setPucAppointments(upcoming_appointment);

    setPucSubmissions(undefined);
    const submission_list: SubmissionCoreMetadataDto[] =
      await formsService.getUserSubmissions(record.record_under_care.id);
    setPucSubmissions(submission_list);

    usePatientRecordState.setState({ status: 'success' });
  };

  const setVisibility = async (visible: boolean) => {
    form.clearErrors();
    if (visible) await refreshPatientRecord();
    else {
      changeViewMode(false);
    }
    if (Platform.OS === 'web') {
      setShowModal(visible);
    } else {
      if (!visible) {
        nativeRef.current?.dismiss();
      } else {
        nativeRef.current?.present();
      }
    }
  };

  const showEditPersonalModal = async () => {
    form.clearErrors();
    changeViewMode(false);
    await refreshPatientRecord()
      .catch((e) => {
        logError(e);
      })
      .finally(() => {
        setLoading(false);
        editPersonalInfoRef.current?.show();
      });
  };

  const hideEditPersonalModal = () => {
    changeViewMode(false);
    editPersonalInfoRef.current?.hide();
  };

  const handleOnMedsPress = () => {
    if (updateMedicationsData)
      updateMedicationsData({
        selectedPatientRecordId: record.record_under_care.id,
      });
    nav.navigate('app', {
      screen: 'medications',
      params: { screen: 'medications' },
    });
  };

  const handleOnSubmissionPress = (
    pucSubmissions: SubmissionCoreMetadataDto[],
  ) => {
    usePatientSubmissionsState.setState({
      submissions: pucSubmissions,
    });
    navigation.navigate('patient-under-care-forms', {
      patient_id: record.record_under_care.id,
    });
  };

  const handleOnAppointmentPress = () => {
    appNav.navigate('appointments');
  };

  const refreshPatientRecord = async () => {
    if (!record.record_under_care) throw new Error('No record id');
    else {
      setPatientRecord(
        await patientService.findPatientRecord(record.record_under_care.id),
      );
    }
  };

  const updatePatientRecord = async (updatePR: UpdatePatientRecordDto) => {
    if (patientRecord)
      setPatientRecord(
        await patientService.updatePatientRecord(patientRecord.id, updatePR),
      );
  };

  const handleSave = (updatePR: UpdatePatientRecordDto) => {
    updatePatientRecord(updatePR).catch((error) => {
      //TODO: [ERROR_HANDLING] please review this
      logError(error); //TODO Error handling
    });
  };

  const handleSubmit = async () => {
    if (PatientUnderCareEditDetailsEnum.PersonalInfo === editDetails) {
      updatePatientUnderCareForm(form.getValues());
      const updatedFormValues = form.getValues();
      if (patientRecord) {
        if (record.relationship !== form.getValues().relationship) {
          const updateRecordUnderCare: UpdateRecordUnderCareDto = {
            relationship: form.getValues().relationship,
          };
          await patientService.recordUnderCareUpdate(
            patientRecord.id,
            record.id,
            updateRecordUnderCare,
          );
        }
      }
      const addressDto: UpdateAddressDto = {};
      if (updatedFormValues.address1 !== '')
        addressDto.address1 = updatedFormValues.address1;
      if (updatedFormValues.address2 !== '')
        addressDto.address2 = updatedFormValues.address2;
      if (updatedFormValues.city !== '')
        addressDto.city = updatedFormValues.city;
      if (updatedFormValues.state !== '')
        addressDto.state = updatedFormValues.state;
      if (updatedFormValues.postal_code !== '')
        addressDto.postal_code = updatedFormValues.postal_code;
      if (
        updatedFormValues.address1 !== '' &&
        updatedFormValues.city !== '' &&
        updatedFormValues.state !== '' &&
        updatedFormValues.postal_code !== '' &&
        updatedFormValues.country !== ''
      ) {
        addressDto.country = updatedFormValues.country;
      }
      const isAddressComplete =
        updatedFormValues.address1 &&
        updatedFormValues.city &&
        updatedFormValues.country &&
        updatedFormValues.state &&
        updatedFormValues.postal_code
          ? true
          : false;
      const updatePatientRecord: UpdatePatientRecordDto = {
        first_name: updatedFormValues.first_name,
        last_name: updatedFormValues.last_name,
        date_of_birth: formatDateStringAsISO(
          updatedFormValues.date_of_birth,
          DateTimeFormat.USDateFormat,
        ),
        gender: updatedFormValues.gender,
        address: isAddressComplete ? addressDto : null,
      };
      const isFormValid = await form.trigger([
        'address1',
        'city',
        'postal_code',
        'state',
        'country',
        'first_name',
        'last_name',
        'date_of_birth',
      ]);
      if (isFormValid) {
        handleSave(updatePatientRecord);
        changeViewMode(false);
        await refreshCaregiverRequestsState();
      }
    } else if (PatientUnderCareEditDetailsEnum.MedicalInfo === editDetails) {
      const isFormValid = await form.trigger(['allergies']);
      if (isFormValid) {
        handleMedicalInfoSubmit();
        changeViewMode(false);
      }
    }
  };

  const handleMedicalInfoSubmit = () => {
    changeViewMode(false);

    const { allergies, prefers_easy_open_bottle_caps, medical_conditions } =
      form.getValues();

    handleSave({
      allergies,
      prefers_easy_open_bottle_caps,
      medical_conditions,
    });
  };

  const whichDetailsToEdit = (editType: PatientUnderCareEditDetailsEnum) => {
    if (editType === PatientUnderCareEditDetailsEnum.PersonalInfo) {
      setTitle(getText('personal-info'));
    } else if (editType === PatientUnderCareEditDetailsEnum.MedicalInfo) {
      setTitle(getText('medical-info'));
    }
    setWhichEditDetails(editType);
  };

  const onEditPress = (
    editDetail: PatientUnderCareEditDetailsEnum,
    methods: UseFormReturn<PatientUnderCareForm>,
  ) => {
    if (patientRecord?.first_name)
      methods.setValue('first_name', patientRecord.first_name);
    if (patientRecord?.last_name)
      methods.setValue('last_name', patientRecord.last_name);
    if (patientRecord?.date_of_birth)
      methods.setValue(
        'date_of_birth',
        formatISODateAsString(
          patientRecord.date_of_birth,
          DateTimeFormat.USDateFormat,
        ),
      );
    if (record.relationship)
      methods.setValue('relationship', record.relationship);
    if (patientRecord?.gender) methods.setValue('gender', patientRecord.gender);
    if (patientRecord?.address) {
      methods.setValue(
        'address1',
        patientRecord.address.address1 ? patientRecord.address.address1 : '',
      );
      methods.setValue(
        'address2',
        patientRecord.address.address2 ? patientRecord.address.address2 : '',
      );
      methods.setValue(
        'city',
        patientRecord.address.city ? patientRecord.address.city : '',
      );
      methods.setValue(
        'state',
        patientRecord.address.state ? patientRecord.address.state : '',
      );
      methods.setValue(
        'country',
        patientRecord.address.country ? patientRecord.address.country : '',
      );
      methods.setValue(
        'postal_code',
        patientRecord.address.postal_code
          ? patientRecord.address.postal_code
          : '',
      );
    } else {
      methods.setValue('address1', '');
      methods.setValue('address2', '');
      methods.setValue('city', '');
      methods.setValue('state', '');
      methods.setValue('country', 'US');
      methods.setValue('postal_code', '');
    }
    if (patientRecord?.medical_conditions)
      methods.setValue('medical_conditions', patientRecord.medical_conditions);
    if (patientRecord?.allergies) {
      methods.setValue('allergies', patientRecord.allergies);
    }
    if (
      patientRecord?.prefers_easy_open_bottle_caps !== null &&
      patientRecord?.prefers_easy_open_bottle_caps !== undefined
    ) {
      methods.setValue(
        'prefers_easy_open_bottle_caps',
        patientRecord.prefers_easy_open_bottle_caps,
      );
    }

    changeViewMode(true);
    whichDetailsToEdit(editDetail);
    setForm(methods);

    return methods;
  };

  const relationshipProps: Array<{ label: string; value: string }> = [
    {
      label: getText('child'),
      value: PatientUnderCareRelationship.Child,
    },
    {
      label: getText('spouse'),
      value: PatientUnderCareRelationship.Spouse,
    },
    {
      label: getText('parent'),
      value: PatientUnderCareRelationship.Parent,
    },
    {
      label: getText('grandparent'),
      value: PatientUnderCareRelationship.Grandparent,
    },
    {
      label: getText('grandchild'),
      value: PatientUnderCareRelationship.Grandchild,
    },
    {
      label: getText('partner'),
      value: PatientUnderCareRelationship.Partner,
    },
    {
      label: getText('pet'),
      value: PatientUnderCareRelationship.Pet,
    },
    {
      label: getText('other'),
      value: PatientUnderCareRelationship.Other,
    },
  ];

  const changeViewMode = (editMode: boolean) => {
    switchViewMode(editMode);
  };

  const handleOpenPrescriptionDetailsPress = (
    prescription: PrescriptionDto,
  ) => {
    prescriptionDetailsRef.current?.show(prescription);
  };

  const filterAppointments = pucAppointments?.results;

  return (
    <Surface
      style={[
        {
          elevation: 2,
          overflow: 'hidden',
          backgroundColor: theme.palette.white,
          minHeight: 100,
          width: '100%',
          marginBottom: theme.getSpacing(1),
        },
      ]}
    >
      <View style={styles.bottomLineBold}>
        <View style={styles.requestTextContainer}>
          <Text style={styles.detailsText}>{name}</Text>
          {!isRequest && (
            <TouchableOpacity
              style={styles.textContainer}
              onPress={() => showEditPersonalModal()}
            >
              <Text
                style={[
                  styles.pressableTextRevoke,
                  styles.leftSpacing,
                  isWeb && styles.pointer,
                ]}
              >
                {getText('details')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginBottom: theme.getSpacing(2),
          }}
        >
          <Text style={styles.otherDetailsText}>{date}</Text>
          <Text
            style={[
              styles.otherDetailsText,
              { paddingLeft: theme.getSpacing(0.5) },
            ]}
          >
            {'('}
            {formattedRelationship}
            {')'}
          </Text>
        </View>
      </View>
      <View style={styles.viewMargin}>
        <View style={styles.requestTextContainer}>
          <View style={styles.rowDirection}>
            <View style={styles.rightSpacing}>
              <Icon
                icon={CalendarIcon}
                color={theme.palette.gray[400]}
                size={18}
              ></Icon>
            </View>
            <Text style={styles.nameText}>{getText('appointments')}</Text>
            {filterAppointments && filterAppointments.length > 0 && (
              <View style={{ marginLeft: theme.getSpacing(1) }}>
                <CountBadge
                  backgroundColor={theme.palette.gray[200]}
                  color={theme.palette.gray[900]}
                  count={filterAppointments.length}
                ></CountBadge>
              </View>
            )}
          </View>
          <TouchableOpacity
            onPress={() => handleOnAppointmentPress()}
            style={styles.textContainer}
          >
            <Text
              style={[
                styles.pressableTextRevoke,
                styles.leftSpacing,
                isWeb && styles.pointer,
              ]}
            >
              {getText('view')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.marginLeft}>
          {filterAppointments ? (
            filterAppointments.length > 0 ? (
              filterAppointments
                .map((appointment) => (
                  <View key={appointment.id} style={{ marginLeft: 1 }}>
                    <Text style={styles.textDetails}>
                      {getText('coming-up')}{' '}
                      {formatDateToMMMD(appointment.startTime)}
                    </Text>
                  </View>
                ))
                .at(0)
            ) : (
              <View style={{ marginLeft: 1 }}>
                <Text style={styles.textDetails}>
                  {getText('no-upcoming-appointments')}
                </Text>
              </View>
            )
          ) : (
            <View style={{ marginLeft: 1 }}>
              <Text style={styles.textDetails}>{statusText}</Text>
            </View>
          )}

          <View style={styles.bottomLineThin}></View>
        </View>
      </View>
      <View style={styles.viewMargin}>
        <View style={styles.requestTextContainer}>
          <View style={styles.rowDirection}>
            <View style={styles.rightSpacing}>
              <Icon
                icon={DocumentIcon}
                color={theme.palette.gray[400]}
                size={18}
              ></Icon>
            </View>
            <Text style={styles.nameText}>{getText('forms')}</Text>
          </View>
          {pucSubmissions && pucSubmissions.length > 0 ? (
            <TouchableOpacity
              onPress={() => handleOnSubmissionPress(pucSubmissions)}
              style={styles.textContainer}
            >
              <Text
                style={[
                  styles.pressableTextRevoke,
                  styles.leftSpacing,
                  isWeb && styles.pointer,
                ]}
              >
                {getText('view')}
              </Text>
            </TouchableOpacity>
          ) : null}
        </View>
        <View style={styles.marginLeft}>
          {pucSubmissions ? (
            pucSubmissions.length > 0 ? (
              pucSubmissions
                .map((submission) => (
                  <View
                    key={submission.submission_id}
                    style={styles.textDetailsContainer}
                  >
                    <Text style={styles.textDetails}>
                      {`${getText('last-submission')}: ${moment(
                        submission.created_at,
                        'YYYY-MM-DD',
                      ).format('MMM D, YYYY')}`}
                    </Text>
                  </View>
                ))
                .at(0)
            ) : (
              <View style={styles.textDetailsContainer}>
                <Text style={styles.textDetails}>
                  {getText('no-forms-to-display')}
                </Text>
              </View>
            )
          ) : (
            <View style={styles.textDetailsContainer}>
              <Text style={styles.textDetails}>{statusText}</Text>
            </View>
          )}

          <View style={styles.bottomLineThin}></View>
        </View>
      </View>
      <View style={styles.viewMargin}>
        <View style={styles.requestTextContainer}>
          <View style={styles.rowDirection}>
            <View style={styles.rightSpacing}>
              <Icon
                icon={MedicationTransferIcon}
                color={theme.palette.gray[500]}
                size={16}
              ></Icon>
            </View>
            <Text style={styles.nameText}>{getText('medications')}</Text>
            {pucMedications && pucMedications.length > 0 && (
              <View style={{ marginLeft: theme.getSpacing(1) }}>
                <CountBadge
                  backgroundColor={theme.palette.gray[200]}
                  color={theme.palette.gray[900]}
                  count={pucMedications.length}
                ></CountBadge>
              </View>
            )}
          </View>
          <TouchableOpacity
            onPress={() => handleOnMedsPress()}
            style={styles.textContainer}
          >
            <Text
              style={[
                styles.pressableTextRevoke,
                styles.leftSpacing,
                isWeb && styles.pointer,
              ]}
            >
              {getText('view')}
            </Text>
          </TouchableOpacity>
        </View>
        {pucMedications ? (
          pucMedications.length > 0 ? (
            pucMedications.slice(0, 3).map((medication) => (
              <View
                key={medication.rx_number}
                style={{ marginLeft: theme.getSpacing(3) }}
              >
                <View
                  style={{ marginBottom: theme.getSpacing(1), marginLeft: 4 }}
                >
                  <PrescriptionCard
                    showDetailsIcon={true}
                    showRefillStatus={true}
                    pucStyle={true}
                    showStatus={true}
                    prescription={medication}
                    onOpenDetailsPress={() =>
                      handleOpenPrescriptionDetailsPress(medication)
                    }
                  />
                </View>
              </View>
            ))
          ) : (
            <View style={{ marginLeft: 4 }}>
              <Text style={styles.textDetails}>
                {getText('no-refills-due')}
              </Text>
            </View>
          )
        ) : (
          <View style={{ marginLeft: 4 }}>
            <Text style={styles.textDetails}>{statusText}</Text>
          </View>
        )}
      </View>
      {loading ? (
        <LoadingIndicator />
      ) : (
        patientRecord && (
          <PersonalInfoMdl
            ref={editPersonalInfoRef}
            handleRemovePatientUnderCare={showConfirmDeleteModal}
            record={record}
            patientRecord={patientRecord}
            changeViewMode={changeViewMode}
            viewMode={viewMode}
            relationshipProps={relationshipProps}
            handleSave={() => {}}
            handleSubmit={() => handleSubmit()}
            onEditPress={onEditPress}
            whichDetailsToEdit={whichDetailsToEdit}
            editDetails={editDetails}
            title={title}
            form={form}
            subtitle={getText('basic-info')}
          />
        )
      )}
      <ConfirmDeleteModal
        ref={confirmDeletePatientModalRef}
        height={'45%'}
        title={getText('remove-patient') + '?'}
        subtitle={getText('confirm-remove-patient', {
          firstName: record.record_under_care.first_name,
          lastName: record.record_under_care.last_name,
        })}
        buttons={[
          {
            text: getText('remove'),
            hierarchy: 'destructive',
            onPress: () => {
              handleRemovePatientUnderCare(record);
            },
            logger: { id: 'remove-patient-modal-send-button' },
          },
          {
            onPress: () => handleHideConfirmDeleteModal(),
            logger: { id: 'remove-patient-modal-cancel-button' },
            text: getText('cancel'),
            hierarchy: 'secondary-gray',
          },
        ]}
      />
      <PrescriptionDetails ref={prescriptionDetailsRef} />
    </Surface>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    backgroundColor: theme.palette.gray[100],
    width: '100%',
  },
  emptyContainer: {
    marginTop: theme.getSpacing(4),
    alignItems: 'center',
    paddingTop: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    display: 'flex',
  },
  emptyTextTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  textDetails: {
    marginTop: 2,
    marginLeft: theme.getSpacing(3),
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[600],
  },
  textDetailsContainer: {
    marginLeft: theme.getSpacing(0.5),
  },
  text: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(2),
    paddingStart: theme.getSpacing(1),
    paddingEnd: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
  },
  emptyText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
    textAlign: 'center',
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  rowDirection: {
    flexDirection: 'row',
  },
  otherDetailsText: {
    color: theme.palette.gray[700],
    marginTop: theme.getSpacing(1),
  },
  marginLeft: { marginLeft: theme.getSpacing(0.5) },
  detailsText: {
    fontWeight: '600',
    fontSize: 24,
    lineHeight: 26,
    color: theme.palette.gray[700],
  },
  rightSpacing: {
    marginRight: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(0.5),
  },
  authorizationContainer: {
    flexDirection: 'row',
  },
  requestApprovedText: {
    color: theme.palette.gray[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(0.5),
  },
  nameText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
  },
  bottomLineBold: {
    margin: theme.getSpacing(2),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  bottomLineThin: {
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 0.5,
    marginLeft: theme.getSpacing(3),
    marginTop: theme.getSpacing(2),
  },
  viewMargin: {
    margin: theme.getSpacing(2),
  },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
  requestPendingStyle: {
    color: theme.palette.warning[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '45%',
    marginVertical: theme.getSpacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(1),
  },
  title: {
    ...theme.lumistryFonts.label.xxLarge.bold,
  },
}));

type PatientUnderCareListProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  'linked-accounts'
>;
