import type { StackNavigationProp } from '@react-navigation/stack';
import {
  StackHeaderProps,
  createStackNavigator,
} from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { ConversationBox } from './ConversationBox';
import { Messages } from './Messages';

const Stack = createStackNavigator<MessagesStackParamList>();
export const MessagesNavigation: FunctionComponent<
  PropsWithChildren<MessagesNavigationProps>
> = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="home"
        component={Messages}
        options={{ title: getText('messages'), headerShown: false }}
      />
      <Stack.Screen
        name="conversation-box"
        component={ConversationBox}
        options={{ title: getText('messages') }}
      />
    </Stack.Navigator>
  );
};
export type MessagesNavigationProps = StackHeaderProps;
export type MessagesStackParamList = {
  home: undefined;
  'conversation-box': {
    conversationId: string;
    locationPatientRecordId: string;
    title: string;
  };
};

export type MessagesStackNavigationProp =
  StackNavigationProp<MessagesStackParamList>;
