import React, { FunctionComponent, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { Text } from 'assets/components/text';
import { InternalScreenContainer } from 'assets/layout';
import { useAppStateStore } from '../../store/app-store';
import { Icon } from 'assets/components/icon';
import { LocationIcon, NewMessageIcon } from 'assets/icons';
import { LandingHeader } from '../../components/landing-header';
import { Button } from 'assets/components/button';
import { makeStyles, useTheme } from 'assets/theme';
import { NewMessageHandler } from './MessageProps';
import { NewMessage } from './NewMessage';
import { MessagesEmptyState } from './MessagesEmptyState';
import { getText } from 'assets/localization/localization';
import { ConversationItem } from './ConversationItem';
import { useMessagesState } from './messages-store';
import { setShowMessageModal, buildMessageList } from './messages-actions';
import { LoadingIndicator } from 'assets/components/loading-indicator';

export const Messages: FunctionComponent = () => {
  const pharmacyName = useAppStateStore((state) => state.pharmacyName);

  const theme = useTheme();
  const newMessageRef = React.useRef<NewMessageHandler>(null);
  const styles = useStyles();
  const showModal = useMessagesState((state) => state.showModal);
  const rawConversations = useMessagesState((state) => state.rawConversations);
  const viewedConversationsSet = useMessagesState(
    (state) => state.viewedConversationsSet,
  );
  const isLoading = useMessagesState((state) => state.isLoading);

  const { is_patient_start_chat_available } = useAppStateStore();

  useEffect(() => {
    if (showModal) {
      handleShowNewMessageModal();
    }
  }, [showModal]);

  const handleShowNewMessageModal = () => {
    newMessageRef.current?.show();
    setShowMessageModal(false);
  };

  const onMessageCreate = async () => {
    await buildMessageList();
  };

  const NewMessageButton = () => {
    return (
      is_patient_start_chat_available && (
        <View style={styles.buttonContainerMobile}>
          <Button
            hierarchy={'primary'}
            logger={{
              id: 'new-message-modal-button',
              data: undefined,
            }}
            icon={NewMessageIcon}
            onPress={handleShowNewMessageModal}
          >
            {getText('new-message')}
          </Button>
        </View>
      )
    );
  };

  return (
    <View style={styles.container}>
      {Platform.OS !== 'web' && (
        <LandingHeader
          pharmacyName={pharmacyName}
          greetings={getText('messages')}
        />
      )}
      <InternalScreenContainer
        showFooter
        buttons={
          Platform.OS === 'web' && is_patient_start_chat_available
            ? [
                {
                  hierarchy: 'primary',
                  logger: {
                    id: 'new-message-modal-button',
                    data: undefined,
                  },
                  icon: NewMessageIcon,
                  onPress: handleShowNewMessageModal,
                  text: getText('new-message'),
                },
              ]
            : []
        }
      >
        <>
          <View style={styles.locationContainer}>
            <Text style={styles.label}>{getText('you-are-chatting-with')}</Text>
            <View style={styles.pharmacyNameContainer}>
              <Icon
                icon={LocationIcon}
                size={16}
                color={theme.palette.gray[500]}
              />
              <Text style={styles.pharmacyName}>{pharmacyName}</Text>
            </View>
          </View>
          <NewMessage ref={newMessageRef} onMessageCreate={onMessageCreate} />
          {rawConversations.length === 0 ? (
            <MessagesEmptyState />
          ) : (
            <>
              {isLoading ? (
                <LoadingIndicator />
              ) : (
                <View>
                  {rawConversations.map((conversation) => (
                    <ConversationItem
                      key={conversation.conversation_id}
                      conversation={conversation}
                      isViewed={viewedConversationsSet.has(
                        conversation.conversation_id,
                      )}
                    />
                  ))}
                </View>
              )}
            </>
          )}
        </>
      </InternalScreenContainer>
      {Platform.OS !== 'web' ? <NewMessageButton /> : null}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  locationContainer: {
    marginTop: theme.getSpacing(2),
    marginLeft: theme.getSpacing(1),
  },
  pharmacyNameContainer: {
    flexDirection: 'row',
  },
  pharmacyName: {
    color: theme.palette.gray[500],
  },
  label: {
    color: theme.palette.gray[500],
    marginBottom: theme.getSpacing(0.5),
  },
  buttonContainerMobile: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  button: { fontSize: 8 },
}));
