import {
  ApiError,
  BaseApiConfiguration,
  BaseApiService,
} from 'assets/core/api';
import axios, { AxiosRequestConfig } from 'axios';
import {
  AddPrescriptionDto,
  CreateRecordUnderCareRequestDto,
  DefaultApi as PatientServiceClient,
  LocationPatientRecordDto,
  PatientRecordDto,
  RecordUnderCareDto,
  RecordUnderCareRequestDto,
  UpdatePatientRecordDto,
  InitLocationPatientRecordDto,
  UpdateRecordUnderCareRequestDto,
  RecordUnderCareRequestStatus,
  CreatePatientRecordDto,
  UpdateRecordUnderCareDto,
  DeleteCardDto,
  LocationPatientRecordFindDto,
} from '@digitalpharmacist/patient-service-client-axios';

import { PATIENT_API_BASE_URL } from '../common/constants';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IPatientService {
  createRecordUnderCareRequest(
    userId: string,
    createRecordUnderCareRequestDto: CreateRecordUnderCareRequestDto,
  ): Promise<RecordUnderCareRequestDto>;

  patientRecordAddPrescription(
    patientRecordId: string,
    createPrescription: AddPrescriptionDto,
  ): Promise<PatientRecordDto>;

  findLocationPatientRecords(
    locationId: string,
    options?: {
      offset?: number;
      limit?: number;
      orderBy?: string;
      search?: string;
    },
  ): Promise<LocationPatientRecordFindDto>;

  findLocationPatientRecord(
    locationId: string,
    locationPatientRecordId: string,
  ): Promise<LocationPatientRecordDto | undefined>;

  findLocationPatientRecordByPR(
    locationId: string,
    patientRecord: PatientRecordDto,
  ): Promise<LocationPatientRecordDto | undefined>;

  updatePatientRecord(
    patientRecordId: string,
    patient: UpdatePatientRecordDto,
  ): Promise<PatientRecordDto>;

  findPatientRecord(patientRecordId: string): Promise<PatientRecordDto>;

  findPatientRecordByLocationPatientRecordId(
    locationId: string,
    locationPatientRecordId: string,
  ): Promise<PatientRecordDto>;

  recordUnderCareFindByPatientUser(
    patient_record_id: string,
  ): Promise<RecordUnderCareDto[]>;

  patientRecordInitLocationPatientRecord(
    patientRecordId: string,

    initLocationPatientRecordDto: InitLocationPatientRecordDto,
  ): Promise<PatientRecordDto>;

  recordUnderCareFindByRecordUnderCare(
    recordId: string,
  ): Promise<RecordUnderCareDto[]>;

  revokeByPatientUnderCare(
    recordId: string,
    id: string,
  ): Promise<RecordUnderCareDto>;

  removeRecordUnderCare(
    patientUserId: string,
    id: string,
  ): Promise<RecordUnderCareDto>;

  patientRecordCreate(
    createPatientRecordDto: CreatePatientRecordDto,
  ): Promise<PatientRecordDto>;

  patientRecordUpdate(
    id: string,
    updatePatientRecordDto: UpdatePatientRecordDto,
  ): Promise<PatientRecordDto>;

  recordUnderCareUpdate(
    patientUserId: string,
    id: string,
    updateRecordUnderCareDto: UpdateRecordUnderCareDto,
  ): Promise<RecordUnderCareDto>;

  patientRecordSwitchInsurance(patientId: string): Promise<PatientRecordDto>;

  patientRecordDeleteCard(
    patientRecordId: string,
    deleteCardDto: DeleteCardDto,
  ): Promise<PatientRecordDto>;
}

export class PatientService extends BaseApiService implements IPatientService {
  private patientServiceClient: PatientServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.patientServiceClient = new PatientServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }
  patientRecordUpdate(
    id: string,
    updatePatientRecordDto: UpdatePatientRecordDto,
  ): Promise<PatientRecordDto> {
    throw new Error('Method not implemented.');
  }

  async createRecordUnderCareRequest(
    userId: string,
    createRecordUnderCareRequestDto: CreateRecordUnderCareRequestDto,
  ): Promise<RecordUnderCareRequestDto> {
    const { data } =
      await this.patientServiceClient.recordUnderCareRequestCreate(
        userId,
        createRecordUnderCareRequestDto,
      );
    return data;
  }

  async findLocationPatientRecords(
    locationId: string,
    options?: {
      offset?: number;
      limit?: number;
      orderBy?: string;
      search?: string;
    },
  ): Promise<LocationPatientRecordFindDto> {
    const { data } = await this.patientServiceClient.locationPatientRecordFind(
      locationId,
      options?.offset,
      options?.limit,
      options?.orderBy,
      options?.search,
    );
    return data;
  }

  async findLocationPatientRecordByPR(
    locationId: string,
    patientRecord: PatientRecordDto,
  ): Promise<LocationPatientRecordDto | undefined> {
    const minLocationPatientRecord =
      patientRecord.location_patient_records.find(
        (lpr) => lpr.location_id === locationId,
      );

    if (!minLocationPatientRecord) return;

    const locationPatientRecord = await this.findLocationPatientRecord(
      locationId,
      minLocationPatientRecord.id,
    );

    return locationPatientRecord;
  }

  async findLocationPatientRecord(
    locationId: string,
    locationPatientRecordId: string,
  ): Promise<LocationPatientRecordDto | undefined> {
    try {
      const { data } =
        await this.patientServiceClient.locationPatientRecordFindOne(
          locationId,
          locationPatientRecordId,
        );
      return data;
    } catch (e) {
      return undefined;
    }
  }

  async updatePatientRecord(
    patientRecordId: string,
    patient: UpdatePatientRecordDto,
  ): Promise<PatientRecordDto> {
    const { data } = await this.patientServiceClient.patientRecordUpdate(
      patientRecordId,
      patient,
    );

    return data;
  }

  async findPatientRecordByLocationPatientRecordId(
    locationId: string,
    locationPatientRecordId: string,
  ): Promise<PatientRecordDto> {
    const lprResponse =
      await this.patientServiceClient.locationPatientRecordFindOne(
        locationId,
        locationPatientRecordId,
      );
    if (!lprResponse.data) {
      throw new Error(`LPR ${locationPatientRecordId} was not found!`);
    }
    if (!lprResponse.data.patient_record_id) {
      throw new Error(
        `LPR ${locationPatientRecordId} does not have a patient_record_id!`,
      );
    }
    return await this.findPatientRecord(lprResponse.data.patient_record_id);
  }

  async findPatientRecord(patientRecordId: string): Promise<PatientRecordDto> {
    const { data } =
      await this.patientServiceClient.patientRecordFindOne(patientRecordId);

    return data;
  }

  async patientRecordAddPrescription(
    patient_record_id: string,
    createPrescription: AddPrescriptionDto,
  ): Promise<PatientRecordDto> {
    const { data } =
      await this.patientServiceClient.patientRecordAddPrescription(
        patient_record_id,
        createPrescription,
      );

    return data;
  }

  async recordUnderCareFindByPatientUser(
    patient_user_id: string,
  ): Promise<RecordUnderCareDto[]> {
    const { data } =
      await this.patientServiceClient.recordUnderCareFindByPatientUser(
        patient_user_id,
      );
    return data;
  }

  async patientRecordInitLocationPatientRecord(
    patientRecordId: string,
    initLocationPatientRecordDto: InitLocationPatientRecordDto,
  ): Promise<PatientRecordDto> {
    const { data } =
      await this.patientServiceClient.patientRecordInitLocationPatientRecord(
        patientRecordId,
        initLocationPatientRecordDto,
      );
    return data;
  }

  async ensureLocationPatientRecordExist(
    patientRecordId: string,
    initLocationPatientRecordDto: InitLocationPatientRecordDto,
  ): Promise<void> {
    await this.patientServiceClient
      .patientRecordInitLocationPatientRecord(
        patientRecordId,
        initLocationPatientRecordDto,
      )
      .catch((error) => {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
          return;
        }

        throw error;
      });
  }

  async findPendingRequestsByCaregiver(
    // Pending list on Caregiver side
    userId: string,
  ): Promise<RecordUnderCareRequestDto[]> {
    const { data } =
      await this.patientServiceClient.recordUnderCareRequestFindRequestsByCaregiver(
        userId,
        RecordUnderCareRequestStatus.Pending,
      );
    return data;
  }

  async findPendingRequestsByRecordUnderCare(
    // Medical info
    userId: string,
  ): Promise<RecordUnderCareRequestDto[]> {
    const status: RecordUnderCareRequestStatus =
      RecordUnderCareRequestStatus.Pending;
    const { data } =
      await this.patientServiceClient.recordUnderCareRequestFindRequestsByRecordUnderCare(
        userId,
        status,
      );
    return data;
  }

  async findRecordUnderCareByPatientUser(
    //Established one By caregiver
    patientUserId: string,
  ): Promise<RecordUnderCareDto[]> {
    const { data } =
      await this.patientServiceClient.recordUnderCareFindByPatientUser(
        patientUserId,
      );
    return data;
  }

  async recordUnderCareFindByRecordUnderCare(
    //Established one by record under care - Medical info page
    recordId: string,
  ): Promise<RecordUnderCareDto[]> {
    const { data } =
      await this.patientServiceClient.recordUnderCareFindByRecordUnderCare(
        recordId,
      );
    return data;
  }

  async patchRequestsRecordUnderCare(
    userId: string,
    requestId: string,
    updateRecordUnderCareRequestDto: UpdateRecordUnderCareRequestDto,
  ): Promise<RecordUnderCareRequestDto> {
    const { data } =
      await this.patientServiceClient.recordUnderCareRequestUpdate(
        userId,
        requestId,
        updateRecordUnderCareRequestDto,
      );
    return data;
  }

  async revokeByPatientUnderCare(
    recordId: string,
    id: string,
  ): Promise<RecordUnderCareDto> {
    const { data } =
      await this.patientServiceClient.recordUnderCareRevokeByPatientUnderCare(
        recordId,
        id,
      );
    return data;
  }

  async removeRecordUnderCare(
    patientUserId: string,
    id: string,
  ): Promise<RecordUnderCareDto> {
    const { data } = await this.patientServiceClient.recordUnderCareRemove(
      patientUserId,
      id,
    );
    return data;
  }

  async patientRecordCreate(
    createPatientRecordDto: CreatePatientRecordDto,
  ): Promise<PatientRecordDto> {
    const { data } = await this.patientServiceClient.patientRecordCreate(
      createPatientRecordDto,
    );
    return data;
  }

  async recordUnderCareUpdate(
    patientUserId: string,
    id: string,
    updateRecordUnderCareDto: UpdateRecordUnderCareDto,
  ): Promise<RecordUnderCareDto> {
    const { data } = await this.patientServiceClient.recordUnderCareUpdate(
      patientUserId,
      id,
      updateRecordUnderCareDto,
    );
    return data;
  }

  async patientRecordSwitchInsurance(
    patientId: string,
  ): Promise<PatientRecordDto> {
    const { data } =
      await this.patientServiceClient.patientRecordSwitchInsurance(patientId);
    return data;
  }

  async patientRecordDeleteCard(
    patientRecordId: string,
    deleteCardDto: DeleteCardDto,
  ): Promise<PatientRecordDto> {
    const { data } = await this.patientServiceClient.patientRecordDeleteCard(
      patientRecordId,
      deleteCardDto,
    );
    return data;
  }
}

export default new PatientService(
  PATIENT_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
