import theme, { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View, StyleProp, TextStyle } from 'react-native';

export const CountBadge: FunctionComponent<
  PropsWithChildren<ColoredBadgeProps>
> = ({ count, color = theme.palette.white, backgroundColor, textStyle }) => {
  const styles = useStyles();
  const maxCountToShow = 10;
  const maxCountInfoToShow = `${maxCountToShow}+`;
  const filteredUnreadCount =
    count > maxCountToShow ? maxCountInfoToShow : count;
  return count > 0 ? (
    <View style={styles.badge}>
      <Text
        style={[
          styles.badgeText,
          textStyle,
          {
            backgroundColor,
            color,
          },
        ]}
      >
        {filteredUnreadCount}
      </Text>
    </View>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  badge: {},
  badgeText: {
    ...theme.lumistryFonts.text.small.semiBold,
    fontSize: 12,
    lineHeight: 18,
    textAlign: 'center',
    alignContent: 'center',
    height: 23,
    paddingHorizontal: theme.getSpacing(1),
    borderRadius: 100,
    marginLeft: 10,
  },
}));

export interface ColoredBadgeProps {
  backgroundColor: string;
  color?: string;
  count: number;
  textStyle?: StyleProp<TextStyle>;
  nativeID?: string;
}
